import { CardFullProps, Carousel } from 'components/elements';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';

import styles from './Services.module.scss';

type ServicesProps = { content: { TITLE: string } };

export const Services: React.FC<ServicesProps> = ({ content }) => {
    const { getBanners } = useApi();
    const [banners, setBanners] = useState<CardFullProps[] | undefined>(undefined);

    useEffect(() => {
        hydrateBanners();

        async function hydrateBanners() {
            const banners = await getBanners();
            setBanners(banners);
        }
    }, [getBanners]);

    if (!banners || banners.length === 0) return null;

    return (
        <section className={`${styles['services-cards']} container`}>
            <h2>{content.TITLE}</h2>
            <Carousel __typename="card-full" slides={banners} />
        </section>
    );
};
