import { useCallback, useEffect, useState } from 'react';

import styles from './Drawer.module.scss';

type DrawerProps = {
    header?: JSX.Element;
    footer?: JSX.Element;
    show: boolean;
    setShow: (show: boolean) => void;
};

export const Drawer: React.FC<DrawerProps> = ({ header, footer, show, setShow, children }) => {
    const [showDialog, setShowDialog] = useState(false);

    const shortcutsHandler = useCallback(
        (evt) => {
            if (evt.key === 'Escape' && showDialog) {
                setShowDialog(false);
                setShow(false);
            }
        },
        [setShow, showDialog]
    );

    useEffect(() => setShowDialog(show), [show]);

    useEffect(() => {
        window.addEventListener('keydown', shortcutsHandler);
        return () => window.removeEventListener('keydown', shortcutsHandler);
    }, [shortcutsHandler]);

    const onClick = useCallback(
        (evt) => {
            evt.stopPropagation();
            setShowDialog(false);
            setShow(false);
        },
        [setShow]
    );

    return (
        <section
            className={`${styles.drawer} ${showDialog ? styles.show : ''}`}
            role="button"
            onKeyPress={(evt) => shortcutsHandler(evt)}
            onClick={onClick}
            tabIndex={0}>
            <div
                className={styles.dialog}
                role="button"
                onClick={(e) => e.stopPropagation()}
                onKeyPress={(evt) => shortcutsHandler(evt)}
                tabIndex={0}>
                {header && <div className={styles.header}>{header}</div>}
                <div className={styles.body}>{children}</div>
                {footer && <div className={styles.footer}>{footer}</div>}
            </div>
        </section>
    );
};
