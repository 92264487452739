export const NUTRITION_GURU = {
    PT: {
        VALIDATIONS: {
            QUESTION: 'pergunta obrigatória',
            GDPR: 'assinale a checkbox',
            LEGAL: 'Confirme as condições legais selecionando a checkbox do formulário'
        },
        INTRO: {
            PRE_TITLE: 'dúvidas',
            TITLE: 'Pergunte ao Nutri Guru'
        },
        SEARCH: {
            DESCRIPTION:
                'Hidratos de carbono, dieta do paleolítico, LSD, ácidos gordos… o guru ajuda!',
            PLACEHOLDER: "Pesquisar FAQ's"
        },
        FILTER: {
            LABEL: 'filtrar'
        },
        FAQS: {
            TITLE: "FAQ's"
        },
        FORM: {
            TITLE: 'Ainda com dúvidas?',
            TEXTAREA: 'A sua pergunta',
            GDPR: {
                TEXT: ['Li e concordo com os ', 'Termos e Condições'],
                URL: 'https://www.hihealthyinsurance.com/termos-uso/'
            },
            MESSAGE: {
                SUCCESS:
                    'Obrigado pela sua pergunta! O/A Guru irá responder nas próximas 24 horas.',
                ERROR: 'Oops! Ouve um problema com o envio da sua pergunta. A nossa equipa técnica já foi alertada. Por favor tente mais tarde.'
            },
            BUTTONS: {
                START: 'Pergunte ao Guru',
                SUBMIT: 'Enviar',
                NEW_QUESTION: 'Nova Pergunta'
            }
        },
        MODAL: {
            SUB_TITLE: 'Como funciona?',
            TITLE: 'O Guru sabe a resposta à sua pergunta!',
            STEPS: [
                { id: 1, TEXT: 'Insira o título da sua dúvida' },
                { id: 2, TEXT: 'Descreva de forma detalhada a sua dúvida' },
                { id: 3, TEXT: 'Selecione "enviar" e, em breve, o Guru responde' }
            ]
        }
    },
    EN: {
        VALIDATIONS: {
            QUESTION: 'required question',
            GDPR: 'mark the checkbox',
            LEGAL: 'Confirm the legal conditions by selecting the checkbox on the form'
        },
        INTRO: {
            PRE_TITLE: 'questions',
            TITLE: 'Ask the Nutri Guru'
        },
        SEARCH: {
            DESCRIPTION: 'Carbohydrates, paleolithic diet, LSD, fatty acids... the guru helps!',
            PLACEHOLDER: "Search FAQ's"
        },
        FILTER: {
            LABEL: 'filter'
        },
        FAQS: {
            TITLE: "FAQ's"
        },
        FORM: {
            TITLE: 'Still in doubt?',
            TEXTAREA: 'Your question',
            GDPR: {
                TEXT: ['I have read and agree with the ', 'Terms and Conditions'],
                URL: 'https://www.hihealthyinsurance.com/termos-uso/'
            },
            MESSAGE: {
                SUCCESS:
                    'Thank you for your question! The Guru will answer within the next 24 hours.',
                ERROR: 'Oops! There was a problem sending your question. Our technical team has already been alerted. Please try again later.'
            },
            BUTTONS: {
                START: 'Ask the Guru',
                SUBMIT: 'Send',
                NEW_QUESTION: 'New Question'
            }
        },
        MODAL: {
            SUB_TITLE: 'How does it work?',
            TITLE: 'Guru knows the answer to your question!',
            STEPS: [
                { id: 1, TEXT: 'Enter the title of your question' },
                { id: 2, TEXT: 'Please describe in detail your question' },
                { id: 3, TEXT: 'Select "send" and soon Guru will reply' }
            ]
        }
    }
};
