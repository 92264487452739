import { LogoHi } from 'assets/logos';
import { Button, InputFile, Main, TextAreaWithCounter } from 'components/elements';
import { Header, Unavailable } from 'components/sections';
import { permissions } from 'config';
import * as IMAGES from 'constants/images';
import { SHARE } from 'content';
import { useAuth, useLanguage, useTheme, useWebShare } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import { cj, getRandomInt } from 'utils';

import { TITLE_OPTIONS } from './constants';
import { CropDialog, ErrorMessage, SuccessMessage } from './Modules';
import styles from './Share.module.scss';

const CHARACTER_LIMIT = 50;

export const Share: React.FC = () => {
    const { validateUserAccessToArea } = useAuth();
    const { activeTheme } = useTheme();
    const { canShare, shareTarget, shareImage, hasError, setHasError, hasSuccess, setHasSuccess } =
        useWebShare();
    const { isPT } = useLanguage();

    const CONTENT = isPT ? SHARE.PT : SHARE.EN;

    const [selectedUserPhoto, setSelectedUserPhoto] = useState<string | undefined>(undefined);
    const [croppedPhoto, setCroppedPhoto] = useState<string | undefined>(undefined);
    const [showCropDialog, setShowCropDialog] = useState(false);
    const [imageTitle, setImageTitle] = useState('');
    const [sharedImage, setSharedImage] = useState<string | undefined>(undefined);
    const [footerColor, setFooterColor] = useState('#BF80FF');

    validateUserAccessToArea(permissions.general.share);

    const resizeFile = useCallback(
        (file: Blob) =>
            new Promise((resolve) => {
                Resizer.imageFileResizer(
                    file, // image file which will resized.
                    1080, // maxWidth of the resized new image.
                    1080, // maxHeight of the resized new image.
                    'JPEG', // compressFormat of the resized new image.
                    80, // quality of the resized new image.
                    0, // degree of clockwise rotation to apply to uploaded image.
                    (uri) => {
                        resolve(uri);
                    }, // callBack function of the resized new image URI.
                    'base64', // output type of the resized new image.
                    600, // minWidth of the resized new image.
                    600 // minHeight of the resized new image.
                );
            }),
        []
    );

    const onInputFileChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.files && e.target.files.length > 0) {
                try {
                    const file = e.target.files[0];
                    const image = (await resizeFile(file)) as string;

                    setSelectedUserPhoto(image);
                    setShowCropDialog(true);
                } catch (err) {
                    // TO-DO ERROR HANDLING
                    console.log(err);
                }
            }
        },
        [resizeFile]
    );

    const onShareClick = useCallback(async () => {
        try {
            const image = await shareImage();
            if (!image) return;

            const url = URL.createObjectURL(image);
            setSharedImage(url);
        } catch (error) {
            // TO-DO ERROR HANDLING
            console.log(error);
        }
    }, [shareImage]);

    const onTextAreaChange = useCallback((evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (evt.target.value.length > CHARACTER_LIMIT) return;
        setImageTitle(evt.target.value);
    }, []);

    useEffect(() => {
        const index = getRandomInt(0, TITLE_OPTIONS.length);
        setImageTitle(TITLE_OPTIONS[index].title);
    }, []);

    useEffect(() => {
        if (!hasError) return;

        setCroppedPhoto(undefined);
        setSelectedUserPhoto(undefined);
    }, [hasError, setHasError]);

    if (!canShare)
        return (
            <Unavailable
                title={CONTENT.UNAVAILABLE.TITLE}
                description={CONTENT.UNAVAILABLE.DESCRIPTION}
            />
        );

    if (hasSuccess) {
        return (
            <SuccessMessage
                setHasSuccess={setHasSuccess}
                sharedImage={sharedImage}
                content={CONTENT.SUCCESS}
            />
        );
    }

    return (
        <>
            <Header backButton hasImage={false} />
            <Main>
                <section className={cj(styles.section, 'container')}>
                    {croppedPhoto || hasError ? (
                        <div ref={shareTarget} className={styles['image-placeholder']}>
                            {hasError && <ErrorMessage />}

                            <img
                                src={croppedPhoto}
                                className={styles['user-image']}
                                alt="user selected"
                            />

                            <div className={cj(styles.overlay, styles[activeTheme])}>
                                <h2>{imageTitle}</h2>
                                <span className={styles.url}>hihealthyinsurance.com</span>
                                <img
                                    src={IMAGES.HI_SYMBOL}
                                    alt="Hi brand"
                                    className={styles.symbol}
                                />
                                <svg
                                    className={styles.footer}
                                    width="300"
                                    height="120"
                                    viewBox="0 0 300 108"
                                    preserveAspectRatio="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        opacity="0.8"
                                        d="M300 0C300 0 257.477 61.2701 0 57.9194V108H300V0Z"
                                        fill={footerColor}
                                    />
                                </svg>
                            </div>
                        </div>
                    ) : (
                        <div className={styles['intro-card']}>
                            <span className={styles.emojis}>
                                😍 <LogoHi showLettering={false} width={64} animate /> 👍
                            </span>
                            <h2>{CONTENT.INTRO.TITLE}</h2>
                            <p>{CONTENT.INTRO.DESCRIPTION}</p>
                        </div>
                    )}

                    {croppedPhoto && (
                        <div
                            className={cj(styles['title-select'], croppedPhoto ? styles.show : '')}>
                            <TextAreaWithCounter
                                characterLimit={CHARACTER_LIMIT}
                                onChange={onTextAreaChange}
                                value={imageTitle}
                                height={60}
                                className={styles.input}
                            />
                            <input
                                type="color"
                                value={footerColor}
                                onChange={(evt) => setFooterColor(evt.target.value)}
                            />
                        </div>
                    )}

                    <div className={styles['button-container']}>
                        {!hasError && (
                            <InputFile
                                inputLabel={CONTENT.BUTTONS.CHOOSE}
                                icon="CAMERA"
                                onChange={onInputFileChange}
                                accept="image/*"
                                className={styles['image-select-button']}
                            />
                        )}

                        {croppedPhoto && (
                            <Button
                                label={CONTENT.BUTTONS.SHARE}
                                icon={{ type: 'shareAlt', position: 'right' }}
                                size="md"
                                onClick={onShareClick}
                                className={styles['share-button']}
                            />
                        )}
                    </div>

                    {showCropDialog && selectedUserPhoto && (
                        <CropDialog
                            selectedUserPhoto={selectedUserPhoto}
                            setShowCropDialog={setShowCropDialog}
                            setCroppedPhoto={setCroppedPhoto}
                            setSelectedUserPhoto={setSelectedUserPhoto}
                        />
                    )}
                </section>
            </Main>
        </>
    );
};
