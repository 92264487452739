import { permissions } from 'config';
import { NUTRITION_NUTRITIONISTS_LIST } from 'content';
import { useApi, useAuth, useLanguage } from 'hooks';
import { StaffData } from 'models/types';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { StaffContextData } from './types';

const StaffContext = createContext<StaffContextData>({} as StaffContextData);

export const StaffProvider: React.FC = ({ children }) => {
    const { isPT } = useLanguage();
    const { validateUserAccessToArea } = useAuth();
    const { getStaff } = useApi();

    const CONTENT = isPT ? NUTRITION_NUTRITIONISTS_LIST.PT : NUTRITION_NUTRITIONISTS_LIST.EN;

    const [staff, setStaff] = useState<StaffData[]>([]);
    const [notFound, setNotFound] = useState(false);
    const [filteredContent, setFilteredContent] = useState<{ id: string; data: string }[]>([]);
    const [searchedStaff, setSearchedStaff] = useState<StaffData[]>([]);
    const [resetSearch, setResetSearch] = useState(false);

    const fetchData = useCallback(async () => {
        const nutritionists = await getStaff('nutritionists');
        setStaff(nutritionists);
    }, [getStaff]);

    useEffect(
        () => validateUserAccessToArea(permissions.nutrition.nutritionists),
        [validateUserAccessToArea]
    );

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        const IDs = filteredContent.map((c) => c.id);
        const filteredStaff = staff.filter((s) => IDs.includes(s.id));
        setSearchedStaff(filteredStaff);
    }, [filteredContent, staff]);

    const hasSearchResults = useMemo(() => searchedStaff.length > 0, [searchedStaff]);

    return (
        <StaffContext.Provider
            value={{
                staff,
                CONTENT,
                notFound,
                setNotFound,
                filteredContent,
                setFilteredContent,
                searchedStaff,
                hasSearchResults,
                resetSearch,
                setResetSearch
            }}>
            {children}
        </StaffContext.Provider>
    );
};

export function useStaff(): StaffContextData {
    const context = useContext(StaffContext);
    if (!context) throw new Error('useStaff must be used withing an StaffContext');
    return context;
}
