import { useLanguage } from 'hooks';
import { useEffect, useMemo, useState } from 'react';

import styles from './LangSelect.module.scss';

export type LangSelectProps = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>;

type ButtonsLabels = ('PT' | 'EN')[];

export const LangSelect: React.FC<LangSelectProps> = ({ className, ...props }) => {
    const { switchLanguage, isPT } = useLanguage();
    const [buttons, setButtons] = useState<ButtonsLabels>(['PT', 'EN']);
    const [showList, setShowList] = useState(false);

    useEffect(() => setButtons(isPT ? ['PT', 'EN'] : ['EN', 'PT']), [isPT]);

    const btnClassName = useMemo(
        () => [styles['button-container'], className].join(' '),
        [className]
    );

    return (
        <div className={btnClassName}>
            <button {...props} onClick={() => setShowList((state) => !state)}>
                {buttons[0]}
            </button>
            {showList && (
                <div className={styles.list}>
                    <button
                        onClick={() => {
                            switchLanguage(buttons[0]);
                            setButtons([buttons[0], buttons[1]]);
                            setShowList(false);
                        }}>
                        {buttons[0]}
                    </button>
                    <button
                        onClick={() => {
                            switchLanguage(buttons[1]);
                            setButtons([buttons[1], buttons[0]]);
                            setShowList(false);
                        }}>
                        {buttons[1]}
                    </button>
                </div>
            )}
        </div>
    );
};
