import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconShare: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                d="M2695 903.22a.67.67 0 0 1-.67-.67v-4.24a10.29 10.29 0 0 1 10.31-10.23h.55v-4.19a.67.67 0 0 1 .67-.67.71.71 0 0 1 .48.19l7.79 7.74a.65.65 0 0 1 0 .94l-7.79 7.74a.69.69 0 0 1-1 0 .71.71 0 0 1-.19-.48v-4.19h-2a7.44 7.44 0 0 0-7.47 7.4.67.67 0 0 1-.68.66Zm9.64-13.81a9 9 0 0 0-9 8.49 8.85 8.85 0 0 1 7.46-4.09h2.72a.67.67 0 0 1 .68.67v3.25l6.17-6.12-6.17-6.12v3.25a.67.67 0 0 1-.68.67Z"
                transform="translate(-2694.32 -883.22)"
            />
        </svg>
    );
};
