type ZUMUB_PROPS = {
    CODE: string;
    LINK: {
        PT: string;
        EN: string;
    };
};

export const ZUMUB: ZUMUB_PROPS = {
    CODE: 'NUTRIHI!',
    LINK: {
        PT: 'https://www.zumub.com/PT/?partner=hi2',
        EN: 'https://www.zumub.com/EN/?partner=hi2'
    }
};
