import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconReset: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                d="M18.68,9.34H20A10,10,0,1,1,3.28,2.6,9.73,9.73,0,0,1,10.41,0a9.92,9.92,0,0,1,6.92,3.18V0h1.32V5.65H13V4.34h3.5a8.57,8.57,0,1,0,2.17,5"
                transform="translate(0)"
            />
        </svg>
    );
};
