import { CardArticleV2, Carousel, Filter, NotFoundMessage, Search } from 'components/elements';
import { permissions } from 'config';
import { META_TAGS, MOVEMENT_ON_DEMAND_LIST } from 'content';
import { useAuth, useLanguage, useTheme, useVideos } from 'hooks';
import { VideoData } from 'models/video';
import { CSSProperties, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FixedSizeList as List } from 'react-window';
import { countDuplicatesInArray } from 'utils';

import styles from './OnDemandList.module.scss';

type VideosData = { videos?: VideoData[] | undefined };

type SearchProps = {
    setFilteredContent: Dispatch<
        SetStateAction<
            {
                id: string;
                data: string;
            }[]
        >
    >;
    notFound: boolean;
    setNotFound: Dispatch<SetStateAction<boolean>>;
    setResetSearch: Dispatch<SetStateAction<boolean>>;
    resetSearch: boolean;
};

type VideoListProps = {
    filterLabel: string;
    filterOptions: string[];
    handleFilter: (selectedCategory: string) => void;
    currentVideos: VideosData;
};

type CategoriesListProps = {
    filterLabel: string;
    filterOptions: string[];
    handleFilter: (selectedCategory: string) => void;
};

const IntroSection: React.FC = () => {
    const { isPT } = useLanguage();

    const CONTENT = isPT ? MOVEMENT_ON_DEMAND_LIST.PT : MOVEMENT_ON_DEMAND_LIST.EN;

    return (
        <section className={`${styles.intro} container`}>
            <span className={styles.preTitle}>{CONTENT.PRE_TITLE}</span>
            <h1>{CONTENT.TITLE}</h1>
        </section>
    );
};

const SearchSection: React.FC<SearchProps> = ({
    setFilteredContent,
    setNotFound,
    setResetSearch,
    resetSearch
}) => {
    const { allVideos } = useVideos();
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();

    const CONTENT = isPT ? MOVEMENT_ON_DEMAND_LIST.PT.SEARCH : MOVEMENT_ON_DEMAND_LIST.EN.SEARCH;

    if (allVideos)
        return (
            <section className={`${styles.search} container`}>
                <p>{CONTENT.DESCRIPTION}</p>
                <Search
                    searchContent={allVideos.map((v) => ({
                        id: v.details.tag,
                        data: `${v.details.name} ${v.details.description}`
                    }))}
                    placeholder={CONTENT.PLACEHOLDER}
                    setFilteredContent={setFilteredContent}
                    setNotFound={setNotFound}
                    onInputFocus={() => setResetSearch(false)}
                    resetSearch={resetSearch}
                    theme={activeTheme}
                    className={styles.input}
                />
            </section>
        );

    return null;
};

const HighlightsSection: React.FC = () => {
    const { isPT } = useLanguage();
    const { videosByCategory } = useVideos();
    const { activeTheme } = useTheme();

    const CONTENT = isPT ? MOVEMENT_ON_DEMAND_LIST.PT : MOVEMENT_ON_DEMAND_LIST.EN;

    return (
        <section className={`${styles.highlights} container`}>
            <h2>{CONTENT.HIGHLIGHTS.TITLE}</h2>
            {videosByCategory?.highlights && videosByCategory?.highlights?.length > 0 ? (
                <Carousel
                    __typename="card-videos"
                    slides={videosByCategory.highlights}
                    showCategory
                />
            ) : (
                <div className={`${styles.loading} ${styles[activeTheme]}`}>
                    <span>{CONTENT.LOADING}</span>
                </div>
            )}
        </section>
    );
};

const VideoListSection: React.FC<VideoListProps> = ({
    filterLabel,
    filterOptions,
    handleFilter,
    currentVideos
}) => {
    const { isPT } = useLanguage();
    const CardEl = useRef<HTMLDivElement>(null);

    const CONTENT = isPT
        ? MOVEMENT_ON_DEMAND_LIST.PT.CATEGORIES
        : MOVEMENT_ON_DEMAND_LIST.EN.CATEGORIES;

    /*
     * @see: https://github.com/bvaughn/react-window
     */
    const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
        if (!currentVideos.videos) return null;
        const v = currentVideos.videos[index];
        return (
            <div
                ref={CardEl}
                key={index.toString()}
                style={{ ...style, top: Number(style.top) + 20 }}>
                <CardArticleV2
                    to={`/movimento/videos/${v.details.tag}`}
                    image={v.video.imageLink}
                    title={v.details.name}
                    description={v.details.description}
                    showCategory={false}
                    duration={v.video.durationInSeconds}
                    level={v.specs.level}
                    date={v.video.publishedDate}
                />
            </div>
        );
    };

    return (
        <section className={`${styles.list} container`}>
            <div className={styles.header}>
                <h2>{CONTENT.TITLE}</h2>
                <Filter label={filterLabel} options={filterOptions} handleFilter={handleFilter} />
            </div>

            {currentVideos.videos && (
                <List
                    itemCount={currentVideos.videos.length}
                    itemSize={CardEl.current ? CardEl.current?.offsetHeight : 360}
                    width={340}
                    height={currentVideos.videos.length * 340}>
                    {Row}
                </List>
            )}
        </section>
    );
};

const CategoriesListSection: React.FC<CategoriesListProps> = ({
    filterLabel,
    filterOptions,
    handleFilter
}) => {
    const { isPT } = useLanguage();
    const { videosByCategory } = useVideos();
    const { activeTheme } = useTheme();

    const CONTENT = isPT
        ? MOVEMENT_ON_DEMAND_LIST.PT.CATEGORIES
        : MOVEMENT_ON_DEMAND_LIST.EN.CATEGORIES;

    const categoriesSectionData = [
        {
            id: 1,
            title: 'Cardio',
            videos: videosByCategory?.cardio
        },
        {
            id: 2,
            title: 'Stretch & Recover',
            videos: videosByCategory?.stretchAndRecover
        },
        {
            id: 3,
            title: 'Strength',
            videos: videosByCategory?.strength
        },
        {
            id: 4,
            title: 'Yoga',
            videos: videosByCategory?.yoga
        },
        {
            id: 5,
            title: 'Pilates',
            videos: videosByCategory?.pilates
        },
        {
            id: 6,
            title: 'Cycling',
            videos: videosByCategory?.cycling
        },
        {
            id: 7,
            title: 'Dance',
            videos: videosByCategory?.dance
        },
        {
            id: 8,
            title: 'Meditation',
            videos: videosByCategory?.meditation
        },
        {
            id: 9,
            title: 'Bootcamp',
            videos: videosByCategory?.bootcamp
        },
        {
            id: 10,
            title: 'Box And Fight',
            videos: videosByCategory?.boxAndFight
        },
        {
            id: 11,
            title: 'other',
            videos: videosByCategory?.other
        }
    ];

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? META_TAGS.PRIVATE.MOVEMENT.ON_DEMAND_LIST.PT.TITLE
                        : META_TAGS.PRIVATE.MOVEMENT.ON_DEMAND_LIST.EN.TITLE}
                </title>
            </Helmet>
            <section className={`${styles.categories} container`}>
                <div className={styles.header}>
                    <h2>{CONTENT.TITLE}</h2>
                    <Filter
                        label={filterLabel}
                        options={filterOptions}
                        handleFilter={handleFilter}
                    />
                </div>
                {categoriesSectionData.map((c) => {
                    if (c.videos && c.videos?.length > 0)
                        return (
                            <div className={styles.category} key={c.id.toString()}>
                                <span className={`${styles.label} ${styles[activeTheme]}`}>
                                    {c.title}
                                </span>
                                <Carousel __typename="card-videos" slides={c.videos} />
                            </div>
                        );
                })}
            </section>
        </>
    );
};

export const OnDemandList: React.FC = () => {
    const { isPT } = useLanguage();
    const { allVideos } = useVideos();
    const { validateUserAccessToArea } = useAuth();

    const CONTENT = isPT ? MOVEMENT_ON_DEMAND_LIST.PT : MOVEMENT_ON_DEMAND_LIST.EN;

    const [filterLabel, setFilterLabel] = useState(CONTENT.FILTER.LABEL);
    const [filterOptions, setFilterOptions] = useState<string[]>([]);
    const [currentVideos, setCurrentVideos] = useState<VideosData>({ videos: undefined });
    const [filteredContent, setFilteredContent] = useState<{ id: string; data: string }[]>([]);
    const [notFound, setNotFound] = useState(false);
    const [resetSearch, setResetSearch] = useState(false);

    useEffect(
        () => validateUserAccessToArea(permissions.movement.onDemand),
        [validateUserAccessToArea]
    );

    useEffect(() => validateUserAccessToArea(['admin', 'guest']), [validateUserAccessToArea]);

    useEffect(() => {
        if (!allVideos) return;

        const allCategories = allVideos.map(({ details }) => details.category);

        const countedDuplicatedCategories = countDuplicatesInArray(allCategories.flat().sort());

        // @see : https://medium.com/@gmcharmy/sort-objects-in-javascript-e-c-how-to-get-sorted-values-from-an-object-142a9ae7157c
        const orderedCategories: string[] = Object.entries(countedDuplicatedCategories)
            .sort((a, b) => b[1] - a[1])
            .map((el) => el[0]);

        setFilterOptions(orderedCategories);
    }, [allVideos]);

    const handleFilter = (selectedCategory: string) => {
        if (!selectedCategory || !allVideos) {
            setCurrentVideos({ videos: allVideos });
            setFilterLabel(CONTENT.FILTER.LABEL);
        } else {
            setCurrentVideos({
                videos: allVideos.filter(({ details }) => details.category === selectedCategory)
            });
            setFilterLabel(
                `${selectedCategory.slice(0, 10)}${selectedCategory.length > 10 ? '...' : ''}`
            );
        }
    };

    useEffect(() => {
        const videos =
            filteredContent.length > 0
                ? allVideos?.filter((v) => filteredContent.map((c) => c.id).includes(v.details.tag))
                : allVideos;
        setCurrentVideos({ videos });
    }, [allVideos, filteredContent]);

    return (
        <>
            <IntroSection />
            <SearchSection
                setFilteredContent={setFilteredContent}
                notFound={notFound}
                setNotFound={setNotFound}
                setResetSearch={setResetSearch}
                resetSearch={resetSearch}
            />

            <section className="container">
                <NotFoundMessage show={notFound} setResetForm={setResetSearch} />
            </section>

            {currentVideos.videos && currentVideos.videos !== allVideos ? (
                <VideoListSection
                    filterLabel={filterLabel}
                    filterOptions={filterOptions}
                    handleFilter={handleFilter}
                    currentVideos={currentVideos}
                />
            ) : (
                <>
                    <HighlightsSection />
                    <CategoriesListSection
                        filterLabel={filterLabel}
                        filterOptions={filterOptions}
                        handleFilter={handleFilter}
                    />
                </>
            )}
        </>
    );
};
