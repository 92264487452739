export const PASSWORD_RECOVER = {
    PT: {
        VALIDATION_MESSAGES: {
            EMAIL: ['email obrigatório', 'verifique o email inserido'],
            EMAIL_SENT:
                'Caso este email esteja associado à Hi! vamos enviar um pedido de recuperação'
        },
        TITLE: ['Recuperar', 'Password'],
        DESCRIPTION: ['Insira o endereço de email', 'associado à sua conta Hi!'],
        LABELS: {
            EMAIL: 'e-mail',
            SUBMIT: 'Recuperar'
        },
        LINKS: {
            LOGIN: 'Acesso'
        },
        MODAL: {
            TITLE: 'Verifique o seu email',
            DESCRIPTION: [
                {
                    TEXT: 'Caso o seu endereço de email esteja associado à Hi! irá receber um pedido de recuperação de password.'
                }
            ],
            BUTTON: {
                CLOSE: 'Fechar'
            }
        }
    },
    EN: {
        VALIDATION_MESSAGES: {
            EMAIL: ['email required', 'check entered email'],
            EMAIL_SENT: 'If this email is associated with Hi! we will send you a recovery request'
        },
        TITLE: ['Recover', 'Password'],
        DESCRIPTION: ['Enter your email address', 'associated to your Hi! account'],
        LABELS: {
            EMAIL: 'e-mail',
            SUBMIT: 'Recover'
        },
        LINKS: {
            LOGIN: 'Login'
        },
        MODAL: {
            TITLE: 'Check your email',
            DESCRIPTION: [
                {
                    TEXT: 'If your email is associated with Hi! you will receive a password recovery request.'
                }
            ],
            BUTTON: {
                CLOSE: 'Close'
            }
        }
    }
};
