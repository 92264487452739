import styles from './Intro.module.scss';

type IntroProps = {
    partner: {
        logo: string;
        name: string;
        backgroundColor?: string;
    };
};

export const Intro: React.FC<IntroProps> = ({ partner }) => {
    return (
        <section className={`${styles.intro} container`}>
            <div className={styles.logo}>
                <img src={partner.logo} alt={partner.name} />
            </div>
        </section>
    );
};
