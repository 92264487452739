import { Area } from 'react-easy-crop/types';

type CreateImage = (url: string) => Promise<HTMLImageElement>;
type GetRadianAngle = (degreeValue: number) => number;
type RotateSize = (
    width: number,
    height: number,
    rotation: number
) => { width: number; height: number };

type GetCroppedImg = (
    imageSrc: string,
    pixelCrop: Area,
    rotation?: number,
    flip?: {
        horizontal: boolean;
        vertical: boolean;
    }
) => Promise<string | null>;

type GetRotatedImage = (imageSrc: string, rotation?: number) => Promise<unknown>;

export const createImage: CreateImage = (url) =>
    new Promise<HTMLImageElement>((resolve, reject) => {
        const image: HTMLImageElement = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });

export const getRadianAngle: GetRadianAngle = (degreeValue: number) =>
    (degreeValue * Math.PI) / 180;

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export const rotateSize: RotateSize = (width, height, rotation) => {
    const rotRad = getRadianAngle(rotation);
    return {
        width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
    };
};

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export const getCroppedImg: GetCroppedImg = async (
    imageSrc: string,
    pixelCrop: Area,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    // As Base64 string
    return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise<string>((resolve, reject) => {
        console.log({ reject });

        canvas.toBlob((file) => {
            if (!file) return;
            resolve(URL.createObjectURL(file));
        }, 'image/jpeg');
    });
};

export const getRotatedImage: GetRotatedImage = async (imageSrc: string, rotation = 0) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const orientationChanged =
        rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;
    if (orientationChanged) {
        canvas.width = image.height;
        canvas.height = image.width;
    } else {
        canvas.width = image.width;
        canvas.height = image.height;
    }

    ctx?.translate(canvas.width / 2, canvas.height / 2);
    ctx?.rotate((rotation * Math.PI) / 180);
    ctx?.drawImage(image, -image.width / 2, -image.height / 2);

    return new Promise((resolve) => {
        canvas.toBlob((file) => {
            if (!file) return;
            resolve(URL.createObjectURL(file));
        }, 'image/png');
    });
};
