import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconQuestionRounded: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                d="M2661.72 928a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm0 19.17a9.18 9.18 0 1 1 9.18-9.17 9.17 9.17 0 0 1-9.18 9.17Zm1.93-14.23a4.68 4.68 0 0 0-1.81-.33 4.42 4.42 0 0 0-1.79.34 3.31 3.31 0 0 0-2 2.38l1.14.66a2.35 2.35 0 0 1 2.51-2.18 2.63 2.63 0 0 1 1.7.46 1.52 1.52 0 0 1 .56 1.22 1.7 1.7 0 0 1-.3 1 2.23 2.23 0 0 1-1 .67 7.41 7.41 0 0 1-1.73.42V940h1.33v-1.71a4.43 4.43 0 0 0 1.51-.43 2.69 2.69 0 0 0 1.09-1 2.85 2.85 0 0 0 .41-1.56 2.42 2.42 0 0 0-.45-1.45 2.77 2.77 0 0 0-1.17-.91Zm-2 8.47a1 1 0 1 0 1 .93 1 1 0 0 0-.29-.64 1 1 0 0 0-.71-.29Z"
                transform="translate(-2651.72 -928)"
            />
        </svg>
    );
};
