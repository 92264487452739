export const WELCOME = {
    PT: {
        SLIDES: [
            {
                TITLE: 'Adesão e acesso livre a ginásio',
                DESCRIPTION:
                    'Agende sessões de treino com um/a sports coach e registe os seus treinos.'
            },
            {
                TITLE: 'Acompanhamento, descontos e dicas nutricionais',
                DESCRIPTION:
                    'Aproveite vantagens em alimentação / suplementação, e nutricionistas para o/a ajudar nos seus objetivos'
            },
            {
                TITLE: 'Seguro de saúde compreensivo para a sua proteção',
                DESCRIPTION: 'Use o seguro de saúde de prevenção e marque consultas e check-up'
            },
            {
                TITLE: 'Health Promotor Score (HPS)',
                DESCRIPTION:
                    'Treine, ganhe pontos e tenha acesso a descontos exclusivos! Premiamos a sua saúde.'
            },
            {
                TITLE: 'Bem-vindo/a à Hi!',
                DESCRIPTION: 'Agora faz parte da (r)evolução'
            }
        ],
        TITLE: 'Bem-vindo/a',
        LINK: {
            LOGIN: 'Iniciar Sessão',
            SIMULATION: ['Não é cliente? ', 'Faça uma simulação']
        }
    },
    EN: {
        SLIDES: [
            {
                TITLE: 'Free gym membership and access',
                DESCRIPTION:
                    'Schedule training sessions with a sports coach and record your workouts.'
            },
            {
                TITLE: 'Follow-up, discounts and nutritional tips',
                DESCRIPTION:
                    'Take advantage of advantages in food / supplementation, and nutritionists to help you reach your goals'
            },
            {
                TITLE: 'Comprehensive health insurance for your protection',
                DESCRIPTION:
                    'Use preventive health insurance and schedule appointments and checkups'
            },
            {
                TITLE: 'Health Promotor Score (HPS)',
                DESCRIPTION:
                    'Train, earn points, and have access to exclusive discounts! We reward your health.'
            },
            {
                TITLE: 'Welcome to Hi!',
                DESCRIPTION: 'You are now part of the (r)evolution'
            }
        ],
        TITLE: 'Welcome',
        LINK: {
            LOGIN: 'Sign in',
            SIMULATION: ['Not a client? ', 'Make a simulation']
        }
    }
};
