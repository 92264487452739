import { sanitize } from 'dompurify';
import { useLanguage, useTheme } from 'hooks';
import { Link } from 'react-router-dom';
import { IconCheck } from 'theme/icons';

import styles from './CardPromo.module.scss';

type Language = { pt: string; en: string };

export type CardPromoProps = React.HTMLProps<HTMLDivElement> & {
    partner: {
        name: string;
        slug: string;
        id: string;
    };
    promo: {
        name: Language;
        image: string;
        description: Language;
        notes: Language;
        slug: string;
    };
    showHeader?: boolean;
    isValid: boolean;
};

export const CardPromo: React.FC<CardPromoProps> = ({
    partner,
    promo,
    className = '',
    showHeader = false,
    isValid,
    ...props
}) => {
    const { isPT } = useLanguage();
    const { activeTheme } = useTheme();

    return (
        <Link
            to={`/promos/${partner.slug}/${partner.id}`}
            className={[styles.card, isValid ? '' : styles.invalid, className].join(' ')}>
            <div {...props}>
                {showHeader && (
                    <div className={styles['card-header']}>
                        <strong>{partner.name}</strong>
                    </div>
                )}
                <div className={[styles['card-image'], styles[activeTheme]].join(' ')}>
                    <img src={promo.image} alt={isPT ? promo.name.pt : promo.name.en} />
                    {!isValid && (
                        <div className={[styles['invalid-message'], styles[activeTheme]].join(' ')}>
                            <span>
                                <IconCheck className={styles.icon} />
                                {isPT ? 'Voucher já resgatado' : 'Voucher already redeemed'}
                            </span>
                        </div>
                    )}
                </div>
                <div className={styles['card-body']}>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: sanitize(isPT ? promo.description.pt : promo.description.en)
                        }}
                    />
                    <p
                        className={[styles.notes, styles[activeTheme]].join(' ')}
                        dangerouslySetInnerHTML={{
                            __html: sanitize(isPT ? promo.notes.pt : promo.notes.en)
                        }}
                    />
                </div>
            </div>
        </Link>
    );
};
