// import { DEV } from 'utils';

export const logSignature: () => void = () => {
    console.log(`
    
    `);
    console.log('%c developed by: ', 'color:white; font-size: 12px; background-color: #29292e');
    console.log(
        '%c Mr.Benga & <theycallmewolf/>',
        'color:white; font-family:monospace; font-size: 18px; background-color: #29292e'
    );
    console.log(
        '%c visit: https://mrbenga.com/ and https://www.theycallmewolf.com/',
        'font-size: 10px;'
    );
    console.log(`
    
    `);

    // DEV MODE
    // DEV && devModeLog();
};

// const devModeLog = () => {
//     console.log('%c dev-mode: ', 'color:white; font-size: 12px; background-color: #7C0819;');

//     console.log(`http://localhost:3000/?dev=true
//     `);
// };
