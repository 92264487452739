import { useTheme } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { cj } from 'utils';

import styles from './TextAreaWithCounter.module.scss';

type TextAreaWithCounterProps = React.HTMLProps<HTMLTextAreaElement> & {
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
    characterLimit: number;
    value?: string;
    height?: number;
    className?: string;
};

export const TextAreaWithCounter: React.FC<TextAreaWithCounterProps> = ({
    characterLimit,
    className,
    height,
    ...props
}) => {
    const { activeTheme } = useTheme();

    const [counterAlert, setCounterAlert] = useState(false);
    const [characterCounter, setCharacterCounter] = useState(0);
    const [message, setMessage] = useState('');

    const onTextAreaChange = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const { value } = event.target;

            if (value.length > characterLimit) {
                setCounterAlert(true);
                return;
            }

            setCounterAlert(false);
            setMessage(value);
            setCharacterCounter(value.length);
        },
        [characterLimit]
    );

    useEffect(() => setMessage(props.value ?? ''), [props.value]);
    useEffect(() => setCharacterCounter(props.value ? props.value.length : 0), [props.value]);

    return (
        <div className={cj(styles['textarea-holder'], styles[activeTheme], className)}>
            <textarea
                {...props}
                value={message}
                onChange={(evt) => {
                    onTextAreaChange(evt);
                    props.onChange?.(evt);
                }}
                style={{ height: height ?? '12rem' }}
            />
            <span className={cj(styles['word-counter'], counterAlert ? styles.alert : '')}>
                {characterCounter} / {characterLimit}
            </span>
        </div>
    );
};
