import { CardPromo } from 'components/elements';
import { PROMOS_LIST } from 'content';
import { useLanguage } from 'hooks';
import { PromoDataV2 } from 'models/types';

import styles from './List.module.scss';

type ListProps = {
    promos: PromoDataV2[] | undefined;
};

export const List: React.FC<ListProps> = ({ promos }) => {
    const { isPT } = useLanguage();
    const CONTENT = isPT ? PROMOS_LIST.PT.LIST : PROMOS_LIST.EN.LIST;

    if (!promos) return null;

    if (promos.length === 0) {
        return (
            <section className={`${styles.msg} container`}>
                <h2>{CONTENT.NO_RESULTS.TITLE}</h2>
                <p>{CONTENT.NO_RESULTS.DESCRIPTION}</p>
            </section>
        );
    }

    return (
        <section className={`${styles.list} container`}>
            {promos.map((promo) => (
                <CardPromo
                    showHeader
                    partner={promo.partner}
                    promo={{
                        name: promo.name,
                        image: promo.image,
                        description: promo.description,
                        notes: promo.notes,
                        slug: promo.partner.slug
                    }}
                    isValid={
                        promo.voucher.number_of_redeems === null ||
                        promo.voucher.number_of_redeems > 0
                    }
                    className={styles.card}
                    key={promo.id.toString()}
                />
            ))}
        </section>
    );
};
