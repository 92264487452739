import styles from './Intro.module.scss';

type IntroProps = {
    preTitle: string;
    title: string;
};

export const Intro: React.FC<IntroProps> = ({ preTitle, title }) => {
    return (
        <section className={`${styles.intro} container`}>
            <span className={styles.preTitle}>{preTitle}</span>
            <h1>{title}</h1>
        </section>
    );
};
