import { MEDICAL_APPOINTMENT_PHONE_NUMBER } from 'content';

export const HEALTH_APPOINTMENT = {
    PT: {
        TITLE: 'Médico Online',
        AVAILABILITY: 'disponível 24h, 7 dias por semana',
        DESCRIPTION: [
            {
                ID: 1,
                TYPE: 'h2',
                TEXT: 'Precisa de uma consulta?'
            },
            {
                ID: 2,
                TYPE: 'p',
                TEXT: ' Fale com um médico por video-chamada, que o/a orientará para os cuidados médicos mais apropriados. Tenha um médico mais perto de si, a qualquer hora e a partir de qualquer lugar, de forma simples e segura, e com toda a conveniência. Disponível 24h por dia, 7 dias por semana.'
            },
            {
                ID: 3,
                TYPE: 'h2',
                TEXT: 'Inclui'
            },
            {
                ID: 4,
                TYPE: 'ul',
                ITEMS: [
                    { ID: 1, TEXT: 'Triagem clínica telefónica' },
                    { ID: 2, TEXT: 'Video-consulta realizada por médicos experientes' },
                    {
                        ID: 3,
                        TEXT: 'Envio da prescrição médica de medicamentos por e-mail ou sms'
                    },
                    {
                        ID: 4,
                        TEXT: 'Especialidades médicas de Medicina Geral e Familiar, Medicina Interna, Pediatria e Consulta do viajante.'
                    }
                ]
            },
            {
                ID: 5,
                TYPE: 'strong',
                TEXT: 'Consulte as condições contratadas no seu seguro de saúde'
            },
            {
                ID: 6,
                TYPE: 'h2',
                TEXT: 'Como utilizar?'
            },
            {
                ID: 7,
                TYPE: 'ol',
                ITEMS: [
                    { ID: 1, TEXT: `Ligue para o número ${MEDICAL_APPOINTMENT_PHONE_NUMBER}` },
                    {
                        ID: 2,
                        TEXT: 'Efetue a marcação para a data e hora de acordo com a sua preferência.'
                    },
                    {
                        ID: 3,
                        TEXT: 'Após a marcação ser-lhe-á enviado um sms e/ou um email com o link de acesso para a realização da sua consulta online e, na data e hora agendada, inicie a sua consulta acedendo ao link enviado.'
                    }
                ]
            },
            {
                ID: 8,
                TYPE: 'h2',
                TEXT: 'Dados necessários'
            },
            {
                ID: 9,
                TYPE: 'ul',
                ITEMS: [
                    { ID: 1, TEXT: 'NIF' },
                    { ID: 2, TEXT: 'Data de nascimento' },
                    { ID: 3, TEXT: 'Nº de utente SNS' },
                    { ID: 4, TEXT: 'Contacto telefónico' },
                    { ID: 5, TEXT: 'E-mail' },
                    { ID: 6, TEXT: 'Nº cartão AdvanceCare' }
                ]
            },
            {
                ID: 10,
                TYPE: 'footnote',
                TEXT: 'Serviço prestado pela Europ Assistance - Serviços de Assistência Personalizados, S.A., assumindo esta entidade a responsabilidade integral pela prestação do respetivo serviço.'
            }
        ]
    },
    EN: {
        TITLE: 'Online Doctor',
        AVAILABILITY: 'available 24h, 7 days a week',
        DESCRIPTION: [
            {
                ID: 1,
                TYPE: 'h2',
                TEXT: 'Need an appointment?'
            },
            {
                ID: 2,
                TYPE: 'p',
                TEXT: 'Talk to a doctor by video call, who will guide you to the most appropriate medical care. Have a doctor near you, at any time and from anywhere, in a simple and safe way, and with all the convenience. Available 24 hours a day, 7 days a week.'
            },
            {
                ID: 3,
                TYPE: 'h2',
                TEXT: 'Includes'
            },
            {
                ID: 4,
                TYPE: 'ul',
                ITEMS: [
                    { ID: 1, TEXT: 'Telephone clinical triage' },
                    { ID: 2, TEXT: 'Video consultation by experienced doctors' },
                    {
                        ID: 3,
                        TEXT: 'Sending of prescriptions by e-mail or sms'
                    },
                    {
                        ID: 4,
                        TEXT: "Medical specialties General Practice, Internal Medicine, Pediatrics and Traveler's consultation."
                    }
                ]
            },
            {
                ID: 5,
                TYPE: 'strong',
                TEXT: 'Check the conditions of your health insurance policy'
            },
            {
                ID: 6,
                TYPE: 'h2',
                TEXT: 'How to use?'
            },
            {
                ID: 7,
                TYPE: 'ol',
                ITEMS: [
                    {
                        ID: 1,
                        TEXT: `Make a call to the number ${MEDICAL_APPOINTMENT_PHONE_NUMBER}`
                    },
                    {
                        ID: 2,
                        TEXT: 'Book for the date and time according to your preference.'
                    },
                    {
                        ID: 3,
                        TEXT: 'After booking, you will be sent an SMS and/or an email with the access link to your online appointment and, on the date and time scheduled, start your appointment by accessing the link sent.'
                    }
                ]
            },
            {
                ID: 8,
                TYPE: 'h2',
                TEXT: 'Required data'
            },
            {
                ID: 9,
                TYPE: 'ul',
                ITEMS: [
                    { ID: 1, TEXT: 'NIF' },
                    { ID: 2, TEXT: 'Date of birth' },
                    { ID: 3, TEXT: 'No. of SNS users' },
                    { ID: 4, TEXT: 'Telephone contact' },
                    { ID: 5, TEXT: 'E-mail' },
                    { ID: 6, TEXT: 'AdvanceCare card number' }
                ]
            },
            {
                ID: 10,
                TYPE: 'footnote',
                TEXT: 'Service provided by Europ Assistance - Serviços de Assistência Personalizados, S.A., which is fully responsible for providing the respective service.'
            }
        ]
    }
};
