import { COLORS } from 'theme';

export type IllustrationProps = React.HtmlHTMLAttributes<HTMLOrSVGElement> & { title?: string };

export const SlideTwoNutrition: React.FC<IllustrationProps> = ({ title = 'Nutrição' }) => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 280 336">
        <symbol id="i-apple" viewBox="-13 -15.5 26 31">
            <path
                fill={COLORS.PURPLE_600}
                d="m-3.1-6.1-.4-1.6c-.4.1-.7.3-1.1.4a4.94 4.94 0 0 1-6.2-4.3c-.1-.3.1-.5.3-.6a5 5 0 0 1 6.3.8l.2.2v-.2c0-1 .2-2.1.6-3 .1-.3.3-.6.4-.8.1-.2.4-.3.6-.1l.1.1c.2.2.2.4 0 .6-.4.6-.7 1.3-.7 2-.2 1.2-.1 2.4.1 3.6.1.8.3 1.5.5 2.3 0 .2.1.3.2.5.3-.4.6-.7 1-1 1-1 2.3-1.7 3.7-2.1 1.5-.7 3.3-.5 4.8.4 1.6.9 3 2.3 3.8 4a15.17 15.17 0 0 1 .7 13c-.7 1.8-1.9 3.4-3.5 4.5a6.9 6.9 0 0 1-3.9 1.2c-.1 0-.2 0-.3.1a7.3 7.3 0 0 1-5.9 1.5c-2-.3-3.9-1.2-5.4-2.5a15.3 15.3 0 0 1-4.1-5c-1.3-2.2-1.8-4.8-1.6-7.4.1-1.8.8-3.4 2-4.8.9-1 2.1-1.6 3.5-1.9 1.4-.3 2.8-.3 4.1 0l.2.1zM-9.2 6.6c0 .2.3.3.5.3.2-.1.3-.3.2-.5-.1-.2-.3-.4-.5-.3-.2 0-.3.2-.2.5 0-.1 0 0 0 0z"
            />
        </symbol>
        <symbol id="i-avatar" viewBox="-15.9 -19 31.9 38">
            <path
                d="M.3-1.2c3.1-2.2 4.6-6 3.9-9.7-1-5.2-5.6-8.8-10.4-7.9s-7.9 5.7-6.9 11C-12.5-4.1-9.7-1-6-.1-18.9 4.3-15.5 19-15.5 19L16 13.5c-.1 0-2.1-15-15.7-14.7z"
                fill={COLORS.BLUISH_PURPLE_200}
            />
        </symbol>
        <symbol id="i-calendar" viewBox="-18 -17.8 35.9 35.6">
            <path
                fill={COLORS.PURPLE_300}
                d="M-10.7 3.3c-.1-.3 0-.6.1-.8.1-.3.4-.5.7-.5L-.5-.7c.4-.1.8 0 1.1.3.3.3.4.7.3 1-.2.3-.5.6-.8.7L-9.3 4c-.3.1-.6 0-.8-.1a.9.9 0 0 1-.6-.6zm22-17.7 6.6 23c.1.3 0 .6-.1.8-.1.3-.4.4-.7.5L-10 17.7c-.3.1-.6 0-.8-.1-.3-.1-.4-.4-.5-.7l-6.7-23c-.1-.3 0-.6.1-.8.1-.3.4-.4.6-.5l4.2-1.2-.9-3.1c-.2-.6.1-1.2.7-1.3.2-.1.4-.1.6 0 .4.1.7.4.8.8l.9 3.1 14.6-4.2-.9-3.1c-.1-.4 0-.8.2-1.1.4-.4 1.1-.4 1.5 0 .1.1.2.3.3.5l.9 3.1 4.2-1.2c.3-.1.6 0 .8.1.5.1.7.4.7.6zm-.2 6.9L-14-.3l4.5 15.7 25.1-7.2-4.5-15.7zM-7.3 11.3 7.4 7.2c.3-.1.6-.4.7-.8.1-.6-.2-1.2-.8-1.3h-.6L-7.9 9.3c-.3.1-.6.4-.7.7-.1.4 0 .7.3 1 .3.3.7.4 1 .3z"
            />
        </symbol>
        <symbol id="i-card" viewBox="-25.5 -19.3 51 38.6">
            <path
                fill={COLORS.BLUE_200}
                d="M-21.4-10.9c-2.8.6-4.6 3.1-4 5.6l4.9 21.2c.6 2.4 3.3 3.9 6 3.3L21.4 11c2.8-.6 4.5-3.1 4-5.6l-4.9-21.2c-.8-2.6-3.5-4-6.1-3.3l-35.8 8.2zm.6 2.5 35.9-8.2c1-.3 2.2.2 2.5 1.3v.1l.4 1.9-40.2 9.2-.3-1.9c-.2-1.1.5-2.2 1.7-2.4 0 .1 0 0 0 0zm.2 11 40.2-9.2L22.5 6c.2 1.1-.5 2.1-1.6 2.4h-.1l-35.9 8.2c-1.1.3-2.2-.3-2.6-1.4l-2.9-12.6zM6.9-.3C4 .4 2.2 3 2.8 5.5S6.1 9.6 9 8.9a6 6 0 0 0 2.5-1.3c.9.3 1.9.3 2.8.1 2.9-.7 4.7-3.3 4.1-5.8S15-2.2 12.2-1.5c-.9.2-1.8.6-2.5 1.2-.9-.2-1.9-.2-2.8 0zm-22 6.2c-.4.1-.7.3-.9.6-.4.5-.3 1.3.2 1.7.1 0 .1.1.2.1.3.2.7.2 1.1.1l10-2.3c.4-.1.8-.3 1-.6.2-.3.3-.7.2-1-.1-.3-.4-.6-.7-.8-.3-.2-.7-.2-1.1-.1l-10 2.3zM7.5 2.2c.2 0 .4-.1.7-.1v.1c-.1.3-.1.6-.2.8v.2c0 .3 0 .7.1 1 .1.3.2.6.3 1l.1.2c.2.2.4.4.5.6 0 0 0 .1.1.1-.2.1-.4.2-.6.2-1.2.4-2.4-.3-2.8-1.5-.2-1.2.6-2.3 1.8-2.6zM12.8 1c1.2-.4 2.4.3 2.8 1.5.2 1.2-.6 2.4-1.8 2.6-.6.1-1.2.1-1.7-.2H12c-.4-.2-.7-.5-.8-.9-.1-.1-.2-.3-.2-.4-.1-.6 0-1.1.3-1.6v-.1c.4-.4.9-.8 1.5-.9zm-27.2 8.7c-.5.1-.9.5-1.1 1-.1.5 0 1 .4 1.3.4.3.9.4 1.4.3l4.3-1c.5-.1.9-.4 1.1-.9.2-.4.1-.9-.3-1.3-.4-.3-.9-.5-1.4-.4l-4.3 1h-.1z"
            />
        </symbol>
        <symbol id="i-gift" viewBox="-38 -38 76 76">
            <path
                fill={COLORS.PURPLE_300}
                d="M-13.5 19.8 9.8 27c1.2.4 2.5.3 3.6-.3s1.9-1.6 2.3-2.8l3.5-11.2a4.8 4.8 0 0 0 3.1-3.1l1.4-4.5a5 5 0 0 0-.2-3.4C23 .6 22.2-.2 21.1-.7A7.42 7.42 0 0 0 18-13c-3.1-1-6.4-.4-9 1.5-1.1-3-3.5-5.3-6.5-6.3-3.9-1.2-8.1 1-9.3 4.9A8.2 8.2 0 0 0-7-9.4c-1.1-.2-2.3 0-3.4.6-1 .6-1.8 1.6-2.1 2.7l-1.4 4.5c-.5 1.5-.2 3.1.8 4.3l-3.5 11.2c-.8 2.4.6 5.1 3.1 5.9zm1.2-3.9c-.3-.1-.5-.5-.4-.8l3.2-10.3L.8 7.9l-3.4 11-9.7-3zm24.1 6.8c-.1.2-.2.3-.3.4-.2.1-.3.1-.5 0l-9.7-3 3.4-11L15 12.3l-3.2 10.4zm6.6-14.3c-.1.2-.2.3-.3.4-.2.1-.3.1-.5 0L5.9 5.2 7.7-.6 19.4 3c.2.1.3.2.4.3.1.2.1.4 0 .5l-1.4 4.6zM16.8-9.1c1.8.6 2.8 2.4 2.2 4.2a3.3 3.3 0 0 1-4.2 2.2L9-4.5l.2-.7c1-3.1 4.4-4.9 7.6-3.9zM1.3-13.9c3.2 1 5 4.4 4 7.6l-.2.6-5.8-1.8C-2.5-8-3.5-9.9-3-11.7a3.4 3.4 0 0 1 4.3-2.2zm-9.9 9c.1-.4.5-.6.9-.4L3.9-1.8 2 4-9.6.4c-.4-.1-.6-.5-.5-.8l1.5-4.5z"
            />
            <g style={{ opacity: 0.5 }}>
                <path
                    fill={COLORS.PURPLE_300}
                    d="M0 38c-21 0-38-17-38-38s17-38 38-38S38-21 38 0 21 38 0 38zm0-58c-11 0-20 9-20 20s9 20 20 20 20-9 20-20-9-20-20-20z"
                />
            </g>
        </symbol>
        <symbol id="i-graph" viewBox="-26.7 -27.3 53.4 54.7">
            <path
                d="M.1 27.3c-5.7 0-11.2-1.9-15.7-5.3-11.9-9-14.7-25.7-6.2-38 4-5.9 10.3-9.9 17.4-11 14.5-2.4 28.3 7.8 30.8 22.7 1.2 7.1-.4 14.3-4.5 20.2-4 5.9-10.3 9.9-17.4 11-1.5.3-2.9.4-4.4.4zm-.3-40.6c-.7 0-1.3.1-2 .2-3.3.6-6.2 2.4-8.1 5.2-4.1 6-2.7 14.3 3.1 18.7 5.3 4.1 13 3.1 17.1-2.2.2-.2.3-.4.5-.6 2-2.9 2.8-6.5 2.2-10-1-6.4-6.4-11.2-12.8-11.3z"
                fill={COLORS.BLUISH_PURPLE_400}
                style={{ opacity: 0.4 }}
            />
            <path
                fill={COLORS.BLUISH_PURPLE_400}
                d="m-2.2 2.7 6.7 2.9L9.3-5 6.7-6.1l-3.6 8L-3.6-1-8.4 9.6l2.6 1.2 3.6-8.1zm14.1 5 .4 2.6-22.5 3.7-2.9-17.8 2.6-.4-4.8-4.3-3.2 5.6 2.6-.4 3.4 20.6 25.3-4.2.4 2.6 4.3-4.8-5.6-3.2z"
            />
        </symbol>
        <symbol id="i-heart" viewBox="-13.9 -12.8 27.9 25.6">
            <path
                fill={COLORS.PURPLE_600}
                d="M13.4-8.5a7.4 7.4 0 0 0-8.2-4.2A7.3 7.3 0 0 0-.2-5.4 7.28 7.28 0 0 0-8.1-10c-3.4.7-5.8 3.6-5.9 7.1.1 2.4 1.2 4.7 3 6.4 1.8 1.6 3.8 3 6 4.1 2.9 1.7 5.6 3.3 8.6 5 2-2.7 3.7-5.3 5.7-8 1.5-1.9 2.8-4 3.8-6.2 1-2 1.1-4.6.3-6.9z"
            />
        </symbol>
        <symbol id="i-plane" viewBox="-39.9 -39.9 79.8 79.8">
            <path
                fill={COLORS.BLUE_200}
                d="M28.8 1.5C14.1-4.6-.5-10.7-15.2-16.8c-.8-.3-1.6-.7-2.4.1-.7.8-.3 1.6 0 2.4l15.3 31.1c.9 1.8 1.3 1.9 3.2 1.1.7-.3 1.3-.6 1.9-.9 1.2-.8 2-.6 2.9.6 1.2 1.8 2.7 3.3 4.1 5 .4.6 1.1.9 1.9.8.7-.2 1-.8 1.2-1.5.8-3.2 1.6-6.4 2.3-9.6.1-.8.7-1.5 1.4-1.8 4-1.8 8-3.7 12.1-5.6.7-.3 1.2-.7 1.7-1.2V2.6c-.5-.5-1-.9-1.6-1.1zM11.2 15.7c-.2.9-.4 1.9-.7 3-1-1.3-2-2.5-2.9-3.6 1-1 2.4-1.3 3.6-2 .2-.1.3-.3.7-.1l-.7 2.7zm-10-1.3c-.8.4-1.1.3-1.5-.5C-2.7 9-5.1 4.2-7.5-.7l-4.1-8.4.2-.1L12 9.4c-3.8 1.7-7.3 3.3-10.8 5zm14.7-6.8c-.5.3-1.2.2-1.7-.2C9.5 3.6 4.9-.1.2-3.8l-8.3-6.6.1-.1L25.1 3.2c-3.2 1.5-6.3 2.9-9.2 4.4z"
            />
            <g style={{ opacity: 0.5 }}>
                <path
                    fill={COLORS.BLUE_200}
                    d="M0 39.9C-22 39.9-39.9 22-39.9 0S-22-39.9 0-39.9 39.9-22 39.9 0A39.95 39.95 0 0 1 0 39.9zm0-61.8c-12.1.1-21.8 9.9-21.8 22A21.9 21.9 0 1 0 22-.1 21.88 21.88 0 0 0 0-21.9z"
                />
            </g>
        </symbol>
        <symbol id="i-star" viewBox="-9.1 -9.1 18.2 18.2">
            <path
                fill={COLORS.BLUISH_PURPLE_400}
                d="m0-9.1 3.2 5.9L9.1 0 3.2 3.2 0 9.1l-3.2-5.9L-9.1 0l5.9-3.2L0-9.1z"
            />
        </symbol>
        <g id="canvas">
            <path id="canvas-2" d="M0 0h280v336H0V0z" style={{ fill: 'none' }} />
        </g>
        <g id="fg">
            <ellipse
                cx="140.7"
                cy="321.5"
                rx="104.1"
                ry="13.4"
                fill={COLORS.PURPLE_300}
                style={{ opacity: 0.3 }}
            />
            <path
                fill={COLORS.MAGENTA_300}
                d="M178.6 106h-14.7c-2 0-3.6 1.7-3.6 3.7v2.7c0 2-1.6 3.7-3.6 3.7h-28.6c-2 0-3.6-1.7-3.6-3.7v-2.7c0-2-1.6-3.7-3.6-3.7h-18c-6.4 0-11.6 5.2-11.6 11.6v186.2c0 6.4 5.2 11.5 11.6 11.5h75.5c6.4 0 11.6-5.2 11.6-11.5V117.5c.1-6.4-5.1-11.5-11.4-11.5z"
            />
            <path d="M178.9 100.4h-75.7c-9.1 0-16.5 7.4-16.5 16.5v189.8c0 9.1 7.4 16.5 16.5 16.5h75.7c9.1 0 16.5-7.4 16.5-16.5V116.9c-.1-9.1-7.4-16.4-16.5-16.5zM190.3 304c0 6.4-5.2 11.5-11.5 11.5h-75.5c-6.4 0-11.5-5.2-11.5-11.6V118.7c0-6.4 5.2-11.5 11.5-11.5h18c2 0 3.6 1.7 3.6 3.7v2.7c0 2 1.5 3.7 3.6 3.7H157c2 0 3.6-1.7 3.6-3.7v-2.7c0-2 1.5-3.7 3.6-3.7h14.7c6.4 0 11.5 5.2 11.5 11.6l-.1 185.2z" />
            <defs>
                <path id="SVGID_1_" d="M15.7 281.4h31.9v38H15.7z" />
            </defs>
            <clipPath id="SVGID_00000166649919907752747410000013705765552282257544_">
                <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
            </clipPath>
            <g
                style={{
                    clipPath: 'url(#SVGID_00000166649919907752747410000013705765552282257544_)'
                }}>
                <use
                    xlinkHref="#i-avatar"
                    width="31.9"
                    height="38"
                    x="-15.9"
                    y="-19"
                    transform="translate(28.6 299.73)"
                    style={{ overflow: 'visible' }}
                />
            </g>
            <use
                xlinkHref="#i-star"
                width="18.2"
                height="18.2"
                x="-9.1"
                y="-9.1"
                transform="translate(249.38 154.3)"
                style={{ overflow: 'visible' }}
            />
            <defs>
                <path
                    id="SVGID_00000146461703969152395830000008811168736728706971_"
                    d="M17.7 236.7h27.9v25.6H17.7z"
                />
            </defs>
            <clipPath id="SVGID_00000029759549930000429950000003142039683275546019_">
                <use
                    xlinkHref="#SVGID_00000146461703969152395830000008811168736728706971_"
                    style={{ overflow: 'visible' }}
                />
            </clipPath>
            <g
                style={{
                    clipPath: 'url(#SVGID_00000029759549930000429950000003142039683275546019_)'
                }}>
                <use
                    xlinkHref="#i-heart"
                    width="27.9"
                    height="25.6"
                    x="-13.9"
                    y="-12.8"
                    transform="translate(31.68 248.97)"
                    style={{ overflow: 'visible' }}
                />
            </g>
            <defs>
                <path
                    id="SVGID_00000111171563457963331590000005499789721282810011_"
                    d="M91 261.3h16V300H91z"
                />
            </defs>
            <clipPath id="SVGID_00000017496218258694717150000007796095712266058405_">
                <use
                    xlinkHref="#SVGID_00000111171563457963331590000005499789721282810011_"
                    style={{ overflow: 'visible' }}
                />
            </clipPath>
            <path
                fill={COLORS.MAGENTA_300}
                style={{
                    opacity: 0.4,
                    clipPath: 'url(#SVGID_00000017496218258694717150000007796095712266058405_)'
                }}
                d="M62 275.1c-2.2.4-3.7 2.5-3.4 4.7l4.1 17.8c.7 2.2 2.9 3.4 5.1 2.8l30.2-6.9c2.2-.4 3.7-2.5 3.4-4.7l-4.1-17.9a4.1 4.1 0 0 0-5.1-2.8l-30.2 7zm.5 2.1 30.3-6.9c.9-.3 1.9.2 2.2 1.2l.4 1.6-33.9 7.8-.4-1.6c-.2-1 .4-1.9 1.4-2.1zm-.6 5.7 33.9-7.8.3 1.3-33.9 7.8-.3-1.3zm.8 3.5 33.9-7.8 2.4 10.6c.2.9-.5 1.8-1.4 2l-30.3 6.9c-.9.3-1.9-.2-2.2-1.1l-2.4-10.6zm23.1-2.4c-2.2.2-3.8 2.1-3.6 4.3s2.1 3.8 4.3 3.6c.4 0 .7-.1 1.1-.2.8-.2 1.5-.6 2.1-1.1.8.2 1.6.2 2.4.1 2.2-.2 3.8-2.2 3.6-4.4s-2.2-3.8-4.4-3.6c-.4 0-.7.1-1 .2-1.3 1.1-3 1.5-4.5 1.1zm-18.6 5.2c-.3.1-.6.2-.8.5-.4.4-.3 1.1.1 1.4.1.1.1.1.2.1.3.1.6.2.9.1l8.5-1.9c.3-.1.6-.2.8-.5.2-.2.3-.6.2-.9-.1-.3-.3-.5-.5-.7-.3-.2-.6-.2-.9-.1l-8.5 2zm19.1-3.1c.2 0 .4-.1.6-.1v.1c0 .2-.1.4-.1.6v.2c0 .3 0 .6.1.9.1.3.2.5.3.8 0 .1.1.1.1.2.1.2.2.4.3.5v.1c-.2.1-.3.2-.5.2-.9.3-1.9-.1-2.3-1-.3-.9.1-1.9 1-2.3.2-.1.3-.1.5-.2zm4.5-1c.9-.3 1.9.1 2.3 1 .3.9-.1 1.9-1 2.3-.1.1-.3.1-.5.1-.5.1-1 .1-1.4-.2H90c-.3-.2-.6-.4-.7-.8 0-.1-.1-.2-.1-.3-.1-.5 0-1 .3-1.3v-.1c.3-.2.7-.5 1.3-.7zm-23 7.4c-.4.1-.8.4-.9.8-.1.4 0 .8.3 1.1.3.3.8.4 1.2.2l3.6-.8c.4-.1.8-.4 1-.8.1-.4 0-.8-.2-1.1-.3-.3-.8-.4-1.2-.3l-3.6.8-.2.1z"
            />
            <defs>
                <path
                    id="SVGID_00000121270736453929796930000006776083136188951736_"
                    d="M50.9 259.5h51v38.6h-51z"
                />
            </defs>
            <clipPath id="SVGID_00000165215755465983010790000009974941543482574489_">
                <use
                    xlinkHref="#SVGID_00000121270736453929796930000006776083136188951736_"
                    style={{ overflow: 'visible' }}
                />
            </clipPath>
            <g
                style={{
                    clipPath: 'url(#SVGID_00000165215755465983010790000009974941543482574489_)'
                }}>
                <use
                    xlinkHref="#i-card"
                    width="51"
                    height="38.6"
                    x="-25.5"
                    y="-19.3"
                    transform="translate(75.95 278.24)"
                    style={{ overflow: 'visible' }}
                />
            </g>
            <path
                fill={COLORS.BLUISH_PURPLE_400}
                d="M64.7 74.1c1.8.1 3.4.9 4.6 2.2.6.6 1.6.7 2.2.1.6-.6.7-1.5.1-2.1-1.7-1.9-4.1-3-6.7-3.2-4.5-.2-8.5 2.7-10.4 7l-2.5-.2c-.9-.1-1.6.6-1.6 1.4-.1.9.6 1.6 1.4 1.6l1.9.1c-.1.4-.1.9-.2 1.3v1.4l-1.9-.1a1.56 1.56 0 1 0-.3 3.1h.2l2.5.1c1.3 4.5 5 7.8 9.5 8.1 2.6.1 5.1-.7 7-2.4.6-.5.7-1.5.2-2.1-.6-.7-1.6-.7-2.2-.2a7.4 7.4 0 0 1-4.8 1.7c-2.8-.3-5.3-2.1-6.3-4.8l8.3.4c.9 0 1.6-.6 1.6-1.5s-.6-1.6-1.5-1.6l-9.2-.4v-1.4c0-.4.1-.9.2-1.3l9.2.5c.9 0 1.6-.6 1.6-1.5s-.6-1.6-1.5-1.6l-8.3-.4c1.4-2.6 4-4.2 6.9-4.2z"
            />
            <g>
                <defs>
                    <path
                        id="SVGID_00000147214239869354636790000014313370022773327805_"
                        d="m91 316 89 2s10-8 10-12V172H91v144z"
                    />
                </defs>
                <clipPath id="SVGID_00000091006962661789165740000006767287766772628403_">
                    <use
                        xlinkHref="#SVGID_00000147214239869354636790000014313370022773327805_"
                        style={{ overflow: 'visible' }}
                    />
                </clipPath>
                <g
                    style={{
                        clipPath: 'url(#SVGID_00000091006962661789165740000006767287766772628403_)'
                    }}>
                    <path
                        fill={COLORS.MAGENTA_300}
                        style={{ opacity: 0.4 }}
                        d="M203.1 280.9c-.1-.7-.2-1.5-.5-2.2L168.2 253l-3.4-3.4c1.4-5.4.5-15.2 1.5-17.6.8-2 1.9-4.3.6-6-.7-.8-1.7-1.3-2.7-1.3-.1-2.3-.4-4.6-1-6.8-1.4-5.1-4.7-11.6-10.9-13.8-3.3-1.2-7-1.2-10.3 0-3.4 1.2-6.3 3.3-8.7 4.3-1.5-3.2-4.4-5.7-7-7.4 1.6-2.1 5.1-2.1 8.2-3 3.6-1 9-4.9 9-4.9-3.4-1-7-1.6-10.5-1.8-4.8-.3-6.5 6.6-7 9.4-2.6-1.6-5.3-3.1-8.1-4.4-.2-.1-.5 0-.6.2-.1.1-.1.2 0 .4l.4 1c.4 1 1.2 1.9 2.2 2.3 3 1.1 9.4 4 12.1 8.7-.5.1-1.1.2-1.6.1-3.5-.4-7 0-10.4 1.1-.6.2-1.1.4-1.7.6l-.7.3c-4.9 2.3-8.9 6.4-9.2 12.3v.8c0 1.2.1 2.3.4 3.4 0 .1 0 .2.1.3.9 3.4 2.3 6.7 4 9.9-1.4 1.2-2.6 2.7-3.5 4.3-.3.8.1 2.2.7 3.5.6 1.4 1.9 2.4 3.4 2.7l-.1.1.1-.1c-.3.8-.2 1.7.1 2.5.6 1.4 1.8 2.6 3.3 3.1l-.2.2c-.8.6-3.4 2.7-3.2 5.7.2 1.9.9 3.7 2.2 5.2-.5.9-.9 1.9-1.1 2.9v.1c.1 1.6.8 3 1.9 4.1l.4.2c1.7.6 3.5.8 5.2.6 1.5-.3 3.1-.5 4.6-.7 1.5-.1 3 0 4.4.3 1.7 0 3.4.4 5 1.1 1.5.7 2.9 1.5 4.2 2.5l1 .5c2 1.2 3.9 2.5 5.7 4.1.3.2.6.4.9.7l51.8 52.1 1.7-2.8c-.1-3.7 0-7.3.3-11-.4-6.2-.3-12.4.3-18.5.2-2.5.6-5 1-7.4-.4-4.4-.3-8.6.1-12.8z"
                    />
                    <path
                        fill={COLORS.RED_100}
                        d="M201.6 308.2c-.4-6.2-.3-12.4.3-18.5.2-2.5.6-5 1-7.4-.2-4.2-.2-8.5.2-12.7-.1-.7-.2-1.5-.5-2.2l-34.5-25.8-7.5-7.5c-2-1.8-4-3.7-5.8-5.6-1.9-2.3-3.4-5-4.1-7.9-1.2-4.2-.5-5.5-.9-8.1v-.7c0-.4-.1-.9-.2-1.3-.8-2.5-4.4-2.4-6 .8-1.2 2.5-2.9 6.7.1 16.6l.1.4c2.5 5.7-12.1 1.9-17.1-.1-3-1.1-6.4.4-7.5 3.5-.1.3-.2.6-.2.9l-1.8 19.2a8.12 8.12 0 0 0-2.5 4.5v.1c.1 1.6.8 3 1.9 4.1.1.1.2.1.4.2 1.7.6 3.5.8 5.2.6 1.5-.3 3.1-.5 4.6-.7 1.5-.1 3 0 4.4.3 1.7 0 3.4.4 5 1.1 1.5.7 2.9 1.5 4.2 2.5l1 .5c2 1.2 3.9 2.5 5.7 4.1.3.2.6.4.9.7l51.8 52.1 1.7-2.8c-.3-3.6-.2-7.2.1-10.9z"
                    />
                    <path
                        fill={COLORS.RED_100}
                        d="M166.3 220.6c.8-2 1.9-4.3.6-6.1-1.1-1.4-3.2-1.5-5-1-4 1.2-6.6 5-7.3 8.9s.1 7.8 1 11.6c.6 2.5 1.4 5.2 3.6 6.7 8.7 5.9 5.5-16.3 7.1-20.1z"
                    />
                    <path d="M119.9 231.4c0 .1-1.3.6-2.8 1.4-.6.3-1.3.7-1.9 1.1-.5.3-.7.6-.7.5s.1-.4.5-.8c.6-.5 1.2-.9 1.9-1.2.9-.5 2-.9 3-1zm-1.5-3.8c-.4.9-1.1 1.8-1.9 2.4-.6.5-1.2.9-1.9 1.2-.5.2-.9.2-.9.1.9-.4 1.8-1 2.5-1.6.7-.7 1.4-1.5 2.2-2.1z" />
                    <path
                        fill={COLORS.MAGENTA_500}
                        d="M122.6 229.7c2.4.8 4.9 1.4 7.4 1.7 10 1.2 22.1-1.3 33.7-9.8.8-5.1.6-10.2-.6-15.2-1.4-5.1-4.7-11.6-10.9-13.8-3.3-1.2-7-1.2-10.3 0-5 1.7-8.6 5.3-11.8 4.9-3.5-.4-7 0-10.2 1.1-.6.2-1.1.4-1.7.6l-.7.3c-4.9 2.3-8.9 6.4-9.2 12.3-.1 1.5.1 3 .4 4.5 1.4 5.9 6.6 10.8 13.9 13.4z"
                    />
                    <path
                        fill={COLORS.WHITE}
                        d="M148 194.7s6.1.1 7.4 4.1-2.9 4.7-5.5 3.2-5.3-6.6-1.9-7.3z"
                    />
                    <path
                        fill={COLORS.MAGENTA_500}
                        d="M108.3 211.9c-.1 1.5.1 3 .4 4.5 1.4 5.8 7.6 19.4 15 21.9 0 0 14.5 6.9 23 4.2 9.6-3 15.7-10 17-20.9.8-5.1.6-10.2-.6-15.2 0 0-10.2 15.7-32.5 14.2-8.2-.3-16-3.4-22.3-8.7z"
                    />
                    <path
                        d="M108.3 211.9c-.1 1.5.1 3 .4 4.5 1.4 5.8 7.6 19.4 15 21.9 0 0 14.5 6.9 23 4.2 9.6-3 15.7-10 17-20.9.8-5.1.6-10.2-.6-15.2 0 0-10.2 15.7-32.5 14.2-8.2-.3-16-3.4-22.3-8.7z"
                        fill={COLORS.MAGENTA_500}
                        style={{ opacity: 0.4 }}
                    />
                    <path
                        d="M125.7 190.4c1.3-3.1 5.2-2.9 8.8-3.9s9-4.9 9-4.9c-3.4-1-7-1.6-10.5-1.8-6-.3-7.3 10.6-7.3 10.6z"
                        fill={COLORS.GREEN_200}
                    />
                    <path
                        fill={COLORS.GREEN_400}
                        d="M125.7 190.4c.9-3.3 4.2-7.3 6.8-8s10.9-.8 10.9-.8c-3.4-1-7-1.6-10.5-1.8-5.9-.3-7.2 10.6-7.2 10.6z"
                    />
                    <path
                        d="M130.1 197.6c-3.5-.4-7 0-10.2 1.1 6.1-1.7 13.2 2.1 14.4.1s7.4-6 7.6-6.2c-4.9 1.8-8.6 5.4-11.8 5z"
                        fill={COLORS.PURPLE_600}
                    />
                    <path d="M117.5 199.6c.2-.1.5-.2.7-.3l-.7.3z" fill={COLORS.GREEN_400} />
                    <path
                        fill={COLORS.GREEN_400}
                        d="M133.9 199c-1-4.3-4.9-7.6-8-9.7-2.6-1.6-5.3-3.1-8.1-4.4-.2-.1-.5 0-.6.2-.1.1-.1.2 0 .4l.4 1c.4 1 1.2 1.9 2.2 2.3 3.3 1.2 10.5 4.5 12.6 9.9.2.5 1.7 1 1.5.3z"
                    />
                    <path
                        fill={COLORS.RED_100}
                        d="M114.4 236.8c-1.9.1-3.7-1-4.5-2.7-.6-1.3-1-2.6-.7-3.5 1.4-3.5 13.9-14 16.6-11.7s3.3 4.2-3.3 10c-1.2 1-2.9 2.6-2.9 2.6l1.1 3.5-6.3 1.8z"
                    />
                    <path
                        fill={COLORS.RED_100}
                        d="M117.6 259.3c3.9-1.1 7.5-1.3 11.4-2.5 1.8-.5 5-1.2 6.1-2.6s1.6-2.5.5-3.9c-.9-1.1-3.1-1.1-4.5-1.1-3.6.2-6.6.6-9.3.7-1.3.1-1-.8-.5-.8 1.9-.5 4.1-1.6 6-1.9 2.7-.4 5.3.7 6.8-3.4.2-.5.3-1.1.2-1.7-.3-2-2.2-3.1-4.1-3.4-1.5-.1-3 0-4.5.3 2.8-.6 5.8-1.2 7.5-3.6 1.1-1.3 1.1-3.2.1-4.6-1.3-1.7-3.6-1.6-5.7-1.2-3.6.6-7.1 1.5-10.5 2.7-1.2.4-2.3 1.3-3 2.4-.8 1.3-1.5 3.1-.8 4.5.7 1.5 1.9 2.6 3.4 3.2 0 0-3.7 2.3-3.5 5.9.2 2.3 1.3 4.4 2.9 5.9l1.5 5.1z"
                    />
                    <path
                        d="M116.6 242.5c1.7-1.1 3.6-1.9 5.5-2.6 2-.5 4-.9 6-1-.6-.1-1.2-.2-1.8-.1-1.5 0-2.9.3-4.4.7-1.4.4-2.8 1.1-4 1.9-.4.3-.9.7-1.3 1.1zm-3.4-5.7 6.7-5.4c-2.6 1.3-4.9 3.1-6.7 5.4zm34.8 13.8c-.8-2.2-1.2-4.7-1-7.1 0-.4.1-.8.1-1.1l-.4.1h-.1c0 .3-.1.6-.1.9-.2 2.5.2 4.9 1.2 7.2.3.7.6 1.4 1 2 .1.2.3.5.5.7.1 0-.5-1-1.2-2.7zm-13.6-4.8c-.9-.4-1.9-.7-2.9-.7l.6-.3c6.1-2.6 0-7.1-4.1-6.4v.1c1.3-.1 2.6.1 3.7.7 2.7 1.6 3.6 3.5.1 4.9-5.6 2.4-14 2.7-16.2 9.6 1.9-2.9 4.9-4.5 8-5.8 2.1-.5 4.1-1.1 6.2-1.5 1.3-.1 2.6.1 3.7.8 1 .6 1.5 1.6 1.5 2.7-.1.2-.2.3-.4.3-1.6.6-3.2 1.3-4.8 2-1.7.7-3.2 1.6-4.6 2.8l.1.2c3.1-.7 7.3-1.3 10.2-2.8 3-1.2 1.5-5.4-1.1-6.6z"
                        fill={COLORS.YELLOW_200}
                    />
                    <path
                        d="m199.6 322-52.1-52.2c-6.8-6.8-13.6-30.6 18.1-1.1s36.3 25 36.3 25l-2.3 28.3z"
                        fill={COLORS.YELLOW_200}
                        style={{ opacity: 0.5 }}
                    />
                </g>
            </g>
            <g>
                <defs>
                    <path
                        id="SVGID_00000116936029634664180500000003281330155853725116_"
                        d="M204 68.4h76v76h-76z"
                    />
                </defs>
                <clipPath id="SVGID_00000076583640544205105910000001146003984845484204_">
                    <use
                        xlinkHref="#SVGID_00000116936029634664180500000003281330155853725116_"
                        style={{ overflow: 'visible' }}
                    />
                </clipPath>
                <g
                    style={{
                        clipPath: 'url(#SVGID_00000076583640544205105910000001146003984845484204_)'
                    }}>
                    <use
                        xlinkHref="#i-gift"
                        width="76"
                        height="76"
                        x="-38"
                        y="-38"
                        transform="translate(242.04 106.37)"
                        style={{ overflow: 'visible' }}
                    />
                </g>
            </g>
            <g>
                <defs>
                    <path
                        id="SVGID_00000048487722008351273050000004457794137254475961_"
                        d="M11.8 113.8h53.4v54.7H11.8z"
                    />
                </defs>
                <clipPath id="SVGID_00000060022988663288435640000009759591503579944592_">
                    <use
                        xlinkHref="#SVGID_00000048487722008351273050000004457794137254475961_"
                        style={{ overflow: 'visible' }}
                    />
                </clipPath>
                <g
                    style={{
                        clipPath: 'url(#SVGID_00000060022988663288435640000009759591503579944592_)'
                    }}>
                    <use
                        xlinkHref="#i-graph"
                        width="53.4"
                        height="54.7"
                        x="-26.7"
                        y="-27.3"
                        transform="translate(34.92 139.09)"
                        style={{ overflow: 'visible' }}
                    />
                </g>
            </g>
            <g>
                <defs>
                    <path
                        id="SVGID_00000062912090585010327350000000349157589771472284_"
                        d="M200.2 212.5H280v79.8h-79.8z"
                    />
                </defs>
                <clipPath id="SVGID_00000099631536054799908950000018397142725132072104_">
                    <use
                        xlinkHref="#SVGID_00000062912090585010327350000000349157589771472284_"
                        style={{ overflow: 'visible' }}
                    />
                </clipPath>
                <g
                    style={{
                        clipPath: 'url(#SVGID_00000099631536054799908950000018397142725132072104_)'
                    }}>
                    <use
                        xlinkHref="#i-plane"
                        width="79.8"
                        height="79.8"
                        x="-39.9"
                        y="-39.9"
                        transform="translate(240.08 252.37)"
                        style={{ overflow: 'visible' }}
                    />
                </g>
            </g>
            <path
                d="m223.5 157.4 1.7 3 3.1 1.6-3.1 1.6-1.7 3-1.7-3-3.1-1.6 3.1-1.6 1.7-3zm-152 83 1.7 3 3.1 1.6-3.1 1.6-1.7 3-1.7-3-3.1-1.6 3.1-1.6 1.7-3zm-54-78 1.7 3 3.1 1.6-3.1 1.6-1.7 3-1.7-3-3.1-1.6 3.1-1.6 1.7-3z"
                fill={COLORS.NEUTRAL_100}
            />
            <path
                fill={COLORS.WHITE}
                d="m72.5 103.4 1.7 3 3.1 1.6-3.1 1.6-1.7 3-1.7-3-3.1-1.6 3.1-1.6 1.7-3z"
            />
            <g>
                <defs>
                    <path
                        id="SVGID_00000065036301353837600510000012277467519215388036_"
                        d="M24 185h43.1v43.1H24z"
                    />
                </defs>
                <clipPath id="SVGID_00000080203601280243434110000008540970756710698644_">
                    <use
                        xlinkHref="#SVGID_00000065036301353837600510000012277467519215388036_"
                        style={{ overflow: 'visible' }}
                    />
                </clipPath>
                <g
                    style={{
                        clipPath: 'url(#SVGID_00000080203601280243434110000008540970756710698644_)'
                    }}>
                    <use
                        xlinkHref="#i-calendar"
                        width="35.9"
                        height="35.6"
                        x="-18"
                        y="-17.8"
                        transform="matrix(1.2 0 0 1.21 45.46 206.42)"
                        style={{ overflow: 'visible' }}
                    />
                </g>
            </g>
            <g>
                <defs>
                    <path
                        id="SVGID_00000147185679091928375550000008800525350020237979_"
                        d="M216.8 175.1h32.4v38.7h-32.4z"
                    />
                </defs>
                <clipPath id="SVGID_00000091015345210477949210000003125808208937171114_">
                    <use
                        xlinkHref="#SVGID_00000147185679091928375550000008800525350020237979_"
                        style={{ overflow: 'visible' }}
                    />
                </clipPath>
                <g
                    style={{
                        clipPath: 'url(#SVGID_00000091015345210477949210000003125808208937171114_)'
                    }}>
                    <use
                        xlinkHref="#i-apple"
                        width="26"
                        height="31"
                        x="-13"
                        y="-15.5"
                        transform="matrix(1.25 0 0 1.25 232.81 194.44)"
                        style={{ overflow: 'visible' }}
                    />
                </g>
            </g>
        </g>
        <text
            transform="translate(63.51 49.63)"
            fill={COLORS.MAGENTA_300}
            style={{
                letterSpacing: 1,
                fontSize: 32,
                fontFamily: "font-family:'WorkSans-SemiBold'"
            }}>
            {title}
        </text>
    </svg>
);

export default SlideTwoNutrition;
