import { ModalExternalLink } from 'components/elements';
import { useTheme } from 'hooks';
import { PartnerDeliverData } from 'models/types';
import { useCallback, useState } from 'react';

import styles from './Delivery.module.scss';

export type DeliveryProps = {
    delivers: PartnerDeliverData[] | undefined;
    content: { TITLE: string };
};
export const Delivery: React.FC<DeliveryProps> = ({ delivers, content }) => {
    const { darkMode } = useTheme();
    const [showModal, setShowModal] = useState(false);
    const [externalURL, setExternalURL] = useState<string | undefined>(undefined);

    const onClick = useCallback((URL: string) => {
        setExternalURL(URL);
        setShowModal(true);
    }, []);

    if (!delivers) return null;

    return (
        <>
            <section className={`${styles.deliveries} container`}>
                <h2>{content.TITLE}</h2>
                <ul>
                    {delivers.map((d) => (
                        <li key={d.id}>
                            <button onClick={() => onClick(d.url)}>
                                {d.logo && (
                                    <img
                                        src={darkMode ? d.logo.dark_mode : d.logo.light_mode}
                                        alt={d.name}
                                    />
                                )}
                            </button>
                        </li>
                    ))}
                </ul>
            </section>
            <ModalExternalLink show={showModal} setShow={setShowModal} externalURL={externalURL} />
        </>
    );
};
