import axios, { AxiosError } from 'axios';

export const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_NAMESPACE}`
});

/*
 * typing errors on catch clauses
 * @see: https://typescript.tv/best-practices/error-ts1196-catch-clause-variable-type-annotation/
 * @example: if (isAxiosError(error)) console.log(error.response.data));
 */
// eslint-disable-next-line
export const isAxiosError = (candidate: any): candidate is AxiosError =>
    candidate.isAxiosError === true;
