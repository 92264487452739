import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconBurger: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                d="M2552 888h18.47a.76.76 0 1 0 0-1.52H2552a.76.76 0 0 0 0 1.52Zm18.47 16.95H2552a.76.76 0 0 0-.76.76.77.77 0 0 0 .76.77h18.47a.78.78 0 0 0 .77-.77.77.77 0 0 0-.75-.78Zm0-9.24H2552a.77.77 0 0 0 0 1.53h18.47a.77.77 0 1 0 0-1.53Z"
                transform="translate(-2551.26 -886.46)"
            />
        </svg>
    );
};
