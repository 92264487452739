import { LogoZumub } from 'assets/logos';
import {
    ActivityGraphHearts,
    CardAlternative,
    CardAlternativeProps,
    CardToggleV2,
    Carousel,
    CarouselProps,
    Link
} from 'components/elements';
import { permissions } from 'config';
import { CLIENT_AREA, META_TAGS, NUTRITION_HOME } from 'content';
import { useApi, useAuth, useGamification, useLanguage, useTheme } from 'hooks';
import { DailyTipData } from 'models/types';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { COLORS } from 'theme/colors';
import { IconNutritionV1 } from 'theme/icons';

import styles from './Home.module.scss';

export const NutritionHome: React.FC = () => {
    const { validateUserAccessToArea, user } = useAuth();
    const { userProgress } = useGamification();
    const { activeTheme } = useTheme();
    const { getTipOfTheDay, getPromosBanners, getActivities } = useApi();
    const { isPT } = useLanguage();

    const CONTENT = isPT ? NUTRITION_HOME.PT : NUTRITION_HOME.EN;
    const SUBSCRIPTIONS_CONTENT = isPT
        ? CLIENT_AREA.PT.SUBSCRIPTIONS.CARDS.NUTRITION
        : CLIENT_AREA.EN.SUBSCRIPTIONS.CARDS.NUTRITION;

    const [nutritionPromos, setNutritionPromos] = useState<CarouselProps>({} as CarouselProps);
    const [nutritionActivities, setNutritionActivities] = useState<CardAlternativeProps[]>([]);
    const [tip, setTip] = useState<DailyTipData>({} as DailyTipData);

    useEffect(
        () => validateUserAccessToArea(permissions.nutrition.home),
        [validateUserAccessToArea]
    );

    const getTipOfTheDayData = useCallback(async () => {
        const tip = await getTipOfTheDay('nutrition');
        if (tip) setTip(tip);
    }, [getTipOfTheDay]);

    const getPromosData = useCallback(async () => {
        const promos = await getPromosBanners('nutrition');
        setNutritionPromos({
            __typename: 'card-full',
            slides: promos
        });
    }, [getPromosBanners]);

    const getActivitiesData = useCallback(async () => {
        const activities = await getActivities('nutrition');
        setNutritionActivities(activities);
    }, [getActivities]);

    useEffect(() => {
        getPromosData();
    }, [getPromosData]);

    useEffect(() => {
        getActivitiesData();
    }, [getActivitiesData]);

    useEffect(() => {
        getTipOfTheDayData();
    }, [getTipOfTheDayData]);

    if (!user) return null;

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? META_TAGS.PRIVATE.NUTRITION.HOME.PT.TITLE
                        : META_TAGS.PRIVATE.NUTRITION.HOME.EN.TITLE}
                </title>
            </Helmet>
            <section className={`${styles.intro} container`}>
                <h1>{CONTENT.INTRO.TITLE}</h1>
                <IconNutritionV1
                    colorType="SOLID"
                    variation="OUTLINE"
                    width={20}
                    height={24}
                    color={COLORS.WHITE}
                />
            </section>
            <section className={`${styles.partner} container`}>
                <h2>{CONTENT.PARTNERS.TITLE}</h2>
                <p>{CONTENT.PARTNERS.DESCRIPTION}</p>
                <CardToggleV2
                    logo={<LogoZumub color="negative" width={200} height={50} />}
                    content={SUBSCRIPTIONS_CONTENT.DESCRIPTION}
                    area="nutrition"
                />
            </section>
            <section className={`${styles.points} container`}>
                <h2>{CONTENT.POINTS.TITLE}</h2>
                <ActivityGraphHearts
                    progress={userProgress(user)}
                    area="nutrition"
                    className={styles.graph}
                />
                <Link
                    to="/pontos"
                    label="Saiba Mais"
                    size="xs"
                    type="button"
                    className={styles.button}
                />
            </section>
            {tip.id && (
                <section className={`${styles.tip} ${styles[activeTheme]} container`}>
                    <p>{CONTENT.TIP.TITLE}</p>
                    <h2>{isPT ? tip.content.pt : tip.content.en}</h2>
                </section>
            )}
            <section className={`${styles.promos} container`}>
                <h2>{CONTENT.PROMOS.TITLE}</h2>
                <Carousel {...nutritionPromos} />
                {/* <p className={styles.note}>
                    {CONTENT.PROMOS.NOTE}
                    <a href={ZUMUB.LINK.PT} target="_blank" rel="noopener noreferrer">
                        ZUMUB
                    </a>
                </p> */}
            </section>
            {nutritionActivities.length > 0 && (
                <section className={`${styles.activities} container`}>
                    <h2>
                        {nutritionActivities.length > 1
                            ? CONTENT.ACTIVITIES.TITLE.PLURAL
                            : CONTENT.ACTIVITIES.TITLE.SINGULAR}
                    </h2>
                    <p>{CONTENT.ACTIVITIES.DESCRIPTION}</p>
                    {nutritionActivities.map((activity) => (
                        <CardAlternative key={activity.slug} {...activity} />
                    ))}
                </section>
            )}
        </>
    );
};
