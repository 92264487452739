import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconPlus: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                d="M2601.15 895.93a.77.77 0 0 0-.52-.24h-8.52v-8.47a.77.77 0 0 0-1.53 0v8.47h-8.48a.77.77 0 0 0-.53.24.74.74 0 0 0-.21.54.76.76 0 0 0 .75.75h8.47v8.47a.77.77 0 1 0 1.53 0v-8.47h8.48a.76.76 0 0 0 .77-.74.79.79 0 0 0-.21-.55Z"
                transform="translate(-2581.36 -886.46)"
            />
        </svg>
    );
};
