import { useAuth, useLanguage, useTheme } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Joyride, { Step } from 'react-joyride';
import { COLORS } from 'theme';

export const Tour: React.FC = () => {
    const { user } = useAuth();
    const { darkMode } = useTheme();
    const { isPT } = useLanguage();

    const [run, setRun] = useState(false);

    const triggerTour = useCallback(() => {
        const token = localStorage.getItem('@hi:tour');
        if (token) return;
        setTimeout(() => setRun(true), 2000);
    }, []);

    // `########-####-####-####-2a5a2162e844` -> new `Promos` area
    const handleCallback = useCallback((data) => {
        const { status } = data;
        if (status !== 'finished' || !process.env.REACT_APP_TOUR_TOKEN) return;
        localStorage.setItem('@hi:tour', process.env.REACT_APP_TOUR_TOKEN);
    }, []);

    useEffect(triggerTour);

    // const STEPS: Step[] = useMemo(
    //     () => [
    //         {
    //             title: `Hi! ${user?.name.first ?? ''}`,
    //             content: 'Bem-vindo/a à app Hi! Vamos dar uma volta?',
    //             target: 'body',
    //             placement: 'center'
    //         },
    //         {
    //             title: 'Esta é a navbar',
    //             content: 'A forma mais rápida de aceder às páginas iniciais das áreas Hi!',
    //             target: '.tour-step-2',
    //             placement: 'auto',
    //             isFixed: true
    //         },
    //         {
    //             title: 'Main Areas',
    //             content: 'Ao selecionar cada botão acede a cada uma das áreas principais',
    //             target: '.tour-step-3',
    //             placement: 'auto',
    //             isFixed: true
    //         },
    //         {
    //             title: 'Menu',
    //             content: 'Aceda ao menu e conheça todas as áreas da app Hi!',
    //             target: '.tour-step-4',
    //             placement: 'auto'
    //         },
    //         {
    //             title: 'Agora é consigo',
    //             content: 'Explore a app Hi!, comece a ganhar pontos e melhore a sua vida',
    //             target: 'body',
    //             placement: 'center'
    //         }
    //     ],
    //     [user?.name.first]
    // );

    const STEPS: Step[] = useMemo(
        () => [
            {
                title: `Hi! ${user?.name.first ?? ''}`,
                content: isPT
                    ? 'Encontre a sua próxima oferta na nova área de promoções Hi!'
                    : 'Find your next offer in the new Hi! promotional area',
                target: '.tour-nav-promos',
                placement: 'auto'
            }
        ],
        [isPT, user?.name.first]
    );

    /**
     * @see https://docs.react-joyride.com/styling
     */

    return (
        <Joyride
            steps={STEPS}
            continuous
            run={run}
            locale={{
                back: isPT ? 'anterior' : 'back',
                close: isPT ? 'fechar' : 'close',
                last: isPT ? 'fechar' : 'close',
                next: isPT ? 'seguinte' : 'next',
                open: isPT ? 'abrir' : 'open',
                skip: isPT ? 'continuar' : 'continue'
            }}
            callback={handleCallback}
            styles={{
                options: {
                    arrowColor: darkMode ? COLORS.BLUISH_PURPLE_800 : COLORS.WHITE,
                    backgroundColor: darkMode ? COLORS.BLUISH_PURPLE_800 : COLORS.WHITE,
                    beaconSize: 32,
                    overlayColor: 'rgba(0, 0, 0, 0.7)',
                    primaryColor: COLORS.PURPLE_300,
                    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
                    textColor: darkMode ? COLORS.WHITE : COLORS.NEUTRAL_800,
                    width: undefined,
                    zIndex: 100
                },
                beacon: { outline: 'none' },
                beaconInner: {
                    backgroundColor: darkMode ? COLORS.MAGENTA_400 : COLORS.PURPLE_500
                },
                tooltip: { borderRadius: '1.6rem' },
                buttonNext: {
                    width: '100%',
                    fontSize: '14px',
                    fontWeight: 600,
                    textTransform: 'capitalize',
                    outline: 'none',
                    borderRadius: '0.6rem',
                    color: darkMode ? COLORS.PURPLE_200 : COLORS.MAGENTA_500,
                    backgroundColor: darkMode ? COLORS.PURPLE_700 : COLORS.MAGENTA_50
                },
                buttonBack: {
                    width: '100%',
                    fontSize: '14px',
                    fontWeight: 600,
                    textTransform: 'capitalize',
                    color: darkMode ? COLORS.PURPLE_200 : COLORS.MAGENTA_500,
                    backgroundColor: darkMode ? COLORS.PURPLE_700 : COLORS.MAGENTA_50,
                    borderRadius: '0.6rem'
                },
                buttonClose: { color: darkMode ? COLORS.PURPLE_200 : COLORS.PURPLE_400 }
            }}
            // showProgress
        />
    );
};
