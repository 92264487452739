import { Avatar } from 'components/elements';
import format from 'date-fns/format';
import PTLocale from 'date-fns/locale/pt';
import { useTheme } from 'hooks';
import { AreaType } from 'models/types';
import { IconStar } from 'theme/icons';

import styles from './CardReview.module.scss';

export type CardReviewProps = React.HTMLProps<HTMLDivElement> & {
    photo: string | null;
    name: string;
    comment: string;
    date: string;
    rating: 1 | 2 | 3 | 4 | 5;
    area?: AreaType;
};

export const CardReview: React.FC<CardReviewProps> = ({
    photo,
    name,
    comment,
    date,
    rating,
    area = 'movement',
    ...props
}) => {
    const { activeTheme } = useTheme();
    return (
        <div className={`${styles.card} ${styles[area]} ${styles[activeTheme]}`} {...props}>
            <Avatar source={photo} alt={name} />
            <div className={styles.rating}>
                <IconStar className={rating >= 1 ? styles.on : undefined} />
                <IconStar className={rating >= 2 ? styles.on : undefined} />
                <IconStar className={rating >= 3 ? styles.on : undefined} />
                <IconStar className={rating >= 4 ? styles.on : undefined} />
                <IconStar className={rating >= 5 ? styles.on : undefined} />
            </div>
            <p className={styles.comment}>{comment}</p>
            <span className={styles.label}>
                {name}
                {' • '}
                {format(new Date(date), 'd MMM yyyy', {
                    locale: PTLocale
                })}
            </span>
        </div>
    );
};
