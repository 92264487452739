import { Avatar, CardReview, Carousel, Tag } from 'components/elements';
import { permissions } from 'config';
import { META_TAGS, MOVEMENT_COACHES_PROFILE } from 'content';
import { useApi, useAuth, useGamification, useLanguage, useTheme } from 'hooks';
import { CoachData } from 'models/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import {
    IconFacebook,
    IconInstagram,
    IconLinkedIn,
    IconMail,
    IconPhone,
    IconSkype,
    IconStar,
    IconWhatsapp,
    IconYoutube
} from 'theme/icons';

import styles from './CoachProfile.module.scss';

export const CoachProfile: React.FC = () => {
    const { activeTheme } = useTheme();
    const { slug } = useParams();
    const { isPT } = useLanguage();
    const { validateUserAccessToArea } = useAuth();
    const { reportInteraction } = useGamification();
    const { getStaff } = useApi();

    const [trainer, setTrainer] = useState<CoachData | undefined>(undefined);

    const CONTENT = isPT ? MOVEMENT_COACHES_PROFILE.PT : MOVEMENT_COACHES_PROFILE.EN;

    useEffect(
        () => validateUserAccessToArea(permissions.movement.coaches),
        [validateUserAccessToArea]
    );

    const getTrainer = useCallback(async () => {
        const coaches = await getStaff('coaches');
        const coach = coaches.find((c) => c.slug === slug);
        if (coach) setTrainer(coach);
    }, [getStaff, slug]);

    const sendInteraction = useCallback(() => {
        if (!trainer) return;

        reportInteraction({
            interaction: 'sport_coaches',
            area: 'movement',
            id: trainer.id
        });
    }, [trainer, reportInteraction]);

    useEffect(() => {
        getTrainer();
    }, [getTrainer]);

    const trainerName = useMemo(
        () =>
            `${trainer?.name.split(' ')[0]} ${
                trainer?.name.split(' ')[trainer?.name.split(' ').length - 1]
            }`,
        [trainer?.name]
    );

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? `${trainerName} ${META_TAGS.PRIVATE.MOVEMENT.COACH_PROFILE.PT.TITLE}`
                        : `${trainerName} ${META_TAGS.PRIVATE.MOVEMENT.COACH_PROFILE.EN.TITLE}`}
                </title>
            </Helmet>
            {trainer && (
                <section className={`${styles.intro} container`}>
                    <span className={styles.preTitle}>{CONTENT.PRE_TITLE}</span>
                    <h1>{trainer.name}</h1>
                    <div className={styles.rating}>
                        <IconStar className={trainer.rating >= 1 ? styles.active : undefined} />
                        <IconStar className={trainer.rating >= 2 ? styles.active : undefined} />
                        <IconStar className={trainer.rating >= 3 ? styles.active : undefined} />
                    </div>
                    <Avatar
                        source={trainer.images.profile_2x || trainer.images.profile}
                        alt={trainer.name}
                        className={styles.avatar}
                        width={120}
                        height={120}
                    />
                    <span className={styles.preTitle}>
                        {trainer.locations.map((local, i) => (
                            <span key={i.toString()}>
                                {local}
                                {trainer.locations.length > i + 1 ? ', ' : ''}
                            </span>
                        ))}
                    </span>
                    <h3>
                        {trainer.platforms.map((platform, i) => (
                            <span key={i.toString()}>
                                {platform} {trainer.platforms.length > i + 1 ? ' · ' : ''}
                            </span>
                        ))}
                    </h3>
                    <div className={styles.buttonsContainer}>
                        <div className={styles.languages}>
                            {trainer.languages.map((lang, i) => (
                                <Tag
                                    key={i.toString()}
                                    area="movement"
                                    square={true}
                                    tagSize="sm"
                                    className={styles.tag}>
                                    {lang.slice(0, 2)}
                                </Tag>
                            ))}
                        </div>
                        <div className={styles.social}>
                            {trainer.social.facebook && (
                                <a
                                    href={trainer.social.facebook}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <Tag
                                        area="movement"
                                        square={true}
                                        tagSize="sm"
                                        className={styles.tag}>
                                        <IconFacebook />
                                    </Tag>
                                </a>
                            )}
                            {trainer.social.instagram && (
                                <a
                                    href={trainer.social.instagram}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <Tag
                                        area="movement"
                                        square={true}
                                        tagSize="sm"
                                        className={styles.tag}>
                                        <IconInstagram />
                                    </Tag>
                                </a>
                            )}
                            {trainer.social.youtube && (
                                <a
                                    href={trainer.social.youtube}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <Tag
                                        area="movement"
                                        square={true}
                                        tagSize="sm"
                                        className={styles.tag}>
                                        <IconYoutube />
                                    </Tag>
                                </a>
                            )}
                            {trainer.social.linkedin && (
                                <a
                                    href={trainer.social.linkedin}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <Tag
                                        area="movement"
                                        square={true}
                                        tagSize="sm"
                                        className={styles.tag}>
                                        <IconLinkedIn />
                                    </Tag>
                                </a>
                            )}
                        </div>
                    </div>
                    {trainer.bio && (
                        <div className={styles.bio}>
                            <h3>{trainer.bio.replace(/"/gi, ' ')}</h3>
                            <h2>{CONTENT.QUALIFICATIONS}</h2>
                            <p>{isPT ? trainer.qualifications.pt : trainer.qualifications.en}</p>
                        </div>
                    )}
                </section>
            )}
            {trainer?.tags && (
                <section className={`${styles.specialty} container`}>
                    <h2>{CONTENT.TAGS.TITLE}</h2>
                    <div className={styles.container}>
                        {trainer.tags.map((tag, i) => (
                            <Tag key={i.toString()} theme={activeTheme}>
                                {isPT ? tag.pt : tag.en}
                            </Tag>
                        ))}
                    </div>
                </section>
            )}
            {trainer && trainer?.services.length > 0 && (
                <section className={`${styles.services} container`}>
                    <h2>{CONTENT.SCHEDULE.TITLE}</h2>
                    <Carousel
                        __typename="card-booking"
                        slides={trainer.services}
                        slidesPerView={2}
                        className={styles.carousel}
                    />
                </section>
            )}
            {trainer?.contacts && (
                <section className={`${styles.contacts} container`}>
                    <h2>{CONTENT.CONTACTS.TITLE}</h2>
                    <div className={styles.container}>
                        {trainer.contacts.phone && (
                            <a
                                href={`tel:${trainer.contacts.phone}`.trim()}
                                className={styles.icon}
                                onClick={sendInteraction}>
                                <IconPhone />
                            </a>
                        )}
                        {trainer.contacts.skype && (
                            <a
                                href={`skype:${trainer.contacts.skype}?chat`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.icon}
                                onClick={sendInteraction}>
                                <IconSkype />
                            </a>
                        )}
                        {trainer.contacts.whatsapp && (
                            <a
                                href={`https://wa.me/${trainer.contacts.whatsapp}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.icon}
                                onClick={sendInteraction}>
                                <IconWhatsapp />
                            </a>
                        )}
                        {trainer.contacts.email && (
                            <a
                                href={`mailto:${trainer.contacts.email}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.icon}
                                onClick={sendInteraction}>
                                <IconMail />
                            </a>
                        )}
                    </div>
                </section>
            )}
            {trainer && trainer?.comments.length > 0 && (
                <section className={`${styles.comments} container`}>
                    <h2>{CONTENT.COMMENTS.TITLE}</h2>
                    {trainer.comments.map((comment) => (
                        <CardReview key={comment.id.toString()} {...comment} />
                    ))}
                </section>
            )}
        </>
    );
};
