export const LOGIN = {
    PT: {
        TITLE: 'Acesso',
        LABELS: {
            EMAIL: 'e-mail',
            PASSWORD: 'palavra-passe',
            SUBMIT: 'Entrar'
        },
        LINKS: {
            RECOVER: 'Recuperar Password',
            NOT_CLIENT: ['Não é cliente? ', 'Faça uma simulação']
        },
        VALIDATION_MESSAGES: {
            EMAIL: ['email obrigatório', 'verifique o email inserido'],
            PASSWORD: 'password obrigatória'
        },
        MODAL: {
            TITLE: 'Os dados inseridos não estão corretos',
            DESCRIPTION: [
                {
                    TEXT: 'Verifique se está a inserir todos os dados corretamente e ',
                    BUTTON: 'tente novamente.'
                },
                {
                    TEXT: 'Ou solicite uma ',
                    BUTTON: 'nova password.'
                }
            ],
            BUTTON: {
                CLOSE: 'Fechar'
            }
        }
    },
    EN: {
        TITLE: 'Login',
        LABELS: {
            EMAIL: 'e-mail',
            PASSWORD: 'password',
            SUBMIT: 'Enter'
        },
        LINKS: {
            RECOVER: 'Recover Password',
            NOT_CLIENT: ['Not a client? ', 'Make a simulation']
        },
        VALIDATION_MESSAGES: {
            EMAIL: ['email required', 'check entered email'],
            PASSWORD: ''
        },
        MODAL: {
            TITLE: 'Looks like your details are incorrect',
            DESCRIPTION: [
                {
                    TEXT: 'Please check if you are typing all details correctly and ',
                    BUTTON: 'try again.'
                },
                {
                    TEXT: 'Or request a ',
                    BUTTON: 'new password.'
                }
            ],
            BUTTON: {
                CLOSE: 'Close'
            }
        }
    }
};
