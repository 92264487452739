import { LogoHi } from 'assets/logos';
import { Button, InputPassword, LangSelect, Modal } from 'components/elements';
import { toastOptions } from 'config';
import { META_TAGS, PASSWORD_RESET } from 'content';
import { useFormik } from 'formik';
import { useApi, useAuth, useLanguage } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isAxiosError } from 'services';
import { COLORS } from 'theme';
import { IconCheck } from 'theme/icons';
import * as Yup from 'yup';

import styles from '../../Access.module.scss';

export type PasswordResetProps = React.HTMLProps<HTMLDivElement>;

type FormValues = {
    password: string;
    confirm: string;
};

export const PasswordReset: React.FC<PasswordResetProps> = (props) => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const params = useParams();
    const { postNewUserPassword } = useApi();
    const { isPT } = useLanguage();

    const CONTENT = isPT ? PASSWORD_RESET.PT : PASSWORD_RESET.EN;

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (user) navigate('/inicio');
    }, [navigate, user]);

    const initialValues = { password: '', confirm: '' };

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required(CONTENT.VALIDATION_MESSAGES.PASSWORD[0])
            .min(6, CONTENT.VALIDATION_MESSAGES.PASSWORD[1]),
        confirm: Yup.string().oneOf(
            [Yup.ref('password'), null],
            CONTENT.VALIDATION_MESSAGES.CONFIRM
        )
    });

    const onSubmit = useCallback(
        async (values: FormValues) => {
            try {
                const { token } = params;
                await postNewUserPassword(values.password, false, token);
                setShowModal(true);
            } catch (error) {
                if (isAxiosError(error) && error.response?.data.error === 'wrong_token')
                    toast.dark(CONTENT.VALIDATION_MESSAGES.EXPIRED_TOKEN, toastOptions);
            }
        },
        [CONTENT.VALIDATION_MESSAGES.EXPIRED_TOKEN, params, postNewUserPassword]
    );

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    return (
        <>
            <Helmet>
                <title>
                    {isPT ? META_TAGS.PUBLIC.RESET.PT.TITLE : META_TAGS.PUBLIC.RESET.EN.TITLE}
                </title>
            </Helmet>
            <div className={styles.container} {...props}>
                <RouterLink to="/">
                    <LogoHi animate showLettering={false} width={72} className={styles.logo} />
                </RouterLink>
                <span>
                    <h1>{CONTENT.TITLE}</h1>
                    <p>{CONTENT.DESCRIPTION}</p>
                </span>
                <form onSubmit={formik.handleSubmit} className={styles.form}>
                    <InputPassword
                        label={CONTENT.LABELS.NEW_PASSWORD}
                        hasError={formik.touched.password && formik.errors.password ? true : false}
                        errorMessage={formik.errors.password}
                        {...formik.getFieldProps('password')}
                    />
                    <InputPassword
                        label={CONTENT.LABELS.CONFIRM_PASSWORD}
                        hasError={formik.touched.confirm && formik.errors.confirm ? true : false}
                        errorMessage={formik.errors.confirm}
                        {...formik.getFieldProps('confirm')}
                    />
                    <Button type="submit" label={CONTENT.LABELS.SUBMIT} size="lg" fullWidth />
                </form>
                <p className={styles.register} />
                <LangSelect className={styles['lang-button']} />
            </div>
            <Modal
                show={showModal}
                footer={{ enable: false }}
                className={[styles.modal, styles.success].join(' ')}
                background={{ color: COLORS.WHITE }}
                overlay={{ style: 'dark' }}
                close={{ handler: () => setShowModal(false), buttonColor: COLORS.GREEN_400 }}>
                <IconCheck color={COLORS.GREEN_400} width={64} height={56} />
                <h2>{CONTENT.MODAL.TITLE}</h2>
                <p>{CONTENT.MODAL.DESCRIPTION[0].TEXT}.</p>
                <Button
                    label={CONTENT.MODAL.BUTTON.CLOSE}
                    className={styles.button}
                    size="md"
                    onClick={() => navigate('/login')}
                />
            </Modal>
        </>
    );
};
