export const PWA_INSTALLER = {
    PT: {
        DESCRIPTION:
            'Instale a aplicação no seu dispositivo para aceder facilmente a qualquer momento. Sem download. Sem incómodos.',
        INSTRUCTIONS: {
            ONE: ['1. Toque em'],
            TWO: ['2. Selecione ', 'Adicionar ao ecrã inicial']
        },
        BUTTON_LABEL: {
            CLOSE: 'fechar',
            DONT_SHOW: 'Não mostrar novamente'
        }
    },
    EN: {
        DESCRIPTION:
            'Install the app on your device to easily access it anytime. No download. No hassle.',
        INSTRUCTIONS: {
            ONE: ['1. Tap on'],
            TWO: ['2. Select ', 'Add to Home Screen']
        },
        BUTTON_LABEL: {
            CLOSE: 'close',
            DONT_SHOW: "Don't show again"
        }
    }
};
