import { Button, Modal } from 'components/elements';
import { ANIMATION_HEART, ANIMATION_TROPHY_1, ANIMATION_TROPHY_2 } from 'constants/images';
import { useMemo } from 'react';
import { COLORS } from 'theme';
import { getRandomInt } from 'utils';

import styles from './PointsModal.module.scss';

type PointsModalProps = {
    pointsGained: number;
    setPointsGained: React.Dispatch<React.SetStateAction<number>>;
};

export const PointsModal: React.FC<PointsModalProps> = ({ pointsGained, setPointsGained }) => {
    const animationIcon = useMemo(() => {
        const index = getRandomInt(0, 3);
        return [ANIMATION_HEART, ANIMATION_TROPHY_1, ANIMATION_TROPHY_2][index];
    }, []);

    return (
        <Modal
            show={pointsGained > 0}
            footer={{ enable: false }}
            close={{ handler: () => setPointsGained(0), buttonColor: COLORS.MAGENTA_300 }}
            background={{ color: COLORS.WHITE }}
            className={styles.modal}>
            <strong>Parabéns!</strong>
            <img src={animationIcon} alt="animation" />
            <strong>
                Ganhou
                <br />
                {pointsGained} pontos
            </strong>
            <Button
                label="Fechar"
                size="md"
                variation="outline"
                area="nutrition"
                onClick={() => setPointsGained(0)}
                className={styles.button}
            />
        </Modal>
    );
};
