/**
 *
 * @param hex
 * @param opacity
 * @returns
 */

type HexToRgbA = (hex: string | undefined, opacity: number) => string | undefined;

export const hexToRgbA: HexToRgbA = (hex, opacity) => {
    if (!hex) return undefined;

    let c;

    const isValid = /^#([A-Fa-f0-9]{3}){1,2}$/.test(hex);

    // if (!isValid) throw new Error('Bad Hex');
    if (!isValid) return '#000000';

    c = hex.substring(1).split('');

    if (c.length === 3) c = [c[0], c[0], c[1], c[1], c[2], c[2]];

    c = `0x${c.join('')}`;

    return `rgba(${[(Number(c) >> 16) & 255, (Number(c) >> 8) & 255, Number(c) & 255].join(
        ','
    )},${opacity})`;
};
