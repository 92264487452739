import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconWhatsapp: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path d="M19.85 8.22a10 10 0 0 0-8.4-8.14L10.73 0H9.48c-.37 0-.76.09-1.13.15A9.89 9.89 0 0 0 .2 11.52a10.05 10.05 0 0 0 1.12 3.21.37.37 0 0 1 0 .29C.9 16.61.46 18.19.03 19.78a1.62 1.62 0 0 0-.06.22l.5-.13 4.6-1.19a.47.47 0 0 1 .33 0 10 10 0 0 0 13.5-4.32 11.57 11.57 0 0 0 .5-1.14 9.59 9.59 0 0 0 .53-2.53 1.16 1.16 0 0 1 0-.18V9.26a4.7 4.7 0 0 0-.08-1.04Zm-8 9.71a8.17 8.17 0 0 1-6.14-1 .45.45 0 0 0-.36-.06l-2.73.72h-.2l.25-1 .53-1.9a.33.33 0 0 0-.05-.32 8.11 8.11 0 0 1-1.37-4.63 8.28 8.28 0 1 1 10.06 8.14Zm3-5.9-1.86-.82-.17-.05a.36.36 0 0 0-.5.16l-.7.86a.43.43 0 0 1-.61.16 6.81 6.81 0 0 1-3.34-2.88c-.16-.27-.15-.35.05-.6l.57-.74a.53.53 0 0 0 0-.57l-.52-1.22-.31-.71c-.11-.23-.16-.3-.35-.3a4.09 4.09 0 0 0-.7 0 .71.71 0 0 0-.57.28 6 6 0 0 0-.67 1 2.9 2.9 0 0 0 .13 2.49 11.55 11.55 0 0 0 5.5 5.1 5 5 0 0 0 1.86.44 2.59 2.59 0 0 0 1.46-.42 1.91 1.91 0 0 0 .95-1.76.41.41 0 0 0-.26-.42Z" />
        </svg>
    );
};
