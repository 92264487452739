import { useTheme } from 'hooks';
import { useMemo } from 'react';
import { COLORS } from 'theme/colors';
import { IconAreaProps } from 'theme/icons';

export const IconHomeV1: React.FC<IconAreaProps> = ({
    color,
    width = 75,
    height = 70,
    variation,
    colorType,
    ...props
}) => {
    const { darkMode } = useTheme();

    const defaultColor = useMemo(() => (darkMode ? COLORS.NEUTRAL_800 : COLORS.WHITE), [darkMode]);
    const selectedColor = useMemo(() => color ?? defaultColor, [color, defaultColor]);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 75 70"
            width={width}
            height={height}
            {...props}>
            <defs>
                <linearGradient
                    id="home-linear-gradient"
                    x1="3387.17"
                    y1="1537.19"
                    x2="3457.17"
                    y2="1537.19"
                    gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor={COLORS.PURPLE_600} />
                    <stop offset="1" stopColor={COLORS.MAGENTA_500} />
                </linearGradient>
            </defs>
            {variation === 'FILL' && (
                <path
                    id="fill"
                    fill={colorType === 'GRADIENT' ? 'url(#home-linear-gradient)' : selectedColor}
                    d="M3430 1505.35a11.09 11.09 0 0 0-15.46 0l-27.32 26.79 2.49 2.51 5.39-5.28v34.58a8.3 8.3 0 0 0 8.33 8.27h2.57a8.3 8.3 0 0 0 8.33-8.27v-11.24a4.78 4.78 0 0 1 4.79-4.75h6.24a4.77 4.77 0 0 1 4.79 4.75v11.24a8.3 8.3 0 0 0 8.33 8.27h2.52a8.31 8.31 0 0 0 8.34-8.27v-34.41l5.23 5.17.07.08 1.32-1.19 1.17-1.33Z"
                    transform="translate(-3387.17 -1502.19)"
                />
            )}
            {variation === 'OUTLINE' && (
                <path
                    id="stroke"
                    fill={colorType === 'GRADIENT' ? 'url(#home-linear-gradient)' : selectedColor}
                    d="M3430 1505.35a11.09 11.09 0 0 0-15.46 0l-27.32 26.79 2.49 2.51 5.39-5.28v34.58a8.3 8.3 0 0 0 8.33 8.27h2.57a8.3 8.3 0 0 0 8.33-8.27v-11.24a4.78 4.78 0 0 1 4.79-4.75h6.24a4.77 4.77 0 0 1 4.79 4.75v11.24a8.3 8.3 0 0 0 8.33 8.27h2.52a8.31 8.31 0 0 0 8.34-8.27v-34.41l5.23 5.17.07.08 1.32-1.19 1.17-1.33Zm15.88 58.57a4.76 4.76 0 0 1-4.78 4.75h-2.6a4.77 4.77 0 0 1-4.78-4.75v-11.23a8.31 8.31 0 0 0-8.33-8.28h-6.25a8.31 8.31 0 0 0-8.33 8.28v11.23a4.77 4.77 0 0 1-4.79 4.75h-2.58a4.78 4.78 0 0 1-4.79-4.75v-38.06l18.39-18a7.53 7.53 0 0 1 10.47 0l18.38 18.16Z"
                    transform="translate(-3387.17 -1502.19)"
                />
            )}
        </svg>
    );
};
