import { Partner } from '../../types';

export const PARTNER: Partner = {
    PT: {
        HEADER: {
            TITLE: 'Promos'
        },
        PROMO_LIST: {
            TITLE: 'Promoções ativas',
            DESCRIPTION: 'Consulta aqui todas as promoções', // concat with partner name
            BUTTON_LABEL: 'Saiba mais',
            VOUCHER: {
                BUTTON_LABEL: 'Ativar Oferta',
                SUCCESS: {
                    SHARE_MESSAGE: [
                        'Não se esqueça de partilhar a sua experiência',
                        'com os seus amigos!'
                    ],
                    SHARE_BUTTON: 'Partilhar',
                    CLOSE_BUTTON: 'Fechar'
                },
                REDEEM: {
                    CONFIRM: {
                        MESSAGE:
                            '<p><b>Esta oferta é de utilização única. Deseja ativar agora?</b></p>',
                        BUTTON_LABEL: 'Sim'
                    },
                    INVALID: {
                        TITLE: 'Ups!',
                        MESSAGE: 'O seu voucher já foi resgatado.'
                    },
                    CANCEL: {
                        BUTTON_LABEL: 'Cancelar'
                    }
                }
            }
        },
        EXTERNAL_URL: (partner) =>
            `Ao selecionar o botão será redirecionado/a para o website <b>${partner}</b>`,
        DELIVERIES: {
            TITLE: 'Entregas'
        },
        BOOKING: {
            TITLE: 'Reservas online',
            MESSAGE: 'Caso desejes fazer a tua reserva online, clica no link.',
            BUTTON_LABEL: 'BOOK-IN'
        }
    },
    EN: {
        HEADER: {
            TITLE: 'Promos'
        },
        PROMO_LIST: {
            TITLE: 'Active Promotions',
            DESCRIPTION: 'Consult all promotions from', // concat with partner name
            BUTTON_LABEL: 'Find out more',
            VOUCHER: {
                BUTTON_LABEL: 'Activate Offer',
                SUCCESS: {
                    SHARE_MESSAGE: ["Don't forget to share your", 'experience with your friends!'],
                    SHARE_BUTTON: 'Share',
                    CLOSE_BUTTON: 'Close'
                },
                REDEEM: {
                    CONFIRM: {
                        MESSAGE:
                            '<p><b>This offer is for one-time use only. Would you like to activate now?</b></p>',
                        BUTTON_LABEL: 'Yes'
                    },
                    INVALID: {
                        TITLE: 'Oops!',
                        MESSAGE: 'Your voucher has already been redeemed.'
                    },
                    CANCEL: {
                        BUTTON_LABEL: 'Cancel'
                    }
                }
            }
        },
        EXTERNAL_URL: (partner) =>
            `By selecting the button you will be redirected to <b>${partner}</b> website`,
        DELIVERIES: {
            TITLE: 'Deliveries'
        },
        BOOKING: {
            TITLE: 'Online Booking',
            MESSAGE: 'If you wish to make your reservation online, click on the link.',
            BUTTON_LABEL: 'BOOK-IN'
        }
    }
};
