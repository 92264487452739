import { COLORS } from 'theme';

export type IllustrationProps = React.HtmlHTMLAttributes<HTMLOrSVGElement> & { title?: string };

export const SlideThreeHealth: React.FC<IllustrationProps> = ({ title = 'Saúde' }) => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 280 336"
        xmlSpace="preserve">
        <symbol id="i-calendar" viewBox="-18 -17.8 35.9 35.6">
            <path
                fill={COLORS.PURPLE_300}
                d="M-10.7 3.3c-.1-.3 0-.6.1-.8.1-.3.4-.5.7-.5L-.5-.7c.4-.1.8 0 1.1.3.3.3.4.7.3 1-.2.3-.5.6-.8.7L-9.3 4c-.3.1-.6 0-.8-.1a.9.9 0 0 1-.6-.6zm22-17.7 6.6 23c.1.3 0 .6-.1.8-.1.3-.4.4-.7.5L-10 17.7c-.3.1-.6 0-.8-.1-.3-.1-.4-.4-.5-.7l-6.7-23c-.1-.3 0-.6.1-.8.1-.3.4-.4.6-.5l4.2-1.2-.9-3.1c-.2-.6.1-1.2.7-1.3.2-.1.4-.1.6 0 .4.1.7.4.8.8l.9 3.1 14.6-4.2-.9-3.1c-.1-.4 0-.8.2-1.1.4-.4 1.1-.4 1.5 0 .1.1.2.3.3.5l.9 3.1 4.2-1.2c.3-.1.6 0 .8.1.5.1.7.4.7.6zm-.2 6.9L-14-.3l4.5 15.7 25.1-7.2-4.5-15.7zM-7.3 11.3 7.4 7.2c.3-.1.6-.4.7-.8.1-.6-.2-1.2-.8-1.3h-.6L-7.9 9.3c-.3.1-.6.4-.7.7-.1.4 0 .7.3 1 .3.3.7.4 1 .3z"
            />
        </symbol>
        <symbol id="i-card" viewBox="-25.5 -19.3 51 38.6">
            <path
                fill={COLORS.BLUE_200}
                d="M-21.4-10.9c-2.8.6-4.6 3.1-4 5.6l4.9 21.2c.6 2.4 3.3 3.9 6 3.3L21.4 11c2.8-.6 4.5-3.1 4-5.6l-4.9-21.2c-.8-2.6-3.5-4-6.1-3.3l-35.8 8.2zm.6 2.5 35.9-8.2c1-.3 2.2.2 2.5 1.3v.1l.4 1.9-40.2 9.2-.3-1.9c-.2-1.1.5-2.2 1.7-2.4 0 .1 0 0 0 0zm.2 11 40.2-9.2L22.5 6c.2 1.1-.5 2.1-1.6 2.4h-.1l-35.9 8.2c-1.1.3-2.2-.3-2.6-1.4l-2.9-12.6zM6.9-.3C4 .4 2.2 3 2.8 5.5S6.1 9.6 9 8.9a6 6 0 0 0 2.5-1.3c.9.3 1.9.3 2.8.1 2.9-.7 4.7-3.3 4.1-5.8S15-2.2 12.2-1.5c-.9.2-1.8.6-2.5 1.2-.9-.2-1.9-.2-2.8 0zm-22 6.2c-.4.1-.7.3-.9.6-.4.5-.3 1.3.2 1.7.1 0 .1.1.2.1.3.2.7.2 1.1.1l10-2.3c.4-.1.8-.3 1-.6.2-.3.3-.7.2-1-.1-.3-.4-.6-.7-.8-.3-.2-.7-.2-1.1-.1l-10 2.3zM7.5 2.2c.2 0 .4-.1.7-.1v.1c-.1.3-.1.6-.2.8v.2c0 .3 0 .7.1 1 .1.3.2.6.3 1l.1.2c.2.2.4.4.5.6 0 0 0 .1.1.1-.2.1-.4.2-.6.2-1.2.4-2.4-.3-2.8-1.5-.2-1.2.6-2.3 1.8-2.6zM12.8 1c1.2-.4 2.4.3 2.8 1.5.2 1.2-.6 2.4-1.8 2.6-.6.1-1.2.1-1.7-.2H12c-.4-.2-.7-.5-.8-.9-.1-.1-.2-.3-.2-.4-.1-.6 0-1.1.3-1.6v-.1c.4-.4.9-.8 1.5-.9zm-27.2 8.7c-.5.1-.9.5-1.1 1-.1.5 0 1 .4 1.3.4.3.9.4 1.4.3l4.3-1c.5-.1.9-.4 1.1-.9.2-.4.1-.9-.3-1.3-.4-.3-.9-.5-1.4-.4l-4.3 1h-.1z"
            />
        </symbol>
        <symbol id="i-gift" viewBox="-38 -38 76 76">
            <path
                fill={COLORS.PURPLE_300}
                d="M-13.5 19.8 9.8 27c1.2.4 2.5.3 3.6-.3s1.9-1.6 2.3-2.8l3.5-11.2a4.8 4.8 0 0 0 3.1-3.1l1.4-4.5a5 5 0 0 0-.2-3.4C23 .6 22.2-.2 21.1-.7A7.42 7.42 0 0 0 18-13c-3.1-1-6.4-.4-9 1.5-1.1-3-3.5-5.3-6.5-6.3-3.9-1.2-8.1 1-9.3 4.9A8.2 8.2 0 0 0-7-9.4c-1.1-.2-2.3 0-3.4.6-1 .6-1.8 1.6-2.1 2.7l-1.4 4.5c-.5 1.5-.2 3.1.8 4.3l-3.5 11.2c-.8 2.4.6 5.1 3.1 5.9zm1.2-3.9c-.3-.1-.5-.5-.4-.8l3.2-10.3L.8 7.9l-3.4 11-9.7-3zm24.1 6.8c-.1.2-.2.3-.3.4-.2.1-.3.1-.5 0l-9.7-3 3.4-11L15 12.3l-3.2 10.4zm6.6-14.3c-.1.2-.2.3-.3.4-.2.1-.3.1-.5 0L5.9 5.2 7.7-.6 19.4 3c.2.1.3.2.4.3.1.2.1.4 0 .5l-1.4 4.6zM16.8-9.1c1.8.6 2.8 2.4 2.2 4.2a3.3 3.3 0 0 1-4.2 2.2L9-4.5l.2-.7c1-3.1 4.4-4.9 7.6-3.9zM1.3-13.9c3.2 1 5 4.4 4 7.6l-.2.6-5.8-1.8C-2.5-8-3.5-9.9-3-11.7a3.4 3.4 0 0 1 4.3-2.2zm-9.9 9c.1-.4.5-.6.9-.4L3.9-1.8 2 4-9.6.4c-.4-.1-.6-.5-.5-.8l1.5-4.5z"
            />
            <g style={{ opacity: 0.5 }}>
                <path
                    fill={COLORS.PURPLE_300}
                    d="M0 38c-21 0-38-17-38-38s17-38 38-38S38-21 38 0 21 38 0 38zm0-58c-11 0-20 9-20 20s9 20 20 20 20-9 20-20-9-20-20-20z"
                />
            </g>
        </symbol>
        <symbol id="i-graph" viewBox="-26.7 -27.3 53.4 54.7">
            <path
                d="M.1 27.3c-5.7 0-11.2-1.9-15.7-5.3-11.9-9-14.7-25.7-6.2-38 4-5.9 10.3-9.9 17.4-11 14.5-2.4 28.3 7.8 30.8 22.7 1.2 7.1-.4 14.3-4.5 20.2-4 5.9-10.3 9.9-17.4 11-1.5.3-2.9.4-4.4.4zm-.3-40.6c-.7 0-1.3.1-2 .2-3.3.6-6.2 2.4-8.1 5.2-4.1 6-2.7 14.3 3.1 18.7 5.3 4.1 13 3.1 17.1-2.2.2-.2.3-.4.5-.6 2-2.9 2.8-6.5 2.2-10-1-6.4-6.4-11.2-12.8-11.3z"
                fill={COLORS.BLUISH_PURPLE_400}
                style={{ opacity: 0.4 }}
            />
            <path
                fill={COLORS.BLUISH_PURPLE_400}
                d="m-2.2 2.7 6.7 2.9L9.3-5 6.7-6.1l-3.6 8L-3.6-1-8.4 9.6l2.6 1.2 3.6-8.1zm14.1 5 .4 2.6-22.5 3.7-2.9-17.8 2.6-.4-4.8-4.3-3.2 5.6 2.6-.4 3.4 20.6 25.3-4.2.4 2.6 4.3-4.8-5.6-3.2z"
            />
        </symbol>
        <symbol id="i-heart" viewBox="-13.9 -12.8 27.9 25.6">
            <path
                d="M13.4-8.5a7.4 7.4 0 0 0-8.2-4.2A7.3 7.3 0 0 0-.2-5.4 7.28 7.28 0 0 0-8.1-10c-3.4.7-5.8 3.6-5.9 7.1.1 2.4 1.2 4.7 3 6.4 1.8 1.6 3.8 3 6 4.1 2.9 1.7 5.6 3.3 8.6 5 2-2.7 3.7-5.3 5.7-8 1.5-1.9 2.8-4 3.8-6.2 1-2 1.1-4.6.3-6.9z"
                fill={COLORS.PURPLE_600}
            />
        </symbol>
        <symbol id="i-plane" viewBox="-39.9 -39.9 79.8 79.8">
            <path
                fill={COLORS.BLUE_200}
                d="M28.8 1.5C14.1-4.6-.5-10.7-15.2-16.8c-.8-.3-1.6-.7-2.4.1-.7.8-.3 1.6 0 2.4l15.3 31.1c.9 1.8 1.3 1.9 3.2 1.1.7-.3 1.3-.6 1.9-.9 1.2-.8 2-.6 2.9.6 1.2 1.8 2.7 3.3 4.1 5 .4.6 1.1.9 1.9.8.7-.2 1-.8 1.2-1.5.8-3.2 1.6-6.4 2.3-9.6.1-.8.7-1.5 1.4-1.8 4-1.8 8-3.7 12.1-5.6.7-.3 1.2-.7 1.7-1.2V2.6c-.5-.5-1-.9-1.6-1.1zM11.2 15.7c-.2.9-.4 1.9-.7 3-1-1.3-2-2.5-2.9-3.6 1-1 2.4-1.3 3.6-2 .2-.1.3-.3.7-.1l-.7 2.7zm-10-1.3c-.8.4-1.1.3-1.5-.5C-2.7 9-5.1 4.2-7.5-.7l-4.1-8.4.2-.1L12 9.4c-3.8 1.7-7.3 3.3-10.8 5zm14.7-6.8c-.5.3-1.2.2-1.7-.2C9.5 3.6 4.9-.1.2-3.8l-8.3-6.6.1-.1L25.1 3.2c-3.2 1.5-6.3 2.9-9.2 4.4z"
            />
            <g style={{ opacity: 0.5 }}>
                <path
                    fill={COLORS.BLUE_200}
                    d="M0 39.9C-22 39.9-39.9 22-39.9 0S-22-39.9 0-39.9 39.9-22 39.9 0A39.95 39.95 0 0 1 0 39.9zm0-61.8c-12.1.1-21.8 9.9-21.8 22A21.9 21.9 0 1 0 22-.1 21.88 21.88 0 0 0 0-21.9z"
                />
            </g>
        </symbol>
        <symbol id="i-star" viewBox="-9.1 -9.1 18.2 18.2">
            <path
                fill={COLORS.BLUISH_PURPLE_400}
                d="m0-9.1 3.2 5.9L9.1 0 3.2 3.2 0 9.1l-3.2-5.9L-9.1 0l5.9-3.2L0-9.1z"
            />
        </symbol>
        <g id="canvas">
            <path id="canvas-2" d="M0 0h280v336H0V0z" style={{ fill: 'none' }} />
        </g>
        <g id="fg">
            <defs>
                <path id="SVGID_1_" d="M0 168h76v76H0z" />
            </defs>
            <clipPath id="SVGID_00000048467750883910561780000018246145092420828808_">
                <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
            </clipPath>
            <g
                style={{
                    clipPath: 'url(#SVGID_00000048467750883910561780000018246145092420828808_)'
                }}>
                <use
                    xlinkHref="#i-gift"
                    width="76"
                    height="76"
                    x="-38"
                    y="-38"
                    transform="translate(38 206)"
                    style={{ overflow: 'visible' }}
                />
            </g>
            <ellipse
                cx="140.7"
                cy="321.5"
                rx="104.1"
                ry="13.4"
                fill={COLORS.BLUISH_PURPLE_400}
                style={{ opacity: 0.3 }}
            />
            <path
                fill={COLORS.BLUISH_PURPLE_200}
                d="M178.6 106h-14.7c-2 0-3.6 1.7-3.6 3.7v2.7c0 2-1.6 3.7-3.6 3.7h-28.6c-2 0-3.6-1.7-3.6-3.7v-2.7c0-2-1.6-3.7-3.6-3.7h-18c-6.4 0-11.6 5.2-11.6 11.6v186.2c0 6.4 5.2 11.5 11.6 11.5h75.5c6.4 0 11.6-5.2 11.6-11.5V117.5c.1-6.4-5.1-11.5-11.4-11.5z"
            />
            <path d="M178.9 100.4h-75.7c-9.1 0-16.5 7.4-16.5 16.5v189.8c0 9.1 7.4 16.5 16.5 16.5h75.7c9.1 0 16.5-7.4 16.5-16.5V116.9c-.1-9.1-7.4-16.4-16.5-16.5zM190.3 304c0 6.4-5.2 11.5-11.5 11.5h-75.5c-6.4 0-11.5-5.2-11.5-11.6V118.7c0-6.4 5.2-11.5 11.5-11.5h18c2 0 3.6 1.7 3.6 3.7v2.7c0 2 1.5 3.7 3.6 3.7H157c2 0 3.6-1.7 3.6-3.7v-2.7c0-2 1.5-3.7 3.6-3.7h14.7c6.4 0 11.5 5.2 11.5 11.6l-.1 185.2z" />
            <path
                fill={COLORS.RED_100}
                d="m141.4 293.6 5.2 21.8-33.7-.3-.8-8.8 29.3-12.7m12.7 7.8-4.5 13.9 33.6.2.6-4-29.7-10.1"
            />
            <path
                fill={COLORS.RED_100}
                d="m128.9 247.7-.9-7.4c-.3-1.9-.6-3.7-.8-5.6-.1-2.1.3-4.2 1.1-6.2 1.1-2.9 2-3.3 2.9-5 .1-.1.2-.3.3-.4.2-.3.3-.5.4-.9.6-1.7-1.3-3.2-3.6-2-1.7.9-4.3 2.7-6.9 9.5l-.1.3c-1 4.2-7.3-3.8-9.2-7a3.9 3.9 0 0 0-5.3-1.2c-.2.2-.5.3-.7.5l-9 10.3c-1.2.2-2.4.7-3.3 1.6a3.7 3.7 0 0 0-.4 5l12.7 16.3 6.2 50.8 29.2-12.9-12.6-45.7z"
            />
            <path
                fill={COLORS.RED_200}
                d="M131.2 223.6c.1-.1.2-.3.3-.4.2-.3.3-.5.4-.9.6-1.7-1.3-3.2-3.6-2-1.7.9-4.3 2.7-6.9 9.5l-.1.3c-1 4.2-7.3-3.8-9.2-7a3.9 3.9 0 0 0-5.3-1.2c-.2.2-.5.3-.7.5l-.4.4-.1 17.5c-.1 14.2 6.3 27.1 18.9 38.3 4.9 4.3 10.3 8.1 16 11.2L129 247.5l-.9-7.4c-.3-1.9-.6-3.7-.8-5.6-.1-2.1.3-4.2 1.1-6.2.9-2.6 1.9-3.1 2.8-4.7z"
            />
            <path
                fill={COLORS.RED_100}
                d="m166.6 255.5.9-7.4c.3-1.6.6-3.4.8-5.6.1-2.1-.3-4.2-1.1-6.2-1.1-2.9-2-3.3-2.9-5-.1-.1-.2-.3-.3-.4-.2-.3-.3-.5-.4-.9-.6-1.8 1.3-3.2 3.6-2 1.7.9 4.3 2.7 6.9 9.5 0 .1.1.2.1.3 1 4.2 7.3-3.8 9.2-7a3.88 3.88 0 0 1 6-.6l9 10.3c1.2.2 2.4.7 3.3 1.6 1.4 1.3 1.6 3.4.4 4.9l-12.7 16.3-6.2 50.8-29.2-12.9 12.6-45.7z"
            />
            <path
                fill={COLORS.RED_200}
                d="m190 230.9-.5-.5c-1.5-1.5-4-1.5-5.5 0-.2.2-.4.4-.5.6-1.9 3.2-8.2 11.2-9.2 7 0-.1-.1-.2-.1-.3-2.6-6.9-5.2-8.6-6.9-9.5-2.2-1.2-4.2.2-3.6 2 .1.3.2.6.4.9.1.1.2.3.3.4.9 1.7 1.8 2.1 2.9 5 .8 2 1.2 4.1 1.1 6.2-.2 2.2-.5 4-.8 5.6l-.9 7.4-8.6 31.4c4.4-2.6 8.6-5.6 12.5-9 12.8-11.1 19.3-23.9 19.4-38.1v-9.1z"
            />
            <path
                fill={COLORS.RED_200}
                d="M173.5 241.7c0 .1.8.3 1.9 1 1.4.9 2.7 2.1 3.6 3.5a12.94 12.94 0 0 1 2.1 6.8c.1-.2.1-.4.1-.6 0-.5 0-1.1-.1-1.6a11.52 11.52 0 0 0-7-9.1h-.6z"
            />
            <path
                fill={COLORS.WHITE}
                d="M148.6 176.8c-10.1 9.6-26.3 17.5-43.6 20.8 0 1 0 6.7-.2 32.9-.2 34.9 37.2 52 43 54.6 5.6-2.3 43.6-19.3 43.8-54.2l.2-32.9c-17.2-3.4-33.2-11.5-43.2-21.2z"
            />
            <path
                fill={COLORS.BLUISH_PURPLE_400}
                d="M148.5 180.6c-10.5 8.5-24.9 15.1-40.8 18.8l-.2 31.1c-.1 14.2 6.3 27.1 18.9 38.3 6.5 5.6 13.7 10.4 21.5 14l.6-.3.1-101.8-.1-.1z"
            />
            <path
                fill={COLORS.BLUISH_PURPLE_400}
                d="m148.6 180.7-.1 101.8c7.6-3.5 14.6-8 21-13.5 12.8-11.1 19.3-23.9 19.4-38.1l.2-31.1c-15.8-3.8-30.2-10.6-40.5-19.1z"
            />
            <path
                d="m148.6 180.7-.1 101.8c7.6-3.5 14.6-8 21-13.5 12.8-11.1 19.3-23.9 19.4-38.1l.2-31.1c-15.8-3.8-30.2-10.6-40.5-19.1z"
                fill={COLORS.BLUISH_PURPLE_400}
                style={{ opacity: 0.2 }}
            />
            <path
                fill={COLORS.WHITE}
                d="M172.4 220.3c-1.2-6.8-7.7-11.2-14.5-10-5.3 1-9.3 5.2-10.1 10.5-.9-6.8-7.2-11.6-14-10.7-5.3.7-9.6 4.7-10.6 10-.8 4.2.6 8.6 2.9 12.2s5.5 6.5 8.7 9.4c4.4 4 8.5 7.8 13 11.8 4.5-4 8.3-7.7 12.8-11.7 3.2-2.8 6.4-5.7 8.8-9.3s3.8-8 3-12.2z"
            />
            <path
                fill={COLORS.RED_100}
                d="M201.9 245.9c-2.6.6-4.8 1.7-7.6 2.3-1.2.3-3.3.9-4.5.5s-1.8-1-1.6-2.3c.1-1 1.4-1.8 2.2-2.2 2.2-1.1 4.1-1.8 5.7-2.7.8-.4.3-.8 0-.6-1.3.4-3 .4-4.2.9-1.7.6-2.9 2.2-5.2.1-.3-.3-.6-.6-.7-1-.4-1.3.1-2.7 1.2-3.4.8-.6 1.8-1 2.8-1.3-1.8.6-3.8 1.2-5.7.3-1.1-.5-1.8-1.6-1.7-2.8.2-1.5 1.6-2.2 2.9-2.7 2.3-.9 4.7-1.5 7.1-1.9.9-.1 1.8 0 2.6.5 1 .5 1.8 1.4 2 2.5.1 1.1-.2 2.2-.9 3.1 0 0 3 .2 4.1 2.5.7 1.5.8 3.1.3 4.6l1.2 3.6z"
            />
            <path
                fill={COLORS.RED_100}
                d="M195.4 229.2c1.2-.4 2.1-1.6 2.2-2.9.1-.8 0-1.7-.4-2.5-1.7-2-12.2-6-13.3-3.9s-1.1 3.6 4.5 5.9a62 62 0 0 1 2.5 1.1l.1 2.6 4.4-.3z"
            />
            <path
                fill={COLORS.RED_200}
                d="M188.1 236.8c1.3-.6 2.7-1.1 4.2-1.4 1.5-.2 3-.3 4.5-.2-.4-.2-.9-.3-1.3-.3-2.2-.2-4.3.2-6.3 1.1-.4.3-.8.5-1.1.8zm2.6-7.2c0 .1.9-.1 2-.2.5-.1.9-.1 1.4-.1.4 0 .6.1.6 0s-.2-.2-.5-.3c-.5-.1-1-.1-1.5 0-.7 0-1.4.2-2 .6zm-.4-2.9c.6.4 1.2.7 1.9.9.5.1 1 .2 1.5.1.4-.1.6-.2.6-.2-.7 0-1.4 0-2-.2l-2-.6zm2.6 16.5c.5-.1 1-.4 1.5-.6 1.2-.6 2.4-1 3.7-1.2 1.3-.1 2.6.2 3.6 1 .4.4.8.8 1.1 1.2 0 0 0-.2-.2-.4-.2-.4-.5-.7-.8-1-1.1-.9-2.4-1.3-3.8-1.2-1.3.2-2.6.6-3.8 1.3-.3.3-.8.6-1.3.9z"
            />
            <path
                fill={COLORS.RED_100}
                d="M189.1 246.7c-.5-.1-.8-.6-.7-1.1.1-.5.5-.9 1-1 .5-.1 1-.1 1.5 0 .2 0 .3.1.4.2.4.3.4.9.1 1.2-.3.4-.7.6-1.2.6h-1.4"
            />
            <path
                fill={COLORS.RED_200}
                d="M193.5 242.9c-1.5 1-6.2 2.2-4 4.5.4.3.9.5 1.4.5 2 0 3.8-.2 6-.2l.1.2c-2.2 2.1-6.3 3.2-8.7.6-2.1-2.8.3-4.7 3-5.2l2-.6.2.2z"
            />
            <path
                fill={COLORS.RED_100}
                d="M93.6 238.1c2.6.6 4.8 1.7 7.6 2.3 1.2.3 3.3.9 4.5.5s1.8-1 1.6-2.3c-.1-1-1.4-1.8-2.2-2.2-2.2-1.1-4.1-1.8-5.7-2.7-.8-.4-.3-.9 0-.7 1.3.4 2.9.4 4.2.9 1.7.7 2.9 2.2 5.2.1.3-.3.6-.6.7-1 .4-1.3-.1-2.7-1.2-3.4-.8-.6-1.8-1-2.7-1.3 1.8.6 3.8 1.2 5.7.3 1.1-.5 1.8-1.6 1.7-2.8-.2-1.5-1.6-2.2-2.9-2.7-2.3-.9-4.7-1.5-7.1-1.9-.9-.1-1.8 0-2.6.5-1 .5-1.8 1.4-2 2.5-.1 1.1.2 2.2.9 3.1 0 0-3 .2-4.1 2.5-.7 1.5-.8 3.1-.3 4.6l-1.3 3.7z"
            />
            <path
                fill={COLORS.RED_100}
                d="M100.1 221.5c-1.3-.4-2.1-1.6-2.2-2.9-.1-.8 0-1.7.4-2.5 1.7-2 12.2-6 13.3-3.9s1.1 3.6-4.5 5.9l-2.5 1.1-.1 2.6-4.4-.3z"
            />
            <path
                fill={COLORS.RED_200}
                d="M107.4 229.1c-1.3-.6-2.7-1.1-4.2-1.4-1.5-.2-3-.3-4.5-.2.4-.2.9-.3 1.3-.3 2.1-.2 4.3.2 6.2 1.1.5.2.9.4 1.2.8zm-2.6-7.3c0 .1-.9-.1-2-.2-.5 0-.9-.1-1.4-.1-.4 0-.6.1-.6 0s.2-.2.5-.3c.5-.1 1-.1 1.5 0 .7.1 1.4.3 2 .6zm.4-2.8c-.6.4-1.2.7-1.9.9-.5.1-1 .2-1.5.1-.4-.1-.6-.2-.6-.2.7 0 1.4 0 2.1-.2.6-.3 1.2-.5 1.9-.6zm-2.6 16.4-1.5-.6c-1.2-.6-2.4-1-3.7-1.2-1.3-.1-2.6.2-3.6 1-.4.4-.8.8-1.1 1.2 0-.1.1-.3.2-.4.2-.4.5-.7.8-.9 1.1-.9 2.4-1.3 3.8-1.2 1.3.2 2.6.6 3.8 1.3.3.2.8.5 1.3.8z"
            />
            <path
                fill={COLORS.RED_100}
                d="M106.4 238.9c.5-.1.8-.6.7-1.1-.1-.5-.5-.9-1-1-.5-.1-1-.1-1.5 0-.2 0-.3.1-.4.2-.4.3-.4.9-.1 1.2.3.4.7.6 1.2.6h1.4"
            />
            <path
                fill={COLORS.RED_200}
                d="M102.1 234.9c1.4.3 2.8.8 4.1 1.3 1.4.6 2.1 2.2 1.5 3.7-.1.3-.3.6-.6.8-2.3 2.6-6.4 1.4-8.7-.7l.1-.2c2.1.1 4 .3 6 .2.5 0 1-.2 1.4-.5 2.2-2.4-2.5-3.5-4-4.5l.2-.1z"
            />
            <path
                d="m76.1 225.2 1.8 3.2 3.2 1.8-3.2 1.8-1.8 3.2-1.8-3.2-3.2-1.8 3.2-1.8 1.8-3.2zm-58.3-81.4 1.8 3.2 3.2 1.8-3.2 1.8-1.8 3.2-1.8-3.2-3.2-1.8L16 147l1.8-3.2z"
                fill={COLORS.NEUTRAL_50}
            />
            <defs>
                <path
                    id="SVGID_00000050634855987154810330000010193541471655660419_"
                    d="M212.1 78.3H266v52.4h-53.9z"
                />
            </defs>
            <clipPath id="SVGID_00000065067733902776144780000003561544761458446993_">
                <use
                    xlinkHref="#SVGID_00000050634855987154810330000010193541471655660419_"
                    style={{ overflow: 'visible' }}
                />
            </clipPath>
            <g
                style={{
                    clipPath: 'url(#SVGID_00000065067733902776144780000003561544761458446993_)'
                }}>
                <use
                    xlinkHref="#i-calendar"
                    width="35.9"
                    height="35.6"
                    x="-18"
                    y="-17.8"
                    transform="matrix(1.5 0 0 1.47 238.9 104.28)"
                    style={{ overflow: 'visible' }}
                />
            </g>
            <use
                xlinkHref="#i-star"
                width="18.2"
                height="18.2"
                x="-9.1"
                y="-9.1"
                transform="matrix(.99 0 0 .99 226.01 167.01)"
                style={{ overflow: 'visible' }}
            />
            <path
                d="m244 140.4 2 3.6 3.6 2-3.6 2-2 3.6-2-3.6-3.6-2 3.6-2 2-3.6zM72 78.7l1.5 2.8 2.8 1.5-2.8 1.5-1.5 2.8-1.5-2.8-2.8-1.5 2.8-1.5 1.5-2.8z"
                fill={COLORS.NEUTRAL_50}
            />
            <path
                fill={COLORS.PURPLE_300}
                d="m63 99 3.2 5.8L72 108l-5.8 3.2L63 117l-3.2-5.8L54 108l5.8-3.2L63 99z"
            />
            <path
                fill={COLORS.BLUE_200}
                d="m33.1 87-3-.6L25.5 76l1.2-5.9s-4.5-1.2-5.5 6.7-1.2 5.8-1.2 5.8-9.7-1.9-10.7-1.2c-.7.5-2.9 10.1-4 15.3-.7 2.6.5 5.3 2.9 6.5.3.2.7.3 1 .3 2.4.5 15.2 1 15.2 1l5.1 1L33.1 87zM42 87l-5.8 19.9-5.1-1L35 86l7 1z"
            />
            <path
                fill={COLORS.BLUISH_PURPLE_400}
                d="M54.3 136.9c2.7-1.9 4-5.2 3.4-8.4-.8-4.5-4.9-7.6-9.1-6.9s-6.9 5-6 9.5c.5 3.3 3 6 6.2 6.8-11.3 3.9-8.3 16.6-8.3 16.6l27.5-4.8c-.1.1-1.9-13.1-13.7-12.8z"
            />
            <g>
                <defs>
                    <path
                        id="SVGID_00000043423947207406096380000003001658890680352957_"
                        d="M2 238h52.8v55.2H2z"
                    />
                </defs>
                <clipPath id="SVGID_00000183944552405820440300000000040172831703804807_">
                    <use
                        xlinkHref="#SVGID_00000043423947207406096380000003001658890680352957_"
                        style={{ overflow: 'visible' }}
                    />
                </clipPath>
                <g
                    style={{
                        clipPath: 'url(#SVGID_00000183944552405820440300000000040172831703804807_)'
                    }}>
                    <use
                        xlinkHref="#i-graph"
                        width="53.4"
                        height="54.7"
                        x="-26.7"
                        y="-27.3"
                        transform="matrix(.99 0 0 1.01 24.92 263.58)"
                        style={{ overflow: 'visible' }}
                    />
                </g>
            </g>
            <g>
                <defs>
                    <path
                        id="SVGID_00000177467446607254631370000011575483842542287267_"
                        d="M181.6 119.2h44.9v41.5h-44.9z"
                    />
                </defs>
                <clipPath id="SVGID_00000034804230167077874080000014184113419576579202_">
                    <use
                        xlinkHref="#SVGID_00000177467446607254631370000011575483842542287267_"
                        style={{ overflow: 'visible' }}
                    />
                </clipPath>
                <g
                    style={{
                        clipPath: 'url(#SVGID_00000034804230167077874080000014184113419576579202_)'
                    }}>
                    <use
                        xlinkHref="#i-heart"
                        width="27.9"
                        height="25.6"
                        x="-13.9"
                        y="-12.8"
                        transform="matrix(1.61 0 0 1.62 204.07 139.08)"
                        style={{ overflow: 'visible' }}
                    />
                </g>
            </g>
            <g>
                <defs>
                    <path
                        id="SVGID_00000098934168253189359430000007804819281151658402_"
                        d="m219.9 172.2 55.6 8.2-6.2 42.4-55.6-8.1z"
                    />
                </defs>
                <clipPath id="SVGID_00000028294158703956738690000011859317609088985492_">
                    <use
                        xlinkHref="#SVGID_00000098934168253189359430000007804819281151658402_"
                        style={{ overflow: 'visible' }}
                    />
                </clipPath>
                <g
                    style={{
                        clipPath: 'url(#SVGID_00000028294158703956738690000011859317609088985492_)'
                    }}>
                    <use
                        xlinkHref="#i-card"
                        width="51"
                        height="38.6"
                        x="-25.5"
                        y="-19.3"
                        transform="matrix(1.09 .16 -.16 1.1 244.2 196.83)"
                        style={{ overflow: 'visible' }}
                    />
                </g>
            </g>
            <g>
                <defs>
                    <path
                        id="SVGID_00000026151640630124724920000004173073485571133338_"
                        d="M200 230.5h79.8v79.8H200z"
                    />
                </defs>
                <clipPath id="SVGID_00000182521684614146043910000001068976516032196507_">
                    <use
                        xlinkHref="#SVGID_00000026151640630124724920000004173073485571133338_"
                        style={{ overflow: 'visible' }}
                    />
                </clipPath>
                <g
                    style={{
                        clipPath: 'url(#SVGID_00000182521684614146043910000001068976516032196507_)'
                    }}>
                    <use
                        xlinkHref="#i-plane"
                        width="79.8"
                        height="79.8"
                        x="-39.9"
                        y="-39.9"
                        transform="translate(239.88 270.4)"
                        style={{ overflow: 'visible' }}
                    />
                </g>
            </g>
        </g>
        <text
            transform="translate(84.63 49.63)"
            fill={COLORS.BLUISH_PURPLE_200}
            style={{
                letterSpacing: 1,
                fontSize: 32,
                fontFamily: "font-family:'WorkSans-SemiBold'"
            }}>
            {title}
        </text>
    </svg>
);

export default SlideThreeHealth;
