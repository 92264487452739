export const ABOUT = {
    PT: {
        TITLE: 'Acerca',
        DESCRIPTION: [
            'A app Hi! é um ecossistema único que contempla três serviços: <b>Movimento</b>, <b>Nutrição</b> e <b>Saúde</b>. Uma plataforma interativa onde cada ação tem uma reação, através da acumulação de pontos.',
            'A nossa missão é contribuir para a melhoria da qualidade de vida da sociedade, oferecer um serviço completo de prevenção e desafiar constantemente o seu estilo de vida saudável.'
        ],
        LEGAL_RIGHTS: '©2021 Hiecotech - Healthy Insurance Ecosystem. Todos os direitos reservados.'
    },
    EN: {
        TITLE: 'About',
        DESCRIPTION: [
            'The Hi! app is a unique ecosystem of three services: <b>Movement</b>, <b>Nutrition</b> and <b>Health</b>. An interactive platform where every action has a reaction, through the accumulation of points.',
            'Our mission is to contribute to the improvement of the quality of life of society, to offer a complete preventive service and to constantly challenge your healthy lifestyle.'
        ],
        LEGAL_RIGHTS: '©2021 Hiecotech - Healthy Insurance Ecosystem. All rights reserved.'
    }
};
