import { permissions } from 'config';
import { META_TAGS, MOVEMENT_EXERCISE } from 'content';
import { useAuth, useLanguage } from 'hooks';
import React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { GymLog, Intro, ManualLog, Services, Wearables } from './Modules';

const SHOW_SERVICES = false;

export const Exercise: React.FC = () => {
    const { validateUserAccessToArea } = useAuth();
    const { isPT } = useLanguage();

    const CONTENT = isPT ? MOVEMENT_EXERCISE.PT : MOVEMENT_EXERCISE.EN;

    useEffect(
        () => validateUserAccessToArea(permissions.movement.exercise),
        [validateUserAccessToArea]
    );

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? META_TAGS.PRIVATE.MOVEMENT.EXERCISE.PT.TITLE
                        : META_TAGS.PRIVATE.MOVEMENT.EXERCISE.EN.TITLE}
                </title>
            </Helmet>
            <Intro title={CONTENT.TITLE} preTitle={CONTENT.PRE_TITLE} />
            <GymLog
                title={CONTENT.GYM.TITLE}
                description={CONTENT.GYM.DESCRIPTION}
                noActivity={{
                    title: CONTENT.GYM.NO_ACTIVITY.TITLE,
                    description: CONTENT.GYM.NO_ACTIVITY.DESCRIPTION
                }}
                buttonLabel={CONTENT.GYM.BUTTON}
            />
            <ManualLog title={CONTENT.MANUAL.TITLE} description={CONTENT.MANUAL.DESCRIPTION} />
            <Wearables title={CONTENT.DEVICES.TITLE} description={CONTENT.DEVICES.DESCRIPTION} />
            {SHOW_SERVICES && <Services title={CONTENT.SERVICES.TITLE} />}
        </>
    );
};
