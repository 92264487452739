import { ButtonIcon } from 'components/elements';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconX } from 'theme/icons';

import styles from './Unavailable.module.scss';

type UnavailableProps = {
    title: string;
    description: string;
};

export const Unavailable: React.FC<UnavailableProps> = ({ title, description }) => {
    const navigate = useNavigate();

    const onCLick = useCallback(() => navigate(-1), [navigate]);

    return (
        <section className={styles.unavailable}>
            <IconX width={60} height={60} className={styles.icon} />
            <h2>{title}</h2>
            <p>{description}</p>
            <ButtonIcon
                icon="ARROW_LEFT"
                size="MD"
                backgroundOpacity="OPAQUE"
                className={styles['back-button']}
                onClick={onCLick}
            />
        </section>
    );
};
