import { LogoHi } from 'assets/logos';
import { ScrollTopButton } from 'components/elements';
import { permissions } from 'config';
import { META_TAGS } from 'content';
import format from 'date-fns/format';
import ENLocale from 'date-fns/locale/en-GB';
import PTLocale from 'date-fns/locale/pt';
import { useAuth, useGamification, useLanguage } from 'hooks';
import { useApi } from 'hooks';
import { ArticleData } from 'models/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import styles from './Article.module.scss';
import { Content, Header } from './modules';

type ArticleContent = {
    title: string | undefined;
    subtitle: string;
};

export const Article: React.FC = () => {
    const { slug } = useParams();
    const { validateUserAccessToArea } = useAuth();
    const { isPT } = useLanguage();
    const { reportInteraction } = useGamification();
    const { getArticle } = useApi();

    const headerRef = useRef<HTMLDivElement>(null);

    const [currentArticle, setCurrentArticle] = useState<ArticleData | undefined>(undefined);
    const [headerTopCoordinate, setHeaderTopCoordinate] = useState(0);
    const [articleContent, setArticleContent] = useState<ArticleContent | undefined>(undefined);

    const getData = useCallback(async () => {
        try {
            if (!slug) return;
            const article = await getArticle(slug);
            if (!article) return;

            setCurrentArticle(article);
            setArticleContent({
                title: isPT
                    ? article.article.find(({ type }) => type === 'h1')?.content?.pt
                    : article.article.find(({ type }) => type === 'h1')?.content?.en,
                subtitle: `${article.author.name} · ${format(
                    new Date(article.publish_date.replace(' ', 'T')),
                    'd MMM yyyy',
                    {
                        locale: isPT ? PTLocale : ENLocale
                    }
                )}`
            });

            reportInteraction({
                interaction: 'articles',
                area: article.area,
                id: article.id
            });
        } catch (error) {
            // TO-DO
            console.error(error);
        }
    }, [getArticle, isPT, reportInteraction, slug]);

    useEffect(
        () => validateUserAccessToArea(permissions.articles.article),
        [validateUserAccessToArea]
    );

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        setTimeout(() => {
            const { current } = headerRef;
            if (!current) return;

            const handler = () => {
                const { top } = current.getBoundingClientRect();
                setHeaderTopCoordinate(top);
            };

            window.addEventListener('scroll', handler);
            return window.removeEventListener('scroll', handler);
        }, 1000);
    }, [headerRef]);

    if (!articleContent || !currentArticle)
        return (
            <section className={styles.loading}>
                <LogoHi animate showLettering={false} width={120} />
            </section>
        );

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? `${articleContent.title} ${META_TAGS.PRIVATE.ARTICLES.ARTICLE.PT.TITLE}`
                        : `${articleContent.title} ${META_TAGS.PRIVATE.ARTICLES.ARTICLE.EN.TITLE}`}
                </title>
            </Helmet>
            <Header headerRef={headerRef} />
            <Content articleContent={articleContent} currentArticle={currentArticle} />
            <ScrollTopButton top={headerTopCoordinate} />
        </>
    );
};
