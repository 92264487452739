import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconArrowUp: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_700,
    width = 19.5,
    height = 20,
    ...props
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19.5 20"
        width={width}
        height={height}
        fill={color}
        {...props}>
        <path
            d="M19.39,9.5,10.33.22h-.07L10.11.11H9.68A.81.81,0,0,0,9.4.28L.34,9.56a.75.75,0,0,0,0,1.06.71.71,0,0,0,1,0h0l7.84-8V19.32a.7.7,0,1,0,1.38.17.49.49,0,0,0,0-.17V2.59l7.84,8a.71.71,0,0,0,1,0h0a.76.76,0,0,0,.07-1.08Z"
            transform="translate(-0.12 -0.1)"
        />
    </svg>
);
