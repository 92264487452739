// @see: https://swiperjs.com/react#usage-with-create-react-app
import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/modules/effect-fade/effect-fade.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/calendar.css';
import 'styles/globals.scss';

import { AppProvider } from 'hooks';
import { LanguageProvider } from 'hooks/useLang';
import { DesktopMessage } from 'pages/other';
import { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Routes from 'routes';
import smoothscroll from 'smoothscroll-polyfill';
import { logSignature, setViewportHeight } from 'utils';

const App: React.FC = () => {
    const [showDesktopMessage, setShowDesktopMessage] = useState(false);

    const helmetContext = {};

    useEffect(logSignature);
    useEffect(setViewportHeight);
    useEffect(() => smoothscroll.polyfill());

    useEffect(() => {
        const shell = document.getElementById('shell');
        if (!shell) return;
        shell.remove();
    }, []);

    useEffect(() => {
        const handler = () => setShowDesktopMessage(window.matchMedia('(min-width:500px)').matches);

        handler();
        window.addEventListener('resize', handler);
        return () => window.removeEventListener('resize', handler);
    }, []);

    if (showDesktopMessage)
        return (
            <LanguageProvider>
                <DesktopMessage />
            </LanguageProvider>
        );

    return (
        <HelmetProvider context={helmetContext}>
            <BrowserRouter>
                <AppProvider>
                    <Routes />
                    <ToastContainer limit={1} newestOnTop={false} rtl={false} />
                </AppProvider>
            </BrowserRouter>
        </HelmetProvider>
    );
};

export default App;
