export const NUTRITION_NUTRITIONISTS_LIST = {
    PT: {
        INTRO: {
            PRE_TITLE: 'consulta de nutrição',
            TITLE: 'Agendar um/a Nutricionista'
        },
        FILTER: {
            LABEL: 'filtrar'
        },
        SEARCH: {
            LABEL: 'Pesquisar'
        },
        HIGHLIGHTS: {
            TITLE: 'Em destaque'
        },
        TAGS: {
            TITLE: 'Palavras Chave'
        }
    },
    EN: {
        INTRO: {
            PRE_TITLE: 'nutrition appointment',
            TITLE: 'Book a Nutritionist'
        },
        FILTER: {
            LABEL: 'filter'
        },
        SEARCH: {
            LABEL: 'Search'
        },
        HIGHLIGHTS: {
            TITLE: 'Highlights'
        },
        TAGS: {
            TITLE: 'Key words'
        }
    }
};
