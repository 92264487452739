import { useTheme } from 'hooks';
import { Link, LinkProps } from 'react-router-dom';
import { IconArrow } from 'theme/icons';

import styles from './NavButton.module.scss';

export type NavButtonProps = LinkProps;

export const NavButton: React.FC<NavButtonProps> = ({ children, ...props }) => {
    const { activeTheme } = useTheme();
    return (
        <Link className={`${styles.button} ${styles[activeTheme]}`} {...props}>
            {children} <IconArrow className={styles.icon} />
        </Link>
    );
};
