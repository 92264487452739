import { useScroll, useTheme } from 'hooks';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Cover, MemoizedHeaderSecondary, MemoizedNav } from './modules';

const Menu = React.lazy(
    () =>
        import(
            /* webpackChunkName: "Menu" */
            'components/sections/Menu'
        )
);

import styles from './Header.module.scss';

export type HeaderProps = React.HTMLProps<HTMLDivElement> & {
    image?: string;
    image_2x?: string;
    alt?: string;
    className?: string;
    hasImage?: boolean;
    backButton?: boolean;
    title?: string;
};

export const Header: React.FC<HeaderProps> = ({
    image,
    image_2x = image,
    alt = '',
    className = '',
    hasImage = true,
    backButton = false,
    title,
    ...props
}) => {
    const { activeTheme } = useTheme();
    const { scrollTop } = useScroll();
    const { pathname } = useLocation();
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => scrollTop(), [scrollTop, pathname]);

    const headerClassName = useMemo(
        () => [styles.header, styles[activeTheme], className].join(' '),
        [activeTheme, className]
    );

    return (
        <>
            <header className={headerClassName} {...props}>
                <MemoizedHeaderSecondary
                    title={title}
                    hasImage={hasImage}
                    backButton={backButton}
                    setShowMenu={setShowMenu}
                />
                <Cover
                    hasImage={hasImage}
                    title={title}
                    image={image}
                    image_2x={image_2x}
                    alt={alt}
                />
                <MemoizedNav
                    backButton={backButton}
                    setShowMenu={setShowMenu}
                    hasImage={hasImage}
                />
            </header>
            <Suspense fallback={null}>
                <Menu isOpen={showMenu} setIsOpen={() => setShowMenu(false)} />
            </Suspense>
        </>
    );
};

const MemoizedHeader = React.memo(
    Header,
    (prevProps, nextProps) => prevProps.hasImage !== nextProps.hasImage
);
export default MemoizedHeader;
