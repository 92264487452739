import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconAlertRounded: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path d="M10 .02a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm0 19.18a9.18 9.18 0 1 1 9.17-9.18A9.18 9.18 0 0 1 10 19.18Zm.48-7.3.19-2.66V4.66H9.32v4.58l.2 2.66ZM10 13.3a1 1 0 1 0 1 1 1 1 0 0 0-1-1.02Z" />
        </svg>
    );
};
