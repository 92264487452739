import { Main } from 'components/elements';
import { Header } from 'components/sections';
import { permissions } from 'config';
import { ABOUT, META_TAGS } from 'content';
import { sanitize } from 'dompurify';
import { useAuth, useLanguage } from 'hooks';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import styles from './About.module.scss';

export const About: React.FC = () => {
    const { isPT } = useLanguage();
    const { validateUserAccessToArea } = useAuth();

    const CONTENT = isPT ? ABOUT.PT : ABOUT.EN;

    useEffect(
        () => validateUserAccessToArea(permissions.general.about),
        [validateUserAccessToArea]
    );

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? META_TAGS.PRIVATE.GENERAL.ABOUT.PT.TITLE
                        : META_TAGS.PRIVATE.GENERAL.ABOUT.EN.TITLE}
                </title>
            </Helmet>
            <Header backButton hasImage={false} />
            <Main>
                <section className={`${styles.section} container`}>
                    <h1>{CONTENT.TITLE}</h1>
                    {CONTENT.DESCRIPTION.map((c, i) => (
                        <p key={i.toString()} dangerouslySetInnerHTML={{ __html: sanitize(c) }} />
                    ))}
                    <p className={styles.small}>{CONTENT.LEGAL_RIGHTS}</p>
                </section>
            </Main>
        </>
    );
};
