import { CardTrackerV2, CardTrackerV2Props } from 'components/elements';
import { useApi, useTheme } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

// import { Link } from 'react-router-dom';
import styles from './GymLog.module.scss';

type GymLogProps = {
    title: string;
    description: {
        one: (visits: number) => string;
        two: string;
    };
    noActivity: {
        title: string;
        description: string;
    };
    buttonLabel: string;
};

export const GymLog: React.FC<GymLogProps> = (props) => {
    const { activeTheme } = useTheme();
    const { getUserGymWorkout } = useApi();

    const [gymLog, setGymLog] = useState<CardTrackerV2Props[]>([]);

    const fetchData = useCallback(async () => {
        const activities = await getUserGymWorkout(4);
        setGymLog(activities);
    }, [getUserGymWorkout]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const className = useMemo(
        () =>
            [
                styles['gym-log'],
                styles[activeTheme],
                gymLog.length === 0 ? styles.noRecords : '',
                'container'
            ].join(' '),
        [activeTheme, gymLog.length]
    );

    return (
        <section className={className}>
            <h2>{props.title}</h2>
            {gymLog.length > 0 ? (
                <>
                    <p>
                        <b>{props.description.one(gymLog.length)}</b>
                    </p>
                    <p>{props.description.two}</p>
                    <div className={styles['card-list']}>
                        {gymLog.map((log, i) => (
                            <CardTrackerV2
                                key={log.id?.toString() || i.toString()}
                                {...log}
                                className={styles.card}
                            />
                        ))}
                    </div>
                </>
            ) : (
                <div className={styles.noActivity}>
                    <strong>{props.noActivity.title}</strong>
                    <p>{props.noActivity.description}</p>
                </div>
            )}
        </section>
    );
};
