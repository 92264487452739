import styles from './Symbol.module.scss';

export type SymbolProps = {
    animate?: boolean;
} & React.HTMLProps<HTMLDivElement>;

export const Symbol: React.FC<SymbolProps> = ({ animate = false, className = '', ...props }) => (
    <div className={`${styles.icon} ${className}`} {...props}>
        <svg className={styles.lettering} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
            <path
                d="M8607,2197.51H8596.3v-9.68l-4.47.06v24.48l4.47-.06v-9.46H8607v9.46l4.44.06v-24.48l-4.44-.06Zm16.14,6.61a1.58,1.58,0,0,0,3.16,0l.64-15.34h-4.44Zm1.58,3.84a2.27,2.27,0,0,0-2.27,2.28h0a2.27,2.27,0,1,0,2.27-2.28Zm-9.93,4.41h4.45v-18.72h-4.45Zm4.32-24.46h0l3.39-1.27h0l-3.54-1-.15-3.7h0l-2,3.08-3.54-1h0l2.28,2.9-2,3.08h0l3.44-1.28c.77,1,1.52,1.93,2.28,2.9h0l-.15-3.69Z"
                transform="translate(-8573.59 -2163.53)"
            />
        </svg>
        <svg
            className={`${styles.outerRing} ${animate ? styles.rotate : ''}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 72 72">
            <path
                d="M8644.86,2199.37h-.73a34.59,34.59,0,1,1-9.71-25.15,35.7,35.7,0,0,1,10.44,25.15,35.31,35.31,0,1,0-9.92,25.67,36.44,36.44,0,0,0,10.65-25.67Z"
                transform="translate(-8573.59 -2163.53)"
            />
        </svg>
        <svg
            className={`${styles.innerRing} ${animate ? styles.rotateReverse : ''}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 72 72">
            <path
                d="M8638.48,2187.17a32.31,32.31,0,0,0-6.69-10.5,31.69,31.69,0,0,0-10.21-7.17,31.17,31.17,0,0,0-34.91,6.93,32.53,32.53,0,0,0-6.89,10.56c-.33.8-.62,1.63-.88,2.46.94-1.32,1.92-2.61,3-3.86a31.1,31.1,0,1,1,5.66,35.89,31.34,31.34,0,0,1-8.39-16.09l-.15.53c-.17.56-.33,1.13-.48,1.69a33.25,33.25,0,0,0,1.39,4.12,32.47,32.47,0,0,0,7,10.43,31.75,31.75,0,0,0,10.35,6.93,31.18,31.18,0,0,0,34.43-7.16,32,32,0,0,0,6.75-10.38,32.92,32.92,0,0,0,.1-24.38Zm-57.22,3.45a10.75,10.75,0,0,0,1.1-1.88l-.06,0a10.16,10.16,0,0,0-1.61,1.46,18,18,0,0,0-1.3,1.72l-1.27,1.74c-.41.59-.84,1.17-1.24,1.77l.05,0c.5-.52,1-1.05,1.47-1.57l1.44-1.61a16.8,16.8,0,0,0,1.42-1.62Z"
                transform="translate(-8573.59 -2163.53)"
            />
        </svg>
    </div>
);
