export const ARTICLES_LIST = {
    PT: {
        HEADER: {
            IMAGE_ALT: 'homem a fazer alongamentos às pernas'
        },
        INTRO: {
            TITLE: 'Artigos'
        }
    },
    EN: {
        HEADER: {
            IMAGE_ALT: 'man stretching his legs'
        },
        INTRO: {
            TITLE: 'Articles'
        }
    }
};
