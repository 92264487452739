import { LogoHi } from 'assets/logos';
import { Button, ButtonIcon, ImageFrame } from 'components/elements';
import { useEffect } from 'react';
import { A11y, EffectFade, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { COLORS } from 'theme/colors';

import styles from './Onboarding.module.scss';

type OnboardingProps = {
    setShow: (show: boolean) => void;
};

export const Onboarding: React.FC<OnboardingProps> = ({ setShow }) => {
    useEffect(() => {
        const first_visit = localStorage.getItem('@hi:first-visit');

        if (!first_visit) {
            setShow(true);
            localStorage.setItem('@hi:first-visit', new Date().toISOString());
            return;
        }

        // TEMPORARY
        const moreThanOneDay =
            new Date(first_visit).getTime() - new Date().getTime() > 1000 * 60 * 60 * 24; // 1 day

        if (moreThanOneDay) {
            localStorage.removeItem('@hi:first-visit');
            localStorage.setItem('@hi:first-visit', new Date().toISOString());
            setShow(true);
        }

        setShow(false);
    }, [setShow]);

    const handleClose = () => {
        setShow(false);
    };

    return (
        <div className={styles.container}>
            <ButtonIcon
                icon="X"
                size="MD"
                backgroundOpacity="TRANSPARENT"
                className={styles.closeButton}
                onClick={handleClose}
            />
            <Swiper
                modules={[Pagination, A11y, EffectFade]}
                effect="fade"
                pagination={{
                    clickable: true,
                    bulletClass: styles.bullet,
                    bulletActiveClass: styles.bulletActive
                }}
                loop={false}
                spaceBetween={0}
                slidesPerView={1}
                className={styles.slider}>
                <SwiperSlide className={styles.slide}>
                    <ImageFrame
                        image="/images/onboarding/1.jpg"
                        description="__TEMP__"
                        backgroundColor={COLORS.WHITE}
                    />
                    <div className={styles.wrapper}>
                        <h2>Edite o seu perfil</h2>
                        <p>
                            Faça o upload da sua fotografia e dê um toque pessoal à sua área myHi!
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className={styles.slide}>
                    <ImageFrame
                        image="/images/onboarding/2.jpg"
                        description="__TEMP__"
                        backgroundColor={COLORS.WHITE}
                    />
                    <div className={styles.wrapper}>
                        <h2>Explore o ecossistema Hi!</h2>
                        <p>
                            <span>Movimento + Nutrição + Saúde</span>
                            <br /> Marque sessões de Sports Coach, Agende Consultas de Nutrição,
                            Marque Consultas de Saúde e mais.
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className={styles.slide}>
                    <ImageFrame
                        image="/images/onboarding/3.jpg"
                        description="__TEMP__"
                        backgroundColor={COLORS.WHITE}
                    />
                    <div className={styles.wrapper}>
                        <h2>Cada ação, uma reação</h2>
                        <p>
                            <span>HPS - Health Promote Score</span>
                            <br />
                            Treine, ganhe pontos e tenha acesso a descontos exclusivos! Premiamos a
                            sua saúde.
                        </p>
                        <Button
                            label="começar"
                            size="md"
                            className={styles.button}
                            onClick={handleClose}
                        />
                    </div>
                </SwiperSlide>
                <span slot="container-start">
                    <LogoHi animate showLettering={false} width={70} className={styles.logo} />
                </span>
            </Swiper>
        </div>
    );
};

export default Onboarding;
