import { useTheme } from 'hooks';
import { useMemo, useState } from 'react';

import styles from './OptionButton.module.scss';

export type OptionButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    onClick: () => void;
    isSelected: boolean;
    fullWidth?: boolean;
};

export const OptionButton: React.FC<OptionButtonProps> = ({
    onClick,
    children,
    isSelected,
    fullWidth = false,
    ...props
}) => {
    const { activeTheme } = useTheme();
    const [selected, setSelected] = useState(isSelected);

    const buttonClassName = useMemo(() => {
        return [
            styles.button,
            isSelected ? styles.selected : '',
            styles[activeTheme],
            fullWidth ? styles.full : ''
        ].join(' ');
    }, [activeTheme, fullWidth, isSelected]);

    return (
        <button
            onClick={() => {
                setSelected(!selected);
                onClick();
            }}
            className={buttonClassName}
            {...props}>
            {children}
        </button>
    );
};
