import { Accordion, Avatar, ButtonIcon, Link } from 'components/elements';
import { permissions } from 'config';
import { services } from 'constants/services';
import { useAuth, useLanguage, useWebShare } from 'hooks';
import { AreaType } from 'models/types';
import { UserType } from 'models/user';
import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import styles from './Menu.module.scss';

type MenuItemProps = {
    title: string;
    links: {
        label: string;
        to: string;
        isActive: boolean;
        permissions: UserType[];
    }[];
};

export type MenuProps = React.HTMLProps<HTMLDivElement> & {
    isOpen: boolean;
    setIsOpen: () => void;
};

type SectionProps = {
    setIsOpen: () => void;
};

const MenuHead: React.FC<SectionProps> = ({ setIsOpen }) => {
    const { user } = useAuth();
    if (!user) return null;

    return (
        <div className={styles['menu-head']}>
            <div className={styles.user}>
                <RouterLink to="/myhi">
                    <Avatar
                        source={user.image}
                        alt={user.name.short || user.name.first}
                        hasNotifications={user.new_notifications}
                    />
                </RouterLink>
                <span className={styles.name}>{user.name.short || user.name.first}</span>
                <span className={styles.points}>{user.performance.total_points} pts</span>
            </div>
            <Link
                label="MyHi!"
                type="button"
                size="xs"
                to="/myhi"
                disabled={location.pathname === '/myhi'}
                className={[styles['myhi-button']].join(' ')}>
                my hi
            </Link>
            <ButtonIcon
                icon="X"
                size="MD"
                backgroundOpacity="TRANSPARENT"
                className={styles['close-button']}
                onClick={setIsOpen}
            />
        </div>
    );
};
const MemoizedMenuHead = React.memo(MenuHead);

const SecondaryLinks: React.FC = () => {
    const navigate = useNavigate();
    const { isPT } = useLanguage();
    const { signOut, userCanAccess } = useAuth();
    const { canShare } = useWebShare();

    const canAccess = userCanAccess(permissions.general.share);

    return (
        <div className={styles['secondary-links']}>
            {canShare && canAccess && (
                <button className={styles.link} onClick={() => navigate('/partilhar')}>
                    {isPT ? 'Partilhar' : 'Share'}
                </button>
            )}
            <button className={styles.link} onClick={() => navigate('/preferencias')}>
                {isPT ? 'Preferências' : 'Preferences'}
            </button>
            <button className={styles.link} onClick={() => navigate('/acerca')}>
                {isPT ? 'Acerca' : 'About'}
            </button>
            <button className={styles.link} onClick={signOut}>
                {isPT ? 'Sair' : 'Exit'}
            </button>
        </div>
    );
};
const MemoizedSecondaryLinks = React.memo(SecondaryLinks);

const MenuNav: React.FC<SectionProps> = ({ setIsOpen }) => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { isPT } = useLanguage();

    /**
     * Temporary validation until new Promos area deploy to production
     */
    const { userCanAccess } = useAuth();

    const [menuItems, setMenuItems] = useState<MenuItemProps[]>([]);
    const [accordionsOpen, setAccordionsOpen] = useState([false, false, false, false, false]);

    const onAccordionClick = useCallback(
        (index: number) => setAccordionsOpen(accordionsOpen.map((_, i) => i === index)),
        [accordionsOpen]
    );

    const handleNavClick = useCallback(
        (slug) => {
            setIsOpen();
            setTimeout(() => navigate(slug), 60);
        },
        [navigate, setIsOpen]
    );

    const filterLinks = useCallback(
        (currentArea: Exclude<AreaType, 'bonus'>) =>
            services
                .filter(({ area }) => area === currentArea)
                .map(({ link, is_active, permissions }) => ({
                    label: isPT ? link.label.PT : link.label.EN,
                    to: link.slug,
                    isActive: is_active,
                    permissions
                })),
        [isPT]
    );

    useEffect(() => {
        setMenuItems([
            {
                title: isPT ? 'Movimento' : 'Movement',
                links: filterLinks('movement')
            },
            {
                title: isPT ? 'Nutrição' : 'Nutrition',
                links: filterLinks('nutrition')
            },
            {
                title: isPT ? 'Saúde' : 'Health',
                links: filterLinks('health')
            },
            {
                title: isPT ? 'Artigos' : 'Articles',
                links: [
                    {
                        label: isPT ? 'Recentes' : 'Latest',
                        to: '/artigos',
                        isActive: true,
                        permissions: permissions.articles.list
                    }
                ]
            },
            {
                title: isPT ? 'Promoções' : 'Promotions',
                links: [
                    {
                        label: isPT ? 'Recentes' : 'Latest',
                        to: '/promos',
                        isActive: true,
                        permissions: permissions.general.promos
                    }
                ]
            }
        ]);
    }, [filterLinks, isPT, userCanAccess]);

    if (!user) return null;

    return (
        <nav className={styles.nav}>
            {menuItems.map((it, i) => (
                <Accordion
                    key={it.title}
                    buttonTitle={it.title}
                    icon="ARROW"
                    isOpen={
                        accordionsOpen[i] ||
                        location.pathname.split('/').includes(String(it.links[0].to).slice(1))
                    }
                    onAccordionClick={() => onAccordionClick(i)}
                    size="LG"
                    className={styles.accordion}
                    borders={true}>
                    <div>
                        {it.links.map((l, i) => {
                            if (l.permissions.includes(user.type))
                                return (
                                    <button
                                        onClick={() => handleNavClick(l.to)}
                                        className={`${styles.link} ${
                                            location.pathname === String(l.to)
                                                ? styles.selected
                                                : ''
                                        }`}
                                        disabled={!l.isActive}
                                        key={i.toString()}>
                                        {l.label}
                                    </button>
                                );
                        })}
                    </div>
                </Accordion>
            ))}
            <MemoizedSecondaryLinks />
        </nav>
    );
};

const MemoizedMenuNav = React.memo(MenuNav);

export const Menu: React.FC<MenuProps> = ({ isOpen, setIsOpen, ...props }) => (
    <div className={`${styles.menu} ${isOpen ? styles.open : ''}`} {...props}>
        <MemoizedMenuHead setIsOpen={setIsOpen} />
        <MemoizedMenuNav setIsOpen={setIsOpen} />
    </div>
);

const MemoizedMenu = React.memo(Menu);
export default MemoizedMenu;
