import { LogoHi } from 'assets/logos';
import { CardArticleV2, Link, Tag } from 'components/elements';
import { permissions } from 'config';
import * as IMAGES from 'constants/images';
import { META_TAGS } from 'content';
import { useApi, useAuth, useLanguage, useTheme, useVideos } from 'hooks';
import { VideoData } from 'models/video';
import React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { COLORS } from 'theme';
import { IconPlay } from 'theme/icons';
import { formatTime, getRandomInt } from 'utils';

const ReactHlsPlayer = React.lazy(() => import('react-hls-player'));

import styles from './OnDemandDetail.module.scss';

type SectionData = {
    videoDetails: VideoData | undefined;
};

export const Player: React.FC<SectionData> = ({ videoDetails }) => {
    const { pathname } = useLocation();
    const { tag } = useParams();
    const { getVideo } = useApi();

    const [videoLink, setVideoLink] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const playerRef = useRef<HTMLVideoElement>(null);
    const [playVideo, setPlayVideo] = useState(false);

    const getVideoData = useCallback(
        async ({ tag }) => {
            try {
                setLoading(true);
                const link = await getVideo(tag);
                setVideoLink(link);
                setLoading(false);
            } catch (error) {
                // TO-DO
                console.log({ error });
            }
        },
        [getVideo]
    );

    const onPlay = useCallback(() => {
        setPlayVideo(true);
        if (playerRef.current) playerRef.current.play();
    }, []);

    const addDefaultSrc = useCallback((evt) => (evt.target.src = IMAGES.VIDEO_PLACEHOLDER), []);

    useEffect(() => {
        getVideoData({ tag });
    }, [getVideoData, tag]);

    useEffect(() => setPlayVideo(false), [pathname]);

    if (loading)
        return (
            <section className={styles['player-loading']}>
                <LogoHi animate showLettering={false} width={80} />
            </section>
        );

    if (!videoLink) return null;

    return (
        <section className={styles['player-container']}>
            <ReactHlsPlayer
                playerRef={playerRef}
                src={videoLink || ''}
                autoPlay={false}
                controls={true}
                height={240}
                playsInline
                className={`${styles.player} ${playVideo && styles.show}`}
            />
            <button
                className={`${styles['player-card']} ${!playVideo && styles.show}`}
                onClick={onPlay}>
                <img
                    src={videoDetails?.video.imageLink}
                    alt={videoDetails?.details.name}
                    onError={addDefaultSrc}
                />
                <IconPlay width={48} height={60} color={COLORS.BLUE_400} className={styles.icon} />
            </button>
        </section>
    );
};

export const Details: React.FC<SectionData> = ({ videoDetails }) => {
    const { activeTheme } = useTheme();

    const primaryDetails = `${videoDetails?.specs.level.toLocaleLowerCase()} • intensity ${
        videoDetails?.specs.intensity
    } • ${formatTime(videoDetails?.video.durationInSeconds || 0)}`;

    const validateInstructorName =
        videoDetails?.details.instructor !== '-' && videoDetails?.details.instructor !== 'N/A';

    const tagsLists = [
        {
            id: 1,
            label: 'Equipments',
            content: videoDetails?.details.equipments
        },
        {
            id: 2,
            label: 'labels',
            content: videoDetails?.details.labels
        }
    ];

    if (videoDetails)
        return (
            <>
                <section className={`${styles['primary-details']} ${styles[activeTheme]}`}>
                    {primaryDetails}
                </section>
                <section className={`${styles.details} container`}>
                    <span className={styles['pre-title']}>
                        {`${videoDetails.details.category.toLocaleLowerCase()} ${
                            videoDetails.details.subCategory ?? ''
                        }`}
                    </span>
                    <h1>{videoDetails.details.name}</h1>
                    <strong className={styles['sub-title']}>
                        {validateInstructorName && `with ${videoDetails.details.instructor}`}
                    </strong>
                    <p className={styles.description}>{videoDetails.details.description}</p>

                    {tagsLists &&
                        tagsLists.map((t) => {
                            if (t.content)
                                return (
                                    <div className={styles.specs} key={t.id.toString()}>
                                        <span className={styles.label}>{t.label}</span>
                                        <div className={styles['tags-holder']}>
                                            {t.content?.map((equipment, i) => (
                                                <Tag
                                                    key={i.toString()}
                                                    theme={activeTheme}
                                                    format="fill"
                                                    area="movement">
                                                    {equipment.toLocaleLowerCase()}
                                                </Tag>
                                            ))}
                                        </div>
                                    </div>
                                );
                        })}
                </section>
            </>
        );

    return null;
};

export const Recommended: React.FC<SectionData> = ({ videoDetails }) => {
    const { allVideos } = useVideos();
    const { tag } = useParams();
    const [recommendedVideos, setRecommendedVideos] = useState<VideoData[]>([] as VideoData[]);

    const getRecommendedVideos = useCallback(() => {
        if (!allVideos) return;

        const videos = allVideos.filter(
            (video) =>
                video.details.category === videoDetails?.details.category &&
                video.details.tag !== tag
        );
        const index = getRandomInt(0, videos.length - 2);
        return videos.splice(index, 2);
    }, [allVideos, tag, videoDetails]);

    useEffect(() => {
        if (!videoDetails?.details) return;
        const selectedVideos = getRecommendedVideos();
        if (!selectedVideos) return;
        setRecommendedVideos(selectedVideos);
    }, [getRecommendedVideos, videoDetails]);

    return (
        <section className={`${styles.recommended} container`}>
            <h2>You may also like</h2>
            <div className={styles['card-container']}>
                {recommendedVideos.map((r) => (
                    <CardArticleV2
                        key={r.details.tag.toString()}
                        to={`/movimento/videos/${r.details.tag}`}
                        image={r.video.imageLink}
                        title={r.details.name}
                        description={r.details.description}
                        category={r.details.category}
                        showCategory
                        duration={r.video.durationInSeconds}
                        level={r.specs.level}
                        date={r.video.publishedDate}
                    />
                ))}
            </div>
            <Link
                label="Voltar"
                size="xs"
                type="button"
                area="movement"
                to="/movimento/videos"
                className={styles['back-button']}
            />
        </section>
    );
};

export const OnDemandDetail: React.FC = () => {
    const navigate = useNavigate();
    const { tag } = useParams();
    const { allVideos } = useVideos();
    const { isPT } = useLanguage();
    const { validateUserAccessToArea } = useAuth();

    const [videoDetails, setVideoDetails] = useState<VideoData | undefined>(undefined);

    useEffect(
        () => validateUserAccessToArea(permissions.movement.onDemand),
        [validateUserAccessToArea]
    );

    const getVideoDetails = useCallback(() => {
        if (!allVideos) return;

        const selectedVideo = allVideos.filter(({ details }) => details.tag === tag).shift();

        if (!selectedVideo) {
            navigate('/movimento/videos');
            return;
        }
        setVideoDetails(selectedVideo);
    }, [allVideos, navigate, tag]);

    useEffect(getVideoDetails);

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? `${videoDetails?.details.name} ${META_TAGS.PRIVATE.MOVEMENT.ON_DEMAND_DETAIL.PT.TITLE}`
                        : `${videoDetails?.details.name} ${META_TAGS.PRIVATE.MOVEMENT.ON_DEMAND_DETAIL.EN.TITLE}`}
                </title>
            </Helmet>
            <Player videoDetails={videoDetails} />
            <Details videoDetails={videoDetails} />
            <Recommended videoDetails={videoDetails} />
        </>
    );
};
