import { ButtonIcon } from 'components/elements';
import { sanitize } from 'dompurify';
import { useTheme } from 'hooks';
import { AreaType } from 'models/area';
import { useState } from 'react';

import styles from './CardToggleV2.module.scss';

export type CardToggleV2Props = React.HTMLProps<HTMLDivElement> & {
    logo: React.ReactNode;
    content: string;
    area?: Exclude<AreaType, 'hps' | 'articles'>;
};

export const CardToggleV2: React.FC<CardToggleV2Props> = ({
    logo,
    content,
    area = 'movement',
    ...props
}) => {
    const { activeTheme } = useTheme();
    const [showCardBody, setShowCardBody] = useState(false);

    const cardClassName = `${styles.card} ${styles[area]} ${styles[activeTheme]}`;

    return (
        <div className={cardClassName} {...props}>
            <div className={styles['card-header']}>
                {logo}
                <ButtonIcon
                    icon={showCardBody ? 'X' : 'PLUS'}
                    backgroundOpacity="TRANSLUCID"
                    size="XS"
                    className={styles.button}
                    onClick={() => setShowCardBody((state) => !state)}
                />
            </div>
            {showCardBody && (
                <div
                    className={styles['card-body']}
                    dangerouslySetInnerHTML={{
                        __html: sanitize(content)
                    }}
                />
            )}
        </div>
    );
};
