// import { BgEffect } from 'assets/effects';
import { BgEffect } from 'assets/effects';
import { LogoHi } from 'assets/logos';
import {
    LangSelect,
    Link,
    SlideFiveWaving,
    SlideFourFlag,
    SlideOneMovement,
    SlideThreeHealth,
    SlideTwoNutrition
} from 'components/elements';
import { META_TAGS, WELCOME } from 'content';
import { useAuth, useLanguage } from 'hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { A11y, EffectFade, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

import styles from './Welcome.module.scss';

type IllustrationName = 'movement' | 'nutrition' | 'health' | 'flag' | 'waving';

type IllustrationProps = { name: IllustrationName; language: 'PT' | 'EN' };

type SLideProps = {
    id: number;
    className: string;
    illustration: IllustrationName;
    title: string;
    description: string;
};

const Illustration: React.FC<IllustrationProps> = ({ name, language }) => {
    switch (name) {
        case 'movement':
            return <SlideOneMovement title={language === 'PT' ? 'Movimento' : 'Movement'} />;
        case 'nutrition':
            return <SlideTwoNutrition title={language === 'PT' ? 'Nutrição' : 'Nutrition'} />;
        case 'health':
            return <SlideThreeHealth title={language === 'PT' ? 'Saúde' : 'Health'} />;
        case 'flag':
            return <SlideFourFlag />;
        case 'waving':
            return <SlideFiveWaving />;
        default:
            return null;
    }
};

export const Welcome: React.FC = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { isPT, currentLanguage } = useLanguage();

    const CONTENT = isPT ? WELCOME.PT : WELCOME.EN;

    const slides: SLideProps[] = useMemo(
        () => [
            {
                id: 1,
                className: styles.slideTwo,
                illustration: 'movement',
                title: CONTENT.SLIDES[0].TITLE,
                description: CONTENT.SLIDES[0].DESCRIPTION
            },
            {
                id: 2,
                className: styles.slideThree,
                illustration: 'nutrition',
                title: CONTENT.SLIDES[1].TITLE,
                description: CONTENT.SLIDES[1].DESCRIPTION
            },
            {
                id: 3,
                className: styles.slideFour,
                illustration: 'health',
                title: CONTENT.SLIDES[2].TITLE,
                description: CONTENT.SLIDES[2].DESCRIPTION
            },
            {
                id: 4,
                className: styles.slideFive,
                illustration: 'flag',
                title: CONTENT.SLIDES[3].TITLE,
                description: CONTENT.SLIDES[3].DESCRIPTION
            },
            {
                id: 5,
                className: styles.slideSix,
                illustration: 'waving',
                title: CONTENT.SLIDES[4].TITLE,
                description: CONTENT.SLIDES[4].DESCRIPTION
            }
        ],
        [CONTENT.SLIDES]
    );

    useEffect(() => {
        if (user) navigate('/inicio');
    }, [navigate, user]);

    const [isSlideOneActive, setIsSlideOneActive] = useState(false);

    const handleSlideIndex = useCallback(
        ({ activeIndex }: { activeIndex: number }) => setIsSlideOneActive(activeIndex === 0),
        []
    );

    return (
        <>
            <Helmet>
                <title>
                    {isPT ? META_TAGS.PUBLIC.WELCOME.PT.TITLE : META_TAGS.PUBLIC.WELCOME.EN.TITLE}
                </title>
            </Helmet>
            <Swiper
                modules={[Pagination, A11y, EffectFade]}
                effect="fade"
                pagination={{
                    clickable: true,
                    clickableClass: styles.pagination,
                    bulletClass: `${styles.bullet} ${!isSlideOneActive ? styles.purple : ''}`,
                    bulletActiveClass: styles.bulletActive
                }}
                spaceBetween={0}
                slidesPerView={1}
                onSwiper={({ activeIndex }) => handleSlideIndex({ activeIndex })}
                onSlideChange={({ activeIndex }) => handleSlideIndex({ activeIndex })}
                className={styles.slider}>
                <SwiperSlide className={styles.slide}>
                    <div className={styles.slideOne}>
                        <h1>{CONTENT.TITLE}</h1>
                        <Link label={CONTENT.LINK.LOGIN} to="/login" type="button" size="md" />
                        <p>
                            {CONTENT.LINK.SIMULATION[0]}
                            <a
                                href="https://simulador.hihealthyinsurance.com/"
                                target="_blank"
                                rel="noopener noreferrer">
                                {CONTENT.LINK.SIMULATION[1]}
                            </a>
                            .
                        </p>
                    </div>
                    <BgEffect />
                </SwiperSlide>
                {slides.map((s) => (
                    <SwiperSlide className={styles.slide} key={s.id.toString()}>
                        <div className={s.className}>
                            <Illustration name={s.illustration} language={currentLanguage} />
                            <div className={styles.content}>
                                <h2>{s.title}</h2>
                                <p>{s.description}</p>
                                {s.illustration === 'waving' && (
                                    <Link
                                        label={CONTENT.LINK.LOGIN}
                                        to="/login"
                                        type="button"
                                        size="md"
                                    />
                                )}
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
                <span slot="container-start">
                    <LogoHi
                        animate
                        showLettering={false}
                        width={50}
                        className={`${styles.logo} ${isSlideOneActive ? styles.home : ''}`}
                    />
                </span>
                <LangSelect className={styles['lang-button']} />
            </Swiper>
        </>
    );
};
