export const PREFERENCES = {
    PT: {
        MENU: {
            TITLE: 'Preferências',
            NAME: 'Nome',
            PASSWORD: 'Palavra-Passe',
            PROFILE_PHOTO: 'Foto de Perfil',
            LANGUAGE: 'Idioma',
            THEME: 'Tema'
        },
        NAME: {
            MESSAGES: {
                NAME_REQUIRED: 'Adicione o seu nome',
                SUCCESS: 'O seu nome foi atualizado com sucesso',
                API_ERROR: 'Ocorreu um erro na gravação do seu nome. Tente novamente.'
            },
            INTRO: {
                TITLE: 'Nome',
                DESCRIPTION: 'Este será o nome que o identificará na app'
            },
            FORM: {
                LABELS: {
                    NAME: 'Nome',
                    SUBMIT: 'Alterar'
                }
            }
        },
        PASSWORD: {
            MESSAGES: {
                PASSWORD: {
                    REQUIRED: 'Adicione uma nova palavra-passe',
                    INVALID: 'Insira uma palavra-passe com o mínimo de 6 caracteres alfa-numéricos',
                    CONFIRM: 'A palavra-passe não coincide',
                    SUCCESS: 'Palavra-passe atualizada com sucesso',
                    API_ERROR: 'Ocorreu um erro na gravação da sua palavra-passe. Tente novamente.'
                }
            },
            INTRO: {
                TITLE: 'Palavra-passe',
                DESCRIPTION: 'Altere a sua palavra-passe'
            },
            FORM: {
                LABELS: {
                    PASSWORD: {
                        NEW: 'nova palavra-passe',
                        CONFIRM: 'confirme palavra-passe'
                    },
                    SUBMIT: 'Alterar'
                }
            }
        },
        PROFILE_PHOTO: {
            INTRO: {
                TITLE: 'Foto de perfil',
                DESCRIPTION: 'Actualize a sua foto'
            },
            BUTTON_LABEL: 'Editar'
        },
        LANGUAGE: {
            INTRO: {
                TITLE: 'Idioma',
                DESCRIPTION: 'Selecione o seu idioma'
            },
            LABELS: {
                PT: 'Português',
                EN: 'Inglês'
            }
        },
        THEME: {
            INTRO: {
                TITLE: 'Tema',
                DESCRIPTION: 'Altere o ambiente visual da sua app'
            },
            LABELS: {
                DARK: 'Escuro',
                LIGHT: 'Claro'
            }
        }
    },
    EN: {
        MENU: {
            TITLE: 'Preferences',
            NAME: 'Name',
            PASSWORD: 'Password',
            PROFILE_PHOTO: 'Profile Photo',
            LANGUAGE: 'Language',
            THEME: 'Theme'
        },
        NAME: {
            MESSAGES: {
                NAME_REQUIRED: 'Add your name',
                SUCCESS: 'Your name has been successfully updated',
                API_ERROR: 'An error occurred when recording your name. Please try again.'
            },
            INTRO: {
                TITLE: 'Name',
                DESCRIPTION: 'This will be the name that identifies you in the app'
            },
            FORM: {
                LABELS: {
                    NAME: 'Name',
                    SUBMIT: 'Update'
                }
            }
        },
        PASSWORD: {
            MESSAGES: {
                PASSWORD: {
                    REQUIRED: 'Add a new password',
                    INVALID: 'Enter a password with a minimum of 6 alpha-numeric characters',
                    CONFIRM: 'The password does not match',
                    SUCCESS: 'Password successfully updated',
                    API_ERROR: 'An error has occurred while saving your password. Please try again.'
                }
            },
            INTRO: {
                TITLE: 'Password',
                DESCRIPTION: 'Change your password'
            },
            FORM: {
                LABELS: {
                    PASSWORD: {
                        NEW: 'new password',
                        CONFIRM: 'confirm password'
                    },
                    SUBMIT: 'Update'
                }
            }
        },
        PROFILE_PHOTO: {
            INTRO: {
                TITLE: 'Profile photo',
                DESCRIPTION: 'Update your photo'
            },
            BUTTON_LABEL: 'Edit'
        },
        LANGUAGE: {
            INTRO: {
                TITLE: 'Language',
                DESCRIPTION: 'Select your language'
            },
            LABELS: {
                PT: 'Portuguese',
                EN: 'English'
            }
        },
        THEME: {
            INTRO: {
                TITLE: 'Theme',
                DESCRIPTION: 'Change the visual environment of your app'
            },
            LABELS: {
                DARK: 'Dark',
                LIGHT: 'Light'
            }
        }
    }
};
