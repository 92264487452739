import { useLanguage, useTheme } from 'hooks';
import { AreaType } from 'models/types';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
    IconArticleV2,
    IconHealthV2,
    IconHomeV2,
    IconMovementV2,
    IconNutritionV2,
    IconPromo
} from 'theme/icons';

import styles from './NavBar.module.scss';

export type NavBarProps = React.HTMLAttributes<HTMLDivElement> & {
    activeArea: AreaType | 'home';
};

type ButtonProps = {
    id: string;
    to: string;
    area: AreaType | 'home';
    label: { PT: string; EN: string };
    active: boolean;
    notification: boolean;
};

type IconProps = { area: AreaType | 'home'; isSelected: boolean };

const Icon: React.FC<IconProps> = ({ area, isSelected }) => {
    const { activeTheme } = useTheme();

    const props = { className: styles[activeTheme], isSelected };

    switch (area) {
        case 'movement':
            return <IconMovementV2 {...props} />;
        case 'nutrition':
            return <IconNutritionV2 {...props} />;
        case 'health':
            return <IconHealthV2 {...props} />;
        case 'articles':
            return <IconArticleV2 {...props} />;
        case 'promos':
            return <IconPromo {...props} />;
        case 'home':
            return <IconHomeV2 {...props} />;
        default:
            return null;
    }
};

export const NavBar: React.FC<NavBarProps> = ({ activeArea, ...props }) => {
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();

    const BUTTONS: ButtonProps[] = useMemo(
        () => [
            {
                id: '1',
                to: '/movimento',
                area: 'movement',
                label: { PT: 'movimento', EN: 'movement' },
                active: true,
                notification: false
            },
            {
                id: '2',
                to: '/nutricao',
                area: 'nutrition',
                label: { PT: 'nutrição', EN: 'nutrition' },
                active: true,
                notification: false
            },
            {
                id: '3',
                to: '/saude',
                area: 'health',
                label: { PT: 'saúde', EN: 'health' },
                active: true,
                notification: false
            },
            // {
            //     id: '4',
            //     to: '/artigos',
            //     area: 'articles',
            //     label: { PT: 'artigos', EN: 'articles' },
            //     active: !userCanAccess(PERMISSIONS.GENERAL.PROMOS),
            //     notification: false
            // },
            {
                id: '5',
                to: '/promos',
                area: 'promos',
                label: { PT: 'promos', EN: 'promos' },
                active: true,
                notification: false
            },
            {
                id: '6',
                to: '/inicio',
                area: 'home',
                label: { PT: 'início', EN: 'home' },
                active: true,
                notification: false
            }
        ],
        []
    );

    return (
        <nav className={[styles.navbar, styles[activeTheme], 'tour-step-2'].join(' ')} {...props}>
            {BUTTONS.map((b) => {
                if (b.active)
                    return (
                        <Link
                            to={b.to}
                            className={[
                                styles.link,
                                activeArea === b.area ? styles.selected : '',
                                b.area === 'promos' ? 'tour-step-3' : '',
                                b.area === 'promos' ? 'tour-nav-promos' : '',
                                b.notification ? styles.notification : '',
                                styles[activeTheme]
                            ].join(' ')}
                            key={b.id}>
                            <span className={styles.icon}>
                                <Icon area={b.area} isSelected={activeArea === b.area} />
                            </span>
                            <span className={[styles.label, styles[activeTheme]].join(' ')}>
                                {isPT ? b.label.PT : b.label.EN}
                            </span>
                        </Link>
                    );
            })}
        </nav>
    );
};

const MemoizedNavBar = React.memo(
    NavBar,
    (prevProps, nextProps) => prevProps.activeArea !== nextProps.activeArea
);
export default MemoizedNavBar;
