import { LogoAdvanceCare, LogoFitnessHut, LogoZumub } from 'assets/logos';
import { CardToggleV1 } from 'components/elements';
import { sanitize } from 'dompurify';
import { useAuth, useTheme } from 'hooks';

import styles from './Subscriptions.module.scss';

type SubscriptionsProps = {
    content: {
        TITLE: string;
        CARDS: {
            MOVEMENT: {
                TITLE: string;
                DESCRIPTION: string;
            };
            NUTRITION: {
                TITLE: string;
                DESCRIPTION: string;
            };
            HEALTH: {
                TITLE: string;
                DESCRIPTION: string;
            };
        };
    };
};

export const Subscriptions: React.FC<SubscriptionsProps> = ({ content }) => {
    const { user } = useAuth();
    const { darkMode, activeTheme } = useTheme();

    if (!user) return null;

    return (
        <section className={[styles.subscriptions, styles[activeTheme], 'container'].join(' ')}>
            <h2>{content.TITLE}</h2>
            <CardToggleV1
                title={content.CARDS.MOVEMENT.TITLE}
                area="movement"
                showStatus
                status={user.subscriptions.gym.active}
                className={styles.card}>
                <div className={styles.logoContainer}>
                    <LogoFitnessHut
                        color={darkMode ? 'negative' : 'default'}
                        width={200}
                        height={50}
                    />
                </div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: sanitize(content.CARDS.MOVEMENT.DESCRIPTION)
                    }}
                />
            </CardToggleV1>
            <CardToggleV1
                title={content.CARDS.NUTRITION.TITLE}
                area="nutrition"
                showStatus
                status={user.subscriptions.nutrition.active}
                className={styles.card}>
                <div className={styles.logoContainer}>
                    <LogoZumub color={darkMode ? 'negative' : 'default'} width={200} height={46} />
                </div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: sanitize(content.CARDS.NUTRITION.DESCRIPTION)
                    }}
                />
            </CardToggleV1>
            <CardToggleV1
                title={content.CARDS.HEALTH.TITLE}
                area="health"
                showStatus
                status={user.subscriptions.nutrition.active}
                className={styles.card}>
                <div className={styles.logoContainer}>
                    <LogoAdvanceCare
                        color={darkMode ? 'negative' : 'default'}
                        width={200}
                        height={50}
                    />
                </div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: sanitize(content.CARDS.HEALTH.DESCRIPTION)
                    }}
                />
            </CardToggleV1>
        </section>
    );
};
