import { useOnScreen, useTheme } from 'hooks';
import { AreaType } from 'models/area';
import { useEffect, useMemo, useRef, useState } from 'react';
import CountUp from 'react-countup';
import { useLocation } from 'react-router-dom';
import { triggerDevMode } from 'utils';

import styles from './GraphProgress.module.scss';

type ToolTipProps = {
    value: number | undefined;
    percentage: number;
    animation?: {
        delay: number;
    };
    area: AreaType;
    gradient?: boolean;
};

export type GraphProgressProps = React.HTMLProps<HTMLDivElement> & {
    toolTip?: number;
    percentage: number;
    animation?: {
        delay: number;
    };
    label?: string;
    area: AreaType;
    gradient?: boolean;
    level?: number;
};

const DEFAULT_TRANSITION_DELAY = 0;
const TRANSITION_DURATION = 0;

const ToolTip: React.FC<ToolTipProps> = ({ area, value, percentage, animation, gradient }) => {
    const { activeTheme } = useTheme();
    const [show, setShow] = useState(false);

    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);

    const timeOut = useMemo(
        () =>
            animation?.delay
                ? animation?.delay + TRANSITION_DURATION
                : DEFAULT_TRANSITION_DELAY + TRANSITION_DURATION,
        [animation?.delay]
    );

    useEffect(() => {
        setTimeout(() => setShow(true), timeOut);
    }, [timeOut]);

    const gradientClassName = useMemo(() => (gradient ? styles.gradient : ''), [gradient]);

    if (!value) return null;

    return (
        <div
            ref={ref}
            className={[
                styles.tooltip,
                styles[area],
                styles[activeTheme],
                gradientClassName,
                percentage < 20 ? styles.reverse : '',
                show ? styles.show : ''
            ].join(' ')}
            style={{ left: percentage < 20 ? '100%' : 'unset' }}>
            <div className={styles.triangle}>
                <div />
            </div>
            {isVisible && (
                <CountUp
                    start={value > 200 ? value - 100 : value}
                    end={value}
                    duration={3}
                    delay={0}
                    className={styles.counter}
                />
            )}
        </div>
    );
};

export const GraphProgress: React.FC<GraphProgressProps> = ({
    label = '',
    percentage,
    toolTip,
    animation,
    area,
    gradient = false,
    level,
    className = '',
    ...props
}) => {
    const { activeTheme } = useTheme();

    const location = useLocation();
    const DEV = triggerDevMode(location.search);

    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);

    const gradientClassName = useMemo(() => (gradient ? styles.gradient : ''), [gradient]);
    const devClassName = useMemo(() => (DEV ? styles.dev : ''), [DEV]);

    return (
        <div
            className={[
                styles.graph,
                styles[area],
                styles[activeTheme],
                devClassName,
                className
            ].join(' ')}
            {...props}>
            {!toolTip && (
                <div className={styles.labels}>
                    <span>{label}</span>
                    <CountUp
                        start={percentage <= 10 ? 0 : percentage - 10}
                        end={percentage}
                        duration={0.5}
                        delay={0}
                        suffix="%"
                        className={styles.counter}
                    />
                </div>
            )}
            <div className={styles['background-bar']} />
            <div
                ref={ref}
                className={[styles['progress-bar'], gradientClassName].join(' ')}
                style={{
                    width: `${isVisible ? (percentage <= 100 ? percentage : 100) : 2}%`,
                    transitionDelay: `${animation?.delay ?? DEFAULT_TRANSITION_DELAY}ms`
                }}>
                <ToolTip
                    value={toolTip}
                    percentage={percentage}
                    area={area}
                    animation={animation}
                    gradient={gradient}
                />
            </div>
            {level && <div className={[styles.level, styles[area]].join(' ')}>{level + 1}</div>}
        </div>
    );
};
