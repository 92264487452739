import { AreaType } from 'models/types';
import React from 'react';

import styles from './Tag.module.scss';

export type TagProps = React.HTMLProps<HTMLButtonElement> & {
    isDisabled?: boolean;
    isButton?: boolean;
    theme?: 'dark' | 'light';
    area?: AreaType | 'none';
    format?: 'outline' | 'fill';
    tagSize?: 'xs' | 'sm';
    square?: boolean;
    className?: string;
};

const TagComponent: React.FC<TagProps> = ({
    isDisabled = false,
    isButton = false,
    theme = 'dark',
    area = 'none',
    format = 'outline',
    tagSize = 'xs',
    square = false,
    className = '',
    children
}) => {
    const classNameGroup = `
    ${styles.tag}
    ${isButton ? styles.button : ''}
    ${format === 'outline' ? styles.outline : styles.fill}
    ${tagSize === 'xs' ? styles.xs : styles.sm}
    ${square ? styles.square : ''}
    ${theme === 'dark' ? styles.dark : styles.light}
    ${area === 'health' ? styles.health : ''}
    ${area === 'movement' ? styles.movement : ''}
    ${area === 'nutrition' ? styles.nutrition : ''}
    ${className}
    `.trim();

    if (isButton) {
        return (
            <button className={classNameGroup} disabled={isDisabled}>
                {children}
            </button>
        );
    }
    return <div className={classNameGroup}>{children}</div>;
};

export const Tag = React.memo(
    TagComponent,
    (prevProps, nextProps) => prevProps.children !== nextProps.children
);
