import { useAuth } from 'hooks';
import { Navigate } from 'react-router-dom';

type PrivateProps = {
    element: JSX.Element;
};

export const Private: React.FC<PrivateProps> = ({ element }) => {
    const { user } = useAuth();
    return user ? <>{element}</> : <Navigate to="/" />;
};
