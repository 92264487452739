type Round = (
    value: number,
    minimumFractionDigits: number,
    maximumFractionDigits: number
) => number;

export const round: Round = (value, minimumFractionDigits, maximumFractionDigits) => {
    const formattedValue = value.toLocaleString('en', {
        useGrouping: false,
        minimumFractionDigits,
        maximumFractionDigits
    });
    return Number(formattedValue);
};
