import { ScoreBadge } from 'components/elements';
import { useApi, useLanguage } from 'hooks';
import { SuggestedActivity } from 'models/types';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconPlus } from 'theme/icons';
import { areaToPT } from 'utils';

import styles from './SuggestedActivities.module.scss';

type SuggestedActivitiesProps = { title: string };

export const SuggestedActivities: React.FC<SuggestedActivitiesProps> = ({ title }) => {
    const { isPT } = useLanguage();
    const { getSuggestedActivities } = useApi();

    const [activities, setActivities] = useState<SuggestedActivity[]>([]);

    const fetchData = useCallback(async () => {
        const activities = await getSuggestedActivities();
        setActivities(activities);
    }, [getSuggestedActivities]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <section className={`${styles.activities} container`}>
            <h2>{title}</h2>
            {activities.map((a) => (
                <RouterLink
                    key={a.id.toString()}
                    to={`/${a.slug}` || '/pontos'}
                    className={styles.activity}>
                    <ScoreBadge area={a.area} points={a.points} />
                    <div className={styles.content}>
                        <strong>{isPT ? areaToPT(a.area) : a.area}</strong>
                        <span>{isPT ? a.name.pt : a.name.en || a.name.pt}</span>
                    </div>
                    <span className={styles.icon}>
                        <IconPlus />
                    </span>
                </RouterLink>
            ))}
        </section>
    );
};
