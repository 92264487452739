export const NUTRITION_HOME = {
    PT: {
        INTRO: {
            TITLE: 'Nutrição'
        },
        PARTNERS: {
            TITLE: 'Parceiros',
            DESCRIPTION: 'As melhores vantagens por ser Hi!'
        },
        POINTS: {
            TITLE: 'Pontos'
        },
        TIP: {
            TITLE: 'dica da semana'
        },
        PROMOS: {
            TITLE: 'Promos',
            BUTTON_LABEL: 'comprar',
            NOTE: 'ao selecionar o botão "comprar" será redirecionado/a para a loja online '
        },
        ACTIVITIES: {
            TITLE: {
                SINGULAR: 'Atividades sugeridas',
                PLURAL: 'Atividade sugerida'
            },
            DESCRIPTION: 'Conquiste mais pontos'
        }
    },
    EN: {
        INTRO: {
            TITLE: 'Nutrition'
        },
        PARTNERS: {
            TITLE: 'Partners',
            DESCRIPTION: 'The best advantages of being Hi!'
        },
        POINTS: {
            TITLE: 'Points'
        },
        TIP: {
            TITLE: 'tip of the week'
        },
        PROMOS: {
            TITLE: 'Promos',
            BUTTON_LABEL: 'buy',
            NOTE: 'by selecting the "buy" button you will be redirected to the online shop '
        },
        ACTIVITIES: {
            TITLE: {
                SINGULAR: 'Suggested activities',
                PLURAL: 'Suggested activity'
            },
            DESCRIPTION: 'Score more points'
        }
    }
};
