import { useTheme } from 'hooks';
import { useMemo } from 'react';
import { COLORS } from 'theme/colors';
import { IconAreaV2Props } from 'theme/icons/types';

export const IconNutritionV2: React.FC<IconAreaV2Props> = ({
    width = 30,
    height = 30,
    isSelected,
    ...props
}) => {
    const { darkMode } = useTheme();

    const defaultColor = useMemo(
        () => ({
            fill: darkMode ? COLORS.BLUISH_PURPLE_800 : COLORS.WHITE,
            stroke: darkMode ? COLORS.PURPLE_300 : COLORS.MAGENTA_200
        }),
        [darkMode]
    );

    const selectedColor = useMemo(
        () => ({
            fill: darkMode ? COLORS.BLUISH_PURPLE_800 : COLORS.WHITE,
            stroke: darkMode ? COLORS.WHITE : COLORS.PURPLE_500
        }),
        [darkMode]
    );

    const filter = useMemo(
        () => (darkMode ? 'url(#dark-shadow)' : 'url(#light-shadow)'),
        [darkMode]
    );

    return (
        <svg
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            {...props}>
            <g filter={filter}>
                <path
                    d="M21.1642 8.91457C19.3714 8.41552 17.1833 8.79492 15.3968 9.87473C15.6687 4.64785 17.6608 3.68477 17.7019 3.66434C17.7524 3.65117 17.7993 3.62812 17.8394 3.59675C17.8795 3.56539 17.9118 3.52644 17.9342 3.48255C17.9565 3.43866 17.9684 3.39084 17.969 3.34234C17.9696 3.29385 17.9589 3.2458 17.9376 3.20145C17.9163 3.15711 17.885 3.1175 17.8457 3.08531C17.8064 3.05312 17.76 3.0291 17.7098 3.01488C17.6595 3.00066 17.6066 2.99657 17.5545 3.00288C17.5024 3.00919 17.4524 3.02577 17.4078 3.05148C17.3351 3.08066 16.162 3.58555 15.3652 5.86191C15.0036 4.93374 14.2746 4.1664 13.3248 3.71428C12.3751 3.26216 11.275 3.15882 10.2461 3.42503C10.184 3.44068 10.1275 3.47099 10.082 3.51295C10.0366 3.55492 10.0037 3.6071 9.98678 3.66434C9.68687 4.66513 9.82982 5.73489 10.3842 6.63842C10.9386 7.54195 11.859 8.20531 12.9432 8.48265C13.3167 8.57882 13.7031 8.62598 14.0909 8.62273C14.3175 8.6222 14.5438 8.60562 14.7676 8.57312C14.717 8.99045 14.6791 9.44864 14.6538 9.91559C12.861 8.80367 10.6445 8.40969 8.83584 8.91457C4.91189 10.0148 2.98312 14.9148 4.53879 19.8382C5.43045 22.6691 7.36238 25.0505 9.7022 26.2179C10.4172 26.6035 11.2093 26.8517 12.0309 26.9475C12.8525 27.0433 13.6866 26.9847 14.483 26.7753C14.6581 26.7266 14.8302 26.6691 14.9984 26.6031C15.168 26.6686 15.3411 26.726 15.517 26.7753C16.048 26.9243 16.5999 27 17.1549 27C18.2568 26.9854 19.3372 26.7165 20.2978 26.2179C22.6376 25.0505 24.5696 22.6603 25.4612 19.8295C27.0169 14.9119 25.0881 10.0119 21.1642 8.91457Z"
                    fill={isSelected ? selectedColor.fill : defaultColor.fill}
                />
                <path
                    d="M15.0762 10.822C15.352 10.9153 15.6589 10.8848 15.9141 10.7305C17.5041 9.76949 19.4096 9.4644 20.8954 9.87779C24.0648 10.7645 25.9666 14.9164 24.5078 19.5278L24.5074 19.529C23.6852 22.1394 21.9148 24.2936 19.8514 25.323L19.8371 25.3303C19.0194 25.7548 18.0949 25.9864 17.1482 26C16.6862 25.9994 16.2275 25.936 15.7871 25.8125L15.7865 25.8123C15.6412 25.7716 15.4983 25.7242 15.3586 25.6702C15.1251 25.58 14.8662 25.5807 14.6331 25.6722C14.4991 25.7248 14.3618 25.7708 14.2219 25.81C13.5487 25.9861 12.8426 26.0354 12.1467 25.9542C11.4485 25.8728 10.7785 25.6622 10.1769 25.3377C10.1676 25.3327 10.1582 25.3278 10.1486 25.323C8.08404 24.293 6.31434 22.1467 5.49259 19.5378L5.49232 19.5369C4.03329 14.9194 5.93525 10.7669 9.10525 9.8776C10.6031 9.45968 12.5329 9.7769 14.1267 10.7654C14.4142 10.9437 14.7714 10.9641 15.0762 10.822ZM14.8312 6.70742C14.7635 6.98035 14.7014 7.27101 14.6461 7.58053C14.6387 7.58143 14.6313 7.58242 14.6239 7.58349C14.447 7.60918 14.268 7.62232 14.0886 7.62272L14.0826 7.62277C13.7812 7.62529 13.4814 7.58861 13.1925 7.51422L13.191 7.51385C12.3432 7.29695 11.6469 6.78419 11.2366 6.11544C10.897 5.56199 10.7703 4.93187 10.8624 4.31891C11.5562 4.21584 12.2703 4.3198 12.895 4.6172C13.629 4.96659 14.1698 5.5483 14.4334 6.2249C14.5129 6.42891 14.6544 6.59574 14.8312 6.70742Z"
                    stroke={isSelected ? selectedColor.stroke : defaultColor.stroke}
                    strokeWidth="2"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="light-shadow"
                    x="2"
                    y="3"
                    width="26"
                    height="28"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_490_723"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_490_723"
                        result="shape"
                    />
                </filter>
                <filter
                    id="dark-shadow"
                    x="2"
                    y="3"
                    width="26"
                    height="28"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_490_723"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_490_723"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
