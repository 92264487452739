import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconCamera: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                id="I-CAMERA"
                d="M683.28,439.27a3.23,3.23,0,1,0,3.25,3.22,3.22,3.22,0,0,0-3.25-3.22m10-1.75a2.23,2.23,0,0,0-2.12-2.34h-2.81v-.72a2,2,0,0,0-1.47-2,2.1,2.1,0,0,0-.56-.07h-6.07a2,2,0,0,0-2,1.91v.87h-2.59a2.22,2.22,0,0,0-2.31,1.92.05.05,0,0,1,0,0v10.77a4.56,4.56,0,0,0,.24.71,2.12,2.12,0,0,0,1.86,1.26H691a2.23,2.23,0,0,0,1.92-.93,2.35,2.35,0,0,0,.42-1.43Zm-10,9.86a4.89,4.89,0,1,1,4.88-4.89,4.9,4.9,0,0,1-4.88,4.89"
                transform="translate(-673.3 -431.15)"
            />
        </svg>
    );
};
