import { CardMember } from 'components/elements';
import { useAuth, useTheme } from 'hooks';

import styles from './MemberCard.module.scss';

type MemberCardProps = {
    content: {
        TITLE: string;
    };
};

export const MemberCard: React.FC<MemberCardProps> = ({ content }) => {
    const { user } = useAuth();
    const { activeTheme } = useTheme();

    if (!user) return null;
    return (
        <section className={[styles.memberCard, styles[activeTheme], 'container'].join(' ')}>
            <h2>{content.TITLE}</h2>
            <CardMember
                name={user.name.short || user.name.full}
                cardNumber={user.membership.number}
                membershipPlan={user.membership.name}
                membershipDate={user.membership.start_date}
            />
        </section>
    );
};
