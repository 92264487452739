import { Main, NavBar } from 'components/elements';
import { Header } from 'components/sections';
import { permissions } from 'config';
import { CLIENT_AREA, META_TAGS } from 'content';
import { useAuth, useLanguage } from 'hooks';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import {
    AccountStatus,
    Cancellation,
    Documents,
    Intro,
    MemberCard,
    Subscriptions
} from './Modules';
import { Calendar } from './Modules/Calendar';

export const ClientArea: React.FC = () => {
    const { validateUserAccessToArea } = useAuth();
    const { isPT } = useLanguage();

    const CONTENT = isPT ? CLIENT_AREA.PT : CLIENT_AREA.EN;

    useEffect(
        () => validateUserAccessToArea(permissions.general.clientArea),
        [validateUserAccessToArea]
    );

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? META_TAGS.PRIVATE.GENERAL.CLIENT_AREA.PT.TITLE
                        : META_TAGS.PRIVATE.GENERAL.CLIENT_AREA.EN.TITLE}
                </title>
            </Helmet>
            <Header backButton hasImage={false} />
            <Main>
                <Intro content={CONTENT.AVATAR} />
                <MemberCard content={CONTENT.MEMBER_CARD} />
                <Subscriptions content={CONTENT.SUBSCRIPTIONS} />
                <AccountStatus content={CONTENT.ACCOUNT_STATUS} />
                <Calendar content={CONTENT.CALENDAR} />
                <Documents content={CONTENT.DOCUMENTS} />
                <NavBar activeArea="hps" />
                <Cancellation />
            </Main>
        </>
    );
};
