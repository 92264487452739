import { Search } from 'components/elements';
import { useTheme } from 'hooks';

import { useStaff } from '../../useStaff';
import styles from './Search.module.scss';

export const SearchSection: React.FC = () => {
    const { activeTheme } = useTheme();
    const { CONTENT, staff, setNotFound, setFilteredContent, resetSearch, setResetSearch } =
        useStaff();

    return (
        <section className={`${styles.search} container`}>
            <Search
                placeholder={CONTENT.SEARCH.LABEL}
                className={styles.input}
                setNotFound={setNotFound}
                searchContent={staff.map((s) => ({ id: s.id, data: s.name }))}
                onInputFocus={() => setResetSearch(false)}
                resetSearch={resetSearch}
                setFilteredContent={setFilteredContent}
                theme={activeTheme}
            />
        </section>
    );
};
