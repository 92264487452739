import { LogoHi } from 'assets/logos';
import { Link } from 'components/elements';
import { useLanguage, useWebShare } from 'hooks';
import { memo, useMemo } from 'react';

import styles from './SharePromoBanner.module.scss';

type Data = {
    PT: {
        title: string;
        message: string;
        button: string;
    };
    EN: {
        title: string;
        message: string;
        button: string;
    };
};

const data: Data = {
    PT: {
        title: 'Partilhe momentos Hi!',
        message:
            'Partilhe os seus treinos, as suas experiências, evolução e resultados, com a marca Hi! nas redes sociais e ganhe pontos.',
        button: 'Partilhar'
    },
    EN: {
        title: 'Share Hi! moments',
        message:
            'Share your workouts, experiences, progress and results with the Hi! brand on social networks and earn points.',
        button: 'Share'
    }
};

export const SharePromoBanner: React.FC = () => {
    const { isPT } = useLanguage();
    const { canShare } = useWebShare();

    const content = useMemo(() => (isPT ? data.PT : data.EN), [isPT]);

    if (!canShare) return null;

    return (
        <section className={`${styles.banner} container`}>
            <span className={styles.emojis}>
                😍 <LogoHi showLettering={false} width={64} animate /> 👍
            </span>
            <strong>{content.title}</strong>
            <span>{content.message}</span>
            <Link
                to="/partilhar"
                label={content.button}
                type="button"
                size="xs"
                icon="share"
                area="movement"
                iconPosition="RIGHT"
                className={styles.btn}
            />
        </section>
    );
};

export const MemoizedSharePromoBanner = memo(SharePromoBanner);
