import { useLanguage } from 'hooks';

import styles from './CardBooking.module.scss';

export type CardBookingProps = React.HTMLProps<HTMLDivElement> & {
    id?: string;
    price: number;
    category: {
        pt: string;
        en: string;
    };
    description: {
        pt: string;
        en: string;
    };
    features: {
        id: string;
        name: string;
    }[];
};

export const CardBooking: React.FC<CardBookingProps> = ({
    price,
    category,
    // features,
    description,
    ...props
}) => {
    const { isPT } = useLanguage();
    return (
        <div className={styles.card} {...props}>
            <span className={styles.price}>
                <span>€</span>
                {price}
            </span>
            <span className={styles.category}>{isPT ? category.pt : category.en}</span>
            <span>{isPT ? description.pt : description.en}</span>
            {/* <ul>
                {features.map(({ name, id }) => (
                    <li key={id.toString()}>{name}</li>
                ))}
            </ul> */}
            {/* <Link label="Agendar" to="/" type="button" area="default" size="xs" /> */}
        </div>
    );
};
