import { AreaType } from 'models/types';
import { areaToPT } from 'utils';

import styles from './CardHalfV1.module.scss';

export type CardHalfV1Props = {
    id?: string;
    area?: AreaType;
    title: string;
    backgroundImage: string;
    textStyle?: 'light' | 'dark';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const CardHalfV1: React.FC<CardHalfV1Props> = ({
    area = 'movement',
    title,
    backgroundImage,
    textStyle = 'light',
    ...props
}) => {
    return (
        <button
            className={`${styles.card} ${textStyle === 'light' ? styles.light : styles.dark}`}
            style={{ backgroundImage: `url('${backgroundImage}')` }}
            {...props}>
            <span className={styles.area}>{areaToPT(area)}</span>
            <span className={styles.title}>{title}</span>
        </button>
    );
};
