import { COLORS } from 'theme';

export type IllustrationProps = React.HtmlHTMLAttributes<HTMLOrSVGElement> & { title?: string };

export const SlideOneMovement: React.FC<IllustrationProps> = ({ title = 'Movimento' }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 280 336"
        xmlSpace="preserve">
        <symbol id="i-calendar" viewBox="-18 -17.8 35.9 35.6">
            <path
                fill={COLORS.PURPLE_300}
                d="M-10.7 3.3c-.1-.3 0-.6.1-.8.1-.3.4-.5.7-.5L-.5-.7c.4-.1.8 0 1.1.3.3.3.4.7.3 1-.2.3-.5.6-.8.7L-9.3 4c-.3.1-.6 0-.8-.1a.9.9 0 0 1-.6-.6zm22-17.7 6.6 23c.1.3 0 .6-.1.8-.1.3-.4.4-.7.5L-10 17.7c-.3.1-.6 0-.8-.1-.3-.1-.4-.4-.5-.7l-6.7-23c-.1-.3 0-.6.1-.8.1-.3.4-.4.6-.5l4.2-1.2-.9-3.1c-.2-.6.1-1.2.7-1.3.2-.1.4-.1.6 0 .4.1.7.4.8.8l.9 3.1 14.6-4.2-.9-3.1c-.1-.4 0-.8.2-1.1.4-.4 1.1-.4 1.5 0 .1.1.2.3.3.5l.9 3.1 4.2-1.2c.3-.1.6 0 .8.1.5.1.7.4.7.6zm-.2 6.9L-14-.3l4.5 15.7 25.1-7.2-4.5-15.7zM-7.3 11.3 7.4 7.2c.3-.1.6-.4.7-.8.1-.6-.2-1.2-.8-1.3h-.6L-7.9 9.3c-.3.1-.6.4-.7.7-.1.4 0 .7.3 1 .3.3.7.4 1 .3z"
            />
        </symbol>
        <symbol id="i-card" viewBox="-25.5 -19.3 51 38.6">
            <path
                fill={COLORS.BLUE_200}
                d="M-21.4-10.9c-2.8.6-4.6 3.1-4 5.6l4.9 21.2c.6 2.4 3.3 3.9 6 3.3L21.4 11c2.8-.6 4.5-3.1 4-5.6l-4.9-21.2c-.8-2.6-3.5-4-6.1-3.3l-35.8 8.2zm.6 2.5 35.9-8.2c1-.3 2.2.2 2.5 1.3v.1l.4 1.9-40.2 9.2-.3-1.9c-.2-1.1.5-2.2 1.7-2.4 0 .1 0 0 0 0zm.2 11 40.2-9.2L22.5 6c.2 1.1-.5 2.1-1.6 2.4h-.1l-35.9 8.2c-1.1.3-2.2-.3-2.6-1.4l-2.9-12.6zM6.9-.3C4 .4 2.2 3 2.8 5.5S6.1 9.6 9 8.9a6 6 0 0 0 2.5-1.3c.9.3 1.9.3 2.8.1 2.9-.7 4.7-3.3 4.1-5.8S15-2.2 12.2-1.5c-.9.2-1.8.6-2.5 1.2-.9-.2-1.9-.2-2.8 0zm-22 6.2c-.4.1-.7.3-.9.6-.4.5-.3 1.3.2 1.7.1 0 .1.1.2.1.3.2.7.2 1.1.1l10-2.3c.4-.1.8-.3 1-.6.2-.3.3-.7.2-1-.1-.3-.4-.6-.7-.8-.3-.2-.7-.2-1.1-.1l-10 2.3zM7.5 2.2c.2 0 .4-.1.7-.1v.1c-.1.3-.1.6-.2.8v.2c0 .3 0 .7.1 1 .1.3.2.6.3 1l.1.2c.2.2.4.4.5.6 0 0 0 .1.1.1-.2.1-.4.2-.6.2-1.2.4-2.4-.3-2.8-1.5-.2-1.2.6-2.3 1.8-2.6zM12.8 1c1.2-.4 2.4.3 2.8 1.5.2 1.2-.6 2.4-1.8 2.6-.6.1-1.2.1-1.7-.2H12c-.4-.2-.7-.5-.8-.9-.1-.1-.2-.3-.2-.4-.1-.6 0-1.1.3-1.6v-.1c.4-.4.9-.8 1.5-.9zm-27.2 8.7c-.5.1-.9.5-1.1 1-.1.5 0 1 .4 1.3.4.3.9.4 1.4.3l4.3-1c.5-.1.9-.4 1.1-.9.2-.4.1-.9-.3-1.3-.4-.3-.9-.5-1.4-.4l-4.3 1h-.1z"
            />
        </symbol>
        <symbol id="i-euro" viewBox="-10.7 -11.9 21.5 23.8">
            <path
                fill={COLORS.BLUE_200}
                d="M3.6-8.8c1.8.1 3.4.9 4.5 2.1.6.6 1.6.6 2.2 0 .6-.6.6-1.5.1-2.1-1.7-1.9-4-3-6.5-3.1-4.4-.2-8.3 2.7-10.1 7L-8.7-5c-.8 0-1.6.6-1.6 1.4 0 .8.6 1.6 1.4 1.6l1.8.1c0 .4-.1.9-.1 1.3V.8L-9 .7c-.8-.1-1.6.5-1.7 1.3s.5 1.6 1.3 1.7h.2l2.5.1c1.3 4.5 4.9 7.8 9.3 8 2.5.1 5-.7 6.8-2.4.7-.5.8-1.5.3-2.2-.5-.5-1.5-.6-2.1-.1-.1 0-.1.1-.2.2-1.3 1.1-3 1.7-4.7 1.6-2.8-.3-5.2-2.1-6.1-4.8l8 .4c.8.1 1.6-.5 1.7-1.3.1-.9-.5-1.6-1.3-1.7h-.2l-9-.5V-.4c0-.5.1-.9.2-1.3l9 .5c.8 0 1.6-.6 1.6-1.4 0-.8-.6-1.6-1.5-1.6L-3-4.8c1.3-2.5 3.8-4 6.6-4z"
            />
        </symbol>
        <symbol id="i-gift" viewBox="-38 -38 76 76">
            <path
                fill={COLORS.PURPLE_300}
                d="M-13.5 19.8 9.8 27c1.2.4 2.5.3 3.6-.3s1.9-1.6 2.3-2.8l3.5-11.2a4.8 4.8 0 0 0 3.1-3.1l1.4-4.5a5 5 0 0 0-.2-3.4C23 .6 22.2-.2 21.1-.7A7.42 7.42 0 0 0 18-13c-3.1-1-6.4-.4-9 1.5-1.1-3-3.5-5.3-6.5-6.3-3.9-1.2-8.1 1-9.3 4.9A8.2 8.2 0 0 0-7-9.4c-1.1-.2-2.3 0-3.4.6-1 .6-1.8 1.6-2.1 2.7l-1.4 4.5c-.5 1.5-.2 3.1.8 4.3l-3.5 11.2c-.8 2.4.6 5.1 3.1 5.9zm1.2-3.9c-.3-.1-.5-.5-.4-.8l3.2-10.3L.8 7.9l-3.4 11-9.7-3zm24.1 6.8c-.1.2-.2.3-.3.4-.2.1-.3.1-.5 0l-9.7-3 3.4-11L15 12.3l-3.2 10.4zm6.6-14.3c-.1.2-.2.3-.3.4-.2.1-.3.1-.5 0L5.9 5.2 7.7-.6 19.4 3c.2.1.3.2.4.3.1.2.1.4 0 .5l-1.4 4.6zM16.8-9.1c1.8.6 2.8 2.4 2.2 4.2a3.3 3.3 0 0 1-4.2 2.2L9-4.5l.2-.7c1-3.1 4.4-4.9 7.6-3.9zM1.3-13.9c3.2 1 5 4.4 4 7.6l-.2.6-5.8-1.8C-2.5-8-3.5-9.9-3-11.7a3.4 3.4 0 0 1 4.3-2.2zm-9.9 9c.1-.4.5-.6.9-.4L3.9-1.8 2 4-9.6.4c-.4-.1-.6-.5-.5-.8l1.5-4.5z"
            />
            <g style={{ opacity: 0.5 }}>
                <path
                    fill={COLORS.PURPLE_300}
                    d="M0 38c-21 0-38-17-38-38s17-38 38-38S38-21 38 0 21 38 0 38zm0-58c-11 0-20 9-20 20s9 20 20 20 20-9 20-20-9-20-20-20z"
                />
            </g>
        </symbol>
        <symbol id="i-graph" viewBox="-26.7 -27.3 53.4 54.7">
            <path
                d="M.1 27.3c-5.7 0-11.2-1.9-15.7-5.3-11.9-9-14.7-25.7-6.2-38 4-5.9 10.3-9.9 17.4-11 14.5-2.4 28.3 7.8 30.8 22.7 1.2 7.1-.4 14.3-4.5 20.2-4 5.9-10.3 9.9-17.4 11-1.5.3-2.9.4-4.4.4zm-.3-40.6c-.7 0-1.3.1-2 .2-3.3.6-6.2 2.4-8.1 5.2-4.1 6-2.7 14.3 3.1 18.7 5.3 4.1 13 3.1 17.1-2.2.2-.2.3-.4.5-.6 2-2.9 2.8-6.5 2.2-10-1-6.4-6.4-11.2-12.8-11.3z"
                fill={COLORS.BLUISH_PURPLE_400}
                style={{ opacity: 0.4 }}
            />
            <path
                fill={COLORS.BLUISH_PURPLE_400}
                d="m-2.2 2.7 6.7 2.9L9.3-5 6.7-6.1l-3.6 8L-3.6-1-8.4 9.6l2.6 1.2 3.6-8.1zm14.1 5 .4 2.6-22.5 3.7-2.9-17.8 2.6-.4-4.8-4.3-3.2 5.6 2.6-.4 3.4 20.6 25.3-4.2.4 2.6 4.3-4.8-5.6-3.2z"
            />
        </symbol>
        <symbol id="i-heart" viewBox="-13.9 -12.8 27.9 25.6">
            <path
                d="M13.4-8.5a7.4 7.4 0 0 0-8.2-4.2A7.3 7.3 0 0 0-.2-5.4 7.28 7.28 0 0 0-8.1-10c-3.4.7-5.8 3.6-5.9 7.1.1 2.4 1.2 4.7 3 6.4 1.8 1.6 3.8 3 6 4.1 2.9 1.7 5.6 3.3 8.6 5 2-2.7 3.7-5.3 5.7-8 1.5-1.9 2.8-4 3.8-6.2 1-2 1.1-4.6.3-6.9z"
                fill={COLORS.PURPLE_600}
            />
        </symbol>
        <symbol id="i-like" viewBox="-16.7 -16.6 33.3 33.3">
            <path
                fill={COLORS.BLUISH_PURPLE_400}
                d="M8.7-1.4 6-1.9l-4.2-9.3 1.1-5.3s-4.1-1.1-5 6-1.1 5.2-1.1 5.2S-12-7-12.8-6.4c-.6.4-2.6 9.1-3.6 13.8-.6 2.3.5 4.8 2.6 5.9l.9.3c2.2.4 13.7.9 13.7.9l4.6.9L8.7-1.4zm8 0-5.3 18-4.6-.9 3.6-17.9 6.3.8z"
            />
        </symbol>
        <symbol id="i-plane" viewBox="-39.9 -39.9 79.8 79.8">
            <path
                fill={COLORS.BLUE_200}
                d="M28.8 1.5C14.1-4.6-.5-10.7-15.2-16.8c-.8-.3-1.6-.7-2.4.1-.7.8-.3 1.6 0 2.4l15.3 31.1c.9 1.8 1.3 1.9 3.2 1.1.7-.3 1.3-.6 1.9-.9 1.2-.8 2-.6 2.9.6 1.2 1.8 2.7 3.3 4.1 5 .4.6 1.1.9 1.9.8.7-.2 1-.8 1.2-1.5.8-3.2 1.6-6.4 2.3-9.6.1-.8.7-1.5 1.4-1.8 4-1.8 8-3.7 12.1-5.6.7-.3 1.2-.7 1.7-1.2V2.6c-.5-.5-1-.9-1.6-1.1zM11.2 15.7c-.2.9-.4 1.9-.7 3-1-1.3-2-2.5-2.9-3.6 1-1 2.4-1.3 3.6-2 .2-.1.3-.3.7-.1l-.7 2.7zm-10-1.3c-.8.4-1.1.3-1.5-.5C-2.7 9-5.1 4.2-7.5-.7l-4.1-8.4.2-.1L12 9.4c-3.8 1.7-7.3 3.3-10.8 5zm14.7-6.8c-.5.3-1.2.2-1.7-.2C9.5 3.6 4.9-.1.2-3.8l-8.3-6.6.1-.1L25.1 3.2c-3.2 1.5-6.3 2.9-9.2 4.4z"
            />
            <g style={{ opacity: 0.5 }}>
                <path
                    fill={COLORS.BLUE_200}
                    d="M0 39.9C-22 39.9-39.9 22-39.9 0S-22-39.9 0-39.9 39.9-22 39.9 0A39.95 39.95 0 0 1 0 39.9zm0-61.8c-12.1.1-21.8 9.9-21.8 22A21.9 21.9 0 1 0 22-.1 21.88 21.88 0 0 0 0-21.9z"
                />
            </g>
        </symbol>
        <symbol id="i-star" viewBox="-9.1 -9.1 18.2 18.2">
            <path
                fill={COLORS.BLUISH_PURPLE_400}
                d="m0-9.1 3.2 5.9L9.1 0 3.2 3.2 0 9.1l-3.2-5.9L-9.1 0l5.9-3.2L0-9.1z"
            />
        </symbol>
        <g id="fg">
            <ellipse
                cx="141"
                cy="320.5"
                rx="103.5"
                ry="13.3"
                fill={COLORS.PURPLE_600}
                style={{ opacity: 0.3 }}
            />
            <path
                fill={COLORS.BLUISH_PURPLE_400}
                d="M178.6 106h-14.7c-2 0-3.6 1.7-3.6 3.7v2.7c0 2-1.6 3.7-3.6 3.7h-28.6c-2 0-3.6-1.7-3.6-3.7v-2.7c0-2-1.6-3.7-3.6-3.7h-18c-6.4 0-11.6 5.2-11.6 11.6v185.2c0 6.4 5.2 11.5 11.6 11.5h75.6c6.4 0 11.6-5.2 11.6-11.5V117.5c0-6.4-5.2-11.5-11.5-11.5z"
            />
            <path d="M178.9 100.4h-75.7c-9.1 0-16.5 7.4-16.5 16.5v189.8c0 9.1 7.4 16.5 16.5 16.5h75.7c9.1 0 16.5-7.4 16.5-16.5V116.9c-.1-9.1-7.4-16.4-16.5-16.5zM190.3 304c0 6.4-5.2 11.5-11.6 11.5h-75.6c-6.4 0-11.6-5.2-11.6-11.6V118.7c0-6.4 5.2-11.5 11.5-11.5h18c2 0 3.6 1.7 3.6 3.7v2.7c0 2 1.5 3.7 3.6 3.7H157c2 0 3.6-1.7 3.6-3.7v-2.7c0-2 1.5-3.7 3.6-3.7h14.7c6.4 0 11.5 5.2 11.5 11.6l-.1 185.2z" />
            <path
                d="m91.4 266.2.4 31.1 52.5-16.9v-84.5l23.3-9.6-34-8.6v81.8l-42.2 6.7z"
                fill={COLORS.WHITE}
            />
            <path d="m143.9 196 23.7-9.7v85.6l-23.7 10V196z" fill={COLORS.BLUISH_PURPLE_900} />
            <path
                style={{ opacity: 0.2 }}
                fill={COLORS.BLUISH_PURPLE_400}
                d="m91.4 240.6 42.2 18.9-42.2 6.8v-25.7zm.4 56.7 75.8-25.4 22.7 12.2 1.8 23.8-7.3 8.8-69.4 2.5-17.5-2.8-8.4-9.8 2.3-9.3zM113 236.4c-1.2-.3-2.3-.6-3.4-.9-3.8-1-7.2-3-9.8-5.9-1.9-2.2-3.1-4.9-3.4-7.8 0-.3-.1-.6.2-.7.3 0 .6 0 .8.1 2 1.2 4 2.3 6.2 3.2.8.3 1.6.5 2.4.8l.1-.1c-.8-.6-1.7-1.1-2.5-1.6-2.8-1.8-5.3-4.1-7.4-6.6-2-2.3-3.3-5.1-3.7-8.1-.2-1.4-.1-2.9.4-4.2.2-.5.6-.6 1-.2 6.6 6.4 14.4 11.5 23 14.8 3.8 1.4 7.4 3.2 10.8 5.4 2.7 1.6 5 3.8 6.8 6.5 2.4 3.6 3.2 8.1 2.4 12.4-.8 3.6-2.6 6.8-5.1 9.4-.4.4-.8.3-1-.2-1.4-2.7-4-4.7-6.9-5.5-2.8-.7-5.7-1.3-8.5-1.8-1.8-.3-3.5-.8-5.1-1.5a9.1 9.1 0 0 1-5.1-7.3v-.4c0-.6.3-.7.9-.6 1.3.3 2.6.7 3.9.9.9.1 1.8.2 2.8.1 0-.2.1-.2.2-.2z"
            />
            <path
                d="M108.9 224c-1.6-.4-3.2-.8-4.7-1.2-5.2-1.6-9.9-4-13.5-8.2-2.7-3-4.3-6.7-4.7-10.7 0-.4-.1-.9.3-1 .4-.1.8 0 1.1.2 2.7 1.7 5.6 3.1 8.5 4.4 1.1.4 2.2.7 3.4 1.1l.1-.2c-1.1-.8-2.3-1.5-3.4-2.3-3.8-2.5-7.3-5.6-10.2-9.1-2.8-3.2-4.5-7.1-5.2-11.2-.3-2-.1-4 .6-5.9.2-.7.8-.8 1.4-.3 9.1 8.9 20 15.8 31.8 20.4 5.2 2 10.3 4.5 15 7.4 3.7 2.4 7 5.2 9.3 9 3.2 5.3 4.6 10.9 3.3 17.1a27.3 27.3 0 0 1-7.1 13c-.5.6-1.1.5-1.4-.2-2-3.8-5.5-6.5-9.6-7.6-3.9-1-7.8-1.9-11.8-2.5-2.4-.4-4.8-1.1-7.1-2.1-4-1.9-6.6-5.8-7-10.1v-.5c0-.8.4-1 1.2-.8 1.8.5 3.6 1 5.4 1.3 1.3.2 2.6.2 3.8.2.3-.1.4-.2.5-.2z"
                fill={COLORS.BLUE_400}
            />
            <ellipse
                cx="120.9"
                cy="270.7"
                rx="16"
                ry="3.5"
                fill={COLORS.BLUISH_PURPLE_900}
                style={{ opacity: 0.12 }}
            />
            <path
                fill={COLORS.PURPLE_300}
                d="m84.2 83.3 2.8 5.2 5.2 2.8-5.2 2.8-2.8 5.2-2.8-5.2-5.2-2.8 5.2-2.8c-.1-.1 2.8-5.2 2.8-5.2z"
            />
            <defs>
                <path id="SVGID_1_" d="M28.7 179.8H62v33.3H28.7z" />
            </defs>
            <clipPath id="SVGID_00000120523945922947195280000009495298388337327003_">
                <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
            </clipPath>
            <g
                style={{
                    clipPath: 'url(#SVGID_00000120523945922947195280000009495298388337327003_)'
                }}>
                <use
                    xlinkHref="#i-like"
                    width="33.3"
                    height="33.3"
                    x="-16.7"
                    y="-16.6"
                    transform="translate(44.95 195.37)"
                    style={{ overflow: 'visible' }}
                />
            </g>
            <path
                fill={COLORS.WHITE}
                d="m230 168.1 1.7 3.2 3.2 1.7-3.2 1.7-1.7 3.2-1.7-3.2-3.2-1.7 3.2-1.7 1.7-3.2z"
            />
            <defs>
                <path
                    id="SVGID_00000035520206269908182170000010288169107960650633_"
                    d="M234.1 172.8H270v35.6h-35.9z"
                />
            </defs>
            <clipPath id="SVGID_00000088816856175135546940000008695838421608898705_">
                <use
                    xlinkHref="#SVGID_00000035520206269908182170000010288169107960650633_"
                    style={{ overflow: 'visible' }}
                />
            </clipPath>
            <g
                style={{
                    clipPath: 'url(#SVGID_00000088816856175135546940000008695838421608898705_)'
                }}>
                <use
                    xlinkHref="#i-calendar"
                    width="35.9"
                    height="35.6"
                    x="-18"
                    y="-17.8"
                    transform="translate(251.95 190.48)"
                    style={{ overflow: 'visible' }}
                />
            </g>
            <defs>
                <path
                    id="SVGID_00000057126487233498036880000003699993711248998575_"
                    d="M202.5 78.6h21.4v23.9h-21.4z"
                />
            </defs>
            <clipPath id="SVGID_00000122701575513169472620000003126383142518300077_">
                <use
                    xlinkHref="#SVGID_00000057126487233498036880000003699993711248998575_"
                    style={{ overflow: 'visible' }}
                />
            </clipPath>
            <g
                style={{
                    clipPath: 'url(#SVGID_00000122701575513169472620000003126383142518300077_)'
                }}>
                <use
                    xlinkHref="#i-euro"
                    width="21.5"
                    height="23.8"
                    x="-10.7"
                    y="-11.9"
                    transform="translate(213.08 90.32)"
                    style={{ overflow: 'visible' }}
                />
            </g>
            <defs>
                <path
                    id="SVGID_00000075847126980699892220000009839773323375248551_"
                    d="M9.8 108.3h52.8v54.1H9.8z"
                />
            </defs>
            <clipPath id="SVGID_00000078020808250224414990000015677896114205128105_">
                <use
                    xlinkHref="#SVGID_00000075847126980699892220000009839773323375248551_"
                    style={{ overflow: 'visible' }}
                />
            </clipPath>
            <g
                style={{
                    clipPath: 'url(#SVGID_00000078020808250224414990000015677896114205128105_)'
                }}>
                <use
                    xlinkHref="#i-graph"
                    width="53.4"
                    height="54.7"
                    x="-26.7"
                    y="-27.3"
                    transform="matrix(.99 0 0 .99 32.72 133.4)"
                    style={{ overflow: 'visible' }}
                />
            </g>
            <g>
                <defs>
                    <path
                        id="SVGID_00000131333985939512734600000001335009031719778750_"
                        d="M0 223h79v79.8H0z"
                    />
                </defs>
                <clipPath id="SVGID_00000134238507955815442390000011919383352582365363_">
                    <use
                        xlinkHref="#SVGID_00000131333985939512734600000001335009031719778750_"
                        style={{ overflow: 'visible' }}
                    />
                </clipPath>
                <g
                    style={{
                        clipPath: 'url(#SVGID_00000134238507955815442390000011919383352582365363_)'
                    }}>
                    <use
                        xlinkHref="#i-plane"
                        width="79.8"
                        height="79.8"
                        x="-39.9"
                        y="-39.9"
                        transform="matrix(.99 0 0 1 39.49 262.9)"
                        style={{ overflow: 'visible' }}
                    />
                </g>
            </g>
            <g>
                <defs>
                    <path
                        id="SVGID_00000152980150772014952580000010517310796475083156_"
                        d="M204 232.3h76v76h-76z"
                    />
                </defs>
                <clipPath id="SVGID_00000079487478948426992460000012872104708988637111_">
                    <use
                        xlinkHref="#SVGID_00000152980150772014952580000010517310796475083156_"
                        style={{ overflow: 'visible' }}
                    />
                </clipPath>
                <g
                    style={{
                        clipPath: 'url(#SVGID_00000079487478948426992460000012872104708988637111_)'
                    }}>
                    <use
                        xlinkHref="#i-gift"
                        width="76"
                        height="76"
                        x="-38"
                        y="-38"
                        transform="translate(242 270.3)"
                        style={{ overflow: 'visible' }}
                    />
                </g>
            </g>
            <use
                xlinkHref="#i-star"
                width="18.2"
                height="18.2"
                x="-9.1"
                y="-9.1"
                transform="matrix(.99 0 0 .99 247.01 156.01)"
                style={{ overflow: 'visible' }}
            />
            <path
                d="m64.2 111.6 1.3 2.4 2.4 1.3-2.4 1.3-1.3 2.4-1.3-2.4-2.4-1.3 2.4-1.3 1.3-2.4z"
                fill={COLORS.WHITE}
            />
            <path
                fill={COLORS.WHITE}
                d="m15 156.1 1.7 3.2 3.2 1.7-3.2 1.7-1.7 3.2-1.7-3.2-3.2-1.7 3.2-1.7 1.7-3.2zm55 86 1.7 3.2 3.2 1.7-3.2 1.7-1.7 3.2-1.7-3.2-3.2-1.7 3.2-1.7 1.7-3.2z"
            />
            <path
                fill={COLORS.BLUISH_PURPLE_400}
                d="M48.3 83.9c2.7-1.9 4-5.2 3.4-8.5-.8-4.6-4.9-7.7-9.1-6.9s-6.9 5-6.1 9.5c.5 3.3 3 6 6.3 6.8-11.3 3.9-8.4 16.7-8.4 16.7L62 96.8s-1.9-13.1-13.7-12.9z"
            />
            <g>
                <defs>
                    <path
                        id="SVGID_00000033340750029634261910000007209128304935678141_"
                        d="M206 205h27.9v26.1H206z"
                    />
                </defs>
                <clipPath id="SVGID_00000119102792031181525220000014204946148089194631_">
                    <use
                        xlinkHref="#SVGID_00000033340750029634261910000007209128304935678141_"
                        style={{ overflow: 'visible' }}
                    />
                </clipPath>
                <g
                    style={{
                        clipPath: 'url(#SVGID_00000119102792031181525220000014204946148089194631_)'
                    }}>
                    <use
                        xlinkHref="#i-heart"
                        width="27.9"
                        height="25.6"
                        x="-13.9"
                        y="-12.8"
                        transform="matrix(1 0 0 1.02 219.95 217.5)"
                        style={{ overflow: 'visible' }}
                    />
                </g>
            </g>
            <g>
                <defs>
                    <path
                        id="SVGID_00000026163873698165738300000001222935124926695313_"
                        d="M208 108h51v37.8h-51z"
                    />
                </defs>
                <clipPath id="SVGID_00000067198167031764087210000003843616795719408037_">
                    <use
                        xlinkHref="#SVGID_00000026163873698165738300000001222935124926695313_"
                        style={{ overflow: 'visible' }}
                    />
                </clipPath>
                <g
                    style={{
                        clipPath: 'url(#SVGID_00000067198167031764087210000003843616795719408037_'
                    }}>
                    <use
                        xlinkHref="#i-card"
                        width="51"
                        height="38.6"
                        x="-25.5"
                        y="-19.3"
                        transform="matrix(1 0 0 .98 233.05 126.34)"
                        style={{ overflow: 'visible' }}
                    />
                </g>
            </g>
        </g>
        <text
            transform="translate(42.46 49.37)"
            fill={COLORS.BLUE_200}
            style={{
                letterSpacing: 1,
                fontSize: 32,
                fontFamily: "font-family:'WorkSans-SemiBold'"
            }}>
            {title}
        </text>
    </svg>
);

export default SlideOneMovement;
