import { Button, ButtonIcon, Select } from 'components/elements';
import { toastOptions } from 'config';
import { MOVEMENT_EXERCISE } from 'content';
import { format, sub } from 'date-fns';
import isSameDay from 'date-fns/isSameDay';
import ENLocale from 'date-fns/locale/en-GB';
import PTLocale from 'date-fns/locale/pt';
import { useApi, useGamification, useLanguage, useTheme } from 'hooks';
import { UserManuallyEnteredActivity } from 'models/types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiChevronLeft, FiChevronRight, FiPlus } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { SymbolHPS } from 'theme/symbols';

import styles from './ActivityTracker.module.scss';
import { options } from './select-options';

export type ActivityTrackerProps = React.HTMLProps<HTMLDivElement> & {
    className?: string;
};

const NOW = new Date();
const DUMMY_SCORE = 50;

export const ActivityTracker: React.FC<ActivityTrackerProps> = ({ className = '', ...props }) => {
    const { getManualActivities, postManualActivity } = useApi();
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();
    const { reportInteraction } = useGamification();

    const [isFlipped, setIsFlipped] = useState(false);
    const [score, setScore] = useState<number | null>(null);
    const [calculating, setCalculating] = useState(false);
    const [isToday, setIsToday] = useState(false);
    const [currentDate, setCurrentDate] = useState(NOW);
    const [userActivities, setUserActivities] = useState<UserManuallyEnteredActivity[]>([]);
    const [resetFormFields, setResetFormFields] = useState(false);
    const [userCurrentActivity, setUserCurrentActivity] = useState<
        UserManuallyEnteredActivity | undefined
    >(undefined);
    const [animateNewActivity, setAnimateNewActivity] = useState(false);
    const [animateActivityToLeft, setAnimateActivityToLeft] = useState(false);
    const [animateActivityToRight, setAnimateActivityToRight] = useState(false);

    const activitySelectRef = useRef<HTMLSelectElement>(null);
    const durationSelectRef = useRef<HTMLSelectElement>(null);

    const CONTENT = isPT
        ? MOVEMENT_EXERCISE.PT.MANUAL.TRACKER
        : MOVEMENT_EXERCISE.EN.MANUAL.TRACKER;

    const getCurrentActivity = useCallback(() => {
        const activity = userActivities.find(({ date }) =>
            isSameDay(new Date(date), new Date(currentDate))
        );
        setUserCurrentActivity(activity);
    }, [currentDate, userActivities]);

    const fetchData = useCallback(async () => {
        const activities = await getManualActivities();
        setUserActivities(activities);
    }, [getManualActivities]);

    const handleDateNav = useCallback(
        (action: 'add' | 'remove') => {
            switch (action) {
                case 'add':
                    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 1)));
                    setAnimateActivityToLeft(true);
                    setTimeout(() => setAnimateActivityToLeft(false), 2000);
                    break;
                case 'remove':
                    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 1)));
                    setAnimateActivityToRight(true);
                    setTimeout(() => setAnimateActivityToRight(false), 2000);
                    break;

                default:
                    break;
            }

            getCurrentActivity();
        },
        [currentDate, getCurrentActivity]
    );

    const handleFormSubmit = useCallback(
        async (evt: React.FormEvent<HTMLFormElement>) => {
            evt.preventDefault();

            const noValues =
                activitySelectRef?.current?.value === '' ||
                durationSelectRef?.current?.value === '';
            if (noValues) {
                toast.dark(CONTENT.MESSAGES.FILL_FORM, toastOptions);
                return;
            }

            try {
                await postManualActivity({
                    date: currentDate,
                    type: activitySelectRef?.current?.value,
                    duration: durationSelectRef?.current?.value
                });

                const activities = await getManualActivities();
                setUserActivities(activities);
                getCurrentActivity();
                setResetFormFields(true);
                setTimeout(() => setResetFormFields(false), 1000);
                setIsFlipped(false);
                setAnimateNewActivity(true);
                setTimeout(() => setAnimateNewActivity(false), 3000);
                setScore(null);

                const id = activities.find((a) => isSameDay(new Date(a.date), currentDate))?.id;
                if (!id) return;

                reportInteraction({
                    interaction: 'manual_activities',
                    area: 'movement',
                    id
                });
            } catch (error) {
                toast.dark(CONTENT.MESSAGES.SENT_ERROR, toastOptions);
            }
        },
        [
            CONTENT.MESSAGES.FILL_FORM,
            CONTENT.MESSAGES.SENT_ERROR,
            currentDate,
            getCurrentActivity,
            getManualActivities,
            postManualActivity,
            reportInteraction
        ]
    );

    const calculateScore = useCallback(() => {
        const noValues =
            activitySelectRef?.current?.value === '' || durationSelectRef?.current?.value === '';
        if (noValues) return;

        setCalculating(true);
        setTimeout(() => {
            setScore(DUMMY_SCORE);
            setCalculating(false);
        }, 2000);
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(getCurrentActivity);

    useEffect(() => setIsToday(isSameDay(new Date(currentDate), new Date())), [currentDate]);

    return (
        <div
            className={`${styles.card} ${isFlipped ? styles.flipped : ''} ${className}`}
            {...props}>
            <div className={`${styles.face} ${styles.front} ${styles[activeTheme]}`}>
                <div className={styles.header}>
                    <span className={styles.dateContainer}>
                        <span className={styles.weekday}>
                            {format(currentDate, 'EEEE', {
                                locale: isPT ? PTLocale : ENLocale
                            })}
                        </span>
                        <span className={styles.date}>
                            {format(currentDate, 'd MMM yyyy', {
                                locale: isPT ? PTLocale : ENLocale
                            })}
                        </span>
                    </span>
                </div>

                <div className={styles.body}>
                    <span className={styles.wrapper}>
                        {userCurrentActivity ? (
                            <img
                                className={`${styles.icon} ${
                                    animateNewActivity ? styles['swirl-in-fwd'] : ''
                                } ${animateActivityToLeft ? styles['tilt-in-right-1'] : ''} ${
                                    animateActivityToRight ? styles['tilt-in-left-1'] : ''
                                }`}
                                src={`/icons/activity/i-${userCurrentActivity.type}.svg`}
                                alt={userCurrentActivity.title}
                            />
                        ) : (
                            <button className={styles.addButton} onClick={() => setIsFlipped(true)}>
                                <FiPlus />
                            </button>
                        )}
                        {userCurrentActivity && (
                            <span
                                className={`${styles.wrapper} ${
                                    animateNewActivity ||
                                    animateActivityToLeft ||
                                    animateActivityToRight
                                        ? styles['swing-in-bottom-fwd']
                                        : ''
                                }`}>
                                <span className={styles.activity}>
                                    {userCurrentActivity?.title}
                                </span>
                                <span className={styles.duration}>
                                    {userCurrentActivity?.duration} min.
                                </span>
                            </span>
                        )}
                    </span>
                    {userCurrentActivity ? (
                        <span className={styles.points}>
                            <SymbolHPS />
                            {` ${userCurrentActivity.points} ${
                                userCurrentActivity.points > 1
                                    ? CONTENT.lABELS.POINTS.PLURAL
                                    : CONTENT.lABELS.POINTS.SINGULAR
                            }`}
                        </span>
                    ) : (
                        <span className={styles.helper}>{CONTENT.lABELS.HELPER}</span>
                    )}
                </div>

                <div className={styles.nav}>
                    <button
                        disabled={currentDate < sub(NOW, { days: 7 })}
                        onClick={() => handleDateNav('remove')}>
                        <FiChevronLeft />
                    </button>
                    <button disabled={isToday} onClick={() => handleDateNav('add')}>
                        <FiChevronRight />
                    </button>
                </div>
            </div>
            <div className={`${styles.face} ${styles.back} ${styles[activeTheme]}`}>
                <div className={styles.body}>
                    <form onSubmit={handleFormSubmit}>
                        <Select
                            selectRef={activitySelectRef}
                            id="activity"
                            label={CONTENT.FORM.LABELS.ACTIVITY}
                            options={options.activity}
                            onChangeCallBack={calculateScore}
                            reset={resetFormFields}
                            className={styles.select}
                        />
                        <Select
                            selectRef={durationSelectRef}
                            id="duration"
                            label={CONTENT.FORM.LABELS.DURATION}
                            options={options.duration}
                            onChangeCallBack={calculateScore}
                            reset={resetFormFields}
                            className={styles.select}
                        />
                        {score === null ? (
                            <span className={styles.score}>
                                {calculating ? CONTENT.FORM.LABELS.CALCULATING : ''}
                            </span>
                        ) : (
                            <span className={styles.score}>
                                <SymbolHPS /> {score} pts
                            </span>
                        )}
                        <Button
                            type="submit"
                            label={CONTENT.FORM.LABELS.SUBMIT}
                            size="md"
                            className={styles.button}
                        />
                    </form>

                    <ButtonIcon
                        icon="ARROW_LEFT"
                        size="XS"
                        className={styles.backButton}
                        onClick={() => setIsFlipped(false)}
                    />
                </div>
            </div>
        </div>
    );
};

export default ActivityTracker;
