import { Points } from 'content/types';

export const POINTS: Points = {
    PT: {
        HEADER: {
            TITLE: 'Pontos'
        },
        INTRO: {
            TITLE: 'Pontos',
            DESCRIPTION: 'Adquira pontos, ganhe descontos'
        },
        PROGRESS: {
            TITLE: 'O seu progresso'
        },
        ACTIVITIES: {
            TITLE: 'Atividades sugeridas'
        }
    },
    EN: {
        HEADER: {
            TITLE: 'Points'
        },
        INTRO: {
            TITLE: 'Points',
            DESCRIPTION: 'Get points, get discounts'
        },
        PROGRESS: {
            TITLE: 'Your progress'
        },
        ACTIVITIES: {
            TITLE: 'Suggested activities'
        }
    }
};
