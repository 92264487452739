import { Avatar, CardReview, Carousel, Tag } from 'components/elements';
import { permissions } from 'config';
import { META_TAGS, NUTRITION_NUTRITIONISTS_PROFILE } from 'content';
import { useApi, useAuth, useGamification, useLanguage, useTheme } from 'hooks';
import { NutritionistData } from 'models/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import {
    IconFacebook,
    IconInstagram,
    IconLinkedIn,
    IconMail,
    IconPhone,
    IconSkype,
    IconStar,
    IconWhatsapp,
    IconYoutube
} from 'theme/icons';

import styles from './NutritionistProfile.module.scss';

export const NutritionistProfile: React.FC = () => {
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();
    const { validateUserAccessToArea } = useAuth();
    const { reportInteraction } = useGamification();
    const { getStaff } = useApi();

    const [nutritionist, setNutritionist] = useState<NutritionistData | undefined>(undefined);
    const { slug } = useParams();

    const CONTENT = isPT ? NUTRITION_NUTRITIONISTS_PROFILE.PT : NUTRITION_NUTRITIONISTS_PROFILE.EN;

    useEffect(
        () => validateUserAccessToArea(permissions.nutrition.nutritionists),
        [validateUserAccessToArea]
    );

    const getNutritionist = useCallback(async () => {
        const nutritionists = await getStaff('nutritionists');
        const nutritionist = nutritionists.find((n) => n.slug === slug);
        if (nutritionist) setNutritionist(nutritionist);
    }, [getStaff, slug]);

    const sendInteraction = useCallback(() => {
        if (!nutritionist) return;

        reportInteraction({
            interaction: 'nutritionists',
            area: 'nutrition',
            id: nutritionist.id
        });
    }, [nutritionist, reportInteraction]);

    useEffect(() => {
        getNutritionist();
    }, [getNutritionist]);

    const nutritionistName = useMemo(
        () =>
            `${nutritionist?.name.split(' ')[0]} ${
                nutritionist?.name.split(' ')[nutritionist?.name.split(' ').length - 1]
            }`,
        [nutritionist?.name]
    );

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? `${nutritionistName} ${META_TAGS.PRIVATE.NUTRITION.NUTRITIONIST_PROFILE.PT.TITLE}`
                        : `${nutritionistName} ${META_TAGS.PRIVATE.NUTRITION.NUTRITIONIST_PROFILE.EN.TITLE}`}
                </title>
            </Helmet>
            {nutritionist && (
                <section className={`${styles.intro} container`}>
                    <span className={styles.preTitle}>{CONTENT.PRE_TITLE}</span>
                    <h1>{nutritionist.name}</h1>
                    <div className={styles.rating}>
                        <IconStar
                            className={nutritionist.rating >= 1 ? styles.active : undefined}
                        />
                        <IconStar
                            className={nutritionist.rating >= 2 ? styles.active : undefined}
                        />
                        <IconStar
                            className={nutritionist.rating >= 3 ? styles.active : undefined}
                        />
                    </div>
                    <Avatar
                        source={nutritionist.images.profile_2x || nutritionist.images.profile}
                        alt={nutritionist.name}
                        className={styles.avatar}
                        width={120}
                        height={120}
                    />
                    <span className={styles.preTitle}>
                        {nutritionist.locations.map((local, i) => (
                            <span key={i.toString()}>
                                {local}
                                {nutritionist.locations.length > i + 1 ? ', ' : ''}
                            </span>
                        ))}
                    </span>
                    <h3>
                        {nutritionist.platforms.map((platform, i) => (
                            <span key={i.toString()}>
                                {platform} {nutritionist.platforms.length > i + 1 ? ' · ' : ''}
                            </span>
                        ))}
                    </h3>
                    <div className={styles.buttonsContainer}>
                        <div className={styles.languages}>
                            {nutritionist.languages.map((lang, i) => (
                                <Tag
                                    key={i.toString()}
                                    area="movement"
                                    square={true}
                                    tagSize="sm"
                                    className={styles.tag}>
                                    {lang}
                                </Tag>
                            ))}
                        </div>
                        <div className={styles.social}>
                            {nutritionist.social.facebook && (
                                <a
                                    href={nutritionist.social.facebook}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <Tag
                                        area="movement"
                                        square={true}
                                        tagSize="sm"
                                        className={styles.tag}>
                                        <IconFacebook />
                                    </Tag>
                                </a>
                            )}
                            {nutritionist.social.instagram && (
                                <a
                                    href={nutritionist.social.instagram}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <Tag
                                        area="movement"
                                        square={true}
                                        tagSize="sm"
                                        className={styles.tag}>
                                        <IconInstagram />
                                    </Tag>
                                </a>
                            )}
                            {nutritionist.social.youtube && (
                                <a
                                    href={nutritionist.social.youtube}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <Tag
                                        area="movement"
                                        square={true}
                                        tagSize="sm"
                                        className={styles.tag}>
                                        <IconYoutube />
                                    </Tag>
                                </a>
                            )}
                            {nutritionist.social.linkedin && (
                                <a
                                    href={nutritionist.social.linkedin}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <Tag
                                        area="movement"
                                        square={true}
                                        tagSize="sm"
                                        className={styles.tag}>
                                        <IconLinkedIn />
                                    </Tag>
                                </a>
                            )}
                        </div>
                    </div>
                    {nutritionist.bio && (
                        <div className={styles.bio}>
                            <h3>{nutritionist.bio.replace(/"/gi, ' ')}</h3>
                            <h2>{CONTENT.QUALIFICATIONS}</h2>
                            <p>
                                {isPT
                                    ? nutritionist.qualifications.pt
                                    : nutritionist.qualifications.en}
                            </p>
                        </div>
                    )}
                </section>
            )}
            {nutritionist?.tags && (
                <section className={`${styles.specialty} container`}>
                    <h2>{CONTENT.TAGS.TITLE}</h2>
                    <div className={styles.container}>
                        {nutritionist.tags.map((tag, i) => (
                            <Tag key={i.toString()} theme={activeTheme}>
                                {isPT ? tag.pt : tag.en}
                            </Tag>
                        ))}
                    </div>
                </section>
            )}
            {nutritionist && nutritionist?.services.length > 0 && (
                <section className={`${styles.services} container`}>
                    <h2>{CONTENT.SCHEDULE.TITLE}</h2>
                    <Carousel
                        __typename="card-booking"
                        slides={nutritionist.services}
                        slidesPerView={2}
                        className={styles.carousel}
                    />
                </section>
            )}
            {nutritionist?.contacts && (
                <section className={`${styles.contacts} container`}>
                    <h2>{CONTENT.CONTACTS.TITLE}</h2>
                    <div className={styles.container}>
                        {nutritionist.contacts.phone && (
                            <a
                                href={`tel:${nutritionist.contacts.phone}`.trim()}
                                className={styles.icon}
                                onClick={sendInteraction}>
                                <IconPhone />
                            </a>
                        )}
                        {nutritionist.contacts.skype && (
                            <a
                                href={`skype:${nutritionist.contacts.skype}?chat`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.icon}
                                onClick={sendInteraction}>
                                <IconSkype />
                            </a>
                        )}
                        {nutritionist.contacts.whatsapp && (
                            <a
                                href={`https://wa.me/${nutritionist.contacts.whatsapp}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.icon}
                                onClick={sendInteraction}>
                                <IconWhatsapp />
                            </a>
                        )}
                        {nutritionist.contacts.email && (
                            <a
                                href={`mailto:${nutritionist.contacts.email}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.icon}
                                onClick={sendInteraction}>
                                <IconMail />
                            </a>
                        )}
                    </div>
                </section>
            )}
            {nutritionist && nutritionist?.comments.length > 0 && (
                <section className={`${styles.comments} container`}>
                    <h2>{CONTENT.COMMENTS.TITLE}</h2>
                    {nutritionist.comments.map((comment) => (
                        <CardReview key={comment.id.toString()} {...comment} />
                    ))}
                </section>
            )}
        </>
    );
};
