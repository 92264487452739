import { Button, ModalExternalLink } from 'components/elements';
import { useState } from 'react';

import styles from './Booking.module.scss';
type BookingProps = {
    externalURL: string | undefined;
    content: {
        TITLE: string;
        MESSAGE: string;
        BUTTON_LABEL: string;
    };
};

export const Booking: React.FC<BookingProps> = ({ externalURL, content }) => {
    const [showModal, setShowModal] = useState(false);

    if (!externalURL) return null;

    return (
        <>
            <section className={`${styles.booking} container`}>
                <h2>{content.TITLE}</h2>
                <p>{content.MESSAGE}</p>
                <Button
                    label={content.BUTTON_LABEL}
                    size="lg"
                    onClick={() => setShowModal(true)}
                    className={styles.button}
                />
            </section>
            <ModalExternalLink show={showModal} setShow={setShowModal} externalURL={externalURL} />
        </>
    );
};
