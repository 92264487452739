import { COLORS } from 'theme/colors';

import { SymbolProps } from './types';

export const SymbolHPS: React.FC<SymbolProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            {...props}>
            <path
                fill={color}
                d="M10-.03a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm0 19.21a9.21 9.21 0 1 1 9.2-9.21 9.2 9.2 0 0 1-9.2 9.24Zm5.29-10.81-3.16-.46a.43.43 0 0 1-.33-.24l-1.42-2.86a.43.43 0 0 0-.78 0L8.19 7.67a.45.45 0 0 1-.33.24l-3.16.46a.43.43 0 0 0-.37.49.38.38 0 0 0 .13.25l2.28 2.23a.43.43 0 0 1 .13.39l-.54 3.14a.43.43 0 0 0 .35.5.38.38 0 0 0 .28 0l2.83-1.48a.41.41 0 0 1 .4 0l2.83 1.48a.44.44 0 0 0 .59-.18.45.45 0 0 0 0-.28l-.54-3.14a.45.45 0 0 1 .12-.39l2.29-2.23a.43.43 0 0 0 0-.61.44.44 0 0 0-.24-.13Z"
            />
        </svg>
    );
};
