import { useTheme } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { addId } from 'utils';

import styles from './ButtonGroup.module.scss';
export type Button = {
    label: string;
    selected?: boolean;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export type ButtonGroupProps = {
    buttons: Button[];
    className?: string;
};

export const ButtonGroup: React.FC<ButtonGroupProps> = ({ buttons, className = '' }) => {
    const { activeTheme } = useTheme();

    const [btns, setBtns] = useState<(Button & { id: string })[]>([]);

    const handleInitialSelectState = useCallback(() => {
        const initialSelection = buttons.map((b) => b.selected);
        const selectedBtnIndex = initialSelection.indexOf(true);
        if (selectedBtnIndex > -1) {
            const Bid = addId(buttons);
            setBtns(Bid);
            return;
        }
        const B = buttons.map((s, i) => {
            if (i === 0) return { ...s, selected: true };
            return s;
        });

        const Bid = addId(B);
        setBtns(Bid);
    }, [buttons]);

    const handleOnClickSelectState = useCallback(
        (id: string) => {
            const selectedButtons = btns.map((btn) =>
                btn.id === id ? { ...btn, selected: true } : { ...btn, selected: false }
            );
            setBtns(selectedButtons);
        },
        [btns]
    );

    useEffect(() => {
        if (btns.length > 0) return;
        handleInitialSelectState();
    }, [btns.length, handleInitialSelectState]);

    return (
        <div className={[styles.container, styles[activeTheme], className].join(' ')}>
            {btns.map((btn) => (
                <button
                    key={btn.id}
                    className={btn.selected ? styles.selected : ''}
                    onClick={(evt) => {
                        handleOnClickSelectState(btn.id);
                        btn.onClick?.(evt);
                    }}>
                    {btn.label}
                </button>
            ))}
        </div>
    );
};
