import { compareAsc, compareDesc, format } from 'date-fns';
import ENLocale from 'date-fns/locale/en-GB';
import PTLocale from 'date-fns/locale/pt';
import { useApi, useLanguage } from 'hooks';
import { useTheme } from 'hooks/useTheme';
import { useCallback, useEffect, useState } from 'react';
import ReactCalendar from 'react-calendar';
import {
    HiChevronDoubleLeft,
    HiChevronDoubleRight,
    HiChevronLeft,
    HiChevronRight
} from 'react-icons/hi';
import { cj } from 'utils/class-join';

import styles from './Calendar.module.scss';

type CalendarDatesTypes = 'join-date' | 'first-pay-date' | 'monthly_pay-date';
type CalendarDatesData = {
    id: number;
    _type: CalendarDatesTypes;
    date: string;
    colorName: string | undefined;
};

type NoteData = {
    id: number;
    _type: CalendarDatesTypes;
    title: string;
    description: string;
    date: string;
};

type DateLimits = {
    min: Date;
    max: Date;
};

type CalendarProps = {
    content: {
        TITLE: string;
    };
};
export const Calendar: React.FC<CalendarProps> = (props) => {
    const { activeTheme } = useTheme();
    const { currentLanguage, isPT } = useLanguage();
    const { getPaymentDates } = useApi();

    const [value, onChange] = useState(new Date());
    const [calendarDates, setCalendarDates] = useState<CalendarDatesData[]>([]);
    const [notes, setNotes] = useState<NoteData[]>([]);
    const [hideSection, setHideSection] = useState(false);
    const [dateLimits, setDateLimits] = useState<DateLimits>();

    const setTileClassName = useCallback(
        (date: Date) => {
            const dateObject = calendarDates.find((x) => {
                return (
                    date.getDay() === new Date(x.date).getDay() &&
                    date.getMonth() === new Date(x.date).getMonth() &&
                    date.getDate() === new Date(x.date).getDate()
                );
            });

            return dateObject ? dateObject.colorName : '';
        },
        [calendarDates]
    );

    useEffect(() => {
        const renderNoteType = (type: number): CalendarDatesTypes => {
            switch (type) {
                case 1:
                    return 'join-date';
                case 2:
                    return 'first-pay-date';
                case 3:
                    return 'monthly_pay-date';

                default:
                    return 'join-date';
            }
        };

        const setColorStyle = (type: number) => {
            switch (type) {
                case 1:
                    return styles.green;
                case 2:
                    return styles.purple;
                case 3:
                    return styles.blue;

                default:
                    return;
            }
        };

        const getData = async () => {
            try {
                const { months } = await getPaymentDates();
                if (!months) throw new Error(`"months" not found`);

                setNotes(
                    months
                        .map((m) => {
                            if (!m.dates) return [];

                            return m.dates.map((d) => ({
                                type: d.type,
                                label: d.label[currentLanguage.toLocaleLowerCase() as 'pt' | 'en'],
                                days: d.days.map((day) => Number(day)),
                                monthIndex: Number(m.month) - 1,
                                year: Number(m.year)
                            }));
                        })
                        .flat()
                        .map((note) => {
                            // use `i` case middle point in the `days` array
                            // const i = Math.trunc(Math.abs(note.days.length / 2));

                            return {
                                id: note.type,
                                _type: renderNoteType(note.type),
                                title: note.label.title,
                                description: note.label.description,
                                date: new Date(
                                    note.year,
                                    note.monthIndex,
                                    note.days[0]
                                    // note.days[i]
                                ).toISOString()
                            };
                        })
                        .filter((el) => !!el)
                );

                setCalendarDates(
                    months
                        .map((m) =>
                            m.dates.map((d) => ({
                                date: d.days.map((day) =>
                                    new Date(m.year, m.month - 1, day).toISOString()
                                ),
                                type: d.type,
                                label: d.label[currentLanguage.toLocaleLowerCase() as 'pt' | 'en']
                            }))
                        )
                        .flat(2)
                        .map((d) =>
                            d.date.map((date) => ({
                                date,
                                colorName: setColorStyle(d.type),
                                _type: renderNoteType(d.type),
                                id: d.type
                            }))
                        )
                        .flat()
                );

                const datesArray = months
                    .map((m) =>
                        m.dates.map((d) => d.days.map((day) => new Date(m.year, m.month - 1, day)))
                    )
                    .flat(2);

                const dates = {
                    oldest: datesArray.reduce((prev, curr) =>
                        compareAsc(prev, curr) ? prev : curr
                    ),
                    newest: datesArray.reduce((prev, curr) =>
                        compareDesc(prev, curr) ? curr : prev
                    )
                };

                setDateLimits({ min: dates.oldest, max: dates.newest });
            } catch (error) {
                console.error('getPaymentDates error', error);
                setHideSection(true);
            }
        };

        getData();
    }, [currentLanguage, getPaymentDates]);

    if (hideSection) return null;
    if (calendarDates.length === 0) return null;

    return (
        <section className={cj(styles.section, styles[activeTheme], 'container')}>
            <h2>{props.content.TITLE}</h2>

            <ReactCalendar
                onChange={onChange}
                value={value}
                view="month"
                locale={currentLanguage.toLocaleLowerCase()}
                maxDetail="month"
                maxDate={dateLimits?.max}
                minDate={dateLimits?.min}
                tileClassName={({ date }) => [styles.day, setTileClassName(date) ?? '']}
                showNavigation
                navigationLabel={({ date }) =>
                    format(date, 'MMMM yyyy', {
                        locale: isPT ? PTLocale : ENLocale
                    })
                }
                navigationAriaLabel="navigation"
                nextLabel={<HiChevronRight />}
                nextAriaLabel="next"
                next2Label={<HiChevronDoubleRight />}
                next2AriaLabel="last"
                prevLabel={<HiChevronLeft />}
                prevAriaLabel="previous"
                prev2Label={<HiChevronDoubleLeft />}
                prev2AriaLabel="first"
                className={styles.calendar}
            />

            <div className={styles['card-list']}>
                {notes.map((note, i) => (
                    <div className={cj(styles.card, styles[note._type])} key={i}>
                        <div className={styles.icon}>
                            <span>
                                {format(new Date(note.date), 'MMM', {
                                    locale: isPT ? PTLocale : ENLocale
                                }).toUpperCase()}
                            </span>
                            <strong>{format(new Date(note.date), 'd')}</strong>
                        </div>
                        <div className={styles.content}>
                            <span className={styles.date}>
                                {format(new Date(note.date), 'd MMM yyyy', {
                                    locale: isPT ? PTLocale : ENLocale
                                }).toUpperCase()}
                            </span>
                            <strong className={styles.title}>{note.title}</strong>
                            <span className={styles.description}>{note.description}</span>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};
