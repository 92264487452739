/**
 * based on `How SVG Line Animation Works` CSS-TRICKS blog article
 * @see https://css-tricks.com/svg-line-animation-works/
 */

import { useOnScreen, useTheme } from 'hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { COLORS } from 'theme/colors';

import styles from './ActivityGraphHearts.module.scss';

/**
 * @check CSS stylesheet to find the `start` and `end` values for each `stroke-dasharray` graph /area
 */
const STROKE_WIDTH = 12;
const MOVEMENT_START_STROKE_DASHARRAY = 296;
const MOVEMENT_END_STROKE_DASHARRAY = 595;
const NUTRITION_START_STROKE_DASHARRAY = 371.8;
const NUTRITION_END_STROKE_DASHARRAY = 250;
const HEALTH_START_STROKE_DASHARRAY = 200.1;
const HEALTH_END_STROKE_DASHARRAY = 395;

export type ActivityGraphHeartsProps = React.HTMLProps<HTMLDivElement> & {
    progress: {
        all: number;
        nutrition: number;
        movement: number;
        health: number;
    };
    area: 'movement' | 'nutrition' | 'health' | 'all';
    className?: string;
    disabled?: boolean;
};

const INACTIVE_BASE_RING_OPACITY_ON_LIGHT_MODE = 0.15;
const INACTIVE_BASE_RING_OPACITY_ON_DARK_MODE = 0.3;

export const ActivityGraphHearts: React.FC<ActivityGraphHeartsProps> = ({
    progress,
    className = '',
    area,
    disabled = false,
    ...props
}) => {
    const { activeTheme, darkMode } = useTheme();
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);

    const [activeRing, setActiveRing] = useState({
        nutrition: false,
        movement: false,
        health: false
    });

    useEffect(
        () =>
            setActiveRing({
                nutrition: area === 'all' || area === 'nutrition',
                movement: area === 'all' || area === 'movement',
                health: area === 'all' || area === 'health'
            }),
        [area]
    );

    /**
     * Returns stroke-dasharray based on the area percentage
     * @param {number} percentage - current progress value received from API
     * @param {number} start - `stroke-dasharray` value to set the graph to zero
     * @param {number} end - `stroke-dasharray` value to set the graph to complete
     * @check CSS stylesheet to find the `start` and `end` values for each graph /area
     */
    const calcStrokeDashArray = useCallback((percentage: number, start: number, end: number) => {
        const percent = (end - start) / 100;
        const value = start + percentage * percent;
        return `${value}%`;
    }, []);

    const containerClassName = useMemo(
        () =>
            [
                styles.container,
                styles[activeTheme],
                isVisible ? styles.animate : '',
                disabled ? styles.disabled : '',
                className
            ].join(' '),
        [activeTheme, className, disabled, isVisible]
    );

    return (
        <div className={containerClassName} ref={ref} {...props}>
            <svg
                data-name="HEARTS"
                className={styles.hearts}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 182 170">
                <g
                    data-name="MOVEMENT"
                    className={[
                        styles.heart,
                        styles.movement,
                        activeRing.movement ? styles.active : ''
                    ].join(' ')}>
                    <path
                        data-name="BASE"
                        className={styles.base}
                        style={{
                            opacity: !activeRing.movement
                                ? darkMode
                                    ? INACTIVE_BASE_RING_OPACITY_ON_DARK_MODE
                                    : INACTIVE_BASE_RING_OPACITY_ON_LIGHT_MODE
                                : 1
                        }}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={STROKE_WIDTH}
                        stroke={
                            activeRing.movement && !disabled
                                ? darkMode
                                    ? COLORS.BLUE_800
                                    : COLORS.BLUE_50
                                : darkMode
                                ? COLORS.BLUISH_PURPLE_800
                                : COLORS.NEUTRAL_100
                        }
                        d="M1118.3,166.8c41.9-19.66,71.57,6.8,80.51,28.21l.07.16c17.52,43.66-21.65,80.13-33.46,91.16a265.81,265.81,0,0,1-36.65,28.45,19.08,19.08,0,0,1-10.47,3.12h0a19.08,19.08,0,0,1-10.47-3.12,243,243,0,0,1-37.4-29.07c-11.21-10.68-50-46.94-32.71-90.54l.07-.16c8.94-21.41,39.37-47,80.51-28.21"
                        transform="translate(-1027.32 -153.9)"
                    />
                    <path
                        data-name="PROGRESS"
                        className={styles.progress}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={STROKE_WIDTH}
                        strokeDasharray={calcStrokeDashArray(
                            progress.movement,
                            MOVEMENT_START_STROKE_DASHARRAY,
                            MOVEMENT_END_STROKE_DASHARRAY
                        )}
                        stroke={
                            activeRing.movement && !disabled
                                ? COLORS.BLUE_400
                                : darkMode
                                ? COLORS.BLUISH_PURPLE_800
                                : COLORS.WHITE
                        }
                        d="M1118.3,166.8c41.9-19.66,71.57,6.8,80.51,28.21l.07.16c17.52,43.66-21.65,80.13-33.46,91.16a265.81,265.81,0,0,1-36.65,28.45,19.08,19.08,0,0,1-10.47,3.12h0a19.08,19.08,0,0,1-10.47-3.12,243,243,0,0,1-37.4-29.07c-11.21-10.68-50-46.94-32.71-90.54l.07-.16c8.94-21.41,39.37-47,80.51-28.21"
                        transform="translate(-1027.32 -153.9)"
                    />
                </g>

                <g
                    data-name="NUTRITION"
                    className={[
                        styles.heart,
                        styles.nutrition,
                        activeRing.nutrition ? styles.active : ''
                    ].join(' ')}>
                    <path
                        data-name="BASE"
                        className={styles.base}
                        style={{
                            opacity: !activeRing.nutrition
                                ? darkMode
                                    ? INACTIVE_BASE_RING_OPACITY_ON_DARK_MODE
                                    : INACTIVE_BASE_RING_OPACITY_ON_LIGHT_MODE
                                : 1
                        }}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={STROKE_WIDTH}
                        stroke={
                            activeRing.nutrition && !disabled
                                ? darkMode
                                    ? COLORS.MAGENTA_800
                                    : COLORS.MAGENTA_50
                                : darkMode
                                ? COLORS.BLUISH_PURPLE_800
                                : COLORS.NEUTRAL_100
                        }
                        d="M1185.89,200.42c-11.25-26.93-45.54-35.17-67.59-16.74h0c-22-18.43-56.34-10.19-67.59,16.74-11.74,29.12,8.91,55.14,29.85,75.3,19.64,17.94,28.08,22.87,34.91,27.32a6,6,0,0,0,5.67,0c6.8-4.5,15.27-9.38,34.91-27.32C1177,255.56,1197.63,229.54,1185.89,200.42Z"
                        transform="translate(-1027.32 -153.9)"
                    />
                    <path
                        data-name="PROGRESS"
                        className={styles.progress}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={STROKE_WIDTH}
                        stroke={
                            activeRing.nutrition && !disabled
                                ? COLORS.MAGENTA_500
                                : darkMode
                                ? COLORS.BLUISH_PURPLE_800
                                : COLORS.WHITE
                        }
                        strokeDasharray={calcStrokeDashArray(
                            progress.nutrition,
                            NUTRITION_START_STROKE_DASHARRAY,
                            NUTRITION_END_STROKE_DASHARRAY
                        )}
                        d="M1118.29,183.68c-22-18.43-56.34-10.19-67.59,16.74-11.74,29.12,8.91,55.14,29.85,75.3,19.64,17.94,28.08,22.87,34.91,27.32a6,6,0,0,0,5.67,0c6.8-4.5,15.27-9.38,34.91-27.32,20.94-20.16,41.59-46.18,29.85-75.3-11.25-26.93-45.54-35.17-67.59-16.74"
                        transform="translate(-1027.32 -153.9)"
                    />
                </g>

                <g
                    data-name="HEALTH"
                    className={[
                        styles.heart,
                        styles.health,
                        activeRing.health ? styles.active : ''
                    ].join(' ')}>
                    <path
                        data-name="BASE"
                        className={styles.base}
                        style={{
                            opacity: !activeRing.health
                                ? darkMode
                                    ? INACTIVE_BASE_RING_OPACITY_ON_DARK_MODE
                                    : INACTIVE_BASE_RING_OPACITY_ON_LIGHT_MODE
                                : 1
                        }}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={STROKE_WIDTH}
                        stroke={
                            activeRing.health && !disabled
                                ? darkMode
                                    ? COLORS.PURPLE_800
                                    : COLORS.PURPLE_50
                                : darkMode
                                ? COLORS.BLUISH_PURPLE_800
                                : COLORS.NEUTRAL_100
                        }
                        d="M1118.29,288.11c-5.79-3.87-13.82-9.53-28.17-22.62-25.13-24.23-33.3-42.68-26.47-59.75a29.43,29.43,0,0,1,27.27-18c13.54,0,28.39,14,28.39,14s11.89-14,26.36-14a29.43,29.43,0,0,1,27.27,18c6.83,17.08-1.34,35.51-26.47,59.75C1132.12,278.58,1124.09,284.24,1118.29,288.11Z"
                        transform="translate(-1027.32 -153.9)"
                    />
                    <path
                        data-name="PROGRESS"
                        className={styles.progress}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={STROKE_WIDTH}
                        strokeDasharray={calcStrokeDashArray(
                            progress.health,
                            HEALTH_START_STROKE_DASHARRAY,
                            HEALTH_END_STROKE_DASHARRAY
                        )}
                        stroke={
                            activeRing.health && !disabled
                                ? COLORS.PURPLE_600
                                : darkMode
                                ? COLORS.BLUISH_PURPLE_800
                                : COLORS.WHITE
                        }
                        d="M1119.32,201.78c.3-.36,12.07-14,26.35-14a29.43,29.43,0,0,1,27.27,18c6.83,17.08-1.34,35.51-26.47,59.75-14.35,13.09-22.38,18.75-28.18,22.62-5.79-3.87-13.82-9.53-28.17-22.62-25.13-24.23-33.3-42.68-26.47-59.75a29.43,29.43,0,0,1,27.27-18c13.36,0,28,13.63,28.38,14"
                        transform="translate(-1027.32 -153.9)"
                    />
                </g>
            </svg>
        </div>
    );
};
