import { LogoHi } from 'assets/logos';
import { Button, Input, InputPassword, LangSelect, Link } from 'components/elements';
import { /* ACTIVATION */ META_TAGS } from 'content';
import { useFormik } from 'formik';
import { useAuth, useLanguage } from 'hooks';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { HiOutlineCheckCircle, HiOutlineXCircle } from 'react-icons/hi';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { api, isAxiosError } from 'services';
import { IconExternal } from 'theme/icons';
import { cj, DEV } from 'utils';
import * as Yup from 'yup';

import styles from './AccountActivation.module.scss';

// HOOK
type AccountActivationContextData = {
    formStep: FormSteps;
    setFormStep: React.Dispatch<React.SetStateAction<FormSteps>>;
    messageType: MessageType;
    setMessageType: React.Dispatch<React.SetStateAction<MessageType>>;
    userId: string | undefined;
    setUserId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

type FormSteps = 'email' | 'password' | 'message';
type MessageType = 'success' | 'already-active' | 'not-client';

const AccountActivationContext = createContext({} as AccountActivationContextData);

const AccountActivationProvider: React.FC = ({ children }) => {
    const [formStep, setFormStep] = useState<FormSteps>('email');
    const [messageType, setMessageType] = useState<MessageType>('success');
    const [userId, setUserId] = useState<string>();

    return (
        <AccountActivationContext.Provider
            value={{
                formStep,
                setFormStep,
                messageType,
                setMessageType,
                userId,
                setUserId
            }}>
            {children}
        </AccountActivationContext.Provider>
    );
};

const useAccountActivation: () => AccountActivationContextData = () => {
    const context = useContext(AccountActivationContext);

    if (!context) {
        throw Error(
            'the hook useAccountActivation must be used inside a AccountActivationProvider'
        );
    }
    return context;
};

// MAIN
export const AccountActivation: React.FC = () => (
    <AccountActivationProvider>
        <Main />
    </AccountActivationProvider>
);

const Main: React.FC = () => {
    const navigate = useNavigate();
    const { isPT } = useLanguage();
    const { user } = useAuth();
    const { formStep } = useAccountActivation();

    const CONTENT = useMemo(() => {
        const data = {
            PT: {
                TITLE: 'Bem-vindo/a ao ecossistema Hi!',
                DESCRIPTION: [
                    'Um ecossistema único, focado no seu bem-estar e estilo de vida saudável com produtos e serviços ligados ao exercício físico, nutrição e à saúde.'
                ],
                ACTION: 'Active a sua conta Hi!'
            },
            EN: {
                TITLE: 'Welcome to the Hi! ecosystem',
                DESCRIPTION: [
                    'A unique ecosystem focused on your wellbeing and healthy lifestyle with products and services linked to exercise, nutrition and health.'
                ],
                ACTION: 'Activate your Hi! account'
            }
        };
        return isPT ? data.PT : data.EN;
    }, [isPT]);

    useEffect(() => {
        if (user) navigate('/inicio');
    }, [navigate, user]);

    return (
        <section className={styles.section}>
            <Helmet>
                <title>
                    {isPT
                        ? META_TAGS.PUBLIC.ACCOUNT_ACTIVATION.PT.TITLE
                        : META_TAGS.PUBLIC.ACCOUNT_ACTIVATION.EN.TITLE}
                </title>
            </Helmet>

            <div className={styles.container}>
                <div className={styles.logo}>
                    <RouterLink to="/">
                        <LogoHi animate showLettering={false} width={72} />
                    </RouterLink>
                </div>

                {formStep !== 'message' && (
                    <div className={cj(styles.intro, styles['fade-in'])}>
                        <h1>{CONTENT.TITLE}</h1>
                        <p>{CONTENT.DESCRIPTION}</p>
                        <h2>{CONTENT.ACTION}</h2>
                    </div>
                )}

                <div className={styles.body}>
                    <Form />
                </div>

                <LangSelect className={styles['lang-button']} />
            </div>
        </section>
    );
};

// FORM
const Form: React.FC = () => {
    return (
        <>
            <FormEmail />
            <FormPassword />
            <Message />
        </>
    );
};

// FORM EMAIL
type FormEmailValues = { email: string };
type ResponseType = {
    data: {
        status: 'user_not_active';
        message: string;
        user_id: string;
    };
};

const FormEmail: React.FC = () => {
    const { isPT } = useLanguage();
    const { setFormStep, formStep, setUserId, setMessageType } = useAccountActivation();

    const CONTENT = useMemo(() => {
        const data = {
            PT: {
                FORM: {
                    LABEL: {
                        INPUT: 'Adicione o seu email',
                        SUBMIT: 'Enviar'
                    }
                },
                ERROR: {
                    EXPIRED:
                        'Link expirado. Por favor solicite uma nova recuperação de palavra-passe.'
                }
            },
            EN: {
                FORM: {
                    LABEL: {
                        INPUT: 'Add your email',
                        SUBMIT: 'Send'
                    }
                },
                ERROR: {
                    EXPIRED: 'Link expired. Please request a new password retrieval.'
                }
            }
        };

        return isPT ? data.PT : data.EN;
    }, [isPT]);

    const initialValues = { email: '' };

    const onSubmit = useCallback(
        async (values: FormEmailValues) => {
            try {
                const { email } = values;

                const response: ResponseType = await api.post(`/users/activation/status`, {
                    email
                });

                setUserId(response.data.user_id);
                setFormStep('password');
            } catch (error) {
                if (isAxiosError(error) && error.response?.data.status === 'user_ative') {
                    setMessageType('already-active');
                    setFormStep('message');
                }

                if (isAxiosError(error) && error.response?.data.status === 'not_found') {
                    setMessageType('not-client');
                    setFormStep('message');
                }
            }
        },
        [setFormStep, setMessageType, setUserId]
    );

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('email obrigatório').email('verifique o email inserido')
    });

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    if (formStep !== 'email') return null;

    return (
        <form onSubmit={formik.handleSubmit} className={cj(styles.form, styles['fade-in'])}>
            <Input
                type="email"
                label={CONTENT.FORM.LABEL.INPUT}
                autoComplete="username"
                className={styles.input}
                hasError={formik.touched.email && !!formik.errors.email}
                errorMessage={formik.errors.email}
                {...formik.getFieldProps('email')}
            />
            <Button type="submit" label={CONTENT.FORM.LABEL.SUBMIT} size="lg" fullWidth />
        </form>
    );
};

// FORM PASSWORD
type FormPasswordValues = { password: string; confirm: string };

const FormPassword: React.FC = () => {
    const { isPT } = useLanguage();
    const { formStep, setFormStep, userId, setMessageType } = useAccountActivation();

    const CONTENT = useMemo(() => {
        const data = {
            PT: {
                FORM: {
                    LABEL: {
                        INPUT_PASSWORD: 'Adicione uma palavra-passe',
                        INPUT_CONFIRM: 'Repita a palavra-passe',
                        SUBMIT: 'Ativar Conta'
                    },
                    VALIDATION: {
                        PASSWORD: {
                            REQUIRED: 'palavra-passe obrigatória',
                            INVALID:
                                'insira uma palavra-passe com o mínimo de 6 caracteres alfa-numéricos'
                        },
                        CONFIRM: {
                            REQUIRED: 'palavra-passe obrigatória',
                            INVALID: 'a palavra-passe não coincide'
                        }
                    },
                    ERROR: {
                        EXPIRED:
                            'Link expirado. Por favor solicite uma nova recuperação de palavra-passe'
                    }
                }
            },
            EN: {
                FORM: {
                    LABEL: {
                        INPUT_PASSWORD: 'Add a password',
                        INPUT_CONFIRM: 'Repeat the password',
                        SUBMIT: 'Activate Account'
                    },
                    VALIDATION: {
                        PASSWORD: {
                            REQUIRED: 'password required',
                            INVALID: 'enter a password with a minimum of 6 alpha-numeric characters'
                        },
                        CONFIRM: {
                            REQUIRED: 'password required',
                            INVALID: 'the password does not match'
                        }
                    },
                    ERROR: {
                        EXPIRED: 'Link expired. Please request a new password retrieval.'
                    }
                }
            }
        };

        return isPT ? data.PT : data.EN;
    }, [isPT]);

    const initialValues = { password: '', confirm: '' };

    const onSubmit = useCallback(
        async (values: FormPasswordValues) => {
            try {
                const { password } = values;

                await api.post(`/users/${userId}/activate`, {
                    password
                });

                setMessageType('success');
                setFormStep('message');
            } catch (error) {
                if (isAxiosError(error) && error.response?.data.error === 'wrong_token') {
                    DEV &&
                        console.log(
                            `%c ${error.response?.data.error} `,
                            'background-color: red; color: white; font-size: 20px'
                        );
                }
            }
        },
        [setFormStep, setMessageType, userId]
    );

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required(CONTENT.FORM.VALIDATION.PASSWORD.REQUIRED)
            .min(6, CONTENT.FORM.VALIDATION.PASSWORD.INVALID),
        confirm: Yup.string()
            .required(CONTENT.FORM.VALIDATION.CONFIRM.REQUIRED)
            .oneOf([Yup.ref('password'), null], CONTENT.FORM.VALIDATION.CONFIRM.INVALID)
    });

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    if (formStep !== 'password') return null;

    return (
        <form onSubmit={formik.handleSubmit} className={cj(styles.form, styles['fade-in'])}>
            <InputPassword
                label={CONTENT.FORM.LABEL.INPUT_PASSWORD}
                hasError={formik.touched.password && formik.errors.password ? true : false}
                errorMessage={formik.errors.password}
                {...formik.getFieldProps('password')}
            />
            <InputPassword
                label={CONTENT.FORM.LABEL.INPUT_CONFIRM}
                hasError={formik.touched.confirm && formik.errors.confirm ? true : false}
                errorMessage={formik.errors.confirm}
                {...formik.getFieldProps('confirm')}
            />
            <Button type="submit" label={CONTENT.FORM.LABEL.SUBMIT} size="lg" fullWidth />
        </form>
    );
};

// FORM MESSAGE
const Message: React.FC = () => {
    const { isPT } = useLanguage();
    const { messageType, formStep } = useAccountActivation();

    const CONTENT = useMemo(() => {
        const data = {
            PT: {
                SUCCESS: {
                    TITLE: 'Parabéns',
                    SUBTITLE: 'A sua conta encontra-se ativa',
                    DESCRIPTION:
                        'Já pode aceder à aplicação Hi! e usufruir dos serviços disponíveis.',
                    LINK: 'Aceder'
                },
                ACTIVE: {
                    TITLE: 'Conta ativa',
                    SUBTITLE: 'A sua conta já se encontra ativa',
                    DESCRIPTION: 'Aceda à aplicação Hi! e usufrua os serviços disponíveis.',
                    LINK: 'Aceder'
                },
                NOT_CLIENT: {
                    TITLE: 'Não é cliente',
                    SUBTITLE: 'Ainda não é cliente Hi!',
                    DESCRIPTION: 'Visite o site Hi! e conheça todas as vantagens de ser cliente',
                    LINK: 'Visitar'
                }
            },
            EN: {
                SUCCESS: {
                    TITLE: 'Congratulations',
                    SUBTITLE: 'Your account is active',
                    DESCRIPTION:
                        'You can now access the Hi! application and take advantage of the available services.',
                    LINK: 'Enter'
                },
                ACTIVE: {
                    TITLE: 'Active account',
                    SUBTITLE: 'Your account is already active',
                    DESCRIPTION: 'Visit the Hi! application and enjoy the available services.',
                    LINK: 'Enter'
                },
                NOT_CLIENT: {
                    TITLE: 'Not a client',
                    SUBTITLE: 'Not yet a Hi customer!',
                    DESCRIPTION:
                        'Visit the Hi! site and discover all the advantages of being a customer',
                    LINK: 'Visit'
                }
            }
        };

        const contentByLanguage = isPT ? data.PT : data.EN;

        switch (messageType) {
            case 'success':
            case 'already-active':
                return {
                    ...contentByLanguage.ACTIVE,
                    SLUG: '/login',
                    ICON: <HiOutlineCheckCircle />
                };

            case 'not-client':
                return {
                    ...contentByLanguage.NOT_CLIENT,
                    SLUG: 'https://www.hihealthyinsurance.com/',
                    ICON: <HiOutlineXCircle />
                };

            default:
                return {
                    ...contentByLanguage.SUCCESS,
                    SLUG: '/login',
                    ICON: <HiOutlineCheckCircle />
                };
        }
    }, [isPT, messageType]);

    if (formStep !== 'message') return null;

    return (
        <span className={styles.message}>
            <div className={cj(styles.icon, styles['swirl-in-fwd'])}>{CONTENT.ICON}</div>
            <span className={cj(styles.content, styles['fade-in'])}>
                <h1>{CONTENT.TITLE}</h1>
                <h2>{CONTENT.SUBTITLE}</h2>
                <p>{CONTENT.DESCRIPTION}</p>
            </span>
            {messageType === 'not-client' ? (
                <a
                    href={CONTENT.SLUG}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={cj(styles.link, styles.external, styles['fade-in'])}>
                    <span>{CONTENT.LINK}</span>
                    <IconExternal className={styles.icon} />
                </a>
            ) : (
                <Link
                    to={CONTENT.SLUG}
                    label={CONTENT.LINK}
                    type="button"
                    area="default"
                    size="md"
                    className={cj(styles.link, styles['fade-in'])}
                />
            )}
        </span>
    );
};
