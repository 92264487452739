import { LogoHi } from 'assets/logos';
import { Avatar, ButtonIcon } from 'components/elements';
import { useAuth, useScroll, useTheme } from 'hooks';
import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import styles from './HeaderSecondary.module.scss';

export type HeaderProps = {
    backButton: boolean;
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
    hasImage?: boolean;
    title?: string;
};

export const HeaderSecondary: React.FC<HeaderProps> = ({
    backButton,
    setShowMenu,
    hasImage,
    title
}) => {
    const navigate = useNavigate();
    const { activeTheme } = useTheme();
    const { user } = useAuth();
    const { scrolled } = useScroll();

    const scrolledClassName = useMemo(
        () => (scrolled || !hasImage ? styles.scrolled : ''),
        [hasImage, scrolled]
    );

    const headerSecondaryClassName = useMemo(
        () => [styles['header-secondary'], scrolledClassName, styles[activeTheme]].join(' '),
        [activeTheme, scrolledClassName]
    );

    const backBtnClassName = useMemo(
        () => [styles['back-btn'], styles[activeTheme]].join(' '),
        [activeTheme]
    );

    if (!user) return null;

    return (
        <div className={headerSecondaryClassName}>
            <nav className={styles.nav}>
                {backButton ? (
                    <ButtonIcon
                        icon="ARROW_LEFT"
                        size="XS"
                        backgroundOpacity="TRANSPARENT"
                        className={backBtnClassName}
                        onClick={() => navigate(-1)}
                    />
                ) : (
                    <Link to="/myhi">
                        <Avatar
                            source={user.image}
                            alt="utilizador"
                            className={styles.avatar}
                            hasNotifications={user.new_notifications}
                        />
                    </Link>
                )}
                {title ? (
                    <span className={styles.title}>{title}</span>
                ) : (
                    <Link to="/inicio">
                        <LogoHi
                            animate
                            showLettering={false}
                            className={[styles.logo, styles[activeTheme]].join(' ')}
                        />
                    </Link>
                )}
                <ButtonIcon
                    icon="BURGER"
                    size="MD"
                    backgroundOpacity="TRANSPARENT"
                    onClick={() => setShowMenu(true)}
                    className={[styles['menu-button'], styles[activeTheme]].join(' ')}
                />
            </nav>
        </div>
    );
};

export const MemoizedHeaderSecondary = React.memo(
    HeaderSecondary,
    (prevProps, nextProps) => prevProps.setShowMenu !== nextProps.setShowMenu
);
