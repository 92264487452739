import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconCheck: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 14,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 14"
            width={width}
            height={height}
            style={{ fill: color }}
            {...props}>
            <path
                d="m2666.49 897.41 6.39 6.58a.79.79 0 0 0 .25.17.73.73 0 0 0 .81-.17l12.11-12.46a.79.79 0 0 0 0-1.09.74.74 0 0 0-1.06 0l-11.58 11.92-5.87-6a.73.73 0 0 0-1.05 0 .77.77 0 0 0 0 1.05Z"
                transform="translate(-2666.27 -890.22)"
            />
        </svg>
    );
};
