import { ButtonIcon } from 'components/elements';
import { useLanguage, useTheme } from 'hooks';

import styles from './NotFoundMessage.module.scss';

type NotFoundMessageProps = {
    show: boolean;
    setResetForm?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NotFoundMessage: React.FC<NotFoundMessageProps> = ({ show, setResetForm }) => {
    const { isPT } = useLanguage();
    const { activeTheme } = useTheme();

    return (
        <div className={`${styles.card} ${activeTheme} ${show ? styles.show : ''}`}>
            <strong>{isPT ? 'não encontrado' : 'not found'}</strong>
            {setResetForm && (
                <ButtonIcon
                    icon="RESET"
                    size="MD"
                    backgroundOpacity="TRANSPARENT"
                    onClick={() => setResetForm(true)}
                />
            )}
        </div>
    );
};
