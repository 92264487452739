import React, { useEffect, useState } from 'react';
import { IconAvatar } from 'theme/icons';

import styles from './Avatar.module.scss';

export type AvatarProps = {
    source?: string | null;
    alt: string;
    hasNotifications?: boolean;
    className?: string;
    width?: number;
    height?: number;
} & React.HTMLProps<HTMLDivElement>;

const AvatarComponent: React.FC<AvatarProps> = ({
    hasNotifications = false,
    className = '',
    source = null,
    width = 48,
    height = 48,
    alt,
    ...props
}) => {
    const [imgSource, setImageSource] = useState<string | null>(source);
    useEffect(() => setImageSource(source), [source]);

    const avatarClassName = `${styles.avatar} ${
        hasNotifications ? styles.notification : ''
    } ${className}`;

    return (
        <div
            className={avatarClassName}
            style={{ width: `${width}px`, height: `${height}px` }}
            {...props}>
            {imgSource ? (
                <img src={imgSource} alt={alt} onError={() => setImageSource(null)} />
            ) : (
                <div className={styles.icon}>
                    <IconAvatar />
                </div>
            )}
        </div>
    );
};

export const Avatar = React.memo(
    AvatarComponent,
    (prevProps, nextProps) => prevProps.hasNotifications !== nextProps.hasNotifications
);
