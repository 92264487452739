import { Button, Datalist, Input, Link } from 'components/elements';
import { toastOptions } from 'config';
import { permissions } from 'config';
import { HEALTH_CHECKUP, META_TAGS } from 'content';
import { useFormik } from 'formik';
import { useApi, useAuth, useGamification, useLanguage, useScroll, useTheme } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { IconCheck } from 'theme/icons';
import * as Yup from 'yup';

import styles from './Checkup.module.scss';
import { PT_CITIES } from './static';

type FormValues = {
    phone: number | string;
    addressState: string;
};

type FormProps = {
    setShowSuccessMsg: (status: boolean) => void;
};

type FirstScreenProps = {
    show: (show: boolean) => void;
};

const FirstScreen: React.FC<FirstScreenProps> = ({ show }) => {
    const { isPT } = useLanguage();
    const CONTENT = isPT ? HEALTH_CHECKUP.PT.FIRST_SCREEN : HEALTH_CHECKUP.EN.FIRST_SCREEN;

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? META_TAGS.PRIVATE.HEALTH.CHECKUP.PT.TITLE
                        : META_TAGS.PRIVATE.HEALTH.CHECKUP.EN.TITLE}
                </title>
            </Helmet>
            <section className={`${styles.firstScreen} container`}>
                <h1>{CONTENT.TITLE}</h1>
                <p className={styles.lead}>{CONTENT.LEAD}</p>
                <Button
                    label={CONTENT.BUTTON}
                    size="lg"
                    area="health"
                    onClick={() => show(false)}
                    className={styles.button}
                />
                <p className={styles.note}>{CONTENT.NOTE}</p>
                <div className={styles.specs}>
                    {CONTENT.SPECS.map((s) => {
                        if (s.TYPE === 'h2') return <h2 key={s.ID.toString()}>{s.TEXT}</h2>;
                        if (s.TYPE === 'p') return <p key={s.ID.toString()}>{s.TEXT}</p>;
                        if (s.TYPE === 'ol')
                            return (
                                <ol key={s.ID.toString()}>
                                    {s.ITEMS?.map((it) => (
                                        <li key={it.ID.toString()}>{it.TEXT}</li>
                                    ))}
                                </ol>
                            );
                        if (s.TYPE === 'note')
                            return (
                                <p className={styles.note} key={s.ID.toString()}>
                                    {s.TEXT}
                                </p>
                            );
                        if (s.TYPE === 'highlight')
                            return (
                                <h3 className={styles.highlight} key={s.ID.toString()}>
                                    {s.TEXT}
                                </h3>
                            );
                    })}
                </div>
            </section>
        </>
    );
};

const Form: React.FC<FormProps> = ({ setShowSuccessMsg }) => {
    const { isPT } = useLanguage();
    const { user, validateUserAccessToArea } = useAuth();
    const { reportInteraction } = useGamification();
    const { postCheckupRequest } = useApi();

    const [datalistHasContent, setDatalistHasContent] = useState(false);
    const [addressStates, setAddressStates] = useState<{ name: string; id: string }[]>([]);
    const [loading, setLoading] = useState(false);

    const CONTENT = isPT ? HEALTH_CHECKUP.PT.FORM : HEALTH_CHECKUP.EN.FORM;

    useEffect(
        () => validateUserAccessToArea(permissions.health.checkup),
        [validateUserAccessToArea]
    );

    useEffect(() => {
        setAddressStates(PT_CITIES);
    }, []);

    const initialValues = { phone: '', addressState: '' };

    const validationSchema = Yup.object().shape({
        phone: Yup.number().required('adicione o contacto telefónico'),
        addressState: Yup.string().required('selecione a sua freguesia')
    });

    const onSubmit = useCallback(
        async ({ phone, addressState }: FormValues) => {
            try {
                setLoading(true);
                const isValidAddress = addressStates
                    .map(({ name }) => name)
                    .find((address) => address === addressState);
                if (!isValidAddress) throw CONTENT.VALIDATION_MESSAGES.PARISH;

                try {
                    if (!user) throw new Error();

                    const data = {
                        nclient: user.subscriptions.insurance.number || '',
                        plan: user.membership.name,
                        name: user.name.full,
                        birth_date: user.birth_date,
                        email: user.email,
                        contact: phone,
                        local: addressState
                    };

                    await postCheckupRequest(data);

                    reportInteraction({
                        interaction: 'checkup',
                        area: 'health',
                        id: null
                    });

                    setShowSuccessMsg(true);
                    setLoading(false);
                } catch (error) {
                    // TO DO
                    console.log(error);
                }
            } catch (error) {
                toast.dark(String(error), toastOptions);
            }
        },
        [
            CONTENT.VALIDATION_MESSAGES.PARISH,
            addressStates,
            postCheckupRequest,
            reportInteraction,
            setShowSuccessMsg,
            user
        ]
    );

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    formik.handleBlur = () =>
        formik.values.addressState ? setDatalistHasContent(true) : setDatalistHasContent(false);

    if (!user) return null;

    return (
        <section className={`${styles.form} container`}>
            <form onSubmit={formik.handleSubmit} onBlur={formik.handleBlur}>
                <h3>{CONTENT.TITLE}</h3>
                <Input
                    label={CONTENT.LABELS.PHONE}
                    type="number"
                    hasError={formik.touched.phone && formik.errors.phone ? true : false}
                    disabled={loading}
                    errorMessage={formik.errors.phone}
                    {...formik.getFieldProps('phone')}
                />
                <Datalist
                    id="addressState"
                    label={CONTENT.LABELS.PARISH}
                    options={addressStates.map(({ name }) => name)}
                    hasContent={datalistHasContent}
                    disabled={loading}
                    hasError={
                        formik.touched.addressState && formik.errors.addressState ? true : false
                    }
                    errorMessage={formik.errors.addressState}
                    className={styles.datalist}
                    {...formik.getFieldProps('addressState')}
                />
                <Button
                    type="submit"
                    label={CONTENT.LABELS.SUBMIT}
                    size="lg"
                    area="health"
                    loading={loading}
                    className={styles['submit-btn']}
                />
            </form>
        </section>
    );
};

const SuccessMsg: React.FC = () => {
    const { user } = useAuth();
    const { isPT } = useLanguage();
    const { scrollTop } = useScroll();
    const { activeTheme } = useTheme();

    const CONTENT = isPT ? HEALTH_CHECKUP.PT.MESSAGE.SUCCESS : HEALTH_CHECKUP.EN.MESSAGE.SUCCESS;

    useEffect(scrollTop);

    return (
        <section className={[styles['success-msg'], 'container'].join(' ')}>
            <IconCheck className={[styles.icon, styles[activeTheme]].join(' ')} />
            <h2>
                {CONTENT.TITLE} {user?.name.first}
            </h2>
            <p>{CONTENT.DESCRIPTION}</p>
            <Link size="xs" type="button" area="health" label={CONTENT.BUTTON} to="/inicio" />
        </section>
    );
};

export const Checkup: React.FC = () => {
    const [showFirstScreen, setShowFirstScreen] = useState(true);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);

    const { isPT } = useLanguage();
    const CONTENT = isPT ? HEALTH_CHECKUP.PT : HEALTH_CHECKUP.EN;

    if (showFirstScreen) return <FirstScreen show={setShowFirstScreen} />;

    return (
        <>
            <section className={`${styles.intro} container`}>
                <span className={styles.preTitle}>{CONTENT.CATEGORY}</span>
                <h1>{CONTENT.TITLE}</h1>
            </section>
            {!showSuccessMsg ? <Form setShowSuccessMsg={setShowSuccessMsg} /> : <SuccessMsg />}
        </>
    );
};
