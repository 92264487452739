import { useLanguage, useTheme } from 'hooks';
import { HiOutlineDocumentDownload } from 'react-icons/hi';

import styles from './ButtonDownload.module.scss';

export type ButtonDownloadProps = React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
>;

export const ButtonDownload: React.FC<ButtonDownloadProps> = ({
    className = '',
    href,
    ...props
}) => {
    const { isPT } = useLanguage();
    const { activeTheme } = useTheme();
    return (
        <a
            href={href}
            className={[styles.button, styles[activeTheme], className].join(' ')}
            aria-label={isPT ? 'descarregue a seu ficheiro' : 'download your file'}
            {...props}>
            <HiOutlineDocumentDownload className={styles.icon} />
        </a>
    );
};
