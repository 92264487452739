import { useStaff } from '../../useStaff';
import styles from './Intro.module.scss';

export const Intro: React.FC = () => {
    const { CONTENT } = useStaff();

    return (
        <section className={`${styles.intro} container`}>
            <span className={styles['pre-title']}>{CONTENT.INTRO.PRE_TITLE}</span>
            <h1>{CONTENT.INTRO.TITLE}</h1>
        </section>
    );
};
