import { BgEffect } from 'assets/effects';
import { LogoHi } from 'assets/logos';
import { LangSelect } from 'components/elements';
import { QRCode } from 'components/elements/QRCode';
import { DESKTOP_MESSAGE } from 'content';
import { useLanguage } from 'hooks';

import styles from './DesktopMessage.module.scss';

export type DesktopMessageProps = React.HTMLProps<HTMLDivElement>;

export const DesktopMessage: React.FC<DesktopMessageProps> = (props) => {
    const { isPT } = useLanguage();
    const CONTENT = isPT ? DESKTOP_MESSAGE.PT : DESKTOP_MESSAGE.EN;

    return (
        <div className={styles.message} {...props}>
            <div className={styles.container}>
                <LogoHi animate className={styles.logo} showLettering={false} />
                <div className={styles.wrapper}>
                    <h2>{CONTENT.TITLE}</h2>
                    <p>{CONTENT.DESCRIPTION}</p>
                    <QRCode className={styles['qr-code']} infoMessage={CONTENT.QR_CODE_MSG} />
                </div>
            </div>
            <LangSelect className={styles['lang-button']} />
            <BgEffect />
        </div>
    );
};
