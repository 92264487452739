// import { makeServer } from 'mock-api';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

// if (process.env.NODE_ENV !== 'production') makeServer({ environment });
// makeServer({ environment });

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// SERVICE WORKER : REGISTER
// if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
//     navigator.serviceWorker
//         .register('/sw.js')
//         .then((reg) => console.log('service worker registered', reg.scope))
//         .catch((err) => console.log('service worker not registered', err));
// }

// SERVICE WORKER : UNREGISTER
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) registration.unregister();
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
