import { ModalExternalLink } from 'components/elements';
import { sanitize } from 'dompurify';
import { useLanguage, useTheme } from 'hooks';
import { PartnerData, PartnerLocationData } from 'models/types';
import { useCallback, useEffect, useState } from 'react';
import { IconExternal } from 'theme/icons';
import { countDuplicatesInArray } from 'utils';

import styles from './Locations.module.scss';

type LocationsProps = {
    partner: PartnerData;
    content: { EXTERNAL_URL: (partner: string) => string };
};
type Location = { [region: string]: PartnerLocationData[] };

export const Locations: React.FC<LocationsProps> = ({ partner, content }) => {
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();

    const [locations, setLocations] = useState<Location[]>([]);
    const [locationURL, setLocationURL] = useState<string>();
    const [showModal, setShowModal] = useState(false);
    const [externalURL, setExternalURL] = useState<string | undefined>(undefined);

    const mapLocations = useCallback(() => {
        const { locations } = partner;
        if (!locations) return;
        if (typeof locations === 'string') {
            setLocationURL(locations);
            return;
        }

        const regions = locations.map((l) => l.region.en).sort();
        const countedDuplicatedRegions = countDuplicatesInArray(regions);
        const orderedRegions: string[] = Object.entries(countedDuplicatedRegions)
            .sort((a, b) => b[1] - a[1])
            .map((el) => el[0])
            .sort();

        const groupLocations = (region: string) => locations.filter((l) => l.region.en === region);

        const groupedLocations = orderedRegions.map((r) => ({
            [r]: groupLocations(r)
        }));

        setLocations(groupedLocations);
    }, [partner]);

    const getLocations = useCallback((location: Location) => {
        for (const key in location) {
            if (Object.prototype.hasOwnProperty.call(location, key)) {
                return location[key];
            }
        }
    }, []);

    useEffect(() => mapLocations(), [mapLocations]);

    if (!partner.locations) return null;

    return (
        <>
            <section className={`${styles.locations} container`}>
                <h2>{isPT ? 'Locais' : 'Locations'}</h2>

                {locations.map((l) => {
                    const region = Object.keys(l)[0];
                    return (
                        <div key={region} className={styles.region}>
                            <p>{region}</p>
                            {getLocations(l)?.map((local) => (
                                <div
                                    className={[styles['card-location'], styles[activeTheme]].join(
                                        ' '
                                    )}
                                    key={local.id}>
                                    <div className={styles.wrapper}>
                                        <strong>{local.name}</strong>
                                        <span>{local.address}</span>
                                    </div>
                                    <button
                                        className={styles['button-maps']}
                                        onClick={() => {
                                            setExternalURL(local.google_maps);
                                            setShowModal(true);
                                        }}
                                        aria-label="Google Maps">
                                        <img src="/icons/icon-google-maps.png" alt="Google Maps" />
                                    </button>
                                </div>
                            ))}
                        </div>
                    );
                })}

                {locationURL && (
                    <div className={[styles['button-container'], styles[activeTheme]].join(' ')}>
                        <a
                            href={locationURL}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.button}>
                            {isPT ? 'Visitar' : 'Visit'}
                        </a>
                        <div
                            className={[styles['external-warning'], styles[activeTheme]].join(' ')}>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: sanitize(content.EXTERNAL_URL(partner.name))
                                }}
                            />
                            <IconExternal width={20} className={styles.icon} />
                        </div>
                    </div>
                )}
            </section>
            <ModalExternalLink show={showModal} setShow={setShowModal} externalURL={externalURL} />
        </>
    );
};
