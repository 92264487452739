import { useLanguage } from 'hooks';
import { useCallback } from 'react';

import styles from './TagStatus.module.scss';

export type TagStatusProps = {
    status: 'payed' | 'pending' | 'overdue';
    className?: string;
};

export const TagStatus: React.FC<TagStatusProps> = ({ status, className = '' }) => {
    const { isPT } = useLanguage();
    const renderLabel = useCallback(() => {
        switch (status) {
            case 'payed':
                return isPT ? 'pago' : 'paid';
            case 'pending':
                return isPT ? 'pendente' : 'pending';
            case 'overdue':
                return isPT ? 'em atraso' : 'overdue';
            default:
                return 'label';
        }
    }, [isPT, status]);

    return <div className={[styles.tag, styles[status], className].join(' ')}>{renderLabel()}</div>;
};
