import { COLORS } from 'theme/colors';

import { IconShowHideProps } from '../../types';

export const IconShowHide: React.FC<IconShowHideProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 27,
    height = 20,
    display,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 27 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                id="eye"
                d="M2759.55 909.13a14.44 14.44 0 0 1-8.51-2.92 17.49 17.49 0 0 1-4.65-5l-.17-.29V900.51a18.84 18.84 0 0 1 4.33-5.07 15.29 15.29 0 0 1 9.35-3.27 14.38 14.38 0 0 1 8.81 3.16 17.88 17.88 0 0 1 4.33 4.8.89.89 0 0 1 0 1.07 16.31 16.31 0 0 1-8.86 7.23 15.19 15.19 0 0 1-4.58.74Zm.21-15.7a13.24 13.24 0 0 0-4.44.8 15.46 15.46 0 0 0-7.57 6.11.5.5 0 0 0 0 .6 17.77 17.77 0 0 0 3.24 3.57 13.31 13.31 0 0 0 8.55 3.29 13 13 0 0 0 5-1 16 16 0 0 0 7-5.91.48.48 0 0 0 0-.6 16.36 16.36 0 0 0-4.8-4.72 13 13 0 0 0-7-2.16Zm-.07 12.19a5 5 0 1 1 4.91-5 4.94 4.94 0 0 1-4.91 5Zm0-8.69a3.7 3.7 0 0 0 0 7.39 3.7 3.7 0 0 0 0-7.39Z"
                transform="translate(-2746.17 -890.63)"
            />
            {display === 'HIDE' && (
                <path
                    id="slash"
                    d="m2750.75 910.45 18.74-18.74a.63.63 0 1 0-.89-.89l-18.74 18.73a.63.63 0 0 0 .89.9Z"
                    transform="translate(-2746.17 -890.63)"
                />
            )}
        </svg>
    );
};
