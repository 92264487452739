import { FX1 } from 'assets/effects';

import styles from './ImageFrame.module.scss';

export type ImageFrameProps = React.HTMLProps<HTMLDivElement> & {
    image: string;
    image_2x?: string;
    description: string;
    backgroundColor?: string;
};

export const ImageFrame: React.FC<ImageFrameProps> = ({
    image,
    image_2x = image,
    description,
    className = '',
    ...props
}) => (
    <div className={`${styles.container} ${className}`} {...props}>
        <img alt={description} srcSet={`${image_2x} 2x, ${image}`} src={image} />
        <FX1 hasShadow={false} className={styles.svg} />
    </div>
);
