import { UserType } from 'models/types';

type PermissionsData = Readonly<{
    health: {
        appointment: UserType[];
        checkup: UserType[];
        home: UserType[];
    };
    movement: {
        coaches: UserType[];
        exercise: UserType[];
        guru: UserType[];
        home: UserType[];
        onDemand: UserType[];
    };
    nutrition: {
        guru: UserType[];
        home: UserType[];
        nutritionists: UserType[];
        assessment: UserType[];
    };
    articles: {
        article: UserType[];
        list: UserType[];
    };
    general: {
        about: UserType[];
        clientArea: UserType[];
        home: UserType[];
        preferences: UserType[];
        share: UserType[];
        promos: UserType[];
        gamification: UserType[];
    };
}>;

export const permissions: PermissionsData = Object.freeze({
    health: {
        appointment: ['normal', 'guest', 'admin'],
        checkup: ['normal', 'guest', 'admin'],
        home: ['normal', 'guest', 'admin']
    },
    movement: {
        coaches: ['normal', 'guest', 'admin'],
        exercise: ['normal', 'guest', 'admin'],
        guru: ['normal', 'guest', 'admin'],
        home: ['normal', 'guest', 'admin'],
        onDemand: ['guest', 'admin']
    },
    nutrition: {
        guru: ['normal', 'guest', 'admin'],
        home: ['normal', 'guest', 'admin'],
        nutritionists: ['normal', 'guest', 'admin'],
        assessment: ['normal', 'guest', 'admin']
    },
    articles: {
        article: ['normal', 'guest', 'admin'],
        list: ['normal', 'guest', 'admin']
    },
    general: {
        about: ['normal', 'guest', 'admin'],
        clientArea: ['normal', 'guest', 'admin'],
        home: ['normal', 'guest', 'admin'],
        preferences: ['normal', 'guest', 'admin'],
        share: ['normal', 'guest', 'admin'],
        promos: ['normal', 'guest', 'admin'],
        gamification: ['normal', 'guest', 'admin']
    }
});
