import { NavButton } from 'components/elements';
import { permissions } from 'config';
import { PREFERENCES } from 'content';
import { useAuth, useLanguage } from 'hooks';
import { useEffect } from 'react';

import styles from './HomeMenu.module.scss';

export const HomeMenu: React.FC = () => {
    const { isPT } = useLanguage();
    const { validateUserAccessToArea } = useAuth();

    const CONTENT = isPT ? PREFERENCES.PT.MENU : PREFERENCES.EN.MENU;

    useEffect(
        () => validateUserAccessToArea(permissions.general.preferences),
        [validateUserAccessToArea]
    );

    return (
        <section className="container">
            <h1>{CONTENT.TITLE}</h1>
            <div className={styles['button-list']}>
                <NavButton to="nome">{CONTENT.NAME}</NavButton>
                <NavButton to="password">{CONTENT.PASSWORD}</NavButton>
                <NavButton to="foto-perfil">{CONTENT.PROFILE_PHOTO}</NavButton>
                <NavButton to="idioma">{CONTENT.LANGUAGE}</NavButton>
                <NavButton to="tema">{CONTENT.THEME}</NavButton>
            </div>
        </section>
    );
};
