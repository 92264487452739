export const MOVEMENT_ON_DEMAND_LIST = {
    PT: {
        PRE_TITLE: 'aulas on-demand',
        TITLE: 'Treine Online',
        SEARCH: {
            DESCRIPTION: 'As melhores aulas on-demand para si',
            PLACEHOLDER: 'Pesquisar aulas'
        },
        HIGHLIGHTS: {
            TITLE: 'Destaques do dia'
        },
        CATEGORIES: {
            TITLE: 'Procurar classes'
        },
        LOADING: 'a carregar...',
        FILTER: {
            LABEL: 'Filtrar'
        }
    },
    EN: {
        PRE_TITLE: 'on-demand classes',
        TITLE: 'Train Online',
        SEARCH: {
            DESCRIPTION: 'The best on-demand classes for you',
            PLACEHOLDER: 'Search classes'
        },
        HIGHLIGHTS: {
            TITLE: 'Daily highlights'
        },
        CATEGORIES: {
            TITLE: 'Browse Classes'
        },
        LOADING: 'loading...',
        FILTER: {
            LABEL: 'Filter'
        }
    }
};
