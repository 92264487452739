import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconX: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                data-name="Path 512"
                d="m2628.34 905.19-8.73-8.74 8.73-8.74a.74.74 0 0 0-1-1l-8.73 8.74-8.74-8.74a.74.74 0 0 0-1 1l8.74 8.74-8.74 8.74a.73.73 0 0 0 0 1 .75.75 0 0 0 1 0l8.74-8.74 8.73 8.74a.74.74 0 0 0 1 0 .73.73 0 0 0 0-1"
                transform="translate(-2608.57 -886.46)"
            />
        </svg>
    );
};
