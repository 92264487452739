import { RefObject, useEffect, useState } from 'react';

type UseOnScreen = (ref: RefObject<HTMLDivElement>) => boolean;

export const useOnScreen: UseOnScreen = (ref) => {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        if (!ref.current) return;
        const observer = new IntersectionObserver(([entry]) =>
            setIntersecting(entry.isIntersecting)
        );
        observer.observe(ref.current);
        // Remove the observer as soon as the component is unmounted
        return () => observer.disconnect();
    }, [ref]);

    return isIntersecting;
};
