import { VideoData } from 'models/video';

import { CardBookingProps } from '../Cards/CardBooking';
import { CardFullProps } from '../Cards/CardFull';
import { CardHalfV1Props } from '../Cards/CardHalf/v1';
import { CardHalfV3Props } from '../Cards/CardHalf/v3';
import { CardProfileProps } from '../Cards/CardProfile';

export type Slides =
    | CardFullProps[]
    | CardHalfV1Props[]
    | CardProfileProps[]
    | CardBookingProps[]
    | CardHalfV3Props[]
    | VideoData[];

export type TypeName =
    | 'card-half'
    | 'card-full'
    | 'card-profile'
    | 'card-booking'
    | 'card-wearables'
    | 'card-videos';

export type CarouselProps = {
    __typename: TypeName;
    slides: Slides;
    showCategory?: boolean;
    slidesPerView?: number;
    className?: string;
};

// TYPE PREDICATES
export const areCardFullSlides = (
    __typename: TypeName,
    slides: Slides
): slides is CardFullProps[] => {
    return __typename === 'card-full';
};

export const areCardHalfSlides = (
    __typename: TypeName,
    slides: Slides
): slides is CardHalfV1Props[] => {
    return __typename === 'card-half';
};

export const areCardFProfileSlides = (
    __typename: TypeName,
    slides: Slides
): slides is CardProfileProps[] => {
    return __typename === 'card-profile';
};

export const areCardBookingSlides = (
    __typename: TypeName,
    slides: Slides
): slides is CardBookingProps[] => {
    return __typename === 'card-booking';
};

export const areCardWearableSlides = (
    __typename: TypeName,
    slides: Slides
): slides is CardHalfV3Props[] => {
    return __typename === 'card-wearables';
};

export const areCardVideosSlides = (
    __typename: TypeName,
    slides: Slides
): slides is VideoData[] => {
    return __typename === 'card-videos';
};
