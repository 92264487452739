import { AreaType } from 'models/types';
import React, { useCallback } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import {
    IconArticleV1,
    IconAvatar,
    IconFacebook,
    IconHealthV1,
    IconHomeV1,
    IconInstagram,
    IconMail,
    IconMovementV1,
    IconNutritionV1,
    IconPhone,
    IconPlus,
    IconSearch,
    IconShare
} from 'theme/icons';

import styles from './Link.module.scss';

export type LinkProps = {
    label: string;
    type: 'button' | 'link' | 'link_block';
    size: 'md' | 'xs';
    icon?:
        | 'none'
        | 'phone'
        | 'facebook'
        | 'instagram'
        | 'mail'
        | 'plus'
        | 'share'
        | 'search'
        | 'avatar'
        | 'health'
        | 'movement'
        | 'home'
        | 'nutrition'
        | 'article';
    iconPosition?: 'LEFT' | 'RIGHT'; // requires 'icon' prop
    area?: AreaType | 'default'; // requires style: 'BUTTON'
    fullWidth?: boolean; // requires style: 'BUTTON'
    disabled?: boolean;
} & RouterLinkProps;

const DIMENSIONS = { width: 16, height: 16 };

export const Link: React.FC<LinkProps> = ({
    label,
    size,
    type,
    area,
    icon = 'none',
    iconPosition = 'left',
    fullWidth = false,
    className = '',
    disabled = false,
    ...props
}) => {
    const renderIcon = useCallback(() => {
        switch (icon) {
            case 'phone':
                return <IconPhone {...DIMENSIONS} />;
            case 'facebook':
                return <IconFacebook {...DIMENSIONS} />;
            case 'instagram':
                return <IconInstagram {...DIMENSIONS} />;
            case 'mail':
                return <IconMail {...DIMENSIONS} />;
            case 'plus':
                return <IconPlus {...DIMENSIONS} />;
            case 'share':
                return <IconShare {...DIMENSIONS} />;
            case 'search':
                return <IconSearch {...DIMENSIONS} />;
            case 'avatar':
                return <IconAvatar {...DIMENSIONS} />;
            case 'home':
                return <IconHomeV1 {...DIMENSIONS} variation="FILL" colorType="SOLID" />;
            case 'health':
                return <IconHealthV1 {...DIMENSIONS} variation="FILL" colorType="SOLID" />;
            case 'movement':
                return <IconMovementV1 width={18} height={18} variation="FILL" colorType="SOLID" />;
            case 'nutrition':
                return <IconNutritionV1 {...DIMENSIONS} variation="FILL" colorType="SOLID" />;
            case 'article':
                return <IconArticleV1 {...DIMENSIONS} variation="OUTLINE" colorType="SOLID" />;

            default:
                return null;
        }
    }, [icon]);

    return (
        <RouterLink
            className={[
                styles[type],
                styles[size],
                area && styles[area],
                fullWidth ? styles.full : '',
                iconPosition == 'RIGHT' ? styles.reverse : '',
                disabled ? styles.disabled : '',
                className
            ].join(' ')}
            {...props}>
            <span>
                {renderIcon()}
                {label}
            </span>
        </RouterLink>
    );
};
