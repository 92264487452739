import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconAvatar: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 24,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                d="M2798.46 927.7a6.1 6.1 0 1 0-6.18-6.1 6.14 6.14 0 0 0 6.18 6.1Zm0 0a13 13 0 0 0-12 7.81h24a13 13 0 0 0-12-7.81Z"
                transform="translate(-2786.46 -915.51)"
            />
        </svg>
    );
};
