type StringToSlugParams = (str: string) => string;

export const stringToSlug: StringToSlugParams = (str) => {
    str = str.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();

    const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to = 'aaaaeeeeiiiioooouuuunc------';
    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');

    return str;
};
