import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconDots: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 5,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 5"
            width={width}
            height={height}
            style={{ fill: color, fillRule: 'evenodd' }}
            {...props}>
            <path
                d="M2636.52 864.47a2.5 2.5 0 1 0-2.4-2.5 2.46 2.46 0 0 0 2.4 2.5Zm8 0a2.5 2.5 0 1 0-2.4-2.5 2.46 2.46 0 0 0 2.4 2.5Zm9.6-2.5a2.4 2.4 0 1 1-2.4-2.5 2.45 2.45 0 0 1 2.4 2.5Z"
                transform="translate(-2634.12 -859.47)"
            />
        </svg>
    );
};
