import { ServicesData } from 'models/types';

// import * as IMAGES from './images';

export const services: readonly ServicesData[] = Object.freeze([
    // HEALTH
    {
        id: '15e4da2e-d165-4c2e-a2e2-46ce49925f2b',
        area: 'health',
        is_active: true,
        link: {
            slug: '/saude',
            label: {
                EN: 'Home',
                PT: 'Início'
            }
        },
        card: {
            show: false,
            home_highlight: false,
            title: null,
            description: null,
            background_image: null,
            background_image_2x: null
        },
        permissions: ['normal', 'guest', 'admin']
    },
    // {
    //     id: 'a592ec9b-e8bb-43d2-9fc5-9ce24f516078',
    //     area: 'health',
    //     is_active: false,
    //     link: {
    //         slug: '/saude/cartao',
    //         label: {
    //             PT: 'Cartão virtual',
    //             EN: 'Virtual Card'
    //         }
    //     },
    //     card: {
    //         show: true,
    //         home_highlight: true,
    //         title: 'Use o seu cartão de cliente',
    //         description: 'Use o seu cartão de cliente',
    //         background_image: IMAGES.HEALTH_VIRTUAL_CARD,
    //         background_image_2x: IMAGES.HEALTH_VIRTUAL_CARD_2X,
    //         textStyle: 'dark'
    //     },
    //      permissions: ['normal', 'guest', 'admin']
    // },
    {
        id: '266152e9-4604-437c-a52b-35e79b2a0c20',
        area: 'health',
        is_active: true,
        link: {
            slug: '/saude/consulta',
            label: {
                PT: 'Marcar consulta',
                EN: 'Make an appointment'
            }
        },
        card: {
            show: true,
            home_highlight: true,
            title: 'Marque uma consulta',
            description: 'Uma abrangente rede de médicos está à sua espera',
            background_image: 'IMAGES.HEALTH_APPOINTMENT',
            background_image_2x: 'IMAGES.HEALTH_APPOINTMENT_2X'
        },
        permissions: ['normal', 'guest', 'admin']
    },
    {
        id: 'd14ed24f-fb0b-41f7-8986-bddc38993d77',
        area: 'health',
        is_active: true,
        link: {
            slug: '/saude/check-up',
            label: {
                PT: 'Faça um check-up',
                EN: 'Get a check-up'
            }
        },
        card: {
            show: true,
            home_highlight: false,
            title: 'Faça um check-up',
            description: 'Monitorize os seus sinais vitais e a condição do seu corpo',
            background_image: 'IMAGES.HEALTH_CHECK_UP',
            background_image_2x: 'IMAGES.HEALTH_CHECK_UP_2X'
        },
        permissions: ['normal', 'guest', 'admin']
    },
    // {
    //     id: 'ede08166-825b-43dc-ab79-1029640d5b73',
    //     area: 'health',
    //     is_active: false,
    //     link: {
    //         slug: '/saude/sintomas',
    //         label: {
    //             PT: 'Avaliar sintomas',
    //             EN: 'Evaluate symptoms'
    //         }
    //     },
    //     card: {
    //         show: true,
    //         home_highlight: false,
    //         title: 'Avalie os seus sintomas',
    //         description: 'Desconfia de algo? Comece com um auto-diagnóstico.',
    //         background_image: 'IMAGES.HEALTH_SYMPTOMS_TEST',
    //         background_image_2x: 'IMAGES.HEALTH_SYMPTOMS_TEST_2X'
    //     },
    //      permissions: ['normal', 'guest', 'admin']
    // },
    // MOVEMENT
    {
        id: 'bc6dbdd0-a75d-4925-af38-faa386e24f52',
        area: 'movement',
        is_active: true,
        link: {
            slug: '/movimento',
            label: {
                PT: 'Início',
                EN: 'Home'
            }
        },
        card: {
            show: false,
            home_highlight: false,
            title: null,
            description: null,
            background_image: null,
            background_image_2x: null
        },
        permissions: ['normal', 'guest', 'admin']
    },
    {
        id: '996de4aa-8fba-484b-8cd9-07820de8b835',
        area: 'movement',
        is_active: true,
        link: {
            slug: '/movimento/coaches',
            label: {
                PT: 'Agendar um/a sport coach',
                EN: 'Schedule a sport coach'
            }
        },
        card: {
            show: true,
            home_highlight: true,
            title: 'Marque uma sessão de Treino',
            description: 'Melhore a sua performance com um treino personalizado',
            background_image: 'IMAGES.MOVEMENT_TRAINERS_BOOKING',
            background_image_2x: 'IMAGES.MOVEMENT_TRAINERS_BOOKING_2X',
            textStyle: 'light'
        },
        permissions: ['normal', 'guest', 'admin']
    },
    // {
    //     id: '47fce6a6-2beb-4c1a-a5a2-23f9e33c78c1',
    //     area: 'movement',
    //     is_active: false,
    //     link: {
    //         slug: '/movimento/aulas/direto',
    //         label: {
    //             PT: 'Aulas em direto',
    //             EN: 'Live Classes'
    //         }
    //     },
    //     card: {
    //         show: true,
    //         home_highlight: false,
    //         title: 'Participe numa aula em direto',
    //         description: 'Treine com o/a instrutor/a do outro lado do ecrã',
    //         background_image: 'IMAGES.MOVEMENT_LIVE_CLASSES',
    //         background_image_2x: 'IMAGES.MOVEMENT_LIVE_CLASSES_2X',
    //         textStyle: 'dark'
    //     },
    //      permissions: ['normal', 'guest', 'admin']
    // },
    {
        id: 'd2002bd2-bb11-4781-852c-e5c16b452e58',
        area: 'movement',
        is_active: true,
        link: {
            slug: '/movimento/videos',
            label: {
                PT: 'Aulas on-demand',
                EN: 'On-demand classes'
            }
        },
        card: {
            show: false,
            home_highlight: true,
            title: 'Treine com aulas on-demand',
            description: 'Onde estiver, basta escolher um vídeo e começar a treinar',
            background_image: 'IMAGES.MOVEMENT_ON_DEMAND_CLASSES',
            background_image_2x: 'IMAGES.MOVEMENT_ON_DEMAND_CLASSES_2X',
            textStyle: 'dark'
        },
        permissions: ['guest', 'admin']
    },
    {
        id: '2e06f429-81a4-47c8-8791-697a7b6b663d',
        area: 'movement',
        is_active: true,
        link: {
            slug: '/movimento/treino',
            label: {
                PT: 'Treino',
                EN: 'Workout'
            }
        },
        card: {
            show: true,
            home_highlight: false,
            title: 'Treine e registe o seu movimento',
            description: 'Faça check-in no seu clube e registe treinos',
            background_image: 'IMAGES.MOVEMENT_CLUB_USAGE',
            background_image_2x: 'IMAGES.MOVEMENT_CLUB_USAGE_2X'
        },
        permissions: ['normal', 'guest', 'admin']
    },
    {
        id: '75899be9-210f-4d41-b3a3-d45b8c549b56',
        area: 'movement',
        is_active: true,
        link: {
            slug: '/movimento/guru',
            label: {
                PT: 'Pergunte ao Fit Guru',
                EN: 'Ask the Fit Guru'
            }
        },
        card: {
            show: true,
            home_highlight: false,
            title: 'Dúvidas? Pergunte ao Fit Guru',
            description: 'Uma equipa de experts pronta para responder.',
            background_image: 'IMAGES.MOVEMENT_FIT_GURU',
            background_image_2x: 'IMAGES.MOVEMENT_FIT_GURU_2X'
        },
        permissions: ['normal', 'guest', 'admin']
    },
    // NUTRITION
    {
        id: '0fdc0181-d60e-425b-9606-17cde05a04cc',
        area: 'nutrition',
        is_active: true,
        link: {
            slug: '/nutricao',
            label: {
                PT: 'Início',
                EN: 'Home'
            }
        },
        card: {
            show: false,
            home_highlight: false,
            title: null,
            description: null,
            background_image: null,
            background_image_2x: null
        },
        permissions: ['normal', 'guest', 'admin']
    },
    {
        id: '99b3a953-77b5-4187-bb0c-f7ef00628599',
        area: 'nutrition',
        is_active: true,
        link: {
            slug: '/nutricao/consultas',
            label: {
                PT: 'Marcar consulta',
                EN: 'Make appointment'
            }
        },
        card: {
            show: true,
            home_highlight: true,
            title: 'Marque uma consulta',
            description: 'Personalize a sua dieta com a ajuda de um/a nutricionista',
            background_image: 'IMAGES.NUTRITION_APPOINTMENT',
            background_image_2x: 'IMAGES.NUTRITION_APPOINTMENT_2X',
            textStyle: 'dark'
        },
        permissions: ['normal', 'guest', 'admin']
    },
    // {
    //     id: 'a46e6ab1-9a74-41e1-aa23-d925b87a8116',
    //     area: 'nutrition',
    //     is_active: false,
    //     link: {
    //         slug: '/nutricao/plano',
    //         label: {
    //             PT: 'Aceder ao plano',
    //             EN: 'Access to plan'
    //         }
    //     },
    //     card: {
    //         show: true,
    //         home_highlight: false,
    //         title: 'Aceda ao seu plano',
    //         description: 'O guia diário para alcançar a sua silhueta perfeita.',
    //         background_image: 'IMAGES.NUTRITION_PLAN',
    //         background_image_2x: 'IMAGES.NUTRITION_PLAN_2X'
    //     },
    //      permissions: ['normal', 'guest', 'admin']
    // },
    {
        id: '6ac773c9-eae5-4590-aeb3-db5360d4c60f',
        area: 'nutrition',
        is_active: true,
        link: {
            slug: '/nutricao/guru',
            label: {
                PT: 'Pergunte ao Nutri Guru',
                EN: 'Ask the Nutri Guru'
            }
        },
        card: {
            show: true,
            home_highlight: true,
            title: 'Dúvidas? Pergunte ao Guru',
            description: 'Uma equipa de experts pronta para responder.',
            background_image: 'IMAGES.NUTRITION_GURU',
            background_image_2x: 'IMAGES.NUTRITION_GURU_2X'
        },
        permissions: ['normal', 'guest', 'admin']
    },
    {
        id: '90689bc5-c282-4416-8e0e-55938f362801',
        area: 'nutrition',
        is_active: true,
        link: {
            slug: '/nutricao/avaliacao',
            label: {
                PT: 'Avaliação Nutricional',
                EN: 'Nutritional Assessment'
            }
        },
        card: {
            show: false,
            home_highlight: false,
            title: 'A (r)evolução na sua alimentação começa aqui',
            description:
                'Responda a um curto questionário sobre o seu estilo de vida e ganhe uma oferta Zumub',
            background_image: '',
            background_image_2x: ''
        },
        permissions: ['normal', 'guest', 'admin']
    }
]);
