import { Avatar, ImageCropDialog, InputFile } from 'components/elements';
import { getRotatedImage } from 'components/elements/ImageCropDialog/canvasUtils';
import { permissions } from 'config';
import { PREFERENCES } from 'content';
import { getOrientation } from 'get-orientation/browser';
import { useApi, useAuth, useLanguage, useTheme } from 'hooks';
import { UserData } from 'models/types';
import { useEffect, useState } from 'react';
import { Point } from 'react-easy-crop/types';

import styles from '../Preferences.module.scss';

export const EditProfilePhoto: React.FC = () => {
    const { isPT } = useLanguage();
    const { activeTheme } = useTheme();
    const { user, updateUser, validateUserAccessToArea } = useAuth();
    const { postUserAvatar } = useApi();

    const CONTENT = isPT ? PREFERENCES.PT.PROFILE_PHOTO : PREFERENCES.EN.PROFILE_PHOTO;

    const [userAvatar, setUserAvatar] = useState<string | undefined>(undefined);
    const [selectedUserAvatar, setSelectedUserAvatar] = useState<string | undefined>(undefined);

    useEffect(
        () => validateUserAccessToArea(permissions.general.preferences),
        [validateUserAccessToArea]
    );

    useEffect(() => setUserAvatar(user?.image ?? undefined), [user]);

    const ORIENTATION_TO_ANGLE: { [key: string]: number } = {
        '3': 180,
        '6': 90,
        '8': -90
    };

    const setCroppedImageFor = (
        id: number,
        crop: Point,
        zoom: number,
        aspect: number,
        croppedImageBase64: string
    ) => {
        // console.log({ id, crop, zoom, aspect, croppedImageBase64 });
        const postImage = async () => {
            try {
                const avatar = await postUserAvatar(croppedImageBase64);
                const storedUser = localStorage.getItem('@hi:user');
                if (!storedUser) return;
                const parsedUser: UserData = JSON.parse(storedUser);
                parsedUser.image = avatar;

                updateUser(parsedUser);
            } catch (error) {
                // TO-DO
                console.log({ error });
            }
        };

        postImage();
        setUserAvatar(croppedImageBase64);
        setSelectedUserAvatar(undefined);
    };

    const readFile = (file: Blob) =>
        new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl = (await readFile(file)) as string;

            // apply rotation if needed
            const orientation = await getOrientation(file);
            const rotation = ORIENTATION_TO_ANGLE[orientation];
            if (rotation) imageDataUrl = (await getRotatedImage(imageDataUrl, rotation)) as string;

            setSelectedUserAvatar(imageDataUrl);
        }
    };

    const onCancel = () => setSelectedUserAvatar(undefined);

    return (
        <section className={`${styles.section} container`}>
            <h1>{CONTENT.INTRO.TITLE}</h1>
            <p>{CONTENT.INTRO.DESCRIPTION}</p>
            <div className={`${styles.card} ${styles[activeTheme]}`}>
                <Avatar
                    source={userAvatar}
                    width={120}
                    height={120}
                    alt={user?.name.short || user?.name.first || 'user avatar'}
                    className={styles.avatar}
                />
                <InputFile
                    inputLabel={CONTENT.BUTTON_LABEL}
                    onChange={onFileChange}
                    accept="image/*"
                    className={`${styles.button} ${styles.center}`}
                />

                {selectedUserAvatar && (
                    <ImageCropDialog
                        id={1}
                        imageUrl={selectedUserAvatar}
                        cropInit={{ x: 0, y: 0 }}
                        zoomInit={1}
                        onCancel={onCancel}
                        setCroppedImageFor={setCroppedImageFor}
                    />
                )}
            </div>
        </section>
    );
};
