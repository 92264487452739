import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconAlert: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.27712 2.61304L0.348902 14.9683C0.120387 15.376 8.05078e-07 15.8376 8.05078e-07 16.3077C8.05078e-07 17.7945 1.17846 19 2.63192 19H17.3679C18.8215 19 20 17.7945 20 16.3077C20 15.8377 19.8798 15.376 19.6511 14.9683L12.7229 2.61304C12.1632 1.61527 11.1247 1 10.0001 1C8.87541 1 7.83686 1.61527 7.27722 2.61304H7.27712ZM8.72721 5.97301C8.72721 5.25401 9.29699 4.67124 9.99995 4.67124C10.7029 4.67124 11.2727 5.25401 11.2727 5.97301V10.8482C11.2727 11.5671 10.7029 12.1499 9.99995 12.1499C9.29699 12.1499 8.72721 11.5671 8.72721 10.8482V5.97301ZM8.72721 14.5382C8.72721 13.8192 9.29699 13.2364 9.99995 13.2364C10.7029 13.2364 11.2727 13.8192 11.2727 14.5382V14.9799C11.2727 15.6991 10.7029 16.2818 9.99995 16.2818C9.29699 16.2818 8.72721 15.6991 8.72721 14.9799V14.5382Z"
            />
        </svg>
    );
};
