import * as IMAGES from 'constants/images';

export type WearableData = {
    id: string;
    name: string;
    logo: string;
    slug: string;
};

export const wearables: readonly WearableData[] = Object.freeze([
    {
        id: '66e1bcf4-67c1-4b3a-b9ff-aeeb0b11b755',
        name: 'Apple Fitness',
        logo: IMAGES.LOGO_APPLE_FITNESS,
        slug: '/movimento/treino'
    },
    {
        id: 'b9d93fd5-b553-47d8-b29f-6b40e0bff29d',
        name: 'Google Fit',
        logo: IMAGES.LOGO_GOOGLE_FIT,
        slug: '/movimento/treino'
    }
]);
