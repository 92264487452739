import styles from './Intro.module.scss';

type IntroProps = {
    content: {
        TITLE: string;
        DESCRIPTION: string;
    };
};

export const Intro: React.FC<IntroProps> = ({ content }) => {
    return (
        <section className={`${styles.intro} container`}>
            <h1>{content.TITLE}</h1>
            <p>{content.DESCRIPTION}</p>
        </section>
    );
};
