export const HEALTH_CHECKUP = {
    PT: {
        CATEGORY: 'Saúde',
        TITLE: 'Check-up Online',
        FIRST_SCREEN: {
            TITLE: 'Agendar Check Up',
            LEAD: 'A realização regular de exames médicos é essencial para a identificação e tratamento precoce de doenças, bem como a melhoria de qualidade de vida.',
            BUTTON: 'Agendar',
            NOTE: 'Período de carência: 90 dias',
            SPECS: [
                {
                    ID: 1,
                    TYPE: 'h2',
                    TEXT: 'Detalhes do Serviço'
                },
                {
                    ID: 2,
                    TYPE: 'p',
                    TEXT: 'A cobertura de check-up permite ao Cliente ter acesso a serviços médicos da maior utilidade e inclui os exames clinicamente úteis para a sua faixa etária.'
                },
                {
                    ID: 3,
                    TYPE: 'h2',
                    TEXT: 'Passos a seguir para a utilização desta cobertura'
                },
                {
                    ID: 4,
                    TYPE: 'ol',
                    ITEMS: [
                        {
                            ID: 1,
                            TEXT: 'Preencha o formulário online e nas 24 horas úteis seguintes receberá um contacto do prestador para realizar a marcação do seu check-up'
                        },
                        {
                            ID: 2,
                            TEXT: 'Após a realização dos exames protocolizados, o prestador de serviços agendará a consulta online'
                        },
                        {
                            ID: 3,
                            TEXT: 'Antes da realização da consulta online o prestador enviará o link de acesso à mesma'
                        }
                    ]
                },
                {
                    ID: 5,
                    TYPE: 'note',
                    TEXT: 'Nota: Em todo o processo é necessário que tenha acesso aos dados do seu cartão de saúde'
                },
                {
                    ID: 6,
                    TYPE: 'highlight',
                    TEXT: 'Consulte as condições contratadas no seu seguro de saúde'
                }
            ]
        },
        FORM: {
            TITLE: 'Preencha os campos',
            LABELS: {
                PHONE: 'telefone',
                PARISH: 'freguesia',
                SUBMIT: 'solicitar'
            },
            USER_DATA: {
                TITLE: 'Dados adicionados ao pedido',
                NAME: 'Nome',
                DATE_OF_BIRTH: 'Data de nascimento',
                CLIENT_NUMBER: 'N.º Cliente',
                PLAN: 'Plano'
            },
            VALIDATION_MESSAGES: {
                PARISH: 'Freguesia inválida. Escolha uma da lista.'
            }
        },
        MESSAGE: {
            SUCCESS: {
                TITLE: 'Obrigado',
                DESCRIPTION:
                    'Nas próximas 24 horas será contactado/a para proceder à marcação do seu checkup',
                BUTTON: 'voltar'
            }
        }
    },
    EN: {
        CATEGORY: 'Health',
        TITLE: 'Check-up Online',
        FIRST_SCREEN: {
            TITLE: 'Schedule a Check Up',
            LEAD: 'Regular medical check-ups are essential for the early identification and treatment of diseases, as well as improving quality of life.',
            BUTTON: 'Schedule',
            NOTE: 'Waiting period: 90 days',
            SPECS: [
                {
                    ID: 1,
                    TYPE: 'h2',
                    TEXT: 'Service Details'
                },
                {
                    ID: 2,
                    TYPE: 'p',
                    TEXT: 'The check-up cover allows the Customer access to the most useful medical services and includes clinically useful exams for their age group.'
                },
                {
                    ID: 3,
                    TYPE: 'h2',
                    TEXT: 'Steps to follow to use this cover'
                },
                {
                    ID: 4,
                    TYPE: 'ol',
                    ITEMS: [
                        {
                            ID: 1,
                            TEXT: 'Fill in the online form and within 24 working hours you will be contacted by the provider to book your check-up'
                        },
                        {
                            ID: 2,
                            TEXT: 'Once the registered exams have been carried out, the service provider will schedule the consultation online'
                        },
                        {
                            ID: 3,
                            TEXT: 'Before the online consultation, the provider will send the access link to the consultation'
                        }
                    ]
                },
                {
                    ID: 5,
                    TYPE: 'note',
                    TEXT: 'Note: Throughout the process it is necessary that you have access to your health card details'
                },
                {
                    ID: 6,
                    TYPE: 'highlight',
                    TEXT: 'Check the conditions of your health insurance policy'
                }
            ]
        },
        FORM: {
            TITLE: 'Fill in the fields',
            LABELS: {
                PHONE: 'phone',
                PARISH: 'parish',
                SUBMIT: 'request'
            },
            USER_DATA: {
                TITLE: 'Data added to the order',
                NAME: 'Name',
                DATE_OF_BIRTH: 'Date of birth',
                CLIENT_NUMBER: 'Client No.',
                PLAN: 'Plan'
            },
            VALIDATION_MESSAGES: {
                PARISH: 'Invalid parish. Choose one from the list.'
            }
        },
        MESSAGE: {
            SUCCESS: {
                TITLE: 'Thank you',
                DESCRIPTION: 'You will be contacted within the next 24 hours to book your checkup',
                BUTTON: 'back'
            }
        }
    }
};
