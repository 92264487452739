// /**
//  * DEV MODE
//  * @param ?dev=true
//  */
type TriggerDevMode = (search: string) => boolean;

export const triggerDevMode: TriggerDevMode = (search) => {
    const devIndex = search.indexOf('dev');
    if (devIndex < 0) return false;
    let devParam = search.slice(devIndex);

    const ampersandIndex = devParam.indexOf('&');
    if (ampersandIndex >= 0) devParam = devParam.slice(0, ampersandIndex);

    return devParam.split('=')[1] === 'true';
};

export const DEV = process.env.NODE_ENV === 'development';
