import { useScroll, useTheme } from 'hooks';
import { ButtonHTMLAttributes, DetailedHTMLProps, useCallback, useEffect, useState } from 'react';
import { IconArrowUp } from 'theme/icons';

import styles from './ScrollTopButton.module.scss';

export type ScrollTopButtonProps = {
    top: number;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const ScrollTopButton: React.FC<ScrollTopButtonProps> = ({ top, ...props }) => {
    const { activeTheme } = useTheme();
    const { scrollTop } = useScroll();
    const [showButton, setShowButton] = useState(false);

    const scroll = useCallback(() => scrollTop('smooth'), [scrollTop]);

    useEffect(() => setShowButton(top < 0), [top]);

    return (
        <button
            onClick={scroll}
            className={`${styles.button} ${showButton ? styles.show : ''} ${styles[activeTheme]}`}
            aria-label="back to top"
            {...props}>
            <IconArrowUp className={styles.icon} />
        </button>
    );
};
