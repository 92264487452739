import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconMoon: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        width={width}
        height={height}
        fill={color}
        {...props}>
        <path d="M18.72 11.22a8.36 8.36 0 0 1-2.11.27 8.06 8.06 0 0 1-5.74-2.37 8.17 8.17 0 0 1-2.1-7.84A1 1 0 0 0 8.05.03a1.14 1.14 0 0 0-.52 0 10.15 10.15 0 0 0-4.56 17 10.14 10.14 0 0 0 17-4.56 1 1 0 0 0-.72-1.24.92.92 0 0 0-.53-.01Zm-2.82 4.37A8.13 8.13 0 1 1 4.41 4.09a7.77 7.77 0 0 1 2.09-1.5A10.14 10.14 0 0 0 17.41 13.5a8.2 8.2 0 0 1-1.51 2.09Z" />
    </svg>
);
