import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconPlay: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 16,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                d="m2728.49 929 13.66 8.82a1.17 1.17 0 0 1 .35 1.62 1.2 1.2 0 0 1-.35.36l-13.66 8.82a1.16 1.16 0 0 1-1.61-.35 1.11 1.11 0 0 1-.19-.63V930a1.17 1.17 0 0 1 1.17-1.17 1.11 1.11 0 0 1 .63.17Z"
                transform="translate(-2726.69 -928.82)"
            />
        </svg>
    );
};
