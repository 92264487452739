import {
    CardArticleV2,
    CardBooking,
    CardFull,
    CardHalfV1,
    CardHalfV3,
    CardProfile
} from 'components/elements';
import { useTheme } from 'hooks';
import { A11y, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

import styles from './Carousel.module.scss';
import {
    areCardBookingSlides,
    areCardFProfileSlides,
    areCardFullSlides,
    areCardHalfSlides,
    areCardVideosSlides,
    areCardWearableSlides,
    CarouselProps
} from './types';

export const Carousel: React.FC<CarouselProps> = ({
    slides,
    __typename,
    slidesPerView = 1,
    showCategory = false,
    className = '',
    ...props
}) => {
    const { activeTheme } = useTheme();

    return (
        <Swiper
            modules={[Pagination, A11y]}
            pagination={{
                clickable: true,
                bulletClass: `${styles.bullet} ${styles[activeTheme]}`,
                bulletActiveClass: styles.bulletActive
            }}
            spaceBetween={10}
            slidesPerView={slidesPerView}
            className={`${styles.slider} ${className}`}
            {...props}>
            {__typename === 'card-full' &&
                areCardFullSlides(__typename, slides) &&
                slides?.map(({ id, ...props }, index) => (
                    <SwiperSlide
                        key={id?.toString() || index.toString()}
                        className={styles.cardFullContainer}>
                        <CardFull {...props} />
                    </SwiperSlide>
                ))}
            {__typename === 'card-half' &&
                areCardHalfSlides(__typename, slides) &&
                slides?.map(({ id, ...props }, index) => (
                    <SwiperSlide key={id?.toString() || index.toString()}>
                        <CardHalfV1 {...props} />
                    </SwiperSlide>
                ))}
            {__typename === 'card-profile' &&
                areCardFProfileSlides(__typename, slides) &&
                slides?.map(({ id, ...props }, index) => (
                    <SwiperSlide key={id?.toString() || index.toString()}>
                        <CardProfile {...props} />
                    </SwiperSlide>
                ))}
            {__typename === 'card-booking' &&
                areCardBookingSlides(__typename, slides) &&
                slides?.map(({ id, ...props }, index) => (
                    <SwiperSlide key={id?.toString() || index.toString()}>
                        <CardBooking {...props} />
                    </SwiperSlide>
                ))}
            {__typename === 'card-wearables' &&
                areCardWearableSlides(__typename, slides) &&
                slides?.map(({ id, name, logo }, index) => (
                    <SwiperSlide key={id?.toString() || index.toString()}>
                        <CardHalfV3 name={name} logo={logo} />
                    </SwiperSlide>
                ))}
            {__typename === 'card-videos' &&
                areCardVideosSlides(__typename, slides) &&
                slides?.length > 0 &&
                slides.map((c) => (
                    <SwiperSlide key={c.details.tag.toString()}>
                        <CardArticleV2
                            to={`/movimento/videos/${c.details.tag}`}
                            image={c.video.imageLink}
                            title={c.details.name}
                            description={c.details.description}
                            showCategory={showCategory}
                            category={c.details.category}
                            duration={c.video.durationInSeconds}
                            level={c.specs.level}
                            date={c.video.publishedDate}
                        />
                    </SwiperSlide>
                ))}
        </Swiper>
    );
};
