import { GraphProgress } from 'components/elements';
import { useAuth } from 'hooks';

import styles from './UserProgress.module.scss';

type UserProgressProps = {
    title: string;
};

export const UserProgress: React.FC<UserProgressProps> = ({ title }) => {
    const { user } = useAuth();

    if (!user) return null;

    return (
        <section className={`${styles.progress} container`}>
            <h2>{title}</h2>
            <GraphProgress
                percentage={user.performance.percentage}
                area="nutrition"
                toolTip={user.performance.total_points}
                level={user.performance.level}
                gradient
                className={styles.graph}
            />
        </section>
    );
};
