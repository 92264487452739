import {
    Accordion,
    Button,
    Checkbox,
    Filter,
    Modal,
    NotFoundMessage,
    Search,
    TextArea
} from 'components/elements';
import { permissions, toastOptions } from 'config';
import { META_TAGS, MOVEMENT_FIT_GURU } from 'content';
import { sanitize } from 'dompurify';
import { useFormik } from 'formik';
import { useApi, useAuth, useLanguage, useTheme } from 'hooks';
import { FAQData } from 'models/types';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { COLORS } from 'theme';
import { IconQuestionRounded } from 'theme/icons';
import { countDuplicatesInArray } from 'utils';
import * as Yup from 'yup';

import styles from './Guru.module.scss';

type FormValues = { question: string; gdpr: boolean };

type IntroSectionsProps = {
    content: {
        PRE_TITLE: string;
        TITLE: string;
    };
};

type SearchSectionProps = {
    content: {
        DESCRIPTION: string;
        PLACEHOLDER: string;
    };
    allFAQs: FAQData[];
    setFilteredContent: Dispatch<
        SetStateAction<
            {
                id: string;
                data: string;
            }[]
        >
    >;
    notFound: boolean;
    setNotFound: Dispatch<SetStateAction<boolean>>;
    setResetSearch: Dispatch<SetStateAction<boolean>>;
    resetSearch: boolean;
};

type ListSectionProps = {
    content: {
        TITLE: string;
        FILTER_LABEL: string;
    };
    allFAQs: FAQData[];
    FAQs: { faqs?: FAQData[] };
    setFAQs: Dispatch<
        SetStateAction<{
            faqs?: FAQData[] | undefined;
        }>
    >;
};

const IntroSection: React.FC<IntroSectionsProps> = ({ content }) => {
    return (
        <section className={`${styles.intro} container`}>
            <span className={styles.preTitle}>{content.PRE_TITLE}</span>
            <h1>{content.TITLE}</h1>
        </section>
    );
};

const SearchSection: React.FC<SearchSectionProps> = ({
    content,
    allFAQs,
    setFilteredContent,

    setNotFound,
    setResetSearch,
    resetSearch
}) => {
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();

    return (
        <section className={`${styles.search} container`}>
            <p>{content.DESCRIPTION}</p>
            <Search
                searchContent={allFAQs.map((f) => ({
                    id: f.id,
                    data: isPT
                        ? `${f.label.pt} ${f.description.pt}`
                        : `${f.label.en} ${f.description.en}`
                }))}
                placeholder={content.PLACEHOLDER}
                setFilteredContent={setFilteredContent}
                setNotFound={setNotFound}
                onInputFocus={() => setResetSearch(false)}
                resetSearch={resetSearch}
                theme={activeTheme}
                className={styles.input}
            />
        </section>
    );
};

const ListSection: React.FC<ListSectionProps> = ({ content, allFAQs, FAQs, setFAQs }) => {
    const { isPT } = useLanguage();
    const { activeTheme } = useTheme();

    const [filterLabel, setFilterLabel] = useState(content.FILTER_LABEL);
    const [filterOptions, setFilterOptions] = useState<string[]>([]);

    const [accordionsOpen, setAccordionsOpen] = useState<boolean[]>([]);

    const handleFilter = useCallback(
        (selectedCategory: string) => {
            if (!selectedCategory) {
                setFAQs({ faqs: allFAQs });
                setFilterLabel(content.FILTER_LABEL);
                return;
            }

            const faqs = allFAQs.filter(
                ({ category }) => selectedCategory === (isPT ? category.pt : category.en)
            );

            const label = `${selectedCategory.slice(0, 10)}${
                selectedCategory.length > 10 ? '...' : ''
            }`;

            setFAQs({ faqs });
            setFilterLabel(label);
        },
        [allFAQs, content.FILTER_LABEL, isPT, setFAQs]
    );

    const onAccordionClick = useCallback(
        (index: number) => {
            if (!accordionsOpen) return;
            setAccordionsOpen(accordionsOpen.map((_, i) => i === index));
        },
        [accordionsOpen]
    );

    useEffect(() => setAccordionsOpen(allFAQs.map(() => false)), [allFAQs]);

    useEffect(() => setFilterLabel(content.FILTER_LABEL), [content]);

    useEffect(() => {
        const allCategories = allFAQs.map(({ category }) => (isPT ? category.pt : category.en));

        const countedDuplicatedCategories = countDuplicatesInArray(allCategories.flat().sort());

        /**
         * @see https://medium.com/@gmcharmy/sort-objects-in-javascript-e-c-how-to-get-sorted-values-from-an-object-142a9ae7157c
         */
        const orderedTags: string[] = Object.entries(countedDuplicatedCategories)
            .sort((a, b) => b[1] - a[1])
            .map((el) => el[0]);

        setFilterOptions(orderedTags.sort());
    }, [allFAQs, isPT]);

    if (FAQs.faqs)
        return (
            <section className={`${styles.faqs} container`}>
                <div className={styles.header}>
                    <h2>{content.TITLE}</h2>
                    <Filter
                        label={filterLabel}
                        options={filterOptions}
                        handleFilter={handleFilter}
                    />
                </div>
                <div className={styles.container}>
                    {FAQs.faqs.map((faq, i) => (
                        <Accordion
                            key={faq.id.toString()}
                            buttonTitle={isPT ? faq.label.pt : faq.label.en}
                            isOpen={accordionsOpen[i]}
                            onAccordionClick={() => onAccordionClick(i)}
                            icon="CIRCLE"
                            size="MD"
                            borders={false}
                            className={styles.accordion}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: sanitize(isPT ? faq.description.pt : faq.description.en)
                                }}
                                className={`${styles.answer} ${styles[activeTheme]}`}
                            />
                        </Accordion>
                    ))}
                </div>
            </section>
        );

    return null;
};

const ContactFormSection: React.FC = () => {
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();
    const { postFAQ } = useApi();

    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

    const CONTENT = isPT ? MOVEMENT_FIT_GURU.PT : MOVEMENT_FIT_GURU.EN;

    const [showModal, setShowModal] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [checkboxError, setCheckboxError] = useState(false);
    const [showSentMessage, setShowSentMessage] = useState<'success' | 'error' | undefined>(
        undefined
    );

    const initialValues = { question: '', gdpr: false };

    const validationSchema = Yup.object().shape({
        question: Yup.string().required(CONTENT.VALIDATIONS.QUESTION),
        gdpr: Yup.boolean().required(CONTENT.VALIDATIONS.GDPR)
    });

    const onSubmit = useCallback(
        async ({ question, gdpr }: FormValues, { setSubmitting, resetForm }) => {
            try {
                if (!gdpr) {
                    toast.dark(CONTENT.VALIDATIONS.LEGAL, toastOptions);
                    setCheckboxError(true);
                    return;
                }

                setSubmitting(true);

                await postFAQ('movement', question);
                setSubmitting(false);
                setShowSentMessage('success');
                resetForm();
            } catch ({ message }) {
                setShowSentMessage('error');
            }
        },
        [CONTENT.VALIDATIONS.LEGAL, postFAQ]
    );

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    const handleTextAreaFocus = useCallback(() => {
        if (!textAreaRef.current) return;

        textAreaRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
        });
    }, []);

    const sentMessageClassName = useMemo(
        () => `${styles['sent-message']} ${styles[activeTheme]}`,
        [activeTheme]
    );

    return (
        <>
            <section className={`${styles.formContainer} container`}>
                <div className={styles.header}>
                    <h2>{CONTENT.FORM.TITLE}</h2>
                    <button
                        className={`${styles.modalButton} ${styles[activeTheme]}`}
                        onClick={() => setShowModal(true)}>
                        <IconQuestionRounded />
                    </button>
                </div>

                {!showForm && (
                    <Button
                        label={CONTENT.FORM.BUTTONS.START}
                        size="md"
                        area="movement"
                        className={styles.button}
                        onClick={() => setShowForm(true)}
                    />
                )}

                {showForm && !showSentMessage && (
                    <form className={styles.form} onSubmit={formik.handleSubmit}>
                        <TextArea
                            onFocus={handleTextAreaFocus}
                            label={CONTENT.FORM.TEXTAREA}
                            id="question"
                            {...formik.getFieldProps('question')}
                        />
                        <Checkbox
                            id="gdpr"
                            hasError={checkboxError}
                            {...formik.getFieldProps('gdpr')}>
                            {CONTENT.FORM.GDPR.TEXT[0]}
                            <a
                                href={CONTENT.FORM.GDPR.URL}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`${styles.link} ${styles[activeTheme]}`}>
                                {CONTENT.FORM.GDPR.TEXT[1]}
                            </a>
                        </Checkbox>
                        <Button
                            label={CONTENT.FORM.BUTTONS.SUBMIT}
                            type="submit"
                            size="md"
                            area="movement"
                            className={styles.button}
                        />
                    </form>
                )}

                {showSentMessage && (
                    <div className={sentMessageClassName}>
                        <p>
                            {showSentMessage === 'success'
                                ? CONTENT.FORM.MESSAGE.SUCCESS
                                : CONTENT.FORM.MESSAGE.ERROR}
                        </p>
                        <Button
                            label={CONTENT.FORM.BUTTONS.NEW_QUESTION}
                            size="md"
                            area="movement"
                            className={styles.button}
                            onClick={() => setShowSentMessage(undefined)}
                        />
                    </div>
                )}
            </section>

            <Modal
                show={showModal}
                className={styles.modal}
                header={{ title: CONTENT.MODAL.SUB_TITLE }}
                footer={{ enable: false }}
                close={{ handler: () => setShowModal(false), buttonColor: COLORS.WHITE }}>
                <h2>{CONTENT.MODAL.TITLE}</h2>
                <ol>
                    {CONTENT.MODAL.STEPS.map((s) => (
                        <li key={s.id.toString()}>{s.TEXT}</li>
                    ))}
                </ol>
            </Modal>
        </>
    );
};

export const MovementGuru: React.FC = () => {
    const { validateUserAccessToArea } = useAuth();
    const { getFAQs } = useApi();
    const { isPT } = useLanguage();
    const CONTENT = isPT ? MOVEMENT_FIT_GURU.PT : MOVEMENT_FIT_GURU.EN;

    const [FAQs, setFAQs] = useState<{ faqs?: FAQData[] }>({ faqs: undefined });
    const [allFAQs, setAllFAQs] = useState<FAQData[]>([]);
    const [filteredContent, setFilteredContent] = useState<{ id: string; data: string }[]>([]);
    const [notFound, setNotFound] = useState(false);
    const [resetSearch, setResetSearch] = useState(false);

    const getFAQsData = useCallback(async () => {
        try {
            const faqs = await getFAQs('movement');
            setAllFAQs(faqs);
        } catch (error) {
            // TO-DO
            console.error(error);
        }
    }, [getFAQs]);

    useEffect(
        () => validateUserAccessToArea(permissions.movement.guru),
        [validateUserAccessToArea]
    );

    useEffect(() => {
        getFAQsData();
    }, [getFAQsData]);

    useEffect(() => {
        const faqs =
            filteredContent.length > 0
                ? allFAQs.filter((f) => filteredContent.map((c) => c.id).includes(f.id))
                : allFAQs;

        setFAQs({ faqs });
    }, [allFAQs, filteredContent]);

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? META_TAGS.PRIVATE.MOVEMENT.GURU.PT.TITLE
                        : META_TAGS.PRIVATE.MOVEMENT.GURU.EN.TITLE}
                </title>
            </Helmet>

            <IntroSection content={CONTENT.INTRO} />

            <SearchSection
                content={CONTENT.SEARCH}
                allFAQs={allFAQs}
                setFilteredContent={setFilteredContent}
                notFound={notFound}
                setNotFound={setNotFound}
                setResetSearch={setResetSearch}
                resetSearch={resetSearch}
            />

            {FAQs.faqs && FAQs.faqs.length > 0 && !notFound && (
                <ListSection
                    content={{ TITLE: CONTENT.FAQS.TITLE, FILTER_LABEL: CONTENT.FILTER.LABEL }}
                    allFAQs={allFAQs}
                    FAQs={FAQs}
                    setFAQs={setFAQs}
                />
            )}

            <section className={`${styles.formContainer} ${styles.search} container`}>
                <NotFoundMessage show={notFound} setResetForm={setResetSearch} />
            </section>

            <ContactFormSection />
        </>
    );
};
