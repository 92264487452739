import React, { createContext, useContext, useEffect, useState } from 'react';

type ThemeName = 'light' | 'dark';
type ThemeProps = { name: ThemeName; addToStorage: boolean };

type ThemeContextData = {
    setTheme: ({ name }: ThemeProps) => void;
    darkMode: boolean;
    toggleTheme: () => void;
    activeTheme: ThemeName;
};

const ThemeContext = createContext<ThemeContextData>({} as ThemeContextData);

export const ThemeProvider: React.FC = ({ children }) => {
    const [darkMode, setDarkMode] = useState(false);
    const [activeTheme, setActiveTheme] = useState<ThemeName>('dark');

    const setTheme = ({ name, addToStorage }: ThemeProps) => {
        setDarkMode(name === 'dark');
        setActiveTheme(name);
        document.documentElement.classList.remove('light', 'dark');
        document.documentElement.classList.add(name);
        addToStorage && localStorage.setItem('@hi-theme', name);
    };

    const getTheme = () => {
        const theme = localStorage.getItem('@hi-theme') as ThemeName;
        if (!theme) {
            const darkMode = window.matchMedia('(prefers-color-scheme: dark)');
            const envTheme = darkMode.matches ? 'dark' : 'light';
            setTheme({ name: envTheme, addToStorage: true });
            return;
        }

        setTheme({ name: theme, addToStorage: false });
    };

    const toggleTheme = () =>
        setTheme({
            name: darkMode ? 'light' : 'dark',
            addToStorage: true
        });

    useEffect(getTheme);

    return (
        <ThemeContext.Provider value={{ setTheme, darkMode, activeTheme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme: () => ThemeContextData = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw Error('the hook useTheme must be used inside a ThemeProvider');
    }
    return context;
};
