import { NotFoundMessage } from 'components/elements';

import { useStaff } from '../../useStaff';

export const NotFound: React.FC = () => {
    const { notFound, setResetSearch } = useStaff();

    return (
        <section className="container">
            <NotFoundMessage show={notFound} setResetForm={setResetSearch} />
        </section>
    );
};
