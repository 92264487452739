import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconStar: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 21,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 21 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                d="m2768.64 925.17 2.85 5.77a.5.5 0 0 0 .39.27l6.37.93a.5.5 0 0 1 .43.58.47.47 0 0 1-.14.29l-4.61 4.49a.51.51 0 0 0-.15.45l1.09 6.35a.5.5 0 0 1-.42.58.45.45 0 0 1-.32 0l-5.7-3a.51.51 0 0 0-.48 0l-5.7 3a.51.51 0 0 1-.74-.53l1.09-6.35a.51.51 0 0 0-.15-.45l-4.61-4.49a.52.52 0 0 1 .29-.87l6.37-.93a.5.5 0 0 0 .39-.27l2.85-5.77a.51.51 0 0 1 .69-.22.5.5 0 0 1 .21.17Z"
                transform="translate(-2757.69 -924.89)"
            />
        </svg>
    );
};
