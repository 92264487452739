import { ButtonGroup, CardDocuments, DocumentProps } from 'components/elements';
import { format, isAfter } from 'date-fns';
import ENLocale from 'date-fns/locale/en-GB';
import PTLocale from 'date-fns/locale/pt';
import { useApi, useLanguage, useTheme } from 'hooks';
import { Document, UserDocDataV2 } from 'models/types';
import { useCallback, useEffect, useState } from 'react';
import { HiOutlineXCircle } from 'react-icons/hi';
import { DEV } from 'utils';

import styles from './Documents.module.scss';

type DocumentsProps = {
    content: {
        TITLE: string;
        SECTIONS: {
            INVOICES: {
                BUTTON: string;
            };
            OTHER: {
                BUTTON: string;
            };
        };
    };
};

export const Documents: React.FC<DocumentsProps> = ({ content }) => {
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();
    const { getUserDocuments } = useApi();

    const [userDocs, setUserDocs] = useState<UserDocDataV2>();
    const [userInvoices, setUserInvoices] = useState<DocumentProps[]>([]);
    const [userDocuments, setUserDocuments] = useState<DocumentProps[]>([]);
    const [activeDocuments, setActiveDocuments] = useState<DocumentProps[]>([]);
    const [contentType, setContentType] = useState<'invoice' | 'other'>('invoice');

    const fetchDocs = useCallback(async () => {
        const documents = await getUserDocuments();
        setUserDocs(documents);
    }, [getUserDocuments]);

    const handleDocumentStatus = useCallback(
        (isPaid: boolean, dueDate: string | null): 'payed' | 'pending' | 'overdue' => {
            if (isPaid || !dueDate) return 'payed';
            const now = new Date();
            const due = new Date(dueDate);

            return isAfter(due, now) ? 'pending' : 'overdue';
        },
        []
    );

    const mapUserDocuments = useCallback(
        (documents: Document[] | undefined): DocumentProps[] | undefined => {
            if (!userDocs || !documents) return;

            return documents.map((inv) => ({
                type: 'invoice',
                status: handleDocumentStatus(
                    inv.is_paid ?? true,
                    inv.atm_transaction?.due_date ?? null
                ),
                title: isPT ? inv.title.pt : inv.title.en,
                description: isPT ? inv.description.pt : inv.description.en,
                publishDate: format(new Date(inv.publish_date), 'd MMM yyyy', {
                    locale: isPT ? PTLocale : ENLocale
                }),
                files: inv.files?.map(({ src }) => ({ src })),
                atmTransaction: inv.atm_transaction
                    ? {
                          entity: Number(inv.atm_transaction?.entity),
                          reference: Number(inv.atm_transaction?.reference),
                          dueDate: format(new Date(inv.atm_transaction?.due_date), 'd MMM', {
                              locale: isPT ? PTLocale : ENLocale
                          }),
                          totalAmount: inv.atm_transaction?.total_amount
                      }
                    : undefined
            }));
        },
        [handleDocumentStatus, isPT, userDocs]
    );

    useEffect(() => {
        fetchDocs();
    }, [fetchDocs]);

    useEffect(() => {
        setUserInvoices(mapUserDocuments(userDocs?.invoices) ?? []);
        setUserDocuments(mapUserDocuments(userDocs?.documents) ?? []);
    }, [mapUserDocuments, userDocs]);

    useEffect(
        () => setActiveDocuments(contentType === 'invoice' ? userInvoices : userDocuments),
        [contentType, userDocuments, userInvoices]
    );

    useEffect(() => {
        DEV &&
            console.log(
                `  %c content type: ${contentType} `,
                'background-color: #333; color: yellow'
            );
    }, [contentType]);

    return (
        <section className={[styles.documents, styles[activeTheme], 'container'].join(' ')}>
            <h2>{content.TITLE}</h2>
            <ButtonGroup
                buttons={[
                    {
                        label: content.SECTIONS.INVOICES.BUTTON,
                        onClick: () => setContentType('invoice')
                    },
                    {
                        label: content.SECTIONS.OTHER.BUTTON,
                        onClick: () => setContentType('other'),
                        selected: false
                    }
                ]}
                className={styles['btn-group']}
            />
            {activeDocuments.length > 0 ? (
                activeDocuments.map((doc, i) => <CardDocuments key={i} document={doc} />)
            ) : (
                <div className={[styles['not-found-card'], styles[activeTheme]].join(' ')}>
                    <HiOutlineXCircle className={styles.icon} />
                    <span>Nenhum documento encontrado</span>
                </div>
            )}
        </section>
    );
};
