/*
 * @see: https://javascript.plainenglish.io/creating-a-browser-agnostic-pwa-install-button-41039f312fbe
 */

import React, { Suspense } from 'react';
import {
    createContext,
    RefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';

const PWAInstallDrawer = React.lazy(
    () =>
        import(
            /* webpackChunkName: "PWAInstallDrawer" */
            './PWAInstallDrawer'
        )
);

/*
 * @see: https://stackoverflow.com/questions/51503754/typescript-type-beforeinstallpromptevent
 */
interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed';
        platform: string;
    }>;
    prompt(): Promise<void>;
}

declare global {
    interface WindowEventMap {
        beforeinstallprompt: BeforeInstallPromptEvent;
    }
}

type PWAPromptDialogContextData = {
    buttonEl: RefObject<HTMLButtonElement>;
    isInstallable: boolean;
    isInstalled: boolean;
    showDialog: boolean;
    onClick: () => Promise<void>;
    cancelPWADialog: () => void;
};

const PWAPromptDialogContext = createContext<PWAPromptDialogContextData>(
    {} as PWAPromptDialogContextData
);

export const PWAPromptDialogProvider: React.FC = ({ children }) => {
    const buttonEl = useRef<HTMLButtonElement>(null);
    const [isInstallable, setIsInstallable] = useState(true);
    const [isInstalled, setIsInstalled] = useState(false);
    const [showDialog, setShowDialog] = useState(true);
    const [hasPermission, setHasPermission] = useState(false);
    const [isMobileScreen, setIsMobileScreen] = useState(false);
    const [OS, setOS] = useState<'iOS' | 'Android' | 'Other'>('Other');
    const [prompt, setPrompt] = useState<BeforeInstallPromptEvent | undefined>(undefined);

    //a somewhat verbose approach to iOS detection
    const isThisDeviceRunningOS = useCallback(() => {
        const devices = [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ];

        if (!navigator.userAgentData) return false;
        if (devices.includes(navigator?.userAgentData?.platform)) return true;

        // iPad on iOS 13
        if (navigator.userAgent.includes('Mac') && 'ontouchend' in document) return true;
        return false;
    }, []);

    // @see: https://dev.to/konyu/using-javascript-to-determine-whether-the-client-is-ios-or-android-4i1j
    const getMobileOS = useCallback(() => {
        const ua = navigator.userAgent;
        if (!ua) return 'Other';
        if (/android/i.test(ua)) return 'Android';
        if (
            /iPad|iPhone|iPod/.test(ua) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
        )
            return 'iOS';
        return 'Other';
    }, []);

    const checkUserPermission = useCallback(() => {
        const permissionDenied = localStorage.getItem('@hi_pwa_dialog');

        if (permissionDenied) {
            const moreThanOneDay =
                new Date().getTime() - new Date(permissionDenied).getTime() > 1000 * 60 * 60 * 24; // 1 day

            if (moreThanOneDay) {
                localStorage.removeItem('@hi_pwa_dialog');
                setHasPermission(true);
            }
        }

        if (!permissionDenied) setHasPermission(true);
    }, []);

    const checkIsMobileScreen = useCallback(() => {
        if (window.matchMedia('(min-width:450px)').matches) return false;
        return true;
    }, []);

    const onClick = useCallback(async () => {
        const result = await prompt?.userChoice;
        if (result && result.outcome === 'accepted') setIsInstalled(true);
    }, [prompt?.userChoice]);

    const showDrawer = useMemo(
        () => isInstallable && !isInstalled && showDialog && hasPermission && isMobileScreen,
        [hasPermission, isInstallable, isInstalled, showDialog, isMobileScreen]
    );

    const cancelPWADialog = () => {
        localStorage.setItem('@hi_pwa_dialog', new Date().toISOString());
    };

    // useEffect(() => {
    //     localStorage.removeItem('@hi_pwa_dialog');
    // }, []);

    useEffect(checkUserPermission);

    useEffect(() => {
        const isMobile = checkIsMobileScreen();
        setIsMobileScreen(isMobile);
    }, [checkIsMobileScreen]);

    useEffect(() => {
        const OS = getMobileOS();
        setOS(OS);
    }, [getMobileOS]);

    useEffect(() => {
        // TIP: to test on Android Studio comment this validation
        if (!('serviceWorker' in navigator)) {
            setIsInstallable(false);
            return;
        }

        window.addEventListener('beforeinstallprompt', (evt) => {
            evt.preventDefault();
            localStorage.removeItem('@hi_pwa_install');
            setPrompt(evt);
        });

        //check when the page is loaded if it matches one of the PWA display modes
        window.addEventListener('DOMContentLoaded', function () {
            if (
                window.matchMedia('(display-mode: standalone)').matches ||
                window.matchMedia('(display-mode: fullscreen)').matches ||
                window.matchMedia('(display-mode: minimal-ui)').matches
            )
                setIsInstalled(true);
        });

        const onIOS = isThisDeviceRunningOS();
        if (onIOS) setIsInstalled(true);

        window.addEventListener('appinstalled', async () => {
            setShowDialog(false);
            localStorage.setItem('@hi_pwa_install', 'true');
        });
    }, [isThisDeviceRunningOS]);

    return (
        <PWAPromptDialogContext.Provider
            value={{ buttonEl, isInstallable, isInstalled, showDialog, onClick, cancelPWADialog }}>
            {children}
            {showDrawer && (
                <Suspense fallback={null}>
                    <PWAInstallDrawer onClick={onClick} OS={OS} />{' '}
                </Suspense>
            )}
        </PWAPromptDialogContext.Provider>
    );
};

export const usePWAPromptDialog: () => PWAPromptDialogContextData = () => {
    const context = useContext(PWAPromptDialogContext);
    if (!context)
        throw Error('the usePWAPromptDialog hook must be used inside a PWAPromptDialogProvider');
    return context;
};
