import { CardProfile, Filter, Tag } from 'components/elements';
import { useLanguage, useTheme } from 'hooks';
import { StaffData } from 'models/types';
import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { countDuplicatesInArray } from 'utils';

import { WrapperProps } from '../../types';
import { useStaff } from '../../useStaff';
import styles from './List.module.scss';

const ITEMS_PER_LOAD = 3;

export const List: React.FC = () => {
    const { isPT } = useLanguage();
    const { activeTheme } = useTheme();
    const { CONTENT, staff, hasSearchResults, notFound } = useStaff();

    const [topTags, setTopTags] = useState<string[]>([]);
    const [filteredStaff, setFilteredStaff] = useState<StaffData[]>([]);
    const [remainingStaff, setRemainingStaff] = useState<StaffData[]>([]);
    const [loadedStaff, setLoadedStaff] = useState<StaffData[]>([]);
    const [loadedStaffIndex, setLoadedStaffIndex] = useState(0);
    const [filterLabel, setFilterLabel] = useState(CONTENT.FILTER.LABEL);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);

    const Wrapper = ({ hasNextPage, isNextPageLoading, items, loadNextPage }: WrapperProps) => {
        const itemCount = hasNextPage ? items.length + 1 : items.length;
        const loadMoreItems = isNextPageLoading ? () => null : loadNextPage;
        const isItemLoaded = (index: number) => !hasNextPage || index < items.length;
        const getItemSize = () => 320;

        const Item = ({ index, style }: { index: number; style: CSSProperties }) => {
            const c: StaffData | null = !isItemLoaded(index) ? null : items[index];

            if (c)
                return (
                    <div style={{ ...style, marginBottom: 20 }} key={c.id.toString()}>
                        <CardProfile
                            area="movement"
                            theme={activeTheme}
                            cardSize="standard"
                            price={Math.min(...c.services.map(({ price }) => price))}
                            name={c.name}
                            locations={c.locations}
                            platforms={c.platforms}
                            images={c.images}
                            tags={c.tags}
                            rating={c.rating}
                            slug={c.slug}
                            languages={c.languages}
                        />
                    </div>
                );
            return null;
        };

        return (
            <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}>
                {({ onItemsRendered, ref }) => (
                    <VariableSizeList
                        height={remainingStaff.length * 320}
                        itemCount={itemCount}
                        itemSize={getItemSize}
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                        width={340}>
                        {Item}
                    </VariableSizeList>
                )}
            </InfiniteLoader>
        );
    };

    /**
     * @see https://web.dev/virtualize-long-lists-react-window/
     */
    const _loadNextPage = useCallback(() => {
        setIsNextPageLoading(true);

        setTimeout(() => {
            if (remainingStaff.length === 0) {
                setIsNextPageLoading(false);
                return;
            }

            setHasNextPage(loadedStaff.length + ITEMS_PER_LOAD < remainingStaff.length);

            const remaining = remainingStaff.length - loadedStaffIndex;
            const limit =
                remaining < ITEMS_PER_LOAD
                    ? loadedStaff.length + remaining
                    : loadedStaff.length + ITEMS_PER_LOAD;
            const s = loadedStaff;
            for (let index = loadedStaff.length; index < limit; index++)
                s.push(remainingStaff[index]);
            setLoadedStaff(s);
            setLoadedStaffIndex(loadedStaffIndex + ITEMS_PER_LOAD);
            setIsNextPageLoading(false);
        }, 1500);
    }, [loadedStaff, loadedStaffIndex, remainingStaff]);

    const handleFilter = useCallback(
        (tag: string) => {
            if (!tag) {
                setFilteredStaff(remainingStaff);
                setFilterLabel('filtrar');
                return;
            }

            setFilteredStaff(
                remainingStaff.filter(({ tags }) =>
                    tags.filter((t) => t.pt.includes(tag) || t.en.includes(tag))
                )
            );
            setFilterLabel(`${tag.slice(0, 10)}${tag.length > 10 ? '...' : ''}`);
        },
        [remainingStaff]
    );

    const handleTags = useCallback(() => {
        if (staff.length === 0) return;

        const allTags = staff
            .map(({ tags }) => tags)
            .flat()
            .map(({ pt, en }) => (isPT ? pt : en));

        const countedDuplicatedTags = countDuplicatesInArray(
            allTags.map((t) => t.toLocaleLowerCase()).sort()
        );

        /**
         * @see https://medium.com/@gmcharmy/sort-objects-in-javascript-e-c-how-to-get-sorted-values-from-an-object-142a9ae7157c
         */
        const orderedTags: string[] = Object.entries(countedDuplicatedTags)
            .sort((a, b) => b[1] - a[1])
            .map((el) => el[0]);

        setTopTags(orderedTags.sort());
    }, [isPT, staff]);

    useEffect(() => setRemainingStaff(staff.filter(({ highlight }) => !highlight)), [staff]);

    useEffect(() => {
        handleTags();
    }, [handleTags]);

    if (hasSearchResults || notFound) return null;

    return (
        <section className={`${styles.list} container`}>
            <div className={styles.header}>
                <h2>{CONTENT.TAGS.TITLE}</h2>
                <Filter label={filterLabel} handleFilter={handleFilter} options={topTags} />
            </div>
            <div className={styles['tag-container']}>
                {topTags.map((tag, i) => (
                    <Tag key={i.toString()} theme={activeTheme}>
                        {tag}
                    </Tag>
                ))}
            </div>
            {filteredStaff.length > 0 ? (
                filteredStaff.map((t) => (
                    <CardProfile
                        key={t.id.toString()}
                        id={t.id}
                        area="movement"
                        theme={activeTheme}
                        cardSize="standard"
                        price={Math.min(...t.services.map(({ price }) => price))}
                        name={t.name}
                        locations={t.locations}
                        platforms={t.platforms}
                        images={t.images}
                        tags={t.tags}
                        rating={t.rating}
                        slug={t.slug}
                        languages={t.languages}
                    />
                ))
            ) : (
                <Wrapper
                    hasNextPage={hasNextPage}
                    isNextPageLoading={isNextPageLoading}
                    items={loadedStaff}
                    loadNextPage={_loadNextPage}
                />
            )}
        </section>
    );
};
