import { useTheme } from 'hooks';
import { useMemo } from 'react';
import { COLORS } from 'theme/colors';
import { IconAreaProps } from 'theme/icons/types';

export const IconHealthV1: React.FC<IconAreaProps> = ({
    color,
    width = 75,
    height = 70,
    variation,
    colorType,
    ...props
}) => {
    const { darkMode } = useTheme();

    const defaultColor = useMemo(() => (darkMode ? COLORS.NEUTRAL_800 : COLORS.WHITE), [darkMode]);
    const iconColor = useMemo(() => color ?? defaultColor, [color, defaultColor]);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 75 70"
            width={width}
            height={height}
            {...props}>
            <defs>
                <linearGradient
                    id="health-linear-gradient"
                    x1="3204.8"
                    y1="1554"
                    x2="3251.8"
                    y2="1507.1"
                    gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor={COLORS.PURPLE_600} />
                    <stop offset="1" stopColor={COLORS.BLUE_400} />
                </linearGradient>
            </defs>
            {variation === 'FILL' && (
                <path
                    fill={colorType === 'GRADIENT' ? 'url(#health-linear-gradient)' : iconColor}
                    d="M3264.4 1530a22.4 22.4 0 0 1-4.2 13 20.4 20.4 0 0 1-2.4 2.7l-16.3 16.2-11.7 11.5c-.7.8-1.1.8-1.8 0l-28.6-28.4a19.8 19.8 0 0 1-4.6-7.3 22.1 22.1 0 0 1 33.7-25.5.6.6 0 0 0 .8 0 22.2 22.2 0 0 1 25.8 0 21.7 21.7 0 0 1 9.2 15.8v2Z"
                    transform="translate(-3191.4 -1506)"
                />
            )}
            {variation === 'OUTLINE' && (
                <path
                    fill={colorType === 'GRADIENT' ? 'url(#health-linear-gradient)' : iconColor}
                    d="M3228.9 1576a3.2 3.2 0 0 1-2.3-1.1l-7.6-7.6-21-21a22 22 0 0 1-5-8 24.1 24.1 0 0 1 36-28.3 24.1 24.1 0 0 1 27.3.5 23.6 23.6 0 0 1 10 17.2l.1 1.3v1a24.4 24.4 0 0 1-4.6 14 22.1 22.1 0 0 1-2.6 3l-12.8 12.8-15.2 15a3.3 3.3 0 0 1-2.3 1.2Zm-13.2-66.2a22.4 22.4 0 0 0-4.4.4 20.2 20.2 0 0 0-14.9 26.8 18 18 0 0 0 4.3 6.6l21 21 7.2 7.2 14.8-14.7 12.9-12.7a18.4 18.4 0 0 0 2.1-2.5 20.4 20.4 0 0 0 3.9-11.9v-1.8a19.7 19.7 0 0 0-8.5-14.6 20.4 20.4 0 0 0-23.7 0 2.4 2.4 0 0 1-3 0 20.1 20.1 0 0 0-11.7-3.8Z"
                    transform="translate(-3191.4 -1506)"
                />
            )}
        </svg>
    );
};
