import { Main } from 'components/elements';
import { Header } from 'components/sections';
import { permissions } from 'config';
import { META_TAGS, PARTNER } from 'content';
import { useApi, useAuth, useLanguage, useTheme } from 'hooks';
import { PartnerData } from 'models/types';
import { Loading } from 'pages/public/Loading';
import React, { Suspense } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { About, Banners, Booking, Delivery, Intro, Locations, Promos } from './modules';
import styles from './Partner.module.scss';

const NavBar = React.lazy(
    () =>
        import(
            /* webpackChunkName: "NavBar" */
            'components/elements/NavBar'
        )
);

export const Partner: React.FC = () => {
    const { id } = useParams();
    const { validateUserAccessToArea } = useAuth();
    const { getPartner } = useApi();
    const { isPT } = useLanguage();
    const { darkMode } = useTheme();

    const CONTENT = isPT ? PARTNER.PT : PARTNER.EN;

    const [partner, setPartner] = useState<PartnerData | undefined>(undefined);

    const getPartnerData = useCallback(async () => {
        try {
            if (!id) return;
            const partner = await getPartner(id);
            setPartner(partner);
        } catch (error) {
            // TO-DO
            console.error(error);
        }
    }, [getPartner, id]);

    useEffect(
        () => validateUserAccessToArea(permissions.general.promos),
        [validateUserAccessToArea]
    );

    useEffect(() => {
        getPartnerData();
    }, [getPartnerData]);

    if (!partner) return <Loading />;

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? `${partner.name} ${META_TAGS.PRIVATE.PROMOS.PARTNER.PT.TITLE}`
                        : `${partner.name} ${META_TAGS.PRIVATE.PROMOS.PARTNER.EN.TITLE}`}
                </title>
            </Helmet>
            <Header backButton hasImage={false} title={CONTENT.HEADER.TITLE} />

            <Main className={styles.main}>
                <Intro
                    partner={{
                        backgroundColor: partner.brand.background_color,
                        logo: darkMode
                            ? partner.brand.logo.dark_mode
                            : partner.brand.logo.light_mode,
                        name: partner.name
                    }}
                />
                <Promos partner={partner} content={CONTENT} brand={partner.brand} />
                <Banners partner={partner} content={{ EXTERNAL_URL: CONTENT.EXTERNAL_URL }} />
                <About partner={partner} />
                <Locations partner={partner} content={{ EXTERNAL_URL: CONTENT.EXTERNAL_URL }} />
                <Delivery delivers={partner.delivers} content={CONTENT.DELIVERIES} />
                <Booking externalURL={partner.booking} content={CONTENT.BOOKING} />
            </Main>
            <Suspense fallback={null}>
                <NavBar activeArea="promos" />
            </Suspense>
        </>
    );
};
