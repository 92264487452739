import { CardProfileProps, Carousel } from 'components/elements';
import { useTheme } from 'hooks';
import { StaffData } from 'models/types';
import { useEffect, useState } from 'react';

import { useStaff } from '../../useStaff';
import styles from './Highlights.module.scss';

export const HightLights: React.FC = () => {
    const { activeTheme } = useTheme();
    const { CONTENT, staff, notFound, hasSearchResults } = useStaff();

    const [topStaff, setTopStaff] = useState<CardProfileProps[] | null>(null);

    useEffect(() => {
        setTopStaff(
            staff
                .filter(({ highlight }) => highlight)
                .map((t: StaffData) => ({
                    id: t.id,
                    name: t.name,
                    locations: t.locations,
                    platforms: t.platforms,
                    images: t.images,
                    price: Math.min(...t.services.map(({ price }) => price)),
                    tags: t.tags,
                    rating: t.rating,
                    slug: t.slug,
                    languages: t.languages,
                    area: 'movement',
                    theme: activeTheme,
                    cardSize: 'highlight'
                }))
        );
    }, [activeTheme, staff]);

    if (topStaff && !notFound && !hasSearchResults)
        return (
            <section className={`${styles.highlights} container`}>
                <h2>{CONTENT.HIGHLIGHTS.TITLE}</h2>
                <Carousel __typename="card-profile" slides={topStaff} className={styles.carousel} />
            </section>
        );

    return null;
};
