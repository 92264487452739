import { ButtonIcon } from 'components/elements';
import { useTheme } from 'hooks';
import { useNavigate } from 'react-router-dom';

import styles from './Styles.module.scss';

type HeaderProps = {
    headerRef: React.RefObject<HTMLDivElement>;
};

export const Header: React.FC<HeaderProps> = ({ headerRef }) => {
    const navigate = useNavigate();
    const { activeTheme } = useTheme();

    return (
        <header ref={headerRef} className={`${styles.header} ${styles[activeTheme]} container`}>
            <ButtonIcon
                icon="X"
                size="MD"
                backgroundOpacity="TRANSPARENT"
                onClick={() => navigate(-1)}
                className={styles.icon}
            />
            {/* <ButtonIcon
              icon="SHARE"
              size="MD"
              backgroundOpacity="TRANSPARENT"
              className={styles.icon}
          /> */}
        </header>
    );
};
