import { permissions } from 'config';
import { HEALTH_APPOINTMENT, MEDICAL_APPOINTMENT_PHONE_NUMBER, META_TAGS } from 'content';
import { useAuth, useLanguage, useTheme } from 'hooks';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { IconPhone } from 'theme/icons';

import styles from './Appointment.module.scss';

export const Appointment: React.FC = () => {
    const { activeTheme } = useTheme();
    const { validateUserAccessToArea } = useAuth();
    const { isPT } = useLanguage();

    const CONTENT = isPT ? HEALTH_APPOINTMENT.PT : HEALTH_APPOINTMENT.EN;

    useEffect(
        () => validateUserAccessToArea(permissions.health.appointment),
        [validateUserAccessToArea]
    );

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? META_TAGS.PRIVATE.HEALTH.APPOINTMENT.PT.TITLE
                        : META_TAGS.PRIVATE.HEALTH.APPOINTMENT.EN.TITLE}
                </title>
            </Helmet>
            <section className={`${styles.intro} container`}>
                <span className={styles.preTitle}>Contacto</span>
                <h1>{CONTENT.TITLE}</h1>
                <a
                    href={`tel:${MEDICAL_APPOINTMENT_PHONE_NUMBER}`}
                    className={`${styles.button} ${styles.health} ${styles.md}`}>
                    <span>
                        <IconPhone /> {MEDICAL_APPOINTMENT_PHONE_NUMBER}
                    </span>
                </a>
                <p>{CONTENT.AVAILABILITY}</p>
            </section>
            <section className={`${styles.description} ${styles[activeTheme]} container`}>
                {CONTENT.DESCRIPTION.map((c) => {
                    if (c.TYPE === 'h2') return <h2 key={c.ID.toString()}>{c.TEXT}</h2>;
                    if (c.TYPE === 'p') return <p key={c.ID.toString()}>{c.TEXT}</p>;
                    if (c.TYPE === 'ul')
                        return (
                            <ul key={c.ID.toString()}>
                                {c.ITEMS?.map((it) => (
                                    <li key={it.ID.toString()}>{it.TEXT}</li>
                                ))}
                            </ul>
                        );
                    if (c.TYPE === 'strong')
                        return (
                            <strong
                                className={`${styles.highlight} ${styles[activeTheme]}`}
                                key={c.ID.toString()}>
                                {c.TEXT}
                            </strong>
                        );
                    if (c.TYPE === 'ol')
                        return (
                            <ol key={c.ID.toString()}>
                                {c.ITEMS?.map((it) => (
                                    <li key={it.ID.toString()}>{it.TEXT}</li>
                                ))}
                            </ol>
                        );
                    if (c.TYPE === 'footnote')
                        return (
                            <div className={styles.footnote} key={c.ID.toString()}>
                                {c.TEXT}
                            </div>
                        );
                })}
            </section>
        </>
    );
};
