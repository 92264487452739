import { useTheme } from 'hooks';
import { useEffect, useState } from 'react';
import { COLORS } from 'theme/colors';
import { IconAreaProps } from 'theme/icons/types';

export const IconArticleV1: React.FC<IconAreaProps> = ({
    color,
    width = 53,
    height = 70,
    variation,
    colorType,
    ...props
}) => {
    const { darkMode } = useTheme();
    const [themeClassName, setThemeClassName] = useState('');

    useEffect(
        () => setThemeClassName(color ? color : darkMode ? COLORS.NEUTRAL_800 : COLORS.WHITE),
        [color, darkMode]
    );

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 53 70"
            width={width}
            height={height}
            {...props}>
            <defs>
                <linearGradient
                    id="articles-linear-gradient"
                    x1="3298.97"
                    y1="1537.19"
                    x2="3351.97"
                    y2="1537.19"
                    gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor={COLORS.YELLOW_200} />
                    <stop offset="1" stopColor={COLORS.RED_100} />
                </linearGradient>
            </defs>
            {variation === 'FILL' && (
                <path
                    id="fill"
                    fill={
                        colorType === 'GRADIENT' ? 'url(#articles-linear-gradient)' : themeClassName
                    }
                    d="M3335.34 1502.19h-19.74A16.74 16.74 0 0 0 3299 1519v36.41a16.73 16.73 0 0 0 16.63 16.79h19.74a16.73 16.73 0 0 0 16.63-16.8V1519a16.74 16.74 0 0 0-16.66-16.81Zm-22.1 17.38h8.25a2.06 2.06 0 0 1 0 4.11h-8.25a2.06 2.06 0 0 1 0-4.11Zm26.25 35.25h-28a2.06 2.06 0 0 1 0-4.11h28a2.06 2.06 0 0 1 0 4.11Zm0-9.72h-28a2.06 2.06 0 0 1 0-4.11h28a2.06 2.06 0 0 1 0 4.11Zm0-9.72h-28a2.06 2.06 0 0 1 0-4.11h28a2.06 2.06 0 0 1 0 4.11Z"
                    transform="translate(-3298.97 -1502.19)"
                />
            )}
            {variation === 'OUTLINE' && (
                <path
                    id="stoke"
                    fill={
                        colorType === 'GRADIENT' ? 'url(#articles-linear-gradient)' : themeClassName
                    }
                    d="M3335.34 1502.19h-19.74A16.74 16.74 0 0 0 3299 1519v36.41a16.73 16.73 0 0 0 16.63 16.79h19.74a16.73 16.73 0 0 0 16.63-16.8V1519a16.74 16.74 0 0 0-16.66-16.81Zm12.55 53.21a12.62 12.62 0 0 1-12.55 12.68h-19.74a12.62 12.62 0 0 1-12.55-12.68V1519a12.63 12.63 0 0 1 12.55-12.68h19.74a12.62 12.62 0 0 1 12.55 12.68Zm-34.65-31.72h8.25a2.06 2.06 0 0 0 0-4.11h-8.25a2.06 2.06 0 0 0 0 4.11Zm26.25 7.59h-28a2.06 2.06 0 0 0 0 4.11h28a2.06 2.06 0 0 0 0-4.11Zm0 9.72h-28a2.06 2.06 0 0 0 0 4.11h28a2.06 2.06 0 0 0 0-4.11Zm0 9.72h-28a2.06 2.06 0 0 0 0 4.11h28a2.06 2.06 0 0 0 0-4.11Z"
                    transform="translate(-3298.97 -1502.19)"
                />
            )}
        </svg>
    );
};
