import { LogoHi } from 'assets/logos';
import { ImageFrame } from 'components/elements';
import { useTheme } from 'hooks';
import React from 'react';

import styles from './Cover.module.scss';

type CoverProps = {
    hasImage: boolean;
    image?: string;
    image_2x?: string;
    alt?: string;
    title?: string;
};

export const Cover: React.FC<CoverProps> = ({ hasImage, image, image_2x, alt, title }) => {
    const { activeTheme } = useTheme();

    if (!hasImage) return null;

    return (
        <div className={styles.cover}>
            {hasImage && image && (
                <ImageFrame image={image} image_2x={image_2x} description={alt ?? ''} />
            )}
            {title ? (
                <span className={styles.title}>{title}</span>
            ) : (
                <LogoHi
                    animate
                    showLettering={false}
                    className={[
                        styles.logo,
                        !hasImage ? styles['no-image'] : '',
                        styles[activeTheme]
                    ].join(' ')}
                />
            )}
        </div>
    );
};

// DEPRECATED : don't update `hasImage` props on page switch
// const MemoizedCover = React.memo(
//     Cover,
//     (prevProps, nextProps) => prevProps.hasImage !== nextProps.hasImage
// );
