import { LogoHi } from 'assets/logos';
import { Button, CardPromo, Modal } from 'components/elements';
import { PartnerContent } from 'content/types';
import { sanitize } from 'dompurify';
import { useGamification, useLanguage } from 'hooks';
import { useAuth, useForceRerender, useWebShare } from 'hooks';
import { PartnerBrandData } from 'models/partner';
import { PartnerData, PromoDataV2 } from 'models/types';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { api, isAxiosError } from 'services';
import { IconExternal } from 'theme/icons';
import { DEV } from 'utils';

import styles from './Promos.module.scss';

type ModalSteps = 'welcome' | 'confirm' | 'success';

type PromosProps = {
    content: PartnerContent;
    partner: PartnerData;
    brand: PartnerBrandData;
};

type PromosContextData = {
    data: {
        content: PartnerContent;
        partner: PartnerData;
        brand: PartnerBrandData;
    };
    promo: {
        selectedPromo: PromoDataV2 | undefined;
        setSelectedPromo: React.Dispatch<React.SetStateAction<PromoDataV2 | undefined>>;
    };
    modal: {
        showModal: boolean;
        setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
        modalStep: ModalSteps;
        setModalStep: React.Dispatch<React.SetStateAction<ModalSteps>>;
        handleModalNavigationForRedeemableVoucher: () => void;
        handleModalClose: () => void;
    };
    voucher: {
        // voucherExternalURL: string | undefined;
        alreadyRedeemed: boolean;
        setAlreadyRedeemed: React.Dispatch<React.SetStateAction<boolean>>;
        handleVoucherRedeem: () => Promise<void>;
    };
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const PromosContext = createContext<PromosContextData>({} as PromosContextData);

const PromosProvider: React.FC<PromosProps> = (props) => {
    const { user } = useAuth();
    const { reportInteraction } = useGamification();

    const [selectedPromo, setSelectedPromo] = useState<PromoDataV2>();
    const [showModal, setShowModal] = useState(false);
    const [modalStep, setModalStep] = useState<ModalSteps>('welcome');
    const [loading, setLoading] = useState(false);
    const [alreadyRedeemed, setAlreadyRedeemed] = useState(false);

    const handleModalClose = useCallback(() => {
        setShowModal(false);
        setModalStep('welcome');
    }, [setModalStep, setShowModal]);

    const handleModalNavigationForRedeemableVoucher = useCallback(() => {
        if (!selectedPromo) return;
        const isSingleUse = selectedPromo.voucher.number_of_redeems === 1;
        setModalStep(isSingleUse ? 'confirm' : 'success');
    }, [selectedPromo, setModalStep]);

    const handleVoucherRedeem = useCallback(async () => {
        if (!selectedPromo) return;

        try {
            if (!user) throw new Error('No user found');

            setLoading(true);

            await api.post(`/promos/${selectedPromo.id}/redeem`, {
                user: user.id
            });

            reportInteraction({
                interaction: 'promos',
                area: 'bonus',
                id: selectedPromo.id
            });

            setLoading(false);
            setModalStep('success');
        } catch (error) {
            setLoading(false);

            if (isAxiosError(error) && error.response) {
                const { message } = error.response.data;
                setAlreadyRedeemed(message === 'voucher already redeemed');
                return;
            }
            // TO-DO
            DEV && console.log('error: ', error);
        }
    }, [reportInteraction, selectedPromo, setAlreadyRedeemed, setLoading, setModalStep, user]);

    return (
        <PromosContext.Provider
            value={{
                data: {
                    content: props.content,
                    partner: props.partner,
                    brand: props.brand
                },
                promo: {
                    selectedPromo,
                    setSelectedPromo
                },
                modal: {
                    showModal,
                    setShowModal,
                    modalStep,
                    setModalStep,
                    handleModalNavigationForRedeemableVoucher,
                    handleModalClose
                },
                voucher: {
                    alreadyRedeemed,
                    setAlreadyRedeemed,
                    handleVoucherRedeem
                },
                loading,
                setLoading
            }}>
            {props.children}
        </PromosContext.Provider>
    );
};

const usePromos = (): PromosContextData => {
    const context = useContext(PromosContext);
    if (!context) {
        throw new Error('the hook usePromos must be used inside a PromosProvider');
    }

    return context;
};

export const Promos: React.FC<PromosProps> = (props) => {
    return (
        <PromosProvider {...props}>
            <PromosMain />
        </PromosProvider>
    );
};

const PromosMain: React.FC = () => {
    const { promo, data, modal } = usePromos();
    const { handleModalClose, showModal, modalStep } = modal;
    const { selectedPromo } = promo;
    const { brand, content, partner } = data;
    const { TITLE, DESCRIPTION } = content.PROMO_LIST;

    return (
        <>
            <section className={`${styles['promo-section']} container`}>
                <h1>{TITLE}</h1>
                <p>
                    {DESCRIPTION} <b>{partner.name}</b>
                </p>
                <PromoList />
            </section>

            {selectedPromo && (
                <Modal
                    show={showModal}
                    className={styles.modal}
                    footer={{ enable: false }}
                    close={{
                        handler: handleModalClose,
                        buttonColor: brand.text_color
                    }}
                    background={{ color: brand.background_color }}
                    overlay={{ color: brand.primary_color }}>
                    {modalStep === 'welcome' && <ModalWelcome />}
                    {modalStep === 'confirm' && <ModalConfirm />}
                    {modalStep === 'success' && <ModalSuccess />}
                </Modal>
            )}
        </>
    );
};

const ModalWelcome: React.FC = () => {
    const { isPT } = useLanguage();
    const { promo, data, modal } = usePromos();
    const { handleModalNavigationForRedeemableVoucher } = modal;
    const { selectedPromo } = promo;
    const { brand, content, partner } = data;

    if (!selectedPromo) return null;

    return (
        <>
            <div className={styles['modal-header']}>
                <img src={partner.brand.logo.brand_mode} alt={partner.name} />
            </div>

            <div className={styles['modal-body']}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: sanitize(
                            isPT
                                ? selectedPromo.voucher.description.pt
                                : selectedPromo.voucher.description.en
                        )
                    }}
                    style={{ color: brand.text_color }}
                />

                {selectedPromo.voucher.URL ? (
                    <>
                        {selectedPromo.voucher.URL.type === 'external' && (
                            <a
                                href={selectedPromo.voucher.URL.path}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.button}
                                style={{
                                    background: brand.button_color,
                                    color: brand.button_label_color
                                }}>
                                {content.PROMO_LIST.VOUCHER.BUTTON_LABEL}
                            </a>
                        )}

                        {selectedPromo.voucher.URL.type === 'internal' && (
                            <Link
                                to={`/${selectedPromo.voucher.URL.path}`}
                                className={styles.button}
                                style={{
                                    background: brand.button_color,
                                    color: brand.button_label_color
                                }}>
                                {content.PROMO_LIST.VOUCHER.BUTTON_LABEL}
                            </Link>
                        )}
                    </>
                ) : (
                    <>
                        {/* For in-app redeemable vouchers */}
                        <Button
                            label={content.PROMO_LIST.VOUCHER.BUTTON_LABEL}
                            size="lg"
                            fullWidth
                            style={{
                                background: brand.button_color,
                                color: brand.button_label_color
                            }}
                            onClick={handleModalNavigationForRedeemableVoucher}
                        />
                    </>
                )}

                {selectedPromo.voucher.URL?.type === 'external' && (
                    <div className={styles['external-warning']}>
                        <p
                            style={{ color: brand.text_color }}
                            dangerouslySetInnerHTML={{
                                __html: sanitize(content.EXTERNAL_URL(partner.name))
                            }}
                        />
                        <IconExternal color={brand.text_color} width={20} className={styles.icon} />
                    </div>
                )}
            </div>

            <div className={styles['modal-footer']}>
                <p
                    className={styles.notes}
                    dangerouslySetInnerHTML={{
                        __html: sanitize(isPT ? selectedPromo.notes.pt : selectedPromo.notes.en)
                    }}
                    style={{ color: brand.text_color }}
                />
            </div>
        </>
    );
};

const ModalConfirm: React.FC = () => {
    const { data, loading, voucher, modal } = usePromos();
    const { brand, content, partner } = data;
    const { alreadyRedeemed, handleVoucherRedeem } = voucher;
    const { handleModalClose } = modal;

    const Loading: React.FC = useCallback(() => {
        if (!loading) return null;
        const { primary_color } = partner.brand;

        return (
            <div className={[styles['modal-body'], styles.loading].join(' ')}>
                <LogoHi showLettering={false} animate width={90} color={primary_color} />
            </div>
        );
    }, [loading, partner.brand]);

    const Invalid: React.FC = useCallback(() => {
        if (!alreadyRedeemed) return null;

        const { MESSAGE, TITLE } = content.PROMO_LIST.VOUCHER.REDEEM.INVALID;
        return (
            <div className={[styles['modal-body'], styles.redeemed].join(' ')}>
                <strong>{TITLE}</strong>
                <span>{MESSAGE}</span>
            </div>
        );
    }, [alreadyRedeemed, content.PROMO_LIST.VOUCHER.REDEEM]);

    const Valid: React.FC = useCallback(() => {
        if (loading || alreadyRedeemed) return null;

        const { BUTTON_LABEL, MESSAGE } = content.PROMO_LIST.VOUCHER.REDEEM.CONFIRM;
        const { button_color, button_label_color, text_color } = brand;
        return (
            <>
                <div
                    className={styles['modal-body']}
                    dangerouslySetInnerHTML={{
                        __html: sanitize(MESSAGE)
                    }}
                    style={{ color: text_color }}
                />
                <Button
                    label={BUTTON_LABEL}
                    size="lg"
                    fullWidth
                    style={{ background: button_color, color: button_label_color }}
                    onClick={handleVoucherRedeem}
                />
            </>
        );
    }, [
        alreadyRedeemed,
        brand,
        content.PROMO_LIST.VOUCHER.REDEEM.CONFIRM,
        handleVoucherRedeem,
        loading
    ]);

    const Footer: React.FC = useCallback(() => {
        if (loading) return null;

        return (
            <div className={styles['modal-footer']}>
                <button
                    onClick={handleModalClose}
                    className={styles['button-link']}
                    style={{ color: brand.primary_color }}>
                    {content.PROMO_LIST.VOUCHER.REDEEM.CANCEL.BUTTON_LABEL}
                </button>
            </div>
        );
    }, [
        brand.primary_color,
        content.PROMO_LIST.VOUCHER.REDEEM.CANCEL.BUTTON_LABEL,
        handleModalClose,
        loading
    ]);

    return (
        <>
            <div className={styles['modal-header']}>
                <img src={partner.brand.logo.brand_mode} alt={partner.name} />
            </div>

            <Loading />
            <Invalid />
            <Valid />
            <Footer />
        </>
    );
};

const ModalSuccess: React.FC = () => {
    const { canShare } = useWebShare();
    const navigate = useNavigate();
    const { isPT } = useLanguage();
    const { data, promo, modal } = usePromos();
    const { brand, content, partner } = data;
    const { selectedPromo } = promo;
    const { handleModalClose } = modal;

    if (!selectedPromo) return null;

    return (
        <>
            <div className={styles['modal-header']}>
                <img src={partner.brand.logo.brand_mode} alt={partner.name} />
            </div>

            <div className={styles['modal-body']}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: sanitize(
                            isPT
                                ? selectedPromo.voucher.success_message.pt
                                : selectedPromo.voucher.success_message.en
                        )
                    }}
                    style={{ color: brand.text_color }}
                />

                {canShare && (
                    <div className={styles['share-message']}>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: sanitize(
                                    `${content.PROMO_LIST.VOUCHER.SUCCESS.SHARE_MESSAGE[0]} <b>${partner.name}</b> ${content.PROMO_LIST.VOUCHER.SUCCESS.SHARE_MESSAGE[1]}`
                                )
                            }}
                            style={{ color: brand.text_color }}
                        />
                        <Button
                            label={content.PROMO_LIST.VOUCHER.SUCCESS.SHARE_BUTTON}
                            size="lg"
                            fullWidth
                            style={{
                                background: brand.button_color,
                                color: brand.button_label_color
                            }}
                            onClick={() => navigate('/partilhar')}
                        />
                    </div>
                )}
            </div>

            <div className={styles['modal-footer']}>
                <button
                    onClick={handleModalClose}
                    className={styles['button-link']}
                    style={{ color: brand.primary_color }}>
                    {content.PROMO_LIST.VOUCHER.SUCCESS.CLOSE_BUTTON}
                </button>
            </div>
        </>
    );
};

const PromoList: React.FC = () => {
    const rerender = useForceRerender();
    const { promo, modal, data } = usePromos();
    const { partner, content } = data;
    const { setShowModal, modalStep } = modal;
    const { setSelectedPromo } = promo;

    const selectPromo = useCallback(
        (id: string) => {
            const promo = partner.promos.filter((p) => p.id === id)[0];
            setSelectedPromo(promo);
            setShowModal(true);
        },
        [partner.promos, setSelectedPromo, setShowModal]
    );

    useEffect(() => {
        if (modalStep === 'success') rerender();
    }, [modalStep, rerender]);

    return (
        <div className={styles['promo-list']}>
            {partner.promos.map((promo) => {
                const isValid =
                    promo.voucher.number_of_redeems === null || promo.voucher.number_of_redeems > 0;
                return (
                    <div key={promo.id.toString()} className={styles['promo-card-wrapper']}>
                        <CardPromo
                            partner={partner}
                            promo={{
                                name: promo.name,
                                image: promo.image,
                                description: promo.description,
                                notes: promo.notes,
                                slug: partner.slug
                            }}
                            isValid={isValid}
                            className={styles.card}
                            onClick={() => {
                                if (isValid) selectPromo(promo.id);
                            }}
                        />
                        <Button
                            label={content.PROMO_LIST.BUTTON_LABEL}
                            size="md"
                            variation="outline"
                            area="nutrition"
                            disabled={!isValid}
                            className={styles.button}
                            onClick={() => selectPromo(promo.id)}
                        />
                    </div>
                );
            })}
        </div>
    );
};
