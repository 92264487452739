import { sanitize } from 'dompurify';
import React, { useEffect, useState } from 'react';

import styles from './BgEffect.module.scss';

export type BgEffectProps = React.HTMLProps<HTMLDivElement>;

export const BgEffect: React.FC<BgEffectProps> = (props) => {
    const spanElement = '<span></span>';
    const [innerHTML, setInnerHTML] = useState('');
    const [effectCount, setEffectCount] = useState(15);

    useEffect(() => {
        setInnerHTML((prevState) => prevState + spanElement);
        if (effectCount === 1) return;
        setEffectCount(effectCount - 1);
    }, [effectCount]);

    return (
        <div
            className={styles.effect}
            dangerouslySetInnerHTML={{ __html: sanitize(innerHTML) }}
            {...props}
        />
    );
};

export default BgEffect;
