import { ButtonDownload, TagStatus } from 'components/elements';
import { useLanguage, useTheme } from 'hooks';
import { useMemo } from 'react';
import { addId } from 'utils';

import styles from './CardDocuments.module.scss';

export type DocumentProps = {
    type: 'invoice' | 'other';
    title: string;
    description: string;
    publishDate: string;
    files?: { src: string }[];
    status: 'payed' | 'pending' | 'overdue';
    atmTransaction?: {
        entity: number;
        reference: number;
        totalAmount: number;
        dueDate: string;
    };
};

export type CardDocumentsProps = {
    document: DocumentProps;
};

const CONTENT = {
    PT: {
        TITLE: {
            OVERDUE: 'Reative a sua conta',
            PENDING: 'Regularize a sua conta'
        },
        ENTITY: 'Entidade',
        REFERENCE: 'Referência',
        AMOUNT: 'Valor',
        DUE_DATE: 'Data limite'
    },
    EN: {
        TITLE: {
            OVERDUE: 'Reactivate your account',
            PENDING: 'Settle your account'
        },
        ENTITY: 'Entity',
        REFERENCE: 'Reference',
        AMOUNT: 'Amount',
        DUE_DATE: 'Due date'
    }
};

export const CardDocuments: React.FC<CardDocumentsProps> = ({ document }) => {
    const { isPT } = useLanguage();
    const { activeTheme } = useTheme();

    const filesWidthId: { id: string; src: string }[] = useMemo(
        () => (document.files ? addId(document.files) : []),
        [document.files]
    );

    const content = useMemo(() => (isPT ? CONTENT.PT : CONTENT.EN), [isPT]);

    return (
        <div className={[styles.card, styles[activeTheme], styles[document.status]].join(' ')}>
            <div className={styles.content}>
                <span className={styles.date}>{document.publishDate}</span>
                <strong className={styles.title}>{document.title}</strong>
                <span className={styles.description}>{document.description}</span>
            </div>
            <div className={styles.wrapper}>
                {document.type === 'other' ||
                (document.type === 'invoice' && document.status === 'payed') ? (
                    filesWidthId.map((f) => (
                        <ButtonDownload
                            key={f.id}
                            href={f.src}
                            target="_blank"
                            rel="noopener noreferrer"
                        />
                    ))
                ) : (
                    <TagStatus status={document.status} />
                )}
            </div>
            {document.type === 'invoice' &&
                (document.status === 'overdue' || document.status === 'pending') &&
                document.atmTransaction && (
                    <div className={styles['payment-details']}>
                        {document.status === 'overdue' && (
                            <span className={styles.title}>{content.TITLE.OVERDUE}</span>
                        )}
                        {document.status === 'pending' && (
                            <span className={styles.title}>{content.TITLE.PENDING}</span>
                        )}
                        <ul>
                            <li>
                                <span>{content.ENTITY}</span>
                                <span>{document.atmTransaction.entity}</span>
                            </li>
                            <li>
                                <span>{content.REFERENCE}</span>
                                <span>
                                    {String(document.atmTransaction.reference)
                                        .match(/.{1,3}/g)
                                        ?.join(' ')}
                                </span>
                            </li>
                            <li>
                                <span>{content.AMOUNT}</span>
                                <span>{document.atmTransaction.totalAmount}€</span>
                            </li>
                            <li className={document.status === 'overdue' ? styles.alert : ''}>
                                <span>{content.DUE_DATE}</span>
                                <span>{document.atmTransaction.dueDate}</span>
                            </li>
                        </ul>
                    </div>
                )}
        </div>
    );
};
