import { CardProfile } from 'components/elements';
import { useTheme } from 'hooks';
import { StaffData } from 'models/types';

import { useStaff } from '../../useStaff';
import styles from './SearchResults.module.scss';

export const SearchResults: React.FC = () => {
    const { activeTheme } = useTheme();
    const { hasSearchResults, searchedStaff } = useStaff();

    if (!hasSearchResults) return null;

    return (
        <section className={`${styles.list} ${styles.search} container`}>
            {searchedStaff?.map((t: StaffData) => (
                <CardProfile
                    key={t.id}
                    area="movement"
                    theme={activeTheme}
                    cardSize="standard"
                    price={Math.min(...t.services.map(({ price }) => price))}
                    name={t.name}
                    locations={t.locations}
                    platforms={t.platforms}
                    images={t.images}
                    tags={t.tags}
                    rating={t.rating}
                    slug={t.slug}
                    languages={t.languages}
                />
            ))}
        </section>
    );
};
