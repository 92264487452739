import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconInstagram: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                d="M3028.32,1160.32a4.83,4.83,0,0,0-4.15-3.34l-.15,0h-10.86a8.44,8.44,0,0,0-1.23.28,4.82,4.82,0,0,0-3.3,4.14,5.79,5.79,0,0,0,0,.72c0,3.27,0,6.55,0,9.82a4.84,4.84,0,0,0,2.55,4.4,4.58,4.58,0,0,0,2.29.63c3.43,0,6.87,0,10.31,0a4.92,4.92,0,0,0,4.81-4.4,1,1,0,0,1,0-.17v-10.86C3028.52,1161.11,3028.43,1160.72,3028.32,1160.32Zm-1.74,11.68a2.9,2.9,0,0,1-2.87,2.94h-.07c-3.37,0-6.73,0-10.1,0a2.93,2.93,0,0,1-2.95-2.91v-5.11c0-1.65,0-3.3,0-5a3,3,0,0,1,2.95-3h10.09a2.92,2.92,0,0,1,2.95,2.88v.06Q3026.61,1166.95,3026.58,1172Zm-8-10a5,5,0,1,0,5,5,5,5,0,0,0-4.89-5Zm0,8a3,3,0,1,1,3-3h0a3,3,0,0,1-2.92,3Zm5-9a1,1,0,0,0-1,1,1,1,0,0,0,1,1,1,1,0,0,0,1-1h0a1,1,0,0,0-1-1Z"
                transform="translate(-3008.59 -1156.95)"
            />
        </svg>
    );
};
