export const COLORS = {
    HI_BLUE: '#00a0df',
    HI_PURPLE: '#b11cab',

    BLACK: '#000',
    WHITE: '#fff',

    NEUTRAL_900: '#0c0c13',
    NEUTRAL_800: '#29292e', // previous `NEUTRAL_700 : #29292e`
    NEUTRAL_700: '#3b3e44',
    NEUTRAL_600: '#53575e',
    NEUTRAL_500: '#6a6f78',
    NEUTRAL_400: '#848992',
    NEUTRAL_300: '#a0a3aa',
    NEUTRAL_200: '#babdc2',
    NEUTRAL_100: '#dbdde1', // previous `NEUTRAL_400 : #dbdde1`
    NEUTRAL_50: '#f4f6fa', // previous `NEUTRAL_200 : #f4f6fa`

    GREEN_900: '#001717',
    GREEN_800: '#013d3c',
    GREEN_700: '#136563',
    GREEN_600: '#228c89',
    GREEN_500: '#32b3b0',
    GREEN_400: '#5ad1ce', // previous `AQUA_BLUE_200 : #5ad1ce`
    GREEN_300: '#70d8d6',
    GREEN_200: '#97e4e2', // previous `CRISTAL_BLUE_100 : #9fe4e1`
    GREEN_100: '#bbf0ee',
    GREEN_50: '#ddfcfc',

    BLUE_900: '#001021',
    BLUE_800: '#002e51',
    BLUE_700: '#004c82',
    BLUE_600: '#006bb4',
    BLUE_500: '#0089e6',
    BLUE_400: '#1aa3ff', // previous `OCEAN_BLUE_300 : #0199ff`
    BLUE_300: '#49b7ff',
    BLUE_200: '#7cccff', // previous `NORDIC_BLUE_200 : #80c1fc`
    BLUE_100: '#ace1ff',
    BLUE_50: '#d9f7ff',

    ROYAL_BLUE_900: '#040b17',
    ROYAL_BLUE_800: '#14233a', // previous `NIGHT_BLUE_900: #14233a`
    ROYAL_BLUE_700: '#23395d',
    ROYAL_BLUE_600: '#325082', // previous `OLD_NAVY_BLUE_800 : #4b5980`
    ROYAL_BLUE_500: '#4167a6',
    ROYAL_BLUE_400: '#5980bf',
    ROYAL_BLUE_300: '#7d9dcd',
    ROYAL_BLUE_200: '#a2b9db',
    ROYAL_BLUE_100: '#c6d5eb',
    ROYAL_BLUE_50: '#e9f1fd',

    BLUISH_PURPLE_900: '#1e0e30', // previous `PRIMARY_DARK : #1e0e30`
    BLUISH_PURPLE_800: '#281240', // previous `PRIMARY_SEMI_DARK : #281240`
    BLUISH_PURPLE_700: '#1e136e',
    BLUISH_PURPLE_600: '#2a1c98',
    BLUISH_PURPLE_500: '#3823c2',
    BLUISH_PURPLE_400: '#513ddc', // previous `PURPLE_MIST_500 : #5d4ade`
    BLUISH_PURPLE_300: '#7868e4',
    BLUISH_PURPLE_200: '#9e93ed', // previous `LA_VANDA_200 : #a18fe8`
    BLUISH_PURPLE_100: '#c5bdf8',
    BLUISH_PURPLE_50: '#ece8ff',

    PURPLE_900: '#0f001f',
    PURPLE_800: '#26004f',
    PURPLE_700: '#400081',
    PURPLE_600: '#5900b3', // previous `PRIMARY_MID : #5a00b4`
    PURPLE_500: '#7302e5',
    PURPLE_400: '#8c1bfe',
    PURPLE_300: '#a64dff', // previous `PRIMARY_LIGHT : #a766da`
    PURPLE_200: '#bf80ff',
    PURPLE_100: '#dab2ff',
    PURPLE_50: '#f5e3ff',

    MAGENTA_900: '#1b0115',
    MAGENTA_800: '#410a34',
    MAGENTA_700: '#691554',
    MAGENTA_600: '#931f75',
    MAGENTA_500: '#bc2a96', // previous `PINK_PANTHER_400 : #c62c9e`
    MAGENTA_400: '#d543af',
    MAGENTA_300: '#e06cc2', // previous `PINK_COCKTAIL_200 : #dc7ac3`
    MAGENTA_200: '#ea96d4',
    MAGENTA_100: '#f5bee6',
    MAGENTA_50: '#ffe7fb',

    RED_900: '#1f0000',
    RED_800: '#4e0000',
    RED_700: '#800200',
    RED_600: '#b20601',
    RED_500: '#e50e03',
    RED_400: '#fe221c',
    RED_300: '#fe504e',
    RED_200: '#ff8280', // previous `ALASKA_SALMON_300 : #ff8280`
    RED_200_A: 'rgb(255 130 128 / 60%)',
    RED_100: '#feb3b4', // previous `ICE_SALMON_100 : #feb3b4`
    RED_50: '#ffe3e2',

    YELLOW_900: '#1d1300',
    YELLOW_800: '#4d3800',
    YELLOW_700: '#805d00',
    YELLOW_600: '#b38204',
    YELLOW_500: '#e5a70c',
    YELLOW_400: '#fec021',
    YELLOW_300: '#ffce4f',
    YELLOW_200: '#ffde86', // previous `YELLOW_SAND_100 : #ffde86`
    YELLOW_100: '#ffeab0',
    YELLOW_50: '#fff8dd',

    GRADIENT_MOVEMENT:
        'radial-gradient(farthest-corner at 0% 100%, #1d3a4e, #1f3f53, #204557, #234a5c, #255060, #295969, #2c6272, #306c7b, #357d8a, #3c8e99, #439fa6, #4db1b3)',
    GRADIENT_NUTRITION:
        'radial-gradient(farthest-corner at 0% 100%, #2b2447, #30254b, #35254e, #3a2551, #402554, #49275a, #522860, #5c2966, #6c2b70, #7d2c7a, #8f2b82, #a22a8a)',
    GRADIENT_HEALTH:
        'radial-gradient(farthest-corner at 0% 100%, #1e2851, #202957, #222b5c, #242c62, #272d67, #2a3071, #2e327a, #323584, #393995, #403da6, #4941b7, #5344c8)',
    GRADIENT_MAIN:
        'radial-gradient( farthest-corner at 0% 100%, #b005ce, #9f1ccc, #8f27c9, #7e2dc5, #6d32c1, #544bce, #365ed6, #006edb, #008ee4, #00aae2, #4ac2dc, #8dd7da )'
};
