import { CardArticleV1, CardArticleV1Props } from 'components/elements';
import format from 'date-fns/format';
import ENLocale from 'date-fns/locale/en-GB';
import PTLocale from 'date-fns/locale/pt';
import { useApi } from 'hooks';
import { useCallback, useEffect, useState } from 'react';

import styles from './Articles.module.scss';

type ArticlesProps = {
    content: { TITLE: string };
};

export const Articles: React.FC<ArticlesProps> = ({ content }) => {
    const { getArticles } = useApi();
    const [recentArticles, setRecentArticles] = useState<CardArticleV1Props[]>(
        [] as CardArticleV1Props[]
    );

    const getArticlesData = useCallback(async () => {
        try {
            const articles = await getArticles();

            setRecentArticles(
                articles
                    .filter(({ highlight, is_published }) => highlight && is_published)
                    .map((a) => {
                        const renderSubtitle = (local: 'PT' | 'EN') => {
                            const publishDate = format(
                                new Date(a.publish_date.replace(' ', 'T')),
                                'd MMM',
                                { locale: local === 'PT' ? PTLocale : ENLocale }
                            );
                            return `${a.author.name} • ${publishDate}`;
                        };

                        return {
                            id: a.id,
                            heading: a.article.find(({ type }) => type === 'h1')?.content,
                            subtitle: {
                                pt: renderSubtitle('PT'),
                                en: renderSubtitle('EN')
                            },
                            description: {
                                pt: a.article.find(({ type }) => type === 'p')?.content?.pt || '',
                                en: a.article.find(({ type }) => type === 'p')?.content?.en || ''
                            },
                            image: a.article.find(({ type }) => type === 'image')?.image_content
                                ?.image,
                            image_2x: a.article.find(({ type }) => type === 'image')?.image_content
                                ?.image_2x,
                            area: a.area,
                            to: `/artigos/${a.slug}`,
                            type: 'small'
                        };
                    })
            );
        } catch (error) {
            // TO DO
            console.error(error);
        }
    }, [getArticles]);

    useEffect(() => {
        getArticlesData();
    }, [getArticlesData]);

    return (
        <section className={`${styles['recent-articles']} container`}>
            <h2>{content.TITLE}</h2>
            {recentArticles.map((article, i) => (
                <CardArticleV1 key={article.id?.toString() || i.toString()} {...article} />
            ))}
        </section>
    );
};
