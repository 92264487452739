import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconShareAlt: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                d="M703.8,436.43a2.19,2.19,0,0,0-2.13-2.24h-2a.75.75,0,0,0-.82.64v.09a.74.74,0,0,0,.74.74h1.94a.72.72,0,0,1,.79.65.28.28,0,0,1,0,.13v9.48a.71.71,0,0,1-.65.78h-11.1a.73.73,0,0,1-.79-.64v-9.62a.73.73,0,0,1,.67-.78h2a.74.74,0,1,0,.12-1.47h-1.92a2.2,2.2,0,0,0-2.35,2,1.4,1.4,0,0,0,0,.29v9.33a2.13,2.13,0,0,0,0,.5,2.17,2.17,0,0,0,2.21,1.82h11a2.18,2.18,0,0,0,2.26-2.08v-9.65m-10.48-3.74,1.81-1.8.22-.2v9.42a1.51,1.51,0,0,0,0,.3.71.71,0,0,0,.79.65.72.72,0,0,0,.65-.62,1.94,1.94,0,0,0,0-.34V430.7l.22.21c.6.6,1.19,1.19,1.8,1.78a.74.74,0,0,0,1,.05.73.73,0,0,0,0-1l0,0-3.29-3.27a.72.72,0,0,0-1,0l0,0-3.3,3.29a.67.67,0,0,0-.19.67.74.74,0,0,0,.88.55.76.76,0,0,0,.37-.21"
                transform="translate(-686.05 -428.18)"
            />
        </svg>
    );
};
