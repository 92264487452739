import { Main } from 'components/elements';
import { Header } from 'components/sections';
import { permissions } from 'config';
import { META_TAGS, POINTS } from 'content';
import { useAuth, useLanguage } from 'hooks';
import React, { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { CurrentPoints, SuggestedActivities, UserProgress } from './modules';

const NavBar = React.lazy(
    () =>
        import(
            /* webpackChunkName: "NavBar" */
            'components/elements/NavBar'
        )
);

export const Points: React.FC = () => {
    const { isPT } = useLanguage();

    const { validateUserAccessToArea } = useAuth();

    const CONTENT = isPT ? POINTS.PT : POINTS.EN;

    useEffect(
        () => validateUserAccessToArea(permissions.general.gamification),
        [validateUserAccessToArea]
    );

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? META_TAGS.PRIVATE.GENERAL.GAMIFICATION.PT.TITLE
                        : META_TAGS.PRIVATE.GENERAL.GAMIFICATION.EN.TITLE}
                </title>
            </Helmet>
            <Header backButton hasImage={false} title={CONTENT.HEADER.TITLE} />
            <Main>
                <CurrentPoints content={CONTENT.INTRO} />
                <UserProgress title={CONTENT.PROGRESS.TITLE} />
                <SuggestedActivities title={CONTENT.ACTIVITIES.TITLE} />
            </Main>
            <Suspense fallback={null}>
                <NavBar activeArea="hps" />
            </Suspense>
        </>
    );
};
