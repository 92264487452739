import { CardArticleV1 } from 'components/elements';
import { permissions } from 'config';
import * as IMAGES from 'constants/images';
import { ARTICLES_LIST, META_TAGS } from 'content';
import format from 'date-fns/format';
import ENLocale from 'date-fns/locale/en-GB';
import PTLocale from 'date-fns/locale/pt';
import { useApi, useAuth, useLanguage } from 'hooks';
import { ArticleData } from 'models/types';
import React, { Suspense, useCallback } from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { COLORS } from 'theme/colors';
import { IconArticleV1 } from 'theme/icons';

const NavBar = React.lazy(
    () =>
        import(
            /* webpackChunkName: "NavBar" */
            'components/elements/NavBar'
        )
);
const Header = React.lazy(
    () =>
        import(
            /* webpackChunkName: "Header" */
            'components/sections/Header'
        )
);

import styles from './ArticlesList.module.scss';

export const ArticlesList: React.FC = () => {
    const { isPT } = useLanguage();
    const { validateUserAccessToArea } = useAuth();
    const { getArticles } = useApi();

    const [articlesList, setArticlesList] = useState<ArticleData[] | null>();

    const CONTENT = isPT ? ARTICLES_LIST.PT : ARTICLES_LIST.EN;

    const getData = useCallback(async () => {
        const articles = await getArticles();
        setArticlesList(articles.filter(({ is_published }) => is_published || null));
    }, [getArticles]);

    useEffect(
        () => validateUserAccessToArea(permissions.articles.list),
        [validateUserAccessToArea]
    );

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? META_TAGS.PRIVATE.ARTICLES.LIST.PT.TITLE
                        : META_TAGS.PRIVATE.ARTICLES.LIST.EN.TITLE}
                </title>
            </Helmet>
            <Suspense fallback={<div style={{ marginBottom: '7.2rem', height: '26rem' }} />}>
                <Header
                    image={IMAGES.COVER_ARTICLES}
                    image_2x={IMAGES.COVER_ARTICLES_2X}
                    alt={CONTENT.HEADER.IMAGE_ALT}
                />
            </Suspense>
            <main className={styles.main}>
                <section className={`${styles.intro} container`}>
                    <h1>{CONTENT.INTRO.TITLE}</h1>
                    <IconArticleV1
                        colorType="SOLID"
                        variation="OUTLINE"
                        width={20}
                        height={24}
                        color={COLORS.WHITE}
                    />
                </section>
                <section className={`${styles['articles-list']} container`}>
                    {articlesList?.map((a) => {
                        const empty = {
                            pt: '',
                            en: ''
                        };

                        const heading = a.article.find(
                            ({ type }) => type === 'h1' || type === 'h2'
                        )?.content;

                        const subtitle = {
                            pt: `${a.author.name} ·  ${format(
                                new Date(a.publish_date.replace(' ', 'T')),
                                'd MMM yyyy',
                                {
                                    locale: PTLocale
                                }
                            )}}`,
                            en: `${a.author.name} ·  ${format(
                                new Date(a.publish_date.replace(' ', 'T')),
                                'd MMM yyyy',
                                {
                                    locale: ENLocale
                                }
                            )}}`
                        };

                        const description = a.article.find(({ type }) => type === 'p')?.content;

                        const image = a.article.find(({ type }) => type === 'image')?.image_content
                            ?.image as string;

                        const image_2x =
                            (a.article.find(({ type }) => type === 'image')?.image_content
                                ?.image_2x as string) ||
                            (a.article.find(({ type }) => type === 'image')?.image_content
                                ?.image as string);

                        return (
                            <CardArticleV1
                                key={a.id.toString()}
                                heading={heading || empty}
                                subtitle={subtitle}
                                description={description || empty}
                                image={image}
                                image_2x={image_2x}
                                area={a.area}
                                to={a.slug}
                                data-id={a.id}
                            />
                        );
                    })}
                </section>
            </main>
            <Suspense fallback={null}>
                <NavBar activeArea="articles" />
            </Suspense>
        </>
    );
};
