import { ButtonToggle } from 'components/elements';
import { permissions } from 'config';
import { PREFERENCES } from 'content';
import { useAuth, useLanguage, useTheme } from 'hooks';
import { useEffect } from 'react';

import styles from '../Preferences.module.scss';

export const SelectTheme: React.FC = () => {
    const { darkMode, activeTheme, toggleTheme } = useTheme();
    const { isPT } = useLanguage();
    const { validateUserAccessToArea } = useAuth();

    const CONTENT = isPT ? PREFERENCES.PT.THEME : PREFERENCES.EN.THEME;

    useEffect(
        () => validateUserAccessToArea(permissions.general.preferences),
        [validateUserAccessToArea]
    );

    return (
        <section className={`${styles.section} container`}>
            <h1>{CONTENT.INTRO.TITLE}</h1>
            <p>{CONTENT.INTRO.DESCRIPTION}</p>
            <div className={`${styles.card} ${styles[activeTheme]} ${styles.toggle}`}>
                <p className={styles.label}>
                    {darkMode ? CONTENT.LABELS.DARK : CONTENT.LABELS.LIGHT}
                </p>
                <ButtonToggle handleToggle={toggleTheme} isActive={darkMode} />
            </div>
        </section>
    );
};
