import { useOnScreen, useTheme } from 'hooks';
import { AreaType } from 'models/area';
import React, { useMemo, useRef } from 'react';
import CountUp from 'react-countup';
import { COLORS } from 'theme';

import styles from './ScoreBadge.module.scss';

export type ScoreBadgeProps = React.HTMLAttributes<HTMLDivElement> & {
    points: number;
    area: AreaType;
};

export const ScoreBadge: React.FC<ScoreBadgeProps> = ({
    points,
    area,
    className = '',
    ...props
}: ScoreBadgeProps) => {
    const { darkMode } = useTheme();

    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);

    const badgeColor = useMemo(() => {
        switch (area) {
            case 'movement':
                return COLORS.BLUE_400;
            case 'nutrition':
                return COLORS.MAGENTA_400;
            case 'health':
                return COLORS.PURPLE_500;

            default:
                return COLORS.NEUTRAL_500;
        }
    }, [area]);

    return (
        <div className={[styles.badge, className].join(' ')} {...props}>
            <div ref={ref} className={styles.wrapper}>
                {isVisible && (
                    <CountUp
                        start={0}
                        end={points}
                        duration={2}
                        delay={0}
                        className={styles.points}
                    />
                )}
                <span className={styles.label}>pontos</span>
            </div>
            <svg
                width="66"
                height="76"
                viewBox="0 0 66 76"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g filter={darkMode ? 'url(#dark-shadow-filter)' : 'url(#light-shadow-filter)'}>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4 7.99999C4 3.58172 7.58172 0 12 0H54C58.4183 0 62 3.58172 62 8V49.6424C62 52.809 60.1322 55.6776 57.2362 56.9586L36.4228 66.1649C33.7863 67.3311 30.7748 67.3028 28.1608 66.0871L8.62661 57.003C5.80475 55.6907 4 52.861 4 49.749V7.99999Z"
                        fill={badgeColor}
                    />
                </g>
                <defs>
                    <filter
                        id="dark-shadow-filter"
                        x="0"
                        y="0"
                        width="66"
                        height="76"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect_dropShadow_dark"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect_dropShadow_dark"
                            result="shape"
                        />
                    </filter>
                    <filter
                        id="light-shadow-filter"
                        x="0"
                        y="0"
                        width="66"
                        height="76"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect_dropShadow_light"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect_dropShadow_light"
                            result="shape"
                        />
                    </filter>
                </defs>
            </svg>
        </div>
    );
};
