import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconMail: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 21,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 21 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path d="M20.5 0h-.3l-.2.1L.5 10.2a.7.7 0 0 0-.5.7.7.7 0 0 0 .6.6c1.6.5 3.2 1 4.8 1.7a.8.8 0 0 0 1-.2l10.5-9 .4-.3-8.8 10.5h.1l9 3a.8.8 0 0 0 .4 0c.3 0 .5-.2.6-.6L19 14l.6-4 .5-4 .6-4L21 .7V.3a.8.8 0 0 0-.4-.3ZM7.7 15.6v3.6a2 2 0 0 0 0 .3.6.6 0 0 0 .4.5.6.6 0 0 0 .8-.3l1.8-2.4.5-.8-3.6-1.2Z" />
        </svg>
    );
};
