export const MOVEMENT_COACHES_PROFILE = {
    PT: {
        PRE_TITLE: 'Perfil',
        QUALIFICATIONS: 'Qualificações',
        TAGS: {
            TITLE: 'Especialidades'
        },
        SCHEDULE: {
            TITLE: 'Agendar'
        },
        CONTACTS: {
            TITLE: 'Contactos'
        },
        COMMENTS: {
            TITLE: 'Opiniões'
        }
    },
    EN: {
        PRE_TITLE: 'Profile',
        QUALIFICATIONS: 'Qualifications',
        TAGS: {
            TITLE: 'Specialties'
        },
        SCHEDULE: {
            TITLE: 'Schedule'
        },
        CONTACTS: {
            TITLE: 'Contact'
        },
        COMMENTS: {
            TITLE: 'Reviews'
        }
    }
};
