import * as IMAGES from 'constants/images';
import { HEALTH_LAYOUT } from 'content';
import { useLanguage } from 'hooks';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router';

const NavBar = React.lazy(
    () =>
        import(
            /* webpackChunkName: "NavBar" */
            'components/elements/NavBar'
        )
);
const Header = React.lazy(
    () =>
        import(
            /* webpackChunkName: "Header" */
            'components/sections/Header'
        )
);

const Layout: React.FC = () => {
    const { isPT } = useLanguage();
    const CONTENT = isPT ? HEALTH_LAYOUT.PT : HEALTH_LAYOUT.EN;

    return (
        <>
            <Suspense fallback={<div style={{ marginBottom: '7.2rem', height: '26rem' }} />}>
                <Header
                    image={IMAGES.COVER_HEALTH}
                    image_2x={IMAGES.COVER_HEALTH_2X}
                    alt={CONTENT.IMAGE_ALT}
                />
            </Suspense>
            <main>
                <Outlet />
            </main>
            <Suspense fallback={null}>
                <NavBar activeArea="health" />
            </Suspense>
        </>
    );
};

export default Layout;
