import { useTheme } from 'hooks';
import { ButtonHTMLAttributes, DetailedHTMLProps, useCallback, useEffect, useState } from 'react';

import styles from './ButtonToggle.module.scss';

export type ButtonToggleProps = DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> & {
    handleToggle: () => void;
    isActive?: boolean;
};

export const ButtonToggle: React.FC<ButtonToggleProps> = ({
    handleToggle,
    isActive = false,
    ...props
}) => {
    const { activeTheme } = useTheme();
    const [selected, setSelected] = useState(false);

    useEffect(() => setSelected(isActive), [isActive]);

    const toggleSelected = useCallback(() => {
        setSelected(!selected);
        handleToggle();
    }, [handleToggle, selected]);

    return (
        <button
            onClick={toggleSelected}
            className={`${styles['toggle-container']} ${selected ? styles.selected : ''} ${
                styles[activeTheme]
            }`}
            {...props}
        />
    );
};
