import { ErrorButton, ErrorMessage } from 'components/elements';
import { useTheme } from 'hooks';
import { useMemo, useState } from 'react';
import { stringToSlug } from 'utils';

import { InputProps } from '../types';
import styles from './Input.module.scss';

export const Input: React.FC<InputProps> = ({
    label,
    hasError = false,
    errorMessage = null,
    className = '',
    id,
    ...props
}) => {
    const { activeTheme } = useTheme();
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const slug = stringToSlug(label);
    const toggleMessage = () => {
        setShowErrorMessage(!showErrorMessage);
    };

    const errorClassName = useMemo(() => (hasError ? styles.error : ''), [hasError]);
    const containerClassName = useMemo(
        () => `${styles.container} ${errorClassName} ${styles[activeTheme]} ${className}`,
        [activeTheme, className, errorClassName]
    );

    return (
        <div className={containerClassName}>
            <input name={id ?? slug} id={id ?? slug} placeholder={' '} {...props} />
            <label htmlFor={id ?? slug}>{label}</label>
            {hasError && (
                <ErrorButton hasError handleErrorMessage={toggleMessage} position="CENTER-RIGHT" />
            )}
            {showErrorMessage && <ErrorMessage message={errorMessage} />}
        </div>
    );
};
