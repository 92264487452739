import { PWAPromptDialogProvider } from './modules/usePWAPromptDialog';
import { ApiProvider } from './useApi';
import { AuthProvider } from './useAuth';
import { GamificationProvider } from './useGamification';
import { LanguageProvider } from './useLang';
import { ScrollProvider } from './useScroll';
import { ThemeProvider } from './useTheme';
import { WebShareProvider } from './useWebShare';

export const AppProvider: React.FC = ({ children }) => (
    <LanguageProvider>
        <ThemeProvider>
            <ScrollProvider>
                <AuthProvider>
                    <GamificationProvider>
                        <ApiProvider>
                            <PWAPromptDialogProvider>
                                <WebShareProvider>{children}</WebShareProvider>
                            </PWAPromptDialogProvider>
                        </ApiProvider>
                    </GamificationProvider>
                </AuthProvider>
            </ScrollProvider>
        </ThemeProvider>
    </LanguageProvider>
);
