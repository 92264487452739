import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconExternal: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            style={{ fill: color, fillRule: 'evenodd' }}
            {...props}>
            <path d="M2.24 20A2.24 2.24 0 0 1 0 17.77V5.61a2.24 2.24 0 0 1 2.24-2.23h7.58a.65.65 0 1 1 0 1.29H2.24a1 1 0 0 0-.94.94v12.16a1 1 0 0 0 .94.94h12.21a1 1 0 0 0 .94-.94v-7.52a.65.65 0 1 1 1.3 0v7.52A2.24 2.24 0 0 1 14.45 20Zm6.19-7.73a.65.65 0 0 1-.65-.64.66.66 0 0 1 .22-.46l9.92-9.88h-5.16a.65.65 0 1 1 0-1.29h6.59a.65.65 0 0 1 .65.65v6.56a.65.65 0 0 1-1.3 0v-4.9l-9.81 9.77a.62.62 0 0 1-.46.19Z" />
        </svg>
    );
};
