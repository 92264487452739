import { LogoHi } from 'assets/logos';
import * as IMAGES from 'constants/images';
import format from 'date-fns/format';
import PTLocale from 'date-fns/locale/pt';
import { MembershipPlan } from 'models/user';
import { useEffect, useState } from 'react';

import styles from './CardMember.module.scss';

export type CardMemberProps = {
    name: string;
    cardNumber: string | null;
    membershipPlan: MembershipPlan;
    membershipDate: string;
};

export const CardMember: React.FC<CardMemberProps> = ({
    name,
    cardNumber,
    membershipPlan,
    membershipDate
}) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [flipPreview, setFlipPreview] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState('');

    useEffect(() => {
        setTimeout(() => {
            setFlipPreview(true);
        }, 500);
        setTimeout(() => {
            setFlipPreview(false);
        }, 1500);
    }, []);

    useEffect(() => {
        setBackgroundImage(
            membershipPlan === 'SAFE'
                ? `url(${IMAGES.MEMBER_CARD_SAFE})`
                : membershipPlan === 'SECURE'
                ? `url(${IMAGES.MEMBER_CARD_SECURE})`
                : `url(${IMAGES.MEMBER_CARD_STRONG})`
        );
    }, [membershipPlan]);

    return (
        <button
            className={`
            ${styles.card}
            ${isFlipped ? styles.flipped : ''}
            ${flipPreview ? styles.preview : ''}`.trim()}
            onClick={() => setIsFlipped(!isFlipped)}>
            <span className={`${styles.cardFront} ${styles.cardFace}`} style={{ backgroundImage }}>
                <span className={styles.header}>
                    <LogoHi showLettering={false} width={60} />
                    <span className={styles.plan}>{membershipPlan}</span>
                </span>
                <span className={styles.body}>
                    <span className={styles.number}>{cardNumber?.replace(/-/gi, ' ')}</span>
                </span>
                <span className={styles.footer}>
                    <span className={styles.name}>{name}</span>
                    <span className={styles.date}>
                        {format(new Date(membershipDate), 'MM/yy', {
                            locale: PTLocale
                        })}
                    </span>
                </span>
            </span>

            <span className={`${styles.cardBack} ${styles.cardFace}`} style={{ backgroundImage }}>
                <LogoHi width={180} />
            </span>
        </button>
    );
};
