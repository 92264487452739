// AREA BUTTONS
export const MOVEMENT_BUTTON_BG = '/images/areas-buttons/hi-app-007-454802119.jpg';
export const NUTRITION_BUTTON_BG = '/images/areas-buttons/hi-app-008-1007846011.jpg';
export const HEALTH_BUTTON_BG = '/images/areas-buttons/hi-app-009-699420829.jpg';
export const ARTICLES_BUTTON_BG = '/images/areas-buttons/hi-app-010-1322944970.jpg';

// COVERS
export const COVER_MAIN = '/images/covers/hi-app-006-485695564.jpg';
export const COVER_MAIN_2X = '/images/covers/hi-app-006-485695564@2x.jpg';
export const COVER_NUTRITION = '/images/covers/hi-app-017-1007846011.jpg';
export const COVER_NUTRITION_2X = '/images/covers/hi-app-017-1007846011@2x.jpg';
export const COVER_MOVEMENT = '/images/covers/hi-app-021-454802119.jpg';
export const COVER_MOVEMENT_2X = '/images/covers/hi-app-021-454802119@2x.jpg';
export const COVER_HEALTH = '/images/covers/hi-app-023-699420829.jpg';
export const COVER_HEALTH_2X = '/images/covers/hi-app-023-699420829@2x.jpg';
export const COVER_ARTICLES = '/images/covers/hi-app-026-121866054.jpg';
export const COVER_ARTICLES_2X = '/images/covers/hi-app-026-121866054@2x.jpg';

// LOGOS
export const LOGO_APPLE_FITNESS = '/images/logos/logo-apple-fitness.svg';
export const LOGO_GOOGLE_FIT = '/images/logos/logo-google-fit.svg';
export const ICON_HI_180 = '/images/logos/icon-hi-180x180.png';

// MEMBER CARD
export const MEMBER_CARD_SAFE = '/images/member-card/card-back-safe.jpg';
export const MEMBER_CARD_SECURE = '/images/member-card/card-back-secure.jpg';
export const MEMBER_CARD_STRONG = '/images/member-card/card-back-strong.jpg';

// ILLUSTRATIONS
export const ILLUSTRATION_1_MOVEMENT = '/illustrations/slide-1-movement.svg';
export const ILLUSTRATION_2_NUTRITION = '/illustrations/slide-2-nutrition.svg';
export const ILLUSTRATION_3_HEALTH = '/illustrations/slide-3-health.svg';
export const ILLUSTRATION_4_FLAG = '/illustrations/slide-4-flag.svg';
export const ILLUSTRATION_5_WAVING = '/illustrations/slide-5-waving.svg';
export const ILLUSTRATION_6_FIREWORKS = '/illustrations/slide-6-fireworks.svg';

// ANIMATIONS
export const ANIMATION_HEART = '/images/animations/heart.gif';
export const ANIMATION_LIKES = '/images/animations/likes.gif';
export const ANIMATION_TROPHY_1 = '/images/animations/trophy-1.gif';
export const ANIMATION_TROPHY_2 = '/images/animations/trophy-2.gif';

// SCREENSHOTS
export const SCREENSHOT_1 = '/images/screenshots/1.jpg';
export const SCREENSHOT_2 = '/images/screenshots/2.jpg';
export const SCREENSHOT_3 = '/images/screenshots/3.jpg';
export const SCREENSHOT_4 = '/images/screenshots/4.jpg';
export const SCREENSHOT_5 = '/images/screenshots/5.jpg';
export const SCREENSHOT_6 = '/images/screenshots/6.jpg';
export const SCREENSHOT_7 = '/images/screenshots/7.jpg';

// OTHERS
export const VIDEO_PLACEHOLDER = '/images/video-placeholder.svg';
export const HI_SYMBOL_STICKER = '/images/other/share-icon-sticker.svg';
export const HI_SYMBOL = '/images/other/hi-symbol.webp';
