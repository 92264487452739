import { permissions } from 'config';
import { useAuth, useTheme, useWebShare } from 'hooks';
import { lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { IconShareAlt } from 'theme/icons';

import styles from './ManualLog.module.scss';

const ActivityTracker = lazy(
    () =>
        import(
            /* webpackChunkName: "ActivityTracker" */
            'components/elements/Activity/Tracker'
        )
);

type ManualLogProps = {
    title: string;
    description: string;
};

export const ManualLog: React.FC<ManualLogProps> = ({ title, description }) => {
    const { userCanAccess } = useAuth();
    const { canShare } = useWebShare();
    const { activeTheme } = useTheme();

    const canAccess = userCanAccess(permissions.general.share);

    return (
        <section className={`${styles['manual-log']} container`}>
            <div className={styles.header}>
                <div className={styles.wrapper}>
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
                {canShare && canAccess && (
                    <Link
                        to="/partilhar"
                        className={[styles['share-button'], styles[activeTheme]].join(' ')}>
                        <IconShareAlt />
                    </Link>
                )}
            </div>
            <Suspense fallback={<div className={styles['manual-log-loading']} />}>
                <ActivityTracker />
            </Suspense>
        </section>
    );
};
