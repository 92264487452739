import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IconSearch, IconX } from 'theme/icons';

import styles from './Search.module.scss';

export type SearchableContent = { id: string; data: string };

export type SearchProps = React.HTMLProps<HTMLInputElement> & {
    theme?: 'light' | 'dark';
    searchContent: SearchableContent[];
    setFilteredContent: React.Dispatch<React.SetStateAction<SearchableContent[]>>;
    setNotFound: React.Dispatch<React.SetStateAction<boolean>>;
    onInputFocus?: () => void;
    onInputBlur?: () => void;
    resetSearch?: boolean;
};

export const Search: React.FC<SearchProps> = ({
    theme = 'dark',
    setFilteredContent,
    setNotFound,
    searchContent,
    onInputFocus,
    onInputBlur,
    resetSearch,
    ...props
}) => {
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const format = useCallback(
        (value: string) =>
            value
                .toLocaleLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, ''),
        []
    );

    const onFocus = useCallback(() => {
        setNotFound(false);
        if (onInputFocus) onInputFocus();
    }, [onInputFocus, setNotFound]);

    const onBlur = useCallback(() => {
        if (onInputBlur) onInputBlur();
    }, [onInputBlur]);

    const reset = useCallback(() => {
        setInputValue('');
        setShowCloseButton(false);
        setNotFound(false);
        setFilteredContent([]);
    }, [setFilteredContent, setNotFound]);

    const onSearch = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(event.target.value);

            setNotFound(false);

            setShowCloseButton(true);

            if (event.target.value === '') {
                reset();
                return;
            }

            const results =
                searchContent.filter(({ data }) =>
                    format(data).includes(format(event.target.value))
                ) || [];

            if (results.length === 0) {
                setNotFound(true);
                setFilteredContent([]);
                return;
            }

            setFilteredContent(results);
        },
        [format, reset, searchContent, setFilteredContent, setNotFound]
    );

    useEffect(() => (resetSearch ? reset() : undefined), [reset, resetSearch]);

    const className = useMemo(() => `${styles.container} ${styles[theme]} `, [theme]);

    return (
        <div className={className}>
            <input
                ref={searchInputRef}
                type="search"
                name="search"
                id="search"
                value={inputValue}
                onChange={onSearch}
                onFocus={onFocus}
                onBlur={onBlur}
                {...props}
            />
            {showCloseButton ? (
                <button className={styles['close-button']} onClick={reset}>
                    <IconX />
                </button>
            ) : (
                <IconSearch />
            )}
        </div>
    );
};
