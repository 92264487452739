import { NUTRITION_ASSESSMENT_STEPS as FORM_STEPS } from 'content';
import { round } from 'utils';

export type BMIScore = {
    label: {
        PT: string;
        EN: string;
    };
    score: 'bad' | 'good' | 'neutral';
    value: number;
};

type CalculateBMI = ({ weight, height }: { weight: number; height: number }) => BMIScore;

export const calculateBMI: CalculateBMI = ({ weight, height }) => {
    const BMI = round(weight / ((height / 100) * (height / 100)), 2, 2);

    if (BMI < 18.5)
        return {
            label: {
                PT: FORM_STEPS.PT.IMC.BMI.THINNESS,
                EN: FORM_STEPS.EN.IMC.BMI.THINNESS
            },
            score: 'bad',
            value: BMI
        };
    if (BMI < 24.9)
        return {
            label: {
                PT: FORM_STEPS.PT.IMC.BMI.NORMAL,
                EN: FORM_STEPS.EN.IMC.BMI.NORMAL
            },
            score: 'good',
            value: BMI
        };
    if (BMI < 30)
        return {
            label: {
                PT: FORM_STEPS.PT.IMC.BMI.OVERWEIGHT,
                EN: FORM_STEPS.EN.IMC.BMI.OVERWEIGHT
            },
            score: 'bad',
            value: BMI
        };
    return {
        label: {
            PT: FORM_STEPS.PT.IMC.BMI.OBESITY,
            EN: FORM_STEPS.EN.IMC.BMI.OBESITY
        },
        score: 'bad',
        value: BMI
    };
};
