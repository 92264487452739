import { useTheme } from 'hooks';
import React from 'react';
import { ButtonHTMLAttributes, DetailedHTMLProps, useCallback } from 'react';
import {
    IconArrow,
    IconArticleV1,
    IconAvatar,
    IconBurger,
    IconCheck,
    IconDots,
    IconFacebook,
    IconHealthV1,
    IconHomeV1,
    IconInstagram,
    IconMail,
    IconMoon,
    IconMovementV1,
    IconNutritionV1,
    IconPhone,
    IconPlus,
    IconReset,
    IconSearch,
    IconShare,
    IconShareAlt,
    IconSkype,
    IconStar,
    IconSun,
    IconTwitter,
    IconWhatsapp,
    IconX
} from 'theme/icons';

import styles from './ButtonIcon.module.scss';

export type ButtonIconProps = DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> & {
    icon:
        | 'ARROW_LEFT'
        | 'ARROW_RIGHT'
        | 'ARROW_TOP'
        | 'ARROW_BOTTOM'
        | 'BURGER'
        | 'DOTS'
        | 'HOME'
        | 'PLUS'
        | 'RESET'
        | 'ARTICLES'
        | 'CHECK'
        | 'AVATAR'
        | 'FACEBOOK'
        | 'HEALTH'
        | 'MOVEMENT'
        | 'NUTRITION'
        | 'INSTAGRAM'
        | 'MAIL'
        | 'MOON'
        | 'PHONE'
        | 'SHARE'
        | 'SHARE-ALT'
        | 'SKYPE'
        | 'TWITTER'
        | 'WHATSAPP'
        | 'SEARCH'
        | 'STAR'
        | 'SUN'
        | 'X';
    size: 'XS' | 'MD' | 'LG';
    backgroundOpacity?: 'OPAQUE' | 'TRANSPARENT' | 'TRANSLUCID';
    backgroundColor?: string;
    className?: string;
};

export const ButtonIcon: React.FC<ButtonIconProps> = ({
    icon,
    size,
    backgroundOpacity = 'OPAQUE',
    backgroundColor,
    className = '',
    ...props
}) => {
    const { activeTheme } = useTheme();

    const renderButton = useCallback(() => {
        switch (icon) {
            case 'ARROW_LEFT':
                return <IconArrow className={styles.left} />;
            case 'ARROW_RIGHT':
                return <IconArrow className={styles.right} />;
            case 'ARROW_TOP':
                return <IconArrow />;
            case 'ARROW_BOTTOM':
                return <IconArrow className={styles.bottom} />;
            case 'BURGER':
                return <IconBurger />;
            case 'DOTS':
                return <IconDots />;
            case 'PLUS':
                return <IconPlus />;
            case 'RESET':
                return <IconReset />;
            case 'CHECK':
                return <IconCheck />;
            case 'AVATAR':
                return <IconAvatar />;
            case 'FACEBOOK':
                return <IconFacebook />;
            case 'INSTAGRAM':
                return <IconInstagram />;
            case 'PHONE':
                return <IconPhone />;
            case 'MAIL':
                return <IconMail />;
            case 'MOON':
                return <IconMoon />;
            case 'SHARE':
                return <IconShare />;
            case 'SHARE-ALT':
                return <IconShareAlt />;
            case 'SKYPE':
                return <IconSkype />;
            case 'TWITTER':
                return <IconTwitter />;
            case 'WHATSAPP':
                return <IconWhatsapp />;
            case 'SEARCH':
                return <IconSearch />;
            case 'STAR':
                return <IconStar />;
            case 'SUN':
                return <IconSun />;
            case 'X':
                return <IconX />;
            case 'HOME':
                return <IconHomeV1 colorType="SOLID" variation="FILL" />;
            case 'ARTICLES':
                return <IconArticleV1 colorType="SOLID" variation="FILL" />;
            case 'HEALTH':
                return <IconHealthV1 colorType="SOLID" variation="FILL" />;
            case 'MOVEMENT':
                return <IconMovementV1 colorType="SOLID" variation="FILL" />;
            case 'NUTRITION':
                return <IconNutritionV1 colorType="SOLID" variation="FILL" />;

            default:
                return null;
        }
    }, [icon]);

    const xsClassName = size === 'XS' ? styles.xs : '';
    const mdClassName = size === 'MD' ? styles.md : '';
    const lgClassName = size === 'LG' ? styles.lg : '';
    const translucidClassName = backgroundOpacity === 'TRANSLUCID' ? styles.translucid : '';
    const transparentClassName = backgroundOpacity === 'TRANSPARENT' ? styles.transparent : '';
    const buttonClassName = [
        styles.button,
        xsClassName,
        mdClassName,
        lgClassName,
        translucidClassName,
        transparentClassName,
        styles[activeTheme],
        className
    ].join(' ');

    return (
        <button
            className={buttonClassName}
            style={backgroundColor ? { backgroundColor } : undefined}
            {...props}>
            {renderButton()}
        </button>
    );
};
