import { useTheme } from 'hooks';
import { useMemo } from 'react';
import { COLORS } from 'theme/colors';
import { IconAreaV2Props } from 'theme/icons/types';

export const IconPromo: React.FC<IconAreaV2Props> = ({
    width = 30,
    height = 30,
    isSelected,
    ...props
}) => {
    const { darkMode } = useTheme();

    const defaultColor = useMemo(
        () => ({
            fill: darkMode ? COLORS.BLUISH_PURPLE_800 : COLORS.WHITE,
            stroke: darkMode ? COLORS.PURPLE_300 : COLORS.MAGENTA_200
        }),
        [darkMode]
    );

    const selectedColor = useMemo(
        () => ({
            fill: darkMode ? COLORS.BLUISH_PURPLE_800 : COLORS.WHITE,
            stroke: darkMode ? COLORS.WHITE : COLORS.PURPLE_500
        }),
        [darkMode]
    );

    const filter = useMemo(
        () => (darkMode ? 'url(#dark-shadow)' : 'url(#light-shadow)'),
        [darkMode]
    );

    return (
        <svg
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            {...props}>
            <g filter={filter}>
                <path
                    d="M20.6875 20.625C19.3436 20.625 18.25 19.5314 18.25 18.1875C18.25 16.8436 19.3436 15.75 20.6875 15.75C22.0314 15.75 23.125 16.8436 23.125 18.1875C23.125 19.5314 22.0314 20.625 20.6875 20.625ZM20.6875 17.375C20.2406 17.375 19.875 17.7406 19.875 18.1875C19.875 18.6344 20.2406 19 20.6875 19C21.1344 19 21.5 18.6344 21.5 18.1875C21.5 17.7406 21.1344 17.375 20.6875 17.375Z"
                    fill={isSelected ? selectedColor.stroke : defaultColor.stroke}
                />
                <path
                    d="M3.38038 18.2941L2.98315 18.3736V22.604H26.5782V6.99316H2.98315V11.2236L3.38038 11.303C4.16071 11.4909 4.85512 11.9359 5.35182 12.5663C5.84852 13.1968 6.11861 13.9761 6.11861 14.7787C6.11861 15.5813 5.84852 16.3606 5.35182 16.9911C4.85512 17.6216 4.16071 18.0666 3.38038 18.2544V18.2941Z"
                    fill={isSelected ? selectedColor.fill : defaultColor.fill}
                />
                <path
                    d="M8.5 9.25H10.125V10.875H8.5V9.25Z"
                    fill={isSelected ? selectedColor.stroke : defaultColor.stroke}
                />
                <path
                    d="M8.5 19H10.125V20.625H8.5V19Z"
                    fill={isSelected ? selectedColor.stroke : defaultColor.stroke}
                />
                <path
                    d="M8.5 15.75H10.125V17.375H8.5V15.75Z"
                    fill={isSelected ? selectedColor.stroke : defaultColor.stroke}
                />
                <path
                    d="M8.5 12.5H10.125V14.125H8.5V12.5Z"
                    fill={isSelected ? selectedColor.stroke : defaultColor.stroke}
                />
                <path
                    d="M26.375 23.875H3.625C2.71328 23.875 2 23.1617 2 22.25V18.1875C2 17.739 2.36399 17.375 2.8125 17.375C4.15638 17.375 5.25 16.2814 5.25 14.9375C5.25 13.5936 4.15638 12.5 2.8125 12.5C2.36399 12.5 2 12.136 2 11.6875V7.625C2 6.72963 2.72963 6 3.625 6H26.375C27.2867 6 28 6.71328 28 7.625V22.25C28 23.1617 27.2867 23.875 26.375 23.875ZM3.625 18.9188V22.25H26.375V7.625H3.625V10.9563C5.47741 11.3349 6.875 12.9745 6.875 14.9375C6.875 16.8989 5.47741 18.5417 3.625 18.9188Z"
                    fill={isSelected ? selectedColor.stroke : defaultColor.stroke}
                />
                <path
                    d="M15.8125 14.125C14.4686 14.125 13.375 13.0314 13.375 11.6875C13.375 10.3436 14.4686 9.25 15.8125 9.25C17.1564 9.25 18.25 10.3436 18.25 11.6875C18.25 13.0314 17.1564 14.125 15.8125 14.125ZM15.8125 10.875C15.3656 10.875 15 11.239 15 11.6875C15 12.136 15.3656 12.5 15.8125 12.5C16.2594 12.5 16.625 12.136 16.625 11.6875C16.625 11.239 16.2594 10.875 15.8125 10.875Z"
                    fill={isSelected ? selectedColor.stroke : defaultColor.stroke}
                />
                <path
                    d="M20.6875 20.625C19.3436 20.625 18.25 19.5314 18.25 18.1875C18.25 16.8436 19.3436 15.75 20.6875 15.75C22.0314 15.75 23.125 16.8436 23.125 18.1875C23.125 19.5314 22.0314 20.625 20.6875 20.625ZM20.6875 17.375C20.2406 17.375 19.875 17.7406 19.875 18.1875C19.875 18.6344 20.2406 19 20.6875 19C21.1344 19 21.5 18.6344 21.5 18.1875C21.5 17.7406 21.1344 17.375 20.6875 17.375Z"
                    fill={isSelected ? selectedColor.stroke : defaultColor.stroke}
                />
                <path
                    d="M14.1875 20.6248C14.004 20.6248 13.8202 20.5632 13.6675 20.4364C13.3229 20.1487 13.276 19.6369 13.5636 19.2923L21.6886 9.54234C21.9779 9.19775 22.4897 9.15568 22.8327 9.43842C23.1772 9.72606 23.2226 10.2379 22.9349 10.5841L14.8099 20.3341C14.6507 20.524 14.4198 20.6248 14.1875 20.6248L14.1875 20.6248Z"
                    fill={isSelected ? selectedColor.stroke : defaultColor.stroke}
                />
            </g>
            <defs>
                <filter
                    id="light-shadow"
                    x="0"
                    y="6"
                    width="30"
                    height="21.875"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_490_722"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_490_722"
                        result="shape"
                    />
                </filter>
                <filter
                    id="dark-shadow"
                    x="0"
                    y="6"
                    width="30"
                    height="21.875"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_490_722"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_490_722"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
