export const META_TAGS = {
    PUBLIC: {
        LOGIN: {
            PT: {
                TITLE: 'Acesso | Hi!'
            },
            EN: {
                TITLE: 'Login | Hi!'
            }
        },
        RECOVER: {
            PT: {
                TITLE: 'Recuperar | Hi!'
            },
            EN: {
                TITLE: 'Recover | Hi!'
            }
        },
        RESET: {
            PT: {
                TITLE: 'Redefinir | Hi!'
            },
            EN: {
                TITLE: 'Reset | Hi!'
            }
        },
        ACCOUNT_ACTIVATION: {
            PT: {
                TITLE: 'Ativar conta | Hi!'
            },
            EN: {
                TITLE: 'Activate account | Hi!'
            }
        },
        WELCOME: {
            PT: {
                TITLE: 'Bem-vindo | Hi!'
            },
            EN: {
                TITLE: 'Welcome | Hi!'
            }
        }
    },
    PRIVATE: {
        GENERAL: {
            HOME: {
                PT: {
                    TITLE: 'Início | Hi!'
                },
                EN: {
                    TITLE: 'Home | Hi!'
                }
            },
            ABOUT: {
                PT: {
                    TITLE: 'Acerca | Hi!'
                },
                EN: {
                    TITLE: 'About | Hi!'
                }
            },
            CLIENT_AREA: {
                PT: {
                    TITLE: 'Área Cliente | Hi!'
                },
                EN: {
                    TITLE: 'Client Area | Hi!'
                }
            },
            PREFERENCES: {
                PT: {
                    TITLE: 'Definições | Hi!'
                },
                EN: {
                    TITLE: 'Settings | Hi!'
                }
            },
            GAMIFICATION: {
                PT: {
                    TITLE: 'Pontos | Hi!'
                },
                EN: {
                    TITLE: 'Points | Hi!'
                }
            }
        },
        HEALTH: {
            APPOINTMENT: {
                PT: {
                    TITLE: 'Marcar Consulta | Hi!'
                },
                EN: {
                    TITLE: 'Make an appointment | Hi!'
                }
            },
            CHECKUP: {
                PT: {
                    TITLE: 'Checkup Médico | Hi!'
                },
                EN: {
                    TITLE: 'Medical Checkup | Hi!'
                }
            },
            HOME: {
                PT: {
                    TITLE: 'Saúde | Hi!'
                },
                EN: {
                    TITLE: 'Health| Hi!'
                }
            }
        },
        MOVEMENT: {
            COACHES_LIST: {
                PT: {
                    TITLE: 'Sport Coaches | Hi!'
                },
                EN: {
                    TITLE: 'Sport Coaches | Hi!'
                }
            },
            COACH_PROFILE: {
                PT: {
                    TITLE: 'Coach | Hi!' // Concat with coach name
                },
                EN: {
                    TITLE: 'Coach | Hi!' // Concat with coach name
                }
            },
            EXERCISE: {
                PT: {
                    TITLE: 'Treino | Hi!'
                },
                EN: {
                    TITLE: 'Workout | Hi!'
                }
            },
            GURU: {
                PT: {
                    TITLE: 'Guru de Fitness | Hi!'
                },
                EN: {
                    TITLE: 'Fit Guru | Hi!'
                }
            },
            HOME: {
                PT: {
                    TITLE: 'Movimento | Hi!'
                },
                EN: {
                    TITLE: 'Movement | Hi!'
                }
            },
            ON_DEMAND_LIST: {
                PT: {
                    TITLE: 'Aulas On-Demand | Hi!'
                },
                EN: {
                    TITLE: 'On-Demand classes | Hi!'
                }
            },
            ON_DEMAND_DETAIL: {
                PT: {
                    TITLE: '| Hi!' // Concat with class name
                },
                EN: {
                    TITLE: '| Hi!' // Concat with class name
                }
            }
        },
        NUTRITION: {
            GURU: {
                PT: {
                    TITLE: 'Guru de Nutrição | Hi!'
                },
                EN: {
                    TITLE: 'Nutri Guru | Hi!'
                }
            },
            HOME: {
                PT: {
                    TITLE: 'Nutrição | Hi!'
                },
                EN: {
                    TITLE: 'Nutrition | Hi!'
                }
            },
            NUTRITIONISTS_LIST: {
                PT: {
                    TITLE: 'Nutricionistas | Hi!'
                },
                EN: {
                    TITLE: 'Nutritionists | Hi!'
                }
            },
            NUTRITIONIST_PROFILE: {
                PT: {
                    TITLE: 'Nutricionista | Hi!' // Concat with coach name
                },
                EN: {
                    TITLE: 'Nutritionist | Hi!' // Concat with coach name
                }
            },
            WELCOME_PACK_SURVEY: {
                PT: {
                    TITLE: 'Questionário | Hi!'
                },
                EN: {
                    TITLE: 'Survey | Hi!'
                }
            }
        },
        ARTICLES: {
            LIST: {
                PT: {
                    TITLE: 'Artigos | Hi!'
                },
                EN: {
                    TITLE: 'Articles | Hi!'
                }
            },
            ARTICLE: {
                PT: {
                    TITLE: '| Hi!' // Concat with article title
                },
                EN: {
                    TITLE: '| Hi!' // Concat with article title
                }
            }
        },
        PROMOS: {
            LIST: {
                PT: {
                    TITLE: 'Promos | Hi!'
                },
                EN: {
                    TITLE: 'Promos | Hi!'
                }
            },
            PARTNER: {
                PT: {
                    TITLE: '| Hi!' // Concat with partner name
                },
                EN: {
                    TITLE: '| Hi!' // Concat with partner name
                }
            },
            PROMO: {
                PT: {
                    TITLE: '| Hi!' // Concat with promo title
                },
                EN: {
                    TITLE: '| Hi!' // Concat with promo title
                }
            }
        }
    }
};
