import { IconCamera } from 'theme/icons';

import { InputFileProps } from '../types';
import styles from './InputFile.module.scss';

export const InputFile: React.FC<InputFileProps> = ({
    icon,
    inputLabel,
    className = '',
    ...props
}) => (
    <div className={`${styles['input-container']} ${className}`}>
        <div className={styles.label}>
            {icon === 'CAMERA' && <IconCamera width={16} height={16} />}
            {inputLabel}
        </div>
        <input type="file" className={`${styles['input-file']}`} {...props} />
    </div>
);
