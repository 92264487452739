/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { useTheme } from 'hooks';
import { Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { COLORS } from 'theme/colors';
import { IconX } from 'theme/icons';
import { cj, hexToRgbA } from 'utils';

import styles from './Modal.module.scss';

type BtnProps = {
    label?: string; // default ''
    click: () => void;
    disabled?: boolean; // default false
    icon?: string; // font awesome class
    color?: 'danger' | 'secondary' | 'success' | 'warning' | 'info' | 'dark' | 'primary';
};

export type ModalProps = {
    show: boolean;
    header?: {
        enable?: boolean;
        buttons?: BtnProps[];
        title?: string;
        customTitle?: React.ReactNode; // html node (replace title)
        className?: string;
    };
    footer?: {
        enable: boolean;
        buttons?: BtnProps[];
    };
    close: {
        hideButton?: boolean;
        buttonColor?: string;
        handler: () => void;
    };
    overlay?: {
        style?: 'dark' | 'light';
        color?: string;
        cancelOnClick?: boolean;
    };
    maxWidth?: number;
    background?: { color?: string };
    className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Modal: React.FC<ModalProps> = (props) => {
    const { activeTheme } = useTheme();
    const modalWrapper = useRef(null);

    const shortcutsHandler = useCallback(
        (evt) => {
            if (evt.key === 'Escape' && props.close.handler && props.show) props.close.handler();
        },
        [props.close, props.show]
    );

    useEffect(() => {
        window.addEventListener('keydown', shortcutsHandler);
        return () => window.removeEventListener('keydown', shortcutsHandler);
    }, [shortcutsHandler]);

    const renderModalBtns = useCallback(
        (buttons: BtnProps[]) =>
            buttons.map((el, i) => {
                let buttonColorClass = styles['btn-primary'];

                switch (el.color) {
                    case 'danger':
                        buttonColorClass = styles['btn-danger'];
                        break;
                    case 'secondary':
                        buttonColorClass = styles['btn-secondary'];
                        break;
                    case 'success':
                        buttonColorClass = styles['btn-success'];
                        break;
                    case 'warning':
                        buttonColorClass = styles['btn-warning'];
                        break;
                    case 'info':
                        buttonColorClass = styles['btn-info'];
                        break;
                    case 'dark':
                        buttonColorClass = styles['btn-dark'];
                        break;
                    default:
                        break;
                }

                return (
                    <Fragment key={i.toString()}>
                        <button
                            className={`${styles['modal-btn']} ${buttonColorClass}`}
                            onClick={el.click}
                            disabled={el.disabled}>
                            <span className={styles['btn-content']}>{el.label}</span>
                        </button>
                    </Fragment>
                );
            }),
        []
    );

    const renderHeader = useCallback(() => {
        if (props.header?.enable === false) return;
        return (
            <div className={cj(styles['modal-header'], props.header?.className ?? '')}>
                <div>
                    {props.header?.customTitle ? (
                        props.header?.customTitle
                    ) : (
                        <h4 className={styles['modal-title']}>{props.header?.title}</h4>
                    )}
                    <div>
                        {props.header?.buttons && renderModalBtns(props.header?.buttons)}
                        {!props.close.hideButton && (
                            <button
                                className={styles['header-close-btn']}
                                onClick={props.close.handler}>
                                <IconX color={props.close.buttonColor} />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }, [
        props.header?.enable,
        props.header?.className,
        props.header?.customTitle,
        props.header?.title,
        props.header?.buttons,
        renderModalBtns,
        props.close.hideButton,
        props.close.handler,
        props.close.buttonColor
    ]);

    const renderFooter = useCallback(() => {
        if (props.footer?.enable === false) return;

        return (
            <div className={styles['modal-footer']}>
                {props.footer?.buttons && renderModalBtns(props.footer.buttons)}
            </div>
        );
    }, [props.footer?.buttons, props.footer?.enable, renderModalBtns]);

    const onClick = useCallback(
        (e) => {
            if (e.target !== modalWrapper.current || props.overlay?.cancelOnClick !== undefined)
                return;
            props.close.handler();
        },
        [props.close, props.overlay?.cancelOnClick]
    );

    const showClassName = useMemo(() => (!props.show ? styles.inactive : ''), [props.show]);
    const overlayClassName = useMemo(() => {
        if (!props.overlay?.style) return '';

        switch (props.overlay.style) {
            case 'dark':
                return styles['overlay-dark'];
            case 'light':
                return styles['overlay-light'];
            default:
                return '';
        }
    }, [props.overlay]);

    return (
        <div
            role="button"
            onClick={onClick}
            className={[
                styles['modal-window'],
                showClassName,
                styles[activeTheme],
                overlayClassName
            ].join(' ')}
            style={{ backgroundColor: hexToRgbA(props.overlay?.color, 0.6) }}>
            <div className={styles.wrapper} ref={modalWrapper}>
                <div
                    className={[styles.modal, props.show ? styles.animate : ''].join(' ')}
                    style={{
                        maxWidth: props.maxWidth ?? '60rem',
                        background: props.background?.color ?? COLORS.BLUISH_PURPLE_400
                    }}>
                    {renderHeader()}
                    <div className={[styles['modal-body'], props.className ?? ''].join(' ')}>
                        {props.show ? props.children : null}
                    </div>
                    {renderFooter()}
                </div>
            </div>
        </div>
    );
};
