import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconFacebook: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 10,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path d="M2.26 19.99v-9.9h-2.3V7.15h2.3V3.51a3.81 3.81 0 0 1 .36-1.76 3 3 0 0 1 2-1.58A5.5 5.5 0 0 1 5.83.01H9.65v3.66H7.44a.92.92 0 0 0-1 .8 2 2 0 0 0-.07.54v2.11h3.58c0 .08 0 .14-.05.2L9.14 9.9a.22.22 0 0 1-.23.18H6.37v9.74a1.11 1.11 0 0 0 0 .17Z" />
        </svg>
    );
};
