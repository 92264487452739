import React, { useCallback, useMemo } from 'react';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { HiRefresh } from 'react-icons/hi';
import {
    IconArticleV1,
    IconAvatar,
    IconCamera,
    IconFacebook,
    IconHealthV1,
    IconHomeV1,
    IconInstagram,
    IconMail,
    IconMovementV1,
    IconNutritionV1,
    IconPhone,
    IconPlus,
    IconSearch,
    IconShare,
    IconShareAlt,
    IconX
} from 'theme/icons';

import styles from './Button.module.scss';

type Icon =
    | 'none'
    | 'camera'
    | 'phone'
    | 'facebook'
    | 'instagram'
    | 'mail'
    | 'plus'
    | 'share'
    | 'shareAlt'
    | 'search'
    | 'avatar'
    | 'health'
    | 'movement'
    | 'home'
    | 'nutrition'
    | 'articles'
    | 'x';

export type ButtonProps = DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> & {
    label: string;
    size: 'lg' | 'md';
    icon?: {
        type: Icon;
        position?: 'left' | 'right';
    };
    area?: 'nutrition' | 'health' | 'movement' | 'default';
    fullWidth?: boolean;
    className?: string;
    variation?: 'solid' | 'outline';
    loading?: boolean;
};

export const Button: React.FC<ButtonProps> = ({
    label,
    size,
    icon,
    fullWidth = false,
    area,
    variation,
    loading = false,
    className = '',
    ...props
}) => {
    const renderIcon = useCallback((icon: Icon) => {
        const size = { width: 16, height: 16 };

        switch (icon) {
            case 'phone':
                return <IconPhone {...size} />;
            case 'camera':
                return <IconCamera {...size} />;
            case 'facebook':
                return <IconFacebook {...size} />;
            case 'instagram':
                return <IconInstagram {...size} />;
            case 'mail':
                return <IconMail {...size} />;
            case 'plus':
                return <IconPlus {...size} />;
            case 'share':
                return <IconShare {...size} />;
            case 'shareAlt':
                return <IconShareAlt {...size} />;
            case 'search':
                return <IconSearch {...size} />;
            case 'avatar':
                return <IconAvatar {...size} />;
            case 'x':
                return <IconX {...size} />;
            case 'home':
                return <IconHomeV1 {...size} variation="FILL" colorType="SOLID" />;
            case 'health':
                return <IconHealthV1 {...size} variation="FILL" colorType="SOLID" />;
            case 'movement':
                return <IconMovementV1 width={18} height={18} variation="FILL" colorType="SOLID" />;
            case 'nutrition':
                return <IconNutritionV1 {...size} variation="FILL" colorType="SOLID" />;
            case 'articles':
                return <IconArticleV1 {...size} variation="OUTLINE" colorType="SOLID" />;

            default:
                return null;
        }
    }, []);

    const buttonClassName = useMemo(
        () =>
            [
                styles.button,
                styles[size],
                styles[area || 'default'],
                icon ? styles[icon.position || 'right'] : '',
                fullWidth ? styles.full : '',
                styles[variation || 'solid'],
                className
            ].join(' '),
        [area, className, fullWidth, icon, size, variation]
    );

    return (
        <button {...props} className={buttonClassName} disabled={loading}>
            {icon && renderIcon(icon.type)}
            {loading ? <HiRefresh className={styles['rotate-center']} /> : label}
        </button>
    );
};

export default Button;
