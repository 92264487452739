import { useLanguage } from 'hooks';
import { useCallback, useEffect, useRef, useState } from 'react';

import { SelectProps } from '../types';
import styles from './Select.module.scss';

const CONTENT = {
    SELECT: {
        pt: 'selecionar',
        en: 'select'
    }
};

export const Select: React.FC<SelectProps> = ({
    options,
    label,
    id,
    selectRef,
    onChangeCallBack,
    reset = false,
    className = ''
}) => {
    const { isPT } = useLanguage();

    const optionHolderRef = useRef<HTMLSpanElement | null>(null);
    const [currentValue, setCurrentValue] = useState(CONTENT.SELECT);

    const handleSelectChange = useCallback(
        (evt: React.ChangeEvent<HTMLSelectElement>) => {
            const selectedOptionLabel = options
                .filter(({ value }) => value === evt.target.value)
                .shift()?.label;

            if (!selectedOptionLabel) return;

            setCurrentValue(evt.target.value === '' ? CONTENT.SELECT : selectedOptionLabel);
            onChangeCallBack();
        },
        [onChangeCallBack, options]
    );

    useEffect(() => {
        if (reset) setCurrentValue(CONTENT.SELECT);
    }, [reset]);

    return (
        <div className={styles.container}>
            <label htmlFor={id}>{label}</label>
            <select ref={selectRef} id={id} onChange={handleSelectChange}>
                <option value="" hidden>
                    {isPT ? CONTENT.SELECT.pt : CONTENT.SELECT.en}
                </option>
                {options.map(({ label, value }, i) => (
                    <option value={value} key={i.toString()}>
                        {isPT ? label.pt : label.en}
                    </option>
                ))}
            </select>
            <span className={`${styles.holder} ${className}`} ref={optionHolderRef}>
                <span className={styles.label}>{label}</span>
                {isPT ? currentValue.pt : currentValue.en}
            </span>
        </div>
    );
};
