import { useTheme } from 'hooks';
import { useMemo } from 'react';
import { COLORS } from 'theme/colors';

import { IconAreaV2Props } from '../../../types';

export const IconHomeV2: React.FC<IconAreaV2Props> = ({
    width = 30,
    height = 30,
    isSelected,
    ...props
}) => {
    const { darkMode } = useTheme();

    const defaultColor = useMemo(
        () => ({
            fill: darkMode ? COLORS.BLUISH_PURPLE_800 : COLORS.WHITE,
            stroke: darkMode ? COLORS.PURPLE_300 : COLORS.MAGENTA_200
        }),
        [darkMode]
    );

    const selectedColor = useMemo(
        () => ({
            fill: darkMode ? COLORS.BLUISH_PURPLE_800 : COLORS.WHITE,
            stroke: darkMode ? COLORS.WHITE : COLORS.PURPLE_500
        }),
        [darkMode]
    );

    const filter = useMemo(
        () => (darkMode ? 'url(#dark-shadow)' : 'url(#light-shadow)'),
        [darkMode]
    );

    return (
        <svg
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            {...props}>
            <g filter={filter}>
                <path
                    d="M15 6L24.05 12.5V24.09H18.57V16.82C18.5627 16.6987 18.5105 16.5844 18.4236 16.4994C18.3368 16.4144 18.2214 16.3647 18.1 16.36H11.9C11.7786 16.3647 11.6632 16.4144 11.5764 16.4994C11.4895 16.5844 11.4373 16.6987 11.43 16.82V24.09H6V12.5L15 6Z"
                    fill={isSelected ? selectedColor.fill : defaultColor.fill}
                />
                <path
                    d="M14.7174 5.08452L5.1935 11.9028C5.07309 11.9876 4.99885 12.1292 5.00001 12.2722V24.5455C5.00001 24.7835 5.22688 25 5.47617 25H11.9048C12.1541 25 12.3809 24.7834 12.3809 24.5455V17.2723H17.6191V24.5455C17.6191 24.7835 17.8459 25 18.0952 25H24.5238C24.7731 25 25 24.7834 25 24.5455V12.2722C25 12.1293 24.9269 11.9876 24.8065 11.9028L15.2826 5.08452C15.0639 4.96213 14.9059 4.98195 14.7174 5.08452ZM15.0001 6.02212L24.0477 12.4999V24.0914H18.5715V16.8181C18.5715 16.5802 18.3446 16.3636 18.0954 16.3636H11.9048C11.6555 16.3636 11.4287 16.5802 11.4287 16.8181V24.0914H5.95245V12.4999L15.0001 6.02212Z"
                    stroke={isSelected ? selectedColor.stroke : defaultColor.stroke}
                />
            </g>
            <defs>
                <filter
                    id="light-shadow"
                    x="3"
                    y="6"
                    width="30"
                    height="30"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="5.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_490_721"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_490_721"
                        result="shape"
                    />
                </filter>
                <filter
                    id="dark-shadow"
                    x="3"
                    y="6"
                    width="24.05"
                    height="24.0898"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_490_721"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_490_721"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
