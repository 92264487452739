import {
    NUTRITION_ASSESSMENT_OPTIONS as OPTIONS,
    NUTRITION_ASSESSMENT_STEPS as STEPS
} from 'content';

import { FormOptions, FormStep, WelcomeFormData } from './types';

export const formOptions: FormOptions[] = [
    {
        local: 'medical',
        options: [
            {
                id: 1,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.MEDICAL.UNSUPERVISED,
                    EN: OPTIONS.EN.FORM_OPTIONS.MEDICAL.UNSUPERVISED
                },
                value: ['unsupervised', 'bad']
            },
            {
                id: 2,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.MEDICAL.WHEN_PROBLEM_APPEARS,
                    EN: OPTIONS.EN.FORM_OPTIONS.MEDICAL.WHEN_PROBLEM_APPEARS
                },
                value: ['when problem appears', 'bad']
            },
            {
                id: 3,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.MEDICAL.ANNUAL_CHECKUP,
                    EN: OPTIONS.EN.FORM_OPTIONS.MEDICAL.ANNUAL_CHECKUP
                },
                value: ['annual checkup', 'good']
            },
            {
                id: 4,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.MEDICAL.REGULAR_BY_CHRONIC_CONDITION,
                    EN: OPTIONS.EN.FORM_OPTIONS.MEDICAL.REGULAR_BY_CHRONIC_CONDITION
                },
                value: ['regular by chronic condition', 'good']
            }
        ]
    },
    {
        local: 'weight_objective',
        options: [
            {
                id: 1,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.WEIGHT_OBJECTIVE.OK_WITH_CURRENT_WEIGHT,
                    EN: OPTIONS.EN.FORM_OPTIONS.WEIGHT_OBJECTIVE.OK_WITH_CURRENT_WEIGHT
                },
                value: ['ok with current weight', 'good']
            },
            {
                id: 2,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.WEIGHT_OBJECTIVE.GAIN_MUSCLE_MASS,
                    EN: OPTIONS.EN.FORM_OPTIONS.WEIGHT_OBJECTIVE.GAIN_MUSCLE_MASS
                },
                value: ['gain muscle mass', 'good']
            },
            {
                id: 3,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.WEIGHT_OBJECTIVE.GAIN_WEIGHT,
                    EN: OPTIONS.EN.FORM_OPTIONS.WEIGHT_OBJECTIVE.GAIN_WEIGHT
                },
                value: ['gain weight', 'bad']
            },
            {
                id: 4,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.WEIGHT_OBJECTIVE.LOSE_WEIGHT,
                    EN: OPTIONS.EN.FORM_OPTIONS.WEIGHT_OBJECTIVE.LOSE_WEIGHT
                },
                value: ['lose weight', 'bad']
            }
        ]
    },
    {
        local: 'exercise',
        options: [
            {
                id: 1,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.EXERCISE.NONE,
                    EN: OPTIONS.EN.FORM_OPTIONS.EXERCISE.NONE
                },
                value: ['no', 'bad']
            },
            {
                id: 2,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.EXERCISE.ONE_TO_TWO_PER_WEEK,
                    EN: OPTIONS.EN.FORM_OPTIONS.EXERCISE.ONE_TO_TWO_PER_WEEK
                },
                value: ['1 to 2 per week', 'neutral']
            },
            {
                id: 3,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.EXERCISE.THREE_TO_FOUR_PER_WEEK,
                    EN: OPTIONS.EN.FORM_OPTIONS.EXERCISE.THREE_TO_FOUR_PER_WEEK
                },
                value: ['3 to 4 per week', 'good']
            },
            {
                id: 4,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.EXERCISE.EVERY_DAY,
                    EN: OPTIONS.EN.FORM_OPTIONS.EXERCISE.EVERY_DAY
                },
                value: ['every day', 'good']
            }
        ]
    },
    {
        local: 'smoker',
        options: [
            {
                id: 1,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.SMOKER.NO,
                    EN: OPTIONS.EN.FORM_OPTIONS.SMOKER.NO
                },
                value: ['no', 'good']
            },
            {
                id: 2,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.SMOKER.YES,
                    EN: OPTIONS.EN.FORM_OPTIONS.SMOKER.YES
                },
                value: ['yes', 'bad']
            },
            {
                id: 3,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.SMOKER.OCCASIONALLY,
                    EN: OPTIONS.EN.FORM_OPTIONS.SMOKER.OCCASIONALLY
                },
                value: ['occasionally', 'neutral']
            }
        ]
    },
    {
        local: 'sleep_difficulty',
        options: [
            {
                id: 1,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.SLEEP_DIFFICULTY.EASY,
                    EN: OPTIONS.EN.FORM_OPTIONS.SLEEP_DIFFICULTY.EASY
                },
                value: ['easy', 'good']
            },
            {
                id: 2,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.SLEEP_DIFFICULTY.DIFFICULTY,
                    EN: OPTIONS.EN.FORM_OPTIONS.SLEEP_DIFFICULTY.DIFFICULTY
                },
                value: ['difficulty', 'bad']
            },
            {
                id: 3,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.SLEEP_DIFFICULTY.MEDICATED,
                    EN: OPTIONS.EN.FORM_OPTIONS.SLEEP_DIFFICULTY.MEDICATED
                },
                value: ['medicated', 'bad']
            }
        ]
    },
    {
        local: 'food_intake',
        options: [
            {
                id: 1,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.FOOD_INTAKE.DIVERSIFIED,
                    EN: OPTIONS.EN.FORM_OPTIONS.FOOD_INTAKE.DIVERSIFIED
                },
                value: ['diversified', 'good']
            },
            {
                id: 2,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.FOOD_INTAKE.CAN_IMPROVE,
                    EN: OPTIONS.EN.FORM_OPTIONS.FOOD_INTAKE.CAN_IMPROVE
                },
                value: ['can improve', 'neutral']
            },
            {
                id: 3,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.FOOD_INTAKE.BAD,
                    EN: OPTIONS.EN.FORM_OPTIONS.FOOD_INTAKE.BAD
                },
                value: ['bad', 'bad']
            }
        ]
    },
    {
        local: 'alcohol_intake',
        options: [
            {
                id: 1,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.ALCOHOL_INTAKE.NO,
                    EN: OPTIONS.EN.FORM_OPTIONS.ALCOHOL_INTAKE.NO
                },
                value: ['no', 'good']
            },
            {
                id: 2,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.ALCOHOL_INTAKE.MEALS_AND_SOCIALLY,
                    EN: OPTIONS.EN.FORM_OPTIONS.ALCOHOL_INTAKE.MEALS_AND_SOCIALLY
                },
                value: ['meals and socially', 'neutral']
            },
            {
                id: 3,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.ALCOHOL_INTAKE.MORE_THAN_THREE_PER_DAY,
                    EN: OPTIONS.EN.FORM_OPTIONS.ALCOHOL_INTAKE.MORE_THAN_THREE_PER_DAY
                },
                value: ['more than 3 per day', 'bad']
            }
        ]
    },
    {
        local: 'stress',
        options: [
            {
                id: 1,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.STRESS.NO_SYMPTOMS,
                    EN: OPTIONS.EN.FORM_OPTIONS.STRESS.NO_SYMPTOMS
                },
                value: ['no symptoms', 'good']
            },
            {
                id: 2,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.STRESS.SOME_SYMPTOMS,
                    EN: OPTIONS.EN.FORM_OPTIONS.STRESS.SOME_SYMPTOMS
                },
                value: ['some symptoms', 'neutral']
            },
            {
                id: 3,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.STRESS.CHRONIC_SYMPTOMS,
                    EN: OPTIONS.EN.FORM_OPTIONS.STRESS.CHRONIC_SYMPTOMS
                },
                value: ['chronic symptoms', 'bad']
            }
        ]
    },
    {
        local: 'illnesses',
        options: [
            {
                id: 2,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.ILLNESSES.NO,
                    EN: OPTIONS.EN.FORM_OPTIONS.ILLNESSES.NO
                },
                value: ['no', 'good']
            },
            {
                id: 3,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.ILLNESSES.HYPERTENSION,
                    EN: OPTIONS.EN.FORM_OPTIONS.ILLNESSES.HYPERTENSION
                },
                value: ['hypertension', 'bad']
            },
            {
                id: 4,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.ILLNESSES.DIABETES,
                    EN: OPTIONS.EN.FORM_OPTIONS.ILLNESSES.DIABETES
                },
                value: ['diabetes', 'bad']
            },
            {
                id: 5,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.ILLNESSES.CHOLESTEROL,
                    EN: OPTIONS.EN.FORM_OPTIONS.ILLNESSES.CHOLESTEROL
                },
                value: ['cholesterol', 'bad']
            },
            {
                id: 6,
                label: {
                    PT: OPTIONS.PT.FORM_OPTIONS.ILLNESSES.OTHER,
                    EN: OPTIONS.EN.FORM_OPTIONS.ILLNESSES.OTHER
                },
                value: ['other', 'bad']
            }
        ]
    }
];

export const defaultFormSteps: FormStep[] = [
    {
        id: 1,
        local: 'intro',
        title: {
            PT: null,
            EN: null
        },
        previous: null,
        next: 'imc',
        show: true
    },
    {
        id: 2,
        local: 'imc',
        title: {
            PT: null,
            EN: null
        },
        previous: 'intro',
        next: 'medical',
        show: false
    },
    {
        id: 3,
        local: 'medical',
        title: {
            PT: STEPS.PT.MEDICAL.TITLE,
            EN: STEPS.EN.MEDICAL.TITLE
        },
        previous: 'imc',
        next: 'weight_objective',
        show: false
    },
    {
        id: 4,
        local: 'weight_objective',
        title: {
            PT: STEPS.PT.WEIGHT_OBJECTIVE.TITLE,
            EN: STEPS.EN.WEIGHT_OBJECTIVE.TITLE
        },
        previous: 'medical',
        next: 'exercise',
        show: false
    },
    {
        id: 5,
        local: 'exercise',
        title: {
            PT: STEPS.PT.EXERCISE.TITLE,
            EN: STEPS.EN.EXERCISE.TITLE
        },
        previous: 'weight_objective',
        next: 'smoker',
        show: false
    },
    {
        id: 6,
        local: 'smoker',
        title: {
            PT: STEPS.PT.SMOKER.TITLE,
            EN: STEPS.EN.SMOKER.TITLE
        },
        previous: 'exercise',
        next: 'sleep_difficulty',
        show: false
    },
    {
        id: 7,
        local: 'sleep_difficulty',
        title: {
            PT: STEPS.PT.SLEEP_DIFFICULTY.TITLE,
            EN: STEPS.EN.SLEEP_DIFFICULTY.TITLE
        },
        previous: 'smoker',
        next: 'food_intake',
        show: false
    },
    {
        id: 8,
        local: 'food_intake',
        title: {
            PT: STEPS.PT.FOOD_INTAKE.TITLE,
            EN: STEPS.EN.FOOD_INTAKE.TITLE
        },
        previous: 'sleep_difficulty',
        next: 'alcohol_intake',
        show: false
    },
    {
        id: 9,
        local: 'alcohol_intake',
        title: {
            PT: STEPS.PT.ALCOHOL_INTAKE.TITLE,
            EN: STEPS.EN.ALCOHOL_INTAKE.TITLE
        },
        previous: 'food_intake',
        next: 'stress',
        show: false
    },
    {
        id: 10,
        local: 'stress',
        title: {
            PT: STEPS.PT.STRESS.TITLE,
            EN: STEPS.EN.STRESS.TITLE
        },
        previous: 'alcohol_intake',
        next: 'illnesses',
        show: false
    },
    {
        id: 11,
        local: 'illnesses',
        title: {
            PT: STEPS.PT.ILLNESSES.TITLE,
            EN: STEPS.EN.ILLNESSES.TITLE
        },
        previous: 'stress',
        next: 'resume',
        show: false
    },
    {
        id: 12,
        local: 'resume',
        title: {
            PT: null,
            EN: null
        },
        previous: 'illnesses',
        next: null,
        show: false
    },
    {
        id: 13,
        local: 'message',
        title: {
            PT: null,
            EN: null
        },
        previous: null,
        next: null,
        show: false
    }
];

export const defaultFormData: WelcomeFormData = {
    score: {
        imc: null,
        medical: null,
        weight_objective: null,
        exercise: null,
        smoker: null,
        sleep_difficulty: null,
        food_intake: null,
        alcohol_intake: null,
        stress: null,
        illnesses: null
    },
    form_data: {
        user: {
            weight: null,
            height: null,
            imc: null
        },
        self_assessment: {
            medical: null,
            weight_objective: null,
            exercise: null,
            smoker: null,
            sleep_difficulty: null,
            food_intake: null,
            alcohol_intake: null,
            stress: null,
            illnesses: null
        }
    }
};
