import { OptionButton } from 'components/elements';
import { permissions } from 'config';
import { PREFERENCES } from 'content';
import { useAuth, useLanguage, useTheme } from 'hooks';
import { useEffect } from 'react';

import styles from '../Preferences.module.scss';

export const SelectLanguage: React.FC = () => {
    const { activeTheme } = useTheme();
    const { isPT, isEN, switchLanguage } = useLanguage();
    const { validateUserAccessToArea } = useAuth();

    const CONTENT = isPT ? PREFERENCES.PT.LANGUAGE : PREFERENCES.EN.LANGUAGE;

    useEffect(
        () => validateUserAccessToArea(permissions.general.preferences),
        [validateUserAccessToArea]
    );

    return (
        <section className={`${styles.section} container`}>
            <h1>{CONTENT.INTRO.TITLE}</h1>
            <p>{CONTENT.INTRO.DESCRIPTION}</p>
            <div className={`${styles.card} ${styles[activeTheme]}`}>
                <OptionButton fullWidth isSelected={isPT} onClick={() => switchLanguage('PT')}>
                    {CONTENT.LABELS.PT}
                </OptionButton>
                <OptionButton fullWidth isSelected={isEN} onClick={() => switchLanguage('EN')}>
                    {CONTENT.LABELS.EN}
                </OptionButton>
            </div>
        </section>
    );
};
