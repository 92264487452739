import format from 'date-fns/format';
import PTLocale from 'date-fns/locale/pt';
import { AreaType } from 'models/types';
import { useMemo } from 'react';

import styles from './CardTrackerV2.module.scss';

export type CardTrackerV2Props = React.HTMLProps<HTMLDivElement> & {
    date: string;
    location: string;
    points: number;
    area?: AreaType;
    className?: string;
};

export const CardTrackerV2: React.FC<CardTrackerV2Props> = ({
    date,
    location,
    points,
    area = 'movement',
    className = '',
    ...props
}) => {
    const cardClassName = useMemo(
        () => [styles.card, styles[area], className].join(' '),
        [area, className]
    );

    return (
        <div className={cardClassName} {...props}>
            <div>
                <span className={styles.date}>
                    {format(new Date(date), "d MMM, yyyy '-' H:mm", {
                        locale: PTLocale
                    })}
                </span>
                <span className={styles.location}>{location}</span>
            </div>
            <div>
                {/* <SymbolHPS color={COLORS.WHITE} /> */}
                <span className={styles.points}>{points} pts</span>
            </div>
        </div>
    );
};
