import { Modal } from 'components/elements';
import { useState } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import styles from './QRCode.module.scss';

interface QRCodeProps extends React.SVGProps<SVGSVGElement> {
    infoMessage: string;
}

export const QRCode: React.FC<QRCodeProps> = (props) => {
    const [show, setShow] = useState(false);

    return (
        <>
            <div className={styles.container}>
                <svg
                    width={props.width ?? '100'}
                    height={props.height ?? '100'}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    {...props}>
                    <path
                        d="M32.8886.21c.9522 0 1.7242.772 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242ZM39.7852.21c.9521 0 1.7241.772 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M43.2335.21c.9521 0 1.7241.772 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242ZM46.6818.21c.9521 0 1.7241.772 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242ZM57.0265.21c.9522 0 1.7242.772 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.7721-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M60.4749.21c.9522 0 1.7242.772 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M63.9232.21c.9521 0 1.7241.772 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M67.3714.21c.9521 0 1.7242.772 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242ZM29.4404 3.6583c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7241 1.7242-1.7241ZM36.3369 3.6583c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7241 1.7242-1.7241ZM39.7852 3.6583c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7241 1.7242-1.7241ZM50.13 3.6583c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M53.5782 3.6583c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7241 1.7241-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M57.0265 3.6583c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.7721-1.7241 1.7241-1.7241ZM63.9232 3.6583c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M67.3714 3.6583c.9521 0 1.7242.772 1.7242 1.7241 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M70.8196 3.6583c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7241 1.7241-1.7241ZM36.3369 7.1066c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.7721-1.7242 1.7242-1.7242ZM39.7852 7.1066c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M43.2335 7.1066c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.772-1.7242 1.7242-1.7242ZM46.6818 7.1066c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M50.13 7.1066c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7241 0-.9522.7721-1.7242 1.7242-1.7242ZM57.0265 7.1066c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7241 0-.9522.7721-1.7242 1.7241-1.7242ZM63.9232 7.1066c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.772-1.7242 1.7242-1.7242ZM70.8196 7.1066c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7241 0-.9522.772-1.7242 1.7241-1.7242ZM32.8886 10.5549c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.772-1.7242-1.7241 0-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M36.3369 10.5549c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.7721-1.7242 1.7242-1.7242ZM39.7852 10.5549c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M43.2335 10.5549c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.772-1.7242 1.7242-1.7242ZM46.6818 10.5549c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M50.13 10.5549c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.772-1.7242-1.7241 0-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M53.5782 10.5549c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7241-.772-1.7241-1.7241 0-.9522.772-1.7242 1.7241-1.7242ZM63.9232 10.5549c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.772-1.7242 1.7242-1.7242ZM29.4404 14.0032c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M32.8886 14.0032c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.772-1.7242-1.7241.0001-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M36.3369 14.0032c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241.0001-.9522.7721-1.7242 1.7242-1.7242ZM39.7852 14.0032c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241.0001-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M43.2335 14.0032c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.772-1.7242 1.7242-1.7242ZM50.13 14.0032c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.772-1.7242-1.7241 0-.9522.7721-1.7242 1.7242-1.7242ZM67.3714 14.0032c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7241-1.7242 1.7241-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M43.2335 17.4514c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM46.6818 17.4514c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM67.3714 17.4514c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M70.8196 17.4514c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242ZM29.4404 20.8997c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7241 1.7242-1.7241ZM36.3369 20.8997c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7241 1.7242-1.7241ZM43.2335 20.8997c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7241 1.7242-1.7241ZM50.13 20.8997c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7241 1.7242-1.7241ZM57.0265 20.8997c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242.0001-.9521.7721-1.7241 1.7241-1.7241ZM63.9232 20.8997c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M70.8196 20.8997c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7241 1.7241-1.7241ZM32.8886 24.3479c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242.0001-.9522.7721-1.7242 1.7242-1.7242ZM46.6818 24.3479c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M50.13 24.3479c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242ZM57.0265 24.3479c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242.0001-.9522.7721-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M60.4749 24.3479c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M63.9232 24.3479c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M67.3714 24.3479c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM1.8542 27.7962c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM8.7507 27.7962c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242ZM15.6472 27.7962c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242.0001-.9522.7722-1.7242 1.7242-1.7242ZM22.5438 27.7962c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242ZM32.8886 27.7962c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242.0001-.9522.7721-1.7242 1.7242-1.7242ZM39.7852 27.7962c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242.0001-.9522.7721-1.7242 1.7242-1.7242ZM50.13 27.7962c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M53.5782 27.7962c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242.0001-.9522.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M57.0265 27.7962c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242.0001-.9522.7721-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M60.4749 27.7962c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242ZM67.3714 27.7962c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM84.6128 27.7962c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM94.9576 27.7962c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242.0001-.9522.7721-1.7242 1.7242-1.7242ZM1.8542 31.2446c.9521 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9522.772-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M5.3024 31.2446c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9522.772-1.7241 1.7241-1.7241ZM8.7507 31.2446c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9522.772-1.7241 1.7241-1.7241ZM15.6472 31.2446c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242.0001-.9522.7722-1.7241 1.7242-1.7241ZM32.8886 31.2446c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242.0001-.9522.7721-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M36.3369 31.2446c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9522.7721-1.7241 1.7242-1.7241ZM50.13 31.2446c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9522.7721-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M53.5782 31.2446c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242.0001-.9522.772-1.7241 1.7241-1.7241ZM60.4749 31.2446c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9522.7721-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M63.9232 31.2446c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9522.772-1.7241 1.7242-1.7241ZM70.8196 31.2446c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9522.772-1.7241 1.7241-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M74.2679 31.2446c.9521 0 1.7242.772 1.7242 1.7241 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9522.772-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M77.7162 31.2446c.9521 0 1.7242.772 1.7242 1.7241 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9522.7721-1.7241 1.7242-1.7241ZM88.061 31.2446c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9522.772-1.7241 1.7241-1.7241ZM98.4058 31.2446c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242.0001-.9522.7722-1.7241 1.7242-1.7241ZM1.8542 34.6928c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242C.902 38.1412.13 37.3691.13 36.417c0-.9522.772-1.7242 1.7242-1.7242ZM8.7507 34.6928c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242ZM22.5438 34.6928c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M25.9921 34.6928c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M29.4404 34.6928c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM36.3369 34.6928c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242.0001-.9522.7721-1.7242 1.7242-1.7242ZM43.2335 34.6928c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM46.6818 34.6928c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM70.8196 34.6928c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242ZM91.5093 34.6928c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M94.9576 34.6928c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242.0001-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M98.4058 34.6928c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242.0001-.9522.7722-1.7242 1.7242-1.7242ZM1.8542 38.1411c.9521 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7241 1.7242-1.7241ZM8.7507 38.1411c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7241 1.7241-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M12.199 38.1411c.9521 0 1.7242.772 1.7242 1.7241 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.772-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M15.6472 38.1411c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242.0001-.9521.7722-1.7241 1.7242-1.7241ZM29.4404 38.1411c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M32.8886 38.1411c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M36.3369 38.1411c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7241 1.7242-1.7241ZM43.2335 38.1411c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7241 1.7242-1.7241ZM53.5782 38.1411c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242.0001-.9521.772-1.7241 1.7241-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M57.0265 38.1411c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242.0001-.9521.7721-1.7241 1.7241-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M60.4749 38.1411c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M63.9232 38.1411c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M67.3714 38.1411c.9521 0 1.7242.772 1.7242 1.7241 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7241 1.7242-1.7241ZM74.2679 38.1411c.9521 0 1.7242.772 1.7242 1.7241 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.772-1.7241 1.7242-1.7241ZM84.6128 38.1411c.9521 0 1.7242.772 1.7242 1.7241 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7241 1.7242-1.7241ZM94.9576 38.1411c.9521 0 1.7242.772 1.7242 1.7241 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7241 1.7242-1.7241ZM12.199 41.5894c.9521 0 1.7242.772 1.7242 1.7241 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.772-1.7241 1.7242-1.7241ZM19.0955 41.5894c.9521 0 1.7241.772 1.7241 1.7241 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7241 1.7242-1.7241ZM22.5438 41.5894c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M25.9921 41.5894c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7241 1.7242-1.7241ZM53.5782 41.5894c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242.0001-.9521.772-1.7241 1.7241-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M57.0265 41.5894c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242.0001-.9521.7721-1.7241 1.7241-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M60.4749 41.5894c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7241 1.7242-1.7241ZM67.3714 41.5894c.9521 0 1.7242.772 1.7242 1.7241 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M70.8196 41.5894c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7241 1.7241-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M74.2679 41.5894c.9521 0 1.7242.772 1.7242 1.7241 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.772-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M77.7162 41.5894c.9521 0 1.7242.772 1.7242 1.7241 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7241 1.7242-1.7241ZM88.061 41.5894c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7241 1.7241-1.7241ZM94.9576 41.5894c.9521 0 1.7242.772 1.7242 1.7241 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7241 1.7242-1.7241Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M98.4058 41.5894c.9522 0 1.7242.772 1.7242 1.7241 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242.0001-.9521.7722-1.7241 1.7242-1.7241ZM1.8542 45.0376c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242C.902 48.486.13 47.7139.13 46.7618c0-.9522.772-1.7242 1.7242-1.7242ZM12.199 45.0376c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242.0001-.9522.772-1.7242 1.7242-1.7242ZM19.0955 45.0376c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242.0001-.9522.7721-1.7242 1.7242-1.7242ZM25.9921 45.0376c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242ZM39.7852 45.0376c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242.0001-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M43.2335 45.0376c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM46.6818 45.0376c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM57.0265 45.0376c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242.0001-.9522.7721-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M60.4749 45.0376c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M63.9232 45.0376c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM70.8196 45.0376c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M74.2679 45.0376c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242.0001-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M77.7162 45.0376c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242.0001-.9522.7721-1.7242 1.7242-1.7242ZM81.1645 45.0376c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242ZM88.061 45.0376c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242ZM98.4058 45.0376c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242.0001-.9522.7722-1.7242 1.7242-1.7242ZM1.8542 48.4858c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242C.902 51.9342.13 51.1622.13 50.21c0-.9521.772-1.7242 1.7242-1.7242ZM8.7507 48.4858c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M12.199 48.4858c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M15.6472 48.4858c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242.0001-.9521.7722-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M19.0955 48.4858c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7242 1.7242-1.7242ZM22.5438 48.4858c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M25.9921 48.4858c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242ZM32.8886 48.4858c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7242 1.7242-1.7242ZM39.7852 48.4858c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M43.2335 48.4858c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242ZM50.13 48.4858c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242ZM60.4749 48.4858c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M63.9232 48.4858c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242ZM70.8196 48.4858c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242ZM84.6128 48.4858c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M88.061 48.4858c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242ZM94.9576 48.4858c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M98.4058 48.4858c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242.0001-.9521.7722-1.7242 1.7242-1.7242ZM8.7507 51.9342c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242ZM15.6472 51.9342c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7722-1.7242 1.7242-1.7242ZM25.9921 51.9342c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242ZM32.8886 51.9342c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242ZM43.2335 51.9342c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM46.6818 51.9342c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M50.13 51.9342c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242ZM57.0265 51.9342c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9522.7721-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M60.4749 51.9342c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242ZM67.3714 51.9342c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M70.8196 51.9342c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242ZM81.1645 51.9342c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242ZM88.061 51.9342c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242ZM94.9576 51.9342c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242ZM1.8542 55.3824c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242ZM12.199 55.3824c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M15.6472 55.3824c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7722-1.7242 1.7242-1.7242ZM22.5438 55.3824c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242ZM29.4404 55.3824c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242ZM36.3369 55.3824c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242ZM43.2335 55.3824c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242ZM50.13 55.3824c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M53.5782 55.3824c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M57.0265 55.3824c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.7721-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M60.4749 55.3824c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242ZM74.2679 55.3824c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M77.7162 55.3824c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242ZM81.1645 55.3824c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242ZM88.061 55.3824c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242ZM94.9576 55.3824c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M98.4058 55.3824c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7722-1.7242 1.7242-1.7242ZM5.3024 58.8307c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7241-.772-1.7241-1.7241 0-.9522.772-1.7242 1.7241-1.7242ZM15.6472 58.8307c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.772-1.7242-1.7241.0001-.9522.7722-1.7242 1.7242-1.7242ZM36.3369 58.8307c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241.0001-.9522.7721-1.7242 1.7242-1.7242ZM39.7852 58.8307c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241.0001-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M43.2335 58.8307c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.772-1.7242 1.7242-1.7242ZM50.13 58.8307c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.772-1.7242-1.7241 0-.9522.7721-1.7242 1.7242-1.7242ZM57.0265 58.8307c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7241-.772-1.7241-1.7241.0001-.9522.7721-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M60.4749 58.8307c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.772-1.7242-1.7241 0-.9522.7721-1.7242 1.7242-1.7242ZM70.8196 58.8307c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7241-.772-1.7241-1.7241 0-.9522.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M74.2679 58.8307c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7241-1.7242 1.7241-.9522 0-1.7242-.772-1.7242-1.7241.0001-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M77.7162 58.8307c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7241-1.7242 1.7241-.9522 0-1.7242-.772-1.7242-1.7241.0001-.9522.7721-1.7242 1.7242-1.7242ZM88.061 58.8307c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7241-.772-1.7241-1.7241 0-.9522.772-1.7242 1.7241-1.7242ZM91.5093 58.8307c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7241-.772-1.7241-1.7241 0-.9522.772-1.7242 1.7241-1.7242ZM98.4058 58.8307c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.772-1.7242-1.7241.0001-.9522.7722-1.7242 1.7242-1.7242ZM1.8542 62.279c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM12.199 62.279c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M15.6472 62.279c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7722-1.7242 1.7242-1.7242ZM22.5438 62.279c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242ZM29.4404 62.279c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M32.8886 62.279c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242ZM39.7852 62.279c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242ZM46.6818 62.279c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM63.9232 62.279c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM70.8196 62.279c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242ZM84.6128 62.279c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM94.9576 62.279c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M98.4058 62.279c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7722-1.7242 1.7242-1.7242ZM5.3024 65.7272c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242ZM8.7507 65.7272c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M12.199 65.7272c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.772-1.7242 1.7242-1.7242ZM25.9921 65.7272c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242ZM32.8886 65.7272c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7242 1.7242-1.7242ZM39.7852 65.7272c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7242 1.7242-1.7242ZM60.4749 65.7272c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M63.9232 65.7272c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M67.3714 65.7272c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M70.8196 65.7272c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M74.2679 65.7272c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.772-1.7242 1.7242-1.7242ZM81.1645 65.7272c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242ZM88.061 65.7272c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242ZM94.9576 65.7272c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7242 1.7242-1.7242ZM1.8542 69.1756c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242C.902 72.624.13 71.8519.13 70.8998c0-.9523.772-1.7242 1.7242-1.7242ZM8.7507 69.1756c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9523.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M12.199 69.1756c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242.0001-.9523.772-1.7242 1.7242-1.7242ZM19.0955 69.1756c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242.0001-.9523.7721-1.7242 1.7242-1.7242ZM22.5438 69.1756c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9523.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M25.9921 69.1756c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9523.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M29.4404 69.1756c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9523.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M32.8886 69.1756c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242.0001-.9523.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M36.3369 69.1756c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242.0001-.9523.7721-1.7242 1.7242-1.7242ZM57.0265 69.1756c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242.0001-.9523.7721-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M60.4749 69.1756c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9523.7721-1.7242 1.7242-1.7242ZM67.3714 69.1756c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9523.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M70.8196 69.1756c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9523.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M74.2679 69.1756c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242.0001-.9523.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M77.7162 69.1756c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242.0001-.9523.7721-1.7242 1.7242-1.7242ZM81.1645 69.1756c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9523.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M84.6128 69.1756c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9523.772-1.7242 1.7242-1.7242ZM29.4404 72.6238c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242ZM36.3369 72.6238c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7242 1.7242-1.7242ZM39.7852 72.6238c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M43.2335 72.6238c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242ZM46.6818 72.6238c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242ZM57.0265 72.6238c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242.0001-.9521.7721-1.7242 1.7241-1.7242ZM67.3714 72.6238c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M70.8196 72.6238c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242ZM84.6128 72.6238c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242ZM91.5093 72.6238c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M94.9576 72.6238c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M98.4058 72.6238c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242.0001-.9521.7722-1.7242 1.7242-1.7242ZM32.8886 76.0721c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7242 1.7242-1.7242ZM39.7852 76.0721c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M43.2335 76.0721c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242ZM50.13 76.0721c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242ZM57.0265 76.0721c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242.0001-.9521.7721-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M60.4749 76.0721c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242ZM67.3714 76.0721c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M70.8196 76.0721c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242ZM77.7162 76.0721c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7242 1.7242-1.7242ZM84.6128 76.0721c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M88.061 76.0721c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242ZM94.9576 76.0721c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9521.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M98.4058 76.0721c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242.0001-.9521.7722-1.7242 1.7242-1.7242ZM39.7852 79.5204c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241.0001-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M43.2335 79.5204c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.772-1.7242 1.7242-1.7242ZM46.6818 79.5204c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M50.13 79.5204c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.772-1.7242-1.7241 0-.9522.7721-1.7242 1.7242-1.7242ZM57.0265 79.5204c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7241-.772-1.7241-1.7241.0001-.9522.7721-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M60.4749 79.5204c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.772-1.7242-1.7241 0-.9522.7721-1.7242 1.7242-1.7242ZM70.8196 79.5204c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7241-.772-1.7241-1.7241 0-.9522.772-1.7242 1.7241-1.7242ZM84.6128 79.5204c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7241-1.7242 1.7241-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M88.061 79.5204c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7241-.772-1.7241-1.7241 0-.9522.772-1.7242 1.7241-1.7242ZM94.9576 79.5204c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7241-1.7242 1.7241-.9522 0-1.7242-.772-1.7242-1.7241.0001-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M98.4058 79.5204c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.772-1.7242-1.7241.0001-.9522.7722-1.7242 1.7242-1.7242ZM29.4404 82.9686c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242ZM36.3369 82.9686c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242ZM43.2335 82.9686c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242ZM50.13 82.9686c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M53.5782 82.9686c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M57.0265 82.9686c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.7721-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M60.4749 82.9686c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242ZM70.8196 82.9686c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M74.2679 82.9686c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M77.7162 82.9686c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.7721-1.7242 1.7242-1.7242ZM81.1645 82.9686c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9521.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M84.6128 82.9686c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9521.772-1.7242 1.7242-1.7242ZM98.4058 82.9686c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9521.7722-1.7242 1.7242-1.7242ZM32.8886 86.4169c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.772-1.7242-1.7241.0001-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M36.3369 86.4169c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241.0001-.9522.7721-1.7242 1.7242-1.7242ZM43.2335 86.4169c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.772-1.7242 1.7242-1.7242ZM46.6818 86.4169c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M50.13 86.4169c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.772-1.7242-1.7241 0-.9522.7721-1.7242 1.7242-1.7242ZM60.4749 86.4169c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.772-1.7242-1.7241 0-.9522.7721-1.7242 1.7242-1.7242ZM84.6128 86.4169c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7241-1.7242 1.7241-.9522 0-1.7242-.772-1.7242-1.7241 0-.9522.772-1.7242 1.7242-1.7242ZM91.5093 86.4169c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7241-.772-1.7241-1.7241 0-.9522.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M94.9576 86.4169c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7241-1.7242 1.7241-.9522 0-1.7242-.772-1.7242-1.7241.0001-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M98.4058 86.4169c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.772-1.7242-1.7241.0001-.9522.7722-1.7242 1.7242-1.7242ZM29.4404 89.8652c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM36.3369 89.8652c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242.0001-.9522.7721-1.7242 1.7242-1.7242ZM50.13 89.8652c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242ZM60.4749 89.8652c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M63.9232 89.8652c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242ZM70.8196 89.8652c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242ZM81.1645 89.8652c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M84.6128 89.8652c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.772-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M88.061 89.8652c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.772-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242ZM98.4058 89.8652c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.772-1.7242-1.7242.0001-.9522.7722-1.7242 1.7242-1.7242ZM32.8886 93.3135c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242ZM39.7852 93.3135c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242ZM50.13 93.3135c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M53.5782 93.3135c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M57.0265 93.3135c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9522.7721-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M60.4749 93.3135c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M63.9232 93.3135c.9521 0 1.7241.772 1.7241 1.7242 0 .9521-.772 1.7242-1.7241 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M67.3714 93.3135c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M70.8196 93.3135c.9522 0 1.7242.772 1.7242 1.7242 0 .9521-.772 1.7242-1.7242 1.7242-.9521 0-1.7241-.7721-1.7241-1.7242 0-.9522.772-1.7242 1.7241-1.7242ZM94.9576 93.3135c.9521 0 1.7242.772 1.7242 1.7242 0 .9521-.7721 1.7242-1.7242 1.7242-.9522 0-1.7242-.7721-1.7242-1.7242 0-.9522.7721-1.7242 1.7242-1.7242ZM29.4404 96.7617c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.7719-1.7242-1.7241 0-.9521.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M32.8886 96.7617c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.7719-1.7242-1.7241.0001-.9521.7721-1.7242 1.7242-1.7242ZM39.7852 96.7617c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.7719-1.7242-1.7241.0001-.9521.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M43.2335 96.7617c.9521 0 1.7241.7721 1.7241 1.7242 0 .9522-.772 1.7241-1.7241 1.7241-.9522 0-1.7242-.7719-1.7242-1.7241 0-.9521.772-1.7242 1.7242-1.7242ZM57.0265 96.7617c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7241-1.7242 1.7241-.9521 0-1.7241-.7719-1.7241-1.7241.0001-.9521.7721-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M60.4749 96.7617c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.7719-1.7242-1.7241 0-.9521.7721-1.7242 1.7242-1.7242ZM67.3714 96.7617c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7241-1.7242 1.7241-.9522 0-1.7242-.7719-1.7242-1.7241 0-.9521.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M70.8196 96.7617c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7241-1.7242 1.7241-.9521 0-1.7241-.7719-1.7241-1.7241 0-.9521.772-1.7242 1.7241-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M74.2679 96.7617c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7241-1.7242 1.7241-.9522 0-1.7242-.7719-1.7242-1.7241.0001-.9521.772-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M77.7162 96.7617c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7241-1.7242 1.7241-.9522 0-1.7242-.7719-1.7242-1.7241.0001-.9521.7721-1.7242 1.7242-1.7242ZM84.6128 96.7617c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7241-1.7242 1.7241-.9522 0-1.7242-.7719-1.7242-1.7241 0-.9521.772-1.7242 1.7242-1.7242ZM94.9576 96.7617c.9521 0 1.7242.7721 1.7242 1.7242 0 .9522-.7721 1.7241-1.7242 1.7241-.9522 0-1.7242-.7719-1.7242-1.7241.0001-.9521.7721-1.7242 1.7242-1.7242Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M98.4058 96.7617c.9522 0 1.7242.7721 1.7242 1.7242 0 .9522-.772 1.7241-1.7242 1.7241-.9521 0-1.7242-.7719-1.7242-1.7241.0001-.9521.7722-1.7242 1.7242-1.7242ZM12.199.4467C5.6645.4467.3666 5.7446.3666 12.2791c0 6.5345 5.2978 11.8324 11.8324 11.8324 6.5345 0 11.8324-5.298 11.8324-11.8325 0-6.5344-5.298-11.8323-11.8324-11.8323Zm0 20.1149c-4.567 0-8.2827-3.7156-8.2827-8.2826S7.632 3.9964 12.199 3.9964s8.2826 3.7156 8.2826 8.2826-3.7156 8.2826-8.2826 8.2826ZM88.061.4467c-6.5344 0-11.8324 5.2979-11.8324 11.8324 0 6.5345 5.2979 11.8324 11.8324 11.8324 6.5346 0 11.8324-5.2978 11.8324-11.8324C99.8933 5.7446 94.5955.4467 88.061.4467Zm0 20.1149c-4.567 0-8.2826-3.7156-8.2826-8.2826s3.7156-8.2826 8.2826-8.2826 8.2827 3.7156 8.2827 8.2826-3.7157 8.2826-8.2827 8.2826ZM12.199 76.3087C5.6645 76.3087.3666 81.6065.3666 88.141S5.6644 99.9734 12.199 99.9734c6.5345 0 11.8324-5.2979 11.8324-11.8324 0-6.5344-5.298-11.8323-11.8324-11.8323Zm0 20.115c-4.567 0-8.2827-3.7157-8.2827-8.2827s3.7157-8.2826 8.2827-8.2826 8.2826 3.7156 8.2826 8.2826-3.7156 8.2827-8.2826 8.2827Z"
                        fill={props.fill ?? '#fff'}
                    />
                    <path
                        d="M17.1686 12.1775c0 2.8007-2.2704 5.0711-5.0711 5.0711-2.8005 0-5.071-2.2704-5.071-5.0711 0-2.8005 2.2705-5.071 5.071-5.071 2.8007 0 5.0711 2.2706 5.0711 5.071ZM93.0307 12.1775c0 2.8007-2.2704 5.0711-5.0712 5.0711-2.8005 0-5.0709-2.2704-5.0709-5.0711 0-2.8005 2.2704-5.071 5.0709-5.071 2.8008 0 5.0712 2.2706 5.0712 5.071ZM17.1686 88.0396c0 2.8006-2.2704 5.0711-5.0711 5.0711-2.8005 0-5.071-2.2704-5.071-5.0711 0-2.8005 2.2705-5.071 5.071-5.071 2.8007 0 5.0711 2.2705 5.0711 5.071Z"
                        fill={props.fill ?? '#fff'}
                    />
                </svg>

                <button className={styles['info-button']} onClick={() => setShow(true)}>
                    <AiOutlineQuestionCircle />
                </button>
            </div>

            <Modal
                show={show}
                close={{ handler: () => setShow(false) }}
                header={{ enable: false }}
                footer={{ enable: false }}
                maxWidth={420}>
                <p>{props.infoMessage}</p>
            </Modal>
        </>
    );
};
