import { Avatar, ButtonIcon } from 'components/elements';
import { useAuth, useTheme } from 'hooks';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import styles from './Nav.module.scss';

export type SectionProps = {
    backButton: boolean;
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
    hasImage?: boolean;
};

export const Nav: React.FC<SectionProps> = ({ backButton, setShowMenu, hasImage }) => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { activeTheme } = useTheme();

    const backBtnClassName = `${styles['back-btn']} ${styles[activeTheme]}`;

    if (!hasImage || !user) return null;

    return (
        <nav className={styles.nav}>
            {backButton ? (
                <ButtonIcon
                    icon="ARROW_LEFT"
                    size="XS"
                    backgroundOpacity="TRANSPARENT"
                    className={backBtnClassName}
                    onClick={() => navigate(-1)}
                />
            ) : (
                <Link to="/myhi">
                    <Avatar
                        source={user.image}
                        alt="utilizador"
                        className={styles.avatar}
                        hasNotifications={user.new_notifications}
                    />
                </Link>
            )}
            <ButtonIcon
                icon="BURGER"
                size="MD"
                backgroundOpacity="TRANSPARENT"
                onClick={() => setShowMenu(true)}
                className={`${styles['menu-button']} ${styles[activeTheme]} tour-step-4`}
            />
        </nav>
    );
};

export const MemoizedNav = React.memo(
    Nav,
    (prevProps, nextProps) => prevProps.setShowMenu !== nextProps.setShowMenu
);
