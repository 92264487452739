import { Avatar, ButtonIcon, Tag } from 'components/elements';
import { useLanguage } from 'hooks';
import { AreaType } from 'models/area';
import React from 'react';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS } from 'theme/colors';
import { IconStar } from 'theme/icons';

import styles from './CardProfile.module.scss';

export type CardProfileProps = React.HTMLProps<HTMLDivElement> & {
    id?: string;
    name: string;
    locations: string[];
    platforms: ('outdoor' | 'indoor' | 'online' | 'domicilio')[];
    images: {
        profile: string | null;
        profile_2x: string | null;
    };
    price: number;
    tags: { pt: string; en: string }[];
    rating: number;
    slug: string;
    languages: ('PT' | 'EN' | 'ES' | 'FR')[];
    theme: 'light' | 'dark';
    cardSize: 'highlight' | 'standard';
    area: Omit<AreaType, 'health' | 'hps' | 'articles'>;
};

const CardProfileComponent: React.FC<CardProfileProps> = ({
    name,
    locations,
    platforms,
    images,
    price,
    tags,
    rating,
    languages,
    slug,
    theme,
    cardSize,
    area,
    className = '',
    ...props
}) => {
    const navigate = useNavigate();
    const { isPT } = useLanguage();
    const { profile, profile_2x } = images;
    const TAG_LENGTH = 24;

    const handleClick = (slug: string) => {
        const location = area === 'nutrition' ? 'nutricao/nutricionistas/' : 'movimento/coaches/';
        navigate(`/${location}${slug}`);
    };

    const tagContent = useCallback(
        (tag) => {
            const PTTagTrimmed = tag.pt.trim();
            const ENTagTrimmed = tag.en.trim();

            if (PTTagTrimmed === '' || ENTagTrimmed === '') return '• • •';

            const shortenTag = (tag: string) => `${tag.slice(0, TAG_LENGTH)}...`;
            const isBigTag = (tag: string) => tag.length > TAG_LENGTH;

            const PTTag = isBigTag(PTTagTrimmed) ? shortenTag(PTTagTrimmed) : PTTagTrimmed;
            const ENTag = isBigTag(ENTagTrimmed) ? shortenTag(ENTagTrimmed) : ENTagTrimmed;

            return isPT ? PTTag : ENTag;
        },
        [isPT]
    );

    const ProfileName = useMemo(
        () => `${name.split(' ')[0]} ${name.split(' ')[name.split(' ').length - 1]}`,
        [name]
    );

    return (
        <div
            className={`${styles.card} ${styles[theme]} ${styles[cardSize]} ${className}`}
            {...props}>
            <div className={styles['card-body']}>
                <span className={styles.wrapper}>
                    <figure>
                        <div className={styles.price}>
                            <Tag area="movement" format="fill" tagSize="sm">
                                €{price}
                            </Tag>
                        </div>
                        <Avatar
                            source={profile_2x || profile}
                            width={90}
                            height={90}
                            alt={ProfileName}
                        />
                    </figure>

                    <span className={styles.bioContent}>
                        <span className={styles.locations}>
                            {locations.map((local, i) => (
                                <span key={i.toString()}>
                                    {local}
                                    {i < locations.length - 1 ? ', ' : ''}
                                </span>
                            ))}
                        </span>
                        <h2 className={cardSize === 'highlight' ? styles.highlight : undefined}>
                            {ProfileName}
                        </h2>
                        <span className={styles.platforms}>
                            {platforms.map((platform, i) => (
                                <span key={i.toString()}>
                                    {platform}
                                    {i < platforms.length - 1 ? ' • ' : ''}
                                </span>
                            ))}
                        </span>
                    </span>
                </span>

                <span className={styles.tags}>
                    {tags?.length > 3 ? (
                        <>
                            {tags.map((tag, i) => {
                                if (i < 3)
                                    return (
                                        <Tag theme={theme} tagSize="xs" key={i.toString()}>
                                            {tagContent(tag)}
                                        </Tag>
                                    );
                            })}
                            <span className={styles.tagsCount}>+{tags.length}</span>
                        </>
                    ) : (
                        tags.map((tag, i) => (
                            <Tag theme={theme} tagSize="xs" key={i.toString()}>
                                {tagContent(tag)}
                            </Tag>
                        ))
                    )}
                </span>

                <span className={styles.rating}>
                    <IconStar className={rating >= 1 ? styles.on : undefined} />
                    <IconStar className={rating >= 2 ? styles.on : undefined} />
                    <IconStar className={rating >= 3 ? styles.on : undefined} />
                </span>
            </div>

            <div className={styles['card-footer']}>
                <span className={styles.languages}>
                    {languages.map((lang, i) => (
                        <Tag
                            theme={theme}
                            tagSize="sm"
                            format="outline"
                            area="movement"
                            square
                            key={i.toString()}>
                            {lang.slice(0, 2)}
                        </Tag>
                    ))}
                </span>
                <ButtonIcon
                    icon="PLUS"
                    size="XS"
                    backgroundColor={COLORS.BLUE_400}
                    onClick={() => handleClick(slug)}
                    className={styles.button}
                />
            </div>
        </div>
    );
};

export const CardProfile = React.memo(
    CardProfileComponent,
    (prevProps, nextProps) => prevProps.cardSize !== nextProps.cardSize
);
