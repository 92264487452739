import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconTwitter: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 25,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 25 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path d="M0 17.75a10.65 10.65 0 0 0 7.53-2.07 5.29 5.29 0 0 1-4.78-3.52 6 6 0 0 0 2.28-.1 5.17 5.17 0 0 1-2.95-1.81A4.93 4.93 0 0 1 .96 7.06a5.41 5.41 0 0 0 2.23.6A5.13 5.13 0 0 1 1.67.91a14.76 14.76 0 0 0 10.57 5.3c0-.5-.06-1 0-1.45A5.07 5.07 0 0 1 15.97.2a5.12 5.12 0 0 1 5 1.27.33.33 0 0 0 .34.09A10.45 10.45 0 0 0 24.37.38a5.24 5.24 0 0 1-2.12 2.74 10 10 0 0 0 2.81-.73 9.68 9.68 0 0 1-2.27 2.38.6.6 0 0 0-.28.6 14.28 14.28 0 0 1-12.5 14.5 15.58 15.58 0 0 1-5.23-.19 14.5 14.5 0 0 1-4.62-1.85Z" />
        </svg>
    );
};
