import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconSkype: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path d="M.49 10.06c.06-.52.13-1.08.18-1.64a.66.66 0 0 0-.06-.39A5.5 5.5 0 0 1 3 .61a5.57 5.57 0 0 1 5.3.13.45.45 0 0 0 .3.07 9.6 9.6 0 0 1 11 8 .88.88 0 0 0 0 .16 9.07 9.07 0 0 1-.1 2.94.81.81 0 0 0 .05.46 5.51 5.51 0 0 1-7.51 7.1.66.66 0 0 0-.37 0 9.56 9.56 0 0 1-11-7.73c-.1-.6-.12-1.12-.18-1.68Zm9.56 6.53c.57-.06 1.15-.1 1.72-.2a5 5 0 0 0 3-1.51 3.44 3.44 0 0 0-.11-4.81 5.19 5.19 0 0 0-2.23-1.13c-1-.29-2.07-.51-3.1-.8a4.57 4.57 0 0 1-1.28-.56.87.87 0 0 1-.34-1.18.92.92 0 0 1 .29-.3 1.36 1.36 0 0 1 .47-.29 6.2 6.2 0 0 1 1.19-.27 2.52 2.52 0 0 1 2.7 1.4 2.56 2.56 0 0 0 .43.62 1.31 1.31 0 0 0 1.38.3 1.16 1.16 0 0 0 .9-1 1.94 1.94 0 0 0-.38-1.54 3.91 3.91 0 0 0-1.75-1.36 8.06 8.06 0 0 0-4.07-.46 6.13 6.13 0 0 0-2.68.92 3.09 3.09 0 0 0-.94 4.28 2.9 2.9 0 0 0 .5.6 4.43 4.43 0 0 0 1.81 1c1 .28 1.95.48 2.91.74a11.34 11.34 0 0 1 1.51.55 1.12 1.12 0 0 1 .64.75 1.4 1.4 0 0 1-.69 1.54 3.07 3.07 0 0 1-1.51.46c-1.68.12-2.51-.39-3.09-1.67a4.36 4.36 0 0 0-.33-.6 1.37 1.37 0 0 0-1.7-.51 1.25 1.25 0 0 0-.81 1.38 2.7 2.7 0 0 0 .08.41 4.11 4.11 0 0 0 2.51 2.63 8.49 8.49 0 0 0 3 .57Z" />
        </svg>
    );
};
