import { CardAlternative } from 'components/elements';
import { useTheme } from 'hooks';
import React from 'react';

import styles from './NutritionalAssessmentBanner.module.scss';

type NutritionalAssessmentBannerProps = {
    card: {
        TITLE: string;
        DESCRIPTION: string;
        BUTTON_LABEL: string;
    };
    showBanner: boolean | null;
};

const NutritionalAssessmentBanner: React.FC<NutritionalAssessmentBannerProps> = (props) => {
    const { activeTheme } = useTheme();

    if (!props.showBanner) return null;

    return (
        <section className={[styles['assessment'], styles[activeTheme], 'container'].join(' ')}>
            <CardAlternative
                title={props.card.TITLE}
                description={props.card.DESCRIPTION}
                slug="/nutricao/avaliacao"
                buttonLabel={props.card.BUTTON_LABEL}
                area="nutrition"
                hasImage={false}
                hasPoints={false}
                className={styles.card}
            />
        </section>
    );
};
export const MemoizedNutritionalAssessmentBanner = React.memo(NutritionalAssessmentBanner);
