type TitleOptionData = { id: number; title: string };

const TITLE_OPTIONS: TitleOptionData[] = [
    {
        id: 1,
        title: 'Yoga dá-me asas'
    },
    {
        id: 2,
        title: 'Escute seu corpo'
    },
    {
        id: 3,
        title: 'Em busca da minha paz interior'
    },
    {
        id: 4,
        title: 'Inspire amor, expire gratidão'
    },
    {
        id: 5,
        title: 'A prática de silenciar a mente'
    },
    {
        id: 6,
        title: 'A mente acredita, o corpo alcança'
    }
];

const ORIENTATION_TO_ANGLE: { [key: string]: number } = {
    '3': 180,
    '6': 90,
    '8': -90
};

export { TITLE_OPTIONS, ORIENTATION_TO_ANGLE };
