import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconSearch: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                d="m2710.82 930-3.55-3.55a9.18 9.18 0 1 0-1.06 1.06l3.55 3.55a.75.75 0 0 0 1.06-1.06Zm-10.57-2.05a7.46 7.46 0 0 1 0-14.92 7.38 7.38 0 0 1 5.27 2.18 7.46 7.46 0 0 1-5.27 12.74Z"
                transform="translate(-2691.04 -911.24)"
            />
        </svg>
    );
};
