import { useTheme } from 'hooks';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { IconAlertRounded } from 'theme/icons';

import styles from './ErrorButton.module.scss';

export type ErrorButtonProps = {
    handleErrorMessage: () => void;
    hasError: boolean;
    position?: 'CENTER-RIGHT' | 'TOP-RIGHT' | 'DEFAULT';
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const ErrorButton: React.FC<ErrorButtonProps> = ({
    handleErrorMessage,
    hasError = false,
    position = 'DEFAULT',
    ...props
}) => {
    const { activeTheme } = useTheme();
    return (
        <button
            className={[
                styles.errorButton,
                hasError ? styles.error : '',
                position === 'CENTER-RIGHT' ? styles.centerRight : '',
                position === 'TOP-RIGHT' ? styles.topRight : '',
                styles[activeTheme]
            ].join(' ')}
            onClick={handleErrorMessage}
            {...props}>
            <IconAlertRounded />
        </button>
    );
};
