/**
 * IMPORTANT:
 * `useAuth` CAN'T USE `useGamification`
 * since `GamificationProvider` is children of `AuthProvider`
 * see `AppProvider`
 */
import { AUTH } from 'content';
import { useLanguage } from 'hooks';
import { CancellationRequestStatus, PerformanceData, UserData, UserType } from 'models/types';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, isAxiosError } from 'services';
import { DEV } from 'utils';

export type AccountStatus = 'active' | 'inactive' | 'canceled';
export type PaymentStatus = 'ok' | 'with-debt';

interface SignInCredentials {
    email: string;
    password: string;
}

interface AuthContextData {
    user: UserData | undefined;
    token: string | undefined;
    setUser: React.Dispatch<React.SetStateAction<UserData | undefined>>;
    setToken: React.Dispatch<React.SetStateAction<string | undefined>>;
    signIn(credentials: SignInCredentials): Promise<void>;
    signOut(): void;
    updateUser(user: UserData): void;
    validateUserAccessToArea(userTypes: UserType[] | undefined): void;
    userCanAccess: (userTypes: UserType[]) => boolean;
    accountStatus: AccountStatus | undefined;
    getPaymentStatus: () => Promise<PaymentStatus | undefined>;
    getCancelationRequestStatus: () => Promise<CancellationRequestStatus | undefined>;
}

const ONE_DAY = 1000 * 60 * 60 * 24;
const THIRTY_SECONDS = 1000 * 30;

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }: { children?: React.ReactNode }) => {
    const navigate = useNavigate();
    const { isPT } = useLanguage();

    const CONTENT = isPT ? AUTH.PT : AUTH.EN;

    const [token, setToken] = useState(() => {
        const token = localStorage.getItem('@hi:token');
        if (!token) return;
        api.defaults.headers.common.Authorization = `Bearer ${process.env.REACT_APP_API_TOKEN}`;
        return token;
    });
    const [user, setUser] = useState(() => {
        const user = localStorage.getItem('@hi:user');
        if (!user) return;
        return JSON.parse(user) as UserData;
    });

    /**
     * access to app
     * accountStatus === 'active' || 'inactive'
     */
    const [accountStatus, setAccountStatus] = useState<AccountStatus>();

    const updateUser = useCallback((user: UserData) => {
        localStorage.setItem('@hi:user', JSON.stringify(user));
        setUser(user);
    }, []);

    const removeUser = useCallback(() => {
        setUser(undefined);
        localStorage.removeItem('@hi:user');

        setToken(undefined);
        localStorage.removeItem('@hi:token');

        setAccountStatus(undefined);
        localStorage.removeItem('@hi:status');

        DEV && console.log('%c USER REMOVED ', 'background: #540202; font-size: 16px');
    }, []);

    const signIn = useCallback(
        async ({ email, password }) => {
            try {
                const { data } = await api.post(`users/login`, {
                    email,
                    password
                });

                const user: UserData = data.user;

                if (!user) throw CONTENT.ERROR_MESSAGE;

                updateUser(user);

                // TEMPORARY STATIC TOKEN
                setToken(process.env.REACT_APP_API_TOKEN);
                api.defaults.headers.common.Authorization = `Bearer ${process.env.REACT_APP_API_TOKEN}`;
                localStorage.setItem('@hi:token', process.env.REACT_APP_API_TOKEN || '');

                // REFRESH TOKEN (ON HOLD)
                //-- localStorage.setItem('@hi:token', token);

                DEV && console.log('%c USER ADDED ', 'background: #020c54; font-size: 16px');
            } catch (error) {
                throw CONTENT.ERROR_MESSAGE;
                // if (isAxiosError(error)) {
                //     const errorMsg = error.response?.data.error;
                //     if (errorMsg === 'wrong_login' || errorMsg === 'not_found')
                // }
            }
        },
        [CONTENT.ERROR_MESSAGE, updateUser]
    );

    const signOut = useCallback(() => {
        removeUser();
        navigate('/');
    }, [navigate, removeUser]);

    const validateUserAccessToArea = useCallback(
        (userTypes: UserType[]) => {
            if (!user) return;
            if (!user.type && !userTypes) return;

            if (userTypes) {
                if (!userTypes?.includes(user.type)) navigate('/inicio');
                return;
            }
        },
        [navigate, user]
    );

    const userCanAccess = useCallback(
        (userTypes: UserType[]) => {
            if (!user?.type || !userTypes) return false;
            return userTypes?.includes(user.type);
        },
        [user?.type]
    );

    const getStatus = useCallback(async () => {
        if (!user?.id || !token) return;
        DEV && console.log('%c GET_STATUS', 'background-color: yellow; color: black;');

        try {
            const response = await api.get(`users/${user.id}/status`);
            const reset: boolean = response.data.reset;
            const performance: PerformanceData = response.data.performance;
            const status: AccountStatus = response.data.status;
            if (reset || !status || status === 'canceled') {
                removeUser();
                return;
            }

            /**
             * also updates `userPerformance` on `useGamification` hook
             * see `useEffect`
             */
            if (performance) {
                updateUser({
                    ...user,
                    performance
                });
            }

            setAccountStatus(status);

            if (status === 'active' || status === 'inactive') {
                localStorage.setItem('@hi:status', JSON.stringify(Date.now()));
            }
            DEV && console.log('%c PAY STATUS UPDATE ', 'background: #025420; font-size: 16px');
        } catch (error) {
            if (isAxiosError(error)) {
                const status: AccountStatus = error.response?.data.status;
                if (status === 'canceled') removeUser();
            }
        }
    }, [removeUser, token, updateUser, user]);

    const handleStatus = useCallback(async () => {
        if (!user) return;

        const verify = async () => {
            DEV && console.log(`%c STATUS CHECK `, 'background: #545302; font-size: 16px');
            const status = localStorage.getItem('@hi:status');

            if (!status) {
                await getStatus();
                return;
            }

            const TIME_LIMIT = process.env.NODE_ENV === 'production' ? ONE_DAY : THIRTY_SECONDS;

            if (Date.now() - JSON.parse(status) > TIME_LIMIT) {
                DEV && console.log('%c STATUS UPDATE ', 'background: #095402; font-size: 16px');
                await getStatus();
            }
        };

        await verify();
    }, [getStatus, user]);

    const getPaymentStatus = useCallback(async () => {
        if (!user?.id) return;
        DEV && console.log('%c getPaymentStatus', 'background-color: orange; color: blue;');

        try {
            const { data } = await api.get(`users/${user.id}/payment/status`);
            return data.payment as PaymentStatus;
        } catch (error) {
            // TO-DO
            DEV && console.log(error);
        }
    }, [user?.id]);

    const getCancelationRequestStatus = useCallback(async () => {
        if (!user?.id) return;

        try {
            const { data } = await api.get(`users/${user.id}/cancellation/status`);
            DEV &&
                console.log(
                    '%c getCancelationRequestStatus',
                    'background-color: blue; color: orange;'
                );
            return data.cancellation as CancellationRequestStatus;
        } catch (error) {
            // TO-DO
            DEV && console.log(error);
        }
    }, [user?.id]);

    useEffect(() => {
        handleStatus();
    }, [handleStatus]);

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                token,
                setToken,
                signIn,
                signOut,
                updateUser,
                validateUserAccessToArea,
                userCanAccess,
                accountStatus,
                getPaymentStatus,
                getCancelationRequestStatus
            }}>
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth(): AuthContextData {
    const context = useContext(AuthContext);
    if (!context) throw new Error('useAuth must be used withing an AuthProvider');
    return context;
}
