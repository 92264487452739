export const MOVEMENT_HOME = {
    PT: {
        TITLE: 'Movimento',
        PARTNERS: {
            TITLE: 'Parceiros',
            DESCRIPTION: 'As melhores vantagens por ser Hi!'
        },
        POINTS: {
            TITLE: 'Pontos'
        },
        TIP: {
            TITLE: 'dica da semana'
        },
        PROMOS: {
            TITLE: 'Promos'
        },
        ACTIVITIES: {
            TITLE: {
                SINGULAR: 'Atividades sugeridas',
                PLURAL: 'Atividade sugerida'
            },
            DESCRIPTION: 'Conquiste mais pontos'
        }
    },
    EN: {
        TITLE: 'Movement',
        PARTNERS: {
            TITLE: 'Partners',
            DESCRIPTION: 'The best advantages of being Hi!'
        },
        POINTS: {
            TITLE: 'Points'
        },
        TIP: {
            TITLE: 'tip of the week'
        },
        PROMOS: {
            TITLE: 'Promos'
        },
        ACTIVITIES: {
            TITLE: {
                SINGULAR: 'Suggested activities',
                PLURAL: 'Suggested activity'
            },
            DESCRIPTION: 'Score more points'
        }
    }
};
