import { useTheme } from 'hooks';
import { useEffect, useState } from 'react';
import { COLORS } from 'theme/colors';
import { IconAreaProps } from 'theme/icons';

export const IconNutritionV1: React.FC<IconAreaProps> = ({
    color,
    width = 61,
    height = 70,
    variation,
    colorType,
    ...props
}) => {
    const { darkMode } = useTheme();
    const [themeClassName, setThemeClassName] = useState('');
    useEffect(
        () => setThemeClassName(color ? color : darkMode ? COLORS.NEUTRAL_800 : COLORS.WHITE),
        [color, darkMode]
    );

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 61 70"
            width={width}
            height={height}
            {...props}>
            <defs>
                <linearGradient
                    id="nutrition-linear-gradient"
                    x1="324.1"
                    y1="-261.4"
                    x2="325.1"
                    y2="-261.4"
                    gradientTransform="matrix(55 0 0 -64 -14724.9 -15185.2)"
                    gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor={COLORS.RED_200} />
                    <stop offset="1" stopColor={COLORS.MAGENTA_500} />
                </linearGradient>
            </defs>
            {variation === 'FILL' && (
                <path
                    id="fill"
                    fill={
                        colorType === 'GRADIENT'
                            ? 'url(#nutrition-linear-gradient)'
                            : themeClassName
                    }
                    d="M3144.5 1525.2a19 19 0 0 0-15.2 2.7c.8-14.6 6-17.3 6.1-17.4a1 1 0 0 0 .5-1.2 1 1 0 0 0-1.3-.5c-.2 0-3.3 1.5-5.4 7.8a11 11 0 0 0-2.5-4 11.5 11.5 0 0 0-11-2.8 1 1 0 0 0-.6.7 10.8 10.8 0 0 0 2.8 10.7 11.4 11.4 0 0 0 8 3.2 11.8 11.8 0 0 0 1.8-.2 56 56 0 0 0-.3 3.8 19 19 0 0 0-15.3-2.8c-10.4 3-15.4 16.7-11.3 30.5a29.9 29.9 0 0 0 13.6 17.8 16.5 16.5 0 0 0 12.6 1.6 13.2 13.2 0 0 0 1.3-.5 13.9 13.9 0 0 0 1.4.5 15 15 0 0 0 4.3.6 17.8 17.8 0 0 0 8.3-2.2 30 30 0 0 0 13.6-17.8c4.1-13.8-1-27.5-11.4-30.5Z"
                    transform="translate(-3097.8 -1507.2)"
                />
            )}
            {variation === 'OUTLINE' && (
                <path
                    id="stroke"
                    fill={
                        colorType === 'GRADIENT'
                            ? 'url(#nutrition-linear-gradient)'
                            : themeClassName
                    }
                    d="M3134 1577.2a16.6 16.6 0 0 1-4.8-.7l-1-.3-1 .3a18.1 18.1 0 0 1-13.7-1.7 31.3 31.3 0 0 1-14.3-18.7c-4.3-14.5 1.2-29 12.3-32.4a17.4 17.4 0 0 1 6.6-.5 13 13 0 0 1-1.2-1 12.3 12.3 0 0 1-3.3-12.1 2.4 2.4 0 0 1 1.7-1.7 13 13 0 0 1 12.3 3.2 12 12 0 0 1 1.4 1.6c2.2-4.5 4.7-5.6 5-5.8a2.5 2.5 0 0 1 3.2 1.2 2.4 2.4 0 0 1-1.2 3.2c-.3.1-4 2.7-5.1 13.5a20 20 0 0 1 14-1.5c11.1 3.3 16.6 17.8 12.3 32.3a31.2 31.2 0 0 1-14.3 18.8 19.2 19.2 0 0 1-9 2.3Zm-5.7-4a1.5 1.5 0 0 1 .5.1 13 13 0 0 0 1.3.4 13.7 13.7 0 0 0 3.9.6 16.4 16.4 0 0 0 7.6-2 28.6 28.6 0 0 0 13-17c3.9-13-.8-26-10.4-28.8-4.3-1.3-9.6-.3-14 2.6a1.5 1.5 0 0 1-1.5 0 1.1 1.1 0 0 1-.2-.2 1.6 1.6 0 0 1-.4.3 1.5 1.5 0 0 1-1.4-.1c-4.5-2.9-9.9-4-14.2-2.6-9.6 2.8-14.3 15.7-10.4 28.8 2.3 7.5 7.1 13.9 13 17a15.2 15.2 0 0 0 11.5 1.4c.4 0 .8-.2 1.2-.4a1.5 1.5 0 0 1 .5 0Zm-.6-50.5a1.5 1.5 0 0 1 .6.2 39.5 39.5 0 0 1 1-4.9 1.4 1.4 0 0 1-1.4-1 9.5 9.5 0 0 0-2.3-3.5 10 10 0 0 0-9.2-2.5 9.5 9.5 0 0 0 2.6 9 9.9 9.9 0 0 0 6.9 2.8 10.3 10.3 0 0 0 1.5-.1Zm-11.6-11.6Zm18.7-2Z"
                    transform="translate(-3097.8 -1507.2)"
                />
            )}
        </svg>
    );
};
