export const SPLASH_SCREEN = {
    PT: {
        LOADING: 'A carregar...',
        VERSION: 'ver 0.1.0'
    },
    EN: {
        LOADING: 'Loading...',
        VERSION: 'ver 0.1.0'
    }
};
