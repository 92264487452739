import { Button } from 'components/elements';
import { SHARE } from 'content';
import { useLanguage, useTheme, useWebShare } from 'hooks';
import { useCallback } from 'react';

import styles from './ErrorMessage.module.scss';

export const ErrorMessage: React.FC = () => {
    const { setHasError } = useWebShare();
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();

    const CONTENT = isPT ? SHARE.PT.ERROR : SHARE.EN.ERROR;

    const onCLick = useCallback(() => {
        setHasError(false);
        window.location.reload();
    }, [setHasError]);

    return (
        <div className={styles['error-message']}>
            <strong>{CONTENT.TITLE}</strong>
            <span>{CONTENT.DESCRIPTION}</span>
            <Button
                label="repetir"
                size="md"
                className={[styles['repeat-button'], styles[activeTheme]].join(' ')}
                onClick={onCLick}
            />
        </div>
    );
};
