// import { ButtonIcon } from 'components/elements';
import { useTheme } from 'hooks';

// import { COLORS } from 'theme/colors';
import styles from './CardHalfV3.module.scss';

export type CardHalfV3Props = React.HTMLProps<HTMLDivElement> & {
    name: string;
    logo: string;
};

export const CardHalfV3: React.FC<CardHalfV3Props> = ({ name, logo, className = '', ...props }) => {
    const { activeTheme } = useTheme();
    return (
        <div className={`${styles.card} ${className} ${styles[activeTheme]}`} {...props}>
            <img src={logo} alt={name} />
            {/* <ButtonIcon
                icon="PLUS"
                size="XS"
                backgroundColor={COLORS.BLUE_400}
                onClick={handleClick}
            /> */}
        </div>
    );
};
