export const PASSWORD_RESET = {
    PT: {
        VALIDATION_MESSAGES: {
            PASSWORD: [
                'palavra-passe obrigatória',
                'insira uma palavra-passe com o mínimo de 6 caracteres alfa-numéricos'
            ],
            CONFIRM: 'a palavra-passe não coincide',
            EXPIRED_TOKEN: 'Link expirado. Por favor solicite uma nova recuperação de palavra-passe'
        },
        TITLE: 'Nova Palavra-Passe',
        DESCRIPTION: 'Adicione uma nova palavra-passe à sua conta Hi!',
        LABELS: {
            NEW_PASSWORD: 'nova palavra-passe',
            CONFIRM_PASSWORD: 'confirme palavra-passe',
            SUBMIT: 'Adicionar'
        },
        MODAL: {
            TITLE: 'Password atualizada com sucesso',
            DESCRIPTION: [
                {
                    TEXT: 'Já pode continuar a aceder à sua conta Hi! e aproveitar todas as vantagens.'
                }
            ],
            BUTTON: {
                CLOSE: 'Aceder'
            }
        }
    },
    EN: {
        VALIDATION_MESSAGES: {
            PASSWORD: [
                'required password',
                'enter a password with a minimum of 6 alpha-numeric characters'
            ],
            CONFIRM: "the password doesn't match",
            EXPIRED_TOKEN: 'Link expired. Please request a new password retrieval'
        },
        TITLE: 'New Password',
        DESCRIPTION: 'Add a new password to your Hi! account.',
        LABELS: {
            NEW_PASSWORD: 'new password',
            CONFIRM_PASSWORD: 'confirm password',
            SUBMIT: 'Add'
        },
        MODAL: {
            TITLE: 'Password updated successfully',
            DESCRIPTION: [
                {
                    TEXT: 'You can now log on into your Hi! account and enjoy all the benefits.'
                }
            ],
            BUTTON: {
                CLOSE: 'Access'
            }
        }
    }
};
