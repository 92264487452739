export type ActivityOptionsData = {
    label: {
        pt: string;
        en: string;
    };
    value: string;
};

export type DurationOptionsData = {
    label: {
        pt: string;
        en: string;
    };
    value: string;
};

export type SelectOptionsData = {
    activity: ActivityOptionsData[];
    duration: DurationOptionsData[];
};

export const options: SelectOptionsData = {
    activity: [
        { label: { pt: 'Caminhada', en: 'Walking' }, value: 'walk' },
        { label: { pt: 'Correr', en: 'Running' }, value: 'run' },
        { label: { pt: 'Bicicleta', en: 'Cycling' }, value: 'bike' },
        { label: { pt: 'Elíptico', en: 'Elliptical' }, value: 'elliptical' },
        { label: { pt: 'Bicicleta Estática', en: 'Stationary Bike' }, value: 'indoor_bike' },
        { label: { pt: 'Passadeira', en: 'Treadmill' }, value: 'treadmill' },
        { label: { pt: 'Remar', en: 'Rowing' }, value: 'rowing' },
        { label: { pt: 'Stepper', en: 'Stepper' }, value: 'stepper' },
        { label: { pt: 'HIIT', en: 'HIIT' }, value: 'hiit' },
        { label: { pt: 'Ioga', en: 'Yoga' }, value: 'yoga' },
        {
            label: { pt: 'Treino de força funcional', en: 'Functional Training' },
            value: 'functional'
        },
        { label: { pt: 'Dança', en: 'Dance' }, value: 'dance' },
        { label: { pt: 'Arrefecimento', en: 'Cool Down' }, value: 'cool_down' },
        { label: { pt: 'Treino de core', en: 'Core Training' }, value: 'core' },
        { label: { pt: 'Pilates', en: 'Pilates' }, value: 'pilates' },
        { label: { pt: 'Tai Chi', en: 'Tai Chi' }, value: 'tai_chi' },
        { label: { pt: 'Natação', en: 'Swimming' }, value: 'swim' },
        { label: { pt: 'Surf', en: 'Surf' }, value: 'surf' },
        { label: { pt: 'Outro', en: 'Other' }, value: 'other' }
    ],
    duration: [
        { label: { pt: '5 a 30 min.', en: '5 to 30 min.' }, value: '5-30' },
        { label: { pt: '30 a 60 min.', en: '30 to 60 min.' }, value: '30-60' },
        { label: { pt: 'mais de 60 min.', en: 'over 60 min.' }, value: '60+' }
    ]
};
