import { PromosList } from '../../types';

export const PROMOS_LIST: PromosList = {
    PT: {
        HEADER: {
            TITLE: 'Promos'
        },
        INTRO: {
            TITLE: 'Promoções exclusivas Hi!',
            DESCRIPTION: 'Consulta aqui todas as promoções exclusivas para clientes Hi!'
        },
        LIST: {
            NO_RESULTS: {
                TITLE: 'Em breve',
                DESCRIPTION: 'De momento não temos promoções ativas'
            }
        }
    },
    EN: {
        HEADER: {
            TITLE: 'Promos'
        },
        INTRO: {
            TITLE: 'Exclusive Hi! promotions',
            DESCRIPTION: 'Check here all exclusive promotions for Hi! customers.'
        },
        LIST: {
            NO_RESULTS: {
                TITLE: 'Soon',
                DESCRIPTION: 'At the moment we have no active promotions'
            }
        }
    }
};
