import * as IMAGES from 'constants/images';
import { format } from 'date-fns';
import ENLocale from 'date-fns/locale/en-GB';
import PTLocale from 'date-fns/locale/pt';
import { sanitize } from 'dompurify';
import { useLanguage, useTheme } from 'hooks';
import { useCallback } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import styles from './CardArticleV2.module.scss';

export type CardArticleV2Props = LinkProps & {
    image: string;
    title: string;
    description: string;
    category?: string;
    showCategory?: boolean;
    duration: number;
    level: string;
    date: string;
    isNew?: boolean;
};

export const CardArticleV2: React.FC<CardArticleV2Props> = ({
    image,
    title,
    description,
    category,
    showCategory,
    duration,
    level,
    date,
    isNew = false,
    ...props
}) => {
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();

    const onImageError = useCallback((evt) => (evt.target.src = IMAGES.VIDEO_PLACEHOLDER), []);

    const note = `<b>${showCategory ? category : ''}</b> ${showCategory ? '•' : ''} ${Math.trunc(
        duration / 60
    )}min • 
    ${level} • ${format(new Date(date), 'd MMM yyyy', {
        locale: isPT ? PTLocale : ENLocale
    })}`;

    return (
        <Link className={styles.card} {...props}>
            <div className={`${styles['image-container']} ${styles[activeTheme]}`}>
                <img src={image} alt={title} onError={onImageError} />
            </div>
            <div className={`${styles.info} ${styles[activeTheme]}`}>
                <div>
                    <h3>{`${title.slice(0, 60)}${title.length > 60 ? '...' : ''}`}</h3>
                    <p className={styles.description}>
                        {`${description.slice(0, 120)}${description.length > 120 ? '...' : ''}`}
                    </p>
                    <p
                        className={styles.note}
                        dangerouslySetInnerHTML={{ __html: sanitize(note) }}
                    />
                </div>
                {isNew && <div className={styles.label}>{isPT ? 'Novo' : 'New'}</div>}
            </div>
        </Link>
    );
};
