import { useTheme } from 'hooks';
import { useEffect, useState } from 'react';
import { COLORS } from 'theme/colors';
import { IconAreaProps } from 'theme/icons';

export const IconMovementV1: React.FC<IconAreaProps> = ({
    color,
    width = 70,
    height = 70,
    variation,
    colorType,
    ...props
}) => {
    const { darkMode } = useTheme();
    const [themeClassName, setThemeClassName] = useState('');
    useEffect(
        () => setThemeClassName(color ? color : darkMode ? COLORS.NEUTRAL_800 : COLORS.WHITE),
        [color, darkMode]
    );

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 70 70"
            width={width}
            height={height}
            {...props}>
            <defs>
                <linearGradient
                    id="movement-linear-gradient"
                    x1="314.94"
                    y1="-261.31"
                    x2="315.73"
                    y2="-261.3"
                    gradientTransform="matrix(66 0 0 -64 -17783.14 -15185.11)"
                    gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor={COLORS.BLUE_400} />
                    <stop offset="1" stopColor={COLORS.GREEN_200} />
                </linearGradient>
            </defs>
            {variation === 'FILL' && (
                <path
                    id="fill"
                    fill={
                        colorType === 'GRADIENT' ? 'url(#movement-linear-gradient)' : themeClassName
                    }
                    d="M3054.36 1547c-3.39-11.44-9.56-19.12-31.08-24.5a83.43 83.43 0 0 1-33.54-17.07.63.63 0 0 0-.68-.14.83.83 0 0 0-.46.61c-.15.72-3.28 17.31 22.33 29.38-2 .06-5.79-.58-13-4a.64.64 0 0 0-.72.13 1 1 0 0 0-.25.82 22.41 22.41 0 0 0 4.68 10c3.31 4.08 9.58 8.77 20.94 8.86-1.79.7-4.89 1.23-10 .39a.64.64 0 0 0-.61.25 1 1 0 0 0-.2.73 15.3 15.3 0 0 0 2.18 5.79 13 13 0 0 0 11 6.1c2 .09 3.85.1 5.65.11 8.29 0 14.29.09 18.92 7.42a.72.72 0 0 0 .59.34h.06a.75.75 0 0 0 .51-.36c.32-.52 7.32-12.64 3.68-24.86Z"
                    transform="translate(-2986.91 -1503.71)"
                />
            )}
            {variation === 'OUTLINE' && (
                <path
                    id="stroke"
                    fill={
                        colorType === 'GRADIENT' ? 'url(#movement-linear-gradient)' : themeClassName
                    }
                    d="M3050.17 1573.71a2.21 2.21 0 0 1-1.85-1.07c-4.18-6.61-9.55-6.64-17.67-6.69-1.67 0-3.62 0-5.72-.12a14.45 14.45 0 0 1-12.18-6.79 16.87 16.87 0 0 1-2.39-6.35 2.48 2.48 0 0 1 .51-1.88 1.37 1.37 0 0 1 .19-.22 24.21 24.21 0 0 1-10.51-7.44 23.9 23.9 0 0 1-5-10.69 2.45 2.45 0 0 1 .65-2.12 2.09 2.09 0 0 1 2.41-.44l1.63.76c-15.66-11.57-13.22-24.49-13.09-25.13a2.28 2.28 0 0 1 1.3-1.65 2.13 2.13 0 0 1 2.27.37 82.44 82.44 0 0 0 32.94 16.76c22.23 5.56 28.63 13.57 32.18 25.53 3.88 13.1-3.76 26-3.83 26.09a2.16 2.16 0 0 1-1.55 1.06h-.26Zm-.73-2.59Zm1.34-.06Zm-37.37-18a13.63 13.63 0 0 0 1.81 4.36 11.58 11.58 0 0 0 9.8 5.42c2.05.09 4 .1 5.6.11 8 0 14.46.09 19.43 7 1.66-3.28 5.79-12.9 2.92-22.6-3.24-10.93-9.2-18.28-30-23.5a85.42 85.42 0 0 1-33.05-16.47c-.23 4.09.9 16.7 21.67 26.48a1.48 1.48 0 0 1-.58 2.82c-2.86.11-6.95-1.07-12.24-3.48a20.83 20.83 0 0 0 4.08 8c3 3.76 8.95 8.24 19.83 8.33a1.48 1.48 0 0 1 .53 2.85c-2.52 1.05-5.8 1.27-9.8.72Zm-23.38-47-.03.03Z"
                    transform="translate(-2986.91 -1503.71)"
                />
            )}
        </svg>
    );
};
