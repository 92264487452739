import { Button } from 'components/elements';
import { getCroppedImg } from 'components/elements/ImageCropDialog/canvasUtils';
import { useTheme } from 'hooks';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';

import styles from './CropDialog.module.scss';

type CropDialogProps = {
    selectedUserPhoto: string;
    setShowCropDialog: Dispatch<SetStateAction<boolean>>;
    setCroppedPhoto: Dispatch<SetStateAction<string | undefined>>;
    setSelectedUserPhoto: Dispatch<SetStateAction<string | undefined>>;
};

export const CropDialog: React.FC<CropDialogProps> = ({
    selectedUserPhoto,
    setShowCropDialog,
    setCroppedPhoto,
    setSelectedUserPhoto
}) => {
    const { activeTheme } = useTheme();

    const [zoom, setZoom] = useState(1);
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });

    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({} as Area);

    const onCancel = () => setShowCropDialog(false);

    const onZoomChange = (zoom: number) => setZoom(zoom);
    const onCropChange = (crop: Point) => setCrop(crop);
    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) =>
        setCroppedAreaPixels(croppedAreaPixels);

    const onCrop = async () => {
        if (!selectedUserPhoto) return;
        const croppedImgBase64 = await getCroppedImg(selectedUserPhoto, croppedAreaPixels);

        if (!croppedImgBase64) return;
        setCroppedPhoto(croppedImgBase64);
        setSelectedUserPhoto(croppedImgBase64);
        setShowCropDialog(false);
    };

    return (
        <div className={styles['crop-dialog']}>
            <div className={[styles['crop-container'], styles[activeTheme]].join(' ')}>
                <Cropper
                    image={selectedUserPhoto}
                    zoom={zoom}
                    crop={crop}
                    aspect={1}
                    showGrid={false}
                    onCropChange={onCropChange}
                    onZoomChange={onZoomChange}
                    onCropComplete={onCropComplete}
                    classes={{
                        containerClassName: styles['cropper-container'],
                        mediaClassName: styles['cropper-media'],
                        cropAreaClassName: styles['cropper-area']
                    }}
                />
            </div>
            <div className={[styles.controls, styles[activeTheme]].join(' ')}>
                <div className={styles['controls-upper-area']}>
                    <input
                        aria-labelledby="Zoom"
                        type="range"
                        min={1}
                        max={3}
                        step={0.1}
                        value={zoom}
                        onInput={(e: ChangeEvent<HTMLInputElement>) =>
                            setZoom(Number(e.target.value))
                        }
                        className={styles.slider}
                    />
                </div>
                <div className={styles['button-container']}>
                    <Button
                        label="Cancelar"
                        size="md"
                        onClick={onCancel}
                        className={[styles.button, styles.cancel, styles[activeTheme]].join(' ')}
                    />
                    <Button
                        label="Seguinte"
                        size="md"
                        onClick={onCrop}
                        className={[styles.button, styles.save, styles[activeTheme]].join(' ')}
                    />
                </div>
            </div>
        </div>
    );
};
