import { AreaType } from 'models/types';

export const areaToPT = (area: AreaType): string => {
    switch (area) {
        case 'movement':
            return 'movimento';

        case 'nutrition':
            return 'nutrição';

        case 'health':
            return 'saúde';

        case 'hps':
            return 'hps';

        case 'articles':
            return 'artigos';

        case 'promos':
            return 'promos';

        case 'bonus':
            return 'bónus';

        default:
            return '';
    }
};
