import { LogoHi } from 'assets/logos';
import { Button, Input, LangSelect, Link, Modal } from 'components/elements';
import { META_TAGS, PASSWORD_RECOVER } from 'content';
import { useFormik } from 'formik';
import { useApi, useAuth, useLanguage } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { COLORS } from 'theme';
import { IconMail } from 'theme/icons';
import * as Yup from 'yup';

import styles from '../../Access.module.scss';

export type PasswordRecoverProps = React.HTMLProps<HTMLDivElement>;

type FormValues = {
    email: string;
};

export const PasswordRecover: React.FC<PasswordRecoverProps> = (props) => {
    const navigate = useNavigate();
    const { isPT } = useLanguage();
    const { user } = useAuth();
    const { postUserPasswordRecover } = useApi();

    const [showModal, setShowModal] = useState(false);

    const CONTENT = isPT ? PASSWORD_RECOVER.PT : PASSWORD_RECOVER.EN;

    useEffect(() => {
        if (user) navigate('/inicio');
    }, [navigate, user]);

    const initialValues = { email: '' };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required(CONTENT.VALIDATION_MESSAGES.EMAIL[0])
            .email(CONTENT.VALIDATION_MESSAGES.EMAIL[1])
    });

    const onSubmit = useCallback(
        async (values: FormValues) => {
            try {
                await postUserPasswordRecover(values.email);
                setShowModal(true);
            } catch (error) {
                setShowModal(true);
            }
        },
        [postUserPasswordRecover]
    );

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    return (
        <>
            <Helmet>
                <title>
                    {isPT ? META_TAGS.PUBLIC.RECOVER.PT.TITLE : META_TAGS.PUBLIC.RECOVER.EN.TITLE}
                </title>
            </Helmet>
            <div className={styles.container} {...props}>
                <RouterLink to="/">
                    <LogoHi animate showLettering={false} width={72} className={styles.logo} />
                </RouterLink>
                <span>
                    <h1>
                        {CONTENT.TITLE[0]}
                        <br />
                        {CONTENT.TITLE[1]}
                    </h1>
                    <p>
                        {CONTENT.DESCRIPTION[0]}
                        <br /> {CONTENT.DESCRIPTION[1]}
                    </p>
                </span>
                <form onSubmit={formik.handleSubmit} className={styles.form}>
                    <Input
                        type="email"
                        label={CONTENT.LABELS.EMAIL}
                        autoComplete="username"
                        className={styles.input}
                        hasError={formik.touched.email && formik.errors.email ? true : false}
                        errorMessage={formik.errors.email}
                        {...formik.getFieldProps('email')}
                    />
                    <Button type="submit" label={CONTENT.LABELS.SUBMIT} size="lg" fullWidth />
                </form>
                <Link label={CONTENT.LINKS.LOGIN} to="/login" size="xs" type="link" />
                <p className={styles.register}>&nbsp;</p>
                <LangSelect className={styles['lang-button']} />
            </div>
            <Modal
                show={showModal}
                className={[styles.modal, styles.info].join(' ')}
                footer={{ enable: false }}
                close={{ handler: () => setShowModal(false), buttonColor: COLORS.MAGENTA_500 }}
                background={{ color: COLORS.WHITE }}
                overlay={{ style: 'dark' }}>
                <IconMail color={COLORS.MAGENTA_500} width={64} height={56} />
                <h2>{CONTENT.MODAL.TITLE}</h2>
                <p>{CONTENT.MODAL.DESCRIPTION[0].TEXT}.</p>
                <Button
                    label={CONTENT.MODAL.BUTTON.CLOSE}
                    className={styles.button}
                    size="md"
                    onClick={() => setShowModal(false)}
                />
            </Modal>
        </>
    );
};
