export const NUTRITION_ASSESSMENT_OPTIONS = {
    PT: {
        FORM_OPTIONS: {
            MEDICAL: {
                UNSUPERVISED: 'Sem vigilância',
                WHEN_PROBLEM_APPEARS: 'Quando surge um problema',
                ANNUAL_CHECKUP: 'Faço um check-up anual',
                REGULAR_BY_CHRONIC_CONDITION: 'Regular, por condição crónica'
            },
            WEIGHT_OBJECTIVE: {
                OK_WITH_CURRENT_WEIGHT: 'Sinto-me bem com o peso atual',
                GAIN_MUSCLE_MASS: 'Não tendo problemas de peso mas quero ganhar massa muscular',
                GAIN_WEIGHT: 'Quero ganhar peso',
                LOSE_WEIGHT: 'Quero perder peso'
            },
            EXERCISE: {
                NONE: 'Não pratico',
                ONE_TO_TWO_PER_WEEK: '1 a 2 vezes por semana',
                THREE_TO_FOUR_PER_WEEK: '3 a 4 vezes por semana',
                EVERY_DAY: 'Todos os dias'
            },
            SMOKER: {
                NO: 'Não',
                YES: 'Sim',
                OCCASIONALLY: 'Ocasionalmente'
            },
            SLEEP_DIFFICULTY: {
                EASY: 'É fácil',
                DIFFICULTY: 'Tenho dificuldade',
                MEDICATED: 'Tomo medicação'
            },
            FOOD_INTAKE: {
                DIVERSIFIED: 'Completa, variada e equilibrada',
                CAN_IMPROVE: 'Tenho cuidado mas posso melhorar',
                BAD: 'Pouco cuidada, com défices ou excessos'
            },
            ALCOHOL_INTAKE: {
                NO: 'Não bebo',
                MEALS_AND_SOCIALLY: 'Bebo às refeições e socialmente',
                MORE_THAN_THREE_PER_DAY: 'Bebo mais de três bebidas por dia'
            },
            STRESS: {
                NO_SYMPTOMS: 'Sem sintomas',
                SOME_SYMPTOMS: 'Sintomas pontuais',
                CHRONIC_SYMPTOMS:
                    'Insónias, falta de memória, falta de ar ou outros sintomas crónicos'
            },
            ILLNESSES: {
                NO: 'Nenhuma',
                HYPERTENSION: 'Hipertensão arterial (ainda que medicada)',
                DIABETES: 'Diabetes (ainda que medicada)',
                CHOLESTEROL: 'Colesterol ou triglicéridos elevados (dislipidemias)',
                OTHER: 'Outra (respiratória, alérgica, osteoarticular)'
            }
        }
    },
    EN: {
        FORM_OPTIONS: {
            MEDICAL: {
                UNSUPERVISED: 'Unattended',
                WHEN_PROBLEM_APPEARS: 'When an issue arises',
                ANNUAL_CHECKUP: 'I have an annual check-up',
                REGULAR_BY_CHRONIC_CONDITION: 'Regular, by chronic condition'
            },
            WEIGHT_OBJECTIVE: {
                OK_WITH_CURRENT_WEIGHT: 'I feel fine at my current weight',
                GAIN_MUSCLE_MASS: 'Not having weight problems but I want to gain muscle mass',
                GAIN_WEIGHT: 'I want to gain weight',
                LOSE_WEIGHT: 'I want to lose weight'
            },
            EXERCISE: {
                NONE: "I don't practice",
                ONE_TO_TWO_PER_WEEK: '1 to 2 times a week',
                THREE_TO_FOUR_PER_WEEK: '3 to 4 times a week',
                EVERY_DAY: 'Every day'
            },
            SMOKER: {
                NO: 'No',
                YES: 'Yes',
                OCCASIONALLY: 'Occasionally'
            },
            SLEEP_DIFFICULTY: {
                EASY: "It's easy",
                DIFFICULTY: 'I have difficulty',
                MEDICATED: 'I take medication'
            },
            FOOD_INTAKE: {
                DIVERSIFIED: 'Complete, varied and balanced',
                CAN_IMPROVE: "I'm careful but I can improve",
                BAD: 'Little cared for, with deficits or excesses'
            },
            ALCOHOL_INTAKE: {
                NO: "I don't drink",
                MEALS_AND_SOCIALLY: 'I drink at meals and socially',
                MORE_THAN_THREE_PER_DAY: 'I drink more than three drinks a day'
            },
            STRESS: {
                NO_SYMPTOMS: 'No symptoms',
                SOME_SYMPTOMS: 'Occasional symptoms',
                CHRONIC_SYMPTOMS:
                    'Insomnia, lack of memory, shortness of breath or other chronic symptoms'
            },
            ILLNESSES: {
                NO: 'None',
                HYPERTENSION: 'Hypertension (even if medicated)',
                DIABETES: 'Diabetes (even if medicated)',
                CHOLESTEROL: 'High cholesterol or triglycerides (dyslipidemias)',
                OTHER: 'Other (respiratory, allergic, osteoarticular)'
            }
        }
    }
};

export const NUTRITION_ASSESSMENT_STEPS = {
    PT: {
        INTRO: {
            TITLE: ['Questionário', 'de Nutrição'],
            DESCRIPTION: [
                {
                    ID: 1,
                    TEXT: 'A experiência Hi! integra um acompanhamento nutricional dedicado e personalizado.'
                },
                {
                    ID: 2,
                    TEXT: 'Comece por preencher os próximos campos do questionário para o/a ficarmos a conhecer melhor e propormos uma oferta adequada às suas necessidades.'
                },
                {
                    ID: 3,
                    TEXT: 'Ligue-se a um estilo de vida saudável!'
                }
            ],
            BUTTON_LABEL: 'Começar'
        },
        IMC: {
            LABELS: {
                WEIGHT: 'Peso',
                HEIGHT: 'Altura',
                BMI: 'Índice de massa corporal',
                HOW_TO: 'Como calculamos o seu IMC?'
            },
            BMI: {
                THINNESS: 'Magreza',
                NORMAL: 'Normal',
                OVERWEIGHT: 'Sobrepeso',
                OBESITY: 'Obesidade'
            },
            MODAL: [
                {
                    ID: 1,
                    TYPE: 'h2',
                    TEXT: 'Índice de Massa Corporal'
                },
                {
                    ID: 2,
                    TYPE: 'p',
                    TEXT: 'A classificação do índice de massa corporal (IMC), pode ajudar a identificar obesidade ou desnutrição em crianças, adolescentes, adultos e idosos.'
                },
                {
                    ID: 3,
                    TYPE: 'p',
                    TEXT: 'Com o cálculo do IMC, é possível fazer algumas mudanças na rotina diária, como mudar a dieta, melhorar os hábitos alimentares e praticar atividade física de forma regular, por exemplo.'
                },
                {
                    ID: 4,
                    TYPE: 'p',
                    TEXT: 'O IMC é a relação entre peso e altura e o cálculo é feito de acordo com a fórmula:'
                },
                {
                    ID: 5,
                    TYPE: 'highlight',
                    TEXT: 'IMC = peso / (altura x altura)'
                },
                {
                    ID: 6,
                    TYPE: 'p',
                    TEXT: 'O peso deve estar em kg e a altura em metros, e o resultado é dado em kg/m2. Depois de obter o resultado, é possível verificar em que faixa o IMC se encontra, podendo indicar:'
                },
                {
                    ID: 7,
                    TYPE: 'ul',
                    ITEMS: [
                        {
                            ID: 1,
                            TEXT: ['Magreza', ', quando o resultado é menor que 18,5 kg/m2']
                        },
                        {
                            ID: 2,
                            TEXT: ['Normal', ', quando o resultado está entre 18,5 e 24,9 kg/m2']
                        },
                        {
                            ID: 3,
                            TEXT: ['Sobrepeso', ', quando o resultado está entre 24,9 e 30 kg/m2']
                        },
                        {
                            ID: 4,
                            TEXT: ['Obesidade', ', quando o resultado é maior que 30 kg/m2']
                        }
                    ]
                }
            ]
        },
        MEDICAL: {
            TITLE: 'Acompanhamento médico'
        },
        WEIGHT_OBJECTIVE: {
            TITLE: 'O meu peso'
        },
        EXERCISE: {
            TITLE: 'Atividade física'
        },
        SMOKER: {
            TITLE: 'Fumador/a'
        },
        SLEEP_DIFFICULTY: {
            TITLE: 'Adormecer e dormir'
        },
        FOOD_INTAKE: {
            TITLE: 'Alimentação'
        },
        ALCOHOL_INTAKE: {
            TITLE: 'Bebidas alcoólicas'
        },
        STRESS: {
            TITLE: 'Stress e Fadiga'
        },
        ILLNESSES: {
            TITLE: 'Doenças crónicas'
        },
        RESUME: {
            LABELS: {
                GENDER: 'Género',
                WEIGHT: 'Peso',
                HEIGHT: 'Altura',
                IMC: 'IMC',
                MEDICAL: 'Acompanhamento médico',
                WEIGHT_OBJECTIVE: 'O meu peso',
                EXERCISE: 'Atividade física',
                SMOKER: 'Fumador/a',
                SLEEP_DIFFICULTY: 'Adormecer e dormir',
                FOOD_INTAKE: 'Alimentação',
                ALCOHOL_INTAKE: 'Bebidas alcoólicas',
                STRESS: 'Stress e Fadiga',
                ILLNESSES: 'Doenças crónicas'
            },
            TITLE: 'Resumo',
            SUBMIT: 'Finalizar'
        },
        MESSAGE: {
            TITLE: 'Resultado',
            STORE_BUTTON_LABEL: 'Visitar loja'
        }
    },
    EN: {
        INTRO: {
            TITLE: ['Nutritional', 'Assessment'],
            DESCRIPTION: [
                {
                    ID: 1,
                    TEXT: 'The Hi! experience integrates dedicated and personalized nutritional monitoring.'
                },
                {
                    ID: 2,
                    TEXT: 'Start by filling in the next fields of the questionnaire so that we can get to know you better and propose an offer tailored to your needs.'
                },
                {
                    ID: 3,
                    TEXT: 'Get connected to a healthy lifestyle!'
                }
            ],
            BUTTON_LABEL: 'Start'
        },
        IMC: {
            LABELS: {
                WEIGHT: 'Weight',
                HEIGHT: 'Height',
                BMI: 'Body mass index',
                HOW_TO: 'How do we calculate your BMI?'
            },
            BMI: {
                THINNESS: 'Thinness',
                NORMAL: 'Normal',
                OVERWEIGHT: 'Overweight',
                OBESITY: 'Obesity'
            },
            MODAL: [
                {
                    ID: 1,
                    TYPE: 'h2',
                    TEXT: 'Body Mass Index'
                },
                {
                    ID: 2,
                    TYPE: 'p',
                    TEXT: 'Body mass index (BMI) classification can help identify obesity or malnutrition in children, adolescents, adults and the elderly.'
                },
                {
                    ID: 3,
                    TYPE: 'p',
                    TEXT: 'By calculating the BMI, it is possible to make some changes in the daily routine, such as changing the diet, improving eating habits and practising regular physical activity, for example.'
                },
                {
                    ID: 4,
                    TYPE: 'p',
                    TEXT: 'BMI is the relationship between weight and height and is calculated according to the formula:'
                },
                {
                    ID: 5,
                    TYPE: 'highlight',
                    TEXT: 'BMI = weight / (height x height)'
                },
                {
                    ID: 6,
                    TYPE: 'p',
                    TEXT: 'The weight must be in kg and the height in meters, and the result is given in kg/m2. After obtaining the result, it is possible to check what range the BMI is in, and it can indicate:'
                },
                {
                    ID: 7,
                    TYPE: 'ul',
                    ITEMS: [
                        {
                            ID: 1,
                            TEXT: ['Thinness', ', when the result is less than 18.5 kg/m2']
                        },
                        {
                            ID: 2,
                            TEXT: ['Normal', ', when the result is between 18.5 and 24.9 kg/m2']
                        },
                        {
                            ID: 3,
                            TEXT: ['Overweight', ', when the result is between 24.9 and 30 kg/m2']
                        },
                        {
                            ID: 4,
                            TEXT: ['Obesity', ', when the result is greater than 30 kg/m2']
                        }
                    ]
                }
            ]
        },
        MEDICAL: {
            TITLE: 'Medical follow-up'
        },
        WEIGHT_OBJECTIVE: {
            TITLE: 'My weight'
        },
        EXERCISE: {
            TITLE: 'Physical activity'
        },
        SMOKER: {
            TITLE: 'Smoker'
        },
        SLEEP_DIFFICULTY: {
            TITLE: 'Falling asleep and sleeping'
        },
        FOOD_INTAKE: {
            TITLE: 'Food'
        },
        ALCOHOL_INTAKE: {
            TITLE: 'Drinking Alcohol'
        },
        STRESS: {
            TITLE: 'Stress and Fatigue'
        },
        ILLNESSES: {
            TITLE: 'Chronic diseases'
        },
        RESUME: {
            LABELS: {
                GENDER: 'Gender',
                WEIGHT: 'Weight',
                HEIGHT: 'Height',
                IMC: 'BMI',
                MEDICAL: 'Medical follow-up',
                WEIGHT_OBJECTIVE: 'My weight',
                EXERCISE: 'Physical activity',
                SMOKER: 'Smoker',
                SLEEP_DIFFICULTY: 'Falling asleep and sleeping',
                FOOD_INTAKE: 'Eating',
                ALCOHOL_INTAKE: 'Drinking Alcohol',
                STRESS: 'Stress and Fatigue',
                ILLNESSES: 'Chronic diseases'
            },
            TITLE: 'Summary',
            SUBMIT: 'Finnish'
        },
        MESSAGE: {
            TITLE: 'Result',
            STORE_BUTTON_LABEL: 'Visit shop'
        }
    }
};
