import { useTheme } from 'hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IconArrow, IconMinusRounded, IconPlusRounded } from 'theme/icons';

import styles from './Accordion.module.scss';

export type AccordionProps = React.HTMLAttributes<HTMLDivElement> & {
    buttonTitle: string;
    isOpen: boolean;
    onAccordionClick?: () => void;
    icon: 'ARROW' | 'CIRCLE';
    borders: boolean;
    size: 'MD' | 'LG';
    className?: string;
};

export const Accordion: React.FC<AccordionProps> = ({
    buttonTitle,
    children,
    isOpen,
    onAccordionClick,
    icon,
    size = 'MD',
    borders = true,
    className = '',
    ...props
}: AccordionProps) => {
    const { activeTheme } = useTheme();
    const contentSpace = useRef<HTMLDivElement>(null);

    const [active, setActive] = useState(false);
    const [height, setHeight] = useState('0px');

    const getContentHeight = useCallback(
        () => (active ? `${contentSpace?.current?.scrollHeight}px` || 'auto' : `0px`),
        [active]
    );

    const toggleAccordion = useCallback(() => {
        if (onAccordionClick) onAccordionClick();
        setActive((state) => !state);
        setHeight(getContentHeight);
    }, [getContentHeight, onAccordionClick]);

    useEffect(() => setActive(isOpen), [isOpen]);

    useEffect(() => setHeight(getContentHeight), [getContentHeight]);

    const accordionClassName = useMemo(
        () =>
            [
                styles.container,
                borders ? styles.borders : '',
                size === 'LG' ? styles.lg : '',
                styles[activeTheme],
                className
            ].join(' '),
        [activeTheme, borders, className, size]
    );

    return (
        <div className={accordionClassName} {...props}>
            <button className={styles.button} onClick={toggleAccordion}>
                <span>{buttonTitle}</span>
                {icon === 'ARROW' && <IconArrow className={active ? styles.open : undefined} />}
                {icon === 'CIRCLE' && active && <IconMinusRounded className={styles.open} />}
                {icon === 'CIRCLE' && !active && <IconPlusRounded />}
            </button>
            <div
                ref={contentSpace}
                className={[styles.content, active ? styles.open : ''].join(' ')}
                style={{ height }}>
                {children}
            </div>
        </div>
    );
};
