import { useTheme } from 'hooks';
import { useLanguage } from 'hooks';
import React, { useState } from 'react';
import { IconArrow } from 'theme/icons';

import styles from './Filter.module.scss';

export type FilterProps = {
    label: string;
    options: string[];
    handleFilter(item: string | null): void;
};

export const Filter: React.FC<FilterProps> = ({ label, options, handleFilter }: FilterProps) => {
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();
    const [showFilterOptions, setShowFilterOptions] = useState(false);

    return (
        <div
            className={[
                styles.filter,
                showFilterOptions ? styles.show : '',
                styles[activeTheme]
            ].join(' ')}>
            <button onClick={() => setShowFilterOptions(!showFilterOptions)}>
                <span>{label}</span> <IconArrow />
            </button>
            <div className={styles.list}>
                <button
                    onClick={() => {
                        handleFilter('');
                        setShowFilterOptions(false);
                    }}>
                    {isPT ? 'todos' : 'all'}
                </button>
                {options.map((item, i) => {
                    if (item !== '')
                        return (
                            <button
                                key={i.toString()}
                                onClick={() => {
                                    handleFilter(item);
                                    setShowFilterOptions(false);
                                }}>
                                {`${item.slice(0, 26).toLowerCase()}${
                                    item.length > 26 ? '...' : ''
                                }`}
                            </button>
                        );
                })}
            </div>
        </div>
    );
};
