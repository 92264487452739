import React, { Suspense } from 'react';

const BgEffect = React.lazy(
    () =>
        import(
            /* webpackChunkName: "BgEffect" */
            'assets/effects/BgEffect'
        )
);

type WithBackgroundProps = {
    element: JSX.Element;
};

export const WithBackground: React.FC<WithBackgroundProps> = ({ element }) => (
    <>
        {element}
        <Suspense fallback={null}>
            <BgEffect />
        </Suspense>
    </>
);
