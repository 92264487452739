import { COLORS } from 'theme';

export type IllustrationProps = React.HtmlHTMLAttributes<HTMLOrSVGElement>;

export const SlideFourFlag: React.FC<IllustrationProps> = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 336">
        <defs>
            {/* <!-- <symbol id="i-hi" data-name="i-hi" viewBox="0 0 47.33 47.09">
      <path fill={COLORS.WHITE}
        d="M46.84 23.42h-.48a22.92 22.92 0 0 1-31.61 21.23A23.08 23.08 0 0 1 7.19 7.07a23.49 23.49 0 0 1 7.49-5 23.22 23.22 0 0 1 32.16 21.35 23.42 23.42 0 0 0-46.84 0A23.65 23.65 0 0 0 40.32 40.2a23.79 23.79 0 0 0 7-16.78Zm-4.19-8A21 21 0 0 0 31.53 3.9a20.66 20.66 0 0 0-3.93-1.25 21.81 21.81 0 0 0-4.11-.44 21.22 21.22 0 0 0-8.09 1.6 21 21 0 0 0-6.83 4.62A21.31 21.31 0 0 0 4 15.33c-.22.53-.41 1.07-.58 1.61.62-.86 1.26-1.71 1.94-2.52a20.44 20.44 0 0 1 37 1.12 19.89 19.89 0 0 1 1.21 3.86 20.54 20.54 0 0 1-1.14 12A20.78 20.78 0 0 1 38 38a20.53 20.53 0 0 1-6.69 4.4 20.33 20.33 0 0 1-22.18-4.53 20.45 20.45 0 0 1-4.37-6.64 20.24 20.24 0 0 1-1.15-3.87l-.1.34-.31 1.11a21.27 21.27 0 0 0 .91 2.69 20.88 20.88 0 0 0 19.38 12.91 20.55 20.55 0 0 0 14.65-6.25 21.19 21.19 0 0 0 6-14.73 21.52 21.52 0 0 0-1.5-8ZM5 17.71a7.2 7.2 0 0 0 .72-1.23 7.41 7.41 0 0 0-1.06.95 11.77 11.77 0 0 0-.85 1.12l-.84 1.14-.82 1.15c.33-.34.65-.69 1-1l1-1A13.31 13.31 0 0 0 5 17.71Zm7-1.79v16h2.94v-6.23h7v6.19h2.92v-16h-2.92v6.33h-7v-6.33Zm20.54 10.61a1 1 0 0 0 1 1 1 1 0 0 0 1-1l.43-10h-2.85Zm-.45 4A1.49 1.49 0 1 0 33.58 29a1.49 1.49 0 0 0-1.49 1.53Zm-5 1.39H30V19.68h-2.95Zm2.78-17.49L29.73 12l-1.34 2-2.33-.66 1.5 1.9-1.34 2 2.27-.84L30 18.35c0-.81-.07-1.61-.1-2.41l2.23-.83Z" />
    </symbol> --> */}
            <symbol id="i-euro-round" data-name="i-euro-round" viewBox="0 0 64.59 64.59">
                <path
                    fill={COLORS.WHITE}
                    d="M32.3 64.6a32.3 32.3 0 1 1 32.3-32.3 32.34 32.34 0 0 1-32.3 32.3Zm0-48.87A16.38 16.38 0 0 0 16.15 32.3 16.38 16.38 0 0 0 32.3 48.87 16.39 16.39 0 0 0 48.45 32.3 16.38 16.38 0 0 0 32.3 15.73Z"
                    style={{ opacity: 0.3 }}
                />
                <path
                    fill={COLORS.WHITE}
                    d="M37.46 24.27a8.59 8.59 0 0 1 6.06 2.54 2 2 0 0 0 2.86-.08 2.15 2.15 0 0 0-.08-3A12.42 12.42 0 0 0 37.49 20c-5.79 0-10.7 4.31-12.66 10.33h-3.3a2.09 2.09 0 0 0 0 4.17h2.38a16 16 0 0 0-.11 1.83 17.53 17.53 0 0 0 .14 1.9H21.6a2.09 2.09 0 0 0 0 4.17h3.23c2.07 6 7 10.17 12.83 10.13a12.53 12.53 0 0 0 8.75-3.8 2.15 2.15 0 0 0 0-3 2 2 0 0 0-2.87 0 8.47 8.47 0 0 1-6 2.64c-3.6 0-6.73-2.41-8.4-6l10.6-.08a2.09 2.09 0 0 0 0-4.17L28 38.23c-.06-.63-.13-1.19-.14-1.89a17.65 17.65 0 0 1 .14-1.83l11.83-.09a2.09 2.09 0 0 0 0-4.17l-10.67.08c1.67-3.55 4.76-6 8.36-6.06Z"
                />
            </symbol>
            <symbol id="i-wing" data-name="i-wing" viewBox="0 0 26.92 30.65">
                <path
                    fill={COLORS.BLUE_400}
                    d="M12.06 20.2c-.72-.21-1.41-.38-2.08-.6a12.42 12.42 0 0 1-5.88-3.8A8.24 8.24 0 0 1 2.15 11c0-.18 0-.4.17-.45a.71.71 0 0 1 .49.1 27.13 27.13 0 0 0 3.73 2.06c.49.2 1 .35 1.49.53v-.07l-1.49-1.06a20.49 20.49 0 0 1-4.4-4.17A9.72 9.72 0 0 1 0 2.87 5.76 5.76 0 0 1 .36.27C.48 0 .74-.09 1 .16a42.24 42.24 0 0 0 13.83 9.47 37.65 37.65 0 0 1 6.54 3.49 12.66 12.66 0 0 1 4 4.09 10.32 10.32 0 0 1 1.27 7.61 12.29 12.29 0 0 1-3.32 5.68c-.24.24-.48.19-.63-.13a6.76 6.76 0 0 0-4.16-3.49c-1.7-.57-3.46-.85-5.2-1.25a11.07 11.07 0 0 1-3.1-1 5.49 5.49 0 0 1-3-4.58 1.61 1.61 0 0 1 0-.22c0-.35.2-.46.54-.36.79.23 1.58.48 2.39.64a13.6 13.6 0 0 0 1.7.14Z"
                />
            </symbol>
            <symbol id="i-star" data-name="i-star" viewBox="0 0 18.2 18.2">
                <path
                    fill={COLORS.BLUISH_PURPLE_400}
                    d="m9.1 0 3.22 5.88L18.2 9.1l-5.88 3.22L9.1 18.2l-3.22-5.88L0 9.1l5.88-3.22L9.1 0z"
                />
            </symbol>
            <symbol id="i-graph" data-name="i-graph" viewBox="0 0 53.36 54.67">
                <path
                    fill={COLORS.BLUISH_PURPLE_400}
                    d="M26.84 54.67a26.14 26.14 0 0 1-15.72-5.27 27.85 27.85 0 0 1-6.24-38A26.08 26.08 0 0 1 22.25.35C36.75-2 50.54 8.13 53 23a27.54 27.54 0 0 1-4.5 20.25 26.12 26.12 0 0 1-17.37 11 25.71 25.71 0 0 1-4.29.42ZM26.47 14a12.82 12.82 0 0 0-1.95.16 12.24 12.24 0 0 0-8.12 5.21 13.86 13.86 0 0 0 3.09 18.78A12.16 12.16 0 0 0 37 35.29a13.57 13.57 0 0 0 2.19-10A13.16 13.16 0 0 0 26.47 14Z"
                    style={{ opacity: 0.4 }}
                />
                <path
                    fill={COLORS.BLUISH_PURPLE_400}
                    d="M24.52 30.06 31.26 33 36 22.38l-2.6-1.14-3.59 8-6.74-2.94-4.75 10.61 2.61 1.17Zm14.09 5 .42 2.6-22.54 3.7-2.93-17.82 2.64-.43-4.83-4.29-3.21 5.61 2.6-.43 3.39 20.64 25.35-4.16.42 2.59 4.29-4.83Z"
                />
            </symbol>
            <symbol id="i-heart" data-name="i-heart" viewBox="0 0 27.88 25.6">
                <path
                    fill={COLORS.PURPLE_600}
                    d="M27.31 4.35A7.3 7.3 0 0 0 19.14.17a7.32 7.32 0 0 0-5.41 7.33 7.29 7.29 0 0 0-7.86-4.6A7.3 7.3 0 0 0 0 10a9.52 9.52 0 0 0 3 6.41 28.42 28.42 0 0 0 6 4.14c2.94 1.71 5.65 3.34 8.58 5.05 2-2.73 3.7-5.25 5.72-8a28.16 28.16 0 0 0 3.8-6.18 9.52 9.52 0 0 0 .21-7.07Z"
                />
            </symbol>
            <symbol id="i-gift" data-name="i-gift" viewBox="0 0 75.96 75.96">
                <path
                    fill={COLORS.PURPLE_300}
                    d="M24.51 57.75 47.78 65a4.77 4.77 0 0 0 3.61-.33 4.72 4.72 0 0 0 2.32-2.79l3.48-11.15a4.75 4.75 0 0 0 3.09-3.11l1.41-4.53a4.73 4.73 0 0 0-.2-3.39 4.78 4.78 0 0 0-2.42-2.39A7.45 7.45 0 0 0 56 25a10.17 10.17 0 0 0-9 1.46 10.14 10.14 0 0 0-6.54-6.29 7.44 7.44 0 0 0-9.53 8.38 4.73 4.73 0 0 0-3.35.58 4.79 4.79 0 0 0-2.1 2.68l-1.4 4.53a4.73 4.73 0 0 0 .78 4.31L21.4 51.82a4.74 4.74 0 0 0 3.11 5.93Zm1.21-3.88a.66.66 0 0 1-.44-.84l3.22-10.35 10.34 3.22-3.42 11Zm24.12 6.8a.68.68 0 0 1-.34.4.65.65 0 0 1-.51 0l-9.7-3 3.42-11 10.35 3.22Zm6.56-14.26a.66.66 0 0 1-.33.4.7.7 0 0 1-.51 0l-11.64-3.58 1.81-5.81L57.37 41a.69.69 0 0 1 .4.33.67.67 0 0 1 0 .52Zm-1.63-17.52a3.38 3.38 0 1 1-2 6.46l-5.82-1.81.2-.65a6.1 6.1 0 0 1 7.63-4Zm-15.51-4.83a6.08 6.08 0 0 1 4 7.63l-.2.64-5.81-1.81a3.38 3.38 0 1 1 2-6.46Zm-9.89 9a.68.68 0 0 1 .85-.44l11.63 3.62L40 42l-11.59-3.6a.67.67 0 0 1-.45-.84Z"
                />
                <g style={{ opacity: 0.5 }}>
                    <path
                        fill={COLORS.PURPLE_300}
                        d="M38 76a38 38 0 1 1 38-38 38 38 0 0 1-38 38Zm0-58a20 20 0 1 0 20 20 20 20 0 0 0-20-20Z"
                    />
                </g>
            </symbol>
            <symbol id="i-calendar" data-name="i-calendar" viewBox="0 0 35.93 35.63">
                <path
                    fill={COLORS.PURPLE_300}
                    d="M7.23 21.11a1.07 1.07 0 0 1 .09-.82 1.09 1.09 0 0 1 .68-.52l9.39-2.71a1.13 1.13 0 0 1 1.06.26 1.1 1.1 0 0 1 .3 1.05 1.08 1.08 0 0 1-.75.78l-9.4 2.71a1.1 1.1 0 0 1-.83-.1 1 1 0 0 1-.54-.65Zm22-17.65 6.62 23a1.09 1.09 0 0 1-.1.83 1.06 1.06 0 0 1-.65.52L8 35.59a1.07 1.07 0 0 1-.82-.1 1.06 1.06 0 0 1-.52-.65L0 11.88a1.09 1.09 0 0 1 .1-.83 1.06 1.06 0 0 1 .65-.52L5 9.33 4.06 6.2a1.08 1.08 0 0 1 1.3-1.36 1.1 1.1 0 0 1 .79.76l.9 3.13 14.61-4.21-.9-3.13A1.08 1.08 0 0 1 21 .33a1.09 1.09 0 0 1 1.83.46l.9 3.13 4.18-1.21a1.12 1.12 0 0 1 .82.1 1.06 1.06 0 0 1 .54.65ZM29 10.32 3.93 17.54 8.44 33.2 33.5 26ZM10.67 29.16 25.29 25a1.09 1.09 0 0 0 .75-.79 1.08 1.08 0 0 0-1.35-1.3l-14.62 4.17a1.06 1.06 0 0 0-.75.78 1.08 1.08 0 0 0 .3 1 1.07 1.07 0 0 0 1.05.3Z"
                />
            </symbol>
            <symbol id="i-plane" data-name="i-plane" viewBox="0 0 79.8 79.79">
                <path
                    fill={COLORS.BLUE_200}
                    d="M68.67 41.36q-22-9.12-44-18.27c-.8-.33-1.64-.7-2.37.08s-.34 1.57.05 2.36l15.27 31.12c.87 1.76 1.35 1.91 3.17 1.07a14.25 14.25 0 0 0 1.93-.92c1.25-.84 2.05-.57 2.92.64 1.25 1.76 2.72 3.34 4.06 5a1.72 1.72 0 0 0 1.86.78c.74-.19 1-.81 1.17-1.5.78-3.21 1.64-6.4 2.35-9.63a2.24 2.24 0 0 1 1.41-1.76c4-1.84 8-3.75 12.05-5.6a5.17 5.17 0 0 0 1.73-1.18v-1.06a4 4 0 0 0-1.6-1.13ZM51.12 55.58c-.22.93-.44 1.86-.73 3-1-1.31-2-2.45-2.88-3.58 1-1 2.42-1.27 3.59-2 .16-.11.34-.32.68-.14l-.66 2.72Zm-10.07-1.26c-.78.38-1.11.3-1.51-.52-2.33-4.88-4.73-9.74-7.12-14.6l-4.12-8.4.16-.12 23.38 18.57c-3.75 1.75-7.29 3.38-10.79 5.07Zm14.69-6.87a1.33 1.33 0 0 1-1.66-.23c-4.66-3.75-9.34-7.45-14-11.17l-8.27-6.57.09-.15L65 43.1c-3.26 1.53-6.28 2.9-9.26 4.35Z"
                />
                <g style={{ opacity: 0.5 }}>
                    <path
                        fill={COLORS.BLUE_200}
                        d="M39.88 79.79A39.89 39.89 0 1 1 77.9 52a40 40 0 0 1-38.02 27.79Zm0-61.77a21.87 21.87 0 1 0 6.65 1A21.89 21.89 0 0 0 39.92 18Z"
                    />
                </g>
            </symbol>
            <symbol id="i-apple" data-name="i-apple" viewBox="0 0 25.93 30.99">
                <path
                    fill={COLORS.PURPLE_600}
                    d="m9.86 9.39-.42-1.64c-.37.13-.72.27-1.08.37A4.76 4.76 0 0 1 3.75 7a4.94 4.94 0 0 1-1.62-3.2.59.59 0 0 1 .31-.64A5 5 0 0 1 8.77 4l.2.23C9 4.12 9 4 9 4a7.19 7.19 0 0 1 .55-3A3.85 3.85 0 0 1 10 .2a.41.41 0 0 1 .63-.09c.19.17.18.39 0 .62a4.17 4.17 0 0 0-.74 2A11.12 11.12 0 0 0 10 6.28c.13.77.32 1.53.49 2.29a3.49 3.49 0 0 0 .16.49c.33-.35.63-.7 1-1a8.06 8.06 0 0 1 3.66-2.11 6.5 6.5 0 0 1 5 .69 9.82 9.82 0 0 1 3.84 4 15.14 15.14 0 0 1 1 2.29 15.26 15.26 0 0 1-.29 10.73 9.54 9.54 0 0 1-3.54 4.51 6.65 6.65 0 0 1-3.94 1.16.51.51 0 0 0-.31.07 7.07 7.07 0 0 1-5.85 1.48 11.48 11.48 0 0 1-5.42-2.52 15.13 15.13 0 0 1-4.13-5A13 13 0 0 1 .06 16a7.77 7.77 0 0 1 2-4.76 6.68 6.68 0 0 1 3.45-1.93 9.06 9.06 0 0 1 4.11 0ZM3.78 22.08a.39.39 0 0 0 .47.3.39.39 0 0 0 .23-.5.38.38 0 0 0-.47-.3.36.36 0 0 0-.23.5Z"
                />
            </symbol>
            <symbol id="i-cloud" data-name="i-cloud" viewBox="0 0 90.83 41">
                <path
                    d="M84.73 28.39c8.32.57 8 12.61-.29 12.61H8.17c-10.89 0-10.89-16.34 0-16.34a8.49 8.49 0 0 1 4.3 1.43 14.79 14.79 0 0 1 22.65-12.33C40-4.59 66.09-4.59 71 13.76a12 12 0 0 1 13.73 14.63Z"
                    fill={COLORS.WHITE}
                />
            </symbol>
        </defs>
        <g id="canvas">
            <path id="canvas-2" data-name="canvas" style={{ fill: 'none' }} d="M0 0h280v336H0z" />
        </g>
        <g id="fg">
            <ellipse
                cx="140.7"
                cy="321.49"
                rx="104.12"
                ry="13.41"
                fill={COLORS.PURPLE_600}
                style={{ opacity: 0.3 }}
            />
            <path
                fill={COLORS.BLUISH_PURPLE_400}
                d="M1258.58 106h-14.68a3.63 3.63 0 0 0-3.56 3.72v2.66a3.65 3.65 0 0 1-3.57 3.73h-28.61a3.65 3.65 0 0 1-3.56-3.73v-2.66a3.65 3.65 0 0 0-3.6-3.72h-18a11.56 11.56 0 0 0-11.56 11.55v186.24a11.55 11.55 0 0 0 11.56 11.5h75.55a11.56 11.56 0 0 0 11.56-11.55V117.49a11.55 11.55 0 0 0-11.53-11.49Z"
                transform="translate(-1080)"
            />
            {/* <!-- <use width="47.33" height="47.09" transform="translate(118.53 124.6)" xlink:href="#i-hi" /> --> */}
            <path
                d="M1258.86 100.44h-75.7a16.5 16.5 0 0 0-16.51 16.49v189.79a16.49 16.49 0 0 0 16.48 16.5h75.7a16.49 16.49 0 0 0 16.5-16.49V116.94a16.49 16.49 0 0 0-16.47-16.5ZM1270.28 304a11.55 11.55 0 0 1-11.55 11.55h-75.55a11.56 11.56 0 0 1-11.55-11.56V118.74a11.54 11.54 0 0 1 11.56-11.54h18a3.65 3.65 0 0 1 3.57 3.73v2.66a3.65 3.65 0 0 0 3.56 3.73H1237a3.64 3.64 0 0 0 3.57-3.72v-2.66a3.65 3.65 0 0 1 3.56-3.73h14.68a11.56 11.56 0 0 1 11.54 11.55Z"
                transform="translate(-1080)"
            />
            <path
                fill={COLORS.RED_100}
                d="m1224.8 263.26-2-6.43c-.48-1.36-1-3-1.54-4.88a14 14 0 0 1-.19-5.46c.36-2.55 1-3 1.4-4.42 0-.12.09-.27.14-.37a2.16 2.16 0 0 0 .17-.75 1.9 1.9 0 0 0-3.11-1.64c-1.15.84-2.89 2.44-3.72 8.49v.25c-.1 3.74-6.29-3.16-8.28-5.9-1.29-1.78-3.63-2-4.72-.42l-5.23 9.21a3.35 3.35 0 0 0-1.74 5.78l12.48 14 13.29 44.34 20.31-11.88Z"
                transform="translate(-1080)"
            />
            <path
                fill={COLORS.RED_200}
                d="M1210.63 244.4a32.35 32.35 0 0 1-7.08-3.11 27.07 27.07 0 0 1-2.31 7.49v.06a18.38 18.38 0 0 1 4 2.12 23.38 23.38 0 0 1 2.88 1.63 1.41 1.41 0 0 1-1.39 2.46 19 19 0 0 1-2.51-1.45c-1.39-.71-2.82-1.35-4.16-2.17a53.48 53.48 0 0 1-2.48 4.88c2.15.76 4.1 2.18 6.25 3l.14.05.24.09.34.14a1.1 1.1 0 0 1 .44.31 4.48 4.48 0 0 0 3.1-.81 4.24 4.24 0 0 0 .83-5.32c1.12.25 2-.76 2.12-1.95a7.15 7.15 0 0 0-1-3.62c1.4.14 2.43-1.22 2.41-2.76a5.3 5.3 0 0 0-.32-1.64 1.31 1.31 0 0 1-1.5.6ZM1218.2 254c0 .05-.56.23-1.38.74a7.74 7.74 0 0 0-2.59 2.76 9.69 9.69 0 0 0-1.11 3.93c-.08 1.1 0 1.82 0 1.82a2.7 2.7 0 0 1-.09-.51 8.7 8.7 0 0 1 0-1.35 9.14 9.14 0 0 1 1.08-4.06 7.06 7.06 0 0 1 2.7-2.76 6.67 6.67 0 0 1 1.06-.48c.18-.09.33-.09.33-.09ZM1219.73 269.77a21.51 21.51 0 0 1-2.57 2.18 19.89 19.89 0 0 1-2.74 2 20 20 0 0 1 2.57-2.19 19.26 19.26 0 0 1 2.74-1.99Z"
                transform="translate(-1080)"
            />
            <path
                transform="rotate(-159.93 675.52 331.53)"
                fill={COLORS.NEUTRAL_700}
                d="M1212.08 173.59h6.88v124.74h-6.88z"
            />
            <path
                fill={COLORS.RED_100}
                d="M1196.43 255.5c2.11.75 3.92 1.89 6.09 2.62 1 .33 2.66 1.07 3.56.78s1.33-.73 1.1-1.83c-.18-.89-1.23-1.64-1.91-2.07-1.77-1.13-3.3-1.93-4.66-2.82-.64-.42-.33-.76 0-.57 1 .42 2.33.56 3.35 1.09 1.41.71 2.43 2.15 4.07.55a1.76 1.76 0 0 0 .5-.79 3.24 3.24 0 0 0-1.21-3.09 9.15 9.15 0 0 0-2.24-1.38c1.49.66 3.08 1.35 4.46.72a2 2 0 0 0 1.11-2.29 3.93 3.93 0 0 0-2.46-2.55 33.38 33.38 0 0 0-5.68-2.22 2.55 2.55 0 0 0-2 .2 2.42 2.42 0 0 0-1.42 2 4.53 4.53 0 0 0 .93 2.79s-2.31-.08-3 1.82a6.46 6.46 0 0 0 .07 4Z"
                transform="translate(-1080)"
            />
            <path
                fill={COLORS.RED_100}
                d="M1201.42 241.17a3.46 3.46 0 0 1 1-3.13c.61-.62 1.3-1.19 1.76-1.19 2 0 8.87 4.68 7.95 6.72s-1.87 2.8-5.65.4a62.86 62.86 0 0 0-1.72-1.06l-1.73 1.42Z"
                transform="translate(-1080)"
            />
            <path
                fill={COLORS.RED_200}
                d="M1206.19 248.71a17.82 17.82 0 0 0-3.15-1.54 14.78 14.78 0 0 0-3.25-.54 2.29 2.29 0 0 1 .93-.11 8.08 8.08 0 0 1 2.35.39 9.41 9.41 0 0 1 2.27 1.11 3.15 3.15 0 0 1 .85.69ZM1210.11 245.62c.13.18-2.09-1.07-4.49-2.21-1.2-.57-2.22-1-2.88-1.26a6.08 6.08 0 0 1-1.06-.39c-.13-.11-.1-.34.45-.24a12.89 12.89 0 0 1 2.74 1.06 33.64 33.64 0 0 1 5.24 3.04ZM1203.13 253.7a7 7 0 0 1-1.24-.65 10.28 10.28 0 0 0-3-1.35 3.28 3.28 0 0 0-2.76.59 4.87 4.87 0 0 0-.75 1 .86.86 0 0 1 .1-.35 2.19 2.19 0 0 1 .53-.76 3.2 3.2 0 0 1 2.88-.71 9.53 9.53 0 0 1 3 1.45 9.35 9.35 0 0 1 1.24.78Z"
                transform="translate(-1080)"
            />
            <path
                fill={COLORS.BLUE_400}
                d="M1265.81 207.72s7 13.18 37.68 25c0 0-40.24-1.58-54.07-5.09l8.39-22.82Z"
                transform="translate(-1080)"
            />
            <path
                fill={COLORS.WHITE}
                d="M1264.71 220.56a47.38 47.38 0 0 1-15.29 7.06l4.37-11.89 3.6-5.12Z"
                transform="translate(-1080)"
            />
            <path
                fill={COLORS.BLUE_200}
                d="M1233 178.55s36.75 12.71 40.89 17.09l-9.17 24.92A117.49 117.49 0 0 0 1222 208.4Z"
                transform="translate(-1080)"
            />
            <path fill={COLORS.RED_100} d="m141.79 315.05 25.52.05-7.41-17.12-18.11 17.07z" />
            <path
                fill={COLORS.PURPLE_300}
                d="m86 87.59 2.31 4.47 4.23 2.44-4.23 2.44-2.31 4.47-2.31-4.47-4.23-2.44 4.23-2.44L86 87.59z"
            />
            <path
                fill={COLORS.WHITE}
                d="m68 113.48 1.35 2.6 2.46 1.42-2.46 1.42-1.35 2.6-1.35-2.6-2.46-1.42 2.46-1.42 1.35-2.6z"
            />
            <use
                width="64.6"
                height="64.6"
                transform="translate(205.63 73.29)"
                xlinkHref="#i-euro-round"
            />
            <use
                width="26.92"
                height="30.65"
                transform="translate(52.19 124.18)"
                xlinkHref="#i-wing"
            />
            <path
                fill={COLORS.WHITE}
                d="m1241 204.43 3.5-5.26-3.92-5 6.08 1.7 3.51-5.27.26 6.31 6.08 1.71-5.83 2.16.26 6.3-3.92-4.95-5.92 2.2Z"
                transform="translate(-1080)"
            />
            <path
                fill={COLORS.BLUISH_PURPLE_400}
                d="M1117.1 88.9a8.27 8.27 0 0 0 2.15-8.84c-1.47-4.37-5.92-6.86-10-5.56s-6.11 5.91-4.64 10.28a8.42 8.42 0 0 0 7.14 5.86c-10.62 5.41-5.91 17.65-5.91 17.65l26.56-8.6s-3.6-12.69-15.3-10.79Z"
                transform="translate(-1080)"
            />
            <use
                width="18.21"
                height="18.21"
                transform="matrix(.88 0 0 .93 252.99 140.05)"
                xlinkHref="#i-star"
            />
            <path
                fill={COLORS.NEUTRAL_400}
                d="m234 153.5 1.68 3.23 3.06 1.77-3.06 1.77-1.68 3.23-1.68-3.23-3.06-1.77 3.06-1.77 1.68-3.23z"
            />
            <path
                fill={COLORS.PURPLE_300}
                d="m43.88 277.04-.01 6.12 3.2 5.02-5.94.26-5.01 3.52.01-6.12-3.2-5.02 5.94-.26 5.01-3.52z"
            />
            <use
                width="53.36"
                height="54.67"
                transform="matrix(.99 0 0 1.01 227 180)"
                xlinkHref="#i-graph"
            />
            <use
                width="27.88"
                height="25.6"
                transform="matrix(1 0 0 .98 204 171)"
                xlinkHref="#i-heart"
            />
            <use width="75.96" height="75.96" transform="translate(202 233)" xlinkHref="#i-gift" />
            <use
                width="35.93"
                height="35.63"
                transform="matrix(1.09 .12 -.12 1.1 9.42 117.25)"
                xlinkHref="#i-calendar"
            />
            <use
                width="79.8"
                height="79.79"
                transform="matrix(.91 0 0 .91 27.52 159.52)"
                xlinkHref="#i-plane"
            />
            <use width="25.93" height="30.99" transform="translate(57 251)" xlinkHref="#i-apple" />
            <path
                fill={COLORS.WHITE}
                d="m1117.47 242.92-2.7.18-6.44-7.82-.36-5.39s-4.18 0-3.22 7 .31 5.27.31 5.27-8.85.62-9.54 1.44c-.5.59-.15 9.41.07 14.19a5.2 5.2 0 0 0 4 5 3.92 3.92 0 0 0 1 .07c2.21-.15 13.37-2.69 13.37-2.69l4.66-.31Z"
                transform="translate(-1080)"
            />
            <path fill={COLORS.WHITE} d="m45.09 240.93-.43 18.59-4.62.3-1.2-18.1 6.25-.79z" />
            <path
                d="M1212.12 51.27c10.64.73 10.27 16.13-.36 16.13h-97.53c-13.93 0-13.93-20.9 0-20.9a11 11 0 0 1 5.5 1.84 18.91 18.91 0 0 1 29-15.77c6.24-23.46 39.6-23.46 45.83 0a15.39 15.39 0 0 1 17.6 18.7Z"
                transform="translate(-1080)"
                fill={COLORS.BLUE_200}
            />
            <use
                width="90.83"
                height="41"
                transform="translate(105.43 40.63)"
                xlinkHref="#i-cloud"
            />
        </g>
    </svg>
);

export default SlideFourFlag;
