export const PT_CITIES = [
    {
        name: 'A dos Cunhados',
        id: '2nXYTQyMUV'
    },
    {
        name: 'A dos Francos',
        id: 'WxmRW34sDP'
    },
    {
        name: 'Aboim da Nóbrega',
        id: '4a2MkAyTvl'
    },
    {
        name: 'Abrantes',
        id: '2oZVdaFrEd'
    },
    {
        name: 'Abraveses',
        id: 'OHz5vulEj2'
    },
    {
        name: 'Abrigada',
        id: 'RAhwppWpuz'
    },
    {
        name: 'Adaúfe',
        id: 'SgCwklHHkV'
    },
    {
        name: 'Água Longa',
        id: '7fWwh7K7GY'
    },
    {
        name: 'Água Retorta',
        id: 'vUqjQ4Uhus'
    },
    {
        name: 'Água de Pau',
        id: 'XFfLl0ozoG'
    },
    {
        name: 'Água de Pena',
        id: 'pfmR09EwuY'
    },
    {
        name: 'Aguada de Cima',
        id: 'hubDTRezuX'
    },
    {
        name: 'Águas Santas',
        id: 'CAYMUJ91L2'
    },
    {
        name: 'Aguçadoura',
        id: 'xU0C4NZhR6'
    },
    {
        name: 'Águeda',
        id: 'jicVnWhSwH'
    },
    {
        name: 'Aguiar da Beira',
        id: 'LehqvMPlKn'
    },
    {
        name: 'Albergaria-a-Velha',
        id: 'QaDSNDtD0W'
    },
    {
        name: 'Albufeira',
        id: 'azDAa0pSSI'
    },
    {
        name: 'Alcabideche',
        id: 'PDtDhdq4sn'
    },
    {
        name: 'Alcácer do Sal',
        id: 'WT7outGYee'
    },
    {
        name: 'Alcains',
        id: 'MGcIHJTrqM'
    },
    {
        name: 'Alcanede',
        id: 'bsfBWVWXpa'
    },
    {
        name: 'Alcanena',
        id: 'hbVzoGg0PK'
    },
    {
        name: 'Alcanhões',
        id: 'djVs2x0gZu'
    },
    {
        name: 'Alcantarilha',
        id: '8eGYVHxHfg'
    },
    {
        name: 'Alcobaça',
        id: 'uQ3xZKDXR9'
    },
    {
        name: 'Alcochete',
        id: '7XxPq9g8Tu'
    },
    {
        name: 'Alcoentre',
        id: 'boT7C2CtUY'
    },
    {
        name: 'Aldeia Nova de São Bento',
        id: '1OPyFM9tWk'
    },
    {
        name: 'Aldeia de Joanes',
        id: 'OyNfHHZMk1'
    },
    {
        name: 'Aldeia de Paio Pires',
        id: 'kQ38uf9rA5'
    },
    {
        name: 'Aldeia do Mato',
        id: 'ELR5f4fJNf'
    },
    {
        name: 'Alenquer',
        id: 'mj3VGHDtOX'
    },
    {
        name: 'Alfaião',
        id: 'OUqNeyAyEr'
    },
    {
        name: 'Alfarelos',
        id: 'OSxShlIb4I'
    },
    {
        name: 'Alfeizerão',
        id: 'FD8SHVFFwg'
    },
    {
        name: 'Alfena',
        id: 'g1ax3JMtNh'
    },
    {
        name: 'Alferrarede',
        id: '7lHcReQrYR'
    },
    {
        name: 'Alfragide',
        id: '9RddTZaRIi'
    },
    {
        name: 'Algés',
        id: 'S5Z3cue8KR'
    },
    {
        name: 'Algoz',
        id: 'P37GaZharr'
    },
    {
        name: 'Algueirão',
        id: 'BzymSPqayE'
    },
    {
        name: 'Alhadas',
        id: 'pKoCFRDQRY'
    },
    {
        name: 'Alhandra',
        id: 'BiHdezqjnw'
    },
    {
        name: 'Alhos Vedros',
        id: 'PLDwdb8peM'
    },
    {
        name: 'Alijó',
        id: 'JvNPXht1D1'
    },
    {
        name: 'Aljezur',
        id: 'uIZ4MfnBmU'
    },
    {
        name: 'Aljubarrota',
        id: 'RUtX9V0pNB'
    },
    {
        name: 'Aljustrel',
        id: 'Rhv1xX3ivP'
    },
    {
        name: 'Almada',
        id: 'rb6c3pHnog'
    },
    {
        name: 'Almancil',
        id: '0PVZFOS1xa'
    },
    {
        name: 'Almargem',
        id: 'm7ncGE3BRz'
    },
    {
        name: 'Almeirim',
        id: 'XsVheySu47'
    },
    {
        name: 'Almodôvar',
        id: 'ffXBh0RO6M'
    },
    {
        name: 'Almograve',
        id: 'ODmXekBP08'
    },
    {
        name: 'Alpendurada',
        id: 'lffOIlVB1M'
    },
    {
        name: 'Alpiarça',
        id: 'DOTHFfgJzl'
    },
    {
        name: 'Alter do Chão',
        id: 'LGcIFuoOiH'
    },
    {
        name: 'Altura',
        id: 'VcT98UCRjC'
    },
    {
        name: 'Alvalade',
        id: 'qGDGIUKDDl'
    },
    {
        name: 'Alvito',
        id: 'Cj1KRWRdYG'
    },
    {
        name: 'Alvito',
        id: 'IuyDQYsnn2'
    },
    {
        name: 'Alvor',
        id: 'pLNX7G9MGd'
    },
    {
        name: 'Alvorninha',
        id: 'iNnXIxUMMd'
    },
    {
        name: 'Amadora',
        id: '5LpN1O31he'
    },
    {
        name: 'Amarante',
        id: 'iy6PSPXdjh'
    },
    {
        name: 'Amiães de Baixo',
        id: '2GNj3b0agU'
    },
    {
        name: 'Amieira',
        id: 'FlD0WNDG9H'
    },
    {
        name: 'Amor',
        id: 'PfY56Rephx'
    },
    {
        name: 'Amora',
        id: 'ux6XSlsEdP'
    },
    {
        name: 'Amorim',
        id: '9lFwkaoDaq'
    },
    {
        name: 'Ançã',
        id: 'K1MLZcTAky'
    },
    {
        name: 'Angra do Heroísmo',
        id: 'EWcy7I7Gtj'
    },
    {
        name: 'Angústias',
        id: '1beVKF2xZ7'
    },
    {
        name: 'Anta',
        id: 'TZVIVHhYhN'
    },
    {
        name: 'Apelação',
        id: '6Xj55yhB46'
    },
    {
        name: 'Apúlia',
        id: 'FOSHZXE3Dn'
    },
    {
        name: 'Apúlia e Fão',
        id: 'E926WZcDAQ'
    },
    {
        name: 'Aradas',
        id: 'VaaGDIKzyh'
    },
    {
        name: 'Arazede',
        id: 'JkT6C0wVng'
    },
    {
        name: 'Arco da Calheta',
        id: 'JxcLoCo4dF'
    },
    {
        name: 'Arco de São Jorge',
        id: 'QpavBlrToT'
    },
    {
        name: 'Arcos',
        id: 'lCnPFyD7Bk'
    },
    {
        name: 'Arcos',
        id: 'aq2EWAJRMr'
    },
    {
        name: 'Arcos',
        id: 'l4Y2SbF1II'
    },
    {
        name: 'Arcos de Valdevez',
        id: '04WGKemZDu'
    },
    {
        name: 'Arcozelo',
        id: 'Cqehmzw2BI'
    },
    {
        name: 'Areosa',
        id: '1Ax3kHR3at'
    },
    {
        name: 'Arez',
        id: '8sfOiegHbh'
    },
    {
        name: 'Arganil',
        id: 'V7EQZC6bwr'
    },
    {
        name: 'Argivai',
        id: '9cHCwucQZz'
    },
    {
        name: 'Argoncilhe',
        id: 'YhJOTggUqn'
    },
    {
        name: 'Armação de Pêra',
        id: 'ochSXpYOVl'
    },
    {
        name: 'Arões',
        id: 'gGBZBVEDbR'
    },
    {
        name: 'Arranhó',
        id: 'jTNoz2q9cW'
    },
    {
        name: 'Arrentela',
        id: 'g4wE9c8kYq'
    },
    {
        name: 'Arrifana',
        id: 'UiDHxXyxsd'
    },
    {
        name: 'Arrifes',
        id: 'UOySHEZgX8'
    },
    {
        name: 'Arronches',
        id: 'kAzBMMoeot'
    },
    {
        name: 'Arruda dos Vinhos',
        id: 'UUqU8L54mz'
    },
    {
        name: 'Árvore',
        id: 'Gr8x5k71IM'
    },
    {
        name: 'Assafarge',
        id: 'I0C8ukximW'
    },
    {
        name: 'Atalaia',
        id: 'XRJ8lIex1d'
    },
    {
        name: 'Atouguia da Baleia',
        id: 'pkgzCGmFM1'
    },
    {
        name: 'Avanca',
        id: '8BLiPG5lBE'
    },
    {
        name: 'Aveiras de Cima',
        id: 'm7KVjX3Dw6'
    },
    {
        name: 'Aveiro',
        id: '5S0NufDDMj'
    },
    {
        name: 'Aveleda',
        id: 'Kc8Mj6AKw0'
    },
    {
        name: 'Aver-o-Mar',
        id: 'PQAzFDh385'
    },
    {
        name: 'Aves',
        id: 'Wol1PxCyHI'
    },
    {
        name: 'Avintes',
        id: 'aMCWCDvWms'
    },
    {
        name: 'Avô',
        id: 'CUS11RhfYd'
    },
    {
        name: 'Azambuja',
        id: '0pqEjM01D3'
    },
    {
        name: 'Azeitão',
        id: 'VMigE21FvY'
    },
    {
        name: 'Azenha',
        id: 'eDF9JQP2Wx'
    },
    {
        name: 'Azenhas do Mar',
        id: 'hTSu0BtFnH'
    },
    {
        name: 'Azinhaga',
        id: 'ZdvbAEDPwu'
    },
    {
        name: 'Baçal',
        id: '7vWookXmEx'
    },
    {
        name: 'Baguim do Monte',
        id: 'UIhgNgFqN1'
    },
    {
        name: 'Baião',
        id: 'WIKfKHEuSd'
    },
    {
        name: 'Balazar',
        id: 'BAopCQApNV'
    },
    {
        name: 'Baltar',
        id: '6PnlTCJRgt'
    },
    {
        name: 'Bandeiras',
        id: 'fLXL1jiq0h'
    },
    {
        name: 'Barão de São João',
        id: 'Y2uIXl1qnB'
    },
    {
        name: 'Barcarena',
        id: 'fzQFW3DVNY'
    },
    {
        name: 'Barcelos',
        id: '186umzHlm2'
    },
    {
        name: 'Barco',
        id: '24s7gJl2bs'
    },
    {
        name: 'Barqueiros',
        id: 'eb2zL0sPIr'
    },
    {
        name: 'Barreiro',
        id: '9CX8KdbkND'
    },
    {
        name: 'Barroca Grande',
        id: 'WwpnqPB7Q2'
    },
    {
        name: 'Barrosas',
        id: 'KAmyaWdFXX'
    },
    {
        name: 'Batalha',
        id: 'vGGEwI3I0J'
    },
    {
        name: 'Beato António',
        id: '0BQOCGNTN8'
    },
    {
        name: 'Beduido',
        id: 'PcBpHTYJ7Y'
    },
    {
        name: 'Beiriz de Baixo',
        id: 'S3VhJERlHA'
    },
    {
        name: 'Beja',
        id: 'rmEBCoEmbp'
    },
    {
        name: 'Belas',
        id: '85BIXBoeP1'
    },
    {
        name: 'Belazaima do Chão',
        id: 'l6Wy1hURdG'
    },
    {
        name: 'Belver',
        id: 'B0xAWNRF8c'
    },
    {
        name: 'Bemfica',
        id: 'IDynopsYgX'
    },
    {
        name: 'Benavente',
        id: 'x2Hn05rnIA'
    },
    {
        name: 'Benedita',
        id: 'ppEbkV93Ov'
    },
    {
        name: 'Benfica',
        id: 'oQgwL9MBPl'
    },
    {
        name: 'Bensafrim',
        id: 'kuEEO5wHD0'
    },
    {
        name: 'Beringel',
        id: 'Dz8cm7W8Yc'
    },
    {
        name: 'Biscoitos',
        id: 'fdP06HowS4'
    },
    {
        name: 'Boaventura',
        id: 'v3HjcS6m0a'
    },
    {
        name: 'Boavista dos Pinheiros',
        id: 'eoWeYPXd36'
    },
    {
        name: 'Bobadela',
        id: '9WX9qNUq51'
    },
    {
        name: 'Boliqueime',
        id: 'UZJ60qiXcH'
    },
    {
        name: 'Bombarral',
        id: 'Q8D1DXJZRp'
    },
    {
        name: 'Borba',
        id: 'Qql8vxed2f'
    },
    {
        name: 'Boticas',
        id: 'fBRQ9F9g1R'
    },
    {
        name: 'Bougado',
        id: 'Yir6FCcn1g'
    },
    {
        name: 'Braga',
        id: 'zMyLAUdq4q'
    },
    {
        name: 'Bragança',
        id: 'rFYptnB9VM'
    },
    {
        name: 'Branca',
        id: 'fUVCJjzN4e'
    },
    {
        name: 'Britiande',
        id: 'E9zSNIOOah'
    },
    {
        name: 'Brito',
        id: 'GZGzRVv91U'
    },
    {
        name: 'Buarcos',
        id: 'yvwBo7QiA8'
    },
    {
        name: 'Bucelas',
        id: 'snJIWtLDny'
    },
    {
        name: 'Burgau',
        id: 'XZh9uJoLyH'
    },
    {
        name: 'Cabanas de Tavira',
        id: '62fb1eJBnW'
    },
    {
        name: 'Cabanas de Torres',
        id: 'D5pDdQ6vpw'
    },
    {
        name: 'Cabanas de Viriato',
        id: 'LKvQ0XBhtm'
    },
    {
        name: 'Cabeça Gorda',
        id: 'yqyeodVMRy'
    },
    {
        name: 'Cabeceiras de Basto',
        id: 'dkorpTVoBM'
    },
    {
        name: 'Cabo da Praia',
        id: '4gEe92LpOQ'
    },
    {
        name: 'Cabouco',
        id: 'pMqQt81aB6'
    },
    {
        name: 'Cacém',
        id: 'q5WCL1kjtS'
    },
    {
        name: 'Cacilhas',
        id: '2MSyl2p5kb'
    },
    {
        name: 'Cadafais',
        id: 'kGMkNODsv0'
    },
    {
        name: 'Cadaval',
        id: 'KnG350qNZe'
    },
    {
        name: 'Cais do Pico',
        id: 'rhM35c8ADC'
    },
    {
        name: 'Caldas da Rainha',
        id: '1xjdebCUQF'
    },
    {
        name: 'Caldas das Taipas',
        id: 'SHwh0KJGwu'
    },
    {
        name: 'Caldas de Vizela',
        id: 'UUyujE8ygz'
    },
    {
        name: 'Caldelas',
        id: '96ciFYGP1T'
    },
    {
        name: 'Calendário',
        id: 'mmZWmA591S'
    },
    {
        name: 'Calheta',
        id: 'JXJ3QfFHDb'
    },
    {
        name: 'Calheta',
        id: 'nCF7NBY4Ko'
    },
    {
        name: 'Calves',
        id: 'YbFAZjANEL'
    },
    {
        name: 'Camacha',
        id: 'oVKAvBo9UI'
    },
    {
        name: 'Camacha',
        id: 'TCJw3asf0S'
    },
    {
        name: 'Câmara de Lobos',
        id: 'mFQoE6ZrcY'
    },
    {
        name: 'Camarate',
        id: 'RUK6CtTtCP'
    },
    {
        name: 'Campanário',
        id: '8XjmsI97iy'
    },
    {
        name: 'Campelos',
        id: 'UjHr6HDT6J'
    },
    {
        name: 'Campo',
        id: 'm3VSDRc6q5'
    },
    {
        name: 'Campo',
        id: 'DKFlhJ545b'
    },
    {
        name: 'Campo Maior',
        id: 's6XlnctBDp'
    },
    {
        name: 'Campo de Besteiros',
        id: 'FVeu23KDWX'
    },
    {
        name: 'Canas de Senhorim',
        id: 'Ab0UJbxWuE'
    },
    {
        name: 'Candoso',
        id: 'y4kNgb68P1'
    },
    {
        name: 'Caneças',
        id: 'oNMCt2Vc80'
    },
    {
        name: 'Canelas',
        id: 'Q3tbpQbRFO'
    },
    {
        name: 'Canhas',
        id: 'CE7EBBjNGU'
    },
    {
        name: 'Caniçal',
        id: 'jlvuLpvtbQ'
    },
    {
        name: 'Caniço',
        id: 'b9sX2szjyo'
    },
    {
        name: 'Canidelo',
        id: 'ZyzqCHmAwu'
    },
    {
        name: 'Cantanhede',
        id: '5o4cCF0Yh6'
    },
    {
        name: 'Caparica',
        id: 'HMqLxjBTId'
    },
    {
        name: 'Caranguejeira',
        id: 'evLH5JLm2y'
    },
    {
        name: 'Carapinheira',
        id: 'kfHKyjREj3'
    },
    {
        name: 'Carapito',
        id: '3RVyK2VSuJ'
    },
    {
        name: 'Carcavelos',
        id: 'pR0WmmUFsx'
    },
    {
        name: 'Carnaxide',
        id: 'QWsnomxoq0'
    },
    {
        name: 'Carragosa',
        id: 'jn878waUcu'
    },
    {
        name: 'Carrapateira',
        id: 'typ976bPXE'
    },
    {
        name: 'Carrazeda de Anciães',
        id: 'Wij7bxB83A'
    },
    {
        name: 'Carregado',
        id: 'B2NcGWSaQt'
    },
    {
        name: 'Carregal do Sal',
        id: 'FK1tD8Fm0u'
    },
    {
        name: 'Cartaxo',
        id: 'oW7MgYWsbM'
    },
    {
        name: 'Carvalhosa',
        id: 'qTDdFV30vJ'
    },
    {
        name: 'Carvoeiro',
        id: 'yqGQBE6bjv'
    },
    {
        name: 'Carvoeiro',
        id: 'QcbuGaDiTW'
    },
    {
        name: 'Casais Robustos',
        id: 'cTR41KQAlH'
    },
    {
        name: 'Cascais',
        id: 'Uit7EGU7M7'
    },
    {
        name: 'Castanheira de Pêra',
        id: 'KGAD4K3RlT'
    },
    {
        name: 'Castanheira do Ribatejo',
        id: 'XzKhS230sK'
    },
    {
        name: 'Castelo Branco',
        id: '15IwH5Y9D1'
    },
    {
        name: 'Castelo Branco',
        id: 'qDWMQj8YeV'
    },
    {
        name: 'Castelo de Vide',
        id: 'WEsoap6WJQ'
    },
    {
        name: 'Castelões de Cepeda',
        id: '07hGhVHSnw'
    },
    {
        name: 'Castro Daire',
        id: 'Vm4BN07NHP'
    },
    {
        name: 'Castro Marim',
        id: '79OaX6inGb'
    },
    {
        name: 'Castro Verde',
        id: 'rBxYXGPZJ9'
    },
    {
        name: 'Castro de Avelãs',
        id: 'rIRo2AExt1'
    },
    {
        name: 'Caxias',
        id: 'X0QRDeXs4w'
    },
    {
        name: 'Cedros',
        id: '5QQT07H9PN'
    },
    {
        name: 'Cedros',
        id: '4bFBBdBKyo'
    },
    {
        name: 'Cela',
        id: 'v4NpoWdCV6'
    },
    {
        name: 'Cercal',
        id: 'F82OvhDCSN'
    },
    {
        name: 'Charneca',
        id: 'wbaFN3HZpj'
    },
    {
        name: 'Charneca de Caparica',
        id: 'BB42H32WVV'
    },
    {
        name: 'Chavão',
        id: 'T6FxwUNw8u'
    },
    {
        name: 'Chaves',
        id: '16JXCeO2xv'
    },
    {
        name: 'Coimbra',
        id: 'CzRUv7yJky'
    },
    {
        name: 'Colares',
        id: 'a1q9Ufdrjl'
    },
    {
        name: 'Comenda',
        id: 'wmW5mmiwDk'
    },
    {
        name: 'Comporta',
        id: 'i495GHREYw'
    },
    {
        name: 'Conceição',
        id: 'JCn3Yj3mfC'
    },
    {
        name: 'Condeixa-a-Nova',
        id: 'PbDBEREZgZ'
    },
    {
        name: 'Constância',
        id: 'jTFjMNRdQM'
    },
    {
        name: 'Corroios',
        id: '0HHOb7CvAS'
    },
    {
        name: 'Cortegaça',
        id: 'hAHsOZI8bb'
    },
    {
        name: 'Cortiçada',
        id: 'iCETAAnJKk'
    },
    {
        name: 'Coruche',
        id: '2DFpJLTxEG'
    },
    {
        name: 'Costa de Caparica',
        id: 'QniO7UHcHP'
    },
    {
        name: 'Covilhã',
        id: 'mE15fz4Cjz'
    },
    {
        name: 'Cristelo',
        id: 'OwgfalCMLK'
    },
    {
        name: 'Cuba',
        id: 'onybrklfBX'
    },
    {
        name: 'Cucujães',
        id: 'Mh9ghWwZrc'
    },
    {
        name: 'Curral das Freiras',
        id: 'VMrC52eUy1'
    },
    {
        name: 'Custoias',
        id: 'aOGiwcT39M'
    },
    {
        name: 'Darque',
        id: 'gcDOYzFWCy'
    },
    {
        name: 'Donai',
        id: 'B1Amuzgy19'
    },
    {
        name: 'Eirado',
        id: 'pjmTgQmYAs'
    },
    {
        name: 'Eixo',
        id: '6DJCJvZt0l'
    },
    {
        name: 'Elvas',
        id: 'zTcD3Jh6px'
    },
    {
        name: 'Entroncamento',
        id: 'ZvFXMsYa6F'
    },
    {
        name: 'Ericeira',
        id: 'qsO7V0kCOz'
    },
    {
        name: 'Ermesinde',
        id: 'JpxUgagh6x'
    },
    {
        name: 'Ervedosa do Douro',
        id: '4kSPpFhuDq'
    },
    {
        name: 'Esgueira',
        id: 'y9zSUHvtjA'
    },
    {
        name: 'Esmoriz',
        id: 'ZAnHpJ7Fqx'
    },
    {
        name: 'Espargo',
        id: 'l86TAbLwX8'
    },
    {
        name: 'Espiche',
        id: '6ctnwODppK'
    },
    {
        name: 'Espinho',
        id: 'tXQviDzwGI'
    },
    {
        name: 'Esporões',
        id: 'RHbd58CQMi'
    },
    {
        name: 'Esposende',
        id: 'FFoijB7cPo'
    },
    {
        name: 'Estela',
        id: 'FqZE6bB2wm'
    },
    {
        name: 'Estói',
        id: 'WUJsve1C08'
    },
    {
        name: 'Estômbar',
        id: 'FgqaOWP8O4'
    },
    {
        name: 'Estoril',
        id: '3HCdjK7IgU'
    },
    {
        name: 'Estreito da Calheta',
        id: 'wy0Gg2atc6'
    },
    {
        name: 'Estremoz',
        id: 'hMop8psfbQ'
    },
    {
        name: 'Évora',
        id: 'xAGPSpKMgg'
    },
    {
        name: 'Fafe',
        id: 'dJoTGyQqDI'
    },
    {
        name: 'Faial',
        id: 'NUl52JtYxW'
    },
    {
        name: 'Fajã Grande',
        id: 'hZzfECbmFP'
    },
    {
        name: 'Fajã da Ovelha',
        id: 'Qd9EHBn6ky'
    },
    {
        name: 'Fajã de Baixo',
        id: 'yxWM3wcvoW'
    },
    {
        name: 'Famalicão',
        id: 'drANzZz5de'
    },
    {
        name: 'Famalicão',
        id: 'B7SCEEYO1r'
    },
    {
        name: 'Famões',
        id: 'thWvEp9KUw'
    },
    {
        name: 'Fânzeres',
        id: 'noJERuFTsH'
    },
    {
        name: 'Faria',
        id: 'SXNtPgK3wf'
    },
    {
        name: 'Faro',
        id: 'keSWY5BxPI'
    },
    {
        name: 'Faro do Alentejo',
        id: 'lFENqR4TqS'
    },
    {
        name: 'Fátima',
        id: 'a0UPrBFhMe'
    },
    {
        name: 'Favaios',
        id: 'Chj8fxgXbl'
    },
    {
        name: 'Fazenda de Santa Cruz',
        id: 'ScBGmH9mzH'
    },
    {
        name: 'Fazendas de Almeirim',
        id: '5Ky52HTuZ3'
    },
    {
        name: 'Feira',
        id: 'w47NU0X7Cx'
    },
    {
        name: 'Felgueiras',
        id: 'rV8GeGMx64'
    },
    {
        name: 'Felgueiras',
        id: 'i9X167iZyj'
    },
    {
        name: 'Fenais da Ajuda',
        id: 'alC10PlJDB'
    },
    {
        name: 'Fenais da Luz',
        id: 'IZEvQh4tm9'
    },
    {
        name: 'Ferragudo',
        id: '44D8RPeE3v'
    },
    {
        name: 'Ferreira',
        id: 'zTjlqECMIr'
    },
    {
        name: 'Ferreira do Alentejo',
        id: 'wHFVxlnucr'
    },
    {
        name: 'Ferreiras',
        id: 'xIsogVyc6g'
    },
    {
        name: 'Ferreiros',
        id: 'j8Mtzfl8cD'
    },
    {
        name: 'Ferreiros',
        id: 'FLBaizbfEs'
    },
    {
        name: 'Ferrel',
        id: 'FNdIyIqnoC'
    },
    {
        name: 'Feteira',
        id: 'cej78aj70i'
    },
    {
        name: 'Fiães',
        id: 'DyQuCAD2ML'
    },
    {
        name: 'Figueira da Foz',
        id: 'BbdgbvFfAj'
    },
    {
        name: 'Figueira do Guincho',
        id: 'wfDD2HimJX'
    },
    {
        name: 'Figueiró',
        id: 'gHNAO3D3pt'
    },
    {
        name: 'Fontanelas',
        id: '6PCn1Vzjgs'
    },
    {
        name: 'Fonte Bastardo',
        id: 'FBrqQvKd6u'
    },
    {
        name: 'Fontelo',
        id: 'l3UfpdXFDT'
    },
    {
        name: 'Fontes',
        id: 'b4zANm5RMg'
    },
    {
        name: 'Fornelos',
        id: 'hFAzUiok8r'
    },
    {
        name: 'Foz do Arelho',
        id: 'PhVrPW6QaM'
    },
    {
        name: 'Foz do Douro',
        id: 'Z4uvb0m9vT'
    },
    {
        name: 'Foz do Sousa',
        id: 'iGpI8dIVrT'
    },
    {
        name: 'Fradelos',
        id: 'jEABeuMbT1'
    },
    {
        name: 'Frazão',
        id: 'V7nntxuMCb'
    },
    {
        name: 'Freamunde',
        id: 'DOrqySMG7o'
    },
    {
        name: 'Freixo de Espada à Cinta',
        id: 'GAvQOBCUyn'
    },
    {
        name: 'Freixo de Numão',
        id: 'cBmTmr3Qfn'
    },
    {
        name: 'Friumes',
        id: 'AZ7rOSFMFH'
    },
    {
        name: 'Frossos',
        id: 'rJcZitLHZy'
    },
    {
        name: 'Funchal',
        id: 'slGOXcUWlH'
    },
    {
        name: 'Fundão',
        id: '6kSKTV8NWr'
    },
    {
        name: 'Furnas',
        id: 'ZaZtZSHwuS'
    },
    {
        name: 'Fuzeta',
        id: 'SWe8ZaxMkC'
    },
    {
        name: 'Gafanha da Encarnação',
        id: 'T7qJiT0MqV'
    },
    {
        name: 'Gafanha da Nazaré',
        id: 'ZNn6pYgk6m'
    },
    {
        name: 'Gáfete',
        id: 'cIksNDV1N9'
    },
    {
        name: 'Galegos',
        id: 'fDRj1oMGRU'
    },
    {
        name: 'Gandra',
        id: 'r9pSValg1q'
    },
    {
        name: 'Gandra',
        id: 'IX4lfREi5A'
    },
    {
        name: 'Gavião',
        id: '8xE609aG00'
    },
    {
        name: 'Gemunde',
        id: 'Z2wiSBNiCY'
    },
    {
        name: 'Gimonde',
        id: '3j4OHOIFyP'
    },
    {
        name: 'Goães',
        id: 'RGUkjE6A5w'
    },
    {
        name: 'Godim',
        id: 'l6hWX1xxZq'
    },
    {
        name: 'Góis',
        id: 'yvhvjjIDGn'
    },
    {
        name: 'Golegã',
        id: 'GLgAIxzXCK'
    },
    {
        name: 'Gondizalves',
        id: 'kd0R8jmYC8'
    },
    {
        name: 'Gondomar',
        id: 'ZHwGu8ZUf7'
    },
    {
        name: 'Gostei',
        id: 'QE7isMZsEx'
    },
    {
        name: 'Gradiz',
        id: 'DxdWlezHoA'
    },
    {
        name: 'Grândola',
        id: 'Fu1TqBHq24'
    },
    {
        name: 'Grijó',
        id: 'VMzZhB4AGC'
    },
    {
        name: 'Guarda',
        id: 'NeNSC9NPyQ'
    },
    {
        name: 'Gueral',
        id: 'ojwrhX8IV9'
    },
    {
        name: 'Guia',
        id: 'Dnf0pDvQSx'
    },
    {
        name: 'Guifões',
        id: 'AQstgqO1FQ'
    },
    {
        name: 'Guimarães',
        id: 'XR0tA1xv2b'
    },
    {
        name: 'Guisande',
        id: 'Hwf9TNC8SM'
    },
    {
        name: 'Gulpilhares',
        id: 'HPafaEp7RC'
    },
    {
        name: 'Horta',
        id: 'XHSy1MyCxG'
    },
    {
        name: 'Ílhavo',
        id: 'oRAmL7IbZA'
    },
    {
        name: 'Jardim do Mar',
        id: 'oC8R1Meid6'
    },
    {
        name: 'Joane',
        id: 'o9z24J8ngH'
    },
    {
        name: 'Jovim',
        id: 'ZuTDKtHMij'
    },
    {
        name: 'Lago',
        id: 'eGNBbUM4S9'
    },
    {
        name: 'Lagoa',
        id: 'ShKD1Dt6MM'
    },
    {
        name: 'Lagoa',
        id: 'cSsoElJz1a'
    },
    {
        name: 'Lagoaça',
        id: '3FCd8j4Z53'
    },
    {
        name: 'Lagos',
        id: 'aYyNGGU8ie'
    },
    {
        name: 'Lajes',
        id: 'raKCZmZlwt'
    },
    {
        name: 'Lajes',
        id: 'yZLieJb5Gs'
    },
    {
        name: 'Lajes das Flores',
        id: 'bUaHlVJJi5'
    },
    {
        name: 'Lalim',
        id: 'NRMPGXqwIJ'
    },
    {
        name: 'Lamas',
        id: 'CuF3W9Hzro'
    },
    {
        name: 'Lamas',
        id: 'cHHzoaO9LB'
    },
    {
        name: 'Lamego',
        id: 'L11O3cRJ4c'
    },
    {
        name: 'Lamelas',
        id: 'u7IVJc3qtJ'
    },
    {
        name: 'Lapa do Lobo',
        id: 'tCHooEEuxE'
    },
    {
        name: 'Laranjeira',
        id: 'yIN5djnAyY'
    },
    {
        name: 'Laranjeiro',
        id: 'Bcsvzik3X9'
    },
    {
        name: 'Lavos',
        id: 'rLXARqFmHM'
    },
    {
        name: 'Lavra',
        id: 'nv6ZuFRl8m'
    },
    {
        name: 'Lavradio',
        id: 'XEjLfK9GkA'
    },
    {
        name: 'Leça da Palmeira',
        id: 'buMCwuLTPo'
    },
    {
        name: 'Leça do Bailio',
        id: 'AEn3UemdpY'
    },
    {
        name: 'Leiria',
        id: 'akwykHMmFt'
    },
    {
        name: 'Linda-a-Velha',
        id: 'mBaDFgSaK8'
    },
    {
        name: 'Lisboa',
        id: 'S2IXCWMOTa'
    },
    {
        name: 'Lobão',
        id: 'zF90XHiWCQ'
    },
    {
        name: 'Lomba',
        id: 'wynMEfvFuO'
    },
    {
        name: 'Lordelo',
        id: 'GCjDrWfOPH'
    },
    {
        name: 'Lordelo',
        id: 'vkYdPDMjbl'
    },
    {
        name: 'Loriga',
        id: 'qWDlkoyH5j'
    },
    {
        name: 'Lorvão',
        id: 'VvgJoqvnX7'
    },
    {
        name: 'Loulé',
        id: 'BPdIfyzHZd'
    },
    {
        name: 'Loures',
        id: 'yOthsQSbYe'
    },
    {
        name: 'Louriçal',
        id: 'L1mxTFSTOo'
    },
    {
        name: 'Lourinhã',
        id: 'AS4AlTnnGJ'
    },
    {
        name: 'Lourosa',
        id: 'lxD2yy2B0H'
    },
    {
        name: 'Lousã',
        id: 'D5PWtKacHG'
    },
    {
        name: 'Lousada',
        id: 'YzGCS6NCD0'
    },
    {
        name: 'Lugar de Baixo',
        id: 'ZluSx401DL'
    },
    {
        name: 'Luso',
        id: 'EuVxVHOPSS'
    },
    {
        name: 'Lustosa',
        id: 'RJzNNaJtX3'
    },
    {
        name: 'Luz',
        id: 'C7Xpu0xHKC'
    },
    {
        name: 'Macedo de Cavaleiros',
        id: 'hf02VIWZyj'
    },
    {
        name: 'Maceira',
        id: '17I6joxdRQ'
    },
    {
        name: 'Machico',
        id: '4VF9D9L6AG'
    },
    {
        name: 'Macieira de Cambra',
        id: '1hFj2o7IYi'
    },
    {
        name: 'Macieira de Rates',
        id: '4BkxQACYuO'
    },
    {
        name: 'Madalena',
        id: 'HbSe9S2S2u'
    },
    {
        name: 'Madalena',
        id: '8gbFccKeHb'
    },
    {
        name: 'Madalena',
        id: 'uuI93qZw3A'
    },
    {
        name: 'Madalena do Mar',
        id: 'wlifYRrbc3'
    },
    {
        name: 'Mafra',
        id: 'ZjpS9UC6ok'
    },
    {
        name: 'Maia',
        id: 'aDxWPqOjVU'
    },
    {
        name: 'Maia',
        id: 'Agvv7p8MRA'
    },
    {
        name: 'Malveira',
        id: 'ICmFBgHc9O'
    },
    {
        name: 'Manadas',
        id: '8vEWvKyBYt'
    },
    {
        name: 'Mangualde',
        id: '6ErnDbuyqQ'
    },
    {
        name: 'Manta Rota',
        id: 'v7qKphPhu5'
    },
    {
        name: 'Manteigas',
        id: 'ZJiQIDshwa'
    },
    {
        name: 'Marco de Canavezes',
        id: 'aTu203zbBo'
    },
    {
        name: 'Margaride',
        id: 'ltGE8xFr0e'
    },
    {
        name: 'Marinha Grande',
        id: 'c7RofAWTk2'
    },
    {
        name: 'Marinhais',
        id: 'aTDRPY0uFr'
    },
    {
        name: 'Marinhas',
        id: 'ThlJEtgYLQ'
    },
    {
        name: 'Mariz',
        id: '3yoxO1WwU0'
    },
    {
        name: 'Marmelete',
        id: 'Ae5ZubXnz8'
    },
    {
        name: 'Martinchel',
        id: 'ndj9wPek08'
    },
    {
        name: 'Marvão',
        id: 'srYPuzgai8'
    },
    {
        name: 'Massamá',
        id: 'jkPlY1EkG6'
    },
    {
        name: 'Matosinhos',
        id: 'wLcBBx8k4t'
    },
    {
        name: 'Meadela',
        id: 'h1ZvHxTacv'
    },
    {
        name: 'Mealhada',
        id: 'uzK2TplWC1'
    },
    {
        name: 'Meca',
        id: '5kHqZ1iBXi'
    },
    {
        name: 'Mêda',
        id: 'bdOk1qIcWX'
    },
    {
        name: 'Meinedo',
        id: 'kRBYIAgw68'
    },
    {
        name: 'Meixedo',
        id: 'xZFAmGZBdf'
    },
    {
        name: 'Melres',
        id: 'MnRJbHmoEX'
    },
    {
        name: 'Mem Martins',
        id: 'zlHcVA3iJj'
    },
    {
        name: 'Merelim',
        id: 'jxNBjaH87u'
    },
    {
        name: 'Mértola',
        id: '2HU3oDIHdq'
    },
    {
        name: 'Mesão Frio',
        id: 'ADZ2aGdDDU'
    },
    {
        name: 'Mesquitela',
        id: 'dCi78OIbVK'
    },
    {
        name: 'Mexilhoeira Grande',
        id: 'LjpRaUiCoe'
    },
    {
        name: 'Milhão',
        id: 'lbAxlzV2tA'
    },
    {
        name: 'Milharado',
        id: 'jX6of4UeYE'
    },
    {
        name: 'Milheirós',
        id: '5e6PlEz4Aj'
    },
    {
        name: 'Milheirós de Poiares',
        id: '0sETj7qx3t'
    },
    {
        name: 'Minas de São Domingos',
        id: 't955zu9s4F'
    },
    {
        name: 'Minde',
        id: 'uvDdnCv35n'
    },
    {
        name: 'Mindelo',
        id: 'JNwHFCmaYx'
    },
    {
        name: 'Mira',
        id: '9HUAYEWiON'
    },
    {
        name: 'Mira',
        id: 'AN40E1sKEh'
    },
    {
        name: 'Miranda do Corvo',
        id: 'H3LY3XoEhO'
    },
    {
        name: 'Miranda do Douro',
        id: 'fWkXSjOFoI'
    },
    {
        name: 'Mirandela',
        id: '8ji9dYF7Pk'
    },
    {
        name: 'Mogadouro',
        id: 'WzNhO8uGTl'
    },
    {
        name: 'Moimenta da Beira',
        id: 'aDlEACp72x'
    },
    {
        name: 'Moita',
        id: 'kQTbAPCWBD'
    },
    {
        name: 'Moita dos Ferreiros',
        id: 'kEzXAGlNM6'
    },
    {
        name: 'Moitas Venda',
        id: 'dDuXzNQCsf'
    },
    {
        name: 'Mombeja',
        id: 'pydJDbuYqm'
    },
    {
        name: 'Monção',
        id: '2IZKO1wxrw'
    },
    {
        name: 'Moncarapacho',
        id: 'a7Mf9t34Wz'
    },
    {
        name: 'Monchique',
        id: 'NjXu66ZTbh'
    },
    {
        name: 'Mondim da Beira',
        id: 'I6ncgJtywO'
    },
    {
        name: 'Mondim de Basto',
        id: '4mIzw0J2AB'
    },
    {
        name: 'Monforte',
        id: 'pXIcR9qVWy'
    },
    {
        name: 'Monsanto',
        id: 'BDtEZq04d4'
    },
    {
        name: 'Monsanto',
        id: 'c8Kx1U7GKJ'
    },
    {
        name: 'Montalvinho',
        id: 'fCfRdaGGnH'
    },
    {
        name: 'Montalvo',
        id: 'TW2CqijXyn'
    },
    {
        name: 'Montargil',
        id: 'eLbB6Q8bLz'
    },
    {
        name: 'Monte Estoril',
        id: '8BNJEJxU95'
    },
    {
        name: 'Monte Gordo',
        id: 'YTvvAf4nIq'
    },
    {
        name: 'Monte Real',
        id: 'O5Ti7FpcTX'
    },
    {
        name: 'Monte Redondo',
        id: 'IwH7X0eBKE'
    },
    {
        name: 'Montemor-o-Novo',
        id: 'lyurMbZQrb'
    },
    {
        name: 'Montemor-o-Velho',
        id: 'VbcmQhoVHS'
    },
    {
        name: 'Montijo',
        id: 'm7WD1S5PCK'
    },
    {
        name: 'Moreira',
        id: 'IfvbS2RMGh'
    },
    {
        name: 'Moreira',
        id: 'uFxc8D1Cxl'
    },
    {
        name: 'Moreira de Conegos',
        id: 'C4RFogJx9b'
    },
    {
        name: 'Moscavide e Portela',
        id: 'kKajvpCKDR'
    },
    {
        name: 'Mosteirô',
        id: '2QZDD3cjth'
    },
    {
        name: 'Mosteiros',
        id: 'pW0IWFuaTQ'
    },
    {
        name: 'Moura',
        id: 'l6vOBGhlsD'
    },
    {
        name: 'Mozelos',
        id: '2nKqpoIZVu'
    },
    {
        name: 'Murtosa',
        id: '84q7RGs5D5'
    },
    {
        name: 'Nadadouro',
        id: 'rBNCiVUkCP'
    },
    {
        name: 'Nazaré',
        id: 'quiAcCzlYf'
    },
    {
        name: 'Negreiros',
        id: 'htcBzeTrDb'
    },
    {
        name: 'Negrelos',
        id: 'H3GqA2avIO'
    },
    {
        name: 'Nelas',
        id: 'SWO20QBYV9'
    },
    {
        name: 'Neves',
        id: 'ZWTqBVcPS6'
    },
    {
        name: 'Nisa',
        id: 'nDuhxWfVTU'
    },
    {
        name: 'Nogueira',
        id: 'CRpxpLu5op'
    },
    {
        name: 'Nogueira',
        id: 'MH0jiyNPtj'
    },
    {
        name: 'Nogueira da Regedoura',
        id: 'FsI9i1FrG8'
    },
    {
        name: 'Norte Pequeno',
        id: 'JXdIhMRWG3'
    },
    {
        name: 'Nossa Senhora do Monte',
        id: '9X3bvNKx0l'
    },
    {
        name: 'Óbidos',
        id: 'UW6qvGaOTA'
    },
    {
        name: 'Odeceixe',
        id: '2RvIUl63kV'
    },
    {
        name: 'Odemira',
        id: 'EtTYHHeMSr'
    },
    {
        name: 'Odivelas',
        id: 'EVDzQvEFWr'
    },
    {
        name: 'Oeiras',
        id: 'SW1eCSrYG0'
    },
    {
        name: 'Oiã',
        id: 'kiQedgZOjM'
    },
    {
        name: 'Oleiros',
        id: 'SnQeGJtt8n'
    },
    {
        name: 'Olhão',
        id: 'MuSX1kdm1j'
    },
    {
        name: 'Olhos de Água',
        id: 'k3gxdcvyuY'
    },
    {
        name: 'Olivais',
        id: 'erMFmoxBJD'
    },
    {
        name: 'Olival',
        id: 'D67E1WCBwF'
    },
    {
        name: 'Olival do Basto',
        id: 'PYpRyGpt6z'
    },
    {
        name: 'Oliveira',
        id: 'LMh3FIilmK'
    },
    {
        name: 'Oliveira de Azemeis',
        id: 'YHk19XByHs'
    },
    {
        name: 'Oliveira do Bairro',
        id: 'shp3IoAb4j'
    },
    {
        name: 'Oliveira do Douro',
        id: 'ZdahRPEZlL'
    },
    {
        name: 'Oliveira do Hospital',
        id: 'UhhA2iqiPy'
    },
    {
        name: 'Oliveira do Mondego',
        id: 'f8k7NnFDOB'
    },
    {
        name: 'Oliveirinha',
        id: 'byFHxpy8jq'
    },
    {
        name: 'Ota',
        id: '80aQzPy2dn'
    },
    {
        name: 'Ourém',
        id: 'mxe1vavg2F'
    },
    {
        name: 'Ovar',
        id: 'iTI0TIMLto'
    },
    {
        name: 'Paço de Arcos',
        id: 't2jxxIyQ9D'
    },
    {
        name: 'Paços de Brandão',
        id: '6Xc2EPmTxj'
    },
    {
        name: 'Paços de Ferreira',
        id: 'TwYb6FhUlw'
    },
    {
        name: 'Paderne',
        id: 'DymbdTICUi'
    },
    {
        name: 'Paialvo',
        id: '6RhzZOMBcZ'
    },
    {
        name: 'Palmela',
        id: 'CvDGqJyuJA'
    },
    {
        name: 'Pampilhosa da Serra',
        id: 'BurzHrpCJO'
    },
    {
        name: 'Pampilhosa do Botão',
        id: 'RqjYFjfr2a'
    },
    {
        name: 'Parada de Gonta',
        id: 'WnKMS8n8he'
    },
    {
        name: 'Paradela',
        id: 'vrxX2lBfZj'
    },
    {
        name: 'Parchal',
        id: 'SGI6GES7Ys'
    },
    {
        name: 'Pardilhó',
        id: 'AxjciOJiqf'
    },
    {
        name: 'Parede',
        id: 'TRWWlbddbJ'
    },
    {
        name: 'Paredes',
        id: '7lNvWFLsHR'
    },
    {
        name: 'Pataias',
        id: '06QJjB06os'
    },
    {
        name: 'Paul do Mar',
        id: 'wWmDSC8JJk'
    },
    {
        name: 'Pedrógão Grande',
        id: '1K3zF2VWV6'
    },
    {
        name: 'Pedroso',
        id: '8WZiZAsA63'
    },
    {
        name: 'Pedrouços',
        id: 'ztNBeQzYpN'
    },
    {
        name: 'Pego',
        id: 'nvUwnWHoYu'
    },
    {
        name: 'Penacova',
        id: 'IyRVNyVTHD'
    },
    {
        name: 'Penafiel',
        id: 'WiReq8rmH4'
    },
    {
        name: 'Penedo Gordo',
        id: 'f9V06Mzs5K'
    },
    {
        name: 'Penedono',
        id: 'cl4idDaLi0'
    },
    {
        name: 'Peniche',
        id: '5pGx8QTnGN'
    },
    {
        name: 'Pêra',
        id: 'WuVf7KLbPN'
    },
    {
        name: 'Pêra Velha',
        id: 'KO4TpqTJBF'
    },
    {
        name: 'Perafita',
        id: 'zbani0dbji'
    },
    {
        name: 'Pereiro da Palhacana',
        id: 'K2qfbRuhQC'
    },
    {
        name: 'Perelhal',
        id: 'BWW0IFvsUB'
    },
    {
        name: 'Pero Pinheiro',
        id: 'gIp0rREY49'
    },
    {
        name: 'Peroguarda',
        id: 'r9cXVKWWT0'
    },
    {
        name: 'Perozinho',
        id: 'I5qXhtQ35C'
    },
    {
        name: 'Peso da Régua',
        id: 'uhoeWOd5Q4'
    },
    {
        name: 'Piedade',
        id: 'xeHXEgxTq4'
    },
    {
        name: 'Pinhal Novo',
        id: '3Mekg9NJUu'
    },
    {
        name: 'Pinhão',
        id: 'CyEkeOB0k5'
    },
    {
        name: 'Pinheiro',
        id: 'z9bKDwEhha'
    },
    {
        name: 'Pinheiro Grande',
        id: 'lCjyku8de3'
    },
    {
        name: 'Poceirão',
        id: 'liTNfv9837'
    },
    {
        name: 'Poço do Canto',
        id: 'PJpYpBU8Hp'
    },
    {
        name: 'Podence',
        id: 'ozVIbKMtg7'
    },
    {
        name: 'Poiares',
        id: 'BqsAcHvpHK'
    },
    {
        name: 'Pombal',
        id: 'WVlmZe0C24'
    },
    {
        name: 'Pombalinho',
        id: 'pCettCbStX'
    },
    {
        name: 'Ponta Delgada',
        id: 'EXmqt4MaaT'
    },
    {
        name: 'Ponta Delgada',
        id: 'uQ9TYi24JC'
    },
    {
        name: 'Ponta Garça',
        id: 'YbvnX0trYd'
    },
    {
        name: 'Ponta do Pargo',
        id: 'eQhgWulxqE'
    },
    {
        name: 'Ponta do Sol',
        id: 'jZtBWJFCSd'
    },
    {
        name: 'Ponte',
        id: 'f4Lt4PeC2H'
    },
    {
        name: 'Ponte da Barca',
        id: 'vNJLqDIJIO'
    },
    {
        name: 'Ponte de Lima',
        id: 'Lc3iurHyQy'
    },
    {
        name: 'Ponte de Sôr',
        id: 'e4s7BSqAEL'
    },
    {
        name: 'Pontével',
        id: 'sG6IukPVBy'
    },
    {
        name: 'Pontinha',
        id: 'KRPfPWG9Wh'
    },
    {
        name: 'Porches',
        id: '9z7z1KJ6Jd'
    },
    {
        name: 'Portalegre',
        id: 'KlDz1tckqA'
    },
    {
        name: 'Portimão',
        id: 'f3cDpRclk1'
    },
    {
        name: 'Porto',
        id: 'jYckCfRXHR'
    },
    {
        name: 'Porto Covo',
        id: 'R40gDyHITC'
    },
    {
        name: 'Porto Judeu',
        id: 'GZkVmrVuLz'
    },
    {
        name: 'Porto Martins',
        id: 'w7vB7YExmE'
    },
    {
        name: 'Porto Moniz',
        id: '8atFBQNJDw'
    },
    {
        name: 'Porto Salvo',
        id: 'ixKGFUBCmG'
    },
    {
        name: 'Porto da Cruz',
        id: 'wQyx8wee96'
    },
    {
        name: 'Porto de Mós',
        id: 'G4FG91s8wR'
    },
    {
        name: 'Póvoa da Raposeira',
        id: 'yl6vjVrasI'
    },
    {
        name: 'Póvoa de Lanhoso',
        id: 'TZTRBYXipw'
    },
    {
        name: 'Póvoa de Santa Iria',
        id: 'Bv5uScOS6C'
    },
    {
        name: 'Póvoa de Santo Adrião',
        id: 'SpgTnnK7kZ'
    },
    {
        name: 'Póvoa de Varzim',
        id: '6dxXqMQJgo'
    },
    {
        name: 'Povoação',
        id: 'LCI4aEC3xg'
    },
    {
        name: 'Prado',
        id: 'bK57aMQGAm'
    },
    {
        name: 'Pragal',
        id: 'mJScGOF7dQ'
    },
    {
        name: 'Praia da Graciosa',
        id: 'dERxKu2lD9'
    },
    {
        name: 'Praia da Vitória',
        id: 'vc3Ieba9Hv'
    },
    {
        name: 'Praia do Ribatejo',
        id: 'Rm6zKVgYlD'
    },
    {
        name: 'Prazeres',
        id: '5y70jnT1qF'
    },
    {
        name: 'Prior Velho',
        id: 'nG0INgP5oV'
    },
    {
        name: 'Proença-a-Nova',
        id: 'oMmQLPrB3r'
    },
    {
        name: 'Quarteira',
        id: 'rVeYSC7tcD'
    },
    {
        name: 'Queijas',
        id: 'azaZKOeIFJ'
    },
    {
        name: 'Queluz',
        id: 'BNV64YaQmi'
    },
    {
        name: 'Quinta Do Conde',
        id: 'dSasHJlhUo'
    },
    {
        name: 'Quinta do Anjo',
        id: 'PMyjIE8grF'
    },
    {
        name: 'Quintanilha',
        id: 'I2j6IygxvY'
    },
    {
        name: 'Quintela',
        id: '5isCk32xyv'
    },
    {
        name: 'Rabal',
        id: 'MNcPtC2pgr'
    },
    {
        name: 'Rabo de Peixe',
        id: 'ClUT5J6pD1'
    },
    {
        name: 'Ramada',
        id: 'qvEqCkE13s'
    },
    {
        name: 'Real',
        id: 'nf4tsM5zrX'
    },
    {
        name: 'Rebordões',
        id: '1xeo5I6Kai'
    },
    {
        name: 'Rebordosa',
        id: 'Y05mfmbTN5'
    },
    {
        name: 'Recarei',
        id: 'jaa8vwSrNk'
    },
    {
        name: 'Redondo',
        id: 'cQq4XRAzVf'
    },
    {
        name: 'Refojos de Basto',
        id: '9oNuBm4kRY'
    },
    {
        name: 'Reguengos de Monsaraz',
        id: 'NDb8efBYOZ'
    },
    {
        name: 'Relva',
        id: 'Db36MP0Lsw'
    },
    {
        name: 'Rendufe',
        id: 'OjVqsV1Ifz'
    },
    {
        name: 'Resende',
        id: 'ZCxm36EzZP'
    },
    {
        name: 'Riachos',
        id: '6XVFKipzfr'
    },
    {
        name: 'Riba de Ave',
        id: 'j7gIsr3obU'
    },
    {
        name: 'Ribeira Brava',
        id: 'A7FH6nUTce'
    },
    {
        name: 'Ribeira Chã',
        id: 'Az6m56ENPe'
    },
    {
        name: 'Ribeira Grande',
        id: 'xX1cRcKYIM'
    },
    {
        name: 'Ribeira Grande',
        id: 'kXkiDNdy3y'
    },
    {
        name: 'Ribeira Quente',
        id: 'FLCg15ARDO'
    },
    {
        name: 'Ribeira Seca',
        id: 'JAgnBBiGaY'
    },
    {
        name: 'Ribeira Seca',
        id: 'GrVf0vwRIL'
    },
    {
        name: 'Ribeira Seca',
        id: 'QgwdMOZ0At'
    },
    {
        name: 'Ribeira das Tainhas',
        id: '1CwUd2SfyA'
    },
    {
        name: 'Ribeirão',
        id: 'vOHHvkC9eh'
    },
    {
        name: 'Ribeirinha',
        id: 'vWC1iv17vq'
    },
    {
        name: 'Rio Maior',
        id: '2c8HGpeeGb'
    },
    {
        name: 'Rio Mau',
        id: 'Efda8iLasl'
    },
    {
        name: 'Rio Mau',
        id: '5lB68h4GW9'
    },
    {
        name: 'Rio Meão',
        id: 'tpxPdEvktO'
    },
    {
        name: 'Rio Tinto',
        id: 'SZkwBC887X'
    },
    {
        name: 'Rio de Loba',
        id: 'r0jAKJzwUY'
    },
    {
        name: 'Rio de Mouro',
        id: '5YLL3Ae4BX'
    },
    {
        name: 'Rochoso',
        id: 'C8EikPi4BV'
    },
    {
        name: 'Ronfe',
        id: 'JNXuUnIwdb'
    },
    {
        name: 'Rosairinho',
        id: 'XDEwZg6CFI'
    },
    {
        name: 'Rosais',
        id: 'e6gLmIcRMH'
    },
    {
        name: 'Rosto de Cão',
        id: '5g5XkExdQW'
    },
    {
        name: 'Rua',
        id: '1VPpqBx61t'
    },
    {
        name: 'Rubiãis',
        id: 'F81W4OcP4r'
    },
    {
        name: 'Sabrosa',
        id: 'Lhw2AOkD8G'
    },
    {
        name: 'Sacavém',
        id: 'scP6lg9HO4'
    },
    {
        name: 'Sagres',
        id: 'IEr6i8ZCHC'
    },
    {
        name: 'Salir de Matos',
        id: '036Fkbzdkc'
    },
    {
        name: 'Salir do Porto',
        id: 'ESl0mVUAu2'
    },
    {
        name: 'Salreu',
        id: '7FtKQJsciy'
    },
    {
        name: 'Salvada',
        id: 'wsVAy4l6DO'
    },
    {
        name: 'Salvaterra de Magos',
        id: 'Aifm1mdGfB'
    },
    {
        name: 'Samil',
        id: 't3MZCfmB7Q'
    },
    {
        name: 'Samora Correia',
        id: 'D37FbvGfSl'
    },
    {
        name: 'Samouco',
        id: 'Yzk5vE1vn5'
    },
    {
        name: 'Sande',
        id: '4kP0fW62Vp'
    },
    {
        name: 'Sandim',
        id: 'CCIZEwbIMx'
    },
    {
        name: 'Sanfins',
        id: 'WAnkTjC9v4'
    },
    {
        name: 'Sangalhos',
        id: 'cajkIdz2Th'
    },
    {
        name: 'Santa Bárbara',
        id: 'FUM9cSbJ1P'
    },
    {
        name: 'Santa Bárbara',
        id: '07CCq98PGV'
    },
    {
        name: 'Santa Bárbara de Nexe',
        id: 'ANHDfPHq4s'
    },
    {
        name: 'Santa Catarina da Serra',
        id: 'fkr73cTAxC'
    },
    {
        name: 'Santa Clara',
        id: '6j6B7TG5ol'
    },
    {
        name: 'Santa Clara de Louredo',
        id: 'ULYPr7KLjZ'
    },
    {
        name: 'Santa Comba Dão',
        id: 'aeZxBPCUrd'
    },
    {
        name: 'Santa Cruz',
        id: 'lwKaT0GvtN'
    },
    {
        name: 'Santa Cruz',
        id: '23ijBqdlTG'
    },
    {
        name: 'Santa Cruz da Graciosa',
        id: 'n2aYFQzYIN'
    },
    {
        name: 'Santa Cruz das Flores',
        id: 'Zv2f9rUN8c'
    },
    {
        name: 'Santa Cruz do Bispo',
        id: 'jU8C8miqgG'
    },
    {
        name: 'Santa Iria da Azóia',
        id: 'YnA3wkytj2'
    },
    {
        name: 'Santa Luzia',
        id: 'Ag1WdS8Hc1'
    },
    {
        name: 'Santa Luzia',
        id: 'mtFEfKvKY7'
    },
    {
        name: 'Santa Margarida da Coutada',
        id: 'oZcVn8q37P'
    },
    {
        name: 'Santa Ovaia',
        id: 'zWpQ1HZ22W'
    },
    {
        name: 'Santa Vitória',
        id: 'EPLu0nIcaW'
    },
    {
        name: 'Santana',
        id: 'cYCVJKmvDp'
    },
    {
        name: 'Santarém',
        id: 'Yi3QvdZmyC'
    },
    {
        name: 'Santiago do Cacém',
        id: 'FVHwbS83UW'
    },
    {
        name: 'Santo André',
        id: 'KhDDqCXMUM'
    },
    {
        name: 'Santo André',
        id: 'hZyAx4HByi'
    },
    {
        name: 'Santo Antão',
        id: '7w05Au0t4d'
    },
    {
        name: 'Santo Antão do Tojal',
        id: 'mSO2CJ19C9'
    },
    {
        name: 'Santo António da Charneca',
        id: 'UiYyuA1L8c'
    },
    {
        name: 'Santo António dos Olivais',
        id: 'M07EI3JLdR'
    },
    {
        name: 'Santo Isidoro',
        id: '5IRgyEF15a'
    },
    {
        name: 'Santo Izidoro',
        id: 'wX4FD1URQH'
    },
    {
        name: 'Santo Tirso',
        id: 'kwA4ubSci9'
    },
    {
        name: 'Santo da Serra',
        id: 'ds0CNDomIW'
    },
    {
        name: 'Santos-o-Velho',
        id: 'KqTEOGpu80'
    },
    {
        name: 'São Bartolomeu',
        id: 'GRVVA9tBEw'
    },
    {
        name: 'São Bartolomeu',
        id: 'zhVvE0v2qS'
    },
    {
        name: 'São Bartolomeu de Messines',
        id: '1Be8HHiBJR'
    },
    {
        name: 'São Brás de Alportel',
        id: 'mgHoJd4tHa'
    },
    {
        name: 'São Brissos',
        id: 'SBrr6J0izM'
    },
    {
        name: 'São Domingos de Rana',
        id: 'QgyBJictmn'
    },
    {
        name: 'São Félix da Marinha',
        id: 'CgTnCeUkqm'
    },
    {
        name: 'São Jacinto',
        id: 'xJGsfVLpa2'
    },
    {
        name: 'São João',
        id: 'xcARhdyDDq'
    },
    {
        name: 'São João da Madeira',
        id: 'TU2O7VSso9'
    },
    {
        name: 'São João da Pesqueira',
        id: 'a2yZJ3LZmr'
    },
    {
        name: 'São João da Talha',
        id: 'WEkJavamTq'
    },
    {
        name: 'São João das Lampas',
        id: '74cKtUxDTS'
    },
    {
        name: 'São João de Areias',
        id: '1ih9v8afPt'
    },
    {
        name: 'São João de Ver',
        id: 'TUrubxNawC'
    },
    {
        name: 'São João dos Montes',
        id: '92q3jSczXm'
    },
    {
        name: 'São Jorge',
        id: 'BFiyvXX2LF'
    },
    {
        name: 'São Jorge da Beira',
        id: '3SHDb8GZ3X'
    },
    {
        name: 'São Luis',
        id: '19N2XG0gF3'
    },
    {
        name: 'São Mamede de Infesta',
        id: '7UgH9TCGGN'
    },
    {
        name: 'São Marcos da Serra',
        id: 'xP6D8N3qwk'
    },
    {
        name: 'São Martinho',
        id: 'ngL0lXU0BT'
    },
    {
        name: 'São Martinho do Porto',
        id: 'D6woJgMSV7'
    },
    {
        name: 'São Mateus',
        id: 'YLWhHsXQP5'
    },
    {
        name: 'São Matias',
        id: 'sDM3VbCKvI'
    },
    {
        name: 'São Miguel de Rio Torto',
        id: 'CBPYLGiDjL'
    },
    {
        name: 'São Miguel do Couto',
        id: 'XUL2v98yR0'
    },
    {
        name: 'São Pedro da Cadeira',
        id: 'JttN6Dq0EM'
    },
    {
        name: 'São Pedro da Cova',
        id: '3S7ROJSfxO'
    },
    {
        name: 'São Pedro de Alva',
        id: 'SuA7rgp2xL'
    },
    {
        name: 'São Pedro de Serracenos',
        id: 'b4qW1ECKym'
    },
    {
        name: 'São Pedro do Sul',
        id: 'ystoMu8ti3'
    },
    {
        name: 'São Romão',
        id: 'EduQBpBQOc'
    },
    {
        name: 'São Romão do Coronado',
        id: '6EZAYNsNzD'
    },
    {
        name: 'São Roque',
        id: 'fD7n9kO8LX'
    },
    {
        name: 'São Roque',
        id: 'BCW0VtpBLh'
    },
    {
        name: 'São Roque',
        id: '8wWM5j4Y2b'
    },
    {
        name: 'São Roque do Pico',
        id: 'rP5H6HapuC'
    },
    {
        name: 'São Sebastião',
        id: '4vFYn9ANH9'
    },
    {
        name: 'São Simão / Pé da Serra',
        id: 'hTxzWL5fyg'
    },
    {
        name: 'São Teotónio',
        id: 'UEM9oeZVwF'
    },
    {
        name: 'São Vicente',
        id: '3C0rzYErJU'
    },
    {
        name: 'São Vicente',
        id: 'ttUv2NKJFd'
    },
    {
        name: 'São Vicente do Paul',
        id: '6ndyAVI0i1'
    },
    {
        name: 'Sardoal',
        id: '4Ku7KJbQJj'
    },
    {
        name: 'Sarilhos Pequenos',
        id: 'N1u4M3oUFo'
    },
    {
        name: 'Sátão',
        id: '1wvMaH5WzJ'
    },
    {
        name: 'Sazes da Beira',
        id: 'umuD9aGdkh'
    },
    {
        name: 'Seia',
        id: 'nUEE2F3WZb'
    },
    {
        name: 'Seixal',
        id: 'aqPYwKYUJK'
    },
    {
        name: 'Seixal',
        id: 'KQRoJLHjAq'
    },
    {
        name: 'Selho',
        id: 'THmEWWrrkP'
    },
    {
        name: 'Semelhe',
        id: 'mE953eJXCB'
    },
    {
        name: 'Sendim',
        id: 'FV8OzWjEHe'
    },
    {
        name: 'Senhora da Hora',
        id: 't0XfHJ15KQ'
    },
    {
        name: 'Senhora da Luz',
        id: 'gStvzoTYWM'
    },
    {
        name: 'Senhora do Rosário',
        id: 'uDWGmJKr5n'
    },
    {
        name: 'Sequeira',
        id: 'KNHVAHBnvY'
    },
    {
        name: 'Sequeiros',
        id: '2nn8cTfJ3Q'
    },
    {
        name: 'Sermonde',
        id: 'nvxsOcrLN6'
    },
    {
        name: 'Sernancelhe',
        id: 'PZjJNUZklf'
    },
    {
        name: 'Seroa',
        id: 'X1v8j2irku'
    },
    {
        name: 'Serpa',
        id: 'Ost08g3KN3'
    },
    {
        name: 'Serra de El-Rei',
        id: 'jmNkBgVtbJ'
    },
    {
        name: 'Serra do Bouro',
        id: 'pmtuhQI1Jt'
    },
    {
        name: 'Sertã',
        id: 'uH9oK5YluY'
    },
    {
        name: 'Serzedelo',
        id: 'Adlfw89uUw'
    },
    {
        name: 'Serzedo',
        id: 'O5WEA5C0BZ'
    },
    {
        name: 'Serzedo',
        id: 'sGO3amLzD8'
    },
    {
        name: 'Sesimbra',
        id: 'SdYd9N49As'
    },
    {
        name: 'Sete Cidades',
        id: 'onoHueI2Np'
    },
    {
        name: 'Setúbal',
        id: 'wlA2fRkmfJ'
    },
    {
        name: 'Silvalde',
        id: '2jltrIGvQI'
    },
    {
        name: 'Silveira',
        id: 'XA4p6tsmeT'
    },
    {
        name: 'Silves',
        id: 'aCKxzfCSO0'
    },
    {
        name: 'Sines',
        id: 'DV1tRmsGVd'
    },
    {
        name: 'Sintra',
        id: 'ZqrAahRV7d'
    },
    {
        name: 'Sobrado',
        id: 'CpRf8h7lQ2'
    },
    {
        name: 'Sobral de Monte Agraço',
        id: 'I9xuNhQ5iy'
    },
    {
        name: 'Sobralinho',
        id: 'uaJSHFhTjf'
    },
    {
        name: 'Sobreda',
        id: 'GgCGK8tltu'
    },
    {
        name: 'Sobreira',
        id: '1SiAHtokV6'
    },
    {
        name: 'Soure',
        id: '4wxsGCndkd'
    },
    {
        name: 'Souto',
        id: '4E22hMBOTF'
    },
    {
        name: 'Souto',
        id: 'Aql3GxBhd8'
    },
    {
        name: 'Souto da Carpalhosa',
        id: 'T4dM9bzowj'
    },
    {
        name: 'Souto de Aguiar',
        id: '1ZRUcic7ZJ'
    },
    {
        name: 'Tábua',
        id: 'e9sqXvkT90'
    },
    {
        name: 'Tabuaço',
        id: 'nhj3yUgGD4'
    },
    {
        name: 'Tamera',
        id: '1pLxTwpg14'
    },
    {
        name: 'Tancos',
        id: 'SHitBhOtAt'
    },
    {
        name: 'Tarouca',
        id: '8qUSW5DnVn'
    },
    {
        name: 'Tavarede',
        id: 'kVbDuGhONy'
    },
    {
        name: 'Tavira',
        id: 'eoADAWqHJ5'
    },
    {
        name: 'Tebosa',
        id: 'K6u6lbMTO9'
    },
    {
        name: 'Teixoso',
        id: 'iB0nO5znmM'
    },
    {
        name: 'Telões',
        id: 'XFNpWbSEay'
    },
    {
        name: 'Terrugem',
        id: '5A1mGwUReA'
    },
    {
        name: 'Tocha',
        id: '41wxeRz6rz'
    },
    {
        name: 'Tomar',
        id: 'E8Qlmr5zre'
    },
    {
        name: 'Tondela',
        id: '3KMe0HiA3F'
    },
    {
        name: 'Torre de Moncorvo',
        id: 'mQ4C7OXTV3'
    },
    {
        name: 'Torres Novas',
        id: 'fnXHTLeI07'
    },
    {
        name: 'Torres Vedras',
        id: 'QBwakqUAZW'
    },
    {
        name: 'Trafaria',
        id: 'Z4yMc2nJDa'
    },
    {
        name: 'Tramagal',
        id: 'zIXk38ls9W'
    },
    {
        name: 'Travanca',
        id: 'c6wB2lAIGo'
    },
    {
        name: 'Treixedo',
        id: 'xHSMHIV1hC'
    },
    {
        name: 'Trevões',
        id: 'FL5QfyeHUt'
    },
    {
        name: 'Trigaches',
        id: '12nbeqne4t'
    },
    {
        name: 'Trofa',
        id: 'fiOq8JaNP7'
    },
    {
        name: 'Tunes',
        id: 'SBhu3PVeZC'
    },
    {
        name: 'Turquel',
        id: 'Huq2GZZu5N'
    },
    {
        name: 'Unhais-o-Velho',
        id: 'H4ZwzNMT5X'
    },
    {
        name: 'Unhos',
        id: 'mDFFlkVvBn'
    },
    {
        name: 'Urgeses',
        id: 'Y5mKY0hpt1'
    },
    {
        name: 'Urzelina',
        id: 'TF7MAvPfnp'
    },
    {
        name: 'Vagos',
        id: '1rOXLqlOeW'
    },
    {
        name: 'Vairão',
        id: 'PSSyD2SHKT'
    },
    {
        name: 'Valadares',
        id: 'VoyTDTk1yS'
    },
    {
        name: 'Valado de Frades',
        id: 'hNPJKhlOzJ'
    },
    {
        name: 'Valbom',
        id: 'PWwm0iZ3bu'
    },
    {
        name: 'Vale da Amoreira',
        id: '94fijorkuX'
    },
    {
        name: 'Vale de Cambra',
        id: 'FXGJyOAdFA'
    },
    {
        name: 'Vale de Figueira',
        id: 'N7ls3G9Xr5'
    },
    {
        name: 'Vale de Santarém',
        id: 'KbRvUKoJwG'
    },
    {
        name: 'Válega',
        id: 'XtLM1vmHhM'
    },
    {
        name: 'Valenza',
        id: 'A6vARYJayE'
    },
    {
        name: 'Vales do Rio',
        id: 'sICYkkzAgX'
    },
    {
        name: 'Valongo',
        id: '7Fhi97cC6e'
    },
    {
        name: 'Valongo',
        id: 'GUB3mN5xDU'
    },
    {
        name: 'Valpaços',
        id: 't49Ox3SUKG'
    },
    {
        name: 'Valverde',
        id: 'bZEuUdI1xu'
    },
    {
        name: 'Várzea',
        id: '3WurZQNtph'
    },
    {
        name: 'Velas',
        id: 'BCE6HDmNPr'
    },
    {
        name: 'Venda do Pinheiro',
        id: 'pfcwWxZeDO'
    },
    {
        name: 'Vendas Novas',
        id: 'SkoODmaOWC'
    },
    {
        name: 'Ventosa',
        id: 'QPJ0cdDcpk'
    },
    {
        name: 'Vestiaria',
        id: 'DdmeVz5HHq'
    },
    {
        name: 'Vialonga',
        id: 'ji0hdoeAac'
    },
    {
        name: 'Viana do Castelo',
        id: '6hIyz35tpM'
    },
    {
        name: 'Vidais',
        id: 'w0TFcdG47T'
    },
    {
        name: 'Vide',
        id: 'QcIqw8zFin'
    },
    {
        name: 'Vidigueira',
        id: 'CDIuJKgsws'
    },
    {
        name: 'Vieira de Leiria',
        id: 'AUfc1Vrhyp'
    },
    {
        name: 'Vieira do Minho',
        id: 'L5qeNJcimo'
    },
    {
        name: 'Vila Chã',
        id: '9vzblxLHTI'
    },
    {
        name: 'Vila Chã de Ourique',
        id: '1edmJtF0dD'
    },
    {
        name: 'Vila Cova',
        id: 'hjyRyaP3ha'
    },
    {
        name: 'Vila Franca de Xira',
        id: 'WlLj58DmIM'
    },
    {
        name: 'Vila Franca do Campo',
        id: 'x81oPEolg1'
    },
    {
        name: 'Vila Frescainha',
        id: 'mkSM8DGIXv'
    },
    {
        name: 'Vila Meã',
        id: 'oSf2Vnk7YF'
    },
    {
        name: 'Vila Nova De Cacela',
        id: 'z9Q7EiWIc4'
    },
    {
        name: 'Vila Nova da Barquinha',
        id: 'z8QNSFP3qk'
    },
    {
        name: 'Vila Nova da Telha',
        id: 'ZnmO0RVoZT'
    },
    {
        name: 'Vila Nova de Ceira',
        id: '7AUXrVugTA'
    },
    {
        name: 'Vila Nova de Famalicão',
        id: 'oNoFXxZ60s'
    },
    {
        name: 'Vila Nova de Foz Côa',
        id: 'JXBkBz9Gvv'
    },
    {
        name: 'Vila Nova de Gaia',
        id: '37koiUB9d1'
    },
    {
        name: 'Vila Nova de Milfontes',
        id: 'nhbpkJO7PB'
    },
    {
        name: 'Vila Nova de Paiva',
        id: '7iM3cPrSHS'
    },
    {
        name: 'Vila Praia de Âncora',
        id: 'MPfny15EzY'
    },
    {
        name: 'Vila Real',
        id: '7e73mPELxm'
    },
    {
        name: 'Vila Real de Santo António',
        id: 'Jp7dMbQVv4'
    },
    {
        name: 'Vila Seca',
        id: 'HKr4CYSQRs'
    },
    {
        name: 'Vila Velha de Ródão',
        id: '9MEF8WQdd2'
    },
    {
        name: 'Vila Verde',
        id: 'kYiMr1ADIh'
    },
    {
        name: 'Vila Verde',
        id: 'pIkUWFrBfO'
    },
    {
        name: 'Vila Viçosa',
        id: 'iz0uRoBdYO'
    },
    {
        name: 'Vila de Rei',
        id: '4wdNVCpXmB'
    },
    {
        name: 'Vila do Bispo',
        id: 'Efv2SIHJra'
    },
    {
        name: 'Vila do Conde',
        id: 'HWWuhu55mI'
    },
    {
        name: 'Vila do Corvo',
        id: 'cXgY6XTJjp'
    },
    {
        name: 'Vila do Porto',
        id: 'noOYQe24OO'
    },
    {
        name: 'Vilaça',
        id: 'r6g4d87Tsf'
    },
    {
        name: 'Vilamoura',
        id: 'jRnYxqoKGs'
    },
    {
        name: 'Vilar de Andorinho',
        id: 'inYnrvmDZB'
    },
    {
        name: 'Vilar de Figos',
        id: 'raLgfXf2Rs'
    },
    {
        name: 'Vilar do Paraíso',
        id: 'XoKi9zM0Bi'
    },
    {
        name: 'Vilarinho',
        id: 'tKo4peY6EN'
    },
    {
        name: 'Vilela',
        id: '1PxBSwPG8v'
    },
    {
        name: 'Vimeiro',
        id: '4MGmpAi7FG'
    },
    {
        name: 'Vinhais',
        id: 'y7zGBqKBHO'
    },
    {
        name: 'Viseu',
        id: 'wzCPv1QglJ'
    },
    {
        name: 'Vizela',
        id: 'nqb2jpJnLz'
    },
    {
        name: 'Zambujeira do Mar',
        id: 'LHMkGcU4Yc'
    }
];
