import { Button } from 'components/elements';
import { ChangeEvent, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';

import { getCroppedImg } from './canvasUtils';
import styles from './ImageCropDialog.module.scss';

export type ImageCropDialogProps = {
    id: number;
    imageUrl: string;
    cropInit: Point;
    zoomInit: number;
    onCancel: () => void;
    setCroppedImageFor: (
        id: number,
        crop: Point,
        zoom: number,
        aspect: number,
        croppedImgUrl: string
    ) => void;
    // resetImage: (id: number) => void;
};

export const ImageCropDialog: React.FC<ImageCropDialogProps> = ({
    id,
    imageUrl,
    cropInit,
    zoomInit,
    onCancel,
    setCroppedImageFor
    // resetImage
}) => {
    if (!zoomInit) zoomInit = 1;
    if (!cropInit) cropInit = { x: 0, y: 0 };

    const [zoom, setZoom] = useState(zoomInit);
    const [crop, setCrop] = useState<Point>(cropInit);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({} as Area);

    const onCropChange = (crop: Point) => setCrop(crop);

    const onZoomChange = (zoom: number) => setZoom(zoom);

    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) =>
        setCroppedAreaPixels(croppedAreaPixels);

    const onCrop = async () => {
        const croppedImgBase64 = await getCroppedImg(imageUrl, croppedAreaPixels);

        if (!croppedImgBase64) return;
        setCroppedImageFor(id, crop, zoom, 1, croppedImgBase64);
    };

    // const onResetImage = () => resetImage(id);

    return (
        <div className={styles.dialog}>
            <div className={styles.backdrop} />
            <div className={styles['crop-container']}>
                <Cropper
                    image={imageUrl}
                    zoom={zoom}
                    crop={crop}
                    aspect={1}
                    showGrid={false}
                    onCropChange={onCropChange}
                    onZoomChange={onZoomChange}
                    onCropComplete={onCropComplete}
                    classes={{
                        containerClassName: styles['cropper-container'],
                        mediaClassName: styles['cropper-media'],
                        cropAreaClassName: styles['cropper-area']
                    }}
                />
            </div>
            <div className={styles.controls}>
                <div className={styles['controls-upper-area']}>
                    <input
                        type="range"
                        min={1}
                        max={3}
                        step={0.1}
                        value={zoom}
                        onInput={(e: ChangeEvent<HTMLInputElement>) =>
                            setZoom(Number(e.target.value))
                        }
                        className={styles.slider}
                    />
                </div>
                <div className={styles['button-container']}>
                    <Button
                        label="Cancelar"
                        size="md"
                        onClick={onCancel}
                        className={styles['button-cancel']}
                    />
                    {/* <button onClick={onResetImage}>reset</button> */}
                    <Button
                        label="Gravar"
                        size="md"
                        onClick={onCrop}
                        className={styles['button-save']}
                    />
                </div>
            </div>
        </div>
    );
};
