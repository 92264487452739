import { useTheme } from 'hooks';
import { useMemo } from 'react';
import { COLORS } from 'theme/colors';
import { IconAreaV2Props } from 'theme/icons/types';

export const IconHealthV2: React.FC<IconAreaV2Props> = ({
    width = 30,
    height = 30,
    isSelected,
    ...props
}) => {
    const { darkMode } = useTheme();

    const defaultColor = useMemo(
        () => ({
            fill: darkMode ? COLORS.BLUISH_PURPLE_800 : COLORS.WHITE,
            stroke: darkMode ? COLORS.PURPLE_300 : COLORS.MAGENTA_200
        }),
        [darkMode]
    );

    const selectedColor = useMemo(
        () => ({
            fill: darkMode ? COLORS.BLUISH_PURPLE_800 : COLORS.WHITE,
            stroke: darkMode ? COLORS.WHITE : COLORS.PURPLE_500
        }),
        [darkMode]
    );

    const filter = useMemo(
        () => (darkMode ? 'url(#dark-shadow)' : 'url(#light-shadow)'),
        [darkMode]
    );

    return (
        <svg
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            {...props}>
            <g filter={filter}>
                <path
                    d="M23.1676 6.95949C22.1046 5.82048 20.691 5.13035 19.1879 5.01667C17.6848 4.90299 16.194 5.37345 14.9909 6.34108C13.7751 5.37659 12.2724 4.91541 10.7619 5.04321C9.25133 5.171 7.8356 5.87907 6.77759 7.03593C5.71959 8.19278 5.09122 9.71981 5.00918 11.3334C4.92714 12.947 5.397 14.5375 6.33151 15.8096L6.35093 15.8303L14.8106 24.9201C14.8342 24.9455 14.8623 24.9657 14.8932 24.9794C14.9242 24.9931 14.9574 25.0001 14.9909 25C15.0577 24.9995 15.1216 24.9707 15.1685 24.9201L23.6587 15.8688C23.7017 15.823 23.7274 15.7619 23.7308 15.6972C24.6432 14.4142 25.0888 12.8221 24.9853 11.2157C24.8817 9.6093 24.236 8.09728 23.1676 6.95949Z"
                    fill={isSelected ? selectedColor.fill : defaultColor.fill}
                />
                <path
                    d="M22.4365 7.64177L22.4386 7.64402C23.3428 8.60688 23.8982 9.89742 23.9874 11.28C24.0765 12.6628 23.6919 14.0264 22.9159 15.1176L22.7475 15.3544L22.7462 15.3799L14.9917 23.6469L7.10419 15.1719C6.33148 14.0976 5.93824 12.7541 6.00789 11.3842C6.07853 9.99487 6.61944 8.69061 7.51553 7.7108C8.41022 6.73251 9.59547 6.14546 10.8462 6.03965C12.0963 5.93389 13.3479 6.31414 14.3695 7.1245L14.9952 7.62094L15.6177 7.1203C16.6282 6.30756 17.8694 5.91981 19.1125 6.01383C20.3561 6.10788 21.5385 6.6795 22.4365 7.64177ZM22.93 15.1839L22.9293 15.1846L22.93 15.1839Z"
                    stroke={isSelected ? selectedColor.stroke : defaultColor.stroke}
                    strokeWidth="2"
                />
            </g>
            <defs>
                <filter
                    id="light-shadow"
                    x="3"
                    y="5"
                    width="24"
                    height="24"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_490_725"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_490_725"
                        result="shape"
                    />
                </filter>
                <filter
                    id="dark-shadow"
                    x="3"
                    y="5"
                    width="30"
                    height="30"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_490_725"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_490_725"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
