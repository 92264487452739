import { CardFull } from 'components/elements';
import { sanitize } from 'dompurify';
import { useLanguage, useTheme } from 'hooks';
import { PartnerData } from 'models/types';
import { COLORS } from 'theme';
import { IconExternal } from 'theme/icons';

import styles from './Banners.module.scss';

type BannersProps = {
    partner: PartnerData;
    content: { EXTERNAL_URL: (partner: string) => string };
};

export const Banners: React.FC<BannersProps> = ({ partner, content }) => {
    const { isPT } = useLanguage();
    const { activeTheme } = useTheme();

    if (!partner.banners) return null;

    return (
        <section className={`${styles.banners} container`}>
            <div className={[styles['external-warning'], styles[activeTheme]].join(' ')}>
                <p
                    dangerouslySetInnerHTML={{
                        __html: sanitize(content.EXTERNAL_URL(partner.name))
                    }}
                />
                <IconExternal width={20} className={styles.icon} />
            </div>

            {partner.banners.map((b) => (
                <CardFull
                    key={b.id}
                    area={b.area}
                    hasButton
                    label={isPT ? b.category?.pt : b.category?.en}
                    buttonLabel={isPT ? 'comprar' : 'buy'}
                    heading={b.title}
                    description={b.category}
                    backgroundImage={b.image_2x ?? b.image}
                    uri={b.slug}
                    linkDestination="external"
                    color={partner.brand.primary_color ?? COLORS.ROYAL_BLUE_800}
                    textStyle="dark"
                />
            ))}
        </section>
    );
};
