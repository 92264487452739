export const MOVEMENT_EXERCISE = {
    PT: {
        PRE_TITLE: 'treino',
        TITLE: 'O seu exercício',
        GYM: {
            TITLE: 'Treino em ginásio',
            DESCRIPTION: {
                one: (visits: number): string =>
                    `Estes são os seus ${
                        visits > 4 ? 4 : visits
                    } treinos mais recentes em que ganhou pontos Hi!`,
                two: 'Os dados dos seus treinos podem demorar até uma semana a serem disponibilizados pelo Fitness Hut.'
            },
            NO_ACTIVITY: {
                TITLE: 'Ainda sem nenhum registo',
                DESCRIPTION:
                    'Os dados dos seus treinos podem demorar até uma semana a serem disponibilizados pelo Fitness Hut.'
            },
            BUTTON: 'Mais'
        },
        MANUAL: {
            TITLE: 'Registo manual',
            DESCRIPTION: 'Preencha os campos seguintes e acumule pontos de movimento',
            TRACKER: {
                MESSAGES: {
                    FILL_FORM: 'Preencha o formulário',
                    SENT_ERROR: 'Erro no envio. Tente mais tarde.'
                },
                lABELS: {
                    POINTS: {
                        SINGULAR: 'ponto',
                        PLURAL: 'pontos'
                    },
                    HELPER: 'adicione uma atividade'
                },
                FORM: {
                    LABELS: {
                        ACTIVITY: 'Atividade',
                        DURATION: 'Duração',
                        CALCULATING: 'A calcular...',
                        SUBMIT: 'adicionar'
                    }
                }
            }
        },
        DEVICES: {
            TITLE: 'Dispositivos',
            DESCRIPTION: 'Use os seus dispositivos e ganhe pontos de movimento'
        },
        SERVICES: {
            TITLE: 'A pensar em si'
        }
    },
    EN: {
        PRE_TITLE: 'workout',
        TITLE: 'Your exercise',
        GYM: {
            TITLE: 'Gym workouts',
            DESCRIPTION: {
                one: (visits: number): string =>
                    `These were your ${
                        visits > 4 ? 4 : visits
                    } most recent workouts in which you won Hi points!`,
                two: 'Your workout data can take up to a week to be made available by the Fitness Hut.'
            },
            NO_ACTIVITY: {
                TITLE: 'No record yet',
                DESCRIPTION:
                    'Your workout data can take up to a week to be made available by the Fitness Hut.'
            },
            BUTTON: 'more'
        },
        MANUAL: {
            TITLE: 'Manual registration',
            DESCRIPTION: 'Fill in the following fields and collect movement points',
            TRACKER: {
                MESSAGES: {
                    FILL_FORM: 'Fill in the form',
                    SENT_ERROR: 'Error sending. Please try again later.'
                },
                lABELS: {
                    POINTS: {
                        SINGULAR: 'point',
                        PLURAL: 'points'
                    },
                    HELPER: 'add an activity'
                },
                FORM: {
                    LABELS: {
                        ACTIVITY: 'Activity',
                        DURATION: 'Duration',
                        CALCULATING: 'Calculating...',
                        SUBMIT: 'add'
                    }
                }
            }
        },
        DEVICES: {
            TITLE: 'Devices',
            DESCRIPTION: 'Use your devices and earn movement points'
        },
        SERVICES: {
            TITLE: 'Thinking of you'
        }
    }
};
