import { ZUMUB } from 'content';

export const CLIENT_AREA = {
    PT: {
        AVATAR: {
            BUTTON_LABEL: 'Editar',
            ERROR_MESSAGE: {
                TITLE: 'Ups!',
                DESCRIPTION:
                    'Aconteceu um erro na atualização da sua imagem de perfil. A equipa Hi! está a trabalhar na solução para este problema.',
                BUTTON_LABEL: 'Fechar'
            }
        },
        MEMBER_CARD: {
            TITLE: 'Cartão de cliente'
        },
        ACCOUNT_STATUS: {
            TITLE: 'O estado da sua conta',
            CARD: {
                STATUS: {
                    ACTIVE: 'Ativa',
                    INACTIVE: 'Suspensa'
                }
            },
            MESSAGE: {
                ACTIVE: 'Todos os serviços da sua subscrição Hi! estão acessíveis',
                INACTIVE: 'A sua subscrição Hi! está temporariamente suspensa'
                // INACTIVE:
                //     'A sua conta encontra-se suspensa por ter pagamentos em atraso. Use os seguintes dados para reativar a sua conta Hi!'
            },
            PAYMENT: {
                ENTITY: 'Entidade',
                REFERENCE: 'Referência',
                AMOUNT: 'Montante',
                NOTE: 'Lembre-se: a atualização do estado da sua conta pode levar até 48h pós efetuar o pagamento do valor em falta.'
            }
        },
        CALENDAR: {
            TITLE: 'Datas Importantes'
        },
        SUBSCRIPTIONS: {
            TITLE: 'As suas subscrições',
            CARDS: {
                MOVEMENT: {
                    TITLE: 'Movimento',
                    DESCRIPTION:
                        '<p><b>Adesão total à rede de ginásios Fitness Hut</b> com o tipo de adesão MOVE:</p><ul><li>Acesso livre-trânsito 7 dias por semana</li><li>Utilização de todos os clubes em Portugal</li><li>Participe em todas as aulas presenciais</li><li>Inclui o serviço de águas e bebidas energéticas Hut2O</li></ul><p><b>Marque sessões de treino com sports coaches</b></p><p>Registe os seus treinos e <b>ganhe pontos HPS (Health Promoter Score)</b></p>'
                },
                NUTRITION: {
                    TITLE: 'Nutrição',
                    DESCRIPTION: `<p><b>Oferta de boas-vindas ZUMUB</b>, o parceiro Hi! para produtos alimentares e de suplementação, com descontos e promoções exclusivas à sua adesão Hi!</p><p>Registe-se na <b><a href=${ZUMUB.LINK.PT} target="_blank" rel="noopener noreferrer">plataforma ZUMUB</a></b> e utilize o cupão Hi! na sua primeira compra.</p><p>Terá imediatamente uma oferta de 10€, mais um shaker!</p><p><b>Descontos exclusivos à parceria Hi! / ZUMUB</b></p><p>Após registo na plataforma ZUMUB, utilize o cupão <b>${ZUMUB.CODE}</b> para ter acesso a descontos ZUMUB exclusivos.</p>`
                },
                HEALTH: {
                    TITLE: 'Saúde',
                    DESCRIPTION:
                        '<p><b>Um seguro de saúde de prevenção</b>, powered by Advancecare.</p><p><b>Marque os seus check-ups através da Hi!</b> e ganhe pontos HPS (Health Promoter Score). Para qualquer outra ativação do seu seguro de saúde, utilize os serviços na rede <a href="https://www.advancecare.pt" target="_blank" rel="noopener noreferrer">AdvanceCare</a>. Mais informações sobre a sua apólice nos documentos disponíveis na área de cliente myHi!.</p>'
                }
            }
        },
        DOCUMENTS: {
            TITLE: 'Documentos',
            SECTIONS: {
                INVOICES: {
                    BUTTON: 'Faturas'
                },
                OTHER: {
                    BUTTON: 'Restantes'
                }
            }
        }
    },
    EN: {
        AVATAR: {
            BUTTON_LABEL: 'Edit',
            ERROR_MESSAGE: {
                TITLE: 'Oops!',
                DESCRIPTION:
                    'An error has occurred while updating your profile picture. The Hi! team is working on a solution to this problem.',
                BUTTON_LABEL: 'Close'
            }
        },
        MEMBER_CARD: {
            TITLE: 'Member card'
        },
        ACCOUNT_STATUS: {
            TITLE: 'Your Account Status',
            CARD: {
                STATUS: {
                    ACTIVE: 'Active',
                    INACTIVE: 'Suspended'
                }
            },
            MESSAGE: {
                ACTIVE: 'All of your Hi! subscription services are accessible',
                INACTIVE: 'Your Hi! subscription is temporarily suspended'
                // INACTIVE:
                //     'Your account is suspended due to late payments. Please use the following details to reactivate your Hi!'
            },
            PAYMENT: {
                ENTITY: 'Entity',
                REFERENCE: 'Reference',
                AMOUNT: 'Amount',
                NOTE: 'Remember: updating your account status may take up to 48 hours after you have paid the outstanding amount.'
            }
        },
        CALENDAR: {
            TITLE: 'Important Dates'
        },
        SUBSCRIPTIONS: {
            TITLE: 'Your subscriptions',
            CARDS: {
                MOVEMENT: {
                    TITLE: 'Movement',
                    DESCRIPTION:
                        '<p><b>Full membership of the Fitness Hut network of gyms</b> with the MOVE membership type:</p><ul><li>Transit-free access 7 days a week</li><li>Use of all clubs in Portugal</li><li>Participate in all face-to-face classes</li><li>Includes Hut2O water and energy drink service</li></ul><p><b>Book training sessions with sports coaches</b></p><p>Record your workouts and <b>earn HPS (Health Promoter Score) points</b></p>'
                },
                NUTRITION: {
                    TITLE: 'Nutrition',
                    DESCRIPTION: `<p><b>ZUMUB welcome offer</b>, the Hi! partner for food and supplementation products, with discounts and promotions exclusive to your Hi! membership.</p><p>Join the <b><a href=${ZUMUB.LINK.EN} target="_blank" rel="noopener noreferrer">ZUMUB platform</a></b> and use the Hi! coupon on your first purchase.</p><p>You'll immediately get a €10 gift, plus a shaker!</p><p><b>Exclusive discounts Hi! partnership / ZUMUB</b></p><p>After registering on the ZUMUB platform, use the coupon <b>${ZUMUB.CODE}</b> to get access to exclusive ZUMUB discounts.</p>`
                },
                HEALTH: {
                    TITLE: 'Health',
                    DESCRIPTION:
                        '<p><b>A preventive health insurance</b>, powered by Advancecare.</p><p><b>Book your check-ups through Hi!</b> and earn HPS (Health Promoter Score) points. For any other activation of your health insurance, use the services in the network <a href="https://www.advancecare.pt" target="_blank" rel="noopener noreferrer">AdvanceCare</a>. Find out more about your policy in the documents available in the myHi!</p>'
                }
            }
        },
        DOCUMENTS: {
            TITLE: 'Documents',
            SECTIONS: {
                INVOICES: {
                    BUTTON: 'Invoices'
                },
                OTHER: {
                    BUTTON: 'Others'
                }
            }
        }
    }
};
