import { LogoZumub } from 'assets/logos';
import { Button, ButtonIcon, Main, Modal, OptionButton } from 'components/elements';
import { Header } from 'components/sections';
import { permissions } from 'config';
import { META_TAGS, NUTRITION_ASSESSMENT_STEPS as FORM_STEPS } from 'content';
import { useApi, useAuth, useGamification, useLanguage, useTheme } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { COLORS } from 'theme';
import { IconNutritionV1 } from 'theme/icons';
import { calculateBMI } from 'utils';
import { BMIScore } from 'utils/calculate-bmi';

import { defaultFormData, defaultFormSteps, formOptions } from './constants';
import styles from './NutritionalAssessment.module.scss';
import {
    AlcoholIntakeData,
    DefaultStepProps,
    ExerciseData,
    FoodIntakeData,
    FormOption,
    FormOptionValue,
    FormScoreData,
    FormStep,
    IllnessesData,
    Local,
    MedicalData,
    MessageProps,
    NavigationProps,
    ResultContent,
    ResumeProps,
    SleepDifficultyData,
    SmokerData,
    StepProps,
    StressData,
    WeightObjectiveData,
    WelcomeFormData
} from './types';

export const Navigation: React.FC<NavigationProps> = ({
    previous,
    next,
    onButtonClick,
    nextCallBack,
    previousCallBack,
    disablePrevious = false,
    disableNext = false
}) => {
    const { activeTheme } = useTheme();

    const onClick = useCallback((local: Local) => onButtonClick(local), [onButtonClick]);

    return (
        <nav className={styles.nav}>
            <ButtonIcon
                type="button"
                icon="ARROW_LEFT"
                size="MD"
                className={[styles['nav-button'], styles[activeTheme]].join(' ')}
                disabled={disablePrevious}
                onClick={() => {
                    onClick(previous);
                    if (previousCallBack) previousCallBack();
                }}
            />
            <ButtonIcon
                type="button"
                icon="ARROW_RIGHT"
                size="MD"
                disabled={disableNext}
                className={[styles['nav-button'], styles[activeTheme]].join(' ')}
                onClick={() => {
                    onClick(next);
                    if (nextCallBack) nextCallBack();
                }}
            />
        </nav>
    );
};

export const Intro: React.FC<
    Omit<StepProps, 'welcomeFormData' | 'formOptions' | 'setWelcomeFormData'>
> = ({ onButtonClick }) => {
    const { isPT } = useLanguage();

    const onClick = useCallback(() => onButtonClick('imc'), [onButtonClick]);

    const FROM_STEPS_CONTENT = isPT ? FORM_STEPS.PT.INTRO : FORM_STEPS.EN.INTRO;

    return (
        <section className="container">
            <div className={styles.card}>
                <IconNutritionV1 colorType="GRADIENT" variation="FILL" width={110} height={128} />
                <h1>
                    {FROM_STEPS_CONTENT.TITLE[0]} <br /> {FROM_STEPS_CONTENT.TITLE[1]}
                </h1>
            </div>
            {FROM_STEPS_CONTENT.DESCRIPTION.map((P) => (
                <p key={P.ID.toString()}>{P.TEXT}</p>
            ))}

            <Button
                label={FROM_STEPS_CONTENT.BUTTON_LABEL}
                size="lg"
                area="nutrition"
                className={`${styles['push-top']} ${styles['push-right']}`}
                onClick={onClick}
            />
        </section>
    );
};

export const IMC: React.FC<StepProps> = ({
    onButtonClick,
    welcomeFormData,
    setWelcomeFormData
}) => {
    const { isPT } = useLanguage();
    const { activeTheme } = useTheme();
    const [weight, setWeight] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);
    const [BMI, setBMI] = useState<BMIScore>({} as BMIScore);
    const [showBMI, setShowBMI] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const FROM_STEPS_CONTENT = isPT ? FORM_STEPS.PT.IMC : FORM_STEPS.EN.IMC;

    useEffect(() => {
        if (welcomeFormData.form_data.user.weight) setWeight(welcomeFormData.form_data.user.weight);
        if (welcomeFormData.form_data.user.height) setHeight(welcomeFormData.form_data.user.height);
    }, [welcomeFormData]);

    useEffect(() => {
        if (weight < 40 || height < 140) return;
        const userBMI = calculateBMI({ weight, height });
        setBMI(userBMI);
        setShowBMI(true);
    }, [welcomeFormData, height, setWelcomeFormData, weight]);

    const updateFormData = useCallback(() => {
        const updatedData: WelcomeFormData = {
            score: {
                ...welcomeFormData.score,
                imc: BMI.score
            },
            form_data: {
                ...welcomeFormData.form_data,
                user: {
                    weight: weight || null,
                    height: height || null,
                    imc: BMI.value
                }
            }
        };

        setWelcomeFormData(updatedData);
    }, [BMI, welcomeFormData, height, setWelcomeFormData, weight]);

    const inputContainerClassName = `${styles['input-container']} ${styles[activeTheme]}`;
    const inputClassName = `${styles.input} ${styles[activeTheme]}`;
    const fakeBtnClassName = `${styles['fake-btn']} ${styles[activeTheme]}`;
    const linkClassName = `${styles.link} ${styles[activeTheme]}`;

    return (
        <>
            <section className="container">
                <div className={`${inputContainerClassName} ${styles.weight}`}>
                    <label htmlFor="weight" className={styles.label}>
                        {FROM_STEPS_CONTENT.LABELS.WEIGHT}
                    </label>
                    <input
                        type="number"
                        name="weight"
                        id="weight"
                        min={40}
                        max={180}
                        value={weight === 0 ? '' : weight}
                        onChange={(e) => setWeight(Number(e.target.value))}
                        className={inputClassName}
                    />
                </div>
                <div className={`${inputContainerClassName} ${styles.height}`}>
                    <label htmlFor="height" className={styles.label}>
                        {FROM_STEPS_CONTENT.LABELS.HEIGHT}
                    </label>
                    <input
                        type="number"
                        name="height"
                        id="height"
                        min={140}
                        max={220}
                        value={height === 0 ? '' : height}
                        onChange={(e) => setHeight(Number(e.target.value))}
                        className={inputClassName}
                    />
                </div>
                {showBMI && (
                    <>
                        <div>
                            <label htmlFor="bmi" className={styles.label}>
                                {FROM_STEPS_CONTENT.LABELS.BMI}
                            </label>
                            <div className={fakeBtnClassName}>{`${
                                isPT ? BMI.label.PT : BMI.label.EN
                            }, ${BMI.value}kg/m2`}</div>
                        </div>
                        <button className={linkClassName} onClick={() => setShowModal(true)}>
                            {FROM_STEPS_CONTENT.LABELS.HOW_TO}
                        </button>
                    </>
                )}
                <Navigation
                    previous="intro"
                    next="medical"
                    onButtonClick={onButtonClick}
                    nextCallBack={updateFormData}
                    disableNext={!showBMI}
                />
            </section>
            <Modal
                show={showModal}
                className={styles.modal}
                footer={{ enable: false }}
                close={{ handler: () => setShowModal(false), buttonColor: COLORS.WHITE }}>
                {FROM_STEPS_CONTENT.MODAL.map((C) => {
                    if (C.TYPE === 'h2') return <h2>{C.TEXT}</h2>;
                    if (C.TYPE === 'p') return <p>{C.TEXT}</p>;
                    if (C.TYPE === 'highlight')
                        return <strong className={styles.highlight}>{C.TEXT}</strong>;
                    if (C.TYPE === 'ul')
                        return (
                            <ul>
                                {C.ITEMS?.map((I) => (
                                    <li key={I.ID.toString()}>
                                        <b>{I.TEXT[0]}</b>
                                        {I.TEXT[1]}
                                    </li>
                                ))}
                            </ul>
                        );
                })}
            </Modal>
        </>
    );
};

export const DefaultFormStep: React.FC<DefaultStepProps> = ({
    onButtonClick,
    welcomeFormData,
    setWelcomeFormData,
    formOptions,
    local,
    title,
    previous,
    next
}) => {
    const { isPT } = useLanguage();
    const [selectedOption, setSelectedOption] = useState<FormOption | undefined>(undefined);

    const updateFormData = useCallback(() => {
        const score = selectedOption?.value[1] as FormScoreData;
        const selfAssessment = selectedOption?.value[0];

        const updatedData: WelcomeFormData = {
            score: {
                imc: welcomeFormData.score.imc,
                medical: local === 'medical' ? score : welcomeFormData.score.medical,
                weight_objective:
                    local === 'weight_objective' ? score : welcomeFormData.score.weight_objective,
                exercise: local === 'exercise' ? score : welcomeFormData.score.exercise,
                smoker: local === 'smoker' ? score : welcomeFormData.score.smoker,
                sleep_difficulty:
                    local === 'sleep_difficulty' ? score : welcomeFormData.score.sleep_difficulty,
                food_intake: local === 'food_intake' ? score : welcomeFormData.score.food_intake,
                alcohol_intake:
                    local === 'alcohol_intake' ? score : welcomeFormData.score.alcohol_intake,
                stress: local === 'stress' ? score : welcomeFormData.score.stress,
                illnesses: local === 'illnesses' ? score : welcomeFormData.score.illnesses
            },
            form_data: {
                user: {
                    imc: welcomeFormData.form_data.user.imc,
                    height: welcomeFormData.form_data.user.height,
                    weight: welcomeFormData.form_data.user.weight
                },
                self_assessment: {
                    medical:
                        local === 'medical'
                            ? (selfAssessment as MedicalData)
                            : (welcomeFormData.form_data.self_assessment.medical as MedicalData),
                    weight_objective:
                        local === 'weight_objective'
                            ? (selfAssessment as WeightObjectiveData)
                            : (welcomeFormData.form_data.self_assessment
                                  .weight_objective as WeightObjectiveData),
                    exercise:
                        local === 'exercise'
                            ? (selfAssessment as ExerciseData)
                            : (welcomeFormData.form_data.self_assessment.exercise as ExerciseData),
                    smoker:
                        local === 'smoker'
                            ? (selfAssessment as SmokerData)
                            : (welcomeFormData.form_data.self_assessment.smoker as SmokerData),
                    sleep_difficulty:
                        local === 'sleep_difficulty'
                            ? (selfAssessment as SleepDifficultyData)
                            : (welcomeFormData.form_data.self_assessment
                                  .sleep_difficulty as SleepDifficultyData),
                    food_intake:
                        local === 'food_intake'
                            ? (selfAssessment as FoodIntakeData)
                            : (welcomeFormData.form_data.self_assessment
                                  .food_intake as FoodIntakeData),
                    alcohol_intake:
                        local === 'alcohol_intake'
                            ? (selfAssessment as AlcoholIntakeData)
                            : (welcomeFormData.form_data.self_assessment
                                  .alcohol_intake as AlcoholIntakeData),
                    stress:
                        local === 'stress'
                            ? (selfAssessment as StressData)
                            : (welcomeFormData.form_data.self_assessment.stress as StressData),
                    illnesses:
                        local === 'illnesses'
                            ? (selfAssessment as IllnessesData)
                            : (welcomeFormData.form_data.self_assessment.illnesses as IllnessesData)
                }
            }
        };

        setWelcomeFormData(updatedData);
    }, [welcomeFormData, local, selectedOption, setWelcomeFormData]);

    const getCurrentStepValue = useCallback(() => {
        const selfAssessment = welcomeFormData.form_data.self_assessment;

        switch (local) {
            case 'medical':
                return selfAssessment.medical;
            case 'weight_objective':
                return selfAssessment.weight_objective;
            case 'exercise':
                return selfAssessment.exercise;
            case 'smoker':
                return selfAssessment.smoker;
            case 'sleep_difficulty':
                return selfAssessment.sleep_difficulty;
            case 'food_intake':
                return selfAssessment.food_intake;
            case 'alcohol_intake':
                return selfAssessment.alcohol_intake;
            case 'stress':
                return selfAssessment.stress;
            case 'illnesses':
                return selfAssessment.illnesses;
            default:
                return;
        }
    }, [welcomeFormData, local]);

    useEffect(() => {
        const value = getCurrentStepValue();
        if (value) {
            setSelectedOption(
                formOptions
                    .find((o) => o.local === local)
                    ?.options.find((o) => o.value[0] === value)
            );
        }
    }, [formOptions, getCurrentStepValue, local]);

    return (
        <section className="container">
            <h2>{isPT ? title.PT : title.EN}</h2>
            {formOptions
                .find((o) => o.local === local)
                ?.options.map((o) => (
                    <OptionButton
                        key={o.id.toString()}
                        onClick={() => setSelectedOption(o)}
                        isSelected={o.id === selectedOption?.id}>
                        {isPT ? o.label.PT : o.label.EN}
                    </OptionButton>
                ))}
            <Navigation
                previous={previous || 'intro'}
                next={next || 'intro'}
                onButtonClick={onButtonClick}
                nextCallBack={updateFormData}
                disableNext={!selectedOption}
            />
        </section>
    );
};

export const Resume: React.FC<ResumeProps> = ({
    onButtonClick,
    welcomeFormData,
    formOptions,
    setResultContent
}) => {
    const { user } = useAuth();
    const { isPT } = useLanguage();
    const { activeTheme } = useTheme();
    const { reportInteraction } = useGamification();
    const { postWelcomePackSurvey } = useApi();

    const CONTENT_FORM_STEPS = isPT ? FORM_STEPS.PT.RESUME : FORM_STEPS.EN.RESUME;

    const getLabel = useCallback(
        (local: Local, value: FormOptionValue | null) => {
            const label = formOptions
                .find((o) => o.local === local)
                ?.options.find((o) => o.value[0] === value)?.label;
            return isPT ? label?.PT : label?.EN;
        },
        [isPT, formOptions]
    );

    const onClick = useCallback((local: Local) => onButtonClick(local), [onButtonClick]);

    const onSend = useCallback(async () => {
        try {
            const result = await postWelcomePackSurvey(welcomeFormData);
            if (!result) throw new Error('No data from server');
            setResultContent(result);

            onButtonClick('message');

            reportInteraction({
                interaction: 'nutri_survey',
                area: 'bonus',
                id: null
            });
        } catch (error) {
            // TO-DO
            console.log({ error });
        }
    }, [
        postWelcomePackSurvey,
        welcomeFormData,
        setResultContent,
        onButtonClick,
        reportInteraction
    ]);

    const BMI = calculateBMI({
        weight: welcomeFormData.form_data.user.weight as number,
        height: welcomeFormData.form_data.user.height as number
    });

    const resumeItems = [
        {
            id: 1,
            label: CONTENT_FORM_STEPS.LABELS.GENDER,
            value: isPT ? (user?.gender === 'female' ? 'Feminino' : 'Masculino') : user?.gender
        },
        {
            id: 2,
            label: CONTENT_FORM_STEPS.LABELS.WEIGHT,
            value: `${welcomeFormData.form_data.user.weight}kg`
        },
        {
            id: 3,
            label: CONTENT_FORM_STEPS.LABELS.HEIGHT,
            value: `${welcomeFormData.form_data.user.height}cm`
        },
        {
            id: 4,
            label: CONTENT_FORM_STEPS.LABELS.IMC,
            value: `${isPT ? BMI.label.PT : BMI.label.EN}, ${BMI.value}kg/m2`
        },
        {
            id: 5,
            label: CONTENT_FORM_STEPS.LABELS.MEDICAL,
            value: getLabel('medical', welcomeFormData.form_data.self_assessment.medical)
        },
        {
            id: 6,
            label: CONTENT_FORM_STEPS.LABELS.WEIGHT_OBJECTIVE,
            value: getLabel(
                'weight_objective',
                welcomeFormData.form_data.self_assessment.weight_objective
            )
        },
        {
            id: 7,
            label: CONTENT_FORM_STEPS.LABELS.EXERCISE,
            value: getLabel('exercise', welcomeFormData.form_data.self_assessment.exercise)
        },
        {
            id: 8,
            label: CONTENT_FORM_STEPS.LABELS.SMOKER,
            value: getLabel('smoker', welcomeFormData.form_data.self_assessment.smoker)
        },
        {
            id: 9,
            label: CONTENT_FORM_STEPS.LABELS.SLEEP_DIFFICULTY,
            value: getLabel(
                'sleep_difficulty',
                welcomeFormData.form_data.self_assessment.sleep_difficulty
            )
        },
        {
            id: 10,
            label: CONTENT_FORM_STEPS.LABELS.FOOD_INTAKE,
            value: getLabel('food_intake', welcomeFormData.form_data.self_assessment.food_intake)
        },
        {
            id: 11,
            label: CONTENT_FORM_STEPS.LABELS.ALCOHOL_INTAKE,
            value: getLabel(
                'alcohol_intake',
                welcomeFormData.form_data.self_assessment.alcohol_intake
            )
        },
        {
            id: 12,
            label: CONTENT_FORM_STEPS.LABELS.STRESS,
            value: getLabel('stress', welcomeFormData.form_data.self_assessment.stress)
        },
        {
            id: 13,
            label: CONTENT_FORM_STEPS.LABELS.ILLNESSES,
            value: getLabel('illnesses', welcomeFormData.form_data.self_assessment.illnesses)
        }
    ];

    const fakeBtnClassName = `${styles['fake-btn']} ${styles[activeTheme]}`;

    return (
        <section className="container">
            <h2>{CONTENT_FORM_STEPS.TITLE}</h2>
            <div>
                {resumeItems.map((i) => (
                    <div key={i.id.toString()}>
                        <span>{i.label}</span>
                        <div className={fakeBtnClassName}>{i.value}</div>
                    </div>
                ))}
            </div>
            <nav className={styles.nav}>
                <ButtonIcon
                    icon="ARROW_LEFT"
                    size="MD"
                    className={[styles['nav-button'], styles[activeTheme]].join(' ')}
                    onClick={() => onClick('illnesses')}
                />
                <Button
                    label={CONTENT_FORM_STEPS.SUBMIT}
                    size="lg"
                    area="nutrition"
                    onClick={onSend}
                />
            </nav>
        </section>
    );
};

export const Message: React.FC<MessageProps> = ({ resultContent }) => {
    const { isPT } = useLanguage();

    useEffect(
        () =>
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            }),
        []
    );

    const CONTENT_FORM_STEPS = isPT ? FORM_STEPS.PT.MESSAGE : FORM_STEPS.EN.MESSAGE;

    return (
        <section className={`${styles.intro} container`}>
            <div className={styles.card}>
                <IconNutritionV1 colorType="GRADIENT" variation="FILL" width={110} height={128} />
                <h1>{CONTENT_FORM_STEPS.TITLE}</h1>
            </div>
            <p>{resultContent.description}</p>
            <h2 className={styles['push-top']}>Experimente</h2>
            {!resultContent.pack.image || resultContent.pack.image === '' ? (
                <div className={styles['logo-container']}>
                    <LogoZumub width={180} height={36} />
                </div>
            ) : (
                <img
                    srcSet={`${resultContent.pack.image_2x} 2x, ${resultContent.pack.image}`}
                    src={resultContent.pack.image}
                    alt={resultContent.pack.title}
                    className={styles['pack-image']}
                />
            )}

            <h3>{resultContent.pack.title}</h3>
            <p>{resultContent.pack.description}</p>

            <a
                href={resultContent.pack.url}
                target="_blank"
                rel="noopener noreferrer"
                className={styles['button-link']}>
                {CONTENT_FORM_STEPS.STORE_BUTTON_LABEL}
            </a>
        </section>
    );
};

export const Assessment: React.FC = () => {
    const { validateUserAccessToArea } = useAuth();
    const { isPT } = useLanguage();

    const [formSteps, setFormSteps] = useState<FormStep[]>(defaultFormSteps);
    const [currentStep, setCurrentStep] = useState<FormStep>(
        formSteps.find(({ local }) => local === 'intro') as FormStep
    );
    const [welcomeFormData, setWelcomeFormData] = useState<WelcomeFormData>(defaultFormData);
    const [resultContent, setResultContent] = useState<ResultContent>({} as ResultContent);

    useEffect(
        () => validateUserAccessToArea(permissions.nutrition.assessment),
        [validateUserAccessToArea]
    );

    const handleNav = useCallback(
        (local: Local) => {
            const currentLocal = formSteps.find((s) => s.local === local);
            if (!currentLocal) return;

            setFormSteps(
                [...formSteps].map((s) => ({
                    id: s.local === local ? currentLocal.id : s.id,
                    title: s.local === local ? currentLocal.title : s.title,
                    previous: s.local === local ? currentLocal.previous : s.previous,
                    next: s.local === local ? currentLocal.next : s.next,
                    local: s.local === local ? currentLocal.local : s.local,
                    show: s.local === local
                }))
            );
            setCurrentStep(currentLocal);
        },
        [formSteps]
    );

    const CurrentComponent: React.FC = () => {
        const commonProps = {
            welcomeFormData,
            setWelcomeFormData,
            formOptions,
            onButtonClick: (local: Local) => handleNav(local)
        };

        const defaultStepProps = {
            local: currentStep.local,
            title: currentStep.title,
            previous: currentStep.previous,
            next: currentStep.next
        };

        switch (currentStep.local) {
            case 'intro':
                return <Intro onButtonClick={(local) => handleNav(local)} />;
            case 'imc':
                return <IMC {...commonProps} />;
            case 'medical':
            case 'weight_objective':
            case 'exercise':
            case 'smoker':
            case 'sleep_difficulty':
            case 'food_intake':
            case 'alcohol_intake':
            case 'stress':
            case 'illnesses':
                return <DefaultFormStep {...defaultStepProps} {...commonProps} />;
            case 'resume':
                return <Resume {...commonProps} setResultContent={setResultContent} />;
            case 'message':
                return <Message {...commonProps} resultContent={resultContent} />;

            default:
                return <Intro onButtonClick={(local) => handleNav(local)} />;
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? META_TAGS.PRIVATE.NUTRITION.WELCOME_PACK_SURVEY.PT.TITLE
                        : META_TAGS.PRIVATE.NUTRITION.WELCOME_PACK_SURVEY.EN.TITLE}
                </title>
            </Helmet>
            <Header backButton hasImage={false} />
            <Main className={styles.main}>
                <CurrentComponent />
            </Main>
        </>
    );
};
