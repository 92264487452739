import html2canvas from 'html2canvas';
import {
    createContext,
    Dispatch,
    RefObject,
    SetStateAction,
    useContext,
    useEffect,
    useRef,
    useState
} from 'react';

type WebShareContextData = {
    canShare: boolean;
    shareTarget: RefObject<HTMLDivElement>;
    shareImage: () => Promise<File | undefined>;
    hasError: boolean;
    setHasError: Dispatch<SetStateAction<boolean>>;
    hasSuccess: boolean;
    setHasSuccess: Dispatch<SetStateAction<boolean>>;
};

const WebShareContext = createContext<WebShareContextData>({} as WebShareContextData);

export const WebShareProvider: React.FC = ({ children }) => {
    const [canShare, setCanShare] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [hasSuccess, setHasSuccess] = useState(false);
    const shareTarget = useRef(null);

    useEffect(() => setCanShare(!!navigator.share), []);

    const onShare = async (shareTarget: RefObject<HTMLDivElement>) => {
        if (!shareTarget.current) {
            throw new Error('missing `shareTarget` ref');
        }

        const canvas = await html2canvas(shareTarget.current);
        const dataUrl = canvas.toDataURL();
        const blob = await (await fetch(dataUrl)).blob();
        const filesArray: File[] = [
            new File([blob], `hi-${new Date().getTime()}.png`, {
                type: blob.type,
                lastModified: new Date().getTime()
            })
        ];
        const shareData = {
            files: filesArray
        };

        try {
            await navigator.share(shareData as { files: File[] });
            setHasSuccess(true);
            return shareData.files[0];
        } catch (error) {
            console.log('error:', error);
            // setHasError(true);
        }
    };

    const shareImage = onShare.bind(onShare, shareTarget);

    return (
        <WebShareContext.Provider
            value={{
                canShare,
                shareTarget,
                shareImage,
                hasError,
                setHasError,
                hasSuccess,
                setHasSuccess
            }}>
            {children}
        </WebShareContext.Provider>
    );
};

export const useWebShare: () => WebShareContextData = () => {
    const context = useContext(WebShareContext);
    if (!context) {
        throw Error('the hook useWebShare must be used inside a WebShareProvider');
    }

    return context;
};
