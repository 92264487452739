import { Header } from 'components/sections';
import * as IMAGES from 'constants/images';
import { MOVEMENT_LAYOUT } from 'content';
import { useLanguage } from 'hooks';
import { VideosProvider } from 'hooks/useVideos';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';

const NavBar = React.lazy(
    () =>
        import(
            /* webpackChunkName: "NavBar" */
            'components/elements/NavBar'
        )
);
// DEPRECATED: Don't update props values on page switch
// const Header = React.lazy(
//     () =>
//         import(
//             /* webpackChunkName: "Header" */
//             'components/sections/Header'
//         )
// );

const Layout: React.FC = () => {
    const { isPT } = useLanguage();
    const { pathname } = useLocation();
    const CONTENT = isPT ? MOVEMENT_LAYOUT.PT : MOVEMENT_LAYOUT.EN;

    return (
        <VideosProvider>
            <Header
                image={IMAGES.COVER_MOVEMENT}
                image_2x={IMAGES.COVER_MOVEMENT_2X}
                alt={CONTENT.IMAGE_ALT}
                hasImage={!pathname.includes('/movimento/videos/')}
                backButton={pathname.includes('/movimento/videos/')}
            />
            <main>
                <Outlet />
            </main>
            <Suspense fallback={null}>
                <NavBar activeArea="movement" />
            </Suspense>
        </VideosProvider>
    );
};

export default Layout;
