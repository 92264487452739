import { COLORS } from 'theme';

export type IllustrationProps = React.HtmlHTMLAttributes<HTMLOrSVGElement>;

export const SlideFiveWaving: React.FC<IllustrationProps> = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 336">
        <defs>
            <symbol id="i-avatar" data-name="i-avatar" viewBox="0 0 31.87 38.01">
                <path
                    d="M16.23 17.77a9.53 9.53 0 0 0 3.86-9.71C19.14 2.85 14.49-.71 9.71.12s-7.87 5.73-6.92 11a9.68 9.68 0 0 0 7.15 7.82C-3 23.32.4 38 .4 38l31.47-5.47s-2.08-15.07-15.64-14.76Z"
                    fill={COLORS.BLUISH_PURPLE_200}
                />
            </symbol>
            {/* <!-- <symbol id="i-hi" data-name="i-hi" viewBox="0 0 47.33 47.09">
      <path fill={COLORS.WHITE}
        d="M46.84 23.42h-.48a22.92 22.92 0 0 1-31.61 21.23A23.08 23.08 0 0 1 7.19 7.07a23.49 23.49 0 0 1 7.49-5 23.22 23.22 0 0 1 32.16 21.35 23.42 23.42 0 0 0-46.84 0A23.65 23.65 0 0 0 40.32 40.2a23.79 23.79 0 0 0 7-16.78Zm-4.19-8A21 21 0 0 0 31.53 3.9a20.66 20.66 0 0 0-3.93-1.25 21.81 21.81 0 0 0-4.11-.44 21.22 21.22 0 0 0-8.09 1.6 21 21 0 0 0-6.83 4.62A21.31 21.31 0 0 0 4 15.33c-.22.53-.41 1.07-.58 1.61.62-.86 1.26-1.71 1.94-2.52a20.44 20.44 0 0 1 37 1.12 19.89 19.89 0 0 1 1.21 3.86 20.54 20.54 0 0 1-1.14 12A20.78 20.78 0 0 1 38 38a20.53 20.53 0 0 1-6.69 4.4 20.33 20.33 0 0 1-22.18-4.53 20.45 20.45 0 0 1-4.37-6.64 20.24 20.24 0 0 1-1.15-3.87l-.1.34-.31 1.11a21.27 21.27 0 0 0 .91 2.69 20.88 20.88 0 0 0 19.38 12.91 20.55 20.55 0 0 0 14.65-6.25 21.19 21.19 0 0 0 6-14.73 21.52 21.52 0 0 0-1.5-8ZM5 17.71a7.2 7.2 0 0 0 .72-1.23 7.41 7.41 0 0 0-1.06.95 11.77 11.77 0 0 0-.85 1.12l-.84 1.14-.82 1.15c.33-.34.65-.69 1-1l1-1A13.31 13.31 0 0 0 5 17.71Zm7-1.79v16h2.94v-6.23h7v6.19h2.92v-16h-2.92v6.33h-7v-6.33Zm20.54 10.61a1 1 0 0 0 1 1 1 1 0 0 0 1-1l.43-10h-2.85Zm-.45 4A1.49 1.49 0 1 0 33.58 29a1.49 1.49 0 0 0-1.49 1.53Zm-5 1.39H30V19.68h-2.95Zm2.78-17.49L29.73 12l-1.34 2-2.33-.66 1.5 1.9-1.34 2 2.27-.84L30 18.35c0-.81-.07-1.61-.1-2.41l2.23-.83Z" />
    </symbol> --> */}
            <symbol id="i-like-rounded" data-name="i-like-rounded" viewBox="0 0 68.07 68.07">
                <path
                    d="M34 68.07a34 34 0 1 1 34-34 34.08 34.08 0 0 1-34 34ZM34 18a16 16 0 1 0 16 16 16.05 16.05 0 0 0-16-16Z"
                    fill={COLORS.PURPLE_600}
                    style={{ opacity: 0.4 }}
                />
                <path
                    d="m42.83 39-4.31-9.49 1.08-5.46s-4.17-1.1-5.09 6.14-1.1 5.32-1.1 5.32-9-1.74-9.88-1.11c-.65.46-2.66 9.32-3.71 14.13a5.34 5.34 0 0 0 2.66 6 4.53 4.53 0 0 0 .94.32c2.23.44 14 .89 14 .89l4.73.94 3.4-17.15Zm4.42-.29-3.63 18.36 4.68.93 5.39-18.35Z"
                    fill={COLORS.PURPLE_600}
                />
            </symbol>
            <symbol id="i-graph" data-name="i-graph" viewBox="0 0 53.36 54.67">
                <path
                    fill={COLORS.BLUISH_PURPLE_400}
                    d="M26.84 54.67a26.14 26.14 0 0 1-15.72-5.27 27.85 27.85 0 0 1-6.24-38A26.08 26.08 0 0 1 22.25.35C36.75-2 50.54 8.13 53 23a27.54 27.54 0 0 1-4.5 20.25 26.12 26.12 0 0 1-17.37 11 25.71 25.71 0 0 1-4.29.42ZM26.47 14a12.82 12.82 0 0 0-1.95.16 12.24 12.24 0 0 0-8.12 5.21 13.86 13.86 0 0 0 3.09 18.78A12.16 12.16 0 0 0 37 35.29a13.57 13.57 0 0 0 2.19-10A13.16 13.16 0 0 0 26.47 14Z"
                    style={{ opacity: 0.4 }}
                />
                <path
                    fill={COLORS.BLUISH_PURPLE_400}
                    d="M24.52 30.06 31.26 33 36 22.38l-2.6-1.14-3.59 8-6.74-2.94-4.75 10.61 2.61 1.17Zm14.09 5 .42 2.6-22.54 3.7-2.93-17.82 2.64-.43-4.83-4.29-3.21 5.61 2.6-.43 3.39 20.64 25.35-4.16.42 2.59 4.29-4.83Z"
                />
            </symbol>
            <symbol id="i-plane" data-name="i-plane" viewBox="0 0 79.8 79.79">
                <path
                    fill={COLORS.BLUE_200}
                    d="M68.67 41.36q-22-9.12-44-18.27c-.8-.33-1.64-.7-2.37.08s-.34 1.57.05 2.36l15.27 31.12c.87 1.76 1.35 1.91 3.17 1.07a14.25 14.25 0 0 0 1.93-.92c1.25-.84 2.05-.57 2.92.64 1.25 1.76 2.72 3.34 4.06 5a1.72 1.72 0 0 0 1.86.78c.74-.19 1-.81 1.17-1.5.78-3.21 1.64-6.4 2.35-9.63a2.24 2.24 0 0 1 1.41-1.76c4-1.84 8-3.75 12.05-5.6a5.17 5.17 0 0 0 1.73-1.18v-1.06a4 4 0 0 0-1.6-1.13ZM51.12 55.58c-.22.93-.44 1.86-.73 3-1-1.31-2-2.45-2.88-3.58 1-1 2.42-1.27 3.59-2 .16-.11.34-.32.68-.14l-.66 2.72Zm-10.07-1.26c-.78.38-1.11.3-1.51-.52-2.33-4.88-4.73-9.74-7.12-14.6l-4.12-8.4.16-.12 23.38 18.57c-3.75 1.75-7.29 3.38-10.79 5.07Zm14.69-6.87a1.33 1.33 0 0 1-1.66-.23c-4.66-3.75-9.34-7.45-14-11.17l-8.27-6.57.09-.15L65 43.1c-3.26 1.53-6.28 2.9-9.26 4.35Z"
                />
                <path
                    fill={COLORS.BLUE_200}
                    d="M39.88 79.79A39.89 39.89 0 1 1 77.9 52a40 40 0 0 1-38.02 27.79Zm0-61.77a21.87 21.87 0 1 0 6.65 1A21.89 21.89 0 0 0 39.92 18Z"
                    style={{ opacity: 0.5 }}
                />
            </symbol>
            <symbol id="i-euro" data-name="i-euro" viewBox="0 0 21.43 23.87">
                <path
                    fill={COLORS.BLUE_200}
                    d="M14.28 3.11a6.69 6.69 0 0 1 4.48 2.14A1.54 1.54 0 0 0 21 3.14 9.64 9.64 0 0 0 14.49 0c-4.4-.22-8.31 2.69-10.07 7l-2.5-.13a1.53 1.53 0 0 0-.17 3.06l1.81.07a12.24 12.24 0 0 0-.16 1.34 10.75 10.75 0 0 0 0 1.39l-1.81-.09a1.53 1.53 0 1 0-.16 3.05l2.45.13c1.3 4.48 4.89 7.78 9.28 8A9.62 9.62 0 0 0 20 21.47a1.54 1.54 0 1 0-2-2.33 6.57 6.57 0 0 1-4.64 1.66A7.22 7.22 0 0 1 7.24 16l8 .42a1.53 1.53 0 1 0 .16-3.05l-9-.48V11.5c0-.51.1-.92.17-1.34l9 .48a1.53 1.53 0 0 0 .16-3.06l-8.1-.43a7.37 7.37 0 0 1 6.62-4.06Z"
                />
            </symbol>
            <symbol id="i-calendar" data-name="i-calendar" viewBox="0 0 35.93 35.63">
                <path
                    fill={COLORS.PURPLE_300}
                    d="M7.23 21.11a1.07 1.07 0 0 1 .09-.82 1.09 1.09 0 0 1 .68-.52l9.39-2.71a1.13 1.13 0 0 1 1.06.26 1.1 1.1 0 0 1 .3 1.05 1.08 1.08 0 0 1-.75.78l-9.4 2.71a1.1 1.1 0 0 1-.83-.1 1 1 0 0 1-.54-.65Zm22-17.65 6.62 23a1.09 1.09 0 0 1-.1.83 1.06 1.06 0 0 1-.65.52L8 35.59a1.07 1.07 0 0 1-.82-.1 1.06 1.06 0 0 1-.52-.65L0 11.88a1.09 1.09 0 0 1 .1-.83 1.06 1.06 0 0 1 .65-.52L5 9.33 4.06 6.2a1.08 1.08 0 0 1 1.3-1.36 1.1 1.1 0 0 1 .79.76l.9 3.13 14.61-4.21-.9-3.13A1.08 1.08 0 0 1 21 .33a1.09 1.09 0 0 1 1.83.46l.9 3.13 4.18-1.21a1.12 1.12 0 0 1 .82.1 1.06 1.06 0 0 1 .54.65ZM29 10.32 3.93 17.54 8.44 33.2 33.5 26ZM10.67 29.16 25.29 25a1.09 1.09 0 0 0 .75-.79 1.08 1.08 0 0 0-1.35-1.3l-14.62 4.17a1.06 1.06 0 0 0-.75.78 1.08 1.08 0 0 0 .3 1 1.07 1.07 0 0 0 1.05.3Z"
                />
            </symbol>
            <symbol id="i-cloud" data-name="i-cloud" viewBox="0 0 90.83 41">
                <path
                    d="M84.73 28.39c8.32.57 8 12.61-.29 12.61H8.17c-10.89 0-10.89-16.34 0-16.34a8.49 8.49 0 0 1 4.3 1.43 14.79 14.79 0 0 1 22.65-12.33C40-4.59 66.09-4.59 71 13.76a12 12 0 0 1 13.73 14.63Z"
                    fill={COLORS.WHITE}
                />
            </symbol>
        </defs>
        <g style={{ isolation: 'isolate' }}>
            <g id="canvas">
                <path
                    id="canvas-2"
                    data-name="canvas"
                    style={{ fill: 'none' }}
                    d="M0 0h280v336H0z"
                />
            </g>
            <g id="fg">
                <use
                    width="31.87"
                    height="38.01"
                    transform="translate(96 71)"
                    xlinkHref="#i-avatar"
                />
                <ellipse
                    cx="140.7"
                    cy="321.49"
                    rx="104.12"
                    ry="13.41"
                    fill={COLORS.PURPLE_600}
                    style={{ opacity: 0.3 }}
                />
                <path
                    fill={COLORS.BLUISH_PURPLE_400}
                    d="M1618.58 106h-14.68a3.63 3.63 0 0 0-3.56 3.72v2.66a3.65 3.65 0 0 1-3.57 3.73h-28.61a3.65 3.65 0 0 1-3.56-3.73v-2.66a3.65 3.65 0 0 0-3.6-3.72h-18a11.56 11.56 0 0 0-11.56 11.55v186.24a11.55 11.55 0 0 0 11.56 11.5h75.55a11.56 11.56 0 0 0 11.56-11.55V117.49a11.55 11.55 0 0 0-11.53-11.49Z"
                    transform="translate(-1440)"
                />
                {/* <!-- <use width="47.33" height="47.09" transform="translate(118.53 124.6)" xlinkHref="#i-hi" /> --> */}
                <path
                    d="M1618.86 100.44h-75.7a16.5 16.5 0 0 0-16.51 16.49v189.79a16.49 16.49 0 0 0 16.48 16.5h75.7a16.49 16.49 0 0 0 16.5-16.49V116.94a16.49 16.49 0 0 0-16.47-16.5ZM1630.28 304a11.55 11.55 0 0 1-11.55 11.55h-75.55a11.56 11.56 0 0 1-11.55-11.56V118.74a11.54 11.54 0 0 1 11.56-11.54h18a3.65 3.65 0 0 1 3.57 3.73v2.66a3.65 3.65 0 0 0 3.56 3.73H1597a3.64 3.64 0 0 0 3.57-3.72v-2.66a3.65 3.65 0 0 1 3.56-3.73h14.68a11.56 11.56 0 0 1 11.54 11.55Z"
                    transform="translate(-1440)"
                />
                <path
                    d="M1556.83 315h43.44a2127.64 2127.64 0 0 1-31.12-66.3c-1.32-3.18 22.79-40.43 17.17-41.86s-11.29 19.67-17.32 17-24.75-54.27-28.09-51.82c-6.24 4.6 10.2 32 6.85 33.59-2.64 1.27-10-18-16.3-25.94v13.25c3.74 7 7.68 13.79 6.83 14.92-.6.81-3.34-1.66-6.83-5.09v30.14c5.3 6.24 11.33 12.32 14.57 25.9 2.27 9.57 5.4 32.05 10.8 56.21Z"
                    transform="translate(-1440)"
                    fill={COLORS.BLUISH_PURPLE_400}
                    style={{ mixBlendMode: 'multiply', opacity: 0.3 }}
                />
                <path
                    d="M1553 258.78c2.28 9.58 5.41 32.06 10.81 56.22h43.44a2127.64 2127.64 0 0 1-31.12-66.3c-1.32-3.18 22.79-40.43 17.17-41.86s-11.29 19.67-17.32 17-24.75-54.27-28.09-51.82c-6.24 4.6 10.2 32 6.85 33.59s-14.42-30-21.06-29.27 13.4 29.09 11.59 31.5-22.53-24.31-26-18.81c-1.89 3 19 23.36 15.15 25.12s-22.36-11.5-24.19-7.85 17 9.17 22.87 19.28 15.49 14.64 19.9 33.2Z"
                    transform="translate(-1440)"
                    fill={COLORS.RED_100}
                />
                <path
                    fill={COLORS.WHITE}
                    d="m79.69 141.8 1.22 2.3 2.22 1.26-2.22 1.26-1.22 2.3-1.22-2.3-2.22-1.26 2.22-1.26 1.22-2.3z"
                />
                <path
                    fill={COLORS.PURPLE_600}
                    d="m76.94 117.7 2.44 4.46 4.45 2.43-4.45 2.44-2.44 4.46-2.44-4.46-4.45-2.44 4.45-2.43 2.44-4.46z"
                />
                <path
                    fill={COLORS.BLUISH_PURPLE_400}
                    d="m212.71 274.44 2.76 5.05 5.06 2.76-5.06 2.77-2.76 5.06-2.77-5.06-5.06-2.77 5.06-2.76 2.77-5.05z"
                />
                <path
                    fill={COLORS.NEUTRAL_200}
                    d="m57.41 185.72 1.45 2.64 2.65 1.45-2.65 1.45-1.45 2.65-1.45-2.65-2.65-1.45 2.65-1.45 1.45-2.64z"
                />
                <path
                    d="M1476.53 194.52a6.07 6.07 0 0 0-11.67 2.7 6.08 6.08 0 0 0-11.8 2.11 8.2 8.2 0 0 0 2.61 5.51 24.54 24.54 0 0 0 5.08 3.59c2.52 1.48 4.86 2.87 7.38 4.34 1.73-2.34 3.17-4.51 4.91-6.86a24.15 24.15 0 0 0 3.26-5.3 8.24 8.24 0 0 0 .23-6.09Z"
                    transform="translate(-1440)"
                    fill={COLORS.PURPLE_600}
                />
                <path
                    d="M1478.76 151.93c-.81-.23-1.59-.42-2.35-.68a13.85 13.85 0 0 1-6.62-4.27 9.27 9.27 0 0 1-2.19-5.43c0-.2 0-.44.19-.5a.81.81 0 0 1 .56.12 30.5 30.5 0 0 0 4.19 2.31c.55.23 1.12.4 1.67.6V144l-1.68-1.19a23.23 23.23 0 0 1-5-4.7 11 11 0 0 1-2.41-5.68 6.49 6.49 0 0 1 .37-2.93.38.38 0 0 1 .7-.12 47.5 47.5 0 0 0 15.68 10.62 43.12 43.12 0 0 1 7.37 3.94 14.19 14.19 0 0 1 4.52 4.6 11.53 11.53 0 0 1 1.42 8.56 13.52 13.52 0 0 1-3.73 6.4c-.27.27-.54.21-.71-.14a7.59 7.59 0 0 0-4.68-3.94 58.55 58.55 0 0 0-5.85-1.4 12.34 12.34 0 0 1-3.49-1.15 6.2 6.2 0 0 1-3.37-5.16 2.09 2.09 0 0 1 0-.25c0-.39.22-.51.61-.4.89.26 1.78.54 2.69.72a15.06 15.06 0 0 0 1.91.16Z"
                    transform="translate(-1440)"
                    fill={COLORS.HI_BLUE}
                />
                <use
                    width="68.07"
                    height="68.07"
                    transform="translate(201.53 207.67)"
                    xlinkHref="#i-like-rounded"
                />
                <path
                    fill={COLORS.WHITE}
                    d="m227.32 295.98 1.21 2.3 2.23 1.26-2.23 1.26-1.21 2.3-1.22-2.3-2.22-1.26 2.22-1.26 1.22-2.3z"
                />
                <use
                    width="53.36"
                    height="54.67"
                    transform="matrix(1.46 .22 -.22 1.46 23.41 235.36)"
                    xlinkHref="#i-graph"
                />
                <path
                    fill={COLORS.BLUE_200}
                    d="M1647.05 171.24c-2.5.56-4.14 2.81-3.64 5l4.42 19c.51 2.18 3 3.5 5.48 2.94l32.66-7.38a4.38 4.38 0 0 0 3.62-5l-4.42-19c-.51-2.18-3-3.5-5.47-2.94Zm.53 2.27 32.65-7.38a1.85 1.85 0 0 1 2.33 1.26l.39 1.67-36.54 8.25-.39-1.67a1.84 1.84 0 0 1 1.56-2.13Zm.23 9.78 36.53-8.25 2.64 11.32a1.83 1.83 0 0 1-1.55 2.13l-32.65 7.37a1.86 1.86 0 0 1-2.34-1.25Zm25-2.58c-2.58.58-4.27 2.91-3.75 5.17s3.08 3.62 5.66 3a5.36 5.36 0 0 0 2.29-1.16 5.25 5.25 0 0 0 2.56.07c2.58-.59 4.27-2.92 3.75-5.18s-3.08-3.62-5.66-3a5.2 5.2 0 0 0-2.28 1.16 5.31 5.31 0 0 0-2.57-.06Zm-20 5.53a1.44 1.44 0 0 0-.87.53 1.07 1.07 0 0 0 .38 1.63 1.43 1.43 0 0 0 1 .11l9.11-2.06a1.41 1.41 0 0 0 .87-.53 1.07 1.07 0 0 0-.38-1.63 1.43 1.43 0 0 0-1-.11Zm20.56-3.26a2.51 2.51 0 0 1 .61 0 .25.25 0 0 1 0 .08 3.65 3.65 0 0 0-.1.65v.22a3.44 3.44 0 0 0 .09.9 3.63 3.63 0 0 0 .32.85c0 .07.08.12.11.18a4.14 4.14 0 0 0 .38.55v.07a2.23 2.23 0 0 1-.58.21 2 2 0 0 1-2.52-1.35 2 2 0 0 1 1.67-2.31Zm4.85-1.09a2 2 0 0 1 2.52 1.35 2 2 0 0 1-1.67 2.31 2.32 2.32 0 0 1-1.55-.17h-.07a1.82 1.82 0 0 1-.78-.8 2.06 2.06 0 0 1-.12-.35 1.75 1.75 0 0 1 .3-1.42v-.06a2.29 2.29 0 0 1 1.33-.82Zm-24.74 7.8a1.35 1.35 0 0 0-1 .86 1.06 1.06 0 0 0 .33 1.13 1.45 1.45 0 0 0 1.29.26l3.89-.88a1.39 1.39 0 0 0 1-.83 1 1 0 0 0-.27-1.14 1.37 1.37 0 0 0-1.26-.31l-3.89.88a.28.28 0 0 0-.13 0Z"
                    transform="translate(-1440)"
                />
                <use
                    width="79.8"
                    height="79.79"
                    transform="matrix(.82 0 0 .82 206.26 86.26)"
                    xlinkHref="#i-plane"
                />
                <use
                    width="21.43"
                    height="23.87"
                    transform="matrix(1.44 .29 -.3 1.47 160.67 75.28)"
                    xlinkHref="#i-euro"
                />
                <use
                    width="35.93"
                    height="35.63"
                    transform="matrix(1.21 0 0 1.22 33.33 73.33)"
                    xlinkHref="#i-calendar"
                />
                <path
                    fill={COLORS.PURPLE_300}
                    d="m1495.89 218.39-.42-1.64c-.37.13-.72.28-1.09.37a4.8 4.8 0 0 1-4.62-1.09 4.89 4.89 0 0 1-1.62-3.2.59.59 0 0 1 .3-.65 5 5 0 0 1 6.35.86 3 3 0 0 0 .21.23v-.32a7.36 7.36 0 0 1 .55-3 4.41 4.41 0 0 1 .48-.78.41.41 0 0 1 .63-.09c.19.17.18.39 0 .62a4.07 4.07 0 0 0-.74 2 10.91 10.91 0 0 0 .11 3.56c.13.76.31 1.52.48 2.28 0 .16.11.32.17.49.33-.35.63-.69 1-1a8 8 0 0 1 3.67-2.11 6.52 6.52 0 0 1 5 .69 9.81 9.81 0 0 1 3.85 4 13 13 0 0 1 1 2.28 15.2 15.2 0 0 1-.28 10.73 9.56 9.56 0 0 1-3.56 4.52 6.67 6.67 0 0 1-4 1.15.55.55 0 0 0-.31.08 7.09 7.09 0 0 1-5.86 1.48 11.64 11.64 0 0 1-5.44-2.52 15.28 15.28 0 0 1-4.14-5 13 13 0 0 1-1.56-7.37 7.7 7.7 0 0 1 2-4.75 6.61 6.61 0 0 1 3.42-1.89 9 9 0 0 1 4.12 0Zm-6.11 12.7a.4.4 0 0 0 .48.3.41.41 0 0 0 .24-.5.41.41 0 0 0-.48-.31.38.38 0 0 0-.24.51Z"
                    transform="translate(-1440)"
                />
                <use
                    width="90.83"
                    height="41"
                    transform="translate(43.43 11.63)"
                    xlinkHref="#i-cloud"
                />
                <path
                    d="M1648.12 51.27c10.64.73 10.27 16.13-.36 16.13h-97.53c-13.93 0-13.93-20.9 0-20.9a11 11 0 0 1 5.5 1.84 18.91 18.91 0 0 1 29-15.77c6.24-23.46 39.6-23.46 45.83 0a15.39 15.39 0 0 1 17.6 18.7Z"
                    transform="translate(-1440)"
                    fill={COLORS.BLUE_200}
                    style={{ fillRule: 'evenodd' }}
                />
            </g>
        </g>
    </svg>
);

export default SlideFiveWaving;
