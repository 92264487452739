import { useTheme } from 'hooks';
import { useMemo } from 'react';
import { COLORS } from 'theme/colors';

import { IconAreaV2Props } from '../../../types';

export const IconMovementV2: React.FC<IconAreaV2Props> = ({
    width = 30,
    height = 30,
    isSelected,
    ...props
}) => {
    const { darkMode } = useTheme();

    const defaultColor = useMemo(
        () => ({
            fill: darkMode ? COLORS.BLUISH_PURPLE_800 : COLORS.WHITE,
            stroke: darkMode ? COLORS.PURPLE_300 : COLORS.MAGENTA_200
        }),
        [darkMode]
    );

    const selectedColor = useMemo(
        () => ({
            fill: darkMode ? COLORS.BLUISH_PURPLE_800 : COLORS.WHITE,
            stroke: darkMode ? COLORS.WHITE : COLORS.PURPLE_500
        }),
        [darkMode]
    );

    const filter = useMemo(
        () => (darkMode ? 'url(#dark-shadow)' : 'url(#light-shadow)'),
        [darkMode]
    );

    return (
        <svg
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            {...props}>
            <g filter={filter}>
                <path
                    d="M24.8431 18.4234C24.1042 15.2533 22.3631 12.9889 15.6875 10.7989C11.9131 9.58512 8.45115 7.63122 5.52737 5.06458C5.50124 5.03832 5.46828 5.01904 5.43181 5.00867C5.39533 4.9983 5.35661 4.99721 5.31955 5.00551C5.28112 5.0141 5.24565 5.03188 5.21655 5.05716C5.18744 5.08244 5.16566 5.11437 5.15329 5.1499C5.08633 5.33805 3.61311 9.65462 11.3879 13.7721C10.7644 13.7196 9.56601 13.4155 7.38852 12.2406C7.35402 12.2198 7.31397 12.2087 7.27306 12.2087C7.23215 12.2087 7.1921 12.2198 7.1576 12.2406C7.12237 12.2665 7.09503 12.3009 7.0784 12.3401C7.06176 12.3793 7.05643 12.422 7.06293 12.4638C7.18603 13.4965 7.59996 14.4789 8.26136 15.308C9.18501 16.5156 11.0508 17.9858 14.653 18.4081C14.0642 18.535 13.0666 18.5656 11.4641 18.1609C11.4298 18.152 11.3936 18.1514 11.359 18.159C11.3243 18.1666 11.2922 18.1823 11.2655 18.2046C11.2378 18.2283 11.2158 18.2574 11.2011 18.2898C11.1863 18.3223 11.1792 18.3574 11.1801 18.3928C11.2285 18.9631 11.4082 19.5165 11.7066 20.014C12.1684 20.7753 13.092 21.7423 15.0178 22.0268C15.6459 22.1208 16.237 22.1886 16.8097 22.2455C19.4421 22.5518 21.3425 22.7728 22.6033 24.8884C22.6211 24.9189 22.6462 24.9449 22.6768 24.9643C22.7073 24.9838 22.7423 24.996 22.7788 25H22.8019C22.8344 24.9994 22.8665 24.9922 22.896 24.979C22.9254 24.9658 22.9516 24.9469 22.9727 24.9234C23.079 24.8097 25.6513 21.8233 24.8431 18.4234Z"
                    fill={isSelected ? selectedColor.fill : defaultColor.fill}
                />
                <path
                    d="M24.8431 18.4234C24.1042 15.2533 22.3631 12.9889 15.6875 10.7989C11.9131 9.58512 8.45115 7.63122 5.52737 5.06458C5.50124 5.03832 5.46828 5.01904 5.43181 5.00867C5.39533 4.9983 5.35661 4.99721 5.31955 5.00551C5.28112 5.0141 5.24565 5.03188 5.21655 5.05716C5.18744 5.08244 5.16566 5.11437 5.15329 5.1499C5.08633 5.33805 3.61311 9.65462 11.3879 13.7721C10.7644 13.7196 9.56601 13.4155 7.38852 12.2406C7.35402 12.2198 7.31397 12.2087 7.27306 12.2087C7.23215 12.2087 7.1921 12.2198 7.1576 12.2406C7.12237 12.2665 7.09503 12.3009 7.0784 12.3401C7.06176 12.3793 7.05643 12.422 7.06293 12.4638C7.18603 13.4965 7.59996 14.4789 8.26136 15.308C9.18501 16.5156 11.0508 17.9858 14.653 18.4081C14.0642 18.535 13.0666 18.5656 11.4641 18.1609C11.4298 18.152 11.3936 18.1514 11.359 18.159C11.3243 18.1666 11.2922 18.1823 11.2655 18.2046C11.2378 18.2283 11.2158 18.2574 11.2011 18.2898C11.1863 18.3223 11.1792 18.3574 11.1801 18.3928C11.2285 18.9631 11.4082 19.5165 11.7066 20.014C12.1684 20.7753 13.092 21.7423 15.0178 22.0268C15.6459 22.1208 16.237 22.1886 16.8097 22.2455C19.4421 22.5518 21.3425 22.7728 22.6033 24.8884C22.6211 24.9189 22.6462 24.9449 22.6768 24.9643C22.7073 24.9838 22.7423 24.996 22.7788 25H22.8019C22.8344 24.9994 22.8665 24.9922 22.896 24.979C22.9254 24.9658 22.9516 24.9469 22.9727 24.9234C23.079 24.8097 25.6513 21.8233 24.8431 18.4234Z"
                    stroke={isSelected ? selectedColor.stroke : defaultColor.stroke}
                    strokeWidth="2"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="light-shadow"
                    x="1.99988"
                    y="4"
                    width="26.0004"
                    height="26"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_490_724"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_490_724"
                        result="shape"
                    />
                </filter>
                <filter
                    id="dark-shadow"
                    x="1.99988"
                    y="4"
                    width="26.0004"
                    height="26"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_490_724"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_490_724"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
