import { CardAlternativeProps, CardFullProps, CardTrackerV2Props } from 'components/elements';
import { useAuth, useLanguage } from 'hooks';
import {
    ActivityData,
    AreaType,
    ArticleData,
    BannersData,
    DailyTipData,
    FAQData,
    PartnerData,
    PromoData,
    PromoDataV2,
    StaffData,
    SuggestedActivity,
    UserActivityData,
    UserDocDataV2,
    UserManuallyEnteredActivity,
    VideoData
} from 'models/types';
import { MembershipPlan } from 'models/user';
import { ResultContent, WelcomeFormData } from 'pages/private/Nutrition/Assessment/types';
import React, { createContext, useCallback, useContext } from 'react';
import { api } from 'services';
import { areaToPT } from 'utils';

type APIResponseData = {
    status: string;
    message: string;
    user_id: string;
    months: {
        month: number;
        year: number;
        dates: {
            days: number[];
            type: number;
            label: {
                [lang: string]: {
                    title: string;
                    description: string;
                };
            };
        }[];
    }[];
};

type WexerAPIData = {
    tag: string;
    name: string;
    description: string;
    language: string;
    languageCode: string;
    category: string;
    subCategory: string;
    intensity: number;
    skill: number;
    imageLink: string;
    trailerLink: string;
    instructor: string;
    level: string;
    labels: string[];
    providerID: string;
    providerName: string;
    equipments: string[];
    durationSecond: number;
    publishedDate: string;
};

type CheckUpRequest = {
    nclient: string;
    plan: MembershipPlan;
    name: string;
    birth_date: string;
    email: string;
    contact: string | number;
    local: string;
};

type ManualActivityData = {
    date: Date;
    type: string | undefined;
    duration: string | undefined;
};

type ApiContextData = {
    getManualActivities: () => Promise<UserManuallyEnteredActivity[]>;
    postManualActivity: (data: ManualActivityData) => Promise<void>;
    getTipOfTheDay: (area: Omit<AreaType, 'bonus' | 'articles'>) => Promise<DailyTipData>;
    getVideos: () => Promise<VideoData[]>;
    getArticles: () => Promise<ArticleData[]>;
    getArticle: (slug: string) => Promise<ArticleData | undefined>;
    getUserDocuments: () => Promise<UserDocDataV2>;
    postUserAvatar: (avatar: string) => Promise<string | null>;
    postNewUserPassword: (password: string, logged: boolean, token?: string) => Promise<void>;
    getPaymentDates: () => Promise<APIResponseData>;
    postUserPasswordRecover: (email: string) => Promise<void>;
    getSuggestedActivities: () => Promise<SuggestedActivity[]>;
    postUserShortName: (short_name: string) => Promise<void>;
    postCheckupRequest: (data: CheckUpRequest) => Promise<void>;
    getActivities: (area: AreaType) => Promise<CardAlternativeProps[]>;
    getPromosBanners: (area: AreaType) => Promise<CardFullProps[]>;
    getBanners: () => Promise<CardFullProps[]>;
    getStaff: (type: 'coaches' | 'nutritionists') => Promise<StaffData[]>;
    getUserGymWorkout: (max?: number | undefined) => Promise<CardTrackerV2Props[]>;
    postCancellationRequest: (option: string, reason: string | undefined) => Promise<void>;
    getFAQs: (area: AreaType) => Promise<FAQData[]>;
    postFAQ: (area: 'movement' | 'nutrition', question: string) => Promise<void>;
    getVideo: (tag: string) => Promise<string>;
    postWelcomePackSurvey: (welcomeFormData: WelcomeFormData) => Promise<ResultContent>;
    getPromos: () => Promise<PromoDataV2[]>;
    getPartner: (id: string) => Promise<PartnerData>;
};

const ApiContext = createContext<ApiContextData>({} as ApiContextData);

export const ApiProvider: React.FC = ({ children }) => {
    const { user } = useAuth();
    const { isPT } = useLanguage();

    // MANUAL ACTIVITY
    const getManualActivities = useCallback(async () => {
        if (!user) throw new Error('No user Found');
        const { data } = await api.get(`/users/${user.id}/activities/manual`);
        return data.activities as UserManuallyEnteredActivity[];
    }, [user]);

    const postManualActivity = useCallback(
        async (data: ManualActivityData) => {
            if (!user) throw new Error('No user Found');
            await api.post(`/users/${user.id}/activities/manual/add`, data);
        },
        [user]
    );

    // ON-DEMAND
    const getVideos = useCallback(async () => {
        const { data } = await api.get('videos/get');
        const videos: WexerAPIData[] = data.videos;

        return videos.map((v) => ({
            details: {
                tag: v.tag,
                name: v.name,
                description: v.description,
                language: v.languageCode,
                instructor: v.instructor,
                category: v.category,
                subCategory: v.subCategory,
                labels: v.labels,
                equipments: v.equipments
            },
            specs: {
                intensity: v.intensity,
                skill: v.skill,
                level: v.level
            },
            video: {
                durationInSeconds: v.durationSecond,
                publishedDate: v.publishedDate,
                imageLink: v.imageLink,
                trailerLink: v.trailerLink
            },
            provider: {
                id: v.providerID,
                name: v.providerName
            }
        }));
    }, []);

    const getVideo = useCallback(
        async (tag: string) => {
            if (!user) throw new Error('No user Found');
            const { data } = await api.get(`/videos/${tag}/${user.id}`);
            return data.streamingUrl;
        },
        [user]
    );

    // ARTICLES
    const getArticles = useCallback(async () => {
        const { data } = await api.get('articles/get');
        return data.articles as ArticleData[];
    }, []);

    const getArticle = useCallback(
        async (slug: string) => {
            const articles = await getArticles();
            return articles.find((a) => a.slug === slug);
        },
        [getArticles]
    );

    // USER
    const getUserDocuments = useCallback(async () => {
        if (!user) throw new Error('No user Found');
        const { data } = await api.get(`users/${user.id}/invoices`);
        return data as UserDocDataV2;
    }, [user]);

    const postUserAvatar = useCallback(
        async (avatar: string) => {
            if (!user) throw new Error('No user Found');
            const { data } = await api.post(`users/${user.id}/avatar`, { avatar });
            return data.avatar;
        },
        [user]
    );

    const postUserShortName = useCallback(
        async (short_name: string) => {
            if (!user) throw new Error('No user Found');
            await api.post(`/users/${user.id}/shortname`, {
                short_name
            });
        },
        [user]
    );

    const getUserGymWorkout = useCallback(
        async (max?: number) => {
            if (!user) throw new Error('No user Found');
            const { data } = await api.get(`/users/${user.id}/activities`);
            const activities: UserActivityData[] = data.activities;
            const gymWorkouts = activities.filter((a) => a.task.type === 'gym workout');
            return gymWorkouts.slice(0, max ?? 3).map((a) => ({
                date: a.date,
                location: a.task.name,
                points: a.points,
                area: 'movement',
                id: a.id
            })) as CardTrackerV2Props[];
        },
        [user]
    );

    const postCancellationRequest = useCallback(
        async (option: string, reason: string | undefined) => {
            if (!user) throw new Error('No user Found');
            await api.post(`/users/${user.id}/cancellation`, { option, reason });
        },
        [user]
    );

    const postUserPasswordRecover = useCallback(async (email: string) => {
        await api.post('users/password/recover', { email });
    }, []);

    const postNewUserPassword = useCallback(
        async (password: string, logged: boolean, token?: string) => {
            const param = logged ? user?.id : token;
            await api.post(`/users/${param}/password/reset`, {
                logged,
                password
            });
        },
        [user]
    );

    const getPaymentDates = useCallback(async () => {
        if (!user) throw new Error('No user Found');
        const { data } = await api.get(`/users/${user.id}/calendar`);
        return data;
    }, [user]);

    // PROMOS AREA
    const getPromos = useCallback(async () => {
        if (!user) throw new Error('No user Found');
        const { data } = await api.post('promos/list', { user: user.id });
        return data.promos as PromoDataV2[];
    }, [user]);

    const getPartner = useCallback(
        async (id: string) => {
            if (!user) throw new Error('No user Found');
            const { data } = await api.post(`/partners/${id}/get`, {
                user: user.id
            });
            return data.partner as PartnerData;
        },
        [user]
    );

    // BANNERS
    const getBanners = useCallback(async () => {
        const { data } = await api.get('banners/get');
        const banners: CardFullProps[] = data.banners.map((b: BannersData) => ({
            id: b.id,
            label: isPT ? areaToPT(b.area) : b.area,
            heading: b.title,
            description: b.description,
            backgroundImage: b.image_2x || b.image,
            uri: b.slug,
            linkDestination: 'internal',
            color: b.color,
            area: b.area
        }));

        return banners;
    }, [isPT]);

    const getPromosBanners = useCallback(
        async (area: AreaType) => {
            const { data } = await api.get(`/promos/get`);
            const promos: PromoData[] = data.promos;

            const filtered: CardFullProps[] = promos
                .filter((p) => p.area === area)
                .map((p) => ({
                    id: p.id,
                    label: areaToPT(p.area),
                    heading: p.title,
                    description: p.category,
                    backgroundImage: p.image_2x,
                    hasButton: false,
                    buttonLabel: isPT ? 'comprar' : 'buy',
                    area: p.area,
                    uri: p.slug,
                    linkDestination: 'external',
                    textStyle: 'dark' as 'light' | 'dark'
                }));

            return filtered;
        },
        [isPT]
    );

    // FAQs
    const getFAQs = useCallback(async (area: AreaType) => {
        const { data } = await api.get('faqs/get');
        const faqs: FAQData[] = data.faqs;
        return faqs.filter((f) => f.area === area);
    }, []);

    const postFAQ = useCallback(
        async (area: 'movement' | 'nutrition', question: string) => {
            if (!user) throw new Error('No user Found');
            await api.post(`users/${user.id}/faqs/send`, {
                date: new Date(),
                area,
                question
            });
        },
        [user]
    );

    // GAMIFICATION
    const getSuggestedActivities = useCallback(async () => {
        if (!user) throw new Error('No user Found');
        const { data } = await api.get(`users/${user.id}/suggested-activities/get`);
        return data.activities as SuggestedActivity[];
    }, [user]);

    // OTHER
    const getTipOfTheDay = useCallback(async (area: Omit<AreaType, 'bonus' | 'articles'>) => {
        const { data } = await api.get('tips/get');
        const tips: DailyTipData[] = data.tips;
        return tips.filter((t) => t.area === area)[0];
    }, []);

    const getActivities = useCallback(
        async (area: AreaType) => {
            const { data } = await api.get('activities/get');
            const activities: ActivityData[] = data.activities;
            return activities
                .filter((a) => a.area === area)
                .map((a) => ({
                    image: a.image,
                    image_2x: a.image_2x,
                    alt: isPT ? a.title.pt : a.title.en || a.title.pt,
                    title: isPT ? a.title.pt : a.title.en || a.title.pt,
                    description: isPT ? a.description.pt : a.description.en || a.description.pt,
                    points: a.points,
                    buttonLabel: isPT ? a.label.pt || 'Agendar' : a.label.en || 'Schedule',
                    slug: a.slug,
                    area: a.area
                }));
        },
        [isPT]
    );

    const postCheckupRequest = useCallback(
        async (data: CheckUpRequest) => {
            if (!user) throw new Error('No user Found');
            await api.post(`users/${user.id}/checkup/send`, data);
        },
        [user]
    );

    const getStaff = useCallback(async (type: 'coaches' | 'nutritionists') => {
        const { data } = await api.get(`${type}/get`);
        return data[type] as StaffData[];
    }, []);

    const postWelcomePackSurvey = useCallback(
        async (welcomeFormData: WelcomeFormData) => {
            if (!user) throw new Error('No user Found');
            const { data } = await api.post(`users/${user.id}/nutrition/send`, {
                ...welcomeFormData
            });
            return data.result as ResultContent;
        },
        [user]
    );

    return (
        <ApiContext.Provider
            value={{
                getManualActivities,
                postManualActivity,
                getTipOfTheDay,
                getVideos,
                getVideo,
                getArticles,
                getArticle,
                getUserDocuments,
                getSuggestedActivities,
                postUserAvatar,
                postUserShortName,
                postCancellationRequest,
                postUserPasswordRecover,
                postNewUserPassword,
                getPaymentDates,
                postCheckupRequest,
                getActivities,
                getPromosBanners,
                getBanners,
                getStaff,
                getUserGymWorkout,
                getFAQs,
                postFAQ,
                postWelcomePackSurvey,
                getPromos,
                getPartner
            }}>
            {children}
        </ApiContext.Provider>
    );
};

export function useApi(): ApiContextData {
    const context = useContext(ApiContext);
    if (!context) throw new Error('useApi must be used withing an ApiProvider');
    return context;
}
