import { format } from 'date-fns';
import ENLocale from 'date-fns/locale/en-GB';
import PTLocale from 'date-fns/locale/pt';
import { useAuth, useLanguage, useTheme } from 'hooks';
import { PaymentStatus } from 'hooks/useAuth';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IconCheck, IconX } from 'theme/icons';
import { cj } from 'utils';

import styles from './AccountStatus.module.scss';

type AccountStatusProps = {
    content: {
        TITLE: string;
        CARD: {
            STATUS: {
                ACTIVE: string;
                INACTIVE: string;
            };
        };
        MESSAGE: {
            ACTIVE: string;
            INACTIVE: string;
        };
        PAYMENT: {
            ENTITY: string;
            REFERENCE: string;
            AMOUNT: string;
            NOTE: string;
        };
    };
};

export const AccountStatus: React.FC<AccountStatusProps> = ({ content }) => {
    const { isPT } = useLanguage();
    const { activeTheme } = useTheme();
    const { getPaymentStatus } = useAuth();

    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>();

    const activeContent = useMemo(() => {
        const OK = paymentStatus === 'ok';
        return {
            title: OK ? content.CARD.STATUS.ACTIVE : content.CARD.STATUS.INACTIVE,
            message: OK ? content.MESSAGE.ACTIVE : content.MESSAGE.INACTIVE,
            icon: OK ? <IconCheck /> : <IconX />
        };
    }, [paymentStatus, content]);

    const getStatus = useCallback(async () => {
        const status = await getPaymentStatus();
        setPaymentStatus(status);
    }, [getPaymentStatus]);

    useEffect(() => {
        getStatus();
    }, [getStatus]);

    if (!paymentStatus) return null;

    return (
        <section className={cj(styles['account-status'], styles[activeTheme], 'container')}>
            <h2>{content.TITLE}</h2>
            <div className={cj(styles.card, styles[paymentStatus], styles[activeTheme])}>
                <header>
                    <div className={styles.wrapper}>
                        <strong className={styles.title}>{activeContent.title}</strong>
                        <span className={styles.date}>
                            {format(new Date(), 'd MMM yyyy', {
                                locale: isPT ? PTLocale : ENLocale
                            })}
                        </span>
                    </div>
                    <div className={cj(styles.icon, styles[activeTheme])}>{activeContent.icon}</div>
                </header>
                <footer>
                    <span className={styles.message}>{activeContent.message}</span>
                </footer>
            </div>
        </section>
    );
};
