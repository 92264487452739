import { Main } from 'components/elements';
import { Header } from 'components/sections';
import { permissions } from 'config';
import { META_TAGS, PROMOS_LIST } from 'content';
import { useApi, useAuth, useLanguage } from 'hooks';
import { PromoDataV2 } from 'models/types';
import React, { useCallback, useState } from 'react';
import { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { Intro, List } from './modules';
import styles from './PromosList.module.scss';

const NavBar = React.lazy(
    () =>
        import(
            /* webpackChunkName: "NavBar" */
            'components/elements/NavBar'
        )
);

export const PromosList: React.FC = () => {
    const { isPT } = useLanguage();
    const { validateUserAccessToArea } = useAuth();
    const { getPromos } = useApi();

    const CONTENT = isPT ? PROMOS_LIST.PT : PROMOS_LIST.EN;

    const [promos, setPromos] = useState<PromoDataV2[] | undefined>(undefined);

    const getPromosData = useCallback(async () => {
        try {
            const promos = await getPromos();
            setPromos(promos);
        } catch (error) {
            // TO-DO
            console.error(error);
        }
    }, [getPromos]);

    useEffect(
        () => validateUserAccessToArea(permissions.general.promos),
        [validateUserAccessToArea]
    );

    useEffect(() => {
        getPromosData();
    }, [getPromosData]);

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? META_TAGS.PRIVATE.PROMOS.LIST.PT.TITLE
                        : META_TAGS.PRIVATE.PROMOS.LIST.EN.TITLE}
                </title>
            </Helmet>
            <Header backButton hasImage={false} title={CONTENT.HEADER.TITLE} />
            <Main className={styles.main}>
                <Intro content={CONTENT.INTRO} />
                <List promos={promos} />
            </Main>
            <Suspense fallback={null}>
                <NavBar activeArea="promos" />
            </Suspense>
        </>
    );
};
