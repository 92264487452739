export const HOME = {
    PT: {
        HEADER: {
            IMAGE_ALT: 'jovem a atar os atacadores dos tennis'
        },
        NUTRITIONAL_ASSESSMENT_BANNER: {
            TITLE: 'A (r)evolução na sua alimentação começa aqui',
            DESCRIPTION:
                'Responda a um curto questionário sobre o seu estilo de vida e ganhe uma oferta Zumub',
            BUTTON_LABEL: 'Começar'
        },
        POINTS: {
            TITLE: 'Pontos',
            DESCRIPTION: 'Adquira pontos, ganhe descontos',
            BUTTON_LABEL: 'Saiba mais'
        },
        SERVICES: {
            TITLE: 'A pensar em si'
        },
        ARTICLES: {
            TITLE: 'Publicações recentes'
        }
    },
    EN: {
        HEADER: {
            IMAGE_ALT: 'young man tying his shoelaces'
        },
        NUTRITIONAL_ASSESSMENT_BANNER: {
            TITLE: 'The (r)evolution of your diet starts here',
            DESCRIPTION: 'Answer a short questionnaire about your lifestyle and win a Zumub offer',
            BUTTON_LABEL: 'Start'
        },
        POINTS: {
            TITLE: 'Points',
            DESCRIPTION: 'Get points, get discounts',
            BUTTON_LABEL: 'Read more'
        },
        SERVICES: {
            TITLE: 'Thinking of you'
        },
        ARTICLES: {
            TITLE: 'Recent publications'
        }
    }
};
