import { useLanguage, useTheme } from 'hooks';
import { PartnerData } from 'models/types';
import { A11y, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

import styles from './About.module.scss';

type AboutProps = {
    partner: PartnerData;
};

export const About: React.FC<AboutProps> = ({ partner }) => {
    const { isPT } = useLanguage();
    const { activeTheme } = useTheme();

    return (
        <section className={styles.about}>
            <div className={`${styles.description} container`}>
                {partner.about.description.map((d) => {
                    if (d.type === 'title') return <h2 key={d.id}>{isPT ? d.pt : d.en}</h2>;
                    if (d.type === 'paragraph') return <p key={d.id}>{isPT ? d.pt : d.en}</p>;
                })}
            </div>
            {partner.about.image_gallery && (
                <div>
                    <Swiper
                        modules={[Pagination, A11y]}
                        autoplay={{ delay: 5000, disableOnInteraction: true }}
                        pagination={{
                            clickable: true,
                            bulletClass: `${styles.bullet} ${styles[activeTheme]}`,
                            bulletActiveClass: styles.active
                        }}
                        spaceBetween={0}
                        slidesPerView={1}
                        className={styles.slider}>
                        {partner.about.image_gallery.map((img) => (
                            <SwiperSlide key={img.id} className={styles.slide}>
                                <img src={img.src} alt={partner.name} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
        </section>
    );
};
