import { useApi } from 'hooks';
import { VideoData, VideosByCategoryData } from 'models/video';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

type VideosContextData = {
    videosByCategory: VideosByCategoryData | undefined;
    allVideos: VideoData[] | undefined;
};

type Category =
    | 'Bootcamp'
    | 'Strength'
    | 'Cardio'
    | 'Stretch & Recover'
    | 'Box & Fight'
    | 'Pilates'
    | 'Cycling'
    | 'Dance'
    | 'Meditation'
    | 'Yoga'
    | 'Other'
    | 'Highlights';

const VideosContext = createContext<VideosContextData>({} as VideosContextData);

const VideosProvider: React.FC = ({ children }: { children?: React.ReactNode }) => {
    const location = useLocation();
    const { getVideos } = useApi();

    const [allVideos, setAllVideos] = useState<VideoData[] | undefined>(undefined);
    const [videosByCategory, setVideosByCategory] = useState<VideosByCategoryData | undefined>(
        undefined
    );

    const VIDEO_LIMIT = 10;

    const getRandom = useCallback((arr: VideoData[], n: number) => {
        const result = new Array(n);
        let len = arr.length;
        const taken = new Array(len);
        if (n > len) throw new RangeError('getRandom: more elements taken than available');
        while (n--) {
            const x = Math.floor(Math.random() * len);
            result[n] = arr[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }
        return result;
    }, []);

    const filterByCategory: (category: Category) => VideoData[] | undefined = useCallback(
        (category) => {
            if (!allVideos) return;

            if (category === 'Other')
                return allVideos
                    .filter(
                        ({ details }) =>
                            ![
                                'Bootcamp',
                                'Strength',
                                'Cardio',
                                'Stretch & Recover',
                                'Box & Fight',
                                'Yoga',
                                'Pilates',
                                'Cycling',
                                'Dance',
                                'Meditation'
                            ].includes(details.category)
                    )
                    .splice(0, VIDEO_LIMIT);

            if (category === 'Highlights') return getRandom(allVideos, VIDEO_LIMIT);

            return allVideos
                .filter(({ details }: VideoData) => details.category === category)
                .splice(0, VIDEO_LIMIT);
        },
        [allVideos, getRandom]
    );

    const renderVideoList = useCallback(() => {
        if (!allVideos) return;

        setVideosByCategory({
            bootcamp: filterByCategory('Bootcamp'),
            strength: filterByCategory('Strength'),
            cardio: filterByCategory('Cardio'),
            stretchAndRecover: filterByCategory('Stretch & Recover'),
            boxAndFight: filterByCategory('Box & Fight'),
            yoga: filterByCategory('Yoga'),
            pilates: filterByCategory('Pilates'),
            cycling: filterByCategory('Cycling'),
            dance: filterByCategory('Dance'),
            meditation: filterByCategory('Meditation'),
            other: filterByCategory('Other'),
            highlights: filterByCategory('Highlights')
        });
    }, [allVideos, filterByCategory]);

    const fetchData = useCallback(async () => {
        const videos = await getVideos();
        setAllVideos(videos);
    }, [getVideos]);

    useEffect(() => {
        if (allVideos && allVideos.length > 0) return;
        if (location.pathname.includes('/movimento/videos')) fetchData();
    }, [allVideos, fetchData, location]);

    useEffect(() => {
        renderVideoList();
    }, [renderVideoList]);

    return (
        <VideosContext.Provider value={{ videosByCategory, allVideos }}>
            {children}
        </VideosContext.Provider>
    );
};

function useVideos(): VideosContextData {
    const context = useContext(VideosContext);
    if (!context) throw Error('the hook useVideos must be used inside a VideosProvider');
    return context;
}

export { useVideos, VideosProvider };
