import { Button, Modal, TextAreaWithCounter } from 'components/elements';
import { format } from 'date-fns';
import ENLocale from 'date-fns/locale/en-GB';
import PTLocale from 'date-fns/locale/pt';
import { useApi, useAuth, useLanguage, useTheme } from 'hooks';
import { CancellationRequestStatus } from 'models/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HiCheck, HiExclamation, HiOutlineClock } from 'react-icons/hi';
import { COLORS } from 'theme';
import { cj, DEV } from 'utils';

import styles from './Cancellation.module.scss';

type SurveyData = { option: string | undefined; reason: string | undefined };
type FormSteps = 'question-1' | 'question-2' | 'success' | 'error';

export const Cancellation: React.FC = () => {
    const { getCancelationRequestStatus } = useAuth();

    const [cancellationStatus, setCancellationStatus] = useState<CancellationRequestStatus>();
    const [showModal, setShowModal] = useState(false);
    const [formStep, setFormStep] = useState<FormSteps>('question-1');
    const [survey, setSurvey] = useState<SurveyData>({ option: undefined, reason: undefined });

    const getStatus = useCallback(async () => {
        const status = await getCancelationRequestStatus();
        setCancellationStatus(status);
    }, [getCancelationRequestStatus]);

    useEffect(() => {
        getStatus();
    }, [getStatus]);

    if (!cancellationStatus) return null;

    return (
        <>
            <RequestedMessage status={cancellationStatus} />
            <CancellationMessage
                setShowModal={setShowModal}
                requested={cancellationStatus.requested}
            />
            <ModalForm
                showModal={showModal}
                setShowModal={setShowModal}
                formStep={formStep}
                setFormStep={setFormStep}
                survey={survey}
                setSurvey={setSurvey}
                setCancellationStatus={setCancellationStatus}
            />
        </>
    );
};

type CancellationMessage = {
    requested: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CancellationMessage: React.FC<CancellationMessage> = (props) => {
    const { isPT } = useLanguage();
    const { activeTheme } = useTheme();

    const CONTENT = useMemo(() => {
        const DATA = {
            PT: {
                TITLE: 'Gestão de Subscrição',
                MESSAGE: 'Deseja desativar a sua subscrição?',
                BUTTON: 'Desativar'
            },
            EN: {
                TITLE: 'Subscription Management',
                MESSAGE: 'Do you want to deactivate your subscription?',
                BUTTON: 'Deactivate'
            }
        };
        return isPT ? DATA.PT : DATA.EN;
    }, [isPT]);

    if (props.requested) return null;

    return (
        <section className={cj(styles.cancellation, 'container')}>
            <h2>{CONTENT.TITLE}</h2>
            <p>{CONTENT.MESSAGE}</p>
            <Button
                label={CONTENT.BUTTON}
                size="md"
                className={cj(styles.button, styles[activeTheme])}
                onClick={() => props.setShowModal(true)}
            />
        </section>
    );
};

type RequestedMessage = {
    status: CancellationRequestStatus;
};

export const RequestedMessage: React.FC<RequestedMessage> = (props) => {
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();

    const CONTENT = useMemo(() => {
        const DATA = {
            PT: {
                TITLE: 'Gestão de Subscrição',
                CARD: {
                    TITLE: 'Pedido em apreciação',
                    MESSAGE:
                        'O pedido para a desativação da sua subscrição Hi foi enviado, encontrando-se em apreciação.',
                    DATE: 'Data do envio'
                }
            },
            EN: {
                TITLE: 'Subscription Management',
                CARD: {
                    TITLE: 'Request under review',
                    MESSAGE:
                        'The request to deactivate your Hi subscription has been sent and is under consideration.',
                    DATE: 'Submission date'
                }
            }
        };
        return isPT ? DATA.PT : DATA.EN;
    }, [isPT]);

    if (!props.status.requested) return null;

    return (
        <section className={cj(styles.cancellation, 'container')}>
            <h2>{CONTENT.TITLE}</h2>
            <div className={cj(styles.card, styles[activeTheme])}>
                <div className={styles['card-header']}>
                    <div className={styles['icon-container']}>
                        <HiOutlineClock className={cj(styles.icon, styles.success)} />
                    </div>
                </div>
                <div className={styles['card-body']}>
                    <strong className={styles.title}>{CONTENT.CARD.TITLE}</strong>
                    <span className={styles.message}>{CONTENT.CARD.MESSAGE}</span>
                    {props.status.date && (
                        <span className={styles.date}>
                            {CONTENT.CARD.DATE}
                            {' : '}
                            {format(new Date(props.status.date.replace(' ', 'T')), 'd MMM yyyy', {
                                locale: isPT ? PTLocale : ENLocale
                            })}
                        </span>
                    )}
                </div>
            </div>
        </section>
    );
};

type ModalFormProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    formStep: FormSteps;
    setFormStep: React.Dispatch<React.SetStateAction<FormSteps>>;
    survey: SurveyData;
    setSurvey: React.Dispatch<React.SetStateAction<SurveyData>>;
    setCancellationStatus: React.Dispatch<
        React.SetStateAction<CancellationRequestStatus | undefined>
    >;
};

const ModalForm: React.FC<ModalFormProps> = (props) => {
    const { activeTheme, darkMode } = useTheme();

    const handleModalClose = useCallback(() => {
        props.setSurvey({ option: undefined, reason: undefined });
        props.setShowModal(false);
        props.setFormStep('question-1');
    }, [props]);

    if (!props.showModal) return null;

    return (
        <Modal
            show={props.showModal}
            className={cj(styles.modal, styles[activeTheme])}
            header={{
                className: cj(styles['modal-header'], styles[activeTheme]),
                customTitle:
                    props.formStep !== 'success' && props.formStep !== 'error'
                        ? 'Pedido de Desativação'
                        : ''
            }}
            footer={{ enable: false }}
            close={{
                handler: handleModalClose,
                buttonColor: COLORS.PURPLE_300
            }}
            background={{
                color: darkMode
                    ? `conic-gradient(from 0deg at 50% 25%, ${COLORS.PURPLE_900} 0deg, ${COLORS.PURPLE_700} 91deg)`
                    : `conic-gradient(from 0deg at 50% 25%, ${COLORS.NEUTRAL_100} 0deg, ${COLORS.NEUTRAL_50} 91deg)`
            }}>
            <FormStepOne
                survey={props.survey}
                setSurvey={props.setSurvey}
                formStep={props.formStep}
                setFormStep={props.setFormStep}
            />
            <FormStepTwo
                formStep={props.formStep}
                setFormStep={props.setFormStep}
                survey={props.survey}
                setSurvey={props.setSurvey}
                setCancellationStatus={props.setCancellationStatus}
            />
            <Message
                formStep={props.formStep}
                setFormStep={props.setFormStep}
                setShowModal={props.setShowModal}
            />
        </Modal>
    );
};

type FormStepOneProps = {
    survey: SurveyData;
    setSurvey: React.Dispatch<React.SetStateAction<SurveyData>>;
    formStep: FormSteps;
    setFormStep: React.Dispatch<React.SetStateAction<FormSteps>>;
};

export const FormStepOne: React.FC<FormStepOneProps> = (props) => {
    const { isPT } = useLanguage();
    const { activeTheme } = useTheme();

    const getRadioButtonValue = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.persist();
            props.setSurvey({
                option: event.target.value.replace('-', ' '),
                reason: undefined
            });
        },
        [props]
    );

    const handleNavigation = useCallback(() => props.setFormStep('question-2'), [props]);

    const CONTENT = useMemo(() => {
        const DATA = {
            PT: {
                QUESTION: 'Qual o motivo principal para efetuar o seu pedido?',
                ANSWER: 'Insatisfação com o serviço prestado por:',
                BUTTON: 'Próximo'
            },
            EN: {
                QUESTION: 'What is the main reason for placing your order?',
                ANSWER: 'Dissatisfaction with the service provided by:',
                BUTTON: 'Next'
            }
        };
        return isPT ? DATA.PT : DATA.EN;
    }, [isPT]);

    const radioButtons = useMemo(
        () => [
            { id: 'fitness-hut', label: { pt: 'Fitness Hut', en: 'Fitness Hut' } },
            { id: 'advancecare', label: { pt: 'AdvanceCare', en: 'AdvanceCare' } },
            { id: 'hi', label: { pt: 'Hi! Ecosystem', en: 'Hi! Ecosystem' } },
            { id: 'other', label: { pt: 'Outro motivo', en: 'Other reason' } }
        ],
        []
    );

    if (props.formStep !== 'question-1') return null;

    return (
        <div className={cj(styles['modal-wrapper'], styles[activeTheme])}>
            <p>{CONTENT.QUESTION}</p>
            <div className={styles.options} onChange={getRadioButtonValue}>
                <span>{CONTENT.ANSWER}</span>
                {radioButtons.map((btn) => (
                    <span className={styles['radio-btn']} key={btn.id}>
                        <input type="radio" name="motive" id={btn.id} value={btn.id} />
                        <label htmlFor={btn.id}>{isPT ? btn.label.pt : btn.label.en}</label>
                    </span>
                ))}
            </div>
            <Button
                label={CONTENT.BUTTON}
                onClick={handleNavigation}
                size="md"
                disabled={!props.survey.option}
                className={cj(styles.button, styles.next)}
            />
        </div>
    );
};

type FormStepTwoProps = {
    formStep: FormSteps;
    survey: SurveyData;
    setSurvey: React.Dispatch<React.SetStateAction<SurveyData>>;
    setFormStep: React.Dispatch<React.SetStateAction<FormSteps>>;
    setCancellationStatus: React.Dispatch<
        React.SetStateAction<CancellationRequestStatus | undefined>
    >;
};

const CHARACTER_LIMIT = 160;

export const FormStepTwo: React.FC<FormStepTwoProps> = (props) => {
    const { postCancellationRequest } = useApi();
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();

    const onTextAreaChange = useCallback(
        (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
            const reason = evt.target.value;
            props.setSurvey((state) => ({ ...state, reason }));
        },
        [props]
    );

    const handleSubmit = useCallback(async () => {
        try {
            if (!props.survey.option) {
                throw new Error();
            }

            await postCancellationRequest(props.survey.option, props.survey.reason);

            props.setCancellationStatus({
                requested: true,
                option: props.survey.option,
                reason: props.survey.reason,
                date: new Date().toISOString()
            } as CancellationRequestStatus);
            props.setFormStep('success');
        } catch (error) {
            DEV && console.log('error:', error);
            props.setFormStep('error');
        }
    }, [postCancellationRequest, props]);

    const CONTENT = useMemo(() => {
        const DATA = {
            PT: {
                QUESTION: 'Personalize o seu pedido adicionando uma curta descrição',
                NOTE: 'Ao selecionar o botão, o pedido será submetido para apreciação da equipa Hi!',
                BUTTON: 'Enviar Pedido'
            },
            EN: {
                QUESTION: 'Customize your order by adding a short description',
                NOTE: 'By selecting the button, the request will be submitted to the Hi!',
                BUTTON: 'Send Request'
            }
        };
        return isPT ? DATA.PT : DATA.EN;
    }, [isPT]);

    if (props.formStep !== 'question-2') return null;

    return (
        <div className={cj(styles['modal-wrapper'], styles[activeTheme])}>
            <p>{CONTENT.QUESTION}</p>
            <TextAreaWithCounter characterLimit={CHARACTER_LIMIT} onChange={onTextAreaChange} />
            <Button
                label={CONTENT.BUTTON}
                onClick={handleSubmit}
                size="md"
                className={cj(styles.button, styles.submit)}
            />
            <span className={styles.note}>{CONTENT.NOTE}</span>
        </div>
    );
};

type MessageProps = {
    formStep: FormSteps;
    setFormStep: React.Dispatch<React.SetStateAction<FormSteps>>;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};
export const Message: React.FC<MessageProps> = (props) => {
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();

    const handleCloseModal = useCallback(() => {
        props.setFormStep('question-1');
        props.setShowModal(false);
    }, [props]);

    const CONTENT = useMemo(() => {
        const DATA = {
            PT: {
                SUCCESS: {
                    TITLE: 'Pedido submetido com sucesso',
                    DESCRIPTION: 'O seu pedido de desativação foi submetido com sucesso.',
                    BUTTON: 'Fechar',
                    ICON: (
                        <HiCheck
                            className={cj(styles.icon, styles.success, styles[props.formStep])}
                        />
                    )
                },
                ERROR: {
                    TITLE: 'Aconteceu um problema ao enviar o seu pedido',
                    DESCRIPTION:
                        'A equipa técnica Hi! já está a resolver o problema. Por favor tente mais tarde.',
                    BUTTON: 'Fechar',
                    ICON: (
                        <HiExclamation
                            className={cj(styles.icon, styles.success, styles[props.formStep])}
                        />
                    )
                }
            },
            EN: {
                SUCCESS: {
                    TITLE: 'Request sent successfully',
                    DESCRIPTION: 'Your deactivation request was submitted successfully.',
                    BUTTON: 'Close',
                    ICON: (
                        <HiCheck
                            className={cj(styles.icon, styles.success, styles[props.formStep])}
                        />
                    )
                },
                ERROR: {
                    TITLE: 'There was a problem sending your order',
                    DESCRIPTION:
                        'The Hi! technical team is already solving the problem. Please try again later.',
                    BUTTON: 'Close',
                    ICON: (
                        <HiExclamation
                            className={cj(styles.icon, styles.success, styles[props.formStep])}
                        />
                    )
                }
            }
        };
        const CONTENT_BY_LANGUAGE = isPT ? DATA.PT : DATA.EN;
        return props.formStep === 'success'
            ? CONTENT_BY_LANGUAGE.SUCCESS
            : CONTENT_BY_LANGUAGE.ERROR;
    }, [isPT, props.formStep]);

    if (props.formStep !== 'success' && props.formStep !== 'error') return null;

    return (
        <div className={cj(styles['message-wrapper'], styles[activeTheme])}>
            <div className={styles['icon-container']}>{CONTENT.ICON}</div>
            <div className={styles.message}>
                <span className={cj(styles.title, styles[props.formStep])}>{CONTENT.TITLE}</span>
                <span className={styles.description}>{CONTENT.DESCRIPTION}</span>
            </div>
            <button onClick={handleCloseModal} className={styles.button}>
                {CONTENT.BUTTON}
            </button>
        </div>
    );
};
