import { Link } from 'components/elements';
import { useTheme } from 'hooks';
import { AreaType } from 'models/types';
import { SymbolHPS } from 'theme/symbols';

import styles from './CardAlternative.module.scss';

export type CardAlternativeProps = React.HTMLProps<HTMLDivElement> & {
    image?: string;
    image_2x?: string;
    alt?: string;
    title: string;
    description: string;
    points?: number;
    buttonLabel: string;
    slug: string;
    area?: AreaType;
    hasImage?: boolean;
    hasPoints?: boolean;
    className?: string;
};

export const CardAlternative: React.FC<CardAlternativeProps> = ({
    image = '',
    image_2x = image,
    title,
    description,
    alt = title,
    points = 0,
    buttonLabel,
    slug,
    area = 'articles',
    hasImage = true,
    hasPoints = true,
    className = '',
    ...props
}) => {
    const { activeTheme } = useTheme();

    // /*
    //  * GAMING: TEMPORARILY HIDES ACTIVITY POINTS
    //  */
    // hasPoints = false;

    return (
        <div
            className={`${styles.card} ${styles[activeTheme]} ${hasImage ? styles.image : ''} ${
                hasPoints ? styles.points : ''
            } ${className}`}
            {...props}>
            {hasImage && (
                <img
                    srcSet={`${image_2x} 2x, ${image}`}
                    src={image}
                    alt={alt}
                    className={styles.image}
                />
            )}
            <div className={styles.info}>
                <span className={styles.title}>{title}</span>
                <p className={styles.description}>{description}</p>
            </div>
            {hasPoints && (
                <div
                    className={`${styles.points} ${area === 'movement' ? styles.movement : ''} ${
                        area === 'health' ? styles.health : ''
                    } ${area === 'nutrition' ? styles.nutrition : ''} ${
                        area === 'articles' ? styles.articles : ''
                    }`}>
                    <SymbolHPS className={styles.icon} />
                    <span className={styles.points}>{points}pts</span>
                </div>
            )}
            <div className={styles.link}>
                <Link label={buttonLabel} to={slug} type="button" size="xs" area={area} />
            </div>
        </div>
    );
};
