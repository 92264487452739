import { ErrorButton, ErrorMessage } from 'components/elements';
import { useTheme } from 'hooks';
import { useState } from 'react';

import { DatalistProps } from '../types';
import styles from './Datalist.module.scss';

export const Datalist: React.FC<DatalistProps> = ({
    id,
    label,
    options,
    className = '',
    hasError = false,
    errorMessage = null,
    hasContent = false,
    ...props
}) => {
    const { activeTheme } = useTheme();
    const inputID = Math.floor(Math.random() * 1000);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const toggleMessage = () => {
        setShowErrorMessage(!showErrorMessage);
    };

    return (
        <div
            className={`${styles.container} ${className} ${styles[activeTheme]} ${
                hasError ? styles.error : ''
            }`}>
            <input list={`options-${inputID}`} id={id} name={id} {...props} />
            <label htmlFor={id} className={hasContent ? styles.hasContent : undefined}>
                {label}
            </label>
            <datalist id={`options-${inputID}`}>
                {options.map((option: string, i: number) => (
                    <option value={option} key={i.toString()} />
                ))}
            </datalist>
            {hasError && (
                <ErrorButton hasError handleErrorMessage={toggleMessage} position="CENTER-RIGHT" />
            )}
            {showErrorMessage && <ErrorMessage message={errorMessage} />}
        </div>
    );
};
