import { Avatar, Button, ImageCropDialog, InputFile, Modal } from 'components/elements';
import { useApi, useAuth, useGamification, useTheme } from 'hooks';
import { UserData } from 'models/types';
import { useCallback, useEffect, useState } from 'react';
import { Point } from 'react-easy-crop/types';
import Resizer from 'react-image-file-resizer';
import { COLORS } from 'theme';
import { IconAlert } from 'theme/icons';

import styles from './Intro.module.scss';

type IntroProps = {
    content: {
        BUTTON_LABEL: string;
        ERROR_MESSAGE: {
            TITLE: string;
            DESCRIPTION: string;
            BUTTON_LABEL: string;
        };
    };
};

export const Intro: React.FC<IntroProps> = ({ content }) => {
    const { user, updateUser } = useAuth();
    const { activeTheme, darkMode } = useTheme();
    const { postUserAvatar } = useApi();
    const { reportInteraction } = useGamification();

    const [userAvatar, setUserAvatar] = useState<string | undefined>(undefined);
    const [selectedUserAvatar, setSelectedUserAvatar] = useState<string | undefined>(undefined);
    const [showModal, setShowModal] = useState(false);

    const resizeFile = useCallback(
        (file: Blob) =>
            new Promise((resolve) => {
                Resizer.imageFileResizer(
                    file, // image file which will resized.
                    600, // maxWidth of the resized new image.
                    600, // maxHeight of the resized new image.
                    'JPEG', // compressFormat of the resized new image.
                    80, // quality of the resized new image.
                    0, // degree of clockwise rotation to apply to uploaded image.
                    (uri) => {
                        resolve(uri);
                    }, // callBack function of the resized new image URI.
                    'base64', // output type of the resized new image.
                    120, // minWidth of the resized new image.
                    120 // minHeight of the resized new image.
                );
            }),
        []
    );

    const onCancel = useCallback(() => setSelectedUserAvatar(undefined), []);

    const onInputFileChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.files && e.target.files.length > 0) {
                try {
                    const file = e.target.files[0];
                    const image = (await resizeFile(file)) as string;
                    setSelectedUserAvatar(image);
                } catch (err) {
                    // console.log(err);
                    setShowModal(true);
                }
            }
        },
        [resizeFile]
    );

    const postImage = useCallback(
        async (croppedImageBase64: string) => {
            try {
                const avatar = await postUserAvatar(croppedImageBase64);
                const storedUser = localStorage.getItem('@hi:user');
                if (!storedUser) return;
                const parsedUser: UserData = JSON.parse(storedUser);
                parsedUser.image = avatar;

                updateUser(parsedUser);

                reportInteraction({
                    interaction: 'upload_photo',
                    area: 'bonus',
                    id: null
                });
            } catch (err) {
                // console.log({ err });
                setShowModal(true);
            }
        },
        [postUserAvatar, reportInteraction, updateUser]
    );

    const setCroppedImageFor = (
        id: number,
        crop: Point,
        zoom: number,
        aspect: number,
        croppedImageBase64: string
    ) => {
        // console.log({ id, crop, zoom, aspect, croppedImageBase64 });

        postImage(croppedImageBase64);
        setUserAvatar(croppedImageBase64);
        setSelectedUserAvatar(undefined);
    };

    useEffect(() => setUserAvatar(user?.image || undefined), [user]);

    if (!user) return null;

    return (
        <>
            <section className={[styles.intro, styles[activeTheme], 'container'].join(' ')}>
                <span className={styles.preTitle}>my hi!</span>
                <h1>{user.name.short || user.name.full}</h1>
                <h2>
                    {user.membership.name} • {user.performance.total_points}pts
                </h2>

                <Avatar
                    source={userAvatar}
                    alt={user.name.short || user.name.first}
                    className={styles.avatar}
                    width={120}
                    height={120}
                />

                <InputFile
                    inputLabel={content.BUTTON_LABEL}
                    onChange={onInputFileChange}
                    accept="image/*"
                    // capture="user"
                    multiple={false}
                />

                {selectedUserAvatar && (
                    <ImageCropDialog
                        id={1}
                        imageUrl={selectedUserAvatar}
                        cropInit={{ x: 0, y: 0 }}
                        zoomInit={1}
                        onCancel={onCancel}
                        setCroppedImageFor={setCroppedImageFor}
                    />
                )}
            </section>
            <Modal
                show={showModal}
                className={styles.modal}
                footer={{ enable: false }}
                close={{ handler: () => setShowModal(false), buttonColor: COLORS.RED_500 }}
                background={{ color: darkMode ? COLORS.BLUISH_PURPLE_800 : COLORS.WHITE }}>
                <IconAlert color={COLORS.RED_500} width={64} height={56} />
                <h2>{content.ERROR_MESSAGE.TITLE}</h2>
                <p>{content.ERROR_MESSAGE.DESCRIPTION}</p>
                <Button
                    label={content.ERROR_MESSAGE.BUTTON_LABEL}
                    className={styles.button}
                    size="md"
                    onClick={() => setShowModal(false)}
                />
            </Modal>
        </>
    );
};
