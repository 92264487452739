import { CoachesList } from 'content/types';

export const MOVEMENT_COACHES_LIST: CoachesList = {
    PT: {
        INTRO: {
            PRE_TITLE: 'treino pessoal',
            TITLE: 'Agendar um/a Sport Coach'
        },
        FILTER: {
            LABEL: 'filtrar'
        },
        SEARCH: {
            LABEL: 'Pesquisar'
        },
        HIGHLIGHTS: {
            TITLE: 'Em destaque'
        },
        TAGS: {
            TITLE: 'Palavras Chave'
        }
    },
    EN: {
        INTRO: {
            PRE_TITLE: 'personal training',
            TITLE: 'Schedule a Sport Coach'
        },
        FILTER: {
            LABEL: 'filter'
        },
        SEARCH: {
            LABEL: 'Search'
        },
        HIGHLIGHTS: {
            TITLE: 'Highlights'
        },
        TAGS: {
            TITLE: 'Key words'
        }
    }
};
