import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconPlusRounded: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                d="M2606.74 928.82a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm0 19.18a9.18 9.18 0 1 1 9.18-9.18 9.18 9.18 0 0 1-9.18 9.18Zm.56-14.17h-1.11v4.44h-4.45v1.11h4.45v4.44h1.11v-4.44h4.44v-1.11h-4.44Z"
                transform="translate(-2596.74 -928.82)"
            />
        </svg>
    );
};
