import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconLinkedIn: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 21,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path d="M20 2.3A2.34 2.34 0 0 0 18.1.04H2.03a7.09 7.09 0 0 0-1 .43 2.16 2.16 0 0 0-1 1.49.49.49 0 0 1 0 .11V18a2.78 2.78 0 0 0 .48 1.1 2.25 2.25 0 0 0 1.83.93h15.38a2.32 2.32 0 0 0 2.31-2.3Zm-1.56 15.23a.8.8 0 0 1-.9.9H2.46a.79.79 0 0 1-.89-.9V2.46a.8.8 0 0 1 .9-.9h15.07a.8.8 0 0 1 .9.9Zm-2.16-7.86a2.37 2.37 0 0 0-1.29-1.77 3.82 3.82 0 0 0-3.14-.09 2.79 2.79 0 0 0-1.23 1v-1.1H7.98v8.8h2.65V12.16a5.84 5.84 0 0 1 .08-.93 1.49 1.49 0 0 1 1.32-1.34 1.34 1.34 0 0 1 1.68 1.21 5.69 5.69 0 0 1 .08.83v4.58h2.69v-5.26a7.67 7.67 0 0 0-.2-1.58ZM3.53 16.5h2.71V7.7H3.53ZM4.92 3.28a1.6 1.6 0 1 0 1.6 1.6 1.6 1.6 0 0 0-1.6-1.6Z" />
        </svg>
    );
};
