import { ButtonIcon } from 'components/elements';
import { useLanguage } from 'hooks';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconX } from 'theme/icons';

import styles from './NotFound.module.scss';

export const NotFound: React.FC = () => {
    const navigate = useNavigate();
    const { isPT } = useLanguage();

    const onCLick = useCallback(() => navigate('/'), [navigate]);

    return (
        <main className={styles.main}>
            <IconX width={60} height={60} className={styles.icon} />

            <h1>404</h1>
            <p>
                {isPT ? 'A página que visitou não existe' : 'The page you visited does not exist'}
            </p>
            <ButtonIcon
                icon="ARROW_LEFT"
                size="MD"
                backgroundOpacity="OPAQUE"
                className={styles['back-button']}
                onClick={onCLick}
            />
        </main>
    );
};
