import { useTheme } from 'hooks';

import styles from './FX1.module.scss';

export type EffectProps = React.HTMLAttributes<HTMLOrSVGElement> & {
    fillColor?: string;
    hasShadow?: boolean;
    className?: string;
};

export const FX1: React.FC<EffectProps> = ({
    fillColor,
    hasShadow = false,
    className = '',
    ...props
}) => {
    const { darkMode } = useTheme();
    return (
        <div className={`${styles.container} ${className}`}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 423 248"
                preserveAspectRatio="xMidYMid"
                className={styles.svg}
                fill={fillColor}
                {...props}>
                <defs>
                    <filter
                        id="fx1-shadow"
                        x="0"
                        y="0"
                        width="423"
                        height="248"
                        filterUnits="userSpaceOnUse">
                        <feOffset dy="-10" />
                        <feGaussianBlur stdDeviation="8" result="blur" />
                        <feFlood floodOpacity={darkMode ? '.4' : '.1'} />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                </defs>
                <g filter={hasShadow ? 'url(#fx1-shadow)' : undefined}>
                    <path d="M24 234h375V34c0 73.6-41.8 136-98.2 146.7L24 233.4Z" />
                </g>
            </svg>
        </div>
    );
};
