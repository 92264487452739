import { ErrorButton, ErrorMessage } from 'components/elements';
import { useTheme } from 'hooks';
import { useState } from 'react';
import { stringToSlug } from 'utils';

import { TextAreaProps } from '../types';
import styles from './TextArea.module.scss';

export const TextArea: React.FC<TextAreaProps> = ({ label, hasError = false, ...props }) => {
    const { activeTheme } = useTheme();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const slug = stringToSlug(label);

    const toggleMessage = () => {
        setErrorMessage('Some error message');
        setShowErrorMessage(!showErrorMessage);
    };

    return (
        <div
            className={`${styles.container} ${styles[activeTheme]} ${
                hasError ? styles.error : ''
            }`}>
            <textarea placeholder=" " {...props} />
            <label htmlFor={slug}>{label}</label>
            {hasError && <ErrorButton hasError handleErrorMessage={toggleMessage} />}
            {showErrorMessage && <ErrorMessage message={errorMessage} />}
        </div>
    );
};
