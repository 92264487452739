export const DESKTOP_MESSAGE = {
    PT: {
        TITLE: 'Bem-vindo/a ao Hi!',
        DESCRIPTION: 'Por favor visite através do seu smartphone',
        QR_CODE_MSG:
            'Digitalize este código QR utilizando a câmara do seu smartphone para aceder rapidamente a esta aplicação no seu dispositivo.'
    },
    EN: {
        TITLE: 'Welcome to Hi!',
        DESCRIPTION: 'Please visit via your smartphone',
        QR_CODE_MSG:
            'Scan this QR code using the camera on your smartphone to quick access this app on your device.'
    }
};
