import { useLanguage, useTheme } from 'hooks';
import { ArticleData } from 'models/types';
import { A11y, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

import styles from './Content.module.scss';

type ArticleContent = {
    title: string | undefined;
    subtitle: string;
};

type ArticleProps = {
    articleContent: ArticleContent | undefined;
    currentArticle: ArticleData | undefined;
};

export const Content: React.FC<ArticleProps> = ({ articleContent, currentArticle }) => {
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();

    if (!articleContent || !currentArticle) return null;

    return (
        <article className={[styles.article, styles[activeTheme], 'container'].join(' ')}>
            <h1>{articleContent.title}</h1>
            <span className={[styles.subtitle, styles[currentArticle.area]].join(' ')}>
                {articleContent.subtitle}
            </span>

            {currentArticle.article
                .filter(({ type }) => type !== 'h1')
                .map((article) => {
                    if (article.type === 'h2')
                        return (
                            <h2 key={article.id.toString()}>
                                {isPT ? article.content?.pt : article.content?.en}
                            </h2>
                        );

                    if (article.type === 'image')
                        return (
                            <img
                                key={article.id.toString()}
                                srcSet={`${
                                    article.image_content?.image_2x ?? article.image_content?.image
                                } 2x, ${article.image_content?.image}`}
                                src={article.image_content?.image}
                                alt={
                                    isPT
                                        ? article.image_content?.caption?.pt ||
                                          currentArticle.article
                                              .filter((a) => a.type === 'h1')
                                              .shift()?.content?.pt
                                        : article.image_content?.caption?.en ||
                                          currentArticle.article
                                              .filter((a) => a.type === 'h1')
                                              .shift()?.content?.en
                                }
                            />
                        );

                    if (article.type === 'lead')
                        return (
                            <p key={article.id.toString()} className={styles.lead}>
                                {isPT ? article.content?.pt : article.content?.en}
                            </p>
                        );

                    if (article.type === 'p')
                        return (
                            <p key={article.id.toString()}>
                                {isPT ? article.content?.pt : article.content?.en}
                            </p>
                        );

                    if (article.type === 'slider')
                        return (
                            article.slider_content && (
                                <Swiper
                                    key={article.id.toString()}
                                    modules={[Pagination, A11y]}
                                    pagination={{
                                        clickable: true,
                                        bulletClass: styles.bullet,
                                        bulletActiveClass: styles.bulletActive
                                    }}
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    className={styles.slider}>
                                    {article.slider_content.map((slider) => (
                                        <SwiperSlide key={slider.id.toString()}>
                                            <img
                                                srcSet={`${slider.image_2x || slider.image} 2x, ${
                                                    slider.image
                                                }`}
                                                src={slider.image}
                                                alt={isPT ? slider.caption?.pt : slider.caption?.en}
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            )
                        );

                    return null;
                })}
        </article>
    );
};
