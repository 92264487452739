import { Carousel } from 'components/elements';
import { wearables } from 'constants/wearables';
import { useLanguage, useTheme } from 'hooks';
import { useMemo } from 'react';

import styles from './Wearables.module.scss';

type WearablesProps = {
    title: string;
    description: string;
};

export const Wearables: React.FC<WearablesProps> = ({ title, description }) => {
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();

    const className = useMemo(
        () =>
            [styles.wearables, styles[activeTheme], isPT ? styles.pt : styles.en, 'container'].join(
                ' '
            ),
        [activeTheme, isPT]
    );

    return (
        <section className={className}>
            <h2>{title}</h2>
            <p>{description}</p>
            <Carousel
                __typename="card-wearables"
                slidesPerView={2}
                slides={wearables.map((wearable) => ({
                    handleClick: () => console.log('clicked'),
                    ...wearable
                }))}
            />
        </section>
    );
};
