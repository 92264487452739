import { NavBar, Tour } from 'components/elements';
import { permissions } from 'config';
import * as IMAGES from 'constants/images';
import { HOME, META_TAGS } from 'content';
import { useAuth, useLanguage } from 'hooks';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
// import { Helmet } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';

import {
    Articles,
    MemoizedAreaCards,
    MemoizedNutritionalAssessmentBanner,
    MemoizedSharePromoBanner,
    Points,
    Services
} from './modules';

const Header = React.lazy(
    () =>
        import(
            /* webpackChunkName: "Header" */
            'components/sections/Header'
        )
);
const Onboarding = React.lazy(
    () =>
        import(
            /* webpackChunkName: "Onboarding" */
            'components/sections/Onboarding'
        )
);

export const Home: React.FC = () => {
    const { user, validateUserAccessToArea } = useAuth();
    const { isPT } = useLanguage();

    const CONTENT = isPT ? HOME.PT : HOME.EN;

    const [showOnboarding, setShowOnboarding] = useState(false);

    useEffect(() => validateUserAccessToArea(permissions.general.home), [validateUserAccessToArea]);
    useEffect(() => setShowOnboarding(user?.log_count === 1), [setShowOnboarding, user?.log_count]);

    const showBanner = useMemo(() => user?.subscriptions.nutrition.start_pack ?? false, [user]);

    if (showOnboarding)
        return (
            <Suspense fallback={null}>
                <Onboarding setShow={setShowOnboarding} />
            </Suspense>
        );

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? META_TAGS.PRIVATE.GENERAL.HOME.PT.TITLE
                        : META_TAGS.PRIVATE.GENERAL.HOME.EN.TITLE}
                </title>
            </Helmet>
            <Suspense fallback={<div style={{ marginBottom: '7.2rem', height: '26rem' }} />}>
                <Header
                    image={IMAGES.COVER_MAIN}
                    image_2x={IMAGES.COVER_MAIN_2X}
                    alt={CONTENT.HEADER.IMAGE_ALT}
                />
            </Suspense>
            <main>
                <MemoizedAreaCards />
                <MemoizedSharePromoBanner />
                <MemoizedNutritionalAssessmentBanner
                    card={CONTENT.NUTRITIONAL_ASSESSMENT_BANNER}
                    showBanner={showBanner}
                />
                <Points content={CONTENT.POINTS} />
                <Services content={CONTENT.SERVICES} />
                <Articles content={CONTENT.ARTICLES} />
            </main>
            <NavBar activeArea="home" />
            <Tour />
        </>
    );
};
