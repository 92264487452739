import { Button, Input } from 'components/elements';
import { permissions, toastOptions } from 'config';
import { PREFERENCES } from 'content';
import { useFormik } from 'formik';
import { useApi, useAuth, useLanguage, useTheme } from 'hooks';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import styles from '../Preferences.module.scss';
type NameFormValues = {
    name: string;
};

export const EditName: React.FC = () => {
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();
    const { user, updateUser } = useAuth();
    const { validateUserAccessToArea } = useAuth();
    const { postUserShortName } = useApi();

    const CONTENT = isPT ? PREFERENCES.PT.NAME : PREFERENCES.EN.NAME;

    useEffect(
        () => validateUserAccessToArea(permissions.general.preferences),
        [validateUserAccessToArea]
    );

    const initialValues = { name: user?.name.short || user?.name.full || '' };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(CONTENT.MESSAGES.NAME_REQUIRED)
    });

    const onSubmit = useCallback(
        async (values: NameFormValues) => {
            if (!user) throw new Error();

            try {
                await postUserShortName(values.name);

                updateUser({
                    ...user,
                    name: {
                        first: user.name.first,
                        full: user.name.full,
                        last: user.name.last,
                        short: values.name
                    }
                });

                toast.dark(CONTENT.MESSAGES.SUCCESS, toastOptions);
            } catch (error) {
                toast.dark(CONTENT.MESSAGES.API_ERROR, toastOptions);
            }
        },
        [CONTENT.MESSAGES.API_ERROR, CONTENT.MESSAGES.SUCCESS, postUserShortName, updateUser, user]
    );

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    return (
        <section className={`${styles.section} container`}>
            <h1>{CONTENT.INTRO.TITLE}</h1>
            <p>{CONTENT.INTRO.DESCRIPTION}</p>
            <div className={`${styles.card} ${styles[activeTheme]}`}>
                <form onSubmit={formik.handleSubmit}>
                    <Input
                        label={CONTENT.FORM.LABELS.NAME}
                        id="name"
                        hasError={formik.touched.name && !!formik.errors.name}
                        errorMessage={formik.errors.name}
                        {...formik.getFieldProps('name')}
                    />
                    <Button
                        type="submit"
                        label={CONTENT.FORM.LABELS.SUBMIT}
                        area="movement"
                        size="md"
                        className={styles.button}
                    />
                </form>
            </div>
        </section>
    );
};
