import { Modal } from 'components/elements';
import { useLanguage } from 'hooks';
import { COLORS } from 'theme';
import { IconExternal } from 'theme/icons';

import styles from './ModalExternalLink.module.scss';

export type ModalExternalLinkProps = {
    show: boolean;
    setShow: (value: React.SetStateAction<boolean>) => void;
    externalURL: string | undefined;
};

export const ModalExternalLink: React.FC<ModalExternalLinkProps> = ({
    show,
    setShow,
    externalURL
}) => {
    const { isPT } = useLanguage();

    if (!externalURL) return null;
    return (
        <Modal
            show={show}
            className={styles.modal}
            footer={{ enable: false }}
            close={{ handler: () => setShow(false), buttonColor: COLORS.MAGENTA_500 }}
            background={{ color: COLORS.WHITE }}>
            <IconExternal className={styles.icon} />
            <strong>{isPT ? 'Abrir link externo' : 'Open external link'}</strong>
            <p>
                {isPT
                    ? 'Está prestes a abrir um link externo. Para continuar, selecione OK.'
                    : 'You are about to open an external link. To continue, select OK.'}
            </p>
            <a
                href={externalURL}
                className={styles.button}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => setShow(false)}>
                OK
            </a>
        </Modal>
    );
};
