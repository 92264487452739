import { ActivityGraphHearts, GraphProgress } from 'components/elements';
import { useAuth, useGamification, useLanguage } from 'hooks';
import { AreaType, TrackerData } from 'models/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { areaToPT } from 'utils';

import styles from './CurrentPoints.module.scss';

type CurrentPointsProps = {
    content: {
        TITLE: string;
        DESCRIPTION: string;
    };
};

export const CurrentPoints: React.FC<CurrentPointsProps> = ({ content }) => {
    const { user } = useAuth();
    const { userProgress } = useGamification();
    const { isPT } = useLanguage();

    const [trackers, setTrackers] = useState<TrackerData[]>([]);

    const getTracker = useCallback(
        (area: AreaType) => {
            if (!trackers) return;
            const tracker = trackers.find((t) => t.area === area);
            if (!tracker) return;
            return {
                label: isPT ? areaToPT(tracker.area) : tracker?.area,
                percentage: tracker.percentage,
                points: tracker.points,
                area: tracker.area
            };
        },
        [isPT, trackers]
    );

    const movementTracker = useMemo(() => getTracker('movement'), [getTracker]);
    const nutritionTracker = useMemo(() => getTracker('nutrition'), [getTracker]);
    const healthTracker = useMemo(() => getTracker('health'), [getTracker]);

    useEffect(() => setTrackers(user?.performance.trackers ?? []), [user]);

    if (!user) return null;

    return (
        <section className={`${styles.current} container`}>
            <h2>{content.TITLE}</h2>
            <p>{content.DESCRIPTION}</p>
            <ActivityGraphHearts
                progress={userProgress(user)}
                area="all"
                className={styles.graph}
            />
            {movementTracker && (
                <GraphProgress
                    label={movementTracker.label}
                    percentage={movementTracker.percentage}
                    area={movementTracker.area}
                />
            )}
            {nutritionTracker && (
                <GraphProgress
                    label={nutritionTracker.label}
                    percentage={nutritionTracker.percentage}
                    area={nutritionTracker.area}
                />
            )}
            {healthTracker && (
                <GraphProgress
                    label={healthTracker.label}
                    percentage={healthTracker.percentage}
                    area={healthTracker.area}
                />
            )}
        </section>
    );
};
