import { Lettering } from './Lettering';
import styles from './LogoHi.module.scss';
import { Symbol } from './Symbol';

export type LogoHiProps = {
    showLettering?: boolean;
    showSymbol?: boolean;
    animate?: boolean;
    width?: number;
} & React.HTMLProps<HTMLDivElement>;

export const LogoHi: React.FC<LogoHiProps> = ({
    showLettering = true,
    showSymbol = true,
    width = 180,
    className = '',
    animate,
    ...props
}) => (
    <div
        className={`${styles.container} ${showLettering ? styles.showLettering : ''} ${
            showSymbol ? styles.showSymbol : ''
        } ${className}`}
        style={{ width }}
        {...props}>
        {showSymbol && <Symbol animate={animate} className={`${styles.icon} ${className}`} />}
        {showLettering && <Lettering className={`${styles.lettering} ${className}`} />}
    </div>
);
