import { LogoFitnessHut } from 'assets/logos';
import {
    ActivityGraphHearts,
    CardAlternative,
    CardAlternativeProps,
    CardToggleV2,
    Carousel,
    CarouselProps,
    Link
} from 'components/elements';
import { permissions } from 'config';
import { CLIENT_AREA, META_TAGS, MOVEMENT_HOME } from 'content';
import { useApi, useAuth, useGamification, useLanguage, useTheme } from 'hooks';
import { DailyTipData } from 'models/types';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { COLORS } from 'theme/colors';
import { IconMovementV1 } from 'theme/icons';

import styles from './Home.module.scss';

const SHOW_PROMOS = false;

export const MovementHome: React.FC = () => {
    const { validateUserAccessToArea, user } = useAuth();
    const { userProgress } = useGamification();
    const { activeTheme } = useTheme();
    const { getTipOfTheDay, getActivities, getPromosBanners } = useApi();
    const { isPT } = useLanguage();

    const CONTENT = isPT ? MOVEMENT_HOME.PT : MOVEMENT_HOME.EN;
    const SUBSCRIPTIONS_CONTENT = isPT
        ? CLIENT_AREA.PT.SUBSCRIPTIONS.CARDS.MOVEMENT
        : CLIENT_AREA.EN.SUBSCRIPTIONS.CARDS.MOVEMENT;

    const [movementPromos, setMovementPromos] = useState<CarouselProps>({} as CarouselProps);
    const [movementActivities, setMovementActivities] = useState<CardAlternativeProps[]>([]);

    const [tip, setTip] = useState<DailyTipData>({} as DailyTipData);

    useEffect(
        () => validateUserAccessToArea(permissions.movement.home),
        [validateUserAccessToArea]
    );

    const getTipOfTheDayData = useCallback(async () => {
        const tip = await getTipOfTheDay('movement');
        if (tip) setTip(tip);
    }, [getTipOfTheDay]);

    const getActivitiesData = useCallback(async () => {
        const activities = await getActivities('movement');
        setMovementActivities(activities);
    }, [getActivities]);

    const getPromosData = useCallback(async () => {
        const promos = await getPromosBanners('movement');
        setMovementPromos({
            __typename: 'card-full',
            slides: promos
        });
    }, [getPromosBanners]);

    useEffect(() => {
        getActivitiesData();
    }, [getActivitiesData]);

    useEffect(() => {
        if (SHOW_PROMOS) getPromosData();
    }, [getPromosData]);

    useEffect(() => {
        getTipOfTheDayData();
    }, [getTipOfTheDayData]);

    if (!user) return null;

    return (
        <>
            <Helmet>
                <title>
                    {isPT
                        ? META_TAGS.PRIVATE.MOVEMENT.HOME.PT.TITLE
                        : META_TAGS.PRIVATE.MOVEMENT.HOME.EN.TITLE}
                </title>
            </Helmet>
            <section className={`${styles.intro} container`}>
                <h1>{CONTENT.TITLE}</h1>
                <IconMovementV1
                    colorType="SOLID"
                    variation="OUTLINE"
                    width={20}
                    height={24}
                    color={COLORS.WHITE}
                />
            </section>
            <section className={`${styles.partner} container`}>
                <h2>{CONTENT.PARTNERS.TITLE}</h2>
                <p>{CONTENT.PARTNERS.DESCRIPTION}</p>
                <CardToggleV2
                    logo={<LogoFitnessHut color="negative" width={200} height={50} />}
                    content={SUBSCRIPTIONS_CONTENT.DESCRIPTION}
                    area="movement"
                />
            </section>
            <section className={`${styles.points} container`}>
                <h2>{CONTENT.POINTS.TITLE}</h2>
                <ActivityGraphHearts
                    progress={userProgress(user)}
                    area="movement"
                    className={styles.graph}
                />
                <Link
                    to="/pontos"
                    label="Saiba Mais"
                    size="xs"
                    type="button"
                    className={styles.button}
                />
            </section>
            {tip.id && (
                <section className={`${styles.tip} ${styles[activeTheme]} container`}>
                    <p>{CONTENT.TIP.TITLE}</p>
                    <h2>{isPT ? tip.content.pt : tip.content.en}</h2>
                </section>
            )}
            {SHOW_PROMOS && (
                <section className={`${styles.promos} container`}>
                    <h2>{CONTENT.PROMOS.TITLE}</h2>
                    <Carousel {...movementPromos} />
                </section>
            )}
            {movementActivities.length > 0 && (
                <section className={`${styles.activities} container`}>
                    <h2>
                        {movementActivities.length > 1
                            ? CONTENT.ACTIVITIES.TITLE.PLURAL
                            : CONTENT.ACTIVITIES.TITLE.SINGULAR}
                    </h2>
                    <p>{CONTENT.ACTIVITIES.DESCRIPTION}</p>
                    {movementActivities.map((activity) => (
                        <CardAlternative key={activity.slug} {...activity} />
                    ))}
                </section>
            )}
        </>
    );
};
