import { useLanguage, useTheme } from 'hooks';
import { AreaType } from 'models/area';
import { useState } from 'react';
import {
    IconHealthV1,
    IconMinusRounded,
    IconMovementV1,
    IconNutritionV1,
    IconPlusRounded
} from 'theme/icons';

import styles from './CardToggleV1.module.scss';

export type CardToggleV1Props = React.HTMLProps<HTMLDivElement> & {
    title: string;
    showStatus?: boolean;
    status?: boolean;
    className?: string;
    area: Exclude<AreaType, 'hps' | 'articles'>;
};

export const CardToggleV1: React.FC<CardToggleV1Props> = ({
    title,
    showStatus = true,
    status = false,
    area,
    className = '',
    children,
    ...props
}) => {
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();
    const [showCardDetails, setShowCardDetails] = useState(false);

    const CONTENT = isPT
        ? { STATUS: { ACTIVE: 'ativo', INACTIVE: 'inativo' } }
        : { STATUS: { ACTIVE: 'active', INACTIVE: 'inactive' } };

    return (
        <div className={`${styles.card} ${styles[activeTheme]} ${className}`} {...props}>
            <button
                type="button"
                className={styles.button}
                onClick={() => setShowCardDetails(!showCardDetails)}>
                <div className={styles.wrapper}>
                    {area === 'movement' && (
                        <IconMovementV1
                            colorType="GRADIENT"
                            width={40}
                            height={40}
                            variation="FILL"
                            className={styles.icon}
                        />
                    )}
                    {area === 'health' && (
                        <IconHealthV1
                            colorType="GRADIENT"
                            width={40}
                            height={40}
                            variation="FILL"
                            className={styles.icon}
                        />
                    )}
                    {area === 'nutrition' && (
                        <IconNutritionV1
                            colorType="GRADIENT"
                            width={40}
                            height={40}
                            variation="FILL"
                            className={styles.icon}
                        />
                    )}
                    <div>
                        <strong className={styles.title}>{title}</strong>
                        {showStatus && (
                            <span className={`${styles.status} ${status ? styles.on : styles.off}`}>
                                {`status: `}
                                <b>{status ? CONTENT.STATUS.ACTIVE : CONTENT.STATUS.INACTIVE}</b>
                            </span>
                        )}
                    </div>
                </div>
                {showCardDetails ? <IconMinusRounded /> : <IconPlusRounded />}
            </button>
            {showCardDetails && <div className={styles.content}>{children}</div>}
        </div>
    );
};
