import { Button } from 'components/elements';

import styles from './SuccessMessage.module.scss';

type SuccessMessageProps = {
    setHasSuccess: (value: React.SetStateAction<boolean>) => void;
    sharedImage: string | undefined;
    content: {
        TITLE: string;
        DESCRIPTION: string;
        BUTTON_LABEL: string;
    };
};

export const SuccessMessage: React.FC<SuccessMessageProps> = ({
    setHasSuccess,
    sharedImage,
    content
}) => {
    return (
        <div className={styles['success-message']}>
            <div className={styles.wrapper}>
                <div className={[styles['shared-image'], styles['scale-in-center']].join(' ')}>
                    <img src={sharedImage} alt="shared" />
                </div>
                <h2>{content.TITLE}</h2>
                <p>{content.DESCRIPTION}</p>
                <Button
                    label={content.BUTTON_LABEL}
                    size="md"
                    onClick={() => {
                        setHasSuccess(false);
                        // window.location.reload();
                    }}
                />
            </div>
        </div>
    );
};
