import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconSun: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        width={width}
        height={height}
        fill={color}
        {...props}>
        <path d="M10 5a5 5 0 1 0 5 5 5 5 0 0 0-5-5Zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-10a1 1 0 0 0 1-1V1a1 1 0 0 0-2 0v1a1 1 0 0 0 1 1Zm0 14a1 1 0 0 0-1 1v1a1 1 0 0 0 2 0v-1a1 1 0 0 0-1-1Zm6.36-12 .71-.71a1 1 0 0 0-1.41-1.41l-.71.71A1 1 0 1 0 16.36 5ZM3.64 15l-.71.71a1 1 0 0 0 1.41 1.41l.71-.71A1 1 0 1 0 3.64 15ZM19 9h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2ZM3 10a1 1 0 0 0-1-1H1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Zm13.36 5a1 1 0 1 0-1.41 1.41l.71.71a1 1 0 0 0 1.41-1.41ZM3.64 5.1a1 1 0 1 0 1.41-1.41l-.71-.71a1 1 0 0 0-1.41 1.41Z" />
    </svg>
);
