import { ErrorButton, ErrorMessage } from 'components/elements';
import { useTheme } from 'hooks';
import { useState } from 'react';
import { stringToSlug } from 'utils';

import { CheckboxProps } from '../types';
import styles from './Checkbox.module.scss';

export const Checkbox: React.FC<CheckboxProps> = ({ children, id, hasError = false, ...props }) => {
    const { activeTheme } = useTheme();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const slug = stringToSlug(id);

    const toggleMessage = () => {
        setErrorMessage('Some error message');
        setShowErrorMessage(!showErrorMessage);
    };

    return (
        <div
            className={[styles.container, styles[activeTheme], hasError ? styles.error : ''].join(
                ' '
            )}>
            <input type="checkbox" name={slug} id={slug} {...props} />
            <label htmlFor={slug}>
                <span>{children}</span>
            </label>
            {hasError && (
                <ErrorButton hasError handleErrorMessage={toggleMessage} position="TOP-RIGHT" />
            )}
            {showErrorMessage && <ErrorMessage message={errorMessage} />}
        </div>
    );
};
