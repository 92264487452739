import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconYoutube: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 29,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 29 20"
            width={width}
            height={height}
            style={{ fill: color }}
            {...props}>
            <path d="M28.92 7.38c-.1-1.29-.22-2.58-.43-3.86a3.53 3.53 0 0 0-3.1-3 29.18 29.18 0 0 0-3-.3c-3-.1-6-.18-9.06-.2a82.35 82.35 0 0 0-9 .37 4.94 4.94 0 0 0-2.21.63 3.74 3.74 0 0 0-1.74 3 79.27 79.27 0 0 0-.39 5v.94c0 .88 0 1.76.09 2.63a38.61 38.61 0 0 0 .43 3.86 3.51 3.51 0 0 0 3.1 3 28.7 28.7 0 0 0 2.94.3c3 .1 6 .18 9.06.2a82.35 82.35 0 0 0 9-.37 4.94 4.94 0 0 0 2.21-.63 3.77 3.77 0 0 0 1.75-3c.16-1.68.33-3.37.38-5v-1c.05-.82.03-1.7-.03-2.57Zm-17.19 6.89V5.74l7.57 4.23Z" />
        </svg>
    );
};
