import { Button, InputPassword } from 'components/elements';
import { permissions, toastOptions } from 'config';
import { PREFERENCES } from 'content';
import { useFormik } from 'formik';
import { useApi, useAuth, useLanguage, useTheme } from 'hooks';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { isAxiosError } from 'services';
import * as Yup from 'yup';

import styles from '../Preferences.module.scss';

type PasswordFormValues = {
    password: string;
    confirm: string;
};

export const EditPassword: React.FC = () => {
    const { validateUserAccessToArea } = useAuth();
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();
    const { postNewUserPassword } = useApi();

    const CONTENT = isPT ? PREFERENCES.PT.PASSWORD : PREFERENCES.EN.PASSWORD;

    useEffect(
        () => validateUserAccessToArea(permissions.general.preferences),
        [validateUserAccessToArea]
    );

    const initialValues = { password: '', confirm: '' };

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required(CONTENT.MESSAGES.PASSWORD.REQUIRED)
            .min(6, CONTENT.MESSAGES.PASSWORD.INVALID),
        confirm: Yup.string().oneOf([Yup.ref('password'), null], CONTENT.MESSAGES.PASSWORD.CONFIRM)
    });

    const onSubmit = useCallback(
        async (values: PasswordFormValues) => {
            try {
                await postNewUserPassword(values.password, true);
                toast.dark(CONTENT.MESSAGES.PASSWORD.SUCCESS, toastOptions);
            } catch (error) {
                if (isAxiosError(error) && error.response?.data.error === 'wrong_token')
                    toast.dark(CONTENT.MESSAGES.PASSWORD.API_ERROR, toastOptions);
            }
        },
        [
            CONTENT.MESSAGES.PASSWORD.API_ERROR,
            CONTENT.MESSAGES.PASSWORD.SUCCESS,
            postNewUserPassword
        ]
    );

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    return (
        <section className={`${styles.section} container`}>
            <h1>{CONTENT.INTRO.TITLE}</h1>
            <p>{CONTENT.INTRO.DESCRIPTION}</p>
            <div className={`${styles.card} ${styles[activeTheme]}`}>
                <form onSubmit={formik.handleSubmit} className={styles.form}>
                    <InputPassword
                        id="password"
                        label={CONTENT.FORM.LABELS.PASSWORD.NEW}
                        hasError={formik.touched.password && formik.errors.password ? true : false}
                        errorMessage={formik.errors.password}
                        {...formik.getFieldProps('password')}
                    />
                    <InputPassword
                        id="confirm"
                        className={styles['input-password']}
                        label={CONTENT.FORM.LABELS.PASSWORD.CONFIRM}
                        hasError={formik.touched.confirm && formik.errors.confirm ? true : false}
                        errorMessage={formik.errors.confirm}
                        {...formik.getFieldProps('confirm')}
                    />
                    <Button
                        type="submit"
                        label={CONTENT.FORM.LABELS.SUBMIT}
                        area="movement"
                        size="md"
                        className={styles.button}
                    />
                </form>
            </div>
        </section>
    );
};
