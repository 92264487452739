/**
 * The maximum is exclusive and the minimum is inclusive
 * @param {number} min - minimum return value
 * @param {number} max - maximum return value
 */
type GetRandomIntData = (min: number, max: number) => number;
export const getRandomInt: GetRandomIntData = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
};
