import { LogoHi } from 'assets/logos';
import { useLanguage, useTheme } from 'hooks';
import { AreaType } from 'models/types';
import { useEffect, useMemo, useState } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import styles from './CardArticleV1.module.scss';

type LangContent = {
    pt: string;
    en: string;
};

export type CardArticleV1Props = LinkProps & {
    heading?: LangContent;
    subtitle: LangContent;
    description?: LangContent;
    image?: string;
    image_2x?: string;
    area?: AreaType;
    to: string;
    type?: 'default' | 'alt' | 'small';
};

type CurrentContentData = { heading: string; subtitle: string; description: string };

export const CardArticleV1: React.FC<CardArticleV1Props> = ({
    heading,
    subtitle,
    description,
    image,
    image_2x,
    to,
    type = 'default',
    area = 'articles',
    ...props
}) => {
    const { activeTheme } = useTheme();
    const { isPT } = useLanguage();

    const cardClassName = useMemo(
        () => [styles.card, styles[area], styles[type], styles[activeTheme]].join(' '),
        [activeTheme, area, type]
    );
    const [currentContent, setCurrentContent] = useState<CurrentContentData | undefined>(undefined);

    useEffect(() => {
        setCurrentContent({
            heading: (isPT ? heading?.pt : heading?.en) || 'Hi Healthy Insurance',
            subtitle: isPT ? subtitle.pt : subtitle.en,
            description: isPT
                ? `${description?.pt?.slice(0, 120)}${
                      description && description?.pt?.length > 120 ? '...' : ''
                  }` || ''
                : `${description?.en?.slice(0, 120)}${
                      description && description?.en?.length > 120 ? '...' : ''
                  }` || ''
        });
    }, [isPT, description, subtitle, heading]);

    return (
        <Link to={to} className={cardClassName} {...props}>
            <h2 className={styles.title}>{currentContent?.heading}</h2>
            <p className={styles.subtitle}>{currentContent?.subtitle}</p>
            <div className={styles['image-container']}>
                {image ? (
                    <img
                        alt={currentContent?.heading}
                        srcSet={`${image_2x || image} 2x, ${image}`}
                        src={image}
                    />
                ) : (
                    <LogoHi animate />
                )}
            </div>
            <p className={styles.description}>{currentContent?.description}</p>
        </Link>
    );
};
