export const SHARE = {
    PT: {
        INTRO: {
            TITLE: 'Partilhe momentos Hi!',
            DESCRIPTION:
                'Comece por escolher uma foto da sua experiência Hi!, depois personalize o texto e, por fim, partilhe com os seus amigos. E claro, ganhe pontos Hi!'
        },
        ERROR: {
            TITLE: 'Oops!',
            DESCRIPTION: 'Aconteceu um erro na partilha. Tente novamente'
        },
        UNAVAILABLE: {
            TITLE: 'Oops!',
            DESCRIPTION: 'O seu dispositivo não suporta esta funcionalidade'
        },
        SUCCESS: {
            TITLE: 'Imagem partilhada!',
            DESCRIPTION:
                'Apostamos que esta imagem que partilhou com os seus amigos, vai trazer  bons comentários!',
            BUTTON_LABEL: 'Continuar'
        },
        BUTTONS: {
            CHOOSE: 'Escolher',
            SHARE: 'Partilhar'
        }
    },
    EN: {
        INTRO: {
            TITLE: 'Share Hi! moments',
            DESCRIPTION:
                'Start by choosing a photo from your Hi! experience, then personalize the text and finally share with your friends. And of course, earn Hi!'
        },
        ERROR: {
            TITLE: 'Oops!',
            DESCRIPTION: 'An error has occurred in sharing. Please try again'
        },
        UNAVAILABLE: {
            TITLE: 'Oops!',
            DESCRIPTION: 'Your device does not support this feature'
        },
        SUCCESS: {
            TITLE: 'Image shared!',
            DESCRIPTION: 'We bet this image you shared with your friends will bring good comments!',
            BUTTON_LABEL: 'Continue'
        },
        BUTTONS: {
            CHOOSE: 'Choose',
            SHARE: 'Share'
        }
    }
};
