import React from 'react';

import styles from './Main.module.scss';

type MainProps = { className?: string };

export const Main: React.FC<MainProps> = ({ children, className = '', ...props }) => (
    <main {...props} className={[styles.main, className ?? ''].join(' ')}>
        {children}
    </main>
);
