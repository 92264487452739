import { ErrorButton, ErrorMessage } from 'components/elements';
import { useState } from 'react';
import { IconShowHide } from 'theme/icons';
import { stringToSlug } from 'utils';

import { InputPasswordProps } from '../types';
import styles from './InputPassword.module.scss';

export const InputPassword: React.FC<InputPasswordProps> = ({
    label,
    hasError = false,
    errorMessage = null,
    className = '',
    id,
    ...props
}) => {
    const [inputType, setInputType] = useState<'password' | 'text'>('password');
    const [passwordVisibility, setPasswordVisibility] = useState<'SHOW' | 'HIDE'>('HIDE');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const slug = stringToSlug(label);

    const toggleMessage = () => {
        setShowErrorMessage(!showErrorMessage);
    };

    const handleInputType = () => {
        setInputType(inputType === 'password' ? 'text' : 'password');
        setPasswordVisibility(passwordVisibility === 'SHOW' ? 'HIDE' : 'SHOW');
    };

    return (
        <div className={`${styles.container} ${hasError ? styles.error : ''} ${className}`}>
            <input
                type={inputType}
                name={id ?? slug}
                id={id ?? slug}
                placeholder={' '}
                {...props}
            />
            <label htmlFor={id ?? slug}>{label}</label>
            <button type="button" className={styles['toggle-button']} onClick={handleInputType}>
                <IconShowHide display={passwordVisibility} />
            </button>
            {hasError && (
                <ErrorButton hasError handleErrorMessage={toggleMessage} position="CENTER-RIGHT" />
            )}
            {showErrorMessage && <ErrorMessage message={errorMessage} />}
        </div>
    );
};
