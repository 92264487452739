import { AreaType } from 'models/types';
import React, { Suspense } from 'react';

import styles from './AreaCards.module.scss';

const CardHalfV2 = React.lazy(
    () =>
        import(
            /* webpackChunkName: "CardHalfV2" */
            'components/elements/Cards/CardHalf/v2'
        )
);

type AreaCard = {
    area: AreaType;
    slug: string;
};

const cards: AreaCard[] = [
    {
        area: 'movement',
        slug: '/movimento'
    },
    {
        area: 'nutrition',
        slug: '/nutricao'
    },
    {
        area: 'health',
        slug: '/saude'
    },
    {
        area: 'articles',
        slug: '/artigos'
    }
];

const AreaCards: React.FC = () => {
    return (
        <section className={`${styles['area-cards']} container`}>
            {cards.map((c) => (
                <Suspense fallback={null} key={c.area}>
                    <CardHalfV2 area={c.area} to={c.slug} />
                </Suspense>
            ))}
        </section>
    );
};

export const MemoizedAreaCards = React.memo(AreaCards);
