import { Link } from 'components/elements';
import { useLanguage } from 'hooks';
import { AreaType } from 'models/types';
import { Link as RouterLink } from 'react-router-dom';

import styles from './CardFull.module.scss';

export type CardFullProps = {
    id?: string;
    label: string;
    heading: {
        pt: string;
        en: string;
    };
    description: {
        pt: string;
        en: string;
    };
    backgroundImage: string;
    hasButton?: boolean;
    buttonLabel?: string; // requires hasButton
    area?: AreaType; // requires hasButton
    uri: string;
    linkDestination?: 'internal' | 'external';
    textStyle?: 'light' | 'dark';
    color?: string;
};

export const CardFull: React.FC<CardFullProps> = ({ uri, hasButton = false, ...props }) => {
    switch (true) {
        case !hasButton && props.linkDestination === 'internal':
            return (
                <RouterLink className={styles.link} to={uri}>
                    <Content uri={uri} hasButton={hasButton} {...props} />
                </RouterLink>
            );

        case !hasButton && props.linkDestination === 'external':
            return (
                <a href={uri} target="_blank" rel="noopener noreferrer">
                    <Content uri={uri} hasButton={hasButton} {...props} />
                </a>
            );

        default:
            return <Content uri={uri} hasButton={hasButton} {...props} />;
    }
};

const Content: React.FC<CardFullProps & React.HTMLProps<HTMLDivElement>> = ({
    label,
    heading,
    description,
    backgroundImage,
    area = 'movement',
    hasButton,
    buttonLabel = 'label',
    uri,
    linkDestination = 'internal',
    color,
    textStyle = 'light',
    ...props
}) => {
    const { isPT } = useLanguage();

    return (
        <div
            className={`${styles.card} ${styles[textStyle]} ${styles[area]}`}
            style={{ backgroundImage: `url('${backgroundImage}')` }}
            {...props}>
            <span style={{ color }}>{label}</span>
            <h2 style={{ color }}>{isPT ? heading?.pt : heading?.en}</h2>
            {!hasButton && <p style={{ color }}>{isPT ? description.pt : description.en}</p>}
            {hasButton && linkDestination === 'internal' && (
                <Link label={buttonLabel} to={uri} type="button" size="xs" area={area} />
            )}
            {hasButton && linkDestination === 'external' && (
                <a
                    href={uri}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.button} ${styles[area]}`}>
                    {buttonLabel}
                </a>
                //
            )}
        </div>
    );
};
