import {
    About,
    Article,
    ArticlesList,
    ClientArea,
    EditName,
    EditPassword,
    EditProfilePhoto,
    Home,
    HomeMenu,
    Partner,
    Points,
    PromosList,
    SelectLanguage,
    SelectTheme,
    Share
} from 'pages/private';
import { Appointment, Checkup, HealthHome } from 'pages/private/Health';
import {
    CoachesList,
    CoachProfile,
    Exercise,
    MovementGuru,
    MovementHome,
    OnDemandDetail,
    OnDemandList
} from 'pages/private/Movement';
import {
    Assessment,
    NutritionGuru,
    NutritionHome,
    NutritionistProfile,
    NutritionistsList
} from 'pages/private/Nutrition';
import { Loading, NotFound } from 'pages/public';
import { AccountActivation, Login, PasswordRecover, PasswordReset, Welcome } from 'pages/public';
import { lazy, Suspense } from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';

import { Private } from './config/Private';
import { WithBackground } from './config/WithBackground';

const MovementLayout = lazy(
    () =>
        import(
            /* webpackChunkName: "MovementLayout" */
            'pages/private/Movement/Layout'
        )
);
const HealthLayout = lazy(
    () =>
        import(
            /* webpackChunkName: "HealthLayout" */
            'pages/private/Health/Layout'
        )
);
const NutritionLayout = lazy(
    () =>
        import(
            /* webpackChunkName: "NutritionLayout" */
            'pages/private/Nutrition/Layout'
        )
);
const PreferencesLayout = lazy(
    () =>
        import(
            /* webpackChunkName: "PreferencesLayout" */
            'pages/private/General/Preferences/Layout'
        )
);

const Routes: React.FC = () => (
    <ReactRoutes>
        {/* PUBLIC */}
        <Route path="/" element={<Welcome />} />
        <Route path="/login" element={<WithBackground element={<Login />} />} />
        <Route path="/recuperar" element={<WithBackground element={<PasswordRecover />} />} />
        <Route path="/reset/:token" element={<WithBackground element={<PasswordReset />} />} />
        {/* <Route path="/ativar/:token" element={<WithBackground element={<AccountActivation />} />} /> */}
        <Route path="/ativar" element={<WithBackground element={<AccountActivation />} />} />
        <Route path="*" element={<NotFound />} />

        {/* GENERAL PRIVATE */}
        <Route path="/inicio" element={<Private element={<Home />} />} />
        <Route path="/myhi" element={<Private element={<ClientArea />} />} />
        <Route path="/artigos" element={<Private element={<ArticlesList />} />} />
        <Route path="/artigos/:slug" element={<Private element={<Article />} />} />
        <Route path="/acerca" element={<Private element={<About />} />} />
        <Route
            path="/preferencias"
            element={
                <Suspense fallback={<Loading />}>
                    <Private element={<PreferencesLayout />} />
                </Suspense>
            }>
            <Route index element={<Private element={<HomeMenu />} />} />
            <Route path="nome" element={<Private element={<EditName />} />} />
            <Route path="password" element={<Private element={<EditPassword />} />} />
            <Route path="foto-perfil" element={<Private element={<EditProfilePhoto />} />} />
            <Route path="tema" element={<Private element={<SelectTheme />} />} />
            <Route path="idioma" element={<Private element={<SelectLanguage />} />} />
        </Route>
        <Route path="/partilhar" element={<Private element={<Share />} />} />
        <Route path="/promos">
            <Route index element={<Private element={<PromosList />} />} />
            <Route path=":slug/:id" element={<Private element={<Partner />} />} />
        </Route>
        <Route path="/pontos" element={<Private element={<Points />} />} />

        {/* MOVEMENT */}
        <Route
            path="movimento"
            element={
                <Suspense fallback={<Loading />}>
                    <Private element={<MovementLayout />} />
                </Suspense>
            }>
            <Route index element={<Private element={<MovementHome />} />} />
            <Route path="coaches" element={<Private element={<CoachesList />} />} />
            <Route path="coaches/:slug" element={<Private element={<CoachProfile />} />} />
            <Route path="guru" element={<Private element={<MovementGuru />} />} />
            <Route path="videos" element={<Private element={<OnDemandList />} />} />
            <Route path="videos/:tag" element={<Private element={<OnDemandDetail />} />} />
            <Route path="treino" element={<Private element={<Exercise />} />} />
        </Route>

        {/* NUTRITION */}
        <Route
            path="nutricao"
            element={
                <Suspense fallback={<Loading />}>
                    <Private element={<NutritionLayout />} />
                </Suspense>
            }>
            <Route index element={<Private element={<NutritionHome />} />} />
            <Route path="guru" element={<Private element={<NutritionGuru />} />} />
            <Route path="consultas" element={<Private element={<NutritionistsList />} />} />
            <Route
                path="nutricionistas/:slug"
                element={<Private element={<NutritionistProfile />} />}
            />
        </Route>
        <Route path="/nutricao/avaliacao" element={<Private element={<Assessment />} />} />

        {/* HEALTH */}
        <Route
            path="saude"
            element={
                <Suspense fallback={<Loading />}>
                    <Private element={<HealthLayout />} />
                </Suspense>
            }>
            <Route index element={<Private element={<HealthHome />} />} />
            <Route path="consulta" element={<Private element={<Appointment />} />} />
            <Route path="check-up" element={<Private element={<Checkup />} />} />
        </Route>
    </ReactRoutes>
);

export default Routes;
