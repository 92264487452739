import { createContext, useCallback, useContext, useEffect, useState } from 'react';

type ScrollContextData = {
    scrollTop: (behavior?: ScrollBehavior | undefined) => void;
    scrolled: boolean;
};

const ScrollContext = createContext<ScrollContextData>({} as ScrollContextData);

const DOCUMENT_BODY_POSITION_TO_REVEAL_SECONDARY_HEADER = -170;

const ScrollProvider: React.FC = ({ children }) => {
    const [scrolled, setScrolled] = useState(false);

    const scrollTop = useCallback((behavior?: ScrollBehavior) => {
        window.scroll({
            top: 0,
            left: 0,
            behavior
        });
    }, []);

    useEffect(() => {
        const handler = () =>
            setScrolled(
                document.body.getBoundingClientRect().y <
                    DOCUMENT_BODY_POSITION_TO_REVEAL_SECONDARY_HEADER
            );

        window.addEventListener('scroll', handler);
        return window.removeEventListener('scroll', handler);
    }, []);

    return (
        <ScrollContext.Provider value={{ scrollTop, scrolled }}>{children}</ScrollContext.Provider>
    );
};

const useScroll: () => ScrollContextData = () => {
    const context = useContext(ScrollContext);
    if (!context) {
        throw Error('the hook useScroll must be used inside a ScrollProvider');
    }
    return context;
};

export { ScrollProvider, useScroll };
