import { useAuth } from 'hooks';
import { AreaType } from 'models/area';
import { PerformanceData, UserData } from 'models/types';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { api, isAxiosError } from 'services';
import { DEV } from 'utils';

import { PointsModal } from './modules';

type Interaction =
    | 'articles'
    | 'nutritionists'
    | 'sport_coaches'
    | 'checkup'
    | 'manual_activities'
    | 'upload_photo'
    | 'nutri_survey'
    | 'promos';

type InteractionData = {
    interaction: Interaction;
    area: AreaType;
    id: string | null;
};

type UserProgress = (user: UserData) => {
    all: number;
    health: number;
    movement: number;
    nutrition: number;
};

type GamificationContextData = {
    userProgress: UserProgress;
    reportInteraction: (body: InteractionData) => Promise<void>;
    updateUserPerformance: (performance: PerformanceData) => UserData;
    userPerformance: PerformanceData | undefined;
    pointsGained: number;
    setPointsGained: React.Dispatch<React.SetStateAction<number>>;
};

const GamificationContext = createContext<GamificationContextData>({} as GamificationContextData);

export const GamificationProvider: React.FC = ({ children }) => {
    const { user, updateUser } = useAuth();
    const [pointsGained, setPointsGained] = useState(0);
    const [userPerformance, setUserPerformance] = useState<PerformanceData | undefined>();

    const userProgress = useCallback((user: UserData) => {
        const { performance } = user;
        const { percentage, trackers } = performance;

        const progressPercentages = {
            all: percentage,
            health: trackers.find(({ area }) => area === 'health')?.percentage || 0,
            movement: trackers.find(({ area }) => area === 'movement')?.percentage || 0,
            nutrition: trackers.find(({ area }) => area === 'nutrition')?.percentage || 0
        };

        return progressPercentages;
    }, []);

    const updateUserPerformance = useCallback(
        (performance: PerformanceData) => {
            setPointsGained(performance.new_points);
            setUserPerformance(performance);

            if (!user) throw new Error('no user found');
            user.performance = performance;
            updateUser(user);

            DEV &&
                console.log(
                    '%c USER PERFORMANCE UPDATE ',
                    'background-color: #095402; font-size: 16px;'
                );

            return user;
        },
        [updateUser, user]
    );

    const reportInteraction = useCallback(
        async (body: InteractionData) => {
            try {
                const response = await api.post(`users/${user?.id}/interactions/add`, body);
                const performance: PerformanceData = response.data.performance;

                if (performance) updateUserPerformance(performance);

                DEV &&
                    console.log(
                        '%c REPORT INTERACTION ',
                        'background-color: #3f0088; font-size: 16px;'
                    );
            } catch (error) {
                if (isAxiosError(error)) {
                    DEV &&
                        console.log(
                            `%c err: ${error.response?.data.message}`,
                            'background-color: #420000; font-size: 14px;'
                        );
                }
            }
        },
        [updateUserPerformance, user?.id]
    );

    /**
     * Handles eventual user performance updates added server-side
     * got after using `getStatus` on `useAuth`
     * workaround since `useAuth` can´t use `useGamification`
     */
    useEffect(() => {
        if (!user) return;
        updateUserPerformance(user?.performance);
    }, [updateUserPerformance, user]);

    return (
        <GamificationContext.Provider
            value={{
                userProgress,
                reportInteraction,
                pointsGained,
                setPointsGained,
                updateUserPerformance,
                userPerformance
            }}>
            {children}
            <PointsModal pointsGained={pointsGained} setPointsGained={setPointsGained} />
        </GamificationContext.Provider>
    );
};

export const useGamification: () => GamificationContextData = () => {
    const context = useContext(GamificationContext);
    if (!context) {
        throw Error('the hook useGamification must be used inside a GamificationProvider');
    }
    return context;
};
