import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconArrow: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 11,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 11"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                d="m2657 897.41-9.25-9.47a.85.85 0 0 0-.25-.17.75.75 0 0 0-.81.17l-9.25 9.47a.78.78 0 0 0 0 1.08.74.74 0 0 0 1.06 0l8.72-8.92 8.72 8.92a.74.74 0 0 0 1.06 0 .78.78 0 0 0 0-1.08Z"
                transform="translate(-2637.17 -887.72)"
            />
        </svg>
    );
};
