import { ActivityGraphHearts } from 'components/elements';
import { Link } from 'components/elements';
import { useAuth, useGamification } from 'hooks';
import { DEV } from 'utils';

import styles from './Points.module.scss';

type PointsProps = {
    content: {
        TITLE: string;
        DESCRIPTION: string;
        BUTTON_LABEL: string;
    };
};

export const Points: React.FC<PointsProps> = ({ content }) => {
    const { user } = useAuth();
    const { userProgress } = useGamification();

    // DEV
    if (DEV && user) {
        console.log(`%c user progress:`, 'background-color: #3823c2; font-size: 20px');
        console.log(`%c   all: ${userProgress(user).all}`, 'background-color: #3823c2');
        console.log(`%c   health: ${userProgress(user).health}`, 'background-color: #3823c2');
        console.log(`%c   movement: ${userProgress(user).movement}`, 'background-color: #3823c2');
        console.log(`%c   nutrition: ${userProgress(user).nutrition}`, 'background-color: #3823c2');
    }

    if (!user) return null;
    return (
        <section className={`${styles.points} container`}>
            <h2>{content.TITLE}</h2>
            <p>{content.DESCRIPTION}</p>
            <ActivityGraphHearts
                progress={userProgress(user)}
                area="all"
                className={styles.graph}
            />
            <Link
                to="/pontos"
                label="Saiba Mais"
                size="xs"
                type="button"
                className={styles.button}
            />
        </section>
    );
};
