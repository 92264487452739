import { META_TAGS } from 'content';
import { useLanguage } from 'hooks';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

import { HightLights, Intro, List, NotFound, Search, SearchResults } from './modules';
import { StaffProvider } from './useStaff';

export const CoachesList: React.FC = () => {
    const { isPT } = useLanguage();
    const pageTitle = useMemo(
        () =>
            isPT
                ? META_TAGS.PRIVATE.MOVEMENT.COACHES_LIST.PT.TITLE
                : META_TAGS.PRIVATE.MOVEMENT.COACHES_LIST.EN.TITLE,
        [isPT]
    );

    return (
        <StaffProvider>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <Intro />
            <Search />
            <NotFound />
            <HightLights />
            <List />
            <SearchResults />
        </StaffProvider>
    );
};
