import { Slide, ToastOptions } from 'react-toastify';

export const toastOptions: ToastOptions = {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    delay: 200,
    pauseOnFocusLoss: true,
    transition: Slide,
    draggablePercent: 60,
    role: 'alert',
    className: 'toast',
    progressClassName: 'toast-progress'
};
