import { LogoHi } from 'assets/logos';
import { Button, Input, InputPassword, LangSelect, Link, Modal } from 'components/elements';
import { LOGIN, META_TAGS } from 'content';
import { useFormik } from 'formik';
import { useAuth, useLanguage } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { COLORS } from 'theme';
import { IconAlert } from 'theme/icons';
import * as Yup from 'yup';

import styles from '../Access.module.scss';

export type LoginProps = React.HTMLProps<HTMLDivElement>;
type FormValues = { email: string; password: string };

export const Login: React.FC = () => {
    const { isPT } = useLanguage();
    const { signIn, user } = useAuth();
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (user) navigate('/inicio');
    }, [navigate, user]);

    const CONTENT = isPT ? LOGIN.PT : LOGIN.EN;

    const initialValues = { email: '', password: '' };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required(CONTENT.VALIDATION_MESSAGES.EMAIL[0])
            .email(CONTENT.VALIDATION_MESSAGES.EMAIL[1]),
        password: Yup.string().required(CONTENT.VALIDATION_MESSAGES.PASSWORD)
    });

    const onSubmit = useCallback(
        async (values: FormValues) => {
            try {
                await signIn(values);
            } catch (error) {
                setShowModal(true);
            }
        },
        [signIn]
    );

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    return (
        <>
            <Helmet>
                <title>
                    {isPT ? META_TAGS.PUBLIC.LOGIN.PT.TITLE : META_TAGS.PUBLIC.LOGIN.EN.TITLE}
                </title>
            </Helmet>
            <div className={styles.container}>
                <RouterLink to="/">
                    <LogoHi animate showLettering={false} width={72} className={styles.logo} />
                </RouterLink>
                <h1>{CONTENT.TITLE}</h1>
                <form className={styles.form} onSubmit={formik.handleSubmit}>
                    <Input
                        type="email"
                        label={CONTENT.LABELS.EMAIL}
                        autoComplete="username"
                        className={styles.input}
                        hasError={formik.touched.email && !!formik.errors.email}
                        errorMessage={formik.errors.email}
                        {...formik.getFieldProps('email')}
                    />
                    <InputPassword
                        label={CONTENT.LABELS.PASSWORD}
                        autoComplete="current-password"
                        className={styles.input}
                        hasError={formik.touched.password && !!formik.errors.password}
                        errorMessage={formik.errors.password}
                        {...formik.getFieldProps('password')}
                    />
                    <Button type="submit" label={CONTENT.LABELS.SUBMIT} size="lg" />
                </form>
                <Link label={CONTENT.LINKS.RECOVER} to="/recuperar" size="xs" type="link" />
                <p className={styles.register}>
                    {CONTENT.LINKS.NOT_CLIENT[0]}
                    <a
                        href="https://simulador.hihealthyinsurance.com/"
                        target="_blank"
                        rel="noopener noreferrer">
                        {CONTENT.LINKS.NOT_CLIENT[1]}
                    </a>
                    .
                </p>
                <LangSelect className={styles['lang-button']} />
            </div>
            <Modal
                show={showModal}
                className={[styles.modal, styles.error].join(' ')}
                footer={{ enable: false }}
                close={{ handler: () => setShowModal(false), buttonColor: COLORS.RED_500 }}
                background={{ color: COLORS.WHITE }}
                overlay={{ style: 'dark' }}>
                <IconAlert color={COLORS.RED_500} width={64} height={56} />
                <h2>{CONTENT.MODAL.TITLE}</h2>
                <p>
                    {CONTENT.MODAL.DESCRIPTION[0].TEXT}
                    <RouterLink
                        to="/login"
                        className={styles.link}
                        onClick={() => setShowModal(false)}>
                        {CONTENT.MODAL.DESCRIPTION[0].BUTTON}
                    </RouterLink>
                </p>
                <p>
                    {CONTENT.MODAL.DESCRIPTION[1].TEXT}
                    <RouterLink to="/recuperar" className={styles.link}>
                        {CONTENT.MODAL.DESCRIPTION[1].BUTTON}
                    </RouterLink>
                </p>
                <Button
                    label={CONTENT.MODAL.BUTTON.CLOSE}
                    className={styles.button}
                    size="md"
                    onClick={() => setShowModal(false)}
                />
            </Modal>
        </>
    );
};
