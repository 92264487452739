import { COLORS } from 'theme/colors';

import { IconProps } from '../../types';

export const IconPhone: React.FC<IconProps> = ({
    color = COLORS.NEUTRAL_800,
    width = 20,
    height = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width={width}
            height={height}
            fill={color}
            {...props}>
            <path
                fillRule="evenodd"
                d="M11.81 2.41c-.49-.06-1.12-.27-1.23.38s.58.63 1 .72c3.08.68 4.14 1.78 4.65 4.77a2.71 2.71 0 0 1 0 .41c0 .36.12.67.66.58a.39.39 0 0 0 .38-.39v-.6a6.25 6.25 0 0 0-5.55-5.87Zm.28 2.36c-.32 0-.64 0-.76.38s.2.63.57.69a2.2 2.2 0 0 1 2.06 2.14.79.79 0 0 0 .15.42.46.46 0 0 0 .41.16.61.61 0 0 0 .27-.08.82.82 0 0 0 .22-.76 3.28 3.28 0 0 0-2.92-3Zm6.38 9.7c-.62-.49-1.31-.93-1.94-1.38-1.31-.89-2.51-1-3.48.47a1.53 1.53 0 0 1-2.11.49 9.14 9.14 0 0 1-4.92-4.69 3 3 0 0 1-.26-1 1.69 1.69 0 0 1 .87-1.56 1.75 1.75 0 0 0 1-1.58c0-1-2.61-4.5-3.64-4.88a1.94 1.94 0 0 0-1.26 0c-2.39.8-3.37 2.7-2.42 5a26.1 26.1 0 0 0 14.58 14.38 5.33 5.33 0 0 0 1 .28 4.71 4.71 0 0 0 3.89-2.9c.51-1.4-.56-1.94-1.4-2.63Zm-7.64-13.4c5 .76 7.26 3.08 7.9 8v.12c0 .46-.05 1 .55 1s.55-.38.55-.76a1.45 1.45 0 0 1 0-.36 9.36 9.36 0 0 0-8.84-9.1c-.37.06-1.11-.24-1.17.55 0 .53.61.46 1 .5Z"
            />
        </svg>
    );
};
