import { ButtonIcon } from 'components/elements';
import * as IMAGES from 'constants/images';
import { useLanguage } from 'hooks';
import { AreaType } from 'models/types';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS } from 'theme/colors';
import {
    IconAreaProps,
    IconArticleV1,
    IconHealthV1,
    IconMovementV1,
    IconNutritionV1
} from 'theme/icons';
import { areaToPT } from 'utils';

import styles from './CardHalfV2.module.scss';

export type CardHalfV2Props = React.HTMLProps<HTMLDivElement> & {
    area: AreaType;
    to: string;
};

export const CardHalfV2: React.FC<CardHalfV2Props> = ({ area, to, ...props }) => {
    const navigate = useNavigate();
    const { isPT } = useLanguage();

    const [bgImage, setBgImage] = useState<string | null>(null);
    const iconProps: IconAreaProps = {
        width: 72,
        height: 72,
        color: COLORS.WHITE,
        variation: 'OUTLINE',
        colorType: 'SOLID'
    };

    const getAreaImage = useCallback(() => {
        switch (area) {
            case 'movement':
                return IMAGES.MOVEMENT_BUTTON_BG;
            case 'health':
                return IMAGES.HEALTH_BUTTON_BG;
            case 'nutrition':
                return IMAGES.NUTRITION_BUTTON_BG;
            case 'articles':
                return IMAGES.ARTICLES_BUTTON_BG;
            default:
                return IMAGES.MOVEMENT_BUTTON_BG;
        }
    }, [area]);

    useEffect(() => setBgImage(getAreaImage), [area, getAreaImage]);

    return (
        <div
            style={{
                backgroundImage: `url('${bgImage}')`
            }}
            className={styles.card}
            {...props}>
            <span className={styles.area}>{isPT ? areaToPT(area) : area}</span>
            {area === 'health' && <IconHealthV1 {...iconProps} />}
            {area === 'movement' && <IconMovementV1 {...iconProps} />}
            {area === 'articles' && <IconArticleV1 {...iconProps} />}
            {area === 'nutrition' && <IconNutritionV1 {...iconProps} />}
            <ButtonIcon
                icon="PLUS"
                size="XS"
                backgroundOpacity="TRANSLUCID"
                onClick={() => navigate(to)}
                className={styles['plus-button']}
            />
        </div>
    );
};

export default CardHalfV2;
