import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';

type LanguageContextData = {
    currentLanguage: 'PT' | 'EN';
    switchLanguage: (language: 'PT' | 'EN') => void;
    checkLanguage: () => void;
    toggleLanguage: () => void;
    isPT: boolean;
    isEN: boolean;
};

type ProviderProps = {
    children: ReactNode;
};

const LanguageContext = createContext<LanguageContextData>({} as LanguageContextData);

const LanguageProvider: React.FC<ProviderProps> = ({ children }) => {
    const [currentLanguage, setCurrentLanguage] = useState<'PT' | 'EN'>('PT');

    const switchLanguage = useCallback((language: 'PT' | 'EN') => {
        localStorage.setItem('@hi_lang', language);
        setCurrentLanguage(language);
    }, []);

    const checkLanguage = useCallback(() => {
        const lang = localStorage.getItem('@hi_lang') as 'PT' | 'EN';

        if (!lang) {
            const navigatorLanguage = navigator.language.toUpperCase().split('-')[0];
            const currentLang = navigatorLanguage === 'PT' ? 'PT' : 'EN';
            setCurrentLanguage(currentLang);
            localStorage.setItem('@hi_lang', currentLang);
            return;
        }

        setCurrentLanguage(lang);
    }, []);

    const toggleLanguage = useCallback(
        () => setCurrentLanguage((state) => (state === 'PT' ? 'EN' : 'PT')),
        []
    );

    const isPT = useMemo(() => currentLanguage === 'PT', [currentLanguage]);
    const isEN = useMemo(() => currentLanguage === 'EN', [currentLanguage]);

    useEffect(() => checkLanguage(), [checkLanguage]);

    return (
        <LanguageContext.Provider
            value={{ currentLanguage, switchLanguage, checkLanguage, toggleLanguage, isPT, isEN }}>
            {children}
        </LanguageContext.Provider>
    );
};

const useLanguage: () => LanguageContextData = () => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw Error('the hook useLanguage must be used inside a LanguageProvider');
    }
    return context;
};

export { LanguageProvider, useLanguage };
